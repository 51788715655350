import React from 'react'
import FormError from '../UI/Forms/FormError/FormError'
import FormControlsFormik, {
  FormControlFormikType,
} from '../UI/Forms/FormControlsFormik/FormControlsFormik'
import { FormDynamicComponentProps } from '../FormDynamic/FormDynamicComponent'

const FieldFunctionOntology: React.FC<FormDynamicComponentProps> = (
  props: FormDynamicComponentProps
) => {
  switch (props.annotation.ontology) {
    case 'practitioner':
      return (
        <FormControlsFormik
          {...props}
          controlType={FormControlFormikType.PRACTITIONER_SELECT}
          handleChange={props.handleChange}
        />
      )
    case 'icd10':
      return (
        <FormControlsFormik
          {...props}
          controlType={FormControlFormikType.ICD10_SELECT}
          handleChange={props.handleChange}
        />
      )
    default:
      return <FormError>Ontologiekomponente unbekannten Typs</FormError>
  }
}

export default FieldFunctionOntology
