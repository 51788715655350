import React, { Component, createRef } from 'react'
import { Practitioner } from '../../../types/Practitioner'
import { Form, Formik } from 'formik'
import Loader from '../../UI/Spinner/Loader'
import { Button, Col, Row } from 'react-bootstrap'
import MaterialIcon from '../../UI/MaterialIcon/MaterialIcon'
import { SexType } from '../../../constants/SexType'
import OrganizationSelect from '../../UI/Forms/OrganizationSelect/OrganizationSelect'
import FormControlsFormik, {
  FormControlFormikType,
} from '../../UI/Forms/FormControlsFormik/FormControlsFormik'
import { FormOption } from '../../../types/FormOption'
import { UserPermission } from '../../../constants/UserPermission'
import { AuthStore } from '../../../infrastructure/AuthProvider'

interface IProps {
  profile?: Practitioner
  onSubmit?: (value: Practitioner) => void
  onAbort?: () => void
  readOnly?: boolean
}

interface IState {
  profileForm: Practitioner | null
}

const positions: FormOption[] = [
  { value: '0', label: 'Chefarzt' },
  { value: '1', label: 'Oberarzt' },
  { value: '2', label: 'Assistenzarzt' },
  { value: '3', label: 'Case Manager' },
  { value: '4', label: 'Unbekannt' },
]

export const emptyProfileForm: Practitioner = {
  id: null,

  firstName: null,
  lastName: null,
  lanr: null,
  prefix: null,
  role: null,
  gender: null,
  birthDate: null,
  phoneNumber: null,
  organization: null,
  position: null,

  // address
  street: null,
  streetNumber: null,
  city: null,
  postalCode: null,
  country: null,

  userName: null,
  email: null,
  qualifications: null,
  sharedCalendar: null,
}

class ProfilForm extends Component<IProps, IState> {
  state = {
    profileForm: null,
    readOnly: false,
  }
  static contextType = AuthStore

  formRef = createRef<HTMLFormElement>()

  componentDidMount(): void {
    if (this.props.profile) {
      this.createFormikForm()
    }
  }

  componentDidUpdate(prevProps: IProps): void {
    const { profile } = this.props

    if (profile !== prevProps.profile) {
      this.createFormikForm()
    }
  }

  createFormikForm = (): void => {
    const { profile } = this.props
    const profileForm = {
      ...emptyProfileForm,
    }
    Object.keys(profileForm).forEach(key => {
      profileForm[key] = profile[key]
    })

    this.setState({
      profileForm,
    })
  }

  resetForm = (): void => {
    this.createFormikForm()
    if (this.props.onAbort) this.props.onAbort()
  }

  handleSubmit = (formikValues: Practitioner): void => {
    const values = this.mapValuesToPractitioner(formikValues)

    const practitioner: Practitioner = {
      ...this.props.profile,
    }

    Object.keys(emptyProfileForm).forEach(key => {
      practitioner[key] = values[key] !== undefined ? values[key] : null
    })

    this.props.onSubmit({
      ...practitioner,
    })
  }

  mapValuesToPractitioner = (values: Practitioner): Practitioner => {
    if (!values.email) {
      values.email = null
    } else if (!Array.isArray(values.email)) {
      const email = []
      email.push(values.email)
      values.email = email
    }
    if (!Array.isArray(values.qualifications)) {
      const quali = []
      quali.push(values.qualifications)
      values.qualifications = quali
    }
    if (!Array.isArray(values.phoneNumber)) {
      const phone = []
      phone.push(values.phoneNumber)
      values.phoneNumber = phone
    }
    return values
  }

  render(): JSX.Element {
    if (this.props.profile === null || this.state.profileForm === null)
      return <Loader />

    return (
      <Formik
        initialValues={{ ...this.state.profileForm }}
        onSubmit={this.handleSubmit}
        enableReinitialize>
        {formState => {
          return (
            <Form>
              <Row>
                <Col>
                  <h5 className="mt-2">
                    <MaterialIcon icon={'donut_large'} size={'16px'} /> Person
                  </h5>
                </Col>
              </Row>
              <Row>
                <FormControlsFormik
                  disabled={this.props.readOnly}
                  controlType={FormControlFormikType.SELECT}
                  label="Anrede"
                  name="gender"
                  options={Object.entries(SexType).map(val => ({
                    label: val[1],
                    value: val[0],
                  }))}
                  className="col-12 col-md-6 col-xl-3"
                  required
                />
                <FormControlsFormik
                  disabled={this.props.readOnly}
                  controlType={FormControlFormikType.TEXT}
                  type="text"
                  label="Titel"
                  name="prefix"
                  className="col-12 col-md-6 col-xl-3"
                />

                <FormControlsFormik
                  disabled={this.props.readOnly}
                  controlType={FormControlFormikType.TEXT}
                  type="text"
                  name="lastName"
                  label="Nachname"
                  className="col-12 col-md-6 col-xl-3"
                  required
                />
                <FormControlsFormik
                  disabled={this.props.readOnly}
                  controlType={FormControlFormikType.TEXT}
                  type="text"
                  name="firstName"
                  label="Vorname"
                  className="col-12 col-md-6 col-xl-3"
                  required
                />
              </Row>
              <Row>
                <Col>
                  <h5 className="mt-2">
                    <MaterialIcon icon={'donut_large'} size={'16px'} /> Kontakt
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col xs="12" xl="6">
                  <Row>
                    <FormControlsFormik
                      disabled={this.props.readOnly}
                      controlType={FormControlFormikType.TEXT}
                      type="text"
                      label="Straße"
                      name="street"
                      className="col-12 col-md-8"
                      required
                    />
                    <FormControlsFormik
                      disabled={this.props.readOnly}
                      controlType={FormControlFormikType.TEXT}
                      type="text"
                      label="Hausnummer"
                      name="streetNumber"
                      className="col-6 col-md-4"
                      required
                    />
                    <FormControlsFormik
                      disabled={this.props.readOnly}
                      controlType={FormControlFormikType.TEXT}
                      type="text"
                      label="Postleitzahl"
                      name="postalCode"
                      placeholder="Postleitzahl"
                      className="col-6 col-md-4"
                      required
                    />
                    <FormControlsFormik
                      disabled={this.props.readOnly}
                      controlType={FormControlFormikType.TEXT}
                      type="text"
                      label="Stadt"
                      name="city"
                      className=" col-12 col-md-8"
                      required
                    />
                  </Row>
                </Col>
                <Col xs="12" xl="6">
                  <Row>
                    <FormControlsFormik
                      disabled={this.props.readOnly}
                      controlType={FormControlFormikType.TEXT}
                      type="text"
                      label="E-Mail"
                      name="email"
                      className="col-12"
                    />
                    <FormControlsFormik
                      disabled={this.props.readOnly}
                      controlType={FormControlFormikType.TEXT}
                      type="text"
                      label="Telefonnummer"
                      name="phoneNumber"
                      className="col-12"
                    />
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <h5 className="mt-2">
                    <MaterialIcon icon={'donut_large'} size={'16px'} />{' '}
                    Tätigkeit
                  </h5>
                </Col>
                {this.context.handlers.hasPermission(
                  UserPermission.PROFILE_SET_CENTER_OR_POSITION
                ) && (
                  <Col xs="12" lg="6">
                    <OrganizationSelect
                      disabled={this.props.readOnly}
                      label="Zentrum"
                      value={formState.values.organization}
                      onChange={organization =>
                        formState.setFieldValue('organization', organization.id)
                      }
                    />
                  </Col>
                )}
                <FormControlsFormik
                  disabled={this.props.readOnly}
                  controlType={FormControlFormikType.TEXT}
                  type="text"
                  label="Spezialisierung"
                  name="qualifications"
                  placeholder={'Spezialisierung'}
                  className="col-lg-6 col-12"
                />
                {this.context.handlers.hasPermission(
                  UserPermission.PROFILE_SET_CENTER_OR_POSITION
                ) && (
                  <FormControlsFormik
                    disabled={this.props.readOnly}
                    controlType={FormControlFormikType.SELECT}
                    label="Position"
                    name="position"
                    options={positions}
                    className="col-12 col-lg-6"
                    required
                  />
                )}
                <FormControlsFormik
                  disabled={this.props.readOnly}
                  controlType={FormControlFormikType.TEXT}
                  type="text"
                  label="Lebenslange Arztnummer"
                  name="lanr"
                  className="col-12 col-lg-6"
                />
              </Row>
              {!this.props.readOnly && (
                <Row>
                  <Col>
                    <h5 className="mt-2">
                      <MaterialIcon icon={'donut_large'} size={'16px'} />{' '}
                      Persönlicher Kalender
                    </h5>
                    <FormControlsFormik
                      controlType={FormControlFormikType.CHECKBOX}
                      name="sharedCalendar"
                      valueText="Standardmäßig freigegeben"
                    />
                  </Col>
                </Row>
              )}
              {!this.props.readOnly && (
                <div className="text-right pt-3">
                  <button
                    className="btn btn-secondary mr-1 "
                    onClick={this.resetForm}
                    type="reset">
                    Abbrechen
                  </button>
                  <Button variant="primary" type="submit">
                    Speichern
                  </Button>
                </div>
              )}
            </Form>
          )
        }}
      </Formik>
    )
  }
}

export default ProfilForm
