import React from 'react'

export interface ITabSimple {
  title: string
  key: string
  badge?: number | string
  errors: boolean
}

interface TabsSimpleProps {
  tabs: ITabSimple[]
  activeTab: string
  disableBottomLine?: boolean
  onSelect: (tab: string) => void
}

const TabsSimple: React.FC<TabsSimpleProps> = (props: TabsSimpleProps) => {
  const { tabs, activeTab, onSelect } = props

  const handleClick = (event: React.MouseEvent, tab: ITabSimple) => {
    if (onSelect) onSelect(tab.key)
  }

  return (
    <ul
      className="nav nav-material w-100"
      style={props.disableBottomLine ? { borderBottom: 0 } : {}}>
      {tabs.map((tab: ITabSimple, index: number) => (
        <li className="nav-item" key={tab.key}>
          <a
            className={`nav-link ${activeTab === tab.key ? 'active' : ''}`}
            href={`#${tab.key}`}
            onClick={event => handleClick(event, tab)}>
            <span style={tab.errors ? { color: 'red' } : {}}>{tab.title}</span>
            {tab.badge ? (
              <span className="badge badge-panos ml-1">{tab.badge}</span>
            ) : null}
          </a>
        </li>
      ))}
    </ul>
  )
}
export default TabsSimple
