import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthStore } from '../../../infrastructure/AuthProvider'
import { UserPermission } from '../../../constants/UserPermission'

/**
 * @template T type of the key in the tab, default string.
 */
export interface ITab<T = string> {
  title: string
  key: T
  badge?: number | string
  linkTo?: string
  permission?: UserPermission
}

interface TabsComponentProps {
  tabs: ITab[]
  activeTab: string
  onSelect?: (tab: string) => void
}

const TabsComponent: React.FC<TabsComponentProps> = (
  props: TabsComponentProps
) => {
  const { tabs, activeTab, onSelect } = props
  const history = useHistory()
  const context = useContext(AuthStore)
  const { hasPermission } = context.handlers

  const handleClick = (event: React.MouseEvent, tab: ITab) => {
    if (tab.linkTo) event.preventDefault()
    if (tab.linkTo) history.push(tab.linkTo)
    if (onSelect) onSelect(tab.key)
  }

  return (
    <ul className="nav nav-material w-100">
      {tabs
        .filter(tab => !tab.permission || hasPermission(tab.permission))
        .map(tab => (
          <li className="nav-item" key={tab.key}>
            <a
              className={`nav-link ${activeTab === tab.key ? 'active' : ''}`}
              onClick={event => handleClick(event, tab)}
              href={tab.linkTo ? tab.linkTo : `#${tab.key}`}>
              {tab.title}
              {tab.badge ? (
                <span className="badge badge-panos ml-1">{tab.badge}</span>
              ) : null}
            </a>
          </li>
        ))}
    </ul>
  )
}
export default TabsComponent
