import React, { Component, ReactNode } from 'react'
import { SelectorList } from '../../SelectorList'
import { Document } from '../../../types/Document'
import { Button, Col, Form, Row } from 'react-bootstrap'

interface Props {
  selectionList: Document[]
  assignedDocument: Document
  setFormReference: (string) => void
  readOnlyMode?: boolean
}

interface State {
  selectedItem: string
  oneDocumentAlreadyAssigned: boolean
}

class ExternalDocumentSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      oneDocumentAlreadyAssigned: props.assignedDocument ? true : false,
      selectedItem: props.assignedDocument ? props.assignedDocument.id : null,
    }
  }

  onSelectedDocument = (id: string): void => {
    if (this.props.readOnlyMode !== true) {
      this.props.setFormReference(id)
      this.setState({
        selectedItem: id,
      })
    }
  }

  onResetAssignment = (): void => {
    if (this.props.readOnlyMode !== true) {
      this.props.setFormReference(null)

      this.setState({
        selectedItem: null,
      })
    }
  }

  render(): ReactNode {
    return (
      <div>
        <div>
          <Row className={'align-items-center'}>
            <Col xs={6}>
              <Form.Group controlId={'patient-data-findings-assignment'}>
                <Form.Label className={'text-muted'}>
                  Verknüpfung Fremdbefund
                </Form.Label>
                <div>
                  <SelectorList
                    menuPosition="fixed"
                    values={this.props.selectionList}
                    value={this.state.selectedItem}
                    disabled={this.state.oneDocumentAlreadyAssigned}
                    get={val => ({ value: val.id, label: val.documentName })}
                    onChange={value => this.onSelectedDocument(value)}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col xs={6} className="d-flex justify-content-center">
              <div>
                <Button
                  disabled={this.state.oneDocumentAlreadyAssigned}
                  onClick={this.onResetAssignment}>
                  Zuweisung aufheben
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default ExternalDocumentSelector
