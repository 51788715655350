import React from 'react'
import { Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import BigIcon from '../BigIcon/BigIcon'
import { SidebarButtonData } from './SidebarEntry'

interface SidebarButtonProps extends SidebarButtonData {
  active?: boolean
  colorTheme?: string
  onClick?: () => void
}

const SidebarButton: React.FC<SidebarButtonProps> = ({
  active,
  colorTheme,
  description,
  path,
  icon,
  notification,
  notifications,
  onClick,
}: SidebarButtonProps): JSX.Element => {
  let badge = null
  if (notification && notifications && notifications[notification]) {
    badge = notifications[notification]
  }
  const doOnClick = () => {
    if (onClick) onClick()
  }
  return (
    <li
      className={
        (active ? 'active' : '') +
        (colorTheme !== undefined ? ' hoverable theme-' + colorTheme : '')
      }>
      <Link
        to={path || ''}
        style={{ textDecoration: 'none' }}
        onClick={doOnClick}>
        <div className={'btn w-100 d-flex'}>
          {icon === undefined ? (
            <div className={'description iconless flex-grow-1 text-left'}>
              {description}
            </div>
          ) : (
            <div className={'flex-grow-1'}>
              <BigIcon icon={icon}>
                <span className={'description'}>{description}</span>
              </BigIcon>
            </div>
          )}
          {badge ? (
            <Badge className={'align-self-center'}>{badge}</Badge>
          ) : null}
        </div>
      </Link>
    </li>
  )
}

export default React.memo(SidebarButton)
