import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface Props {
  callback: () => void
  question: string
  confirmText?: string
  title?: string
  disabled?: boolean
}

interface State {
  show: boolean
}

export class ConfirmDialogue extends React.Component<Props, State> {
  state: State = {
    show: false,
  }
  handleClose = (): void => {
    this.setState({ show: false })
  }
  handleShow = (event: React.MouseEvent<HTMLInputElement>): void => {
    event.stopPropagation()
    this.setState({ show: true })
  }
  handleConfirm = (event: React.MouseEvent<HTMLInputElement>): void => {
    event.stopPropagation()

    this.handleClose()
    this.props.callback()
  }

  render = (): JSX.Element => {
    if (this.props.disabled)
      return <span onClick={this.props.callback}>{this.props.children}</span>
    return (
      <>
        <span onClick={this.handleShow}>{this.props.children}</span>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          onClick={event => event.stopPropagation()}>
          <Modal.Header closeButton>
            {this.props.title ? (
              <Modal.Title>{this.props.title}</Modal.Title>
            ) : null}
          </Modal.Header>
          <Modal.Body>{this.props.question}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Abbrechen
            </Button>
            <Button variant="primary" onClick={this.handleConfirm}>
              {this.props.confirmText || 'OK'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}
