export enum MedicationRequestStatus {
  // patient is taking the pill
  active = 'active',

  // While editing, a prescription is paused
  on_hold = 'on-hold',
  // alias because of underscore
  'on-hold' = 'on-hold',

  // Deleted, but stays in plan
  stopped = 'stopped',

  // deleted, will be excluded from plan
  cancelled = 'cancelled',

  // exists in past, is created, when editing a prescription, that only changes
  // the future of medication consumption
  completed = 'completed',

  // can be worked with freely
  draft = 'draft',
}
