/* eslint no-eval: 0 */

import React, { Component, ReactNode, RefObject } from 'react'
import { Parser } from 'acorn'
import DagreGraph from 'dagre-d3-react'
import { Modal } from 'react-bootstrap'
import MaterialIcon from '../UI/MaterialIcon/MaterialIcon'
import { FormDynamicComponentProps } from '../FormDynamic/FormDynamicComponent'
import {
  makeCheckBoxOptionNameRedcap,
  switchRedcapValueToCheckboxValue,
} from '../../utils/FormUtils'
import { FieldFunctionSymbolButton } from '../UI/FieldFunctionSymbolButton/FieldFunctionSymbolButton'
import { astToDiGraph } from './FieldFunctionPipingDebugGraph'
import { RedCapFieldQuote, RedCapFieldQuoteProps } from './RedCapFieldQuote'
import { FieldTypes } from '../../constants/FieldTypes'

export interface RedCapFieldReference {
  id: string
  date: number
  owner: string
  record: string
  formName: string
  repeatInstance: string
  arm: string
  event: string
  fieldName: string
  value: any
}

type RedCapFieldReferenceList = (
  | RedCapFieldReference
  | RedCapFieldReferenceObject
  | RedCapFieldReference[]
)[]

type RedCapFieldReferenceObject = Record<
  string,
  RedCapFieldReference | RedCapFieldReference[]
>

type RedCapFieldReferenceNest =
  | RedCapFieldReference
  | RedCapFieldReferenceObject
  | RedCapFieldReferenceList

function resolvePipingList(
  computedResult: RedCapFieldReferenceList,
  component: FormDynamicComponentProps,
  valueName: string
): RedCapFieldReference | RedCapFieldReferenceObject {
  const listResult = computedResult.reduce((result, it) => {
    if (result) return result

    const objectValue = recursiveResolveComputedValue(component, it, valueName)
    return objectValue[valueName]
  }, null)

  return listResult as RedCapFieldReference | RedCapFieldReferenceObject
}

export const resolvePipingRadioObject = (
  component: FormDynamicComponentProps,
  computedResult: RedCapFieldReferenceObject,
  valueName: string
): RedCapFieldReference => {
  const result = Object.entries(computedResult).map(
    ([radio_computed_key, radio_computed_value]) => {
      if (radio_computed_value != null)
        return recursiveResolveComputedValue(
          component,
          radio_computed_value,
          radio_computed_key
        )[radio_computed_key]
      else return null
    }
  )
  if (result) return result.find(x => x.value) as RedCapFieldReference
  else return null
}

export const recursiveResolveComputedValue = (
  component: FormDynamicComponentProps,
  computedResult: RedCapFieldReferenceNest,
  valueName: string
): {
  [valueName: string]: RedCapFieldReference | RedCapFieldReferenceObject
} => {
  if (!valueName) {
    valueName = component.name
  }

  if (computedResult === null || computedResult === undefined)
    return {
      [valueName]: computedResult as RedCapFieldReference,
    }
  switch (true) {
    case computedResult &&
      typeof (computedResult as RedCapFieldReference).value === 'number':
    case computedResult &&
      typeof (computedResult as RedCapFieldReference).value === 'string':
      return {
        [valueName]: computedResult as RedCapFieldReference,
      }
    case (computedResult as RedCapFieldReferenceList).length &&
      computedResult instanceof Array:
      return {
        [valueName]: resolvePipingList(
          computedResult as RedCapFieldReferenceList,
          component,
          valueName
        ),
      }
    case [FieldTypes.CHECKBOX].includes(component.field_type) &&
      computedResult instanceof Object:
      return {
        [component.name]: Object.fromEntries(
          Object.entries(computedResult).map(
            ([checkbox_number_key, checkbox_computed_value]) => [
              checkbox_number_key,
              recursiveResolveComputedValue(
                { ...component, field_type: FieldTypes.FAKE },
                checkbox_computed_value,
                checkbox_number_key
              )[checkbox_number_key],
            ]
          )
        ) as unknown as RedCapFieldReferenceObject,
      }
    case [FieldTypes.RADIO, FieldTypes.YESNO].includes(component.field_type) &&
      computedResult instanceof Object:
      return {
        [component.name]: resolvePipingRadioObject(
          component,
          computedResult as RedCapFieldReferenceObject,
          component.name
        ),
      }
    default:
      if (computedResult !== null)
        return {
          [component.name]: null,
        }
  }
}

export function fillWithNotChangedValues(
  allValues: Record<string, unknown>,
  mergeValues: Record<string, unknown>,
  component: FormDynamicComponentProps
): Record<string, unknown> {
  // does it really have only one value?
  const variableName = Object.keys(mergeValues).pop()
  const variable = allValues[variableName]
  if (component.field_type !== FieldTypes.CHECKBOX) {
    return Object.assign(allValues, mergeValues)
  }
  // checkbox
  const mergedValues = { [variableName]: {} }
  const mergeObject = mergeValues[variableName] as Record<string, unknown>
  for (const prop in mergeObject) {
    mergedValues[variableName][prop] = mergeObject[prop]
  }
  for (const prop in variable as Record<string, unknown>) {
    if (!Object.prototype.hasOwnProperty.call(mergedValues[variableName], prop))
      mergedValues[variableName][prop] = variable[prop]
  }
  return Object.assign(allValues, mergedValues)
}

export const chooseFromCheckBoxOption = (
  checkbox_name: string,
  checkbox_number_key: string,
  checkbox_computed_value: unknown
): (string | null)[] =>
  // This function computes a truth value for checkboxes from complex prioritized
  // lists of values:
  // 0 -> 0
  // 1 -> 1
  // [[0], null, [1]] -> 0
  // [[null], null, [1]] -> 1
  // null means to set not the value
  // 1 means to set the value
  // 0 means to unset the value
  //
  // return: two element list for the formikValues to be set of field key and field value
  {
    // If the checkbox option is null at all, then don't (de)-activate it
    if (checkbox_computed_value === null) return [null, null]
    let newValue = null
    // it is a list with either lists or numbers in it
    if (
      checkbox_computed_value instanceof Array &&
      checkbox_computed_value.length
    )
      newValue = checkbox_computed_value.reduce((result, it) => {
        if (result !== null && result !== undefined) return result
        if (it === null || it === undefined) return result
        // if the checkbox is a list of lists, meaning, one field unpacked
        // multiple repeat instances or events or arms, then we take the first
        // selected field
        if (it instanceof Array)
          return it.find(
            field =>
              field.value === '0' ||
              field.value === '1' ||
              field.value === 1 ||
              field.value === 0
          )
        // if it is simple, meaning there is only one element here
        // , we check, if the value is set at all
        // checked, then we set it to true, other way false
        else return it.value != null
      }, null)
    else newValue = checkbox_computed_value

    // choice is made, if we found something evaluable, return this as the value for the checkbox
    if (newValue !== null && newValue !== undefined && !isNaN(newValue)) {
      return [
        makeCheckBoxOptionNameRedcap(checkbox_name, {
          label: checkbox_number_key,
          value: checkbox_number_key,
        }),
        newValue,
      ]
      // other way we will ignore this field
    } else return [null, null]
  }

export const makeFunctionFromFunctionBody = (
  functionBodyString: string
): string => {
  const isConditional = functionBodyString.trimStart().startsWith('if')
  return `() => { ${
    isConditional ? '' : 'return'
  } ${functionBodyString.trimStart()} }`
}

export const processPipingExpression = (expression: string): any => {
  const functionString = makeFunctionFromFunctionBody(expression)
  const quoteResult = eval(functionString)()
  return quoteResult
}

interface FieldFunctionPipingState {
  show: boolean
  modalIsOpen: boolean
  links: any[]
  nodes: any[]

  formikValuesToSet: {
    [p: string]: RedCapFieldReference | RedCapFieldReferenceObject
  }
  functionString: string
  ast: any

  ghost?: any
}

interface FieldFunctionPipingProps {
  values?: any
  setValues?: (values: any, shouldValidate?: boolean) => void
  component: FormDynamicComponentProps
  containerRef?: RefObject<HTMLElement>
}

class FieldFunctionPiping extends Component<
  FieldFunctionPipingProps,
  FieldFunctionPipingState
> {
  /*
 the string in props.pipe contains a function body.
 the result of calling this function is the result of the pipe
 the AST, abstract syntax tree, we use for visualization of the
 come out of this value
 */
  ghostPrefix = 'piping-preview--'

  state = {
    show: false,
    modalIsOpen: false,

    nodes: [],
    links: [],

    formikValuesToSet: null,
    functionString: null,
    ast: null,
    ghost: null,
  }
  private container: HTMLDivElement

  componentDidMount(): void {
    if (
      !this.props.component.annotation ||
      !this.props.component.annotation.pipe
    )
      return

    const functionString = makeFunctionFromFunctionBody(
      this.props.component.annotation.pipe
    )
    let ast = null
    try {
      ast = Parser.parse(functionString, { ecmaVersion: 6 })
    } catch (e) {
      console.error(
        'Could not parse piping function for this',
        this.props,
        functionString,
        e
      )
      return
    }

    if (ast) {
      const computedResult = eval(functionString)()

      const formikValuesToSet = recursiveResolveComputedValue(
        this.props.component,
        computedResult,
        this.props.component.name
      )

      let quoteInfoToSet = null
      let ghostOptions = this.props.component.options

      const quoteValues = eval(functionString)()
      const chosenQuote = recursiveResolveComputedValue(
        this.props.component,
        quoteValues,
        this.props.component.name
      )[this.props.component.name] as RedCapFieldQuoteProps

      if (
        this.props.component.field_type === FieldTypes.CHECKBOX &&
        chosenQuote
      ) {
        ghostOptions = this.props.component.options.map(option => {
          if (!chosenQuote[option.value]) return option
          return {
            ...option,
            info: <RedCapFieldQuote {...chosenQuote[option.value]} />,
          }
        })
      } else {
        quoteInfoToSet = <RedCapFieldQuote {...chosenQuote} />
      }

      let ghostValues = null
      if (formikValuesToSet && formikValuesToSet[this.props.component.name]) {
        ghostValues = !(this.props.component.field_type === FieldTypes.CHECKBOX)
          ? formikValuesToSet[this.props.component.name]?.value
          : // Paste in original values to show that original values, if not
            // setting them via piping
            Object.fromEntries(
              Object.entries(formikValuesToSet[this.props.component.name]).map(
                ([fieldName, field]) => [
                  fieldName,
                  switchRedcapValueToCheckboxValue(field?.value),
                ]
              )
            )
      }
      const ghost = {
        ...this.props.component,
        name: this.ghostPrefix + this.props.component.name,
        options: ghostOptions,
        value: ghostValues,
        info: quoteInfoToSet,
        annotation: null,
      }

      this.setState({ formikValuesToSet, ast, functionString, ghost })
    }
  }

  computeGraph = (): void => {
    if (this.state.ast) {
      const { nodes, links } = astToDiGraph(
        this.state.ast.body[0].expression.body,
        this.state.functionString
      )
      this.setState({
        nodes: nodes,
        links: links,
      })
    }
  }

  handleShow = (): void => {
    this.computeGraph()
    this.setState({ show: !this.state.show })
  }
  closeModal = (): void => this.setState({ show: false, modalIsOpen: false })
  onTake = (): void => {
    if (
      this.state.formikValuesToSet &&
      this.state.formikValuesToSet[this.props.component.name]
    )
      if (this.props.component.field_type !== FieldTypes.CHECKBOX) {
        this.props.setValues(
          fillWithNotChangedValues(
            this.props.values,
            {
              [this.props.component.name]:
                this.state.formikValuesToSet[this.props.component.name].value,
            },
            this.props.component
          )
        )
      } else {
        this.props.setValues(
          fillWithNotChangedValues(
            this.props.values,
            Object.fromEntries(
              Object.entries(this.state.formikValuesToSet).map(
                ([fieldName, optionFields]) => [
                  fieldName,
                  Object.fromEntries(
                    Object.entries(optionFields).map(
                      ([checkboxOption, field]) => [
                        checkboxOption,
                        switchRedcapValueToCheckboxValue(field?.value),
                      ]
                    )
                  ),
                ]
              )
            ),
            this.props.component
          )
        )
      }
  }
  render(): ReactNode {
    if (
      !this.props.component.annotation ||
      !this.props.component.annotation.pipe ||
      !this.state.nodes ||
      !this.state.links
    )
      return null

    return (
      <div>
        <Modal
          className={' modal-lg custom-lg-modal'}
          show={this.state.show}
          onShow={() => this.setState({ modalIsOpen: true })}
          onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div>Vorwerte aus anderen Formularen (Piping)</div>
            </Modal.Title>
          </Modal.Header>
          {this.state.modalIsOpen && (
            <DagreGraph
              className="ast-graph"
              nodes={this.state.nodes}
              links={this.state.links}
              zoomable={true}
              config={{
                rankdir: 'LR',
                align: 'UL',
                ranker: 'network-simplex',
              }}
              width={(0.9 * window.innerWidth).toString()}
              height={(0.9 * window.innerHeight).toString()}
              animate={1000}
              shape="rect"
              fitBoundaries
            />
          )}
          <Modal.Footer>
            <button className={'btn btn-light'} onClick={this.closeModal}>
              <MaterialIcon icon="clear" verticalAlignment="middle" /> Schließen
            </button>
          </Modal.Footer>
        </Modal>

        <div>
          <FieldFunctionSymbolButton
            icon="low_priority"
            hoverTitle="Piping-Vorschau"
            component={this.state.ghost}
            containerRef={this.props.containerRef}
            name={`${this.props.component.name}_preview`}
            invert={true}
          />

          <FieldFunctionSymbolButton
            icon="done"
            onClick={this.onTake}
            containerRef={this.props.containerRef}
            name={`${this.props.component.name}_take`}
            invert={true}
          />

          <FieldFunctionSymbolButton
            icon="alt_route"
            onClick={this.handleShow}
            name={`${this.props.component.name}_debug`}
            invert={true}
          />
        </div>
      </div>
    )
  }
}
export default FieldFunctionPiping
