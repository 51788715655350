import React, { useEffect, useState } from 'react'
import PractitionerSelect from './PractitionerSelect'
import { Practitioner } from '../../../../types/Practitioner'
import PractitionerService from '../../../../services/PractitionerService'
import { APIResult } from '../../../../constants/APIResult'
import FormErrorMessage from '../FormError/FormErrorMessage'

interface FormPractitionerSelectProps {
  name: string
  onChange?: (value: string) => void
  onChangeEvent?: (event: React.ChangeEvent<any>) => void
  label?: string | JSX.Element
  required?: boolean
  value?: string | null
  className?: string
  disabled?: boolean
  error?: string
  touched?: boolean
}

/**
 * This Select is a mere copy of the other, with readying of practitioners from
 * redCAP with the special "simple ontology"-plugin, that inserts the first
 * column value as id of a uploaded xlsx-file. This id is an extension of the
 * practioner and needs only be read, when seeing a form, filled in redcap
 * and displayed in the platform. After saving it will use the normal practitioner
 * -form-select
 *
 * The id's in the fields on redcap differ on the qualification part of the id:
 *
 * plugin:     "828"
 *
 * our select: "Practitioner/1221"
 *
 */

const FormPractitionerXLSIDSelect: React.FC<FormPractitionerSelectProps> = ({
  name,
  value,
  onChangeEvent,
  onChange,
  className,
  error,
  label,
  touched,
  required,
  disabled,
}: FormPractitionerSelectProps) => {
  const inputValue = value !== null && value !== undefined ? value : null
  const [practitioner, setPractitioner] = useState<Practitioner>(null)

  useEffect(() => {
    const loadPractitioner = async () => {
      // For example practitioner_select 8 LANR-7470604
      if (
        inputValue &&
        inputValue.split(' ')[0] &&
        isNaN(Number(inputValue.split(' ')[0]))
      )
        console.error(
          'XLS import with inputValue containing other things as numbers'
        )

      const inputPractitionerResponse =
        await new PractitionerService().getPractitionerXLSID(
          inputValue.split(' ')[0]
        )

      if (
        inputPractitionerResponse.Result === APIResult.SUCCESS &&
        inputPractitionerResponse.Response
      )
        setPractitioner(inputPractitionerResponse.Response as Practitioner)
    }
    if (inputValue && !practitioner) {
      loadPractitioner()
    }
  }, [inputValue, practitioner])

  const onChangeHandler = (value: Practitioner): void => {
    if (onChangeEvent) {
      const event = {
        target: {
          name: name,
          value: 'Practitioner/' + value.id,
        },
        persist: () => {
          return
        },
      }
      onChangeEvent(event as React.ChangeEvent<any>)
    } else if (onChange) {
      onChange('Practitioner/' + value.id)
    }
  }
  const cssClasses = className ? [className] : []
  if (error) cssClasses.push('position-relative')
  if (error) cssClasses.push('validate-bad')

  return (
    <div className={`form-group ${cssClasses.join(' ')}`}>
      {label ? (
        <label className="form-label">
          {label}
          {required ? <span style={{ color: 'red' }}> *</span> : null}
        </label>
      ) : null}
      <PractitionerSelect value={practitioner} onChange={onChangeHandler} />
      <FormErrorMessage error={error} />
    </div>
  )
}

export default FormPractitionerXLSIDSelect
