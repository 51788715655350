import axios, { AxiosResponse } from 'axios'
import {
  API_FORWARDING_GET,
  API_FORWARDING_GET_RESOURCES,
  API_FORWARDING_GET_RESOURCES_BY_FILTER,
} from '../config/Paths'
import { getToken } from './Keycloak'

export function getActivityDefinitionById(
  activityDefinitionId: string
): Promise<AxiosResponse<any>> {
  const url = new URL(API_FORWARDING_GET)
  url.searchParams.append('resourceId', activityDefinitionId)

  return axios.get(url.toString(), {
    headers: { Authorization: `Bearer ` + getToken() },
  })
}

export function getActivityDefinitionByMedication(
  medicationId: string
): Promise<AxiosResponse<any>> {
  const filterParameter =
    'identifier=urn:panos-backend-medication:Id|' + medicationId

  const url = new URL(API_FORWARDING_GET_RESOURCES_BY_FILTER)
  url.searchParams.append('resourceType', 'ActivityDefinition')
  url.searchParams.append('filterParameter', filterParameter)
  return axios.get(url.toString(), {
    headers: {
      Authorization: `Bearer ` + getToken(),
    },
  })
}

export function getAllActivityDefinitions(): Promise<AxiosResponse<any>> {
  const url = new URL(API_FORWARDING_GET_RESOURCES)
  url.searchParams.append('resourceType', 'ActivityDefinition')

  return axios.get(url.toString(), {
    headers: { Authorization: `Bearer ` + getToken() },
  })
}
