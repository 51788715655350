import React, { useEffect, useState } from 'react'
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker'
import de from 'date-fns/locale/de'
import 'react-datepicker/dist/react-datepicker.css'
import {
  formatDate,
  formatRedcapDate,
  isValidDate,
  parseUnknownToDate,
} from '../../../../utils/Utils'
import './FormDatepicker.scss'
import moment from 'moment'
import FormErrorMessage from '../FormError/FormErrorMessage'

export type FormDatePickerTypes =
  | 'date'
  | 'birthdate'
  | 'datetime'
  | 'redcapdate'
interface FormDatepickerProps {
  name: string
  label?: string | JSX.Element
  onChange?: (value: string | Date) => void
  onChangeEvent?: (event: React.ChangeEvent<any>) => void
  placeholder?: string
  required?: boolean
  disabled?: boolean
  value?: string | Date
  className?: string
  type?: FormDatePickerTypes
  error?: string
  touched?: boolean
  minDate?: Date
  maxDate?: Date
  softDateLimit?: boolean //If true, maxDate is only a softlimit and allows to pick later dates
  minTime?: number
  maxTime?: number
}

/**
 * Use this component with or without formik context.
 *
 * Required props without Formik are:
 * -- name
 * -- value
 * -- onChange
 */

registerLocale('de', de)

const FormDatepicker: React.FC<FormDatepickerProps> = ({
  name,
  label,
  placeholder,
  required,
  disabled,
  value,
  className,
  type,
  error,
  minDate,
  maxDate,
  softDateLimit,
  minTime,
  maxTime,
  onChange,
  onChangeEvent,
}: FormDatepickerProps) => {
  const [outOfRangeError, setOutOfRangeError] = useState<boolean>(false)
  const [outOfRangeWarning, setOutOfRangeWarning] = useState<boolean>(false)
  /** maxDate needs to be stored locally for verificatiob */
  const [currentMaxDate, setMaxDate] = useState<Date>(null)
  const inputValue = value || ''

  const onDateChangeHandler = (value: Date): void => {
    onChangeHandler(value, true)
  }

  const onTimeChangeHandler = (value: Date): void => {
    console.log('onTimeChangeHandler')
    onChangeHandler(value, false)
  }

  const onChangeHandler = (value: Date, updateTime: boolean): void => {
    if (value && updateTime && minTime && value.getHours() === 0) {
      value.setHours(minTime)
    }
    if (softDateLimit) {
      if (value > currentMaxDate) {
        setOutOfRangeWarning(true)
      } else {
        setOutOfRangeWarning(false)
      }
    }

    if (onChangeEvent) {
      const event = {
        target: {
          name: name,
          value: value.toISOString(),
        },
      }
      //Temporary hack for RedCap special dateformat
      if (type === 'redcapdate') {
        event.target.value = formatRedcapDate(value)
      }
      onChangeEvent(event as React.ChangeEvent<any>)
    } else if (onChange) {
      if (type === 'redcapdate' && value) {
        const stringValue = formatRedcapDate(moment(value).toDate())
        onChange(stringValue)
        return
      } else {
        onChange(value)
        return
      }
    }
  }

  useEffect(() => {
    if (maxDate !== currentMaxDate) {
      setMaxDate(maxDate)
    }
  }, [maxDate, currentMaxDate])

  useEffect(() => {
    if (currentMaxDate && value && softDateLimit && value > currentMaxDate) {
      setOutOfRangeWarning(true)
    } else if (
      type === 'datetime' &&
      value &&
      value < moment.now().toString()
    ) {
      setOutOfRangeError(true)
    } else {
      setOutOfRangeWarning(false)
      setOutOfRangeError(false)
    }
  }, [currentMaxDate, value, type, softDateLimit])

  /**
   * Add additional className for each day
   */
  const today = new Date().setHours(0, 0, 0)
  const dayClassName = date => {
    if (currentMaxDate) {
      if (date > today) {
        if (date <= currentMaxDate && date >= today) {
          return 'text-success'
        } else {
          return 'text-warning'
        }
      }
    }
    return undefined
  }

  const timeClassName = time => {
    const hours = time.getHours()
    return softDateLimit &&
      ((minTime && hours < minTime) || (maxTime && hours > maxTime))
      ? 'text-warning'
      : undefined
  }

  let dateVal: Date

  if (isValidDate(inputValue)) {
    dateVal = new Date(inputValue)
  } else {
    dateVal = parseUnknownToDate(inputValue)
    if (!isValidDate(dateVal)) {
      dateVal = null
    }
  }

  const props: ReactDatePickerProps = {
    autoComplete: 'off',
    disabled,
    required,
    selected: dateVal,
    placeholderText: placeholder,
    onChange: onDateChangeHandler,
    dateFormat: 'dd.LL.yyyy',
    locale: de,
  }
  let dateTimeProps: ReactDatePickerProps

  if (minDate) props.minDate = minDate
  if (!softDateLimit && currentMaxDate) props.maxDate = currentMaxDate

  if (type === 'birthdate') {
    props.showYearDropdown = true
    props.showMonthDropdown = true
  } else if (type === 'datetime') {
    dateTimeProps = {
      ...props,
      showTimeSelect: true,
      showTimeSelectOnly: true,
      timeIntervals: 15,
      timeCaption: 'Time',
      dateFormat: 'HH:mm',
      onChange: onTimeChangeHandler,
    }
    if (!softDateLimit && minTime)
      dateTimeProps.minTime = new Date(new Date().setHours(minTime))
    if (!softDateLimit && maxTime)
      dateTimeProps.maxTime = new Date(new Date().setHours(maxTime))
  }

  const cssClasses = className ? [className] : []

  if (error) cssClasses.push('position-relative')
  if (error) cssClasses.push('validate-bad')

  return (
    <div className={`form-group ${cssClasses.join(' ')}`}>
      {label ? (
        <label className="form-label">
          {label}
          {required ? <span style={{ color: 'red' }}> *</span> : null}
        </label>
      ) : null}
      {type === 'datetime' ? (
        <div className="d-flex">
          <DatePicker
            id={name}
            className={`form-control ${error ? 'is-invalid' : ''}`}
            wrapperClassName={'w-50 mr-3'}
            popperPlacement={'bottom'}
            dayClassName={dayClassName}
            {...props}
          />
          {type === 'datetime' && (
            <DatePicker
              id={name}
              className={`form-control ${error ? 'is-invalid' : ''}`}
              wrapperClassName={'w-25'}
              popperPlacement={'bottom'}
              timeClassName={timeClassName}
              {...dateTimeProps}
            />
          )}
        </div>
      ) : (
        <DatePicker
          id={name}
          className={`form-control ${error ? 'is-invalid' : ''}`}
          wrapperClassName={'w-100'}
          popperPlacement={'bottom'}
          dayClassName={dayClassName}
          {...props}
        />
      )}
      {outOfRangeWarning ? (
        <small className="text-warning">
          Hinweis: Das Ausgewählte Datum befindet sich nach dem{' '}
          {formatDate(currentMaxDate)}
        </small>
      ) : null}
      {outOfRangeError ? (
        <small className="text-danger">
          Fehler: Der ausgewählte Zeitraum befindet sich in der Vergangenheit.
        </small>
      ) : null}
      <FormErrorMessage error={error} />
    </div>
  )
}

FormDatepicker.defaultProps = {
  type: 'date',
}

export default FormDatepicker
