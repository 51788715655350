import React, { Component } from 'react'
import { APIResult } from '../../../constants/APIResult'
import { Appointment } from '../../../types/Appointment'
import { NotificationManager } from 'react-notifications'
import AppointmentService from '../../../services/AppointmentService'
import Loader from '../../UI/Spinner/Loader'
import FormInput from '../../UI/Forms/FormInput/FormInput'
import { formatDateTime } from '../../../utils/Utils'
import { LinkList } from '../../UI/LinkList/LinkList'
import { AuthContext, withAuth } from '../../../infrastructure/AuthProvider'
import BigButton from '../../UI/BigButton/BigButton'
import { ParticipationStatus } from '../../../constants/ParticipationStatus'
import { ConfirmDialogue } from '../../UI/Dialogues/ConfirmDialogue'
import AppointmentForm from '../AppointmentForm/AppointmentForm'
import { getAppointmentPartiticipantStatus } from '../../../utils/CalendarUtils'
import AppointmentParticipants from '../AppointmentParticipants/AppointmentParticipants'
import FormTextarea from '../../UI/Forms/FormTextarea/FormTextarea'
import PriorityIcon from '../../UI/PriorityIcon'
import { AppointmentCategories } from '../../../constants/AppointmentCategories'
import { isPureAppointment } from '../../../utils/DraftUtils'
import { MedicalInstrument } from '../../Forms/FormInterfaces'
import {
  SubscriptionIdentifier,
  SubscriptionProps,
  SubscriptionType,
  withSubscriptions,
} from '../../../infrastructure/SubscriptionProvider'

interface Props extends AuthContext, SubscriptionProps {
  appointmentId: string
  onChanged?: () => void
  onClose: () => void
}

interface State {
  appointment: Appointment
  isEditing: boolean
}

class AppointmentDetail extends Component<Props, State> {
  state = {
    appointment: null,
    isEditing: false,
  }

  checkLinkedAppointmentsForUpdates(title) {
    if (
      Object.entries(this.state.appointment?.links || {}).find(
        ([key, linkParams]: [string, any[]]) =>
          key === 'RedCap' &&
          linkParams.find(
            linkParam =>
              linkParam.forms &&
              (linkParam.forms as MedicalInstrument[]).find(
                form => 'event-' + form.event_name === title
              )
          )
      )
    )
      this.fetchAppointment()
  }
  boundCheckLinkedAppointmentsForUpdates =
    this.checkLinkedAppointmentsForUpdates.bind(this)

  checkAppointmentForUpdates(apointmentId) {
    if (apointmentId === this.props.appointmentId) this.fetchAppointment()
  }
  boundCheckAppointmentForUpdates = this.checkAppointmentForUpdates.bind(this)

  componentDidMount() {
    this.fetchAppointment()

    const { subscribe } = this.props
    if (subscribe) {
      ;(async () => {
        await subscribe(
          SubscriptionType.DynamicSidebar,
          SubscriptionIdentifier.Appointment,
          this.boundCheckLinkedAppointmentsForUpdates
        )
        await subscribe(
          SubscriptionType.Calendar,
          SubscriptionIdentifier.Appointment,
          this.boundCheckAppointmentForUpdates
        )
      })()
    }
  }

  componentWillUnmount() {
    const { unsubscribe } = this.props
    if (unsubscribe) {
      ;(async () => {
        await unsubscribe(
          SubscriptionType.DynamicSidebar,
          SubscriptionIdentifier.Appointment,
          this.boundCheckLinkedAppointmentsForUpdates
        )
        await unsubscribe(
          SubscriptionType.Calendar,
          SubscriptionIdentifier.Appointment,
          this.boundCheckAppointmentForUpdates
        )
      })()
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState) {
    if (prevProps.appointmentId !== this.props.appointmentId) {
      this.fetchAppointment()
    }
    if (prevState.appointment !== this.state.appointment) {
      if (this.props.onChanged) this.props.onChanged()
    }
  }

  fetchAppointment = async () => {
    if (this.props.appointmentId) {
      const service = new AppointmentService()
      const res = await service.getAppointment(this.props.appointmentId)
      if (res.Response && res.Result === APIResult.SUCCESS) {
        const appointment = res.Response as Appointment
        await this.updateAppointment(appointment)
      } else {
        NotificationManager.error(
          'Fehler',
          'Termin konnte nicht geladen werden'
        )
      }
    }
  }

  /* Todo: Merge the next 2 methods */
  onAcceptAppointment = () => {
    const service = new AppointmentService()
    service
      .putAppointmentAcceptance(
        this.props.auth.panosId,
        this.state.appointment.id,
        'accepted'
      )
      .then(response => {
        if (response.Result === APIResult.SUCCESS && response.Response) {
          NotificationManager.success('Erfolg', 'Termin akzeptiert')
          this.fetchAppointment()
          this.props.onChanged()
        } else {
          NotificationManager.error(
            'Fehler: ' + JSON.stringify(response.Response)
          )
        }
      })
  }

  onDeclineAppointment = () => {
    const service = new AppointmentService()
    service
      .putAppointmentAcceptance(
        this.props.auth.panosId,
        this.state.appointment.id,

        'declined'
      )
      .then(response => {
        if (response.Result === APIResult.SUCCESS) {
          NotificationManager.success('Erfolg', 'Termin abgelehnt')
          this.fetchAppointment()
          this.props.onChanged()
        } else {
          NotificationManager.error(
            'Fehler: ' + JSON.stringify(response.Response)
          )
        }
      })
  }

  onDeleteAppointment = async (): Promise<void> => {
    const service = new AppointmentService()
    const response = await service.deleteAppointment(this.state.appointment)
    if (response.Result === APIResult.SUCCESS) {
      NotificationManager.success('Erfolg', 'Termin wurde gelöscht')
      this.props.onChanged()
      this.props.onClose()
    } else {
      NotificationManager.error('Fehler: ', 'Löschen nicht möglich')
      console.error('Error during deletion:', response.Response)
    }
  }
  /* Todo: Merge the next 2 methods END */

  updateAppointment = async (appointment: Appointment) => {
    this.setState({
      appointment: { ...appointment },
      isEditing: false,
    })
    this.props.onChanged()
  }

  /** Condition Methods */
  isAppointmentAcceptable = () => {
    const status = getAppointmentPartiticipantStatus(
      this.props.auth.panosId,
      this.state.appointment
    )
    return (
      status &&
      status !== ParticipationStatus.ACCEPTED &&
      isPureAppointment(this.state.appointment)
    )
  }

  isAppointmentDeclineable = () => {
    const status = getAppointmentPartiticipantStatus(
      this.props.auth.panosId,
      this.state.appointment
    )
    return (
      status &&
      status !== ParticipationStatus.DECLINED &&
      isPureAppointment(this.state.appointment)
    )
  }

  /** End Of Condition Methods */

  render = () => {
    if (!this.state.appointment) return <Loader />
    const { appointment } = this.state
    return (
      <>
        <div className="mb-1 d-flex align-items-center mb-2">
          <h6 style={{ maxWidth: '400px' }}>
            {appointment.title || 'Ohne Titel'}{' '}
            <PriorityIcon value={appointment.priority} />
          </h6>
        </div>
        {!this.state.isEditing && (
          <div className="d-flex justify-content-end">
            {this.isAppointmentAcceptable() ? (
              <BigButton
                icon="check_circle"
                onClick={() => this.onAcceptAppointment()}>
                Akzeptieren
              </BigButton>
            ) : null}
            {this.isAppointmentDeclineable() ? (
              <BigButton
                icon="highlight_off"
                onClick={() => this.onDeclineAppointment()}>
                Ablehnen
              </BigButton>
            ) : null}

            <BigButton
              icon="edit"
              onClick={() => this.setState({ isEditing: true })}>
              Bearbeiten
            </BigButton>

            <ConfirmDialogue
              callback={() => this.onDeleteAppointment()}
              title="Termin löschen"
              question="Möchten Sie den Termin tatsächlich löschen?">
              <BigButton icon="delete">Löschen</BigButton>
            </ConfirmDialogue>
          </div>
        )}
        {this.state.isEditing ? (
          <AppointmentForm
            appointment={this.state.appointment}
            isEditable={true}
            onChanged={this.updateAppointment}
            onClose={() => this.setState({ isEditing: false })}
            handleValueChange={(appointment: Appointment) =>
              this.setState({
                appointment: { ...appointment },
              })
            }
          />
        ) : (
          <>
            <FormInput
              name="startingDateTime"
              label="Beginn"
              value={formatDateTime(appointment.startingDateTime)}
              disabled
            />
            <FormInput
              name="minutesDuration"
              label="Dauer"
              value={appointment.minutesDuration}
              disabled
            />
            {appointment.location && (
              <FormInput
                name="location"
                label="Ort"
                value={appointment.location.name}
                disabled
              />
            )}
            {appointment.room && (
              <FormInput
                name="room"
                label="Raum"
                value={appointment.room}
                disabled
              />
            )}
            <FormInput
              name="category"
              label="Kategorie"
              value={appointment.category}
              disabled
            />
            {appointment.category === AppointmentCategories.PRIVATE &&
            appointment.shared ? (
              <span className="badge badge-primary">{'Freigegeben'}</span>
            ) : null}
            <AppointmentParticipants participants={appointment.participants} />
            <FormTextarea
              name="description"
              label="Notiz"
              disabled
              value={this.state.appointment.description}
            />
            <LinkList
              links={appointment.links}
              linkObject={{ appointment: appointment }}
            />
          </>
        )}
      </>
    )
  }
}

export default withAuth(withSubscriptions(AppointmentDetail))
