import React, { Component, ReactNode } from 'react'
import _ from 'lodash'
import { NotificationManager } from 'react-notifications'

import HistoryTable, { HistoryGroup } from './HistoryTable'
import { diff } from 'deep-diff'
import { CarePlan } from '../../../types/CarePlan'
import CarePlanService from '../../../services/CarePlanService'
import MedicationRequestService from '../../../services/MedicationRequestService'
import { APIResult } from '../../../constants/APIResult'
import { getIdPartFromFhirResourceId, pairwise } from '../../../utils/Utils'
import Loader from '../../UI/Spinner/Loader'
import { MedicationScheduleObject } from '../../../utils/MedicationScheduleUtils'
import { mapmap, nest, unique } from '../../../utils/NestUtils'
import { sortMedicationRequests } from '../../../utils/MedicationRequestUtils'

export const getUniqueMedicationRequestsFromCareplanVersions = (
  carePlans: CarePlan[][]
): string[] =>
  _.uniq(
    carePlans
      .flat()
      .reduce(
        (prevValue, currentValue) => [
          ...prevValue,
          ...currentValue.medicationRequests,
        ],
        []
      )
  )

export const compareMedicationRequests = (
  v1: MedicationRequestExtended,
  v2: MedicationRequestExtended
): MedicationRequestExtended => {
  if (v1) {
    v2.diff = diff(v1, v2, {
      prefilter: (path, key) =>
        [
          'id',
          'meta',
          'versionId',
          'meta',
          'diff',
          'idVersioned',
          'compared',
        ].includes(key),
    })
    v2.meta.interval = [v1.meta.lastUpdated, v2.meta.lastUpdated]
    v2.compared = v1
  }
  return v2
}

export const sortVersions = <T extends CarePlan | MedicationRequest>(
  fhirObjs: T[]
): T[] => {
  return fhirObjs.sort((a, b) =>
    a.meta.lastUpdated > b.meta.lastUpdated ? -1 : 1
  )
}

export const computeHistoricalMedicationGroups = (
  carePlans: CarePlan[],
  medicationRequests: MedicationRequest[][]
): HistoryGroup[] => {
  const medicationRequestDictionary = nest(medicationRequests.flat(), [
    'medicationReference.display',
  ]) as { [medicationId: string]: MedicationRequest[][] }

  const medicationRequestDictionarySorted = Object.fromEntries(
    Object.entries(medicationRequestDictionary).map(
      ([medicationId, medicationRequests]) => {
        const sortedMedicationRequests = unique(
          sortVersions(medicationRequests.flat()),
          'idVersioned'
        )
        const medicationRequestDiffs = [
          ...pairwise<MedicationRequest>(sortedMedicationRequests.reverse()),
        ]
          .map(([v1, v2]) => compareMedicationRequests(v1, v2))
          .reverse()
        return [medicationId, medicationRequestDiffs]
      }
    )
  )

  const sortedCarePlans = sortVersions(
    carePlans.flat()
  ) as unknown as CarePlan[]
  const diffMedicationRequestDictionary = nest(
    Object.values(medicationRequestDictionarySorted).flat(),
    ['idVersioned']
  )

  const olderCarePlansBefore = []
  return [...pairwise<CarePlan>(sortedCarePlans.reverse())]
    .map(([prev, current]) => {
      if (prev) olderCarePlansBefore.push(prev)
      return {
        group: current,
        data: sortMedicationRequests(
          unique(
            current.medicationRequests
              .map(id => diffMedicationRequestDictionary[id])
              .flat(),
            'id'
          )
        ),
        groupVersionBefore: prev,
        groupVersionsBefore: [...olderCarePlansBefore].reverse(),
      }
    })
    .reverse()
}

interface IProps {
  patientId: string
  carePlan: CarePlan
  setSchedule: (schedule: MedicationScheduleObject) => void
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

interface IState {
  groups: HistoryGroup[]
}

export const prepareHistoryItem = (fhirObj: Record<string, any>): any => {
  return {
    ...fhirObj,
    idVersioned: `${fhirObj.resourceType}/${fhirObj.id}/_history/${fhirObj.meta.versionId}`,
    meta: {
      ...fhirObj.meta,
      lastUpdated: new Date(fhirObj.meta.lastUpdated),
    },
  }
}

class MedicationHistory extends Component<IProps, IState> {
  carePlanService: CarePlanService
  medicationRequestService: MedicationRequestService

  state = {
    groups: null,
  }

  componentDidMount(): void {
    this.init()
  }

  init = async (): Promise<void> => {
    const { carePlans, medicationRequests } = await this.loadHistory()
    this.setState({
      ...this.state,
      groups: computeHistoricalMedicationGroups(
        carePlans.flat(),
        medicationRequests
      ),
    })
  }

  loadHistory = async (): Promise<{
    carePlans: CarePlan[][]
    medicationRequests: MedicationRequest[][]
  }> => {
    const carePlanResponse = await new CarePlanService(
      'Dummi'
    ).getActiveCarePlanHistory(this.props.patientId)
    if (
      carePlanResponse.Result !== APIResult.SUCCESS &&
      !carePlanResponse.isAborted
    )
      NotificationManager.error(
        'Fehler beim Laden der versionierten Medikamentenpläne'
      ) && console.log(carePlanResponse)

    let carePlanVersions = carePlanResponse.Response as CarePlan[][]

    const uniqueMedicationRequestIds =
      getUniqueMedicationRequestsFromCareplanVersions(carePlanVersions).map(
        it => getIdPartFromFhirResourceId(it)
      )

    const medicationRequestsResponse =
      await new MedicationRequestService().getMedicationRequestHistories(
        uniqueMedicationRequestIds
      )
    if (medicationRequestsResponse.Result !== APIResult.SUCCESS)
      NotificationManager.error('Fehler beim Laden der Verschreibungen') &&
        console.log(medicationRequestsResponse)

    let medicationRequestVersions =
      medicationRequestsResponse.Response as MedicationRequest[][]

    carePlanVersions = mapmap(carePlanVersions, prepareHistoryItem)
    medicationRequestVersions = mapmap(
      medicationRequestVersions,
      prepareHistoryItem
    )
    return {
      carePlans: carePlanVersions,
      medicationRequests: medicationRequestVersions,
    }
  }

  render(): ReactNode {
    return this.state.groups ? (
      <HistoryTable
        carePlan={this.props.carePlan}
        setSchedule={this.props.setSchedule}
        setShowModal={this.props.setShowModal}
        groups={this.state.groups}
        singleItems={[]}
        onClick={() => null}
      />
    ) : (
      <Loader />
    )
  }
}

export default MedicationHistory
