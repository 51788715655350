import React from 'react'
import { KeyboardArrowRight } from '@material-ui/icons'
import {
  Accordion as BootstrapAccordion,
  AccordionCollapse,
  AccordionContext,
  AccordionProps,
  AccordionToggleProps,
} from 'react-bootstrap'
import './VisualIndicatorAccordion.scss'

interface VisualIndicatorAccordionToggleProps extends AccordionToggleProps {
  variant?: string
  title?: any
}

const Toggle: React.FC<VisualIndicatorAccordionToggleProps> = ({
  children,
  ...otherProps
}: AccordionToggleProps) => {
  return (
    <BootstrapAccordion.Toggle {...otherProps}>
      <AccordionContext.Consumer>
        {currentEventKey => (
          <span
            className={
              'VisualIndicator' +
              (currentEventKey === otherProps.eventKey ? ' rotate90d' : '')
            }>
            <KeyboardArrowRight />
          </span>
        )}
      </AccordionContext.Consumer>{' '}
      {children}
    </BootstrapAccordion.Toggle>
  )
}

class Accordion extends React.Component<AccordionProps> {
  static Toggle = Toggle
  static Collapse = AccordionCollapse

  render(): JSX.Element {
    const { children, ...otherProps } = this.props
    return <BootstrapAccordion {...otherProps}>{children}</BootstrapAccordion>
  }
}

export default Accordion
