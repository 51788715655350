import React from 'react'
import { ParticipationStatus } from '../../../constants/ParticipationStatus'
import { AppointmentPerson } from '../../../types/AppointmentPerson'
import { getFullName } from '../../../utils/Utils'
import MaterialIcon from '../../UI/MaterialIcon/MaterialIcon'

interface AppointmentParticipantsProps {
  participants?: AppointmentPerson[]
  onDelete?: (participant: AppointmentPerson) => void
}
const AppointmentParticipants: React.FC<AppointmentParticipantsProps> = ({
  participants,
  onDelete,
}: AppointmentParticipantsProps) => {
  const getStatusClass = (status: ParticipationStatus) => {
    switch (status) {
      case ParticipationStatus.ACCEPTED:
        return 'text-success'
      case ParticipationStatus.DECLINED:
        return 'text-danger'
      default:
        return 'text-muted'
    }
  }

  return (
    <div className="form-group">
      <label>Teilnehmer</label>
      <ul className="list-group list-group-sm">
        {participants?.map(person => (
          <li
            className={`p-1 list-group-item d-flex align-items-center`}
            key={person.id}>
            <span
              className={`flex-grow-1 ${getStatusClass(
                person.participationStatus
              )}`}>
              {getFullName(person)}
            </span>
            {onDelete ? (
              <span className="cursor-pointer" onClick={() => onDelete(person)}>
                <MaterialIcon icon="delete" size="20px" />
              </span>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AppointmentParticipants
