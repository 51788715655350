import React, { Component } from 'react'
import AppointmentDetail from '../AppointmentDetail/AppointmentDetail'
import { RouteComponentProps, withRouter } from 'react-router'
import { Appointment } from '../../../types/Appointment'
import { AuthContext, withAuth } from '../../../infrastructure/AuthProvider'
import CalendarOverview from './CalendarOverview'
import { APIResult } from '../../../constants/APIResult'
import AppointmentService from '../../../services/AppointmentService'
import { AppointmentFilterParams } from '../../../types/AppointmentFilterParams'
import { NotificationManager } from 'react-notifications'
import AppointmentFilter from '../AppointmentFilter/AppointmentFilter'
import AppointmentForm from '../AppointmentForm/AppointmentForm'
import BigButton from '../../UI/BigButton/BigButton'
import { markDraftsAndAddDraftsOnly } from '../../../utils/DraftUtils'
import { ROUTE_CALENDAR_NEW } from '../../../config/Paths'
import {
  DynamicSidebarContent,
  DynamicSidebarContextAsProps,
  withDynamicSidebar,
} from '../../../infrastructure/DynamicSidebarProvider'
import { PanosInstitution } from '../../../constants/PanosInstitutions'
import {
  withSubscriptions,
  SubscriptionProps,
  SubscriptionIdentifier,
  SubscriptionType,
} from '../../../infrastructure/SubscriptionProvider'

interface State {
  selectedAppointment: Appointment //deprecated
  appointments: Appointment[]
  filterParams: AppointmentFilterParams
  additionalQuickfilters: PanosInstitution[]
  selectedDate?: Date
}

export enum AppointmentIconAction {
  needsPractitioner,
  hasDraft,
  isDraft,
}

type CalendarRouteId = {
  id: string
}

// Your component own properties
interface CalendarContainerProps
  extends RouteComponentProps<CalendarRouteId, unknown, unknown>,
    AuthContext,
    SubscriptionProps,
    DynamicSidebarContextAsProps {
  onReloadedAppointments?: (int) => void
}

class CalendarContainer extends Component<CalendarContainerProps, State> {
  onNotificationReceived = () => {
    this.fetchAppointments()
  }
  private boundOnNotificationReceived = this.onNotificationReceived.bind(this)
  state = {
    appointments: [],
    selectedAppointment: null,
    filterParams: null,
    additionalQuickfilters: [],
  }

  componentDidMount() {
    const newState: State = { ...this.state }
    const { subscribe } = this.props
    const filterParams = new AppointmentFilterParams()
    filterParams.userId = this.props.auth.panosId
    newState.filterParams = filterParams

    const { match } = this.props
    if (match.path === ROUTE_CALENDAR_NEW) {
      this.handleShowNew()
    } else if (match.params.id) {
      const appointment = new Appointment()
      this.showSelectedAppointment({
        ...appointment,
        id: match.params.id,
      })
    }

    if (subscribe) {
      ;(async () => {
        await subscribe(
          SubscriptionType.Notifications,
          SubscriptionIdentifier.Appointment,
          this.boundOnNotificationReceived
        )
        await subscribe(
          SubscriptionType.DynamicSidebar,
          SubscriptionIdentifier.Appointment,
          this.boundOnNotificationReceived
        )
        await subscribe(
          SubscriptionType.DynamicSidebar,
          SubscriptionIdentifier.Form,
          this.boundOnNotificationReceived
        )
      })()
    }

    this.setState(newState)
  }

  componentDidUpdate(_, prevState: State) {
    if (
      prevState.filterParams !== this.state.filterParams ||
      prevState.additionalQuickfilters !== this.state.additionalQuickfilters
    ) {
      this.fetchAppointments()
    }
  }

  componentWillUnmount() {
    const { unsubscribe } = this.props
    if (unsubscribe) {
      ;(async () => {
        await unsubscribe(
          SubscriptionType.Notifications,
          SubscriptionIdentifier.Appointment,
          this.boundOnNotificationReceived
        )
        await unsubscribe(
          SubscriptionType.DynamicSidebar,
          SubscriptionIdentifier.Appointment,
          this.boundOnNotificationReceived
        )
        await unsubscribe(
          SubscriptionType.DynamicSidebar,
          SubscriptionIdentifier.Form,
          this.boundOnNotificationReceived
        )
      })()
    }
    this.setState = (values: State) => {
      console.warn(
        '[CalendarContainer] State update after unmount. Slow request?',
        values
      )
    }
  }

  handleFilterUpdate = (filterParams: AppointmentFilterParams): void => {
    this.setState({ filterParams })
  }

  onDateSelected = (selectedDate: Date) => {
    console.log(
      'onDateSelected should show all dates in sidebar later',
      selectedDate
    )
  }

  onDateTimeSelected = (selectedDate: Date) => {
    console.log(
      'onDateTimeSelected should maybe do something similar',
      selectedDate
    )
  }

  fetchAppointments = async () => {
    const result = await new AppointmentService().getAppointments(
      this.state.filterParams,
      this.state.additionalQuickfilters
    )
    if (result && result.Result === APIResult.SUCCESS) {
      const appointments = result.Response as Appointment[]
      await markDraftsAndAddDraftsOnly(appointments, 'Appointment', () =>
        this.setState({ appointments })
      )
      if (this.props.onReloadedAppointments)
        this.props.onReloadedAppointments(appointments.length)
    } else {
      NotificationManager.error(
        'Fehler',
        'Termine konnten nicht geladen werden'
      )
    }
  }

  refreshAppointments = () => {
    this.fetchAppointments()
  }

  handleShowNew = () => {
    const { addContent, removeContent } = this.props.sidebarContext.handlers
    const content: DynamicSidebarContent = {
      identifier: 'appointment-new',
      subscriptionIdentifier: SubscriptionIdentifier.Appointment,
      headline: 'neuen Termin erstellen',
      maxWidth: 300,
      component: (
        <AppointmentForm
          onClose={() => {
            removeContent('appointment-new')
          }}
          onChanged={() => {
            removeContent('appointment-new')
          }}
        />
      ),
    }
    addContent(content)
  }

  showSelectedAppointment = (appointment: Appointment) => {
    const { addContent, removeContent, pushUpdate } =
      this.props.sidebarContext.handlers
    const identifier = 'appointment-' + appointment.id
    const content: DynamicSidebarContent = {
      identifier: identifier,
      subscriptionIdentifier: SubscriptionIdentifier.Appointment,
      maxWidth: 300,
      component: (
        <AppointmentDetail
          appointmentId={appointment.id}
          onChanged={() =>
            pushUpdate(identifier, SubscriptionIdentifier.Appointment)
          }
          onClose={() => {
            removeContent(identifier)
          }}
        />
      ),
    }
    addContent(content)
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-lg-10">
            <AppointmentFilter
              status={this.state.filterParams?.status || null}
              onSubmit={this.handleFilterUpdate}
              onQuickFilterChange={additionalQuickfilters =>
                this.setState({ additionalQuickfilters })
              }
            />
            <CalendarOverview
              appointments={this.state.appointments}
              onAppointmentSelected={this.showSelectedAppointment}
              onDateSelected={this.onDateSelected}
              onDateTimeSelected={this.onDateTimeSelected}
              refreshAppointments={this.refreshAppointments}
              isEditable={!this.state.selectedAppointment}
            />
          </div>
          <div className="col-lg-2 border-left pt-2">
            <BigButton icon="notifications" onClick={this.handleShowNew}>
              Termin erstellen
            </BigButton>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(
  withAuth(withDynamicSidebar(withSubscriptions(CalendarContainer)))
)
