import React, { cloneElement, ReactComponentElement } from 'react'

const DiffChangeClasses = {
  E: 'editChange',
  D: 'deleteChange',
  N: 'newChange',
  A: 'arrayChange',
}

const DiffSwitchNewDelete = {
  N: 'D',
  D: 'N',
  A: 'A',
  E: 'E',
}

export const Diffable = (props: {
  // What to use as outer Element
  wrapperElement?: ReactComponentElement<any>

  /* Either use "change" only or use "changes" and "index" or "last" key to
     identify the change by finding it in some array
   */

  // What was the change?
  change?: any

  // Changes for the object, whose property changed and needs to be looked for
  changes?: any[]
  index?: number
  lastKey?: string

  // Function applied to use format the values (value and changed value)
  formatFunction?: (any) => string

  // If it is an array, may be forced to use "index" for searching
  isArray?: boolean

  // Never display the changed value or search for it
  noComparand?: boolean

  // Actual value
  children: any
}): React.FunctionComponentElement<any> => {
  const change =
    props.change ??
    (props.changes &&
      (props.isArray
        ? props.changes.find(it => it.index === props.index)
        : props.changes.find(it => it.lastKey === props.lastKey)))

  const value = props.formatFunction
    ? props.formatFunction(props.children)
    : props.children

  const comparisonValue =
    !props.noComparand && change?.rhs
      ? props.formatFunction
        ? props.formatFunction(change.rhs)
        : change.rhs
      : null

  const kind =
    change?.kind === 'A' ? DiffSwitchNewDelete[change.item.kind] : change?.kind

  const wrapperElement = props.wrapperElement ?? <p></p>

  return cloneElement(
    wrapperElement,
    {
      ...wrapperElement.props,
      className: DiffChangeClasses[kind],
      key: props.index,
    },
    <>
      {!props.noComparand && comparisonValue && (
        <>
          {' '}
          <span className="deleteChange">{comparisonValue}</span>{' '}
          <span style={{ color: 'black' }}>⇾</span>{' '}
        </>
      )}
      {value}
    </>
  )
}
