import React, { useRef } from 'react'
import { DynamicSidebarContent } from '../../../infrastructure/DynamicSidebarProvider'
import { defaultMinSidebarWidth } from '../../../constants/DynamicSidebarConstants'

interface Props {
  content: DynamicSidebarContent
  onClose: (id: string) => void
}

const DynamicSidebarColumn: React.FC<Props> = ({ content, onClose }: Props) => {
  const ref = useRef()

  const handleClose = () => {
    onClose(content.identifier)
  }

  const minWidth =
    content.minWidth !== undefined ? content.minWidth : defaultMinSidebarWidth
  const width = content.initialWidth ? content.initialWidth : null

  return (
    <div
      className="DynamicSidebarColumn d-flex flex-column"
      key={content.identifier}
      style={{ minWidth: minWidth, width: width, maxWidth: content.maxWidth }}
      ref={ref}>
      <div className="DynamicSidebarChildrenHeader d-flex pb-1 mb-2 align-items-center border-bottom">
        <div className="flex-shrink-1 headline">{content.headline}</div>
        <button
          className="btn btn-light btn-sm ml-auto mr-0 flex-shrink-0"
          onClick={handleClose}>
          X
        </button>
      </div>
      <div className="DynamicSidebarChildren flex-grow-1">
        {content.component}
      </div>
    </div>
  )
}

export default DynamicSidebarColumn
