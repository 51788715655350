import { DraftIdInfo } from './DraftIdInfo'

export interface MinimalToBeDraft {
  tempId?: string
  id?: string
}

export interface RedCapDraft {
  context: any
  patientId?: string
}

export interface MinimalDraft {
  draftId: string
  date: Date
  value: string
}

export class Draft<T> extends DraftIdInfo {
  date: Date
  value: T // value, that will be stringified in the database
  title?: string
  selectedIndex?: number

  constructor(dto: MinimalDraft) {
    super(dto?.draftId)
    if (!dto || !dto?.draftId) {
      return undefined
    }
    this.value = JSON.parse(dto.value)
    this.date = dto.date
    return this
  }
}
