import React, { ChangeEvent } from 'react'
import { FormOption } from '../../../../types/FormOption'
import Slider, { Mark } from '@material-ui/core/Slider'
import FormErrorMessage from '../FormError/FormErrorMessage'

interface FormSliderProps {
  name: string
  label?: string | JSX.Element
  value?: number | null
  min?: number
  max?: number
  steps?: number
  displayLabel?: boolean
  options?: FormOption[]
  required?: boolean
  info?: any
  className?: string
  disabled?: boolean
  error?: string
  onChange?: (value: number) => void
  onChangeEvent?: (event: ChangeEvent<HTMLInputElement>) => void
}
/**
 * Use this component with or without formik cotnext.
 *
 * Required props are:
 * -- name
 * -- value
 * -- onChange
 */
const FormSlider: React.FC<FormSliderProps> = ({
  name,
  label,
  value,
  min,
  max,
  steps,
  options,
  required,
  info,
  className,
  displayLabel,
  disabled,
  error,
  onChange,
  onChangeEvent,
}: FormSliderProps) => {
  const inputValue =
    value !== null && value !== undefined && !isNaN(value)
      ? Number(value)
      : null

  const minValue = min ? min : 0
  const maxValue = max ? max : 100
  const marks = options
    ? (options.map(opt => {
        return { value: Number(opt.value), label: opt.label }
      }) as Mark[])
    : false

  const onChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    value: number
  ): void => {
    if (onChangeEvent) {
      const ev = {
        target: {
          name: name,
          value: value,
        },
      }
      onChangeEvent(ev as React.ChangeEvent<any>)
    } else if (onChange) {
      onChange(value)
    }
  }
  const cssClasses = className ? [className] : []
  if (error) cssClasses.push('position-relative')
  if (error) cssClasses.push('validate-bad')

  return (
    <div className={`form-group ${cssClasses.join(' ')}`}>
      {label ? (
        <label className="form-label">
          {label}
          {required ? <span style={{ color: 'red' }}> *</span> : null}
        </label>
      ) : null}
      <Slider
        name={name}
        value={inputValue}
        step={steps ? steps : null}
        min={minValue}
        max={maxValue}
        valueLabelDisplay={displayLabel ? 'on' : 'off'}
        marks={marks}
        disabled={disabled || null}
        onChange={onChangeHandler}
      />
      {info}
      <FormErrorMessage error={error} />
    </div>
  )
}

export default FormSlider
