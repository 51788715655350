import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ROUTE_LETTER_PREVIEW } from '../../config/Paths'
import { Letter } from '../../types/Letter'
import LetterService from '../../services/LetterService'
import { Task } from '../../types/Task'
import 'react-quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'

interface Props {
  editor: boolean
  letter?: Letter
  task?: Task
  templateUrl?: string
  createUrl?: string
}

interface State {
  letter: Letter
}

class LetterEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      letter: null,
    }
  }

  componentDidMount(): void {
    if (!this.props.letter) {
      this.loadLetter()
    }
  }

  loadLetter(): void {
    const letters = () => {
      const letterService = new LetterService()
      Promise.all([
        letterService.getLetterFromTask(
          this.props.task,
          this.props.templateUrl
        ),
        letterService.loadLetterContent(this.props.templateUrl),
      ]).then(results => {
        const letter = results[0]
        const content = results[1]

        this.setState({
          letter: { ...letter, content: content },
        })

        return { ...letter, content: content }
      })
    }

    letters()
  }

  updateContent = (editor: Record<string, any>): void => {
    this.setState({
      letter: {
        ...this.state.letter,
        content: editor.getHTML(),
      },
    })
  }

  render(): JSX.Element {
    return (
      <>
        {this.props.editor ? (
          <>
            {' '}
            Inhalt des Schreibens
            <ReactQuill
              value={this.state.letter?.content}
              defaultValue={this.state.letter?.content}
              onChange={(b, l, a, editor) => this.updateContent(editor)}
            />
            <Link
              className="btn btn-primary"
              to={{
                pathname: ROUTE_LETTER_PREVIEW,
                state: {
                  letter: this.state.letter,
                  createRoute: this.props.createUrl,
                },
              }}>
              Vorschau
            </Link>
          </>
        ) : null}
      </>
    )
  }
}

export default LetterEditor
