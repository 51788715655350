import * as React from 'react'
import { Component } from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import { addWeeks, isValidDate, parseUnknownToDate } from '../../../utils/Utils'
import moment from 'moment'
import FormErrorMessage from '../../UI/Forms/FormError/FormErrorMessage'

interface Props {
  onChange: (newDate: Date) => void
  value?: Date
  disabled?: boolean
  error?: string
}

interface State {
  date: Date
  week: number
  radioOptions: Array<number>
  option: number
}

const radioOptions = [1, 2, 3, 4]

class DeadlineSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    let value = this.props.value

    if (this.props.value && !isValidDate(this.props.value)) {
      value = parseUnknownToDate(this.props.value as unknown as string)
    }

    const weekDifference = this.getWeekDifferenceOfDate(value)

    this.state = {
      date: value,
      week: null,
      radioOptions: radioOptions,
      option: weekDifference,
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const { value } = this.props
    if (value !== prevProps.value && value !== this.state.date) {
      let date = value
      if (date !== null && !isValidDate(date)) {
        date = parseUnknownToDate(date as unknown as string)
      }

      const weekDifference = this.getWeekDifferenceOfDate(date)
      this.setState({
        date,
        option: weekDifference,
      })
    }
    if (this.state.date !== prevState.date) {
      this.props.onChange(this.state.date)
    }
  }

  getWeekDifferenceOfDate = (date: Date): number => {
    if (!date) {
      return null
    }

    const currentDate = new Date()
    const targetDate = date
    if (targetDate) {
      const difference = moment(targetDate)
        .startOf('day')
        .diff(moment(currentDate).startOf('day'), 'days')

      if (difference % 7 === 0) {
        const weeks = difference / 7
        if (weeks > 0 && weeks <= radioOptions.length) {
          return weeks
        }
      }
    }

    return null
  }

  onSetWeeks = (i: number): void => {
    const date = addWeeks(i, new Date())
    this.setState({
      option: i,
      date,
    })
  }

  onPickerSet = (date: Date): void => {
    if (date) {
      const currentDate = new Date()
      date.setHours(
        currentDate.getHours(),
        currentDate.getMinutes(),
        currentDate.getSeconds(),
        currentDate.getMilliseconds()
      )
    }

    const weekDifference = this.getWeekDifferenceOfDate(date)
    this.setState({
      option: weekDifference,
    })

    this.setState({ option: weekDifference, date })
  }

  render(): JSX.Element {
    const checkBoxes = this.state.radioOptions.map(week => (
      <div className="custom-control custom-radio  mr-1" key={week}>
        <input
          id={`${week}week`}
          onChange={() => this.onSetWeeks(week)}
          type="radio"
          value={week}
          name="deadline"
          className="custom-control-input"
          disabled={this.props.disabled}
          checked={week === this.state.option}
        />
        <label className="custom-control-label" htmlFor={`${week}week`}>
          {week}
        </label>
      </div>
    ))

    return (
      <div className="row">
        <div className="col-12 custom-control-inline">
          {checkBoxes} <label>Wochen</label>
        </div>
        <div className="col-12">
          <DatePicker
            className={`form-control d-block" ${
              this.props.error ? 'is-invalid' : ''
            }`}
            disabled={this.props.disabled}
            selected={this.state.date}
            onChange={this.onPickerSet}
          />
          <FormErrorMessage error={this.props.error} />
        </div>
      </div>
    )
  }
}

export default DeadlineSelector
