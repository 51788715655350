import React, { useCallback, useContext, useEffect, useState } from 'react'
import { fetchCarePlan } from '../Medication/MedicationSchedule/MedicationSchedule'
import FormError from '../UI/Forms/FormError/FormError'
import { withEvents } from '../../infrastructure/EventProvider'
import { FormDynamicComponentProps } from '../FormDynamic/FormDynamicComponent'
import ComposeCarePlanModal from '../Medication/MedicationTemplatePlans/ComposeCarePlanModal'
import { defaultCarePlan } from '../../utils/CarePlanUtils'
import { CarePlanStatus } from '../../constants/CarePlanStatus'
import { Button } from 'react-bootstrap'
import { CarePlan } from '../../types/CarePlan'
import { MedicationReason } from '../Medication/MedicationSchedule/MedicationReason'
import { priorityChoice } from '../../utils/NestUtils'
import MaterialIcon from '../UI/MaterialIcon/MaterialIcon'
import { DecideDialogue } from '../UI/Dialogues/DecideDialogue'
import CarePlanService from '../../services/CarePlanService'
import { APIResult } from '../../constants/APIResult'
import { NotificationManager } from 'react-notifications'
import {
  addResourceTypeIfNot,
  formatDateTime,
  getIdPartFromFhirResourceId,
} from '../../utils/Utils'
import { AuthContext, withAuth } from '../../infrastructure/AuthProvider'
import LocalStorageService from '../../services/LocalStorageService'
import { DynamicSidebarContext } from '../../infrastructure/DynamicSidebarProvider'
import { getMedicationRequestsByCarePlan } from '../../services/MedicationRequestService'
import { EventStore } from 'src/infrastructure/EventProviderInterfaces'
import { noWriteErrorMessage } from '../../utils/FormUtils'
import { TaskCategories } from '../../constants/TaskCategories'
import { activateMedicationRequestsBeforeReLogin } from '../Medication/ReloginOrTask'

// this specifies, which kind of medication plan should be merged in another
// by updating the medication requests in there
const MEDICATION_IMPORT_ORDER = [
  null,
  MedicationReason.ANAMNESE,
  MedicationReason.ORDER,
]
export const FORM_MEDICATION_UPDATE_STORAGE = 'FORM_MEDICATION_UPDATE_STORAGE'

export interface MedicationUpdates {
  carePlan: CarePlan
  medicationRequests: MedicationRequest[]
}

export const reloginActivateMedicationRequests = async (
  authContext: AuthContext,
  sidebarContext: DynamicSidebarContext,
  medicationUpdates: { [reason: string]: MedicationUpdates },
  patientId: string
): Promise<void> => {
  const finalMedicationUpdate =
    medicationUpdates[
      MEDICATION_IMPORT_ORDER[MEDICATION_IMPORT_ORDER.length - 1]
    ]
  if (finalMedicationUpdate) {
    const localStorage = new LocalStorageService<MedicationUpdates>(
      FORM_MEDICATION_UPDATE_STORAGE
    )
    const task = {
      title: 'Medikamentenverschreibung prüfen',
      category: TaskCategories.INDICATION_CHECK,
      ownerDto: authContext.auth.user,
    }
    await activateMedicationRequestsBeforeReLogin(
      null,
      finalMedicationUpdate.medicationRequests,
      MEDICATION_IMPORT_ORDER[MEDICATION_IMPORT_ORDER.length - 1],
      finalMedicationUpdate.carePlan,
      sidebarContext,
      authContext,
      patientId,
      localStorage,
      task,
      true
    )
  }
}

const FieldExternalTool: React.FC<FormDynamicComponentProps> = (
  props: FormDynamicComponentProps
) => {
  const context = useContext(EventStore)
  const reason = props.annotation?.reason
  const [show, setShow] = useState(false)
  const [carePlan, setCarePlan] = useState<CarePlan>(null)
  const [sourceCarePlan, setSourceCarePlan] = useState<CarePlan>(null)

  const registerMedicationUpdate = useCallback(
    (newCarePlan, medicationRequests) => {
      const medicationUpdate = {
        carePlan: newCarePlan,
        medicationRequests,
      }
      context.registerMedicationUpdates(reason, medicationUpdate)
    },
    [context, reason]
  )

  const onSync = useCallback(
    newCarePlan => {
      const makeCarePlanBranch = async () => {
        const carePlanBranch = await new CarePlanService(reason).postCarePlan({
          ...newCarePlan,
          title: reason,
          id: null,
          status: CarePlanStatus.on_hold,
        })
        if (carePlanBranch.Result !== APIResult.SUCCESS) {
          NotificationManager.error('Übernahme von Medikationsplan gescheitert')
          return
        }

        setCarePlan(carePlanBranch.Response as CarePlan)
        setShow(true)
      }

      makeCarePlanBranch()
    },
    [reason]
  )

  useEffect(() => {
    const f = async () => {
      let newCarePlan

      try {
        if (
          context &&
          context.medicationUpdates &&
          Object.keys(context.medicationUpdates).length > 0
        ) {
          const choice = priorityChoice(
            context.medicationUpdates,
            MEDICATION_IMPORT_ORDER,
            reason
          )
          newCarePlan = choice ? choice[1].carePlan : null
        }
        if (!newCarePlan)
          newCarePlan = await fetchCarePlan(reason, context.event.patientId)

        setSourceCarePlan(newCarePlan)

        if (context.values[props.name]) {
          const response = await new CarePlanService(reason).getCarePlan(
            context.values[props.name] as string
          )
          newCarePlan =
            response.Result === APIResult.SUCCESS
              ? (response.Response as unknown as CarePlan)
              : null
          setCarePlan(newCarePlan)
          const medicationRequestResponse =
            await getMedicationRequestsByCarePlan(newCarePlan.id)
          const medicationRequests =
            medicationRequestResponse.data as MedicationRequest[]
          registerMedicationUpdate(newCarePlan, medicationRequests)
        }

        if (!newCarePlan) {
          newCarePlan = defaultCarePlan(
            context.event.patientId,
            [],
            reason,
            CarePlanStatus.on_hold
          )
          onSync(newCarePlan)
        }
      } catch (e) {
        console.log('error on finding correct careplan to import from', e)
      }
    }
    if (!sourceCarePlan) f()
  }, [
    reason,
    context.medicationUpdates,
    context.event.patientId,
    onSync,
    context,
    sourceCarePlan,
    props.name,
    registerMedicationUpdate,
  ])

  const onSubmit = useCallback(
    (carePlanDescription, medicationRequests) => {
      if (props.readOnlyMode === true) {
        noWriteErrorMessage()
        return
      }
      const newCarePlan: CarePlan = {
        ...carePlanDescription.plan,
        id: 'CarePlan/' + carePlanDescription.plan.id,
        medicationRequests: carePlanDescription.plan.medicationRequests.map(
          it => getIdPartFromFhirResourceId(it)
        ),
        status: CarePlanStatus.on_hold,
      }

      context.setFieldValue(props.name, carePlanDescription.plan.id)
      setCarePlan(newCarePlan)

      registerMedicationUpdate(newCarePlan, medicationRequests)
    },
    [context, props.name, props.readOnlyMode, registerMedicationUpdate]
  )

  switch (props.annotation.tool) {
    case 'MedicationSchedule':
      return (
        <>
          <DecideDialogue
            disabled={!sourceCarePlan}
            callback1={() => {
              onSync(sourceCarePlan)
            }}
            callback2={() => {
              if (context.medicationUpdates[reason])
                setCarePlan(context.medicationUpdates[reason].carePlan)
              setShow(true)
            }}
            disabled2={!context.medicationUpdates[reason]}
            text1="Neu übernehmen"
            text2="Weiter bearbeiten"
            title="Medikamentenplan übernehmen"
            question={`
              Möchten Sie den Medikamentenplan von ${sourceCarePlan?.title} nach ${reason} übernehmen?
              Beachten Sie: In ${reason} vorgenommene Änderungen gehen verloren!`}>
            <Button className="btn btn-link btn-sm" key={props.name}>
              <MaterialIcon icon="party_mode" />
              Medikamentenplan für {reason} editieren
            </Button>
          </DecideDialogue>

          {show && carePlan && (
            <ComposeCarePlanModal
              carePlan={{
                ...carePlan,
                title: reason + ' — ' + formatDateTime(new Date()),
                medicationRequests: carePlan.medicationRequests.map(it =>
                  addResourceTypeIfNot(
                    getIdPartFromFhirResourceId(it),
                    'MedicationRequest'
                  )
                ),
              }}
              reason={reason}
              onSubmit={onSubmit}
              patientId={context.event.patientId.replace('Patient/', '')}
              enableEditCarePlanTitle={false}
              checkCarePlanTitle={() => true}
              onHide={() => setShow(false)}
            />
          )}
        </>
      )
    default:
      return (
        <FormError>Diese externe Toolkomponente ist nicht vorhanden.</FormError>
      )
  }
}

export default withAuth(withEvents(FieldExternalTool))
