import { TrackingLocation } from '../types/TrackingLocation'
import {
  API_EXPORT_TRACKING_LOCATION,
  API_TRACKING_LOCATION,
} from '../config/Paths'
import { ApiError } from '../types/ApiError'
import { ApiResponse } from '../types/ApiResponse'
import ApiServiceBase from './ApiServiceBase'
import axios, { AxiosResponse } from 'axios'
import { getToken } from './Keycloak'

export class TrackingService extends ApiServiceBase {
  postLocations(
    locations: TrackingLocation[]
  ): Promise<ApiResponse<string | ApiError>> {
    return this.post<Record<string, any>, string>(
      new URL(API_TRACKING_LOCATION),
      null,
      locations
    )
  }

  async exportTrackingLocationsCSV(): Promise<AxiosResponse> {
    return await axios.get(API_EXPORT_TRACKING_LOCATION, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
  }
}

export default TrackingService
