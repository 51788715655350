import React, { Component } from 'react'
import PanosLogo from '../../../assets/logo-transparent.png'
import './Sidebar.scss'
import { NotificationTypes, SIDEBAR_ENTRIES } from '../../../config/Constants'
import { AuthHandlers, withAuth } from '../../../infrastructure/AuthProvider'
import SidebarEntry from './SidebarEntry'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import IAuth from '../../../types/Auth'
import SidebarButton from './SidebarButton'
import { APIResult } from '../../../constants/APIResult'
import BigIcon from '../BigIcon/BigIcon'
import UserService from '../../../services/UserService'
import { UserPermission } from '../../../constants/UserPermission'

interface Props {
  auth: IAuth
  handlers: AuthHandlers
  additionalClasses?: string
}

interface State {
  collapsed: boolean
  notifications: Record<NotificationTypes, number>
}

class Sidebar extends Component<Props, State> {
  state = {
    collapsed: false,
    notifications: null,
  }
  timeout: ReturnType<typeof setTimeout> = null

  componentDidMount() {
    this.getNotifications()
  }

  componentDidUpdate(_, prevState) {
    if (prevState.notifications !== this.state.notifications) {
      this.timeout = setTimeout(() => this.getNotifications(), 1000 * 30)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  getNotifications = async () => {
    if (this.props.handlers.hasPermission(UserPermission.PATIENT_SHOW)) {
      const notifications = { ...this.state.notifications }
      const userService = new UserService()
      const notificationsResponse = await userService.getNotifications()
      let didNotificationsChange = false
      if (notificationsResponse.Result === APIResult.SUCCESS) {
        const newNotifications = notificationsResponse.Response as Record<
          string,
          number
        >
        for (const key in newNotifications) {
          if (notifications[key] !== newNotifications[key]) {
            didNotificationsChange = true
            notifications[key] = newNotifications[key]
          }
        }
      }
      if (didNotificationsChange) {
        this.setState({ notifications })
      }
    }
  }

  render() {
    const hasPermission = this.props.handlers.hasPermission
    return (
      <nav
        className={
          'Sidebar ' +
          (this.state.collapsed ? ' collapsed' : '') +
          (this.props.additionalClasses
            ? ' ' + this.props.additionalClasses
            : '')
        }
        style={{ position: 'relative' }}>
        <div className="sidebar-fixed-container d-flex flex-column">
          <div className={'sidebar-header d-flex'}>
            <div className={'logo flex-grow-1'}>
              <img
                src={PanosLogo}
                alt="Panos Logo"
                className="img-responsive"
                style={{ maxHeight: '44px' }}
              />
            </div>

            <button
              className={'btn'}
              onClick={() =>
                this.setState({ collapsed: !this.state.collapsed })
              }>
              <MaterialIcon icon={'keyboard_tab'} />
            </button>
          </div>

          <ul className={'list-unstyled components'}>
            {SIDEBAR_ENTRIES.filter(
              entry => entry.description !== 'Profil'
            ).map(entry => {
              if (!hasPermission(entry.permissision)) return null
              return (
                <SidebarEntry
                  sidebarItem={entry}
                  key={'menu-button-' + entry.description}
                  notifications={this.state.notifications}
                />
              )
            })}
          </ul>
          <ul className={'list-unstyled components mt-auto'}>
            {SIDEBAR_ENTRIES.filter(
              entry => entry.description === 'Profil'
            ).map(entry => {
              if (!hasPermission(entry.permissision)) return null
              return (
                <SidebarButton
                  {...entry}
                  key={'menu-button-' + entry.description}
                />
              )
            })}
            <li className={'hoverable theme-default'}>
              <button
                className={'btn w-100 d-flex'}
                onClick={this.props.handlers.doLogout}>
                <div className={'flex-grow-1'}>
                  <BigIcon icon={'logout'}>
                    <span className={'description'}>Logout</span>
                  </BigIcon>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

export default withAuth(Sidebar)
