import React, { Component, ReactElement } from 'react'

export enum PanosLayout {
  default = 'default',
  print = 'print',
}
export const LayoutStore = React.createContext<LayoutContext>({
  layout: PanosLayout.default,
  fixMainColumn: false,
  handlers: null,
})

export interface LayoutContext {
  layout: PanosLayout
  fixMainColumn?: boolean
  handlers?: LayoutHandler | null
}

export interface LayoutProperties {
  layout?: PanosLayout
  fixMainColumn?: boolean
}

export interface LayoutHandler {
  setLayout: (layout: PanosLayout) => void
  setValue: (values: LayoutProperties) => void
}

export default class LayoutProvider extends Component<
  Record<string, unknown>,
  LayoutContext
> {
  state: LayoutContext = {
    layout: PanosLayout.default,
    fixMainColumn: false,
  }

  componentDidUpdate(_: LayoutProperties, prevState: LayoutContext): void {
    if (prevState.layout !== this.state.layout) {
      if (this.state.layout === PanosLayout.print) {
        document.body.classList.add('printable')
      } else {
        document.body.classList.remove('printable')
      }
    }
  }

  setLayout = (layout: PanosLayout): void => {
    this.setState({ layout: layout })
  }

  setValue = (values: LayoutProperties): void => {
    console.log('setValue', values)
    this.setState({ ...this.state, ...values })
  }

  handlers: LayoutHandler = {
    setLayout: this.setLayout,
    setValue: this.setValue,
  }

  render(): ReactElement {
    return (
      <LayoutStore.Provider value={{ ...this.state, handlers: this.handlers }}>
        {this.props.children}
      </LayoutStore.Provider>
    )
  }
}
