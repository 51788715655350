import { formatTime, parseUnknownToDate } from '../../utils/Utils'
import React from 'react'

export function PushMessage(props: { message: any }): JSX.Element {
  return (
    <div
      className={'notification-iconspace notification-' + props.message.kind}>
      <p className={'notification-time'}>
        {props.message.time
          ? formatTime(parseUnknownToDate(props.message.time))
          : null}
      </p>

      {props.message.title ? <b>{props.message.title}</b> : null}
      {props.message.subtitle ? (
        <span className={'notification-content'}>
          <br /> {props.message.subtitle}
        </span>
      ) : null}

      <p className={'notification-content'}>
        {props.message.content ? props.message.content : null}
      </p>
      {/*message.links ? (
            <LinkList
              links={message.links}
              getFormValue={() => 'not defined'}
            />
          ) : null */}
    </div>
  )
}
