import React from 'react'
import { useFormikContext } from 'formik'
import FormInput from '../FormInput/FormInput'
import FormSelect from '../FormSelect/FormSelect'
import FormCheckbox from '../FormCheckbox/FormCheckbox'
import FormCheckboxGroup from '../FormCheckbox/FormCheckboxGroup'
import FormDatepicker, {
  FormDatePickerTypes,
} from '../FormDatepicker/FormDatepicker'
import { FormOption } from '../../../../types/FormOption'
import FormTextarea from '..//FormTextarea/FormTextarea'
import FormRadio from '../FormRadio/FormRadio'
import FormPractitionerSelect from '../PractitionerSelect/FormPractitionerSelect'
import FormPractitionerXLSIDSelect from '../PractitionerSelect/FormPractitionerXLSIDSelect'
import FormSlider from '../FormSlider/FormSlider'
import {
  AnnotationRedcap,
  FormDynamicComponentProps,
} from '../../../FormDynamic/FormDynamicComponent'
import FormInputHidden from '../FormHidden/FormInputHidden'
import FormIcd10Select from '../ICD10Select/FormICD10Select'

export enum FormControlFormikType {
  TEXT = 'text',
  SELECT = 'select',
  PRACTITIONER_SELECT = 'practitioner_select',
  ICD10_SELECT = 'icd10_select',

  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  CHECKBOX_GROUP = 'checkboxgroup',
  DATEPICKER = 'datepicker',
  TEXTAREA = 'textarea',
  SLIDER = 'slider',
  HIDDEN = 'hidden',
}

interface FormControlsFormikProps {
  name: string
  controlType: FormControlFormikType
  label?: string | JSX.Element
  handleChange?: {
    (
      e: React.ChangeEvent<any>,
      component: FormDynamicComponentProps,
      isDefault: boolean
    ): void
    <T = string | React.ChangeEvent<any>>(
      field: T,
      component: FormDynamicComponentProps,
      isDefault: boolean
    ): T extends React.ChangeEvent<any>
      ? void
      : (
          e: string | React.ChangeEvent<any>,
          component: FormDynamicComponentProps,
          isDefault: boolean
        ) => void
  }
  placeholder?: string
  required?: boolean
  value?: string | null | any
  className?: string
  pattern?: string
  maxLength?: number //for input and textarea
  minLength?: number //for input and textarea
  disabled?: boolean
  error?: string
  type?: string | FormDatePickerTypes // string only FormInput | FormDatePickerTypes only Datepicker
  options?: FormOption[] // only FormSelect / FormCheckboxGroup / Radio / Slider
  valueText?: string // only FormCheckbox
  annotation?: AnnotationRedcap
}
/**
 * Use this component with or without formik context.
 *
 * Required props without Formik are:
 * -- name
 * -- value
 * -- onChange
 */
const FormControlsFormik: React.FC<FormControlsFormikProps> = ({
  controlType,
  ...props
}: FormControlsFormikProps) => {
  const context = useFormikContext()

  const name = props.name
  const handleChange = (e: any) => {
    if (props.handleChange) {
      props.handleChange(e, null, null)
    } else {
      context.handleChange(e)
      context.setFieldTouched(props.name, true)
    }
  }

  const inputError = props.error
    ? props.error
    : context
    ? context.errors[name]
    : null

  const inputValue = props.value
    ? props.value
    : context
    ? context.values[name]
    : null
  const inputTouched: boolean | null = context ? context.touched[name] : null

  switch (controlType) {
    case FormControlFormikType.TEXT:
      return (
        <FormInput
          {...props}
          value={inputValue}
          error={inputError}
          touched={inputTouched}
          pattern={props.pattern}
          maxLength={props.maxLength}
          minLength={props.minLength}
          onChangeEvent={handleChange}
        />
      )
    case FormControlFormikType.SELECT:
      return (
        <FormSelect
          {...props}
          options={props.options}
          value={inputValue}
          error={inputError}
          touched={inputTouched}
          onChangeEvent={handleChange}
        />
      )
    case FormControlFormikType.PRACTITIONER_SELECT:
      if (
        !inputValue ||
        inputValue === '' ||
        inputValue.includes('Practitioner/')
      ) {
        return (
          <FormPractitionerSelect
            {...props}
            value={inputValue}
            error={inputError}
            touched={inputTouched}
            onChangeEvent={handleChange}
          />
        )
      } else {
        return (
          <FormPractitionerXLSIDSelect
            {...props}
            value={inputValue}
            error={inputError}
            touched={inputTouched}
            onChangeEvent={handleChange}
          />
        )
      }
    case FormControlFormikType.ICD10_SELECT:
      return (
        <FormIcd10Select
          {...props}
          value={inputValue}
          error={inputError}
          touched={inputTouched}
          onChangeEvent={handleChange}
        />
      )
    case FormControlFormikType.RADIO:
      return (
        <FormRadio
          {...props}
          value={inputValue}
          error={inputError}
          touched={inputTouched}
          options={props.options}
          onChangeEvent={handleChange}
        />
      )
    case FormControlFormikType.CHECKBOX:
      return (
        <FormCheckbox
          {...props}
          valueText={props.valueText}
          value={inputValue}
          error={inputError}
          touched={inputTouched}
          onChangeEvent={handleChange}
        />
      )
    case FormControlFormikType.CHECKBOX_GROUP:
      return (
        <FormCheckboxGroup
          {...props}
          options={props.options}
          value={inputValue}
          error={inputError}
          touched={inputTouched}
          onChangeEvent={handleChange}
        />
      )
    case FormControlFormikType.DATEPICKER:
      return (
        <FormDatepicker
          {...props}
          type={props.type as FormDatePickerTypes}
          value={inputValue}
          error={inputError}
          touched={inputTouched}
          onChangeEvent={handleChange}
        />
      )
    case FormControlFormikType.TEXTAREA:
      return (
        <FormTextarea
          {...props}
          value={inputValue}
          error={inputError}
          touched={inputTouched}
          maxLength={props.maxLength}
          minLength={props.minLength}
          onChangeEvent={handleChange}
        />
      )
    case FormControlFormikType.SLIDER:
      return (
        <FormSlider
          {...props}
          {...props.annotation.slider}
          value={Number(inputValue)}
          options={props.options}
          onChangeEvent={handleChange}
        />
      )
    case FormControlFormikType.HIDDEN:
      return (
        <FormInputHidden
          {...props}
          value={inputValue}
          error={inputError}
          touched={inputTouched}
          onChangeEvent={handleChange}
        />
      )
  }
}

FormControlsFormik.propTypes = {
  controlType: function (props, propName) {
    switch (props[propName]) {
      case FormControlFormikType.SELECT:
      case FormControlFormikType.RADIO:
      case FormControlFormikType.CHECKBOX_GROUP:
      case FormControlFormikType.SLIDER:
        if (!props['options'])
          return new Error('Please provide options prop to Field')
        break
    }
    if (
      props[propName] === FormControlFormikType.CHECKBOX &&
      !props['valueText']
    ) {
      return new Error('Please provide valueText prop')
    }
  },
}

export default FormControlsFormik
