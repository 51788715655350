import React from 'react'
import MaterialIcon from '../MaterialIcon/MaterialIcon'

interface TableGroupRowProps {
  icon: string
  text?: string
  colspan: number
  children?: React.ReactNode
}
const TableGroupRow: React.FC<TableGroupRowProps> = ({
  icon,
  text,
  colspan,
  children,
}: TableGroupRowProps) => {
  return (
    <tr className="table-group">
      <td colSpan={colspan}>
        <span className={'d-flex align-items-center'}>
          {icon && <MaterialIcon icon={icon} className={'mr-1'} />}
          {children ?? <span>{text}</span>}
        </span>
      </td>
    </tr>
  )
}
export default TableGroupRow
