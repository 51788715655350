export enum AppointmentCategories {
  MEDICAL_ROUND_NONMEDICALLY = 'Zentrumsvisite nichtärztlich',
  MEDICAL_ROUND_MEDICALLY = 'Zentrumsvisite ärztlich',
  CONTACT_LICENSED = 'Kontakt Niedergelassene/r',
  CONTACT_PATIENT = 'Kontakt Patient',
  CONTACT_OTHER = 'Kontakt Andere',
  MEETING = 'Besprechung',
  PATIENT_SCHOOL = 'Patientenschule',
  MOTOR_MEASUREMENT = 'Motorik-Messung',
  MISCELLANEOUS = 'Sonstiges',

  // Former:
  PRIVATE = 'Persönlicher Termin',
  ABSENCE = 'Abwesenheit',
}
