import React, { Component } from 'react'
import { AuthStore } from '../infrastructure/AuthProvider'
import Routes from '../config/Routes'
import Layout from './UI/Layout/Layout'

import '../panos.scss'
import 'react-notifications/lib/notifications.css'
import RouteTracker from './Tracking/RouteTracker'

class App extends Component {
  render(): JSX.Element {
    return (
      <AuthStore.Consumer>
        {context => (
          <>
            <RouteTracker {...context} />
            <Layout {...context}>
              <Routes {...context} />
            </Layout>
          </>
        )}
      </AuthStore.Consumer>
    )
  }
}

export default App
