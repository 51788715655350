import { createDynamicYupValidation } from '../../utils/ValidationUtils'
import { FormDynamicComponentProps } from '../FormDynamic/FormDynamicComponent'
import React from 'react'
import * as Yup from 'yup'
import { addSubComponents } from '../../utils/FormUtils'

export class BranchingValidationContext {
  validationSchema: Yup.ObjectSchema<any> = null
  componentMask: Set<string> = new Set()
  prevComponentMask: Set<string> = new Set()
  components: FormDynamicComponentProps[] = null
  triedToSubmit = false
  values = {}
  prevValues = {}
  errors: Record<string, string> = {}

  constructor(components: FormDynamicComponentProps[]) {
    this.components = addSubComponents(components)
    this.componentMask = new Set(components.map(component => component.name))
  }

  validate = (
    values: Record<string, unknown>,
    deactivate: boolean,
    ignoreRequired: boolean
  ): Record<string, string> => {
    this.validateValues(values)
    this.errors = deactivate ? {} : this.errors
    return this.errors
  }

  onSubmit = (
    values: Record<string, unknown>,
    onSubmit: (
      values: Record<string, unknown>,
      valid: boolean
    ) => Promise<void>,
    deactivateValidation: boolean
  ): void => {
    onSubmit(values, deactivateValidation || this.validateValues(values))
  }

  listen = (
    isSubmitting: boolean,
    isValidating: boolean
  ): BranchingValidationContext => {
    if ((isSubmitting && !isValidating) || this.triedToSubmit) {
      this.triedToSubmit = true
      this.updateValidation()
    }
    return this
  }

  validateValues = (values: Record<string, unknown>): boolean => {
    if (!this.validationSchema) return true

    this.errors = {}
    try {
      this.validationSchema.validateSync(values, { abortEarly: false })
    } catch (err) {
      err.inner.forEach(e => (this.errors[e.path] = e.message))
      delete this.errors[''] // mysterious field because of the submit button...
    }
    return Object.keys(this.errors).length === 0
  }

  registerComponent = (name: string, show: boolean): void => {
    if (!show) {
      this.componentMask.delete(name)
    } else {
      this.componentMask.add(name)
    }
    this.prevComponentMask = new Set(this.componentMask)
  }

  updateValidation = (ignoreRequired = false): void => {
    this.validationSchema = createDynamicYupValidation(
      this.components.filter(component =>
        this.componentMask.has(component.name)
      ),
      ignoreRequired
    )
  }
}

const FormikValidationBranchingContext =
  React.createContext<BranchingValidationContext>(null)

export default FormikValidationBranchingContext
