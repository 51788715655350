import { Draft, RedCapDraft } from '../../../types/Draft'
import BigIcon from '../../UI/BigIcon/BigIcon'
import MaterialIcon from '../../UI/MaterialIcon/MaterialIcon'
import { ConfirmDialogue } from '../../UI/Dialogues/ConfirmDialogue'
import React, { useEffect, useState } from 'react'
import { formatDateTime, getFullName } from '../../../utils/Utils'
import { KIND_TO_ICON } from '../DraftContainer'
import { Appointment } from '../../../types/Appointment'
import { Task } from '../../../types/Task'
import { Patient } from '../../../types/Patient'
import { getPatientsFromAnyKind } from '../../../utils/DraftUtils'

interface DraftRowProps {
  draft: Draft<Task | Appointment | RedCapDraft | any>
  className?: string
  selected: boolean
  onClick: (draft: Draft<any>) => void
  onDelete: (draft: Draft<any>) => void
  isSubRow?: boolean
}

const DraftRow: React.FC<DraftRowProps> = ({
  onClick,
  draft,
  selected,
  onDelete,
  isSubRow = false,
  className = '',
}: DraftRowProps) => {
  const [patients, setPatients] = useState<Patient[]>(null)
  useEffect(() => {
    if (!isSubRow && !patients)
      getPatientsFromAnyKind(draft).then(patients => setPatients(patients))
  }, [draft, isSubRow, patients, setPatients])

  return (
    <tr
      onClick={() => onClick(draft)}
      className={(selected ? 'cell-selected' : '') + className}>
      <td className="pt-1 pb-0 theme-drafts">
        {!isSubRow && <BigIcon icon={KIND_TO_ICON[draft.kind]} />}
      </td>
      <td>{draft.value?.title ?? draft.value?.description ?? 'Ohne Titel'}</td>
      <td>{patients?.map(getFullName).join(',')}</td>
      <td>{formatDateTime(draft.date)}</td>
      <td>
        <button className="btn btn-sm btn-link">
          <MaterialIcon icon="edit" />
        </button>

        <ConfirmDialogue
          callback={() => {
            onDelete(draft)
          }}
          question="Wollen Sie den Entwurf wirklich löschen?"
          confirmText="Löschen"
          title="Löschen">
          <button className="btn btn-sm btn-link">
            <MaterialIcon icon="delete" />
          </button>
        </ConfirmDialogue>
      </td>
    </tr>
  )
}

export default DraftRow
