import React from 'react'
import { MedicationUpdates } from 'src/components/FieldFunctionComponents/FieldExternalTool'
import { FieldTerminAufgabeDraft } from 'src/components/FieldFunctionComponents/FieldTerminAufgabeProvider'
import { BranchingValidationContext } from 'src/components/FieldFunctionComponents/ValidationBranchingContext'
import { FormDynamicComponentProps } from 'src/components/FormDynamic/FormDynamicComponent'
import {
  IDynamicFormData,
  LastModule,
  MedicalInstrument,
  PrevVersionInfo,
  SmartVariablesInfo,
} from 'src/components/Forms/FormInterfaces'
import {
  RedCapSaveMethods,
  RedCapSaveStatus,
} from 'src/constants/RedCapSaveMethods'
import { Practitioner } from 'src/types/Practitioner'
import { AuthContext } from './AuthProvider'
import { DynamicSidebarContext } from './DynamicSidebarProvider'
import { Document } from '../types/Document'

export const EventStore = React.createContext<EventContext>(null)

export interface EventContext {
  event: EventMeta
  forms: DynamicForm[]
  values: Record<string, unknown>
  prevValues: Record<string, Record<string, unknown>>
  prevVersions: Record<string, PrevVersionInfo>
  allMeta: Record<string, FormDynamicComponentProps>
  errors: Record<string, string>
  touched: Record<string, boolean>
  edited: Record<string, Record<string, boolean> | boolean>
  saveMethod: RedCapSaveMethods
  isSaving: boolean
  firstRender: boolean
  updateForm: (index: number, form: DynamicForm) => void
  setFieldValue: (key: string, value: unknown) => void
  setValues: (values: Record<string, unknown>) => void
  handleChange: (
    e: React.ChangeEvent<any>,
    component: FormDynamicComponentProps,
    isDefault: boolean
  ) => void
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void
  handleFieldChange: (value: unknown, name: string) => void
  handleSubmit: (
    validate: boolean,
    saveStatus: RedCapSaveStatus,
    assignTo?: string,
    sign?: boolean
  ) => void
  setCurrentPreviousVersion: (string) => void
  currentPreviousVersion: string
  closeSidebar: () => void
  triggerAutosave: () => void
  setClickedForm: (form: string) => void
  setClickedModule: (module: string) => void
  syncModules: () => void
  showSyncModule: boolean
  closeSyncModule: () => void
  showConfirmModule: boolean
  closeConfirmModule: () => void
  continueAction: () => void
  updateEdited: (key: string, value: boolean) => void

  medicationUpdates: { [reason: string]: MedicationUpdates }
  registerMedicationUpdates: (
    reason: string,
    medicationUpdates: MedicationUpdates
  ) => void
}

export interface EventContextProps {
  patientId: string
  instruments: MedicalInstrument[]
  showPreviousValues?: boolean
  saveMethod: RedCapSaveMethods
  isAutosaving?: boolean
  closeSidebar?: () => void
  onSaved?: () => void
  dynamicSidebarStore?: DynamicSidebarContext
  authStore?: AuthContext
}

export interface EventContextContextAsProps {
  event: EventContext
}

export interface DynamicForm {
  name: string
  data: IDynamicFormData
  smartvariables: SmartVariablesInfo[]
  prevValues: Record<string, unknown>
  assignedDocument: Document
  externalDocuments: Document[]
  valid?: boolean
  branchingValidator?: BranchingValidationContext
  fieldDrafts?: FieldTerminAufgabeDraft[]
  lastModules: LastModule[]
}

export interface EventMeta {
  name: string
  label: string
  recordId: string
  instruments: MedicalInstrument[]
  patientId: string
  hash_value: string
}

export interface EventContextProviderState {
  event: EventMeta
  practitioner: Practitioner
  forms: DynamicForm[]
  values: Record<string, unknown>
  prevValues: Record<string, Record<string, unknown>>
  prevVersions: Record<string, PrevVersionInfo>

  errors: Record<string, string>
  allMeta: Record<string, FormDynamicComponentProps>
  formFetchErrors: boolean
  edited: Record<string, Record<string, boolean> | boolean>
  touched: Record<string, boolean>
  isSaving?: boolean
  firstRender: boolean //Is needed for branchinglogik
  triggerAutoSave: boolean
  hasDraft: boolean
  noValidation: boolean
  singleForm: boolean
  lastClickedForm: string
  lastClickedModule: string
  showSyncModule: boolean
  medicationUpdates: { [reason: string]: MedicationUpdates }
  showConfirmModule: boolean
  lastFieldName: string
  lastFieldValue: unknown
  currentPreviousVersion: string
}
