import React, { ReactNode } from 'react'
import Select from 'react-select'
import { getFullName, getIdPartFromFhirResourceId } from '../utils/Utils'

interface Props {
  values: any[] // from which to choose
  get?: (row: any) => void // how to compute label

  defaultValue?: any
  value?: any
  onChange: (value: any) => void
  sideEffect?: (value: any) => void
  disabled?: boolean
  id?: string
  menuPosition?: string
  menuPortalTarget?: HTMLElement
  className?: string
}

interface State {
  selectedValue: ProposedValue
}

interface ProposedValue {
  value: any
  label: string
}

export class SelectorList extends React.Component<Props, State> {
  /*
   * This produces a drop-down menu for inputs. Wrap this component around the input and
   * and it will add a datalist to the input-Component
   *
   * For the sake of html-<datalist> a "list" attribute is added to the input.
   *
   * The values are passed as property and you can add your Dtos, as long as you can add
   * Function as the get-property to get a Name to display
   *
   * */

  values: ProposedValue[]

  constructor(props: Props) {
    super(props)

    this.computeSelectableValues(props)

    this.state = {
      selectedValue: null,
    }
  }

  private computeSelectableValues(props) {
    let proposeValue = null

    if (props.get) proposeValue = props.get
    else proposeValue = this.defaultPropose

    this.values = this.props.values?.map(val => proposeValue(val))
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props !== prevProps) {
      this.computeSelectableValues(this.props)

      this.setState({
        selectedValue: this.filterValues(
          this.props.value ??
            this.props.defaultValue ??
            this.state.selectedValue
        ),
      })
    }
  }

  defaultPropose(value: Record<string, any>): ProposedValue {
    if (value)
      return {
        value: value,
        label: getFullName(value),
      }
    else return null
  }

  filterValues(toFind: unknown): ProposedValue {
    if (!toFind || !this.values) return null
    else {
      // try to get values, either by comparing the value by itself or by id
      let matchedValues = this.values.filter(it => toFind === it.value)
      if (matchedValues.length < 1) {
        matchedValues = this.values.filter(
          it => getIdPartFromFhirResourceId(toFind.toString()) === it.value.id
        )
      }

      // check result, throw error, if too much, none is none
      if (matchedValues.length < 1) {
        return null
      } else {
        if (matchedValues.length > 1) {
          console.error(this, matchedValues, toFind)
          console.error(
            "Multiple '" +
              toFind +
              "'  found: " +
              matchedValues.map(it => it.toString())
          )
        }
      }
      return matchedValues[0]
    }
  }

  apply(name: unknown): void {
    const value = this.filterValues(name)
    this.props.onChange(value ? value.value : value)
    if (this.props.sideEffect) {
      this.props.sideEffect(value ? value.value : value)
    }
  }

  render(): ReactNode {
    const value = this.filterValues(
      this.props.value ?? this.props.defaultValue ?? this.state.selectedValue
    )

    return (
      <>
        <Select
          id={this.props.id}
          options={this.values ? this.values : null}
          value={value}
          styles={{
            menu: provided => ({ ...provided, zIndex: 5 }),
          }}
          menuPortalTarget={this.props.menuPortalTarget}
          className={this.props.className}
          onChange={event => this.apply(this.values ? event.value : null)}
          isDisabled={this.props.disabled}
          menuPosition={this.props.menuPosition ? this.props.menuPosition : ''}
        />
      </>
    )
  }
}
