import { AuthContext, withAuth } from '../../infrastructure/AuthProvider'
import { Appointment } from '../../types/Appointment'
import { AppointmentCategories } from '../../constants/AppointmentCategories'
import { AppointmentFormErrors } from '../../types/AppointmentFormErrors'
import IAuth from '../../types/Auth'
import React from 'react'
import {
  getParticipant,
  makeMaxDate,
  personToParticipant,
} from '../../utils/CalendarUtils'
import { AppointmentPerson } from '../../types/AppointmentPerson'
import { cloneDeep } from 'lodash'
import { Practitioner } from '../../types/Practitioner'
import { Patient } from '../../types/Patient'
import { ParticipationStatus } from '../../constants/ParticipationStatus'
import AppointmentView from '../Appointments/AppointmentForm/AppointmentView'

interface OrganizerAppointmentFormProps extends AuthContext {
  index: number
  appointment: Appointment
  appointments: Appointment[]
  setAppointments: (appointments: Appointment[]) => void
  onChangeEndDate: (index: number, value: Date) => void
  handleChangeAppointment: (
    appointmentIndex: number,
    fieldname: string,
    value: string | number | Date | AppointmentCategories | Location | boolean
  ) => void
  errors?: AppointmentFormErrors
  auth: IAuth
}

const OrganizerAppointmentForm: React.FC<OrganizerAppointmentFormProps> = ({
  index,
  appointment,
  appointments,
  setAppointments,
  onChangeEndDate,
  handleChangeAppointment,
  errors,
  auth,
}: OrganizerAppointmentFormProps) => {
  const maxDate = makeMaxDate(appointment)

  const handleParticipantDelete = (
    appointmentIndex: number,
    person: AppointmentPerson
  ): void => {
    const newAppointments = cloneDeep(appointments)
    const appointment = newAppointments[appointmentIndex]
    const { participants } = appointment
    appointment['participants'] = participants.filter(
      participant => participant.id !== person.id
    )
    setAppointments(newAppointments)
  }

  const onPractitionerAdded = (
    appointmentIndex: number,
    person: Practitioner
  ): void => {
    addParticipant(
      appointmentIndex,
      { ...person, id: 'Practitioner/' + person.id },
      auth && auth.panosId === person.id
    )
  }

  const onPatientAdded = (appointmentIndex: number, person: Patient): void => {
    addParticipant(
      appointmentIndex,
      { ...person, id: 'Patient/' + person.id, role: 'patient' },
      auth && auth.panosId === person.id
    )
  }

  const addParticipant = (
    appointmentIndex: number,
    person: Patient | Practitioner,
    autoAccept: boolean
  ): void => {
    const newAppointments = cloneDeep(appointments)
    const appointment = newAppointments[appointmentIndex]
    if (person && !getParticipant(person.id, appointment)) {
      const { participants } = appointment
      const newParticipants = participants || []
      const newParticipant = personToParticipant(person)
      /* directly accept if you are self assinging */
      if (autoAccept) {
        newParticipant.participationStatus = ParticipationStatus.ACCEPTED
      }
      appointment['participants'] = [...newParticipants, newParticipant]
      setAppointments(newAppointments)
    }
  }

  return (
    <div className="appointment-detail Modal" key={index}>
      <AppointmentView
        appointment={appointment}
        isEditable={true}
        handleInputChange={handleChangeAppointment.bind(null, index)}
        maxDate={maxDate}
        onChangeEndDate={date => onChangeEndDate(index, date)}
        handleParticipantDelete={person =>
          handleParticipantDelete(index, person)
        }
        onPractitionerAdded={person => onPractitionerAdded(index, person)}
        onPatientAdded={person => onPatientAdded(index, person)}
        errors={errors}
      />
    </div>
  )
}

export default withAuth(OrganizerAppointmentForm)
