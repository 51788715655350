import React, { Component, ReactElement } from 'react'
import AuthService from '../services/AuthService'
import IAuth from '../types/Auth'
import { UserPermission } from '../constants/UserPermission'
import Loader from '../components/UI/Spinner/Loader'

export const AuthStore = React.createContext<AuthContext>({
  auth: null,
  handlers: null,
})

export interface AuthContext {
  auth: IAuth | null
  handlers: AuthHandlers | null
}

export interface AuthHandlers {
  doLogin: (options?: Keycloak.KeycloakLoginOptions) => void
  doLogout: () => void
  doRelogin: (returnUrl?: string) => void
  doRegister: () => void
  hasPermission: (value: UserPermission) => boolean
  updateUserSettings: () => void
}
export default class AuthProvider extends Component<
  Record<string, unknown>,
  IAuth
> {
  state: IAuth = {
    authenticated: null,
    keycloak: null,
    panosId: null,
    permissions: null,
    roleLabels: null,
    connected: null,
    user: null,
  }

  AuthService = new AuthService()

  async componentDidMount(): Promise<void> {
    const authData = await this.AuthService.connect()
    this.setState({ ...authData })
  }

  loginHandler = (options?: Keycloak.KeycloakLoginOptions): void => {
    this.AuthService.login(options)
  }

  logoutHandler = (): void => {
    this.AuthService.logout()
  }

  registerHandler = (): void => {
    this.AuthService.register()
  }

  updateUserSettings = (): void => {
    this.AuthService.updateUser(user => this.setState({ user: { ...user } }))
  }

  hasPermission = (permissionKey?: string): boolean => {
    // console.log(
    //   'hasPermission',
    //   permissionKey,
    //   this.state.permissions,
    //   this.state.authenticated
    // )
    if (!this.state.authenticated) {
      return false
    } else {
      if (!permissionKey) return true
      return this.state.permissions?.some(
        permission => permission === permissionKey
      )
    }
  }
  reloginHandler = (returnUrl: string = null): void => {
    if (returnUrl) window.history.pushState(null, null, returnUrl)
    this.AuthService.reLogin()
  }

  handlers: AuthHandlers = {
    doLogin: this.loginHandler,
    doLogout: this.logoutHandler,
    doRegister: this.registerHandler,
    doRelogin: this.reloginHandler,
    hasPermission: this.hasPermission,
    updateUserSettings: this.updateUserSettings,
  }

  render(): ReactElement {
    const authData: IAuth = { ...this.state }

    return (
      <AuthStore.Provider value={{ auth: authData, handlers: this.handlers }}>
        {this.state.connected !== null ? (
          this.props.children
        ) : (
          <div className="w-100 pt-5 text-center">
            <Loader />
          </div>
        )}
      </AuthStore.Provider>
    )
  }
}

export function withAuth<T>(
  Component: React.ComponentType<T>
): React.ComponentType<Omit<T, keyof AuthContext>> {
  const componentWithAuthentication = (props: T) => {
    return (
      <AuthStore.Consumer>
        {store => (
          <Component
            {...props}
            auth={store ? store.auth : null}
            handlers={store ? store.handlers : null}
          />
        )}
      </AuthStore.Consumer>
    )
  }
  componentWithAuthentication.displayName = `withAuth(${
    Component.displayName || Component.name || 'Component'
  })`
  return componentWithAuthentication
}
