class BaseError extends Error {
  meta: Record<string, unknown>
  constructor(message: string, meta: Record<string, unknown>) {
    super(message)
    this.name = this.constructor.name
    this.meta = meta
  }
  toString(): string {
    return `${super.toString()} ${JSON.stringify(this.meta)}`
  }
}

export default BaseError
