import React, { useState } from 'react'
import { FieldFunctionAnnotationExplanation } from './FieldFunctionInterfaces'
import { FieldFunctionSymbolButton } from '../UI/FieldFunctionSymbolButton/FieldFunctionSymbolButton'

const FieldExplanation: React.FC<FieldFunctionAnnotationExplanation> = (
  props: FieldFunctionAnnotationExplanation
) => {
  const [show, setShow] = useState<boolean>(false)

  if (!props.explanation) return null
  return (
    <div>
      <FieldFunctionSymbolButton
        icon="help"
        onClick={() => setShow(!show)}
        name={'help'}
      />
      {show && (
        <div className="alert-info">
          <p>{props.explanation}</p>
        </div>
      )}
    </div>
  )
}
export default FieldExplanation
