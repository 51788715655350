import { Priority } from '../../constants/Priority'
import React from 'react'

interface PriorityIconProps {
  value: Priority | string
}

const IconMap = {
  routine: 'Routine',
  urgent: 'Dringlich',
  asap: 'Notfall',
  unknown: '?',
}

export default class PriorityIcon extends React.Component<PriorityIconProps> {
  render(): JSX.Element {
    if (!this.props.value) return null
    else
      return (
        <span
          className={'priority ' + this.props.value.toString().toLowerCase()}>
          {IconMap[this.props.value.toString().toLowerCase()]}
        </span>
      )
  }
}
