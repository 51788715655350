export enum Priority {
  EMPTY = '',
  ROUTINE = 'ROUTINE',
  URGENT = 'URGENT',
  ASAP = 'ASAP',
}

export const PriorityLabels = {
  [Priority.EMPTY]: '...',
  [Priority.ROUTINE]: 'Routine',
  [Priority.URGENT]: 'Dringlich',
  [Priority.ASAP]: 'Notfall',
}

export const priorityDaysRange = {
  [Priority.ASAP]: 7,
  [Priority.ROUTINE]: 90,
  [Priority.URGENT]: 28,
}
