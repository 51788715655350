import React from 'react'

interface MaterialIconProps {
  icon: string
  className?: string
  size?: string
  color?: string
  verticalAlignment?: string
  mirrored?: boolean //Icon points into wrong direction? Mirror it!
}
/**
 * Get the Icons here: https://jossef.github.io/material-design-icons-iconfont/
 */
const MaterialIcon: React.FC<MaterialIconProps> = ({
  icon,
  className,
  size,
  color,
  verticalAlignment,
  mirrored,
}: MaterialIconProps) => {
  return (
    <i
      className={`material-icons ${!className || className}`}
      style={{
        color: color,
        fontSize: size,
        verticalAlign: verticalAlignment,
        transform: mirrored ? 'scaleX(-1)' : 'none',
      }}>
      {icon}
    </i>
  )
}

export default React.memo(MaterialIcon)
