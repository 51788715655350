import React, { ChangeEvent, useState } from 'react'
import FormErrorMessage from '../FormError/FormErrorMessage'
import { v4 as uuidv4 } from 'uuid'

export interface FormCheckboxProps {
  name: string
  valueText: string
  value?: boolean
  label?: string | JSX.Element
  onChange?: (value: boolean) => void
  onChangeEvent?: (event: ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  className?: string
  disabled?: boolean
  error?: string
  touched?: boolean
  inline?: boolean
  customStyle?: any
}
/**
 * Use this component with or without formik context.
 *
 * Required props without Formik are:
 * -- name
 * -- value
 * -- onChange
 */
const FormCheckbox: React.FC<FormCheckboxProps> = ({
  name,
  label,
  value,
  valueText,
  className,
  disabled,
  required,
  error,
  inline,
  onChange,
  onChangeEvent,
  touched,
  customStyle,
}: FormCheckboxProps) => {
  const inputValue = value || ''
  const inputError = error || null

  const [uniqueKey] = useState<string>(uuidv4())

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onChangeEvent) {
      onChangeEvent(event)
    } else if (onChange) {
      const { checked } = event.target
      if (checked) {
        value = null
      } else {
        value = false
      }
      onChange(value)
    }
  }

  const cssClasses = className ? [className] : []
  if (inputError) cssClasses.push('position-relative')
  if (inline) cssClasses.push('custom-control-inline')
  if (error) cssClasses.push('validate-bad')
  const checkboxId = `${name}_${value}_${uniqueKey}`
  return (
    <div className={`form-group ${cssClasses.join(' ')}`}>
      {label ? (
        <label className="form-label">
          {label}
          {required ? <span style={{ color: 'red' }}> *</span> : null}
        </label>
      ) : null}
      <div className="custom-control custom-checkbox" style={customStyle}>
        <input
          className={`custom-control-input ${error ? 'is-invalid' : ''}`}
          type="checkbox"
          name={name}
          id={checkboxId}
          disabled={disabled}
          required={required}
          onChange={onChangeHandler}
          checked={inputValue ? true : false}
        />
        <label className="custom-control-label" htmlFor={checkboxId}>
          {valueText}
        </label>
      </div>
      {inputError && touched ? <FormErrorMessage error={error} /> : null}
    </div>
  )
}

export default FormCheckbox
