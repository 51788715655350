import { UserRole } from '../constants/UserRole'
import { AppointmentStatus } from '../constants/AppointmentStatus'
import { Organization } from './Organization'
import { Location } from './Location'

//import { AppointmentCategories } from '../constants/AppointmentCategories'

export class AppointmentFilterParams {
  userId: string
  patientId: string
  practitionerId: string
  role: UserRole
  //category: AppointmentCategories
  location: Location
  status: AppointmentStatus
  organization: Organization
  includeOwn?: boolean
}
