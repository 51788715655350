import axios, { AxiosResponse } from 'axios'
import { API_FORWARDING_GET } from '../config/Paths'
import { getToken } from './Keycloak'

export function getMedicationById(
  medicationId: string
): Promise<AxiosResponse<any>> {
  const url = new URL(API_FORWARDING_GET)
  url.searchParams.append('resourceId', medicationId)

  return axios.get(url.toString(), {
    headers: { Authorization: `Bearer ` + getToken() },
  })
}
