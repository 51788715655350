import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React from 'react'

export const DeletePlanModal = (props: {
  show: boolean
  onHide: () => void
  onClickNotDelete: () => void
  onClickDelete: () => void
}): JSX.Element => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title> Eingabebestätigung </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Sind sie sich sicher, dass Sie die Planvorlagen entfernen wollen?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClickNotDelete}>
          Nein
        </Button>
        <Button variant="primary" onClick={props.onClickDelete}>
          Ja
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
