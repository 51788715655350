import React, { useContext } from 'react'
import Select from 'react-select'
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikErrors,
  FormikTouched,
  useFormikContext,
} from 'formik'
import { NotificationManager } from 'react-notifications'
// Todo Handle
//  Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
//     in Toast (onClick notify.hide)
import { Button, Col, Row } from 'react-bootstrap'
import './MedicationForm.sass'
import { saveMedicationActivityDefinition } from '../../../services/MedicationActivityDefinitionService'
import { ShapeOptions } from '../../../constants/MedicationShapeOptions'
import { AdministrationOptions } from '../../../constants/MedicationAdministrationOptions'
import { TypeOptions } from '../../../constants/MedicationTypeOptions'
import { UnitOptions } from '../../../constants/MedicationUnitOptions'
import { ListTypeOptions } from '../../../constants/ListTypeOptions'
import { AuthStore } from '../../../infrastructure/AuthProvider'
import { UserPermission } from '../../../constants/UserPermission'
import Loader from '../../UI/Spinner/Loader'

/**
 * emptyFormValues for Formik
 * @type {{type: string, unit: string, substances: string, code: string, shape: string, dosageChoices: string, businessName: string, warning: string, administration: string}}
 */
const emptyFormValues = {
  substances: '',
  dosageChoices: '',
  businessName: '',
  administration: null,
  type: null,
  shape: null,
  warning: '',
  code: '',
  unit: null,
  timing: '',
  packageVariants: '',
  listVariant: null,
}

/**
 *
 * @param {object} obj
 * @returns {boolean}
 */
function isEmpty(obj) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false
  }
  return true
}

/**
 * Uploads Medication and ActivityDefinition data to backend and reports success or failure
 * @param fields - values from the form
 */
async function saveMedication(fields) {
  // ToDo Weiterleitung der Backendfehler

  await saveMedicationActivityDefinition(fields, fields.listVariant)
    .then(() => {
      NotificationManager.success(
        'Erfolg',
        'Medikament erfolgreich gespeichert.'
      )
    })
    .catch(reason => {
      if (reason.response.status === 403) {
        NotificationManager.error(
          'Verbotene Operation',
          'Die Standarddosierung oder die zugehörige Packungsvariante ' +
            'darf nicht gelöscht werden.'
        )
      } else {
        NotificationManager.error(
          'Fehler',
          'Unbekannter Fehler beim Speichern des Medikaments.'
        )
      }
    })
}

interface MedicationRequestInputFormProps {
  values: MedicationFormValues
  touched: FormikTouched<MedicationFormValues>
  errors: FormikErrors<MedicationFormValues>
}

function MedicationFormFields({
  values,
  errors,
  touched,
}: MedicationRequestInputFormProps): JSX.Element {
  const { setFieldValue } = useFormikContext()

  function handleShapeOptionChange(option) {
    setFieldValue(`shape`, option.value)
  }

  function handleAdministrationOptionChange(option) {
    setFieldValue(`administration`, option.value)
  }

  function handleTypeOptionChange(option) {
    setFieldValue(`type`, option.value)
  }

  function handleUnitOptionChange(option) {
    setFieldValue(`unit`, option.value)
  }

  function handleListTypeOptionChange(option) {
    setFieldValue(`listVariant`, option.value)
  }

  return (
    <div className="px-4 py-2">
      <Row>
        <Col>
          <label htmlFor="businessName">Handelsname</label>
          <Field
            name={'businessName'}
            className={
              'form-control' +
              (errors.businessName && touched.businessName ? ' is-invalid' : '')
            }
            placeholder={'Handelsname'}
          />
          <ErrorMessage
            name={'businessName'}
            component="div"
            className="invalid-feedback"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="substances">Wirkstoff(e)</label>
          <Field
            name={'substances'}
            className={
              'form-control' +
              (errors.substances && touched.substances ? ' is-invalid' : '')
            }
            placeholder={'z.B. Levodopa/Carbidopa/Entacapon bei 3 Wirkstoffen.'}
          />
          <ErrorMessage
            name={'substances'}
            component="div"
            className="invalid-feedback"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="dosageChoices">Dosis</label>
          <Field
            name={'dosageChoices'}
            className={
              'form-control' +
              (errors.dosageChoices && touched.dosageChoices
                ? ' is-invalid'
                : '')
            }
            placeholder={
              'z.B. 2/4/6; 3/5/7 für 2 Dosierungsmöglichkeiten bei 3 Wirkstoffen.'
            }
          />
          <ErrorMessage
            name={'dosageChoices'}
            component="div"
            className="invalid-feedback"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="packageVariants">Packungsvarianten</label>
          <Field
            name={'packageVariants'}
            className={
              'form-control' +
              (errors.packageVariants && touched.packageVariants
                ? ' is-invalid'
                : '')
            }
            placeholder={
              'z.B. 2/4/6; 3/5/7 für 2 Dosierungsmöglichkeiten bei 3 Wirkstoffen.'
            }
          />
          <ErrorMessage
            name={'packageVariants'}
            component="div"
            className="invalid-feedback"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="timing">Zeitspalten</label>
          <Field
            name={'timing'}
            className={
              'form-control' +
              (errors.timing && touched.timing ? ' is-invalid' : '')
            }
            placeholder={
              'Zeitpunkte der Einnahme: z.B. 7-9-11; 7-9-11-13 für 2 Möglichkeiten.'
            }
          />
          <ErrorMessage
            name={'timing'}
            component="div"
            className="invalid-feedback"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="unit">Dosierungseinheit</label>
          <Select
            autofocus
            className={
              'basic-single' +
              (errors.unit && touched.unit ? ' is-invalid' : '')
            }
            classNamePrefix="select"
            name="unit"
            options={UnitOptions}
            value={
              values.unit !== null
                ? UnitOptions.find(option => option.value === values.unit)
                : null
            }
            onChange={option => {
              handleUnitOptionChange(option)
            }}
          />
          <ErrorMessage
            name="unit"
            component="div"
            className="invalid-feedback"
          />
        </Col>
        <Col>
          <label htmlFor="type">Medikamententyp</label>
          <Select
            autofocus
            className={
              'basic-single' +
              (errors.type && touched.type ? ' is-invalid' : '')
            }
            classNamePrefix="select"
            name="type"
            options={TypeOptions}
            value={
              values.type !== null
                ? TypeOptions.find(option => option.value === values.type)
                : null
            }
            onChange={option => {
              handleTypeOptionChange(option)
            }}
          />
          <ErrorMessage
            name="type"
            component="div"
            className="invalid-feedback"
          />
        </Col>
        <Col>
          <label htmlFor="code">Medikamentencode</label>
          <Field
            className={
              'form-control' +
              (errors.code && touched.code ? ' is-invalid' : '')
            }
            name="code"
            type="text"
            placeholder={'Codes trennen mit ";"'}
          />
          <ErrorMessage
            name="code"
            component="div"
            className="invalid-feedback"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="administration">Verabreichung</label>
          <Select
            autofocus
            className={
              'basic-single' +
              (errors.administration && touched.administration
                ? ' is-invalid'
                : '')
            }
            classNamePrefix="select"
            name="administration"
            options={AdministrationOptions}
            value={
              values.administration !== null
                ? AdministrationOptions.find(
                    option => option.value === values.administration
                  )
                : null
            }
            onChange={option => {
              handleAdministrationOptionChange(option)
            }}
          />
          <ErrorMessage
            name="administration"
            component="div"
            className="invalid-feedback"
          />
        </Col>
        <Col>
          <label htmlFor="shape">Form</label>
          <Select
            autofocus
            className={
              'basic-single' +
              (errors.shape && touched.shape ? ' is-invalid' : '')
            }
            classNamePrefix="select"
            name="shape"
            options={ShapeOptions}
            value={
              values.shape !== null
                ? ShapeOptions.find(option => option.value === values.shape)
                : null
            }
            onChange={option => {
              handleShapeOptionChange(option)
            }}
          />
          <ErrorMessage
            name="shape"
            component="div"
            className="invalid-feedback"
          />
        </Col>
        <Col>
          <label htmlFor="listVariant">Medikamentenliste</label>
          <Select
            autofocus
            className={
              'basic-single' +
              (errors.listVariant && touched.listVariant ? ' is-invalid' : '')
            }
            classNamePrefix="select"
            name="listVariant"
            options={ListTypeOptions}
            value={
              values.listVariant !== null
                ? ListTypeOptions.find(
                    option => option.value === values.listVariant
                  )
                : null
            }
            onChange={option => {
              handleListTypeOptionChange(option)
            }}
          />
          <ErrorMessage
            name="listVariant"
            component="div"
            className="invalid-feedback"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="warning">Warnhinweise</label>
          <Field
            as="textarea"
            className={
              'form-control' +
              (errors.warning && touched.warning ? ' is-invalid' : '')
            }
            id="Warnhinweise"
            name="warning"
            type="text"
            placeholder="Warnhinweise"
          />
          <ErrorMessage
            name="warning"
            component="div"
            className="invalid-feedback"
          />
        </Col>
      </Row>
    </div>
  )
}

/**
 *
 * @param {object} values
 * @returns {object} errors
 *
 */
const validate = values => {
  const errors: FormikErrors<MedicationFormValues> = {
    substances: '',
    dosageChoices: '',
    businessName: '',
    administration: '',
    shape: '',
    warning: '',
    code: '',
    type: '',
    unit: '',
    listVariant: '',
    timing: '',
    packageVariants: '',
  }
  let isValid = true
  const substances = values.substances
  const timing = values.timing
  const numberOfSubstances = (substances.match(/\//g) || []).length + 1
  const dosageChoices = values.dosageChoices.split(';')
  const packageVariants = values.packageVariants.split(';')
  const codesArray = values.code.split(';')

  Object.values(values).forEach((it, index) => {
    if (!it) {
      const fieldName = Object.keys(values)[index]
      switch (fieldName) {
        case 'substances':
          errors.substances = 'Wirkstoff(e) darf nicht leer sein. '
          isValid = false
          break
        case 'dosageChoices':
          errors.dosageChoices = 'Bitte Dosis eintragen. '
          isValid = false
          break
        case 'businessName':
          errors.businessName = 'Der Handelsname wird benötigt. '
          isValid = false
          break
        case 'administration':
          errors.administration =
            'Die Verabreichung des Medikaments wird benötigt. '
          isValid = false
          break
        case 'shape':
          errors.shape = 'Die Darreichungsform des Medikaments wird benötigt. '
          isValid = false
          break
        case 'code':
          errors.code = 'Medikamentencode benötigt. '
          isValid = false
          break
        case 'type':
          errors.type = 'Medikamententyp darf nicht leer sein. '
          isValid = false
          break
        case 'unit':
          errors.unit = 'Dosiereinheit darf nicht leer sein. '
          isValid = false
          break
        case 'listVariant':
          errors.listVariant = 'Medikamentenliste darf nicht leer sein. '
          isValid = false
          break
        case 'timing':
          errors.timing = 'Zeitpunkte der Einnahme benötigt. '
          isValid = false
          break
        case 'packageVariants':
          errors.packageVariants = 'Bitte Packungsvariante angeben. '
          isValid = false
          break
      }
    }
  })

  for (const [dosageIndex, dosage] of dosageChoices.entries()) {
    const numberOfStrengths = (dosage.match(/\//g) || []).length + 1
    if (numberOfStrengths !== numberOfSubstances) {
      errors.dosageChoices += `Die ${
        dosageIndex + 1
      }.te Dosierauswahl sollte ${numberOfSubstances} Wirkstoff(e) haben`
      isValid = false
      break
    }
    /**
     * dosage should be positive floats separated by /
     */
    if (!/^(\d+(,\d+)?\/)*?\d+(,\d+)?$/.test(dosage.replace(/\s+/g, ''))) {
      errors.dosageChoices += `Die Dosierung sollte z.B. so aussehen 12,34/2342,23/135`
      isValid = false
      break
    }
  }
  const timingChoices = timing.split(';')
  for (const [timingIndex, timing] of timingChoices.entries()) {
    /**
     * timing should be some positive integers between 6 and 22 separated by -
     */
    if (!/^(\d+-)*\d+$/.test(timing.replace(/\s+/g, ''))) {
      errors.timing += `Die Zeitpunkte sollten z.B. so aussehen 12-14-16`
      isValid = false
      break
    }
    const hours = timing.split('-')
    for (const [timeIndex, time] of hours.entries()) {
      const pointInTime = parseInt(time)
      if (!(0 <= pointInTime && pointInTime <= 23)) {
        errors.timing += `${pointInTime} liegt nicht zwischen 0 und 23 Uhr. (Zeitpunkt ${
          timeIndex + 1
        } in Möglichkeit ${timingIndex + 1})`
        isValid = false
        break
      }
    }
  }
  for (const [dosageIndex, dosage] of packageVariants.entries()) {
    const numberOfStrengths = (dosage.match(/\//g) || []).length + 1
    if (numberOfStrengths !== numberOfSubstances) {
      errors.packageVariants += `Die ${
        dosageIndex + 1
      }.te Packungsvariante sollte ${numberOfSubstances} Wirkstoff(e) haben`
      isValid = false
      break
    }
    /**
     * dosage should be positive floats separated by /
     */
    if (!/^(\d+(,\d+)?\/)*?\d+(,\d+)?$/.test(dosage.replace(/\s+/g, ''))) {
      errors.packageVariants += `Die Packungsvariante sollte z.B. so aussehen 10,5/20,5/30`
      isValid = false
      break
    }
  }
  /**
   * packages and codes should correspond and be separated by ";"
   */
  if (codesArray.length !== packageVariants.length) {
    errors.packageVariants += `Die Anzahl an Packungsvarianten separiert mit ";" sollte der Anzahl an Codes entsprechen`
    errors.code += `Die Anzahl an Codes separiert mit ";" sollte der Anzahl an Packungsvarianten entsprechen`
    isValid = false
  }

  if (isValid) {
    return {}
  } else {
    return errors
  }
}

interface MedicationFormProps {
  selectedMedication?: MedicationFormValues
  onClose?: () => void
  handleBack?: () => void
}

function MedicationForm(props: MedicationFormProps): JSX.Element {
  const context = useContext(AuthStore)

  return (
    <Formik
      initialValues={{
        ...emptyFormValues,
        ...props.selectedMedication,
      }}
      validate={validate}
      onSubmit={() => undefined}>
      {({
        initialValues,
        handleSubmit,
        errors,
        values,
        touched,
        resetForm,
        isSubmitting,
        setSubmitting,
      }) => (
        <Form
          onSubmit={e => {
            if (isEmpty(errors) && initialValues !== values) {
              saveMedication(values).then(() => {
                setSubmitting(false)
                if (props.onClose) props.onClose()
              })
              handleSubmit(e)
            } else {
              handleSubmit(e)
              setSubmitting(false)
            }
          }}>
          <MedicationFormFields
            values={values}
            errors={errors}
            touched={touched}
          />
          <div className="px-4 py-2 d-flex justify-content-end">
            {props.handleBack ? (
              <Button
                variant="secondary"
                className="ml-0 mr-auto"
                onClick={props.handleBack}>
                Zurück
              </Button>
            ) : null}
            {context.handlers.hasPermission(UserPermission.MEDICATIONS_ADD) ||
            context.handlers.hasPermission(UserPermission.MEDICATIONS_EDIT) ? (
              <>
                {props.onClose && (
                  <Button
                    disabled={isSubmitting}
                    onClick={props.onClose}
                    variant="secondary"
                    className="mr-1">
                    Schließen
                  </Button>
                )}
                <Button
                  disabled={isSubmitting}
                  onClick={() => resetForm({ errors: {}, ...emptyFormValues })}
                  variant="secondary"
                  className="mr-1">
                  Zurücksetzen
                </Button>
                <Button type="submit" disabled={isSubmitting}>
                  Speichern {isSubmitting && <Loader size={24} />}
                </Button>
              </>
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default MedicationForm
