import React from 'react'
import './BigIcon.scss'
import MaterialIcon from '../MaterialIcon/MaterialIcon'

export interface BigIconProps {
  children?: JSX.Element | string
  className?: string
  icon: string
}

const BigIcon: React.FC<BigIconProps> = (props: BigIconProps) => {
  return (
    <div
      className={
        'BigIcon' + (props.className !== undefined ? ' ' + props.className : '')
      }>
      <span className="icon-background">
        <MaterialIcon icon={props.icon} />
      </span>
      {props.children ? (
        <span className="children">{props.children}</span>
      ) : null}
    </div>
  )
}
export default BigIcon
