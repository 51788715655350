import ApiServiceBase, { API_RETURN } from './ApiServiceBase'
import {
  API_LETTER,
  API_LETTER_TEMPLATE_DB,
  LETTER_PATHS,
} from '../config/Paths'
import { ApiResponse } from '../types/ApiResponse'
import { ApiError } from '../types/ApiError'
import { Letter } from '../types/Letter'
import { Task } from '../types/Task'
import { Appointment } from '../types/Appointment'
import moment from 'moment'
import { LetterConfig, LetterFlag } from '../types/LetterFlag'
import { APIResult } from '../constants/APIResult'
import { RedCapDTO } from './REDCapService'

export interface PractitionerLetterDto {
  redCapDto: RedCapDTO
  practitionerLetterTemplate: string
}
export interface PractitionerLetterResponseDto {
  info?: string
  practitionerLetterTemplate: string
}

class LetterService extends ApiServiceBase {
  getContent(route: string): Promise<ApiResponse<string | ApiError>> {
    const url = new URL(API_LETTER + route)
    return this.get<null, string>(url, null, API_RETURN.text)
  }

  postLetter(
    letterRoute: string,
    letter: Letter
  ): Promise<ApiResponse<string | ApiError>> {
    return this.post<Letter, string>(
      new URL(API_LETTER + letterRoute),
      null,
      letter,
      API_RETURN.raw
    )
  }

  getLetterFromTask = (task: Task, templateUrl: string): Letter => {
    const required = this.configureLetter(templateUrl)
    return {
      patientId: required.patient ? task.for : null,
      practitionerId: required.practitioner ? task.owner : null,
      content: '',
      deadline: required.deadline ? task.deadline : null,
      start: required.start ? task.start_date : null,
      end: null, // there will be no end set by a task
    } as Letter
  }

  getLetterFromAppointment = (
    appointment: Appointment,
    templateUrl: string
  ): Letter => {
    const required = this.configureLetter(templateUrl)
    const firstPatient = appointment.participants.find(person => !person.role)
    const firstPractitioner = appointment.participants.find(
      person => person.role
    )
    return {
      patientId: required.patient ? firstPatient.id : null,
      practitionerId: required.practitioner ? firstPractitioner.id : null,
      content: '',
      deadline: required.deadline ? appointment.startingDateTime : null, // probably unused
      start: required.start ? appointment.startingDateTime : null,
      end: required.end
        ? moment(appointment.startingDateTime)
            .add(appointment.minutesDuration, 'minutes')
            .toDate()
        : null,
    } as Letter
  }

  async generateLetterFromPractitionerLetterTemplate(
    practitionerLetterTemplate: string,
    record: string,
    event: string,
    instance: string,
    forms: string[],
    letterConfig: LetterConfig
  ): Promise<ApiResponse<PractitionerLetterResponseDto | ApiError>> {
    const redCapDto: RedCapDTO = {
      events: [event],
      repeatInstance: [instance],
      records: [record],
      forms: forms,
    }
    return await this.post<
      PractitionerLetterDto,
      PractitionerLetterResponseDto
    >(
      new URL(API_LETTER + letterConfig.template),
      null,
      { redCapDto, practitionerLetterTemplate },
      API_RETURN.json
    )
  }

  getCustomLetter = (
    practitionerId?: string,
    patientId?: string,
    subject?: string,
    content?: string,
    deadline?: Date,
    start?: Date,
    end?: Date,
    signatureLeft?: string,
    signatureRight?: string
  ): Letter => {
    return {
      practitionerId: practitionerId ? practitionerId : null,
      patientId: patientId ? patientId : null,
      subject: subject ? subject : null,
      content: content ? content : null,
      deadline: deadline ? deadline : null,
      start: start ? start : null,
      end: end ? end : null,
      signatureLeft: signatureLeft ? signatureLeft : null,
      signatureRight: signatureRight ? signatureRight : null,
    } as Letter
  }

  loadLetterContent = async (templateUrl: string): Promise<string> => {
    const contentResult = await this.getContent(templateUrl)
    if (contentResult.Result === APIResult.SUCCESS) {
      if (contentResult.Response) {
        return contentResult.Response.toString()
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  configureLetter = (templateUrl: string): LetterFlag => {
    const letterConfig = Object.values(LETTER_PATHS).find(
      letterConfig => letterConfig.template === templateUrl
    )
    return letterConfig.required
  }

  getTemplate = async (
    key: string
  ): Promise<ApiResponse<string | ApiError>> => {
    return await this.get<string, string>(
      new URL(API_LETTER_TEMPLATE_DB + '/' + key),
      null,
      API_RETURN.text
    )
  }

  saveTemplate = async (
    key: string,
    template: string
  ): Promise<ApiResponse<null | ApiError>> => {
    return await this.post<Record<string, string>, null>(
      new URL(API_LETTER_TEMPLATE_DB),
      null,
      { key, template }
    )
  }
}

export default LetterService
