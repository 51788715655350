import React, { useState } from 'react'
import Select from 'react-select'
import { useFormikContext } from 'formik'
import { Table } from 'react-bootstrap'
import {
  DosageAndTimingSelectorProps,
  periodUnitOptions,
} from './DosageAndTimingSelector'
import { OnInsertedDosageChanged } from './OnInsertedDosageChanged'
import { OnInsertedPiecesChanged } from './OnInsertedPiecesChanged'
import { OnInsertedVolumeChanged } from './OnInsertedVolumeChanged'
import roundTo from 'round-to'

export const DosageOnDemandSelector: React.FC<DosageAndTimingSelectorProps> = (
  props: DosageAndTimingSelectorProps
): JSX.Element => {
  const { setFieldValue } = useFormikContext()

  const [inputValue, setInputValue] = useState(
    props.timingAndDosage.patientInstructions
  )

  function onInsertedDistanceChanged(value) {
    setFieldValue(
      `timeIntervals[${props.index}].timingAndDosage.dosages[0].distance`,
      value
    )
  }

  function onInsertedPeriodChanged(value) {
    setFieldValue(
      `timeIntervals[${props.index}].timingAndDosage.dosages[0].period`,
      value
    )
  }

  function onInsertedFrequencyMaxChanged(value) {
    setFieldValue(
      `timeIntervals[${props.index}].timingAndDosage.dosages[0].frequencyMax`,
      value
    )
  }

  function onInsertedPeriodUnitChanged(value) {
    setFieldValue(
      `timeIntervals[${props.index}].timingAndDosage.dosages[0].periodUnit`,
      value
    )
  }

  function onInsertedDistanceUnitChanged(value) {
    setFieldValue(
      `timeIntervals[${props.index}].timingAndDosage.dosages[0].distanceUnit`,
      value
    )
  }

  function onInsertedIndication(value) {
    setFieldValue(
      `timeIntervals[${props.index}].timingAndDosage.patientInstructions`,
      value
    )
  }

  return (
    <Table
      responsive
      className="dosage-timing-table mt-2"
      variant={props.isValueError ? 'danger' : ''}
      data-testid={'timing-dosage-on-demand-table-interval-' + props.index}
      bordered
      size="sm">
      <thead>
        <tr>
          <th className="dosage-timing-table-on-demand">
            Mindestabstand Einzeldosierung
          </th>
          <th className="dosage-timing-table-on-demand">
            Periodeneinheit Mindestabstand
          </th>

          {props.standardMedication?.ingredient[0]?.strength.numerator.unit ===
            'mg/ml' && (
            <th className="dosage-timing-table-on-demand">
              Maximale Einzeldosis [
              {props.standardMedication?.ingredient?.map((it, index) => {
                if (index === 0) {
                  return 'mg'
                } else {
                  return '|mg'
                }
              })}
              ]
            </th>
          )}
          {props.standardMedication?.ingredient[0]?.strength.numerator.unit !==
            'mg/ml' && (
            <th className="dosage-timing-table-on-demand">
              Maximale Einzeldosis [
              {props.standardMedication?.ingredient?.map((it, index) => {
                if (index === 0) {
                  return it.strength.numerator.unit
                } else {
                  return '|' + it.strength.numerator.unit
                }
              }) ?? ''}
              ]
            </th>
          )}
          {(props.standardMedication?.ingredient[0]?.strength.numerator.unit !==
            'mg/ml' ||
            props.standardMedication?.form?.text === 'Tropfen' ||
            props.standardMedication?.form?.text === 'Pflaster' ||
            props.standardMedication?.form?.text === 'Pen') && (
            <th className="dosage-timing-table-on-demand">
              Stückzahl maximale Einzeldosis [
              {props.standardMedication?.form?.text ?? ''}]
            </th>
          )}
          {props.standardMedication?.ingredient[0]?.strength.numerator.unit ===
            'mg/ml' && (
            <th className="dosage-timing-table-on-demand">
              Menge maximale Einzeldosis [ml]
            </th>
          )}
          <th className="dosage-timing-table-on-demand">Maximale Einnahmen</th>
          <th className="dosage-timing-table-on-demand">
            Periode Maximaleinnahmen
          </th>
          <th className="dosage-timing-table-on-demand">
            Periodeneinheit Maximaleinnahmen
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <input
              name={`timeIntervals[${props.index}].timingAndDosage.dosages[0].distance`}
              className="form-control"
              type="number"
              value={props.timingAndDosage.dosages[0].distance || ''}
              min={1}
              step={1}
              onChange={event => {
                onInsertedDistanceChanged(event.target.valueAsNumber)
              }}
            />
          </td>
          <td>
            <Select
              name={`timeIntervals[${props.index}].timingAndDosage.dosages[0].distanceUnit`}
              className={'distance-unit-select'}
              classNamePrefix={'distance-unit-select'}
              options={periodUnitOptions}
              value={props.timingAndDosage.dosages[0].distanceUnit}
              onChange={val => {
                onInsertedDistanceUnitChanged(val)
              }}
            />
          </td>
          <td>
            <Select
              name={`timeIntervals[${props.index}].timingAndDosage.dosages[0].dose`}
              className={'max-dosage-select'}
              classNamePrefix={'max-dosage-select'}
              options={props.dosageOptions}
              value={props.timingAndDosage.dosages[0].dose}
              onChange={val => {
                OnInsertedDosageChanged(
                  val.value,
                  val.label,
                  0,
                  props,
                  setFieldValue
                )
              }}
            />
          </td>
          {(props.standardMedication?.form?.text === 'Tropfen' ||
            props.standardMedication?.form?.text === 'Pflaster' ||
            props.standardMedication?.form?.text === 'Pen') && (
            <td>
              <input
                name={`timeIntervals[${props.index}].timingAndDosage.dosages[0].pieces`}
                className="form-control"
                type="number"
                value={props.timingAndDosage.dosages[0].pieces || ''}
                min={1}
                step={1}
                onChange={event => {
                  OnInsertedPiecesChanged(
                    event.target.valueAsNumber,
                    0,
                    props,
                    setFieldValue
                  )
                }}
              />
            </td>
          )}
          {props.standardMedication?.form?.text !== 'Tropfen' &&
            props.standardMedication?.form?.text !== 'Pflaster' &&
            props.standardMedication?.form?.text !== 'Pen' &&
            props.standardMedication?.ingredient[0]?.strength.numerator.unit !==
              'mg/ml' && (
              <td>
                <input
                  name={`timeIntervals[${props.index}].timingAndDosage.dosages[0].pieces`}
                  className="form-control"
                  type="number"
                  value={props.timingAndDosage.dosages[0].pieces || ''}
                  min={0.25}
                  step={0.25}
                  onChange={event => {
                    OnInsertedPiecesChanged(
                      event.target.valueAsNumber,
                      0,
                      props,
                      setFieldValue
                    )
                  }}
                />
              </td>
            )}
          {props.standardMedication?.ingredient[0]?.strength.numerator.unit ===
            'mg/ml' && (
            <td>
              <input
                name={`timeIntervals[${props.index}].timingAndDosage.dosages[0].volume`}
                className="form-control"
                type="number"
                value={
                  (props.timingAndDosage.dosages[0].volume ??
                    roundTo(
                      Number(
                        props.timingAndDosage.dosages[0].dose.value
                          .split('/')[0]
                          .replace(',', '.')
                      ) /
                        Number(
                          props.package.value.split('/')[0].replace(',', '.')
                        ),
                      8
                    )) ||
                  ''
                }
                min={0.05}
                step={0.05}
                onChange={event => {
                  OnInsertedVolumeChanged(
                    event.target.valueAsNumber,
                    0,
                    props,
                    setFieldValue
                  )
                }}
              />
            </td>
          )}
          <td>
            <input
              name={`timeIntervals[${props.index}].timingAndDosage.dosages[0].frequencyMax`}
              className="form-control"
              type="number"
              value={props.timingAndDosage.dosages[0].frequencyMax || ''}
              min={1}
              step={1}
              onChange={event => {
                onInsertedFrequencyMaxChanged(event.target.valueAsNumber)
              }}
            />
          </td>
          <td>
            <input
              name={`timeIntervals[${props.index}].timingAndDosage.dosages[0].period`}
              className="form-control"
              type="number"
              value={props.timingAndDosage.dosages[0].period || ''}
              min={1}
              step={1}
              onChange={event => {
                onInsertedPeriodChanged(event.target.valueAsNumber)
              }}
            />
          </td>
          <td>
            <Select
              name={`timeIntervals[${props.index}].timingAndDosage.dosages[0].periodUnit`}
              className={'duration-unit-select'}
              classNamePrefix={'duration-unit-select'}
              options={periodUnitOptions}
              value={props.timingAndDosage.dosages[0].periodUnit}
              onChange={val => {
                onInsertedPeriodUnitChanged(val)
              }}
            />
          </td>
        </tr>
        <tr>
          <td
            colSpan={
              props.standardMedication?.form?.text === 'Tropfen' ? 8 : 7
            }>
            <b>Indikation / Einnahmegrund</b>
          </td>
        </tr>
        <tr>
          <td
            colSpan={
              props.standardMedication?.form?.text === 'Tropfen' ? 8 : 7
            }>
            <textarea
              name={`timeIntervals[${props.index}].timingAndDosage.patientInstructions`}
              className="form-control"
              value={inputValue}
              key={'patient-instruction' + props.index}
              id={'patient-instruction' + props.index}
              onChange={event => setInputValue(event.target.value)}
              onBlur={event => {
                setInputValue(event.target.value)
                onInsertedIndication(event.target.value)
              }}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  )
}
