import { hasStatus } from '../services/FhirResourceService'
import moment from 'moment'
import { MedicationCode } from '../types/MedicationCode'
import { MedicationRequestStatus } from '../constants/MedicationRequestStatus'
import { ExtensionURL } from '../constants/ExtensionURL'
import { AuthContext } from '../infrastructure/AuthProvider'
import { UserPermission } from '../constants/UserPermission'

/**
 *
 * @param {string} timing
 * @return {string}
 */
export function getFirstTimeChoice(timing: string): string {
  return timing.match(/^([^;]+);?/)[1]
}

export interface MedicationTableRow extends MedicationActivityDefinition {
  timingChosen: string
  id: number
  listVariant: string
}

/**
 *
 * @param {Array.<StandardMedication>} standardMedications
 */
export function configureStandardMedications(
  standardMedications: MedicationTableRow[]
): MedicationTableRow[] {
  return standardMedications.map(medication => {
    medication.businessName = medication.businessName
      .replace('\n', '')
      .split(';')
      .map(value => value)[0]
    medication.timingChosen = getFirstTimeChoice(medication.timing)
    return medication
  })
}

export interface OptionChoices {
  value: string
  label: string
}

export function getChoicesSingle(choices: string): OptionChoices[] {
  if (choices.length > 0) {
    return choices.split(';').map(choice => {
      return { value: choice, label: choice }
    })
  } else return []
}

/**
 * finds first start date of all MedicationRequests
 *
 * @param medicationRequests
 * @returns {moment.Moment}
 */
export const findOverallStart = (
  medicationRequests: MedicationRequest[]
): any => {
  let overallStart = null
  for (const request of medicationRequests) {
    if (
      hasStatus(request.status, [
        MedicationRequestStatus.active,
        MedicationRequestStatus.on_hold,
      ])
    ) {
      const start = moment(
        request.dosageInstruction[0].timing.repeat.boundsPeriod.start
      )
      if (!overallStart || start.isBefore(overallStart)) {
        overallStart = start
      }
    }
  }
  return overallStart
}

export function parseSupportingInformationToCode(
  supportingInformation: Array<Reference>
): MedicationCode {
  return {
    coding: [{ code: 'standardMedicationReference' }],
    text: supportingInformation[0].reference,
  }
}

export const parseMedicationToCode = (
  medicationReference: Reference
): MedicationCode => {
  return {
    coding: [
      {
        code: 'urn:panos-backend-medication:Id',
        display: medicationReference.display,
      },
    ],
    text: medicationReference.reference.replace(/^#?\D+\/?/g, ''),
  }
}

export function parseIdentifierToCode(
  identifiers: Identifier[],
  identifierTag: string
): undefined | MedicationCode {
  const wantedIdentifier = identifiers.find(
    identifier => identifier.system === identifierTag
  )
  if (wantedIdentifier) {
    return {
      coding: [{ code: identifierTag }],
      text: wantedIdentifier.value,
    }
  }
  return undefined
}

export function parseDosageTimingCode(
  code: string,
  timing: Record<string, any>,
  startDate: Date
): any {
  const start = moment(startDate)
    .startOf('day')
    .add(timing.repeat.offset, 'days')
  const dosageAndTiming = code.split(';')
  let dosage
  let times
  let period
  let distance
  let frequencyMax
  let periodUnit
  let distanceUnit
  let indication
  if (dosageAndTiming.length === 9) {
    dosage = dosageAndTiming[0] + ';' + dosageAndTiming[1]
    times = dosageAndTiming[2].split(',')
    distance = dosageAndTiming[3]
    distanceUnit = dosageAndTiming[4]
    frequencyMax = dosageAndTiming[5]
    period = dosageAndTiming[6]
    periodUnit = dosageAndTiming[7]
    indication = dosageAndTiming[8]
  } else if (dosageAndTiming.length === 8) {
    dosage = dosageAndTiming[0]
    times = dosageAndTiming[1].split(',')
    distance = dosageAndTiming[2]
    distanceUnit = dosageAndTiming[3]
    frequencyMax = dosageAndTiming[4]
    period = dosageAndTiming[5]
    periodUnit = dosageAndTiming[6]
    indication = dosageAndTiming[7]
  }
  return {
    text: dosage,
    patientInstruction: indication,
    timing: {
      repeat: {
        boundsPeriod: {
          start: start.toISOString(true),
          end: start
            .endOf('day')
            .add(timing.repeat.boundsDuration.value, 'days')
            .toISOString(true),
        },
        frequency: times.length,
        extension: [
          {
            url: ExtensionURL.medicationRequestMinimalDistance,
            valueInteger: distance,
          },
          {
            url: ExtensionURL.medicationRequestMinimalDistanceUnit,
            valueCode: distanceUnit,
          },
        ],
        frequencyMax: frequencyMax,
        period: period,
        periodUnit: periodUnit,
        timeOfDay: times,
      },
    },
  }
}

/**
 * round now to next half hour
 *
 * @param {moment} now
 * @returns Date
 */
export function getNextHalfHour(now: moment.Moment): Date {
  const hour = now.hour()
  const minute = now.minute()

  if (minute < 30) now.set({ minute: 30 })
  if (minute >= 30) now.set({ hour: hour + 1, minute: 0 })

  return now.toDate()
}

/*
 * Checks whether a given dosage and a list of package variants fits so that the dosage is either a
 * quarter of a pill (resp. general unit) or a multiple thereof.
 *
 * Implementation details: For each package size, the minimal portion is calculated (0.25 units). Subsequently,
 * for a given dosage the ratio between its components (ingredients) and the minimal portions of each package is
 * calculated. Each component value must be an integer value (which means a multiple of 0.25) and must be the same
 * value (which means the package ratio matches).
 *
 * Terminology: Ingredient refers to a component in the ingredient name, e.g. the package variant 400/200/100 consists
 * of three ingredients 400, 200, 100
 *
 */
export function checkDosageRatioValidity(
  packageVariants: Array<OptionChoices>,
  selectedDosage: string
): boolean {
  const extractIngredients = (value: string): Array<number> => {
    value = value.replace(/,/g, '.')
    const ingredients = []
    value.split('/').forEach(ingredient => {
      if (isNaN(Number(ingredient))) {
        throw new Error(`Ingredient is not a number: ${ingredient}`)
      }
      ingredients.push(Number(ingredient))
    })
    return ingredients
  }

  const ingredientsDosage = extractIngredients(selectedDosage)

  let ingredientsPackageVariants
  try {
    ingredientsPackageVariants = packageVariants.map(packageVariant => {
      return extractIngredients(packageVariant.value)
    })
  } catch (e) {
    console.error(e)
    return false
  }

  const numberOfIngredients = ingredientsDosage.length
  const illegalPackageVariantIngredientAmount = ingredientsPackageVariants.find(
    ingredient => ingredient.length !== numberOfIngredients
  )

  if (illegalPackageVariantIngredientAmount) {
    return false // There is at least one package variant with a different number of ingredients
  }

  // List of the smallest amount for each package variant, assuming a quarter of a pill:
  const smallestIngredientsPackageVariants = ingredientsPackageVariants.map(
    packageVariants => packageVariants.map(value => value * 0.25)
  )

  // Final analysis: For the given dosage there must be a package variant having the same values or a multiple of four
  const finalRatioValues = smallestIngredientsPackageVariants.map(variant => {
    return variant.map((variant, index) => ingredientsDosage[index] / variant)
  })

  const validCombination = finalRatioValues.filter(finalRatioValue => {
    const firstValue = finalRatioValue[0]
    if (!Number.isInteger(firstValue)) {
      return null // Must be an integer, which indicates one quarter of a pill
    }

    // Check whether there is a different value in the ratio list --> ratio mismatch
    const differentValue = finalRatioValue.find(value => value !== firstValue)
    if (differentValue) {
      return null
    }

    return finalRatioValue // This is a valid combination
  })

  return validCombination && validCombination.length > 0
}

/*
  casemanager and practitioners operate on different status. practitioners on the real ones,
  casemanagers do it in an extension, because they are the arms of the practitioners.
 */
class MedicationStatusDelegationSwitch {
  copyAllStatus = (
    sourceMedicationRequest: MedicationRequest,
    targetMedicationRequest: MedicationRequest
  ) => {
    targetMedicationRequest.status = sourceMedicationRequest.status
    this.setProposedStatus(
      targetMedicationRequest,
      this.getProposedStatus(sourceMedicationRequest)
    )
  }

  changeStatus = (
    medicationRequest: MedicationRequest,
    status: MedicationRequestStatus,
    authContext: AuthContext
  ) => {
    if (
      authContext.handlers.hasPermission(
        UserPermission.PATIENT_MEDICATION_CONFIRM
      )
    ) {
      medicationRequest.status = status
      this.deleteProposedStatus(medicationRequest)
    } else {
      this.setProposedStatus(medicationRequest, status)
    }
  }

  isDraft = (medicationRequest: MedicationRequest) =>
    MedicationRequestStatus.draft ===
    this.getRealOverCaseManagerStatus(medicationRequest)

  getRealStatus = (
    medicationRequest: MedicationRequest
  ): MedicationRequestStatus => {
    if (!medicationRequest.status) return undefined
    return MedicationRequestStatus[medicationRequest.status]
  }

  getProposedStatus = (
    medicationRequest: MedicationRequest
  ): MedicationRequestStatus => {
    const existingExtension = medicationRequest.extension?.find(
      it => it.url === ExtensionURL.medicationRequestProposedStatus
    )
    if (existingExtension)
      return MedicationRequestStatus[existingExtension.valueString]
    else return undefined
  }

  statusDiffer = (medicationRequests: MedicationRequest[]): boolean =>
    medicationRequests.find(it => {
      const status = this.getProposedStatus(it)
      return status && it.status !== status
    }) !== undefined

  takeProposedStatus = medicationRequest =>
    (medicationRequest.status = this.getProposedStatus(medicationRequest))

  setProposedStatus = (
    medicationRequest: MedicationRequest,
    status: MedicationRequestStatus
  ) => {
    if (!medicationRequest.extension?.length) medicationRequest.extension = []
    const existingExtension = medicationRequest.extension?.find(
      it => it.url === ExtensionURL.medicationRequestProposedStatus
    )
    if (existingExtension) {
      existingExtension.valueString = status.toString()
    } else {
      medicationRequest.extension.push({
        url: ExtensionURL.medicationRequestProposedStatus,
        valueString: status,
      })
    }
  }

  deleteProposedStatus = (medicationRequest: MedicationRequest) =>
    (medicationRequest.extension = medicationRequest.extension?.filter(
      it => it.url !== ExtensionURL.medicationRequestProposedStatus
    ))

  getRealOverCaseManagerStatus = (medicationRequest: MedicationRequest) =>
    this.getRealStatus(medicationRequest) ??
    this.getProposedStatus(medicationRequest)

  getCaseManagerOverRealStatus = (medicationRequest: MedicationRequest) =>
    this.getProposedStatus(medicationRequest) ??
    this.getRealStatus(medicationRequest)

  getMyStatus = (
    medicationRequest: MedicationRequest,
    authContext: AuthContext
  ) =>
    !authContext.handlers.hasPermission(
      UserPermission.PATIENT_MEDICATION_CONFIRM
    )
      ? this.getProposedStatus(medicationRequest)
      : this.getRealStatus(medicationRequest)
}

export const MedicationStatusDelegation = new MedicationStatusDelegationSwitch()
