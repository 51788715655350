import React, { useEffect, useState } from 'react'
import PractitionerSelect from './PractitionerSelect'
import { Practitioner } from '../../../../types/Practitioner'
import PractitionerService from '../../../../services/PractitionerService'
import { APIResult } from '../../../../constants/APIResult'
import { getIdPartFromFhirResourceId } from '../../../../utils/Utils'
import FormErrorMessage from '../FormError/FormErrorMessage'

interface FormPractitionerSelectProps {
  name: string
  onChange?: (value: string) => void
  onChangeEvent?: (event: React.ChangeEvent<any>) => void
  label?: string | JSX.Element
  required?: boolean
  value?: string | null
  className?: string
  disabled?: boolean
  error?: string
  touched?: boolean
}

/**
 * Use this component with or without formik cotnext.
 *
 * Required props without Formik are:
 * -- name
 * -- value
 * -- onChange
 */

const FormPractitionerSelect: React.FC<FormPractitionerSelectProps> = ({
  name,
  value,
  onChangeEvent,
  onChange,
  className,
  error,
  label,
  touched,
  required,
  disabled,
}: FormPractitionerSelectProps) => {
  const inputValue = value !== null && value !== undefined ? value : null
  const [practitioner, setPractitioner] = useState<Practitioner>(null)

  useEffect(() => {
    const loadPractitioner = async () => {
      const inputPractitionerResponse =
        await new PractitionerService().getPractitioner(
          getIdPartFromFhirResourceId(inputValue)
        )

      if (
        inputPractitionerResponse.Result === APIResult.SUCCESS &&
        inputPractitionerResponse.Response
      )
        setPractitioner(inputPractitionerResponse.Response as Practitioner)
    }
    if (inputValue && !practitioner) {
      loadPractitioner()
    }
  }, [inputValue, practitioner])

  const onChangeHandler = (value: Practitioner): void => {
    if (onChangeEvent) {
      const event = {
        target: {
          name: name,
          value: value && 'Practitioner/' + value.id,
        },
        persist: () => {
          return
        },
      }
      onChangeEvent(event as React.ChangeEvent<any>)
    } else if (onChange) {
      onChange(value && 'Practitioner/' + value.id)
    }
  }
  const cssClasses = className ? [className] : []
  if (error) cssClasses.push('position-relative')
  if (error) cssClasses.push('validate-bad')

  return (
    <div className={`form-group ${cssClasses.join(' ')}`}>
      {label ? (
        <label className="form-label">
          {label}
          {required ? <span style={{ color: 'red' }}> *</span> : null}
        </label>
      ) : null}
      <PractitionerSelect value={practitioner} onChange={onChangeHandler} />
      <FormErrorMessage error={error} />
    </div>
  )
}

export default FormPractitionerSelect
