export enum TaskCategories {
  INDICATION_CHECK = 'Indikationsprüfung',
  MONITORING = 'Monitoring und Vorbefunde',
  TRIAGE = 'Triage',
  STATIC_ADMISSION = 'stat. Aufnahmeplanung',

  MEDICAL_ROUND_NONMEDICALLY = 'Zentrumsvisite nichtärztlich',
  MEDICAL_ROUND_MEDICALLY = 'Zentrumsvisite ärztlich',
  MEDICAL_ROUND_LICENSED = 'Visite Niedergelassene/r',
  CONTACT_LICENSED = 'Kontakt Niedergelasse/r',
  CONTACT_PATIENT = 'Kontakt Patient',
  CONTACT_OTHER = 'Kontakt Andere',
  MEETING = 'Besprechung',
  PATIENT_SCHOOL = 'Patientenschule',
  MOTOR_MEASUREMENT = 'Motorik-Messung',
  MISCELLANEOUS = 'Sonstiges',
}
