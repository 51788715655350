import React, { useContext, useEffect, useState } from 'react'
import evaluate from '../../../../utils/CalcParseUtils'
import { FormDynamicComponentProps } from '../../../FormDynamic/FormDynamicComponent'
import isEqual from 'lodash/isEqual'
import FormError from '../FormError/FormError'
import { EventStore } from '../../../../infrastructure/EventProviderInterfaces'
import FormInput from '../FormInput/FormInput'

interface FormCalcProps extends FormDynamicComponentProps {
  isPatient: boolean
}

const FormCalc: React.FC<FormCalcProps> = (props: FormCalcProps) => {
  const [value, setValue] = useState<unknown>('')
  const [error, setError] = useState<unknown>(false)

  const context = useContext(EventStore)

  useEffect(() => {
    if (!error && props.annotation?.calculation !== '') {
      try {
        const newValue = evaluate(
          props.annotation?.calculation || '',
          props.smartVariables || [],
          context.values as Record<string, unknown>,
          context.allMeta
        )
        if (
          !isEqual(context.values[props.name], newValue) ||
          !isEqual(value, newValue)
        ) {
          context.setFieldValue(props.name, newValue)
          context.setFieldTouched(props.name, true)
          setValue(newValue)
          setError(false)
        }
      } catch (e) {
        console.log('Error in calc component: ', (e as Error).message)
        setError(true)
      }
    }
  }, [
    props.annotation,
    props.smartVariables,
    props.name,
    context,
    value,
    error,
    setError,
  ])

  if (error) return <FormError />
  return (
    <FormInput
      {...props}
      value={value as string}
      disabled
      hidden={props.isPatient}
    />
  )
}

export default FormCalc
