import React from 'react'

interface FormErrorProps {
  children?: React.ReactNode
  className?: string | null
}

const FormError: React.FC<FormErrorProps> = ({
  children = 'Diese Komponente hat einen Fehler.',
  className = null,
}: FormErrorProps) => {
  return (
    <div className={`${className ? className : 'alert alert-error mt-2'}`}>
      <label>{children}</label>
    </div>
  )
}

export default FormError
