import {
  ROUTE_ADMINISTRATION,
  ROUTE_CALENDAR,
  ROUTE_CHAT,
  ROUTE_DASHBOARD,
  ROUTE_DRAFT,
  ROUTE_MEDICATION_DATA_PAGE,
  ROUTE_PATIENT_MONITORING,
  ROUTE_PATIENT_RECORDS,
  ROUTE_PATIENT_RELATIVE_MONITORING,
  ROUTE_PROFIL,
  ROUTE_TASKS,
  ROUTE_TELEFORM,
} from './Paths'
import { SidebarButtonData } from '../components/UI/Sidebar/SidebarEntry'
import { UserPermission } from '../constants/UserPermission'

export const AUTH_STATE_UNVERIFIED = 'panos.auth_state_unverified'
export const AUTH_UNVERIFIED = 'unverified'

// Prefix of Modules Event Name
export const MODULES_EVENT_PREFIX = 'aufruf'
// Prefix of Modules Form Name
export const MODULES_FORM_PREFIX = 'modul'
// Monitoring Event Name Patient
export const MONITORING_EVENT_NAME = 'monitoring_arm_1'

// Fragebögen Event Name relatives
export const RELATIVES_EVENT_NAME = 'angehoerigenboegen_arm_1'
// Event Name of Visite Zentrumsarzt
export const VISITE_ZENTRUM_EVENT_NAME = 'visite_zentrum_arm_1'
// Event Name Visite Niedergelassener Arzt
export const VISITE_NIEDERGELASSENER_EVENT_NAME = 'visite_ng_arm_1'
// Event Name Dringlichkeitsprüfung
export const PRIORITY_CHECK_EVENT_NAME = 'dringlichkeitsprue_arm_1'
// Event Name Änderungen Pumpe/ THS
export const AENDERUNG_PUMPE_THS_EVENT_NAME = 'aenderung_pumpe_th_arm_1'

// Form Name of Befunde Zuordnen Modul
export const BEFUNDE_EINLESEN = 'befunde_einlesen'
// Fragebögen Start Formular for patients and relatives
export const FRAGEBOEGEN_START = 'frageboegen_start'

// Fragebögen Event Names Patient
export const MONITORING_EVENT_NAMES = [
  MONITORING_EVENT_NAME,
  'patschule_fragen_p_arm_1',
  'patschule_eva_pati_arm_1',
]
// Fragebögen Event Names Relatives
export const MONITORING_RELATIVE_EVENT_NAMES = [
  RELATIVES_EVENT_NAME,
  'patschule_fragen_a_arm_1',
  'patschule_eva_ange_arm_1',
]

// Befunde einlesen Event Name
export const FINDINGS_EVENT_NAME = 'befund_einlesen_arm_1'

// Patientendaten Event Name
export const PATIENTS_DATA_EVENT_NAME = 'patientendaten_arm_1'

// Erstanmeldung Event Name
export const FIRST_REGISTRATION_EVENT_NAME = 'erstanmeldung_arm_1'

export const SIGNABLE_EVENTS = [
  VISITE_ZENTRUM_EVENT_NAME,
  VISITE_NIEDERGELASSENER_EVENT_NAME,
]

//PANOS working times
export const PANOS_WORK_START = 8
export const PANOS_WORK_END = 20

//region Sidebar

export enum NotificationTypes {
  UNASSIGNED_PATIENTS = 'UNASSIGNED_PATIENTS',
  OPEN_DRAFTS = 'OPEN_DRAFTS',
  UNREAD_MESSAGES = 'UNREAD_MESSAGES',
  UNREAD_TASKS = 'UNREAD_TASKS',
  OPEN_APPOINTMENTS = 'OPEN_APPOINTMENTS',
}

export const SIDEBAR_ENTRIES: SidebarButtonData[] = [
  {
    description: 'Übersicht',
    icon: 'dashboard',
    colorTheme: 'dashboard',
    path: ROUTE_DASHBOARD,
  },
  {
    description: 'Chats',
    icon: 'chat_bubble',
    colorTheme: 'chats',
    path: ROUTE_CHAT,
    notification: NotificationTypes.UNREAD_MESSAGES,
    permissision: UserPermission.MESSAGES,
  },
  {
    description: 'Aufgaben',
    icon: 'notifications',
    colorTheme: 'tasks',
    path: ROUTE_TASKS,
    notification: NotificationTypes.UNREAD_TASKS,
    permissision: UserPermission.TASK,
  },
  {
    description: 'Kalender',
    icon: 'today',
    colorTheme: 'calendar',
    path: ROUTE_CALENDAR,
    notification: NotificationTypes.OPEN_APPOINTMENTS,
    permissision: UserPermission.APPOINTMENTS,
  },
  {
    description: 'Patientenakten',
    icon: 'account_box',
    colorTheme: 'patient-records',
    path: ROUTE_PATIENT_RECORDS,
    notification: NotificationTypes.UNASSIGNED_PATIENTS,
    permissision: UserPermission.PATIENT_RECORDS,
  },
  {
    description: 'Entwürfe',
    icon: 'edit',
    path: ROUTE_DRAFT,
    notification: NotificationTypes.OPEN_DRAFTS,
    permissision: UserPermission.DRAFTS,
  },
  {
    description: 'Sonstiges',
    icon: 'apps',
    permissision: UserPermission.MEDICATIONS,
    children: [
      {
        description: 'Medikamentenliste',
        path: ROUTE_MEDICATION_DATA_PAGE,
      },
    ],
  },
  {
    description: 'Administration',
    icon: 'admin_panel_settings',
    colorTheme: 'administration',
    path: ROUTE_ADMINISTRATION,
    permissision: UserPermission.ADMIN_AREA,
  },
  {
    description: 'Monitoringbögen',
    icon: 'article',
    colorTheme: 'chats',
    path: ROUTE_PATIENT_MONITORING,
    permissision: UserPermission.PATIENT_MONITORING,
  },
  {
    description: 'Fragebögen für Angehörige',
    icon: 'article',
    colorTheme: 'chats',
    path: ROUTE_PATIENT_RELATIVE_MONITORING,
    permissision: UserPermission.PATIENT_MONITORING,
  },
  {
    description: 'Teleformscans hochladen',
    icon: 'compare',
    colorTheme: 'chats',
    path: ROUTE_TELEFORM,
    permissision: UserPermission.IMPORT_TELEFORM,
  },
  {
    description: 'Profil',
    icon: 'account_box',
    colorTheme: 'dashboard',
    path: ROUTE_PROFIL,
    permissision: UserPermission.USER_PROFILE,
  },
]

//endregion

// ScheduleTemplate sessionStorage

export const SCHEDULE_TEMPLATE_STATE = 'ScheduleTemplateState'

// form prefix to distinguish duplicate modules, e.g. m_formName_varName
export const MODULE_PREFIX = 'm'

// be constant to create new events from FieldFunction
export const CREATE_NEW_EVENT_FROM_LINK = 'create#new#event'

export enum PDF_KIND {
  PractitionerLetter = 'PractitionerLetter',
  Documentation = 'Documentation',
}
export const EVENT_PDF_CONFIG = {
  [VISITE_ZENTRUM_EVENT_NAME]: PDF_KIND.PractitionerLetter,
  [VISITE_NIEDERGELASSENER_EVENT_NAME]: PDF_KIND.Documentation,
}
