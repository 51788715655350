import React, { ChangeEvent, SyntheticEvent, useState } from 'react'
import { FormOption } from '../../../../types/FormOption'
import FormErrorMessage from '../FormError/FormErrorMessage'

import { v4 as uuidv4 } from 'uuid'

interface FormRadioProps {
  name: string
  onChange?: (value: string) => void
  onChangeEvent?: (event: ChangeEvent<HTMLInputElement>) => void
  options: FormOption[]
  label?: string | JSX.Element
  required?: boolean
  value?: string | null
  className?: string
  disabled?: boolean
  error?: string
  touched?: boolean
  inline?: boolean
}
/**
 * Use this component with or without formik cotnext.
 *
 * Required props without Formik are:
 * -- name
 * -- value
 * -- onChange
 */
const FormRadio: React.FC<FormRadioProps> = ({
  name,
  label,
  value,
  className,
  disabled,
  options,
  error,
  required,
  inline,
  onChange,
  onChangeEvent,
}: FormRadioProps) => {
  const inputValue = value !== null && value !== undefined ? value : null

  const [uniqueKey] = useState<string>(uuidv4())

  /* Other inputs have an "onChange"-handler, this one got an "onClick"-handler,
     because the people want to deselect the radio buttons, what is not supported
     by the "onChange" event, because from perspective of "clicking" to select,
     it is not changing.
   */
  const onClickHandler = (event: SyntheticEvent<HTMLInputElement>): void => {
    if (onChangeEvent) {
      onChangeEvent(event as ChangeEvent<HTMLInputElement>)
    } else if (onChange) {
      let value = (event.target as HTMLInputElement).value

      if (value === inputValue) {
        value = ''
      }
      onChange(value)
    }
  }
  const cssClasses = className ? [className] : []
  if (error) cssClasses.push('position-relative')
  if (inline) cssClasses.push('custom-control-inline')
  if (error) cssClasses.push('validate-bad')

  return (
    <div className={`form-group ${cssClasses.join(' ')}`}>
      {label ? (
        <label className="form-label">
          {label}
          {required ? <span style={{ color: 'red' }}> *</span> : null}
        </label>
      ) : null}
      {options.map(option => {
        const radioId = `${name}_${option.value}_${uniqueKey}`
        return (
          <div className="custom-control custom-radio" key={radioId}>
            <input
              className={`custom-control-input ${error ? 'is-invalid' : ''}`}
              type="radio"
              name={name}
              id={radioId}
              value={option.value}
              disabled={disabled}
              onClick={onClickHandler}
              checked={inputValue === option.value}
              readOnly
            />
            <label className="custom-control-label" htmlFor={radioId}>
              {option.label}
            </label>
            {inputValue === option.value && option.info}
          </div>
        )
      })}
      <FormErrorMessage error={error} />
    </div>
  )
}

export default FormRadio
