import React, { PureComponent, ReactNode } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import logo from '../../../assets/logo-transparent.png'
import { Link } from 'react-router-dom'
import IAuth from 'src/types/Auth'

interface HeaderProps {
  auth: IAuth
}

class Header extends PureComponent<HeaderProps> {
  render(): ReactNode {
    return (
      <Navbar bg="light" expand="lg">
        <Navbar.Brand as={Link} to="/">
          <img src={logo} style={{ maxHeight: '40px' }} alt="Panos Logo" />
        </Navbar.Brand>
        <Nav className="ml-auto mr-0">
          <Nav.Item>
            <Nav.Link as={Link} to="/login">
              Anmeldung
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link as={Link} to="/registration">
              Registrieren
            </Nav.Link>
          </Nav.Item> */}
          <Nav.Item>
            <Nav.Link
              href="https://parkinsonzentrum.uniklinikum-dresden.de/surveys/?s=9LY9XHFPEK"
              target="_blank">
              Patient für Panos anmelden
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar>
    )
  }
}

export default Header
