import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import IAuth from '../../types/Auth'
import TrackingService from '../../services/TrackingService'
import { TrackingLocation } from '../../types/TrackingLocation'

interface Props extends RouteComponentProps<any> {
  auth: IAuth
}

interface State {
  unsentLocations: TrackingLocation[]
  timeout: ReturnType<typeof setTimeout>
}

class RouteTracker extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      unsentLocations: [],
      timeout: null,
    }
  }

  sendLocations = () => {
    if (this.state.unsentLocations.length > 0) {
      const service = new TrackingService()
      service.postLocations(this.state.unsentLocations)
      if (this.state.timeout !== null) clearTimeout(this.state.timeout)
      this.setState({ unsentLocations: [], timeout: null })
    }
  }

  historyDidChange = location => {
    if (this.props.auth && this.props.auth.authenticated) {
      this.setState({
        unsentLocations: [
          ...this.state.unsentLocations,
          {
            time: new Date().toISOString(),
            location,
            origin: window.location.origin,
          },
        ],
      })
    }
  }

  componentDidMount() {
    window.onbeforeunload = this.sendLocations
    this.props.history.listen(this.historyDidChange)

    if (this.props.auth && this.props.auth.authenticated) {
      this.setState({
        unsentLocations: [
          {
            time: new Date().toISOString(),
            location: this.props.history.location,
            origin: window.location.origin,
          },
        ],
      })
    }
  }

  componentDidUpdate(_, prevState) {
    // comparing length will be enough since the objects within should not be changed
    if (
      prevState.unsentLocations.length !== this.state.unsentLocations.length
    ) {
      if (this.state.timeout !== null) clearTimeout(this.state.timeout)
      this.setState({ timeout: setTimeout(this.sendLocations, 5000) })
    }
  }

  render() {
    return null
  }
}

export default withRouter(RouteTracker)
