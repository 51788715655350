/* eslint-disable no-prototype-builtins */
import { Appointment } from '../types/Appointment'
import { Person } from '../types/Person'
import { Task } from '../types/Task'
import { ExtensionTypes } from '../constants/ExtensionTypes'
import { ICD10 } from '../types/ICD10'
import { Practitioner } from '../types/Practitioner'

export const dateFormat1 = new Intl.DateTimeFormat('de-DE', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
})
export const dateFormat2 = new Intl.DateTimeFormat('de-DE', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
})

export const fhirURIregexp =
  /((?<resourceType>[\w]+)\/)?(?<id>\d+)(\/_history\/(?<versionId>\d+))?/

export function getIdPartFromFhirResourceId(resourceId: string): string {
  const match = resourceId.match(fhirURIregexp)
  if (match === null) return null
  else return match.groups.id
}

export const addResourceTypeIfNot = (
  id: string,
  resourceType: string
): string => {
  if (!id || id === '' || id === 'null') return null
  if (id.indexOf('[') >= 0) console.error('Bad id  ' + id)
  if (id.indexOf(resourceType) < 0) return resourceType + '/' + id
  else return id
}

export const debounce = (
  milliseconds: number,
  fun: (...params: unknown[]) => unknown,
  immediately = false
): ((this: unknown, ...args: unknown[]) => number | unknown) => {
  let timer: ReturnType<typeof setTimeout> = null
  return function (this: unknown, ...args: unknown[]) {
    if (timer === undefined && immediately) {
      fun.apply(this, args)
    }
    clearTimeout(timer)
    timer = setTimeout(() => fun.apply(this, args), milliseconds)
    return timer
  }
}

export function isValidDate(date: unknown): boolean {
  return (
    Object.prototype.toString.call(date) === '[object Date]' &&
    !isNaN(date as number)
  )
}

export function parseUnknownToDate(value: unknown): Date {
  return new Date(value as string)
}

export function reduceDistinct<T>(
  array: T[],
  idGetter: (object) => string = val => val.tempId
): T[] {
  return array.reduce(
    (accumulator, value) =>
      accumulator.find(val => idGetter(val) === idGetter(value))
        ? accumulator
        : [...accumulator, value],
    []
  )
}

export function formatDate(date: Date, twodigit = true): string {
  if (!date) {
    return ''
  } else if (!(date instanceof Date) && typeof date === 'string') {
    date = new Date(date)
  }

  //@TODO NULLCHECK
  const options: Intl.DateTimeFormatOptions = twodigit
    ? {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }
    : {}

  try {
    return new Intl.DateTimeFormat('de-DE', options).format(date)
  } catch (e) {
    return 'Invalid Date'
  }
}

export function formatDateTime(date: Date): string {
  if (!date) {
    return ''
  } else if (!(date instanceof Date) && typeof date === 'string') {
    date = new Date(date)
  }

  //@TODO NULLCHECK
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }
  try {
    return new Intl.DateTimeFormat('de-DE', options).format(date)
  } catch (e) {
    return 'Invalid Date'
  }
}

export function formatTime(date: Date | string): string {
  if (!(date instanceof Date)) {
    date = new Date(date)
  }
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  }
  try {
    return new Intl.DateTimeFormat('de-DE', options).format(date) + ' Uhr'
  } catch (e) {
    return 'Invalid Date'
  }
}

export function isTomorrow(someDate: Date): boolean {
  if (!someDate) return false

  const tommorow = new Date()
  tommorow.setDate(tommorow.getDate() + 1)

  return (
    someDate.getDate() === tommorow.getDate() &&
    someDate.getMonth() === tommorow.getMonth() &&
    someDate.getFullYear() === tommorow.getFullYear()
  )
}

export function isToday(someDate: Date): boolean {
  if (!someDate) return false

  const today = new Date()

  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  )
}

export function matchDate(date: Date): string {
  if (date === null) return 'Kein Datum'
  if (isToday(date)) return 'Heute'
  if (isTomorrow(date)) return 'Morgen'
  if (isYesterday(date)) return 'Gestern'

  return formatDate(date)
}

export function isYesterday(someDate: Date): boolean {
  if (!someDate) return false

  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  return (
    someDate.getDate() === yesterday.getDate() &&
    someDate.getMonth() === yesterday.getMonth() &&
    someDate.getFullYear() === yesterday.getFullYear()
  )
}

export function addWeeks(nWeeks: number, date: Date): Date {
  return new Date(date.getTime() + 1000 * 60 * 60 * 24 * 7 * nWeeks)
}

export function addDays(nDays: number, date: Date): Date {
  return new Date(date.getTime() + 1000 * 60 * 60 * 24 * nDays)
}

export function addMinutes(nMinutes: number, date: Date): Date {
  return new Date(date.getTime() + 1000 * 60 * nMinutes)
}

export function getEnumKeyByEnumValue(
  myEnum: unknown,
  enumValue: unknown
): string | null {
  const keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue)
  return keys.length > 0 ? keys[0] : null
}

export function getFullName(person: Person): string {
  if (!person) return ''
  let value = ''
  if (person && (person.firstName || person.lastName)) {
    value = `${person.firstName || ''} ${person.lastName || ''}`
  }
  if (person.prefix) value = person.prefix + ' ' + value
  return value
}

export function getAddress(person: Person): string {
  if (!person) return ''
  if (person.street && person.streetNumber)
    return person.postalCode + ' ' + person.street + ' ' + person.streetNumber
}

export function getFullNamePractitioner(person: Practitioner): string {
  if (!person) return ''
  let value = getFullName(person)
  if (person?.qualifications && (person.qualifications as string[])?.length > 0)
    value += ', ' + person.qualifications
  if (person.lanr && person.lanr !== '') value += ', ' + person.lanr
  if (person.city && person.city !== '') value += ', ' + person.city

  return value
}

export function getFullNameAndAddressPractitioner(
  person: Practitioner
): string {
  if (!person) return ''
  let value = getFullNamePractitioner(person)
  if (person.street && person.streetNumber)
    value += ' (' + person.street + ' ' + person.streetNumber + ')'

  return value
}

export const pairwise = function* <T>(
  iterable: Iterable<T>
): Generator<[T, T]> {
  const iterator = [null, ...iterable][Symbol.iterator]()
  let current = iterator.next()
  let next = iterator.next()
  while (!current.done) {
    if (next.value) yield [current.value, next.value]
    current = next
    next = iterator.next()
  }
}

export function getArrayFromString(text: string | string[]): string[] | string {
  if (Array.isArray(text)) return text
  if (!text) return []
  const target = []
  const source = text.split(',')
  for (const item of source) {
    target.push(item.trim())
  }
  return target
}

export function formatRedcapDate(date: Date): string {
  return date.toLocaleDateString('fr-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}
export class _Array<T> extends Array<T> {
  static range(from: number, to: number, step: number): number[] {
    return Array.from(Array(Math.floor((to - from) / step) + 1)).map(
      (v, k) => from + k * step
    )
  }
}
_Array.range(0, 9, 1)

export const checkIfObjectHasPropStartingWith = (
  obj: Record<string, any>,
  str: string
): boolean => Object.keys(obj).some(prop => ~prop.indexOf(str))

export function fulfilled(
  settled: Array<PromiseSettledResult<Appointment | Task>>
): boolean {
  return settled.every(promise => promise.status === 'fulfilled')
}

export async function recordAllSettled(
  record: Record<string, unknown>
): Promise<Record<string, unknown>> {
  const promises = Object.values(record)
  if (promises && promises.length > 0) {
    await Promise.allSettled(promises)
    return record
  }
  return record
}

export const getExtensionValueByUrl = (
  extension: Array<Extension>,
  url: string
): string | number => {
  const item = extension?.find(item => item.url === url)
  if (item?.hasOwnProperty(ExtensionTypes.string)) {
    return item.valueString
  } else if (item?.hasOwnProperty(ExtensionTypes.code)) {
    return item.valueCode
  } else if (item?.hasOwnProperty(ExtensionTypes.integer)) {
    return item.valueInteger
  } else if (item?.hasOwnProperty(ExtensionTypes.decimal)) {
    return item.valueDecimal
  } else {
    return null
  }
}

const getExtensionIndexByUrl = (
  extension: Array<Extension>,
  url: string
): string | number => {
  return extension?.findIndex(item => item.url === url)
}

export const setOrAddExtension = (
  extension: Array<Extension>,
  url: string,
  value: string | number,
  extensionType: string
): Array<Extension> => {
  const index = getExtensionIndexByUrl(extension, url)
  switch (extensionType) {
    case ExtensionTypes.string:
      if (index !== -1) {
        extension[index] = {
          url: url,
          valueString: String(value),
        }
      } else {
        extension.push({
          url: url,
          valueString: String(value),
        })
      }
      break
    case ExtensionTypes.code:
      if (index !== -1) {
        extension[index] = {
          url: url,
          valueCode: String(value),
        }
      } else {
        extension.push({
          url: url,
          valueCode: String(value),
        })
      }
      break
    case ExtensionTypes.integer:
      if (index !== -1) {
        extension[index] = {
          url: url,
          valueInteger: Number(value),
        }
      } else {
        extension.push({
          url: url,
          valueInteger: Number(value),
        })
      }
      break
    case ExtensionTypes.decimal:
      if (index !== -1) {
        extension[index] = {
          url: url,
          valueDecimal: Number(value),
        }
      } else {
        extension.push({
          url: url,
          valueDecimal: Number(value),
        })
      }
      break
    default:
      break
  }
  return extension
}

export const getICD10Name = (icd10: ICD10): string => {
  let value = icd10.keyNoCross + ': '
  if (icd10.tripleTitle && icd10.tripleTitle !== '') value += icd10.tripleTitle
  if (icd10.quadTitle && icd10.quadTitle !== '')
    value += ' – ' + icd10.quadTitle
  if (icd10.quintTitle && icd10.quintTitle !== '')
    value += ' – ' + icd10.quintTitle
  return value
}
