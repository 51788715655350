import React, { useEffect, useState } from 'react'
import { formatDate, getIdPartFromFhirResourceId } from '../../utils/Utils'
import { Practitioner } from '../../types/Practitioner'
import PractitionerService from '../../services/PractitionerService'

export interface RedCapFieldQuoteProps {
  fieldName?: string
  repeatInstance?: string
  value?: string
  date?: string
  owner?: string
  record?: string
  formName?: string
  arm?: string
  event?: string
}

export const RedCapFieldQuote: React.FC<RedCapFieldQuoteProps> = ({
  fieldName,
  repeatInstance,
  value,
  date,
  owner,
  event,
}: RedCapFieldQuoteProps) => {
  const [practitioner, setPractioner] = useState<Practitioner>(null)
  useEffect(() => {
    if (owner && !practitioner) {
      const fun = async () => {
        const practitionerResponse =
          await new PractitionerService().getPractitioner(
            getIdPartFromFhirResourceId(owner)
          )
        setPractioner(practitionerResponse.Response as Practitioner)
      }
      fun()
    }
  })

  return (
    <div style={{ fontSize: 'small' }}>
      (
      {practitioner && (
        <span>
          <b>
            {[
              practitioner.qualifications,
              practitioner.firstName,
              practitioner.lastName,
            ]
              .flat()
              .join(' ')}
          </b>
          :{' '}
        </span>
      )}
      {fieldName && <span>Feld {fieldName} </span>}
      {repeatInstance && <span>Version {repeatInstance} </span>}
      {value && <span>Wert &quot;{value}&quot; </span>}
      {date && <span>am {formatDate(new Date(date))} </span>}
      {event && <span>Event {event} </span>})
    </div>
  )
}
