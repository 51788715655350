import { MedicationListTypes } from './MedicationListTypes'

export const ListTypeOptions = [
  {
    value: MedicationListTypes.standardList,
    label: 'Standardmedikamentenliste',
  },
  {
    value: MedicationListTypes.houseList,
    label: 'Medikamentenhausliste',
  },
]
