import React, { Component } from 'react'
import CalendarContainer from './Calendar/CalendarContainer'
import TabsComponent, { ITab } from '../UI/TabsComponent/TabsComponent'

interface State {
  activeTab: string
  tabs: ITab[]
}

class AppointmentsPage extends Component<unknown, State> {
  state = {
    activeTab: 'calendar',
    tabs: [{ title: 'Kalender', key: 'calendar' }],
  }

  onTabChanged = (key: string): void => {
    this.setState({
      activeTab: key,
    })
  }

  render(): JSX.Element {
    return (
      <>
        <TabsComponent
          tabs={this.state.tabs}
          activeTab={this.state.activeTab}
          onSelect={(key: string) => this.onTabChanged(key)}
        />
        {this.state.activeTab === 'calendar' && (
          <CalendarContainer
            onReloadedAppointments={numberoOfAppointments => {
              this.setState({
                tabs: this.state.tabs.map(tab =>
                  tab.key === 'calendar'
                    ? {
                        ...tab,
                        badge:
                          numberoOfAppointments > 0
                            ? numberoOfAppointments
                            : undefined,
                      }
                    : tab
                ),
              })
            }}
          />
        )}
      </>
    )
  }
}

export default AppointmentsPage
