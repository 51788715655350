import React, { ChangeEvent } from 'react'

interface FormInputProps {
  name: string
  onChange?: (value: string) => void
  onChangeEvent?: (event: ChangeEvent<HTMLInputElement>) => void
  label?: string | JSX.Element
  placeholder?: string
  required?: boolean
  value?: string | null
  info?: unknown
  type?: string
  className?: string
  pattern?: string
  disabled?: boolean
  error?: string
  touched?: boolean
}
/**
 * Use this component with or without formik cotnext.
 *
 * Required props without Formik are:
 * -- name
 * -- value
 * -- onChange
 */
const FormInputHidden: React.FC<FormInputProps> = ({
  name,
  value,
  className,
  error,
  onChange,
  onChangeEvent,
}: FormInputProps) => {
  const inputValue = value !== null && value !== undefined ? value : ''

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onChangeEvent) {
      onChangeEvent(event)
    } else if (onChange) {
      const { value } = event.target
      onChange(value)
    }
  }

  const cssClasses = className ? [className] : []
  if (error) cssClasses.push('position-relative')

  return (
    <input
      id={name}
      type={'hidden'}
      name={name}
      value={inputValue ? inputValue : ''}
      onChange={onChangeHandler}
    />
  )
}

export default FormInputHidden
