import React from 'react'
import { MedicationRequestStatus } from '../../../constants/MedicationRequestStatus'
import { Diffable } from './Diffable'

interface MedicationDiffProps {
  diff: any
  medicationRequest: MedicationRequestExtended
}

const INGORE_PATHS = [
  /medicationReference,reference/,
  /priorPrescription/,
  ///extension.*/,
]
export const MEDICATION_REQUEST_STATUS_NAMES = {
  [MedicationRequestStatus.draft]: 'in Bearbeitung',
  [MedicationRequestStatus.active]: 'aktiv',
  [MedicationRequestStatus.stopped]: 'abgesetzt',
  [MedicationRequestStatus.cancelled]: 'gelöscht',
  [MedicationRequestStatus.completed]: 'beendet',
  [MedicationRequestStatus.on_hold]: 'pausiert',
}

export const MedicationDiff: React.FC<MedicationDiffProps> = ({
  diff,
  medicationRequest,
}: MedicationDiffProps) => {
  const notDosis = diff?.filter(
    ({ path }) => path.length && path[0] !== 'dosageInstruction'
  )

  return (
    <>
      {notDosis?.map((difference, index_diff) => {
        const str_path = difference.path.join(',')

        if (INGORE_PATHS.some(it => it.test(str_path))) return null

        let v1 = difference.lhs
        let v2 = difference.rhs

        let extra_text = ''
        switch (true) {
          case str_path === 'status': {
            extra_text = 'Status' + difference.lhs + difference.rhs
            v1 = MEDICATION_REQUEST_STATUS_NAMES[difference.lhs]
            v2 = MEDICATION_REQUEST_STATUS_NAMES[difference.rhs]
            break
          }
          case /extension/.test(str_path):
          case /extension,\d,valueString/.test(str_path): {
            if (
              [
                MEDICATION_REQUEST_STATUS_NAMES[difference.lhs],
                (v2 = MEDICATION_REQUEST_STATUS_NAMES[difference.rhs]),
              ].some(it => it)
            ) {
              extra_text = 'Vorgemerkter Status'
              v1 = MEDICATION_REQUEST_STATUS_NAMES[difference.lhs]
              v2 = MEDICATION_REQUEST_STATUS_NAMES[difference.rhs]
            } else return null
            break
          }

          case str_path ===
            'contained,0,ingredient,0,strength,numerator,value': {
            extra_text = 'Packungsgröße:'
            break
          }
          case str_path === 'note': {
            extra_text = 'Grund der (veränderten) Anordnung'
            break
          }
          case /extension,\d,url/.test(str_path): {
            medicationRequest.extension.filter(it => it.url === '')
            break
          }

          case /note,\d+,text/.test(str_path): {
            extra_text = 'Warnhinweise'
            break
          }
          case str_path === 'priorPrescription,reference': {
            extra_text = 'Ersetzt'
            break
          }

          default: {
            v1 = JSON.stringify(difference.lhs)
            v2 = JSON.stringify(difference.rhs)
            extra_text =
              JSON.stringify(difference.path) +
              ` path ${difference.path.join(',')}`
            break
          }
        }
        /*
          N - indicates a newly added property/element
          D - indicates a property/element was deleted
          E - indicates a property/element was edited
          A - indicates a change occurred within an array
          *
           */

        return (
          <p key={index_diff}>
            {extra_text}:
            <Diffable
              wrapperElement={<span />}
              key={index_diff}
              index={index_diff}
              change={{ ...difference, rhs: v1 }}
              formatFunction={it => (it ? it.toString() : null)}>
              {v2}
            </Diffable>
          </p>
        )
      })}
    </>
  )
}
