import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import moment from 'moment'
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TodayMarker,
} from 'react-calendar-timeline'
import {
  createMedicationRequest,
  getMedicationRequestById,
  getMedicationRequestsByCarePlan,
  updateMedicationRequestById,
} from '../../../services/MedicationRequestService'
import { getMedicationById } from '../../../services/MedicationService'
import { Button, Dropdown } from 'react-bootstrap'
import MedicationRequestModal from '../MedicationRequest/MedicationRequestModal'
import _, { cloneDeep } from 'lodash'
import { getActivityDefinitionByMedication } from '../../../services/ActivityDefinitionService'
import './MedicationSchedule.scss'
import { LeftSidebarItemComponent, RightSidebarItemComponent } from './Sidebars'
import { GetWarningButton, OverlayMenuButton } from './ActionButtons'
import { StandardMedicationRequest } from '../MedicationRequest/StandardMedicationRequest'
import { findValueFromIdentifiers } from '../../../services/FhirResourceService'
import LocalStorageService from '../../../services/LocalStorageService'
import { NotificationManager } from 'react-notifications'
import MaterialIcon from '../../UI/MaterialIcon/MaterialIcon'
import {
  AuthContext,
  AuthStore,
  withAuth,
} from '../../../infrastructure/AuthProvider'
import PlanDefinitionTemplate from '../MedicationTemplatePlans/PlanDefinitionTemplate'
import CarePlanTemplate from '../MedicationTemplatePlans/CarePlanTemplate'
import { CarePlan } from '../../../types/CarePlan'
import CarePlanService from '../../../services/CarePlanService'
import { APIResult } from '../../../constants/APIResult'
import { defaultCarePlanFromMedicationRequests } from '../../../utils/CarePlanUtils'
import 'react-calendar-timeline/lib/Timeline.css'
import { MEDICATION_CONFIRM_STORAGE } from '../../../config/Storages'
import Loader from '../../UI/Spinner/Loader'
import { CarePlanStatus } from '../../../constants/CarePlanStatus'
import {
  addResourceTypeIfNot,
  getExtensionValueByUrl,
} from '../../../utils/Utils'
import { MedicationRequestStatus } from '../../../constants/MedicationRequestStatus'
import {
  getNextHalfHour,
  MedicationStatusDelegation,
} from '../../../utils/MedicationUtils'
import {
  addMedicationRequestToSchedule,
  completedItemExists,
  getPackage,
  mapDosageInstruction,
  mapMedRequest,
  mapRequestsToSchedule,
  MedicationScheduleObject,
} from '../../../utils/MedicationScheduleUtils'
import {
  MedicationReason,
  MedicationRequestUpdateWithReason,
} from './MedicationReason'
import { ExtensionURL } from '../../../constants/ExtensionURL'
import PatientService from '../../../services/PatientService'
import { MedicationRequestInitialRationale } from '../../../constants/MedicationRequestInitialRationale'
import { MedicationRequestPanosRationale } from '../../../constants/MedicationRequestPanosRationale'
import { UserPermission } from '../../../constants/UserPermission'
import { HistoryModal } from '../MedicationHistory/HistoryModal'
import { DynamicSidebarStore } from '../../../infrastructure/DynamicSidebarProvider'
import { Patient } from '../../../types/Patient'
import {
  getCaseManagerTask,
  getRemoveRestoreControlConfig,
} from '../../../utils/MedicationScheduleControlUtils'
import { itemRenderer } from './ItemRenderer'
import {
  activateMedicationRequestsAfterReLogin,
  activateMedicationRequestsBeforeReLogin,
} from '../ReloginOrTask'
import { AxiosResponse } from 'axios'

/**
 * @type {MedicationScheduleFormikValues}
 */
const emptyFormikValues = {
  label: '',
  timeIntervals: [],
  note: '',
  warning: '',
  package: {
    value: '',
    label: '',
  },
  historyReason: {
    label: MedicationRequestInitialRationale['BY_PATIENT'],
    value: MedicationRequestInitialRationale.BY_PATIENT,
  },
  arrangementReason: {
    value: '',
    label: '',
  },
  medicationId: null,
  medicationRequestId: null,
  activityDefinitionIds: null,
  shape: null,
  businessName: null,
  activityDefinition: null,
  standardMedicationId: null,
  standardMedication: null,
  isEditing: false,
  autIdem: true,
}

const emptyGroup = {
  id: 'empty',
  title: <div className="text-center">keine Einträge in diesem Zeitraum</div>,
}

export interface MedicationScheduleProps extends AuthContext {
  patientId: string
  carePlan?: CarePlan
  setCarePlan?: (carePlan: CarePlan) => void
  setMedicationRequests?: (medicationRequests: MedicationRequest[]) => void
  disableConfirm?: boolean
  disableCarePlanSaving?: boolean
  isSubComponent?: boolean
  reason: MedicationReason
}

export const fetchCarePlan = async (
  reason: string,
  patientId: string
): Promise<null | CarePlan> => {
  const carePlanService = new CarePlanService(reason)
  const activeCarePlanResponse = await carePlanService.getActiveCarePlan(
    patientId
  )
  let activeCarePlan = null
  if (
    activeCarePlanResponse.Result === APIResult.SUCCESS &&
    (activeCarePlanResponse.Response as CarePlan).id
  ) {
    activeCarePlan = activeCarePlanResponse.Response as CarePlan
  }
  return activeCarePlan
}

const fetchActiveCarePlan = async (
  updateIsRun: boolean,
  updateIsRunning: boolean,
  patientId: string,
  schedule: MedicationScheduleObject,
  props: MedicationScheduleProps,
  setMedicationRequestsFromCarePlan
) => {
  const activeCarePlan = await fetchCarePlan(props.reason, patientId)
  if (activeCarePlan !== null) {
    setMedicationRequestsFromCarePlan(activeCarePlan)
    return activeCarePlan
  } else {
    if (updateIsRun && !updateIsRunning) {
      const updatedCarePlanResponse = await new CarePlanService(
        props.reason
      ).updateCarePlan(
        defaultCarePlanFromMedicationRequests(
          patientId,
          schedule.requests,
          null,
          CarePlanStatus.active
        )
      )
      if (updatedCarePlanResponse.Result === APIResult.SUCCESS) {
        setMedicationRequestsFromCarePlan(
          updatedCarePlanResponse.Response as CarePlan
        )
      } else {
        NotificationManager.error(
          'Es gab keinen aktiven Medikamentenplan und es konnte' +
            ' kein neuer Medikamentenplan erstellt werden'
        )
      }
    }
  }
}

/**
 *
 * @returns {*}
 * @constructor
 * @param props
 */
const MedicationSchedule: React.FC<MedicationScheduleProps> = (
  props: MedicationScheduleProps
) => {
  const patientId = props.patientId
  const [carePlan, setCarePlan] = useState<CarePlan>(null)
  const [treatingPractitioner, setTreatingPractitioner] = useState(null)

  const _isMountedRef = useRef(null)

  useEffect(() => {
    _isMountedRef.current = true
    const patientService = new PatientService()
    patientService
      .getPatient(patientId)
      .then(patientRequest => {
        if (
          patientRequest.Result === APIResult.SUCCESS &&
          _isMountedRef.current
        ) {
          const treatingPhysicianDtos = (patientRequest.Response as Patient)
            .treatingPhysicianDtos

          /** @see { PatientPersonalDataPage } onSetPrimary() */
          const primaryPhysician = treatingPhysicianDtos
            ? treatingPhysicianDtos[0]
            : null
          if (treatingPhysicianDtos && primaryPhysician)
            setTreatingPractitioner(primaryPhysician)
          else
            NotificationManager.error(
              'Konnte keinen behandelnden Arzt für den Patienten finden.'
            )
        } else {
          NotificationManager.error('Konnte den Patienten nicht finden.')
        }
      })
      .catch(() =>
        NotificationManager.error('Konnte den Patienten nicht finden.')
      )
    //getPatient(patientId)
    return () => {
      _isMountedRef.current = false
    }
  }, [patientId, setTreatingPractitioner])

  // For saving while relogin
  const localStorageService = useMemo(
    () =>
      new LocalStorageService<MedicationRequestUpdateWithReason>(
        MEDICATION_CONFIRM_STORAGE
      ),
    []
  )
  const [updateIsRun, setUpdateIsRun] = useState(false)
  const [updateIsRunning, setUpdateIsRunning] = useState(false)

  const [medicationRequestsToUpdate, setMedicationRequestsToUpdate] = useState<
    MedicationRequest[]
  >([])

  const currentDate = new Date()
  const dateStr = currentDate.toISOString().split('T').shift()
  const startTime = '05:00'
  const endTime = '24:00'
  const [schedule, setSchedule] = useState<MedicationScheduleObject>({
    groups: [],
    items: [],
    requests: [],
  })

  const [showMedRequestModal, setShowMedRequestModal] = useState<boolean>(false)
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false)
  const [initialValuesRequest, setInitialValuesRequest] =
    useState<MedicationScheduleFormikValues>(emptyFormikValues)

  const [visibleTime, setVisibleTime] = useState({
    start: moment(dateStr + ' ' + startTime).valueOf(),
    end: moment(dateStr + ' ' + endTime).valueOf(),
  })

  const [medicationplanLoading, setMedicationplanLoading] = useState(false)

  const medicationCreateClick = () => {
    setInitialValuesRequest({
      ...emptyFormikValues,
      timeIntervals: [
        {
          name: 'timeIntervals',
          startDate: getNextHalfHour(moment()),
          endDate: new Date('2099-12-31T00:00:00'),
          timingAndDosage: {
            timing: [],
            selectedTiming: null,
            dosages: [],
            patientInstructions: '',
          },
        },
      ],
    })
    setShowMedRequestModal(true)
  }

  const setMedicationRequestsFromCarePlan = useCallback(
    carePlan => {
      if (carePlan && carePlan.id) {
        if (!props.carePlan) {
          getMedicationRequestsByCarePlan(carePlan.id)
            .then(responseMedRequests => {
              if (responseMedRequests.data && _isMountedRef.current) {
                const objects = responseMedRequests.data.map(medRequest =>
                  JSON.parse(medRequest)
                )
                setSchedule(mapRequestsToSchedule(objects))
              }
            })
            .catch(e => {
              NotificationManager.error(
                'Fehler beim Laden des Medikamentenplans '
              )
              console.error(e)
            })
        }
        if (props.carePlan) {
          Promise.all(
            props.carePlan.medicationRequests.map(it =>
              getMedicationRequestById(it)
            )
          ).then(responses => {
            if (_isMountedRef.current) {
              const medicationRequests = responses.map(it => it.data)
              setSchedule(
                addMedicationRequestToSchedule(schedule, medicationRequests)
              )
            }
          })
        }
      }
    },
    [schedule, props]
  )

  const checkAndFetchActiveCarePlan = useCallback(async () => {
    const carePlan = await fetchActiveCarePlan(
      updateIsRun,
      updateIsRunning,
      patientId,
      schedule,
      props,
      setMedicationRequestsFromCarePlan
    )
    if (carePlan) {
      setCarePlan(carePlan)
      setMedicationRequestsFromCarePlan(carePlan)
    }
  }, [
    schedule,
    props,
    setMedicationRequestsFromCarePlan,
    patientId,
    updateIsRun,
    updateIsRunning,
  ])

  /** If there is no careplan get it from props or fetch it */
  useEffect(() => {
    _isMountedRef.current = true
    if (!carePlan && updateIsRun && !updateIsRunning) {
      if (props.carePlan && _isMountedRef.current) {
        setCarePlan(props.carePlan)
        setMedicationRequestsFromCarePlan(props.carePlan)
      } else {
        checkAndFetchActiveCarePlan().then()
      }
    }

    return () => {
      _isMountedRef.current = false
    }
  }, [
    carePlan,
    updateIsRun,
    updateIsRunning,
    checkAndFetchActiveCarePlan,
    setMedicationRequestsFromCarePlan,
    props.carePlan,
  ])

  const { setCarePlan: setPropsCareplan } = props
  useEffect(() => {
    _isMountedRef.current = true
    if (setPropsCareplan)
      setPropsCareplan({
        ...carePlan,
        medicationRequests: schedule.requests.map(it => it.id.toString()),
      })
    if (props.setMedicationRequests)
      props.setMedicationRequests(schedule.requests)
    return () => {
      _isMountedRef.current = false
    }
  }, [schedule.requests, carePlan, setPropsCareplan, schedule, props])

  const displayGroups = [
    ...schedule.groups
      .filter(
        group =>
          moment(group.startTime).isSameOrBefore(visibleTime.end) &&
          moment(group.endTime).isSameOrAfter(visibleTime.start)
      )
      .map(group => {
        return {
          ...group,
          title: (
            <LeftSidebarItemComponent group={group} groups={schedule.groups} />
          ),
          rightTitle: group.isGroupHeader ? (
            <></>
          ) : (
            <RightSidebarItemComponent
              disabled={
                (!props.handlers.hasPermission(
                  UserPermission.PATIENT_MEDICATION_CONFIRM
                ) &&
                  !props.handlers.hasPermission(
                    UserPermission.PATIENT_MEDICATION_CREATE_TASK
                  )) ||
                props.disableConfirm
              }
              group={group}
              onRemove={() => removeClick(group.medicationRequest)}
              onResume={() => resumeClick(group.medicationRequest)}
              onEdit={() =>
                editClick(
                  group.medicationRequest,
                  group.groupIdKey,
                  group.standardMedicationId
                )
              }
              onConfirm={() => confirmClick(group.medicationRequest.id)}
            />
          ),
        }
      }),
  ]

  /**
   *
   * @param {MedicationRequest} medicationRequest
   */
  async function removeClick(medicationRequest) {
    if (medicationRequest.status === MedicationRequestStatus.completed) {
      NotificationManager.error(
        'Eine Verschreibung, die in der Vergangenheit lag, kann nicht gelöscht werden'
      )
    }
    delete medicationRequest.meta
    const untouchedMedicationRequests = schedule.requests.filter(
      it => it.id !== medicationRequest.id
    )
    let updatedSchedule = null
    const medicationRequestsToEdit = [medicationRequest]

    MedicationStatusDelegation.changeStatus(
      medicationRequest,
      MedicationRequestStatus.stopped,
      authContext
    )
    if (MedicationStatusDelegation.isDraft(medicationRequest))
      medicationRequest.status = MedicationRequestStatus.cancelled

    /**
     * parent MedRequest is self-referenced for sorting reasons
     * in field priorPrescription
     */
    medicationRequest.priorPrescription = {
      reference: 'MedicationRequest/' + medicationRequest.id,
    }

    const start = moment(
      medicationRequest.dosageInstruction[0].timing.repeat.boundsPeriod.start
    ).startOf('minute')
    const now = moment().startOf('minute')

    const timesAndPeriods = medicationRequest.dosageInstruction
      .filter(
        it =>
          it.timing.repeat.boundsPeriod.start ===
          medicationRequest.dosageInstruction[0].timing.repeat.boundsPeriod
            .start
      )
      .map(it => {
        const times = it.timing.repeat.timeOfDay?.map(itTime => itTime)
        return [times, it.timing.repeat.period]
      })

    const itemExists = timesAndPeriods.find(it =>
      completedItemExists(it[1], start, now, it[0])
    )

    if (itemExists) {
      const completedMedRequest = cloneDeep(medicationRequest)
      MedicationStatusDelegation.changeStatus(
        completedMedRequest,
        MedicationRequestStatus.completed,
        authContext
      )
      completedMedRequest.dosageInstruction = []
      completedMedRequest.dosageInstruction = cloneDeep(
        medicationRequest.dosageInstruction
      )
        .filter(it => moment(it.timing.repeat.boundsPeriod.start) < now)
        .map(filtered => {
          if (moment(filtered.timing.repeat.boundsPeriod.end) >= now) {
            filtered.timing.repeat.boundsPeriod.end = now.toISOString(true)
          }
          return filtered
        })
      completedMedRequest.priorPrescription =
        medicationRequest.priorPrescription

      const originalDosageInstruction = cloneDeep(
        medicationRequest.dosageInstruction
      )
      medicationRequest.dosageInstruction = []
      medicationRequest.dosageInstruction = originalDosageInstruction
        .filter(it => moment(it.timing.repeat.boundsPeriod.end) > now)
        .map(filtered => {
          if (moment(filtered.timing.repeat.boundsPeriod.start) <= now) {
            filtered.timing.repeat.boundsPeriod.start = now.toISOString(true)
          }
          return filtered
        })

      const responseCompletedRequest = await createMedicationRequest(
        completedMedRequest
      )
      medicationRequestsToEdit.push(responseCompletedRequest.data)
    }

    const caseManagerTask = getCaseManagerTask(
      [medicationRequest],
      treatingPractitioner
    )

    if (!MedicationStatusDelegation.isDraft(medicationRequest)) {
      await activateMedicationRequestsBeforeReLogin(
        medicationRequest.id,
        [...medicationRequestsToEdit, ...untouchedMedicationRequests],
        props.reason,
        carePlan,
        sidebarContext,
        authContext,
        patientId,
        localStorageService,
        caseManagerTask,
        true,
        true
      )
    }

    const responseMedRequests = await updateMedicationRequestById(
      medicationRequest,
      medicationRequest.id
    )

    updatedSchedule = addMedicationRequestToSchedule(
      schedule,
      Array(responseMedRequests.data)
    )
    setSchedule(updatedSchedule)
    if (!(updatedSchedule && carePlan.id)) return

    await new CarePlanService(props.reason).updateCarePlansMedicationRequests(
      carePlan.id,
      updatedSchedule.requests.map(it => it.id.toString())
    )
  }

  /**
   *
   * @param {MedicationRequest} medicationRequest
   */
  async function resumeClick(medicationRequest) {
    MedicationStatusDelegation.changeStatus(
      medicationRequest,
      medicationRequest.status === MedicationRequestStatus.active
        ? medicationRequest.status
        : MedicationRequestStatus.on_hold,
      authContext
    )

    const responseMedRequests = await updateMedicationRequestById(
      medicationRequest,
      medicationRequest.id
    )
    if (
      _isMountedRef.current &&
      (responseMedRequests.status === 200 || responseMedRequests.data)
    ) {
      const updatedSchedule = addMedicationRequestToSchedule(
        schedule,
        Array(responseMedRequests.data)
      )
      setSchedule(updatedSchedule)

      await new CarePlanService(props.reason).updateCarePlansMedicationRequests(
        carePlan.id,
        updatedSchedule.requests.map(it => it.id.toString())
      )
    } else {
      NotificationManager.error(
        'Es trat ein Fehler beim Löschen der Verschreibung auf.'
      )
    }
  }

  /**
   * medicationRequestId is needed although it is nowhere in MedicationRequestModal
   * But we need to give the information to onSubmit
   * @param medicationRequest {MedicationRequest}
   * @param medicationId {id}
   * @param standardMedicationId {id}
   */
  async function editClick(
    medicationRequest,
    medicationId,
    standardMedicationId
  ) {
    console.log({ medicationRequest, medicationId, standardMedicationId })

    const historyReason = getHistoryReason(medicationRequest)
    const arrangementReason = getArrangementReason(medicationRequest)

    const response = await getActivityDefinitionByMedication(
      standardMedicationId
    )
    try {
      if (response.data) {
        /**
         * Values for our form in MedicationRequestModalEdit
         * @type {MedicationScheduleFormikValues}
         */
        const object = response.data.map(actDef => JSON.parse(actDef))
        const medicationTypeId = addResourceTypeIfNot(
          standardMedicationId,
          'Medication'
        )
        const medication =
          medicationRequest.contained.find(
            it => it.resourceType === 'Medication'
          ) ?? (await getMedicationById(medicationTypeId)).data

        if (_isMountedRef.current && medication) {
          const { timeIntervals } = mapDosageInstruction(
            medicationRequest.dosageInstruction,
            getPackage(object[0], medicationId).value
          )

          const shapeSelection = findValueFromIdentifiers(
            medicationRequest.identifier,
            'urn:panos-backend-medication:Form'
          )
          const requestValues = {
            label: medicationRequest.note[0].text,
            timeIntervals: timeIntervals,
            medicationId: medicationId,
            note: getExtensionValueByUrl(
              medicationRequest.extension,
              ExtensionURL.medicationRequestReason
            )?.toString(),
            warning: medicationRequest.note[1]
              ? medicationRequest.note[1].text
              : '',
            package: getPackage(object[0], medicationId),
            historyReason: historyReason,
            arrangementReason: arrangementReason,
            activityDefinitionIds: object[0].id,
            medicationRequestId: medicationRequest.id,
            shape: {
              value: shapeSelection,
              label: shapeSelection,
            },
            businessName: {
              value: medicationId + '|' + object[0].id,
              label: object[0].productReference.display,
            },
            /** @type {ActivityDefinition} */
            activityDefinition: object[0],
            standardMedicationId: standardMedicationId,
            /** @type {Medication} */
            standardMedication: medication,
            isEditing: true,
            autIdem: medicationRequest.substitution?.allowedBoolean,
          }
          setInitialValuesRequest(requestValues)
          setShowMedRequestModal(true)
        }
      }
    } catch (error) {
      // TODO error message

      NotificationManager.error('Verschreibung konnte nicht editiert werden')
      console.error('Writing medication', error)
    }
  }

  function getHistoryReason(medicationRequest: MedicationRequest) {
    let historyReason = getExtensionValueByUrl(
      medicationRequest.extension,
      ExtensionURL.medicationRequestHistoryReason
    )
    const arrangementReason = getExtensionValueByUrl(
      medicationRequest.extension,
      ExtensionURL.medicationRequestArrangementReason
    )

    if (!historyReason) {
      if (!arrangementReason) {
        historyReason = 'ADOPTED_FROM_PREVIOUS_PLAN'
      } else {
        return {
          label: '',
          value: '',
        }
      }
    }

    return {
      label: MedicationRequestInitialRationale[historyReason],
      value: historyReason,
    }
  }

  function getArrangementReason(medicationRequest: MedicationRequest) {
    const arrangementReason = getExtensionValueByUrl(
      medicationRequest.extension,
      ExtensionURL.medicationRequestArrangementReason
    )

    if (!arrangementReason) {
      return {
        label: '',
        value: '',
      }
    } else {
      return {
        label: MedicationRequestPanosRationale[arrangementReason],
        value: arrangementReason,
      }
    }
  }

  const sidebarContext = useContext(DynamicSidebarStore)
  const authContext = useContext(AuthStore)

  async function confirmClick(groupId = null) {
    await activateMedicationRequestsBeforeReLogin(
      groupId,
      schedule.requests,
      props.reason,
      carePlan,
      sidebarContext,
      authContext,
      patientId,
      localStorageService,
      getCaseManagerTask(
        groupId
          ? schedule.requests.filter(it => it.id === groupId)
          : schedule.requests,
        treatingPractitioner
      )
    )
  }

  const stopOtherMedicationRequests = async (
    medicationRequestsToStop,
    status = MedicationRequestStatus.stopped
  ) => {
    medicationRequestsToStop.forEach(it => {
      if (
        MedicationStatusDelegation.getMyStatus(it, authContext) ===
        MedicationRequestStatus.stopped
      ) {
        MedicationStatusDelegation.changeStatus(it, status, authContext)
      }
    })
    const responseMedRequests = (await Promise.all(
      medicationRequestsToStop.map(
        async it => await updateMedicationRequestById(it, it.id)
      )
    )) as AxiosResponse<MedicationRequest>[]
    if (
      responseMedRequests
        .map(it => it.status === 200)
        .reduce((prev, current) => prev && current, true)
    ) {
      const medicationRequests = responseMedRequests.map(it => it.data)
      /**
       * if user = cm
       *
       * if user = tp
       */
      await new CarePlanService(props.reason).updateCarePlansMedicationRequests(
        carePlan.id,
        medicationRequests.map(it => it.id.toString())
      )
      await checkAndFetchActiveCarePlan()
    }
  }

  /**
   *
   * @param {MedicationScheduleFormikValues} values
   * @param {Identifier[]} identifierList
   */
  async function onSubmit(values, identifierList): Promise<void> {
    try {
      let responseRequest
      if (values.isEditing) {
        const oldMedRequest = await getMedicationRequestById(
          'MedicationRequest/' + values.medicationRequestId
        ).then(response => response.data)
        if (!oldMedRequest.id) {
          console.error('Es trat ein Fehler beim Abfragen der Medikation auf.')
          NotificationManager.error(
            'Es trat ein Fehler beim Abfragen der Medikation auf.'
          )
        }
        const medRequest = await mapMedRequest(
          values,
          identifierList,
          patientId,
          oldMedRequest
        )
        MedicationStatusDelegation.copyAllStatus(oldMedRequest, medRequest)
        MedicationStatusDelegation.changeStatus(
          medRequest,
          MedicationRequestStatus.on_hold,
          authContext
        )

        responseRequest = await updateMedicationRequestById(
          medRequest,
          values.medicationRequestId
        ).then(response => response.data)
        if (responseRequest.status !== 200 && !responseRequest?.id) {
          console.error(
            'Es trat ein Fehler beim Bearbeiten der Medikation auf.'
          )
          NotificationManager.error(
            'Es trat ein Fehler beim Bearbeiten der Medikation auf.'
          )
        }
      } else {
        const medRequest = await mapMedRequest(
          values,
          identifierList,
          patientId
        )
        responseRequest = await createMedicationRequest(medRequest).then(
          response => response.data
        )
      }
      const updatedSchedule = addMedicationRequestToSchedule(
        schedule,
        Array(responseRequest)
      )
      if (_isMountedRef.current) {
        setSchedule(updatedSchedule)
        if (carePlan?.id) {
          await new CarePlanService(
            props.reason
          ).updateCarePlansMedicationRequests(
            carePlan.id,
            updatedSchedule.requests.map(it => it.id.toString())
          )
        }
        setShowMedRequestModal(false)
      }
    } catch (e) {
      console.error(e)
      NotificationManager.error(
        'Es trat ein Fehler beim Bearbeiten der Medikation auf.'
      )
      setShowMedRequestModal(false)
    }
  }

  function openVersionView() {
    setShowHistoryModal(true)
  }

  /**
   * @param {int} visibleTimeStart
   * @param {int} visibleTimeEnd
   */
  const onTimeChange = (visibleTimeStart, visibleTimeEnd) => {
    setVisibleTime({ start: visibleTimeStart, end: visibleTimeEnd })
  }

  const jumpToToday = () => {
    setVisibleTime({
      start: moment(dateStr + ' ' + startTime).valueOf(),
      end: moment(dateStr + ' ' + endTime).valueOf(),
    })
  }

  function Warning(schedule) {
    if (schedule !== undefined) {
      if (
        schedule.groups.filter(group =>
          group.medicationRequest
            ? MedicationStatusDelegation.getMyStatus(
                group.medicationRequest.status,
                authContext
              ) !== MedicationRequestStatus.active
            : false
        ).length > 0
      ) {
        return <GetWarningButton />
      }
    }
  }

  useEffect(() => {
    _isMountedRef.current = true
    const fun = async () => {
      const medicationRequestUpdateWithReason =
        localStorageService.localStorageGet()

      localStorageService.localStorageDeleteAll()
      if (
        medicationRequestUpdateWithReason?.length &&
        !updateIsRunning &&
        props.handlers.hasPermission(UserPermission.PATIENT_MEDICATION_CONFIRM)
      ) {
        setUpdateIsRun(false)
        setUpdateIsRunning(true)
        try {
          const { newMedicationRequests, updatedMedicationRequests } =
            await activateMedicationRequestsAfterReLogin(
              medicationRequestUpdateWithReason[0],
              patientId
            )
          await setSchedule(
            addMedicationRequestToSchedule(schedule, newMedicationRequests)
          )
          if (_isMountedRef.current) {
            setCarePlan(
              updatedMedicationRequests.Response as unknown as CarePlan
            )
            setMedicationRequestsFromCarePlan(
              updatedMedicationRequests.Response as CarePlan
            )

            setUpdateIsRunning(false)
          }
        } catch (e) {
          console.error('MedicationReqeusts were not updated after relogin ', e)
          setMedicationRequestsToUpdate([])
        }
        if (_isMountedRef.current) {
          setMedicationRequestsToUpdate([])
        }
      } else {
        if (!updateIsRunning) {
          setUpdateIsRun(true)
        }
      }
    }
    fun().catch(e => {
      console.error(e)
      NotificationManager('Aktiver Medikamentenplan nicht bearbeitet')
    })
    return () => {
      _isMountedRef.current = false
    }
  }, [
    medicationRequestsToUpdate,
    updateIsRun,
    localStorageService,
    patientId,
    schedule,
    setMedicationRequestsFromCarePlan,
    updateIsRunning,
    props.handlers,
  ])

  const medicationPlanTypes = ['STANDARD', 'PATIENT'] as const
  type MedicationPlanTypes = typeof medicationPlanTypes[number]

  const handleCreateMedicationPlan = (planType: MedicationPlanTypes) => {
    const _patientService = new PatientService()
    setMedicationplanLoading(true)
    _patientService
      .createMedicationPlanPrint(patientId, planType)
      .then(response => {
        if (_isMountedRef.current) {
          if (response.Result === APIResult.SUCCESS) {
            NotificationManager.success(
              'Erfolg',
              'Medikationsplan wurde erstellt. Abrufbar unter Befunde.'
            )
          } else {
            NotificationManager.error(
              'Fehler',
              'Medikationsplan konnte nicht erstellt werden.'
            )
          }
        }
      })
      .catch(error => {
        NotificationManager.error(
          'Fehler',
          `Medikationsplan konnte nicht erstellt werden. '${error}'`
        )
      })
      .finally(() => {
        setMedicationplanLoading(false)
      })
  }
  const { actionTitle, actionIcon } = getRemoveRestoreControlConfig(
    authContext,
    props,
    schedule.requests
  )

  function getHorizontalLineClassNamesForGroup() {
    return group => {
      if (group.isGroupHeader) {
        return []
      } else {
        if (!group.medicationRequest) return []

        const realStatus = MedicationStatusDelegation.getRealStatus(
          group.medicationRequest
        )
        const proposedStatus = MedicationStatusDelegation.getProposedStatus(
          group.medicationRequest
        )
        const status =
          realStatus === proposedStatus || !proposedStatus
            ? realStatus
            : proposedStatus
            ? `${realStatus}_${proposedStatus}`
            : proposedStatus

        return ['timeline_' + status]
      }
    }
  }

  const proposalsExist =
    props.handlers.hasPermission(UserPermission.PATIENT_MEDICATION_CONFIRM) &&
    MedicationStatusDelegation.statusDiffer(schedule.requests)

  return (
    <div className="bg-light">
      {proposalsExist && (
        <div className="alert alert-info">
          Es gibt Vorschläge für Änderungen an diesem Medikationsplan.
          Bestätigen Sie die Änderungen oder setzen Sie die entsprechende
          Version zurück.
        </div>
      )}
      <div className="mb-2 d-flex">
        {updateIsRun ? null : <Loader />}
        <StandardMedicationRequest
          carePlan={carePlan}
          medicationRequests={schedule.requests}
          patientId={patientId}
          handleMedicationRequests={medicationRequests =>
            setSchedule(
              addMedicationRequestToSchedule(schedule, medicationRequests)
            )
          }
          reason={props.reason}
        />
        <PlanDefinitionTemplate
          patientId={patientId}
          medicationRequests={schedule.requests}
          stopOtherMedicationRequests={stopOtherMedicationRequests}
          setLoad={checkAndFetchActiveCarePlan}
          schedule={schedule}
          setSchedule={setSchedule}
          carePlan={carePlan}
          setCarePlan={setCarePlan}
          reason={props.reason}
        />
        <Dropdown className="ml-2 mr-2">
          <Dropdown.Toggle variant={'outline-dark'} size="sm">
            {medicationplanLoading && <Loader size={16} inline />}
            Medikationsplan drucken
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              className={'p-2'}
              onClick={() => handleCreateMedicationPlan('STANDARD')}>
              Standardmedikamentenplan drucken
            </Dropdown.Item>
            <Dropdown.Item
              className={'p-2'}
              onClick={() => handleCreateMedicationPlan('PATIENT')}>
              leicht verständlichen Medikamentenplan drucken{' '}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="ml-auto mr-0">
          <CarePlanTemplate
            carePlan={props.carePlan ?? carePlan}
            setCarePlan={props.setCarePlan ?? setCarePlan}
            schedule={schedule}
            setUpdateIsRunning={setUpdateIsRunning}
            showCarePlanModals={true}
            disableCarePlanSaving={props.disableCarePlanSaving}
            patientId={patientId}
            medicationRequests={_.cloneDeep(schedule.requests)}
            setLoad={checkAndFetchActiveCarePlan}
            isSubComponent={props.isSubComponent}
            reason={props.reason}
          />
        </div>
      </div>
      <div>
        <Timeline
          className="medication-schedule-timeline"
          groups={displayGroups.length > 0 ? displayGroups : [emptyGroup]}
          horizontalLineClassNamesForGroup={getHorizontalLineClassNamesForGroup()}
          items={schedule.items}
          sidebarWidth={420}
          rightSidebarWidth={200}
          canMove={false}
          canResize={false}
          stackItems
          visibleTimeStart={visibleTime.start}
          visibleTimeEnd={visibleTime.end}
          itemRenderer={itemRenderer}
          lineHeight={72}
          itemLineHeight="inherit"
          fullUpdate={true}
          onTimeChange={onTimeChange}>
          <TimelineHeaders className="sticky bg-dark">
            <SidebarHeader>
              {({ getRootProps }) => {
                return (
                  <div
                    className="medication-schedule-header"
                    {...getRootProps()}>
                    Medikation
                    <br />
                    <Button
                      disabled={
                        !props.handlers.hasPermission(
                          UserPermission.PATIENT_MEDICATION_ADD_EDIT
                        )
                      }
                      data-testid={'medicationCreateClick'}
                      onClick={medicationCreateClick}
                      variant={'outline-light'}
                      size="sm">
                      <MaterialIcon
                        icon="add"
                        size={'24px'}
                        verticalAlignment={'middle'}
                      />
                    </Button>
                  </div>
                )
              }}
            </SidebarHeader>
            <DateHeader unit="primaryHeader" />
            <DateHeader />
            <SidebarHeader variant="right">
              {({ getRootProps }) => {
                return (
                  <div
                    className="medication-schedule-header"
                    {...getRootProps()}>
                    Aktionen
                    <br />
                    {Warning(schedule)}
                    <OverlayMenuButton
                      disabled={props.disableConfirm}
                      variant={'outline-light'}
                      icon={actionIcon}
                      onClick={() => confirmClick()}>
                      {actionTitle}
                    </OverlayMenuButton>
                    <OverlayMenuButton
                      icon={'history'}
                      variant={'outline-light'}
                      onClick={() => openVersionView()}>
                      Versionswiederherstellung
                    </OverlayMenuButton>
                  </div>
                )
              }}
            </SidebarHeader>
          </TimelineHeaders>
          <TodayMarker interval={60 * 1000} />
        </Timeline>
      </div>
      <div className="ml-auto ml-0 mb-2 lowerBar">
        <Button
          className="mt-2 mb-2"
          variant={'outline-light'}
          onClick={jumpToToday}>
          Zurück zu heute
        </Button>
      </div>
      {showMedRequestModal && (
        <MedicationRequestModal
          showModal={showMedRequestModal}
          setShowModal={setShowMedRequestModal}
          schedule={schedule}
          onSubmit={onSubmit}
          initialValues={initialValuesRequest}
        />
      )}
      {showHistoryModal && (
        <HistoryModal
          carePlan={carePlan}
          setSchedule={setSchedule}
          showModal={showHistoryModal}
          setShowModal={setShowHistoryModal}
          patientId={patientId}
        />
      )}
    </div>
  )
}

export default withAuth(React.memo(MedicationSchedule))
