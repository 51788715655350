import React, { createRef } from 'react'
import FullCalendar, {
  DateSelectArg,
  EventApi,
  EventClickArg,
  EventInput,
} from '@fullcalendar/react'
import { Component, createElement, EventDropArg } from '@fullcalendar/core'
import deLocale from '@fullcalendar/core/locales/de'

import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, {
  EventResizeDoneArg,
} from '@fullcalendar/interaction'
import MaterialIcon from '../../UI/MaterialIcon/MaterialIcon'
import { mapAppointmentIcons } from '../../../utils/CalendarUtils'
import { formatTime } from '../../../utils/Utils'

interface State {
  weekendsVisible: boolean
  currentEvents: any[]
}

interface Props {
  events: EventInput[]
  isEditable: boolean
  eventClick: (clickInfo: EventClickArg) => void
  dateClick: (selectInfo: DateSelectArg) => void
  onAppointmentSetTime: (arg: EventResizeDoneArg) => void
  onAppointmentSetDate: (arg: EventDropArg) => void
}

class CustomDayHeader extends Component<{ text: string }> {
  render() {
    return createElement('div', {}, this.props.text)
  }
}

export default class ConfiguredCalendar extends React.Component<Props, State> {
  static defaultProps = {
    isEditable: true,
  }
  state: State = {
    weekendsVisible: true,
    currentEvents: [],
  }

  calendarRef = createRef<FullCalendar>()

  handleWeekendsToggle = (): void => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible,
    })
  }

  handleDateSelect = (selectInfo: DateSelectArg): void => {
    this.props.dateClick(selectInfo)

    const calendarApi = selectInfo.view.calendar
    calendarApi.unselect() // clear date selection
  }

  handleEventClick = (clickInfo: EventClickArg): void => {
    this.props.eventClick(clickInfo)
    if (this.calendarRef.current) {
      this.calendarRef.current.render()
    }
  }

  handleEvents = (events: EventApi[]): void => {
    this.setState({
      currentEvents: events,
    })
    if (this.props.events.length !== events.length) {
      this.calendarRef.current.render()
      this.calendarRef.current.getApi().removeAllEvents()
      this.props.events.map(event =>
        this.calendarRef.current.getApi().addEvent(event)
      )
    }
  }

  render(): JSX.Element {
    return (
      <FullCalendar
        ref={this.calendarRef}
        timeZone="local"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        navLinks={false} // can click day/week names to navigate views
        dayHeaderContent={arg =>
          createElement(CustomDayHeader, { text: arg.text })
        }
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
        }}
        aspectRatio={1.2}
        initialView="timeGridWeek"
        editable={this.props.isEditable}
        selectable={true}
        selectMirror={true}
        weekends={this.state.weekendsVisible}
        events={this.props.events} // alternatively, use the `events` setting to fetch from a feed
        eventContent={renderEventContent} // custom render function
        locale={deLocale}
        eventClick={this.handleEventClick}
        select={this.handleDateSelect}
        eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
        eventResize={this.props.onAppointmentSetTime}
        eventDrop={this.props.onAppointmentSetDate}
        slotDuration={'00:15'}
        slotMinTime={'06:00'}
        slotMaxTime={'20:00'}
        allDaySlot={false}
      />
    )
  }
}

export const renderEventContent = (
  eventInfo: Record<string, any>
): JSX.Element => {
  let icons = null
  try {
    const icon_names = eventInfo.event.extendedProps.actionIcons
      ? mapAppointmentIcons(eventInfo.event.extendedProps.actionIcons)
      : ['edit']

    icons = icon_names
      .map((icon_name, index) =>
        icon_name ? (
          <MaterialIcon icon={icon_name} key={index} size="16px" />
        ) : null
      )
      .filter(value => value)
  } catch (e) {
    console.error('bad icons of draft', e)
  }

  if (
    eventInfo.view.type === 'timeGridWeek' ||
    eventInfo.view.type === 'timeGridDay' ||
    eventInfo.view.type === 'listWeek'
  ) {
    return (
      <div
        style={{}}
        className="calItemInner d-flex align-items-center"
        title={`${formatTime(eventInfo.event.start)} - ${
          eventInfo.event.title
        }`}>
        {icons}
        {eventInfo.event.title}
        {eventInfo.event.extendedProps.participants
          ? ': ' + eventInfo.event.extendedProps.participants
          : null}
      </div>
    )
  } else {
    return (
      <div
        style={{}}
        className="calItemInner d-flex align-items-center"
        title={`${formatTime(eventInfo.event.start)} - ${
          eventInfo.event.title
        }`}>
        {icons}
        {eventInfo.event.title}
      </div>
    )
  }
}
