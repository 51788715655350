import { AUTH_API } from './Paths'

export const keycloakConfig = {
  clientId: 'panos_client',
  realm: 'panos',
  url: AUTH_API + '/auth/',
  'ssl-required': 'external',
  resource: 'panos_client',
  'public-client': true,
  'confidential-port': 0,
}
