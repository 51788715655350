export function getHumanNameOfResource(person: Record<string, any>): string {
  for (const name of person.name) {
    if (name.use === 'official') {
      if (name.text && name.text.trim() !== '') {
        return name.text
      }
      return [...name.given, name.family].join(' ')
    }
  }
  return 'unbekannt'
}

export function getPeriodUnitForMoment(periodUnit: string): string {
  switch (periodUnit) {
    case 'wk':
      return 'weeks'
    case 'mo':
      return 'months'
    case 'd':
    default:
      return 'days'
  }
}

export function getEmailAddressOfPerson(person: Record<string, any>): string {
  const emailResource = person.telecom?.find(
    telecom => telecom.system === 'email'
  )
  return emailResource ? emailResource.value : null
}
/**
 * Extracts the id from a Reference object
 *
 * e.g. '208' from
 *  "productReference": {
 *      "reference": "Medication/208",
 *      "display": "Domperidon-Hexal Tabletten"
 *  },
 *
 * @param reference {Reference}
 * @returns {string}
 */
export function getIdFromReference(reference: Reference): string {
  return reference.reference.replace(/^#?\D*\/?/g, '')
}

export function findValueFromIdentifiers(
  identifier: Identifier[],
  urn: string
): string {
  return identifier.find(identifier => identifier.system === urn)?.value
}

/**
 * only get the number
 * @param {string} Id
 */
export function strippedId(Id: string): string {
  return Id.match(/(fhir\/)?([a-zA-Z]+\/)?(\d+)(\/)*/)[3]
}

/**
 *
 * @param {string} status
 * @param {Array.<string>} StatusOptions
 * @returns {boolean}
 */
export function hasStatus(status: string, StatusOptions: string[]): boolean {
  return StatusOptions.includes(status)
}
