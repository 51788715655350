import React, { Component } from 'react'
import { APIResult } from '../../../../constants/APIResult'
import Autocomplete from '../../Autocomplete/Autocomplete'
import { NotificationManager } from 'react-notifications'
import { Location } from '../../../../types/Location'
import LocationService from '../../../../services/LocationService'

interface Props {
  value?: Location | string
  disabled?: boolean
  label?: string
  icon?: string
  onChange: (location: Location) => void
  name: string
}

interface State {
  locations?: Location[]
  selected: Location
  value: string
}

class LocationSelect extends Component<Props, State> {
  static defaultProps = {
    icon: 'location_on',
  }
  state = {
    selected: null,
    locations: null,
    value: '',
  }

  componentDidMount(): void {
    const { value } = this.props

    /* this is a quickfix to let the ac work as a select */
    this.handleSearch('quickfix')

    if (value) {
      if (typeof value !== 'string') {
        this.setState({
          selected: value,
          value: value.name,
        })
      }
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { value } = this.props

    if (
      this.state.locations &&
      this.props.value &&
      !this.state.value &&
      typeof value === 'string'
    ) {
      const propLocationId = value.split('Location/').pop()
      const curLocation = this.state.locations.find(
        loc => loc.id === propLocationId
      )
      if (curLocation)
        this.setState({ value: curLocation.name, selected: curLocation })
    }

    if (prevProps.value !== value) {
      if (typeof value === 'string') {
        this.setState({ selected: null, value: null })
      } else if (value) {
        this.setState({ selected: value, value: value.name })
      } else {
        this.setState({ selected: null, value: '', locations: null })
      }
    }
  }

  handleSelect = (location: Location): void => {
    if (location !== this.state.selected) this.props.onChange(location)
    if (location) {
      this.setState({
        selected: location,
        value: location.name,
      })
    } else {
      this.setState({
        selected: null,
        value: '',
      })
    }
  }

  handleSearch = async (param: string): Promise<void> => {
    if (param) {
      const service = new LocationService()
      const res = await service.getLocations()
      if (
        res &&
        res.Result === APIResult.SUCCESS &&
        Array.isArray(res.Response)
      ) {
        this.setState({ locations: res.Response as Location[] })
      } else {
        NotificationManager.error('Fehler', 'Derzeit keine Antwort vom Server')
      }
    } else {
      this.setState({ locations: [] })
    }
  }

  renderValue = (location: Location): string => {
    return location.name
  }

  render(): JSX.Element {
    return (
      <Autocomplete
        label={this.props.label}
        data={this.state.locations}
        onSearch={this.handleSearch}
        onSelect={this.handleSelect}
        selected={this.state.selected}
        disabled={this.props.disabled}
        icon={this.props.icon}
        value={this.state.value}
        renderValue={this.renderValue}
        minCharacters={0}
        placeholder="Ort wählen"
        notFoundMessage="kein Ort gefunden"
        name={this.props.name}
      />
    )
  }
}

export default LocationSelect
