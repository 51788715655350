import React, { useMemo } from 'react'
import { Table } from 'react-bootstrap'
import { FormDynamicComponentProps } from '../../../FormDynamic/FormDynamicComponent'
import FormMatrixRow from './FormMatrixRow'
import styles from './FormMatrix.module.scss'
import { EventContext } from '../../../../infrastructure/EventProviderInterfaces'
import FormErrorMessage from '../FormError/FormErrorMessage'

export interface MatrixProps extends FormDynamicComponentProps {
  onClickTriggerMatrix: boolean
  context: EventContext
}

export const matrixErrorMessage =
  'Bitte treffen Sie eine Auswahl in den markierten Zeilen'

const Matrix: React.FC<MatrixProps> = (props: MatrixProps) => {
  /**
   * cache rows
   */
  const rows = useMemo((): React.ReactElement[] => {
    return props.subComponents.map((row, rowIndex) => {
      return (
        <FormMatrixRow
          key={`${props.name}_rows_${rowIndex}`}
          {...row}
          error={props.context.errors[row.name]}
          previousValues={props.previousValues}
          onClickTriggerMatrix={props.onClickTriggerMatrix}
          context={props.context}
        />
      )
    })
  }, [
    props.subComponents,
    props.name,
    props.previousValues,
    props.onClickTriggerMatrix,
    props.context,
  ])

  /**
   * gather errors as string
   */
  const inputError = useMemo(() => {
    const reducedErrors = { [props.name]: props.context.errors[props.name] }
    if (props.subComponents)
      props.subComponents.forEach(
        row => (reducedErrors[row.name] = props.context.errors[row.name])
      )
    return Object.values(reducedErrors)
      .filter(value => !!value)
      .join(', ')
  }, [props.context.errors, props.name, props.subComponents])

  const options = [''].concat(props.options.map(option => option.label))
  return (
    <div className={'form-group position-relative'}>
      <div className="table-responsive">
        <Table
          bordered
          className={`bg-light mb-0 ${
            styles.matrixTable
          } matrixTable matrixTable${
            options.length > 7 ? 'Big' : 'Small matrixCols-' + options.length
          } `}>
          <thead>
            <tr>
              {options.map((cell, index) => {
                return (
                  <th key={`${props.name}_${index}`}>
                    {cell ? (
                      <div>
                        <span>{cell}</span>
                      </div>
                    ) : null}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </div>
      {inputError ? <FormErrorMessage error={matrixErrorMessage} /> : null}
    </div>
  )
}

export default Matrix
