import React, { Component } from 'react'
import { APIResult } from '../../../../constants/APIResult'
import { ICD10 } from '../../../../types/ICD10'
import Autocomplete from '../../Autocomplete/Autocomplete'
import { NotificationManager } from 'react-notifications'
import { getICD10Name } from '../../../../utils/Utils'
import ICD10Service from '../../../../services/ICD10Service'

interface Props {
  value?: ICD10
  label?: string
  placeholder?: string
  disabled?: boolean
  icon?: string
  clearOnSelect?: boolean
  onChange: (icd10: ICD10) => void
  excludeSelf: boolean
}

interface State {
  icd10Values?: ICD10[]
  selected: ICD10
  value: string
}

class ICD10Select extends Component<Props, State> {
  static defaultProps = {
    icon: 'search',
    excludeSelf: false,
  }
  state = {
    selected: null,
    icd10Values: null,
    value: '',
  }

  componentDidMount(): void {
    const { value } = this.props

    if (value) {
      this.setState({
        selected: value,
        value: getICD10Name(value),
      })
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { value } = this.props

    if (prevProps.value !== value) {
      if (value) {
        this.setState({ selected: value, value: getICD10Name(value) })
      } else {
        this.setState({ selected: null, value: '', icd10Values: null })
      }
    }
  }

  handleSelect = (icd10: ICD10): void => {
    if (icd10 !== this.state.selected) this.props.onChange(icd10)
    if (this.props.clearOnSelect) {
      this.setState({
        selected: icd10,
        value: '',
      })
    } else if (icd10) {
      this.setState({
        selected: icd10,
        value: getICD10Name(icd10),
      })
    } else {
      this.setState({
        selected: null,
        value: '',
      })
    }
  }

  handleSearch = async (param: string): Promise<void> => {
    if (param) {
      const service = new ICD10Service()
      const res = await service.findICD10(param, this.props.excludeSelf)
      if (
        res &&
        res.Result === APIResult.SUCCESS &&
        Array.isArray(res.Response)
      ) {
        const icd10s = res.Response as ICD10[]
        this.setState({
          icd10Values: icd10s,
        })
      } else {
        NotificationManager.error('Fehler', 'Derzeit keine Antwort vom Server')
      }
    } else {
      this.setState({ icd10Values: [] })
    }
  }

  render(): JSX.Element {
    return (
      <Autocomplete<ICD10>
        label={this.props.label}
        data={this.state.icd10Values}
        onSearch={this.handleSearch}
        onSelect={this.handleSelect}
        selected={this.state.selected}
        disabled={this.props.disabled}
        renderValue={getICD10Name}
        icon={this.props.icon}
        value={this.state.value}
        placeholder={
          this.props.placeholder ? this.props.placeholder : 'ICD10-Code'
        }
        notFoundMessage="Keinen ICD10 Code gefunden"
        idProp={'keyNoCross'}
        name={'ICD10'}
      />
    )
  }
}

export default ICD10Select
