import React, { useCallback, useEffect, useState } from 'react'
import { useEvaluateBranching } from '../../../../hooks/UseEvaluateBranching'
import { FormOption } from '../../../../types/FormOption'
import FormError from '../FormError/FormError'
import MatrixRadio from '../FormMatrix/MatrixRadio'
import MatrixCheckbox from '../FormMatrix/MatrixCheckbox'
import { MatrixProps } from './FormMatrix'
import { FormDynamicComponentProps } from '../../../FormDynamic/FormDynamicComponent'
import { FieldTypes } from '../../../../constants/FieldTypes'
import FormShowPrevValue from '../../../FormDynamic/FormShowPrevValue'

function triggerSetValueFromPrev(
  name: string,
  field_type: string,
  options: Array<FormOption>,
  previousValues: Record<string, unknown>,
  setFieldValue: (
    field: string,
    value: unknown,
    component: FormDynamicComponentProps,
    isDefault: boolean
  ) => void,
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void,
  component: FormDynamicComponentProps,
  isDefault: boolean
): void {
  if (field_type === FieldTypes.CHECKBOX) {
    for (const option of options) {
      const variableName = `${name}___${option.value}`
      setFieldValue(
        variableName,
        previousValues[variableName] ? '1' : '0',
        component,
        isDefault
      )
      setFieldTouched(variableName, false, true)
    }
  } else {
    setFieldValue(name, previousValues[name], component, false)
    setFieldTouched(name, false, true)
  }
}

const FormMatrixRow: React.FC<MatrixProps> = (props: MatrixProps) => {
  const [shouldRender, branchingError] = useEvaluateBranching({
    name: props.name,
  })
  const [trigger, setTrigger] = useState<boolean>(false)

  const setValuesFromPrev = useCallback(() => {
    triggerSetValueFromPrev(
      props.name,
      props.field_type,
      props.options,
      props.previousValues,
      props.context.setFieldValue,
      props.context.setFieldTouched,
      null,
      true
    )
  }, [
    props.name,
    props.field_type,
    props.options,
    props.previousValues,
    props.context.setFieldValue,
    props.context.setFieldTouched,
  ])

  useEffect(() => {
    if (shouldRender && props.onClickTriggerMatrix !== null) {
      setTrigger(true)
    }
  }, [shouldRender, props.onClickTriggerMatrix, setTrigger])

  useEffect(() => {
    if (shouldRender && trigger) {
      setTrigger(false)
      setValuesFromPrev()
    }
  }, [trigger, setTrigger, setValuesFromPrev, shouldRender])

  if (branchingError) {
    console.error('error with branching logic', branchingError.message)
    return <FormError />
  }
  if (!shouldRender) return null

  const onCheckboxGroupChange = (event: any): void => {
    const values = props.context.values
    const rowValues = values[props.name]
      ? { ...(values[props.name] as Record<string, unknown>) }
      : {}
    const { checked, value } = event.target
    rowValues[value] = checked
    props.context.handleFieldChange(rowValues, props.name)
  }

  const { values } = props.context
  return (
    <tr>
      <td className={`${props.error ? 'table-danger' : ''}`}>
        {props.label}
        <FormShowPrevValue
          {...props}
          value={values[props.name]}
          onClick={setValuesFromPrev}
          displayItem={null}
          prevValue={props.previousValues}
          hideIfEqual
        />
      </td>
      {props.options.map((option, index) => {
        const id = `${props.name}_${option.value}`
        return (
          <td key={id} className="text-center position-relative">
            {props.field_type === FieldTypes.RADIO ? (
              <MatrixRadio
                name={props.name}
                id={id}
                value={option.value}
                onChange={props.context.setFieldValue}
                formikValues={values}
                disabled={props.disabled}
              />
            ) : (
              <MatrixCheckbox
                name={props.name}
                id={id}
                value={option.value}
                onChangeEvent={onCheckboxGroupChange}
                disabled={props.disabled}
                rowValues={values[props.name] as Record<string, boolean>}
              />
            )}
          </td>
        )
      })}
    </tr>
  )
}

export default FormMatrixRow
