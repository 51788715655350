import Modal from 'react-bootstrap/Modal'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import MedicationSchedule from '../MedicationSchedule/MedicationSchedule'
import Button from 'react-bootstrap/Button'
import Loader from '../../UI/Spinner/Loader'
import React, { ReactNode } from 'react'
import * as Yup from 'yup'
import { CarePlan } from '../../../types/CarePlan'
import { MedicationReason } from '../MedicationSchedule/MedicationReason'
import _ from 'lodash'

interface ComposeCarePlanModalProps {
  carePlan?: CarePlan
  patientId: string
  onHide: () => void
  reason?: MedicationReason

  checkCarePlanTitle?: (planName) => boolean
  enableEditCarePlanTitle?: boolean
  onSubmit: (carePlan, medicationRequests: MedicationRequest[]) => void
}

interface ComposeCarePlanModalState {
  showMedicationPlan: boolean
  isCarePlanToSave: boolean
  carePlan: CarePlan
  medicationRequests: MedicationRequest[]
  modifiedCarePlan?: CarePlan
}

class ComposeCarePlanModal extends React.Component<
  ComposeCarePlanModalProps,
  ComposeCarePlanModalState
> {
  constructor(props: ComposeCarePlanModalProps) {
    super(props)
    this.state = {
      showMedicationPlan: false,
      isCarePlanToSave: true,
      carePlan: props.carePlan,
      medicationRequests: [],
      modifiedCarePlan: null,
    }
    console.log(props, this.state)
  }

  disableCarePlanSetting = async (): Promise<void> => {
    return this.setState({ isCarePlanToSave: false })
  }

  validateFormikValues = (): any => {
    return Yup.object().shape({
      planName: Yup.string()
        .test(
          'unique',
          'Name des Medikationsplans schon vorhanden',
          this.props.checkCarePlanTitle
        )
        .required('Name des Medikationsplans ist erforderlich'),
    })
  }

  setCarePlan = (carePlan: CarePlan): void => {
    if (!_.isEqual(this.state.modifiedCarePlan, carePlan))
      this.setState({ modifiedCarePlan: carePlan })
  }

  setMedicationRequests = (medicationRequests: MedicationRequest[]): void => {
    if (!_.isEqual(this.state.medicationRequests, medicationRequests))
      this.setState({ medicationRequests })
  }

  render(): ReactNode {
    return (
      <Modal
        size="xl"
        animation={true}
        show={true}
        onShow={() => this.setState({ showMedicationPlan: true })}
        onHide={() => this.props.onHide()}>
        <Modal.Header>
          <Modal.Title> Name des Medikationsplans </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{ planName: this.state.carePlan?.title ?? '' }}
          validationSchema={this.validateFormikValues}
          onSubmit={values => {
            const newCarePlan = {
              ...this.state.modifiedCarePlan,
              title: values.planName,
            }
            this.props.onSubmit(
              { ...values, plan: newCarePlan },
              this.state.medicationRequests
            )
            this.props.onHide()
          }}>
          {({ values, touched, errors, isSubmitting }) => (
            <Form>
              <Modal.Body>
                <Field
                  type="text"
                  name="planName"
                  className={`form-control ${
                    touched.planName && errors.planName ? 'is-invalid' : ''
                  }`}
                  disabled={!this.props.enableEditCarePlanTitle}
                />

                <ErrorMessage
                  component="div"
                  name="planName"
                  className="invalid-feedback"
                />

                {this.state.showMedicationPlan ? (
                  <div style={{ width: '100%' }}>
                    <MedicationSchedule
                      patientId={this.props.patientId}
                      carePlan={{
                        ...this.state.carePlan,
                        title: values.planName,
                      }}
                      setCarePlan={this.setCarePlan}
                      setMedicationRequests={this.setMedicationRequests}
                      isSubComponent={true}
                      disableCarePlanSaving={true}
                      disableConfirm={true}
                      reason={this.props.reason ?? MedicationReason.DRAFT}
                    />
                  </div>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.disableCarePlanSetting().then(() =>
                      this.props.onHide()
                    )
                  }}>
                  Abbrechen
                </Button>
                <Button type="submit" disabled={isSubmitting}>
                  Speichern {isSubmitting && <Loader msg={'Lade...'} />}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    )
  }
}

export default ComposeCarePlanModal
