import React from 'react'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import './BigButton.scss'

export interface BigButtonProps {
  children: JSX.Element | string
  icon?: string
  disabled?: boolean
  onClick?: () => void
}

const BigButton: React.FC<BigButtonProps> = (props: BigButtonProps) => {
  return (
    <button
      className="btn btn-light BigButton mr-1"
      onClick={props.onClick && !props.disabled ? props.onClick : null}
      disabled={props.disabled || false}>
      {props.icon ? <MaterialIcon icon={props.icon} /> : null}
      <span>{props.children}</span>
    </button>
  )
}
export default BigButton
