import ApiServiceBase from '../ApiServiceBase'
import { API_ORGANIZATION, API_ORGANIZATIONS } from '../../config/Paths'
import { ApiResponse } from '../../types/ApiResponse'
import { ApiError } from '../../types/ApiError'
import { Organization } from '../../types/Organization'

export class OrganizationService extends ApiServiceBase {
  getOrganizations(): Promise<ApiResponse<Organization[] | ApiError>> {
    return this.get<null, Organization[]>(new URL(API_ORGANIZATIONS))
  }

  findOrganization(
    value: string
  ): Promise<ApiResponse<Organization[] | ApiError>> {
    return this.get<null, Organization[]>(new URL(API_ORGANIZATIONS))
  }

  getOrganization(
    organizationId: string
  ): Promise<ApiResponse<Organization | ApiError>> {
    return this.get<null, Organization>(
      new URL(API_ORGANIZATION + `/${organizationId}`)
    )
  }

  postOrganization(
    organization: Organization
  ): Promise<ApiResponse<Organization | ApiError>> {
    return this.post<Organization, Organization>(
      new URL(API_ORGANIZATION),
      null,
      organization
    )
  }

  putOrganization(
    organization: Organization
  ): Promise<ApiResponse<Organization | ApiError>> {
    return this.put<Organization>(new URL(API_ORGANIZATION), organization)
  }
}

export default OrganizationService
