import React, { Component, ReactElement } from 'react'
import {
  NotifierProps,
  SubscriptionIdentifier,
  SubscriptionType,
  withSubscriptionNotifier,
} from './SubscriptionProvider'

export const DynamicSidebarStore = React.createContext<DynamicSidebarContext>({
  handlers: null,
  collapsed: true,
  content: [],
})

export interface DynamicSidebarContextAsProps {
  sidebarContext: DynamicSidebarContext
}

interface DynamicSidebarState {
  collapsed: boolean
  content: DynamicSidebarContent[]
}

export interface DynamicSidebarContext extends DynamicSidebarState {
  handlers: DynamicSidebarHandlers
}

export enum DynamicSidebarPositionPriority {
  LEFT = 0,
  RIGHT = 1,
}

export interface DynamicSidebarContent {
  headline?: JSX.Element | string
  identifier: string
  subscriptionIdentifier?: SubscriptionIdentifier
  component: JSX.Element | JSX.Element[] | string
  onClose?: () => void
  initialWidth?: number
  minWidth?: number
  maxWidth?: number
}

export interface DynamicSidebarHandlers {
  addContent: (content: DynamicSidebarContent) => void
  removeContent: (identifier: string) => void
  pushUpdate: (
    identifier: string,
    subscriptionIdentifier?: SubscriptionIdentifier
  ) => void
  updateContent: (content: DynamicSidebarContent, value: number) => void
  setCollapsed: (value: boolean) => void
  getNextAvailableID: () => number
}

class DynamicSidebarProvider extends Component<
  NotifierProps,
  DynamicSidebarState
> {
  private debug = false
  nextAvailableID = -1

  state: DynamicSidebarState = {
    collapsed: true,
    content: [],
  }

  notifySubscriptions = (
    identifier: string,
    subscriptionIdentifier?: SubscriptionIdentifier
  ): void => {
    if (this.debug) console.log('notifiySubscriptions', identifier)
    this.props.notifiySubscriptions(
      SubscriptionType.DynamicSidebar,
      subscriptionIdentifier || (identifier as SubscriptionIdentifier),
      identifier
    )
  }

  addContent = (
    newContent: DynamicSidebarContent,
    positionPriority: DynamicSidebarPositionPriority = DynamicSidebarPositionPriority.LEFT
  ): void => {
    if (this.debug) console.log('addContent', newContent, positionPriority)
    if (
      !this.state.content.filter(
        element => element.identifier === newContent.identifier
      ).length
    ) {
      if (positionPriority === DynamicSidebarPositionPriority.RIGHT) {
        this.setState({ content: [...this.state.content, newContent] })
      } else if (positionPriority === DynamicSidebarPositionPriority.LEFT) {
        this.setState({ content: [newContent, ...this.state.content] })
      }
    }
  }

  removeContent = (contentIdentifier: string): void => {
    if (this.debug) console.log('removeContent', contentIdentifier)
    const identifiersToNotify = this.state.content
      .filter(
        (component: DynamicSidebarContent) =>
          component.identifier === contentIdentifier
      )
      .map(
        (component: DynamicSidebarContent) => component.subscriptionIdentifier
      )
      .filter(e => e)
    this.setState(
      {
        content: this.state.content.filter(
          (component: DynamicSidebarContent) =>
            component.identifier !== contentIdentifier
        ),
      },
      () =>
        identifiersToNotify.length > 0
          ? identifiersToNotify.map(subscriptionIdentifier =>
              this.notifySubscriptions(
                contentIdentifier,
                subscriptionIdentifier
              )
            )
          : this.notifySubscriptions(contentIdentifier)
    )
  }

  updateContent = (newContent: DynamicSidebarContent, index: number): void => {
    if (this.debug) console.log('updateContent', newContent, index)
    if (index === undefined) {
      this.setState(
        {
          content: this.state.content.map((content: DynamicSidebarContent) =>
            content.identifier === newContent.identifier ? newContent : content
          ),
        },
        () =>
          this.notifySubscriptions(
            newContent.identifier,
            newContent.subscriptionIdentifier
          )
      )
    } else {
      const newContentArray = [...this.state.content]
      newContentArray[index] = newContent
      this.setState({ content: newContentArray }, () =>
        this.notifySubscriptions(
          newContent.identifier,
          newContent.subscriptionIdentifier
        )
      )
    }
  }

  setCollapsed = (isCollapsed: boolean): void =>
    this.setState({ collapsed: isCollapsed })

  getNextAvailableID = (): number => {
    this.nextAvailableID++
    return this.nextAvailableID
  }

  handlers = {
    addContent: this.addContent,
    removeContent: this.removeContent,
    updateContent: this.updateContent,
    pushUpdate: this.notifySubscriptions,
    setCollapsed: this.setCollapsed,
    getNextAvailableID: this.getNextAvailableID,
  }

  render(): ReactElement {
    if (this.debug) console.log('DynamicSidebarProvider RENDER')
    return (
      <DynamicSidebarStore.Provider
        value={{ ...this.state, handlers: this.handlers }}>
        {this.props.children}
      </DynamicSidebarStore.Provider>
    )
  }
}

export default withSubscriptionNotifier(DynamicSidebarProvider)

export function withDynamicSidebar<T>(
  Component: React.ComponentType<T>
): React.ComponentType<Omit<T, keyof DynamicSidebarContextAsProps>> {
  const componentWithNotifications = (props: T) => {
    return (
      <DynamicSidebarStore.Consumer>
        {store => {
          return <Component sidebarContext={store ? store : null} {...props} />
        }}
      </DynamicSidebarStore.Consumer>
    )
  }
  componentWithNotifications.displayName = `withDynamicSidebar(${
    Component.displayName || Component.name || 'Component'
  })`
  return componentWithNotifications
}
