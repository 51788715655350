export enum TaskStatus {
  DRAFT = 'DRAFT',
  REQUESTED = 'REQUESTED',
  RECEIVED = 'RECEIVED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  READY = 'READY',
  CANCELLED = 'CANCELLED',
  INPROGRESS = 'INPROGRESS',
  ON_HOLD = 'ON_HOLD',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  ENTERED__IN_ERROR = 'ENTERED__IN_ERROR',
}
