import React, { ChangeEvent, useState } from 'react'
import { FormOption } from '../../../../types/FormOption'
import FormErrorMessage from '../FormError/FormErrorMessage'

import { v4 as uuidv4 } from 'uuid'

interface FormCheckboxGroupProps {
  name: string
  label?: string | JSX.Element
  onChange?: (value: Record<string, unknown>) => void
  onChangeEvent?: (event: ChangeEvent<HTMLInputElement>) => void
  options: FormOption[]
  value?: Record<string, unknown>
  className?: string
  disabled?: boolean
  error?: string
  required?: boolean
  inline?: boolean
  touched?: boolean
}

/**
 * Use this component with or without formik context.
 *
 * Required props without Formik are:
 * -- name
 * -- value
 * -- onChange
 */
const FormCheckboxGroup: React.FC<FormCheckboxGroupProps> = ({
  name,
  label,
  value,
  className,
  disabled,
  options,
  required,
  error,
  inline,
  onChange,
  onChangeEvent,
}: FormCheckboxGroupProps) => {
  const inputValue = value ? value : {}

  const [uniqueKey] = useState<string>(uuidv4())

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onChangeEvent) {
      onChangeEvent(event)
    } else if (onChange) {
      const values = inputValue ? { ...inputValue } : {}
      const { checked, value } = event.target
      values[value] = checked
      onChange(values)
    }
  }

  const cssClasses = className ? [className] : []
  if (error) cssClasses.push('position-relative')
  if (inline) cssClasses.push('custom-control-inline')
  if (error) cssClasses.push('validate-bad')

  return (
    <div className={`form-group ${cssClasses.join(' ')}`}>
      {label ? (
        <label className="form-label">
          {label}
          {required ? <span style={{ color: 'red' }}> *</span> : null}
        </label>
      ) : null}
      {options.map((option, i) => {
        const optionName = `${name}.${option.value}`
        const optionId = `${optionName}_${uniqueKey}`
        const checked =
          inputValue &&
          inputValue[option.value] &&
          inputValue[option.value] === true
            ? true
            : false
        return (
          <div className="custom-control custom-checkbox" key={optionName}>
            <input
              className={`custom-control-input ${error ? 'is-invalid' : ''}`}
              type="checkbox"
              name={optionName}
              id={optionId}
              value={option.value}
              disabled={disabled}
              onChange={onChangeHandler}
              checked={checked}
            />
            <label className="custom-control-label" htmlFor={optionId}>
              {option.label}
            </label>
            {option.info}
          </div>
        )
      })}
      <FormErrorMessage error={error} />
    </div>
  )
}

export default FormCheckboxGroup
