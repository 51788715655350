import React from 'react'

export const standardColumns = [
  {
    dataField: 'businessName',
    text: 'Handelsname(n)',
  },
  {
    dataField: 'substances',
    text: 'Wirkstoff(e)',
  },
  {
    dataField: 'standardDosage',
    text: 'Dosierung',
  },
  {
    dataField: 'unit',
    text: 'Einheit',
  },
  {
    dataField: 'type',
    text: 'Art',
  },
  {
    dataField: 'administration',
    text: 'Applikation',
  },
  {
    dataField: 'shape',
    text: 'Form',
  },
  {
    dataField: 'code',
    text: 'Code',
    hidden: true,
  },
  {
    dataField: 'warning',
    text: 'Warnhinweise',
    hidden: true,
  },
  {
    dataField: 'dosageChoices',
    text: 'Dosierungsmöglichkeiten',
  },
  {
    dataField: 'timing',
    text: 'Zeitplan',
  },
  {
    dataField: 'packageVariants',
    text: 'Packungsvarianten',
  },
]

export const PaginationTotalRenderer = (
  from: number,
  to: number,
  size: number
): JSX.Element => (
  <span className="react-bootstrap-table-pagination-total">
    &nbsp; Zeile {from} bis {to} von insgesamt {size}
  </span>
)

interface NoDataIndicationProps {
  errorMessage: string
}

export const NoDataIndication: React.FC<NoDataIndicationProps> = ({
  errorMessage,
}: NoDataIndicationProps) => (
  <div style={{ height: '200px' }}>
    {errorMessage === '' ? '' : `Error: ${errorMessage}`}
  </div>
)

export interface MedicationListProps {
  medicationList: any[]
  columns: any[]
  loading: boolean
  errorMessage?: string
  selectRow?: any
  rowEvents?: any
  rowStyle?: (row: any, rowIndex: any) => Record<string, unknown>
}
