import React, { useEffect, useRef, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { AxiosResponse } from 'axios'
import { NotificationManager } from 'react-notifications'

import TemplateLoadModal from './TemplateLoadModal'
import { informationFormatterPlanDefinition } from './TemplatePlanFormatters'
import { AuthContext, withAuth } from '../../../infrastructure/AuthProvider'
import { MedicationRequestStatus } from '../../../constants/MedicationRequestStatus'
import { parseDosageTimingCode } from '../../../utils/MedicationUtils'
import { createActionsFromMedicationRequest } from '../../../utils/MedicationRequestUtils'
import Loader from '../../UI/Spinner/Loader'
import { Modal } from 'react-bootstrap'
import {
  createPlanDefinition,
  getActivePlanDefinitionsByIdentifier,
  updatePlanDefinition,
} from '../../../services/PlanDefinitionService'
import { createMedicationRequest } from '../../../services/MedicationRequestService'
import { CarePlan } from '../../../types/CarePlan'
import CarePlanService from '../../../services/CarePlanService'
import { APIResult } from '../../../constants/APIResult'
import _ from 'lodash'
import {
  addResourceTypeIfNot,
  getIdPartFromFhirResourceId,
} from '../../../utils/Utils'
import { PlanDefinitionStatus } from '../../../constants/PlanDefinitionStatus'
import {
  addMedicationRequestToSchedule,
  MedicationScheduleObject,
} from '../../../utils/MedicationScheduleUtils'
import { UserPermission } from '../../../constants/UserPermission'
import { getMedicationById } from '../../../services/MedicationService'
import { getActivityDefinitionById } from '../../../services/ActivityDefinitionService'

interface PlanDefinitionTemplateProps extends AuthContext {
  patientId: string
  medicationRequests: MedicationRequest[]
  stopOtherMedicationRequests: (medicationRequests: MedicationRequest[]) => void
  setLoad: () => void
  schedule: MedicationScheduleObject
  setSchedule: (schedule: MedicationScheduleObject) => void
  carePlan: CarePlan
  setCarePlan: (carePlan: CarePlan) => void
  reason: string
}

const PlanDefinitionTemplate: React.FC<PlanDefinitionTemplateProps> = ({
  medicationRequests,
  stopOtherMedicationRequests,
  patientId,
  setLoad,
  schedule,
  setSchedule,
  carePlan,
  setCarePlan,
  reason,
  handlers,
}: PlanDefinitionTemplateProps): JSX.Element => {
  const [planDefinitionTemplates, setPlanDefinitionTemplates] = useState([])
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [showLoadModal, setShowLoadModal] = useState(false)
  const { hasPermission } = handlers

  const _isMountedRef = useRef(null)

  useEffect(() => {
    _isMountedRef.current = true
    fetchPlanDefinitions()
    return () => {
      _isMountedRef.current = false
    }
  }, [])

  function fetchPlanDefinitions() {
    getActivePlanDefinitionsByIdentifier()
      .then(response => {
        if (response.data && _isMountedRef.current) {
          setPlanDefinitionTemplates(
            response.data.map(plan => JSON.parse(plan))
          )
        } else {
          setPlanDefinitionTemplates([])
        }
      })
      .catch(e => {
        NotificationManager.error('Medikationsplanvorlagen nicht geladen')
        console.error(e)
      })
  }

  async function savePlan(planTitle: string) {
    if (medicationRequests.length) {
      const activeMedicationRequests = medicationRequests.filter(
        it =>
          it.status !== MedicationRequestStatus.stopped.toString() &&
          it.status !== MedicationRequestStatus.cancelled.toString()
      )
      const actions = createActionsFromMedicationRequest(
        activeMedicationRequests
      )

      console.log(actions)
      return createNewPlanDefinition(planTitle, actions).then(async r => {
        if (_isMountedRef.current) {
          setPlanDefinitionTemplates([
            ...planDefinitionTemplates,
            (r as AxiosResponse).data,
          ])
        }
      })
    }
  }

  async function createNewPlanDefinition(planTitle, actions) {
    const newPlanDefinition = {
      resourceType: 'PlanDefinition',
      identifier: [
        {
          system: 'urn:panos-template:Type',
          value: 'medication',
        },
      ],
      title: planTitle,
      status: PlanDefinitionStatus.active,
      action: actions,
    }
    return await createPlanDefinition(newPlanDefinition)
  }

  async function setMedicationRequestsFromPlanDefinition(
    selectedPlanDefinition,
    startDate,
    needToDeleteOtherMedRequests
  ) {
    if (selectedPlanDefinition) {
      if (needToDeleteOtherMedRequests)
        await stopOtherMedicationRequests(medicationRequests)

      if (selectedPlanDefinition.action?.length) {
        const newMedicationRequestCalls = []

        for (const action of selectedPlanDefinition.action) {
          const identifiers = []
          const dosageInstructions = []

          let medicationReference = undefined
          let medication = undefined
          let activityDefinition = undefined
          let supportingInformation = undefined

          for (const code of action.code) {
            if (code.coding[0].code === 'dosage-and-timing') {
              dosageInstructions.push(
                parseDosageTimingCode(code.text, action.timingTiming, startDate)
              )
            } else if (
              code.coding[0].code === 'urn:panos-backend-medication:Id'
            ) {
              identifiers.push({
                system: 'urn:panos-backend-medication:Id',
                value: code.text,
              })
              const medicationTypeId = addResourceTypeIfNot(
                code.text,
                'Medication'
              )
              medication = await getMedicationById(medicationTypeId).then(
                response => response.data
              )
              activityDefinition = await getActivityDefinitionById(
                medicationTypeId
              ).then(response => response.data)
              if (!medication?.id) {
                console.warn('Action not assigned to a Medication', code.text)
                continue
              }
              medicationReference = {
                reference: '#' + medication?.id,
                display: code.coding[0].display,
              }
            } else if (code.coding[0].code === 'standardMedicationReference') {
              supportingInformation = [
                {
                  reference: code.text,
                },
              ]
            } else if (
              code.coding[0].code === 'urn:panos-backend-medication:Art'
            ) {
              identifiers.push({
                system: 'urn:panos-backend-medication:Art',
                value: code.text,
              })
            } else if (
              code.coding[0].code === 'urn:panos-backend-medication:Form'
            ) {
              identifiers.push({
                system: 'urn:panos-backend-medication:Form',
                value: code.text,
              })
            }
          }

          const newMedicationRequest = {
            resourceType: 'MedicationRequest',
            status: MedicationRequestStatus.draft,
            identifier: identifiers,
            intent: 'order',

            subject: {
              reference: 'Patient/' + patientId,
            },
            note: [{ text: action.title }],

            medicationReference: medicationReference,
            dosageInstruction: dosageInstructions,
            supportingInformation: supportingInformation,

            contained: [medication, activityDefinition],
          }
          newMedicationRequestCalls.push(
            createMedicationRequest(newMedicationRequest)
          )
        }

        Promise.all(newMedicationRequestCalls)
          .then(responses => {
            const newMedicationRequests = responses.map(it => it.data)
            setSchedule(
              addMedicationRequestToSchedule(schedule, newMedicationRequests)
            )

            const medicationRequestIds = _.uniq([
              ...carePlan.medicationRequests.map(it =>
                getIdPartFromFhirResourceId(it)
              ),
              ...newMedicationRequests.map(it =>
                getIdPartFromFhirResourceId(it.id.toString())
              ),
            ])
            new CarePlanService(reason)
              .updateCarePlansMedicationRequests(
                carePlan.id,
                medicationRequestIds
              )
              .then(response => {
                if (
                  response.Result === APIResult.SUCCESS &&
                  _isMountedRef.current
                ) {
                  setCarePlan(response.Response as CarePlan)
                  setLoad()
                } else {
                  NotificationManager.error(
                    'MedikamentenPlan konnte nicht aktualisiert werden'
                  )
                }
              })
          })

          .catch(e => console.log(e))
      }
    }
  }

  const retirePlan = async retiredPlan => {
    if (retiredPlan.id) {
      retiredPlan.status = PlanDefinitionStatus.retired
      await updatePlanDefinition(retiredPlan)
      fetchPlanDefinitions()
    }
  }

  if (!hasPermission(UserPermission.PATIENT_PLAN_DEFINITION_TEMPLATE))
    return null

  return (
    <div>
      <Button
        disabled={!hasPermission(UserPermission.PATIENT_MEDICATION_ADD_EDIT)}
        className="ml-2 mr-2"
        variant={'outline-dark'}
        onClick={() => {
          setShowLoadModal(true)
        }}
        size="sm">
        Vorlage laden
      </Button>

      <Button
        className="ml-2 mr-2"
        variant={'outline-dark'}
        onClick={() => {
          setShowSaveModal(true)
        }}
        size="sm">
        Vorlage speichern
      </Button>

      <Modal
        size="lg"
        animation={true}
        show={showSaveModal}
        onHide={() => {
          setShowLoadModal(false)
        }}>
        <Modal.Header>
          <Modal.Title> Namen des Medikationsplans eingeben: </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{ planName: '' }}
          validationSchema={Yup.object().shape({
            planName: Yup.string()
              .test(
                'unique',
                'Name des Medikationsplans schon vorhanden',
                function (planName) {
                  for (const plan of planDefinitionTemplates) {
                    if (plan.title === planName) {
                      return false
                    }
                  }
                  return true
                }
              )
              .required('Name des Medikationsplans ist erforderlich'),
          })}
          onSubmit={async values => {
            await savePlan(values.planName)
            setShowSaveModal(false)
          }}>
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <Modal.Body>
                <Field
                  type="text"
                  name="planName"
                  className={`form-control ${
                    touched.planName && errors.planName ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage
                  component="div"
                  name="planName"
                  className="invalid-feedback"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowSaveModal(false)
                  }}>
                  Abbrechen
                </Button>
                <Button type="submit" disabled={isSubmitting}>
                  Speichern {isSubmitting && <Loader msg={'Lade...'} />}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <TemplateLoadModal
        patientId={patientId}
        showModal={showLoadModal}
        displayStatusColumn={false}
        displaySavedVersionColumn={false}
        handleClose={() => {
          setShowLoadModal(false)
        }}
        medPlanTemplates={planDefinitionTemplates}
        selectPlanAction={async (plan, startDate, deleteOtherMedRequests) => {
          setMedicationRequestsFromPlanDefinition(
            plan,
            startDate,
            deleteOtherMedRequests
          ).then(() => setLoad())
        }}
        deletePlanAction={retirePlan}
        informationFormatter={informationFormatterPlanDefinition}
        enableEditPlans={false}
        enableActivatePlans={true}
        setShowSaveModal={() => null}
        reason={reason}
      />
    </div>
  )
}

export default withAuth(PlanDefinitionTemplate)
