import { isEqual } from 'lodash'

export const objectMap = <Input, Output>(
  obj: Record<string, Input>,
  fn: (string, Input, number) => Output
): Record<string, Output> =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]))

export const fillObjectPropertiesWithValue = <T>(
  object: Record<string, any>,
  value: T
): Record<string, T> =>
  Object.keys(object).reduce((accumulator, current) => {
    accumulator[current] = value
    return accumulator
  }, {})

export function isEqualIgnore(
  x?: Record<string, any>,
  y?: Record<string, any>,
  blackList: string[] = [],
  whiteList: string[] = null
): boolean {
  if (x == null || y == null) return x === y
  let keys = whiteList ?? [...new Set([...Object.keys(x), ...Object.keys(y)])]
  keys = keys.filter(key => !blackList.includes(key))

  for (const key of keys) {
    if (!isEqual(x[key], y[key])) return false
  }
  return true
}
