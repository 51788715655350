import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import overlayFactory from 'react-bootstrap-table2-overlay'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import cellEditFactory from 'react-bootstrap-table2-editor'
import { AxiosResponse } from 'axios'
import { MedicationListProps, NoDataIndication } from '../../MedicationList'
import MaterialIcon from '../../UI/MaterialIcon/MaterialIcon'
import { MedRequestStandardModalValues } from '../../../types/MedRequestStandardValues'
import {
  configureStandardMedications,
  MedicationTableRow,
} from '../../../utils/MedicationUtils'

const { SearchBar } = Search

/**
 * gets the medication data and sets all the necessary states of the corresponding component
 * @param {function} requestFunction - gets the data which should be displayed
 * @param {boolean} loading - if the component is loading data yet
 * @param {function} setErrorMessage - sets the error message for a component
 * @param {function} setLoading - sets the loading state for a component
 * @param {MedRequestStandardModalValues} values - hands over form values
 * @param {React.Dispatch<React.SetStateAction<MedRequestStandardModalValues>} setValues - hands over form values setter
 */

export function getMedicationList(
  requestFunction: Promise<AxiosResponse<MedicationTableRow[]>>,
  loading: boolean,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  values: MedRequestStandardModalValues,
  setValues: React.Dispatch<React.SetStateAction<MedRequestStandardModalValues>>
): void {
  if (!loading) {
    setLoading(true)
    requestFunction
      .then(response => {
        setErrorMessage('')
        setValues({
          ...values,
          medicationListData: configureStandardMedications(response.data),
        })
        setLoading(false)
      })
      .catch(error => {
        setValues({ ...values, medicationListData: [] })
        setLoading(false)
        if (typeof error.response === 'undefined' || error.response === null) {
          setErrorMessage(
            'Die Daten konnten nicht geladen werden, da der Server sich nicht zurückmeldet.'
          )
        } else {
          setErrorMessage(error.response.data)
        }
      })
  }
}

/**
 * Uses bootstraptable to display all Medication
 * @param props
 * @returns {*}
 * @constructor
 */

const MedicationListEditable: React.FC<MedicationListProps> = (
  props: MedicationListProps
) => {
  return (
    <div className="bg-light">
      <ToolkitProvider
        keyField={'medicationId'}
        data={props.medicationList}
        columns={props.columns}
        rowStyle={props.rowStyle}
        search>
        {toolkitProps => (
          <div>
            {props.medicationList.length > 0 && (
              <div>
                <SearchBar
                  {...toolkitProps.searchProps}
                  delay={1000}
                  placeholder="Suche"
                  style={{ verticalAlign: 'middle', display: 'table-cell' }}
                />
                <Button
                  className="ml-2"
                  onClick={toolkitProps.searchProps.onClear}
                  size="sm"
                  style={{
                    verticalAlign: 'middle',
                    display: 'table-cell',
                  }}>
                  <MaterialIcon
                    icon="remove"
                    size={'24px'}
                    verticalAlignment="middle"
                  />
                </Button>
              </div>
            )}
            <BootstrapTable
              {...toolkitProps.baseProps}
              loading={props.loading}
              noDataIndication={() => (
                <NoDataIndication errorMessage={props.errorMessage} />
              )}
              overlay={overlayFactory({
                spinner: true,
                text: 'Daten werden geladen.',
              })}
              rowEvents={props.rowEvents}
              selectRow={props.selectRow}
              rowStyle={props.rowStyle}
              cellEdit={cellEditFactory({
                mode: 'dbclick',
                blurToSave: true,
              })}
              striped
              hover
              condensed
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  )
}

MedicationListEditable.propTypes = {
  medicationList: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  selectRow: PropTypes.object,
  rowEvents: PropTypes.object,
}

export default MedicationListEditable
