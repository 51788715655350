import { Task } from '../types/Task'

export const defaultTask: Task = {
  id: null,
  title: null,
  priority: null,
  category: null,
  description: null,
  status: null,
  for: null,
  deadline: null,
  start_date: null,
  owner: null,
  author: null,
  lastUpdated: null,
  note: null,
  appointments: null,
  links: null,
}
