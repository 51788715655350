import React, { Component } from 'react'
import { APIResult } from '../../../../constants/APIResult'
import Autocomplete from '../../Autocomplete/Autocomplete'
import { NotificationManager } from 'react-notifications'
import { Organization } from '../../../../types/Organization'
import OrganizationService from '../../../../services/OrganizationService/OrganizationService'

interface Props {
  value?: Organization | string
  disabled?: boolean
  label?: string
  icon?: string
  onChange: (organization: Organization) => void
}

interface State {
  organizations?: Organization[]
  selected: Organization
  value: string
}

class OrganizationSelect extends Component<Props, State> {
  static defaultProps = {
    icon: 'location_city',
  }
  state = {
    selected: null,
    organizations: null,
    value: '',
  }

  componentDidMount(): void {
    /* this is a quickfix to let the ac work as a select */
    this.handleSearch('quickfix')
    const selected = this.getOrganizationFromProps()
    if (selected) this.setState({ selected, value: selected.name })
  }

  getOrganizationFromProps = (): Organization => {
    const { value } = this.props
    if (!value) return null
    if (typeof value === 'string') {
      if (this.state.organizations) {
        const selected = this.state.organizations.find(it => it.id === value)
        return selected
      }
    } else if (typeof value === 'object') {
      return value
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const { value } = this.props

    if (
      prevProps.value !== value ||
      (value && prevState.organizations === null && this.state.organizations)
    ) {
      if (value) {
        const selected = this.getOrganizationFromProps()
        if (selected) this.setState({ selected, value: selected.name })
      } else {
        this.setState({ selected: null, value: '', organizations: null })
      }
    }
  }

  handleSelect = (organization: Organization): void => {
    if (organization !== this.state.selected) this.props.onChange(organization)
    if (organization) {
      this.setState({
        selected: organization,
        value: organization.name,
      })
    } else {
      this.setState({
        selected: null,
        value: '',
      })
    }
  }

  handleSearch = async (param: string): Promise<void> => {
    if (param) {
      const service = new OrganizationService()
      const res = await service.findOrganization(param)
      if (
        res &&
        res.Result === APIResult.SUCCESS &&
        Array.isArray(res.Response)
      ) {
        this.setState({ organizations: res.Response as Organization[] })
      } else {
        NotificationManager.error('Fehler', 'Derzeit keine Antwort vom Server')
      }
    } else {
      this.setState({ organizations: [] })
    }
  }

  renderValue = (organization: Organization): string => {
    return organization.name
  }

  render(): JSX.Element {
    return (
      <Autocomplete
        label={this.props.label}
        data={this.state.organizations}
        onSearch={this.handleSearch}
        onSelect={this.handleSelect}
        selected={this.state.selected}
        disabled={this.props.disabled}
        icon={this.props.icon}
        value={this.state.value}
        renderValue={this.renderValue}
        minCharacters={0}
        placeholder="Zentrum wählen"
        notFoundMessage="kein Zentrum gefunden"
        name={'organization'}
      />
    )
  }
}

export default OrganizationSelect
