import React, { useContext } from 'react'
import { MedicalInstrument } from './FormInterfaces'
import FormDynamicForm from '../FormDynamic/FormDynamicForm'
import ExternalDocumentSelector from '../PatientRecord/PatientFindings/ExternalDocumentSelector'

import Loader from '../UI/Spinner/Loader'
import { BEFUNDE_EINLESEN } from '../../config/Constants'
import { RedCapSaveMethods } from '../../constants/RedCapSaveMethods'
import FieldTerminAufgabeProvider, {
  FieldTerminAufgabeDraft,
} from '../FieldFunctionComponents/FieldTerminAufgabeProvider'
import {
  DynamicForm,
  EventStore,
} from '../../infrastructure/EventProviderInterfaces'

interface IMedicalRoundInstrumentProps {
  form: DynamicForm
  instrument: MedicalInstrument
  closeSidebar?: () => void
  signMedicalRound?: boolean
  showSingleSubmit?: boolean
  index?: number
  isArchived?: boolean
  readOnlyMode?: boolean
}

const MedicalRoundInstrument: React.FC<IMedicalRoundInstrumentProps> = ({
  form,
  index,
  instrument,
  ...props
}: IMedicalRoundInstrumentProps): JSX.Element => {
  const eventContext = useContext(EventStore)
  const saveMethod = eventContext.saveMethod
  const dynamicFormData = form?.data ? form.data : null
  const externalDocuments = form?.externalDocuments
  const assignedDocumentOfPatient = form?.assignedDocument

  const setDocumentReference = (documentId: string) => {
    let assignedDocument = null
    const updatedDocuments = externalDocuments.map(document => {
      if (document.id === documentId) {
        const repeatInstance =
          saveMethod === RedCapSaveMethods.EDIT
            ? parseInt(instrument.repeatInstance) + 1
            : parseInt(instrument.repeatInstance)

        const formcode = `${repeatInstance}::${instrument.instrument_name}`
        document.referencedForm = formcode
        assignedDocument = document
      } else {
        document.referencedForm = null
      }
      return document
    })
    const newForm: DynamicForm = {
      ...form,
      assignedDocument: assignedDocument,
      externalDocuments: updatedDocuments,
    }
    eventContext.updateForm(index, newForm)
  }

  const handleFieldDrafts = (drafts: FieldTerminAufgabeDraft[]): void => {
    const fieldDrafts = form.fieldDrafts || []
    const newForm: DynamicForm = {
      ...form,
      fieldDrafts: [...fieldDrafts, ...drafts],
    }
    eventContext.updateForm(index, newForm)
  }

  const overwriteFieldDrafts = (drafts: FieldTerminAufgabeDraft[]): void => {
    const newForm: DynamicForm = {
      ...form,
      fieldDrafts: [...drafts],
    }
    eventContext.updateForm(index, newForm)
  }

  // // We allow assignment of external documents to forms only in the Befunde form
  let customFooter
  if (instrument.instrument_name === BEFUNDE_EINLESEN) {
    customFooter = function externalDocumentSelector(): JSX.Element {
      return (
        <ExternalDocumentSelector
          selectionList={externalDocuments}
          assignedDocument={assignedDocumentOfPatient}
          setFormReference={setDocumentReference}
          readOnlyMode={props.readOnlyMode}
        />
      )
    }
  }

  return (
    <>
      {dynamicFormData ? (
        <FieldTerminAufgabeProvider
          form={instrument.instrument_name}
          handleFieldDrafts={handleFieldDrafts}
          overwriteFieldDrafts={overwriteFieldDrafts}>
          <FormDynamicForm
            initialValues={dynamicFormData.values}
            formName={instrument.instrument_label}
            components={dynamicFormData.redcapForm}
            customFooter={customFooter}
            defaultFooter={props.showSingleSubmit}
            patientId={instrument?.patientId}
            readOnlyMode={props.readOnlyMode}
          />
        </FieldTerminAufgabeProvider>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default MedicalRoundInstrument
