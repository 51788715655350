import React from 'react'
import { Appointment } from '../../../types/Appointment'
import { DateSelectArg } from '@fullcalendar/react'
import { withAuth } from '../../../infrastructure/AuthProvider'
import { UserRole } from '../../../constants/UserRole'
import moment from 'moment'
import IAuth from '../../../types/Auth'
import ConfiguredCalendar from './ConfiguredCalender'
import { EventDropArg } from '@fullcalendar/core'
import { EventResizeDoneArg } from '@fullcalendar/interaction'
import AppointmentService, {
  verifyCastAppointment,
} from '../../../services/AppointmentService'
import { NotificationManager } from 'react-notifications'
import {
  appointmentWriteDate,
  appointmentWriteTime,
  defaultAppointment,
  mapAppointmentToEvent,
} from '../../../utils/CalendarUtils'
import { DraftKind } from '../../../types/DraftKind'
import { AutosaveService } from '../../../services/AutosaveService'
import { isAlsoDraft, isPureDraft } from '../../../utils/DraftUtils'
import { AppointmentStatus } from '../../../constants/AppointmentStatus'
import {
  NotifierProps,
  SubscriptionType,
  SubscriptionIdentifier,
  withSubscriptionNotifier,
} from 'src/infrastructure/SubscriptionProvider'

interface IProps extends NotifierProps {
  onAppointmentSelected: (a: Appointment) => void
  onDateSelected: (a: Date) => void
  onDateTimeSelected: (a: Date) => void
  refreshAppointments: () => void
  appointments: Appointment[]
  auth: IAuth
  isEditable?: boolean
}

interface IState {
  selectedAppointment: Appointment
  selectedDateInCalendar: Date
  selectedTimeInCalendar: Date

  panosId: string
  selectedFilterOption: string
  selectedRole: UserRole
}

class CalendarOverview extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      selectedAppointment: defaultAppointment(),
      selectedDateInCalendar: null,
      selectedTimeInCalendar: null,
      panosId: this.props.auth.panosId,
      selectedFilterOption: 'Nutzer',
      selectedRole: null,
    }
  }

  findAppointment = calendarAppointment => {
    const draftAppointment = this.props.appointments.find(
      a => a.tempId === calendarAppointment.id
    )
    if (draftAppointment) {
      return draftAppointment
    }
    const appointment = this.props.appointments.find(
      a => a.id === calendarAppointment.id
    )
    return appointment
  }

  onEventSelected = arg => {
    let appointment = this.findAppointment(arg.event)
    appointment = verifyCastAppointment(appointment)

    this.setState({
      selectedAppointment: appointment,
      selectedDateInCalendar: null,
    })

    this.props.onAppointmentSelected(appointment)
  }

  onAppointmentSetTime = async (arg: EventResizeDoneArg) => {
    let appointment = this.findAppointment(arg.event)
    try {
      appointment = verifyCastAppointment(this.findAppointment(arg.event))
    } catch {
      return
    }
    appointment.startingDateTime = appointmentWriteTime(
      appointment,
      arg.event.start
    )
    const durationMinutes = moment.duration(
      moment(arg.event.end).diff(moment(arg.event.start))
    )
    appointment.minutesDuration = durationMinutes.asMinutes()

    if (isAlsoDraft(appointment)) {
      await this.onUpdate(appointment)
    } else {
      const draft = await new AutosaveService<Appointment>().findValueById(
        appointment,
        DraftKind.Appointment
      )
      if (draft) {
        draft.startingDateTime = appointmentWriteTime(draft, arg.event.start)
        await this.onUpdate(draft)
      } else {
        await this.onUpdate(appointment)
      }
    }
    this.props.notifiySubscriptions(
      SubscriptionType.Calendar,
      SubscriptionIdentifier.Appointment,
      appointment.id
    )
  }

  onAppointmentDragged = async (arg: EventDropArg) => {
    const writeTime = !(arg.view.type === 'dayGridMonth')

    const draft = await new AutosaveService<Appointment>().findValueById(
      { id: arg.event.id },
      DraftKind.Appointment
    )
    const appointment = draft ?? this.findAppointment(arg.event)

    appointment.startingDateTime = writeTime
      ? arg.event.start
      : appointmentWriteDate(appointment, arg.event.start)

    if (!isPureDraft(appointment)) {
      await new AutosaveService<Appointment>().deleteValue(appointment)
    }
    const res = await this.onUpdate(appointment)
    this.props.notifiySubscriptions(
      SubscriptionType.Calendar,
      SubscriptionIdentifier.Appointment,
      appointment.id
    )

    if (res.Result === 'success') {
      NotificationManager.success('Erfolg', 'Termin verschoben')
    } else {
      NotificationManager.error(
        'Fehler: ' + JSON.stringify(res.Response),
        'Termin nicht gespeichert, Termin behalten'
      )
    }
    this.props.refreshAppointments()
  }

  onDateSelected = (dateClickInfo: DateSelectArg) => {
    if (dateClickInfo.view.type === 'dayGridMonth') {
      this.props.onDateSelected(dateClickInfo.start)
    } else {
      this.props.onDateTimeSelected(dateClickInfo.start)
    }
  }

  onDelete = async (appointment: Appointment = null) => {
    await new AppointmentService().deleteAppointment(appointment)
    this.props.refreshAppointments()
  }

  onSave = async (appointment: Appointment = null) => {
    const response = await new AppointmentService().postAppointment(appointment)
    this.props.refreshAppointments()
    return response
  }

  onUpdate = async (appointment: Appointment = null) => {
    const response = await new AppointmentService().putAppointment({
      ...appointment,
      status: AppointmentStatus[AppointmentStatus.ARRIVED],
    })
    this.props.refreshAppointments()
    return response
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <ConfiguredCalendar
            events={this.props.appointments?.map(appointment =>
              mapAppointmentToEvent(appointment, this.state.panosId)
            )}
            isEditable={this.props.isEditable}
            eventClick={this.onEventSelected}
            dateClick={this.onDateSelected}
            onAppointmentSetTime={this.onAppointmentSetTime}
            onAppointmentSetDate={this.onAppointmentDragged}
          />
        </div>
      </div>
    )
  }
}

export default withAuth(withSubscriptionNotifier(CalendarOverview))
