import ApiServiceBase from './ApiServiceBase'
import { ApiResponse } from '../types/ApiResponse'
import { ApiError } from '../types/ApiError'
import { API_LOCATION } from '../config/Paths'
import { Location } from '../types/Location'

export class LocationService extends ApiServiceBase {
  getLocations(): Promise<ApiResponse<Location[] | ApiError>> {
    return this.get<null, Location[]>(new URL(API_LOCATION + '/all'))
  }

  // findLocation(value: string): Promise<ApiResponse<Location[] | ApiError>> {
  //   return this.get<null, Location[]>(new URL(API_LOCATION))
  // }

  getLocation(locationId: string): Promise<ApiResponse<Location | ApiError>> {
    return this.get<null, Location>(new URL(API_LOCATION + `/${locationId}`))
  }

  postLocation(location: Location): Promise<ApiResponse<Location | ApiError>> {
    return this.post<Location, Location>(new URL(API_LOCATION), null, location)
  }

  putLocation(location: Location): Promise<ApiResponse<Location | ApiError>> {
    return this.put<Location>(
      new URL(API_LOCATION + `/${location.id}`),
      location
    )
  }
}

export default LocationService
