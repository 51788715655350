import _ from 'lodash'

/***
 * Maps a function two levels of an array to apply in place
 *
 * @param arr
 * @param func
 */
export const mapmap = <Input, Output>(
  arr: Input[][],
  func: (Input) => Output
): Output[][] => {
  return arr.map(it => it.map(func))
}

/*
 * This either accesses a nestest object on a path, given by a dot concatenated string functionally
 * or modifies it at the place. It supports dynamic paths for array, given by a "?" or allows to
 * access the object by the indices.
 *
 * @param _obj : object to use
 * @param path: where to access it
 * @param def: default value if path is not found
 * @param setTo: function to modify the object in place
 */
export function path<T>(
  _obj: Record<string, any> | any[],
  path: string[] | string,
  def: T,
  setTo: (any) => T = null
): Record<string, any> | any[] {
  let obj = _obj
  const paths = _.isArray(path) ? path : path?.split('.') ?? []
  paths.forEach((pathStep, index) => {
    const isToSet = setTo && index === paths.length - 1
    if (!obj || typeof obj !== 'object') return def
    if (Array.isArray(obj))
      if (isNaN(parseInt(pathStep)))
        obj = obj
          .flat()
          .map((it, i) => (isToSet ? (obj[i] = setTo(obj[i])) : it))
      else isToSet ? setTo(obj) : (obj = obj[pathStep])
    else isToSet ? setTo(obj) : (obj = obj[pathStep])
  })

  if (obj === undefined) return def
  if (Array.isArray(obj)) return setTo ? _obj : [...new Set(obj)]
  return setTo ? _obj : obj
}

/**
 * * Groups a nested object, by a given path allowing the features of the "path"
 * functional features
 *
 * @param nestedObject: object to use
 * @param keys: path to group levels by
 */
export const nest = <T>(
  nestedObject: T[],
  keys: string[]
): T[] | Record<string, any> => {
  if (!keys.length) {
    return nestedObject
  }
  const [first, ...rest] = keys
  const groups = _.groupBy(nestedObject, object =>
    path(object, first, undefined)
  )
  return _.mapValues(groups, value => nest(value, rest))
}

/**
 * Zips two arrays like pythons zip function for paring objects e.g.
 *
 * @param a
 * @param b
 */
export const zip = <T1, T2>(a: T1[], b: T2[]): [T1, T2][] =>
  Array.from(Array(Math.max(b.length, a.length)), (_, i) => [a[i], b[i]])

/**
 * Splits a list into equal pieces
 *
 * @param array
 * @param chunk_size
 */
export const chunk = <T>(array: T[], chunk_size: number): T[][] =>
  Array(Math.ceil(array.length / chunk_size))
    .fill(undefined)
    .map((_, index) => index * chunk_size)
    .map(begin => array.slice(begin, begin + chunk_size))

/**
 * Removes duplicates
 *
 * @param array
 * @param property
 */
export const unique = <T>(array: T[], property: string[] | string): T[] => {
  return [
    ...array
      .reduce((a, c) => {
        a.set(path(c, property, null), c)
        return a
      }, new Map())
      .values(),
  ]
}

/**
 * Combination of map and find to apply a function on the first found value
 * @param a
 * @param fn
 */
export const applyFind = <T, R>(a: T[], fn: (x: T) => R): void | R => {
  for (const x of a) {
    const y = fn(x)
    if (y) return y
  }
}

export function lazyFind<T>(a: T[], fn: (T) => boolean): void | T {
  for (const x of a) if (fn(x)) return x
}

/**
 * Find function based on a list, which to find first.
 *
 * Given different choices as an object with, like "take the green ones before the red ones and these before the
 * yellow ones", it reveals by sorting and giving the value
 *
 * @param choices: What to choose from
 * @param priorityDefinition: Which to take first
 * @param start_key: Where to start at least
 */
export const priorityChoice = <T>(
  choices: { [key: string]: T },
  priorityDefinition: string[],
  start_key = null
): [string, T] => {
  const startIndex = start_key ? priorityDefinition.indexOf(start_key) : 0
  return Object.entries(choices)
    .sort(([k, v]) => -priorityDefinition.indexOf(k))
    .find(
      ([k, v]) => priorityDefinition.indexOf(k) < startIndex
    ) as unknown as [string, T]
}

/**
 * Splits a stream or an array into to two based on a predicate. Instead of
 * two times map and filter. Like pythons tee function
 *
 * @param array
 * @param isValid
 */
export const partition = <T>(
  array: T[],
  isValid: (any) => boolean
): [T[], T[]] => {
  return array.reduce(
    ([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]
    },
    [[], []]
  )
}
