import { getIdFromReference } from '../../../services/FhirResourceService'
import { MedRequestStandardModalValues } from '../../../types/MedRequestStandardValues'
import { NotificationManager } from 'react-notifications'
import { Dispatch, SetStateAction } from 'react'

const determineRowColor = (
  medicationRequests: MedicationRequest[],
  values: MedRequestStandardModalValues,
  setIndices: Dispatch<SetStateAction<number[]>>
): boolean => {
  let errorExists = false
  const indices = []
  medicationRequests.forEach(medRequest => {
    if (!medRequest.id) {
      NotificationManager.error('Verschreibungen im Medikationsplan noch leer')
    }
    const idMedication = getIdFromReference(medRequest.medicationReference)
    if (values.selected.includes(idMedication)) {
      const listIndex = values.medicationListData.findIndex(
        it => it.medicationId === idMedication
      )
      indices.push(listIndex)
      setIndices(indices)
      errorExists = true
    }
  })
  if (!errorExists) {
    setIndices([])
  }
  return errorExists
}
export default determineRowColor
