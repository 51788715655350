import React, { lazy, Suspense, useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import {
  ROUTE_ADMINISTRATION,
  ROUTE_CALENDAR,
  ROUTE_CALENDAR_AT,
  ROUTE_CALENDAR_NEW,
  ROUTE_CHAT,
  ROUTE_CHAT_AT,
  ROUTE_CHAT_NEW,
  ROUTE_DASHBOARD,
  ROUTE_DRAFT,
  ROUTE_HOME,
  ROUTE_LETTER,
  ROUTE_LETTER_PREVIEW,
  ROUTE_MEDICATION_DATA_PAGE,
  ROUTE_PATIENT_MONITORING,
  ROUTE_PATIENT_RECORD,
  ROUTE_PATIENT_RECORDS,
  ROUTE_PATIENT_REGISTRATION,
  ROUTE_PATIENT_RELATIVE_MONITORING,
  ROUTE_PROFIL,
  ROUTE_REDCAP_EVENTS,
  ROUTE_REGISTRATION,
  ROUTE_TASKS,
  ROUTE_TASKS_AT,
  ROUTE_TASKS_NEW,
  ROUTE_TELEFORM,
} from './Paths'
import IAuth from '../types/Auth'
import AppointmentsPage from '../components/Appointments/AppointmentsPage'
import ProfilContainer from '../components/Profile/ProfilPage'
import Loader from '../components/UI/Spinner/Loader'
import DraftContainer from '../components/Draft/DraftContainer'
import { UserPermission } from '../constants/UserPermission'
import { RouteProps } from 'react-router'
import { AuthStore } from '../infrastructure/AuthProvider'

const LandingPage = lazy(() => import('../components/LandingPage/LandingPage'))

const Dashboard = lazy(
  () => import('../components/Dashboard/DashboardContainer')
)

const ChatPage = lazy(() => import('../components/Chat/ChatPage'))

const TasksPage = lazy(() => import('../components/Tasks/TasksPage'))

const MedicationDataPage = lazy(
  () => import('../components/Medication/MedicationDataPage')
)

const PatientDataPage = lazy(
  () => import('../components/PatientRecord/PatientDataPage')
)
const PatientRecordsPage = lazy(
  () => import('../components/PatientRecords/PatientRecordsPage')
)
const MonitoringPage = lazy(
  () => import('../components/PatientMonitoring/MonitoringPage')
)
const LetterPreview = lazy(() => import('../components/Letter/LetterPreview'))
const LetterContainer = lazy(
  () => import('../components/Letter/LetterContainer')
)
const RegisterWithCMorPhysician = lazy(
  () => import('../components/Forms/RegistrationForm')
)
const AdminPage = lazy(() => import('../components/Administration/AdminPage'))

const TeleformPage = lazy(() => import('../components/Teleform/TeleformPage'))

const RedCapEventPage = lazy(
  () => import('../components/RedCapEvent/RedCapEventPage')
)
interface Props {
  auth: IAuth
}

const RouterRoutes: React.FC<Props> = (props: Props) => {
  const authenticated = props.auth && props.auth.authenticated

  const defaultPath = authenticated ? ROUTE_DASHBOARD : ROUTE_HOME

  const protectedRoutes = [
    <ProtectedRoute
      exact
      key={'register-patient'}
      permission={UserPermission.PATIENT_ADD}
      path={ROUTE_PATIENT_REGISTRATION}
      component={RegisterWithCMorPhysician}
    />,
    <ProtectedRoute
      exact
      key={'dashboard'}
      path={ROUTE_DASHBOARD}
      component={Dashboard}
    />,
    <ProtectedRoute
      exact
      key={'chat'}
      permission={UserPermission.MESSAGES}
      path={ROUTE_CHAT}
      component={ChatPage}
    />,
    <ProtectedRoute
      exact
      key={'chat-new'}
      permission={UserPermission.MESSAGES}
      path={ROUTE_CHAT_NEW}
      component={ChatPage}
    />,
    <ProtectedRoute
      key={'chat-id'}
      permission={UserPermission.MESSAGES}
      path={ROUTE_CHAT_AT}
      component={ChatPage}
    />,
    <ProtectedRoute
      exact
      key={'tasks'}
      permission={UserPermission.TASK}
      path={ROUTE_TASKS}
      component={TasksPage}
    />,
    <ProtectedRoute
      exact
      key={'tasks-new'}
      permission={UserPermission.TASK_ADD}
      path={ROUTE_TASKS_NEW}
      component={TasksPage}
    />,
    <ProtectedRoute
      exact
      key={'tasks-id'}
      permission={UserPermission.TASK_SHOW}
      path={ROUTE_TASKS_AT}
      component={TasksPage}
    />,
    <ProtectedRoute
      exact
      key={'calendar'}
      permission={UserPermission.APPOINTMENTS}
      path={ROUTE_CALENDAR}
      component={AppointmentsPage}
    />,
    <ProtectedRoute
      exact
      key={'calendar-new'}
      path={ROUTE_CALENDAR_NEW}
      permission={UserPermission.APPOINTMENTS_ADD}
      component={AppointmentsPage}
    />,
    <ProtectedRoute
      exact
      key={'calendar-show'}
      path={ROUTE_CALENDAR_AT}
      permission={UserPermission.APPOINTMENTS_SHOW}
      component={AppointmentsPage}
    />,
    <ProtectedRoute
      exact
      key={'patient-records'}
      path={ROUTE_PATIENT_RECORDS}
      permission={UserPermission.PATIENT_RECORDS}
      component={PatientRecordsPage}
    />,
    <ProtectedRoute
      key={'patient-data'}
      path={ROUTE_PATIENT_RECORD}
      permission={UserPermission.PATIENT_SHOW}
      component={PatientDataPage}
    />,
    <ProtectedRoute
      key={'patient-monitoring'}
      path={ROUTE_PATIENT_MONITORING}
      permission={UserPermission.PATIENT_MONITORING}
      component={MonitoringPage}
    />,
    <ProtectedRoute
      key={'relative-monitoring'}
      path={ROUTE_PATIENT_RELATIVE_MONITORING}
      permission={UserPermission.PATIENT_MONITORING}
      component={MonitoringPage}
    />,
    <ProtectedRoute
      exact
      key={'draft'}
      permission={UserPermission.DRAFTS}
      path={ROUTE_DRAFT}
      component={DraftContainer}
    />,
    <ProtectedRoute
      key={'medication-list'}
      permission={UserPermission.MEDICATIONS}
      path={ROUTE_MEDICATION_DATA_PAGE}
      component={MedicationDataPage}
    />,
    <ProtectedRoute
      exact
      key={'profile'}
      permission={UserPermission.USER_PROFILE}
      path={ROUTE_PROFIL}
      component={ProfilContainer}
    />,
    <ProtectedRoute
      exact
      key={'redcap-event'}
      path={ROUTE_REDCAP_EVENTS}
      component={RedCapEventPage}
    />,

    <ProtectedRoute
      exact
      key={'letter-preview'}
      path={ROUTE_LETTER_PREVIEW}
      component={LetterPreview}
    />,
    <ProtectedRoute
      key={'letter-container'}
      exact
      path={ROUTE_LETTER}
      component={LetterContainer}
    />,
    <ProtectedRoute
      key={'administration'}
      permission={UserPermission.ADMIN_AREA}
      path={ROUTE_ADMINISTRATION}
      component={AdminPage}
    />,
    <ProtectedRoute
      key={'administration'}
      permission={UserPermission.IMPORT_TELEFORM}
      path={ROUTE_TELEFORM}
      component={TeleformPage}
    />,
  ]

  const routes = authenticated
    ? protectedRoutes
    : [
        <Route
          exact
          key={'landingpage'}
          path={ROUTE_HOME}
          component={LandingPage}
        />,
        <Route
          exact
          key={'registration'}
          path={ROUTE_REGISTRATION}
          component={LandingPage}
        />,
      ]
  return (
    <Suspense
      fallback={
        <div className="w-100 text-center">
          <Loader />
        </div>
      }>
      <Switch>
        {routes}
        <Redirect to={defaultPath} />
      </Switch>
    </Suspense>
  )
}
interface ProtectedRouteProps extends RouteProps {
  permission?: UserPermission
}

export const ProtectedRoute = ({
  component: Component,
  permission,
  ...rest
}: ProtectedRouteProps): JSX.Element => {
  const context = useContext(AuthStore)
  const hasPermission = context.handlers.hasPermission
  if (!hasPermission(permission)) return <Redirect to="/" />
  return <Route {...rest} render={props => <Component {...props} />} />
}

export default RouterRoutes
