export const AdministrationOptions = [
  {
    value: ' ',
    label: ' ',
  },
  {
    value: 'oral',
    label: 'oral',
  },
  {
    value: 'transdermal',
    label: 'transdermal',
  },
  {
    value: 'nasal',
    label: 'nasal',
  },
  {
    value: 'rektal',
    label: 'rektal',
  },
  {
    value: 'subcutan',
    label: 'subcutan',
  },
  {
    value: 'intravenös',
    label: 'intravenös',
  },
  {
    value: 'intramuskulär',
    label: 'intramuskulär',
  },
  {
    value: 'inhalativ',
    label: 'inhalativ',
  },
  {
    value: 'Sonstiges',
    label: 'Sonstiges',
  },
]
