import React, { useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import {
  PanosInstitution,
  PanosInstitutionNames,
} from '../../../constants/PanosInstitutions'

export interface QuickFilterButtonGroupProps {
  oneLine?: boolean
  onChange: (newState: PanosInstitution[]) => void
  initialState?: PanosInstitution[]
}

const QuickFilterButtonGroup: React.FC<QuickFilterButtonGroupProps> = (
  props: QuickFilterButtonGroupProps
) => {
  const [quickfilters, setQuickfilters] = useState(props.initialState || [])

  const setNewFilters = (newState: PanosInstitution[]) => {
    props.onChange(newState)
    setQuickfilters(newState)
  }
  return (
    <ButtonGroup>
      {Object.values(PanosInstitution).map((institution, i) => (
        <Button
          key={'quickfilterbutton' + i}
          active={quickfilters.includes(institution)}
          style={{
            margin: '1px',
          }}
          onClick={e => {
            if (quickfilters.includes(institution)) {
              setNewFilters(quickfilters.filter(e => e !== institution))
            } else {
              setNewFilters([...quickfilters, institution])
            }
            ;(e.target as HTMLElement).blur()
          }}>
          Zentrumsarzt{props.oneLine ? ' ' : <br />}
          {PanosInstitutionNames[institution].shortName}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default QuickFilterButtonGroup
