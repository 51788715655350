import React from 'react'

interface DescriptiveTextdProps {
  value: string | JSX.Element
  className?: string
}

function DescriptiveText({
  value,
  className,
}: DescriptiveTextdProps): JSX.Element {
  return (
    <div className={className ? className : ''}>
      <div style={{ height: 'auto', minHeight: '100%', width: '100%' }}>
        {value}
      </div>
    </div>
  )
}

export default DescriptiveText
