import React from 'react'
import { useField, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'

interface DateTimePickerFieldProps {
  name: string
  selectsStart?: boolean
  selectsEnd?: boolean
  selected?: null | Date
  className?: string
}

export const DateTimePickerField: React.FC<DateTimePickerFieldProps> = (
  props
): JSX.Element => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)
  return (
    <DatePicker
      dateFormat="dd.MM.yyyy HH:mm"
      timeFormat="HH:mm"
      showTimeSelect
      {...field}
      {...props}
      selected={field.value ? new Date(field.value) : null}
      onChange={val => {
        setFieldValue(field.name, val)
      }}
      popperModifiers={{
        computeStyle: { gpuAcceleration: false },
      }}
      showWeekNumbers
    />
  )
}
