import React, { useContext, useState } from 'react'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { RedCapSaveStatus } from '../../constants/RedCapSaveMethods'
import { SIGNABLE_EVENTS } from '../../config/Constants'
import { ConfirmDialogue } from '../UI/Dialogues/ConfirmDialogue'
import Loader from '../UI/Spinner/Loader'
import { MedicalRoundSignStatus } from '../../types/MedicalRoundSignStatus'
import PractitionerSelect from '../UI/Forms/PractitionerSelect/PractitionerSelect'
import MaterialIcon from '../UI/MaterialIcon/MaterialIcon'
import { UserPermission } from '../../constants/UserPermission'
import { AuthStore } from '../../infrastructure/AuthProvider'
import './MultipleFormButtons.scss'

interface MultipleFormButtonProps {
  event: string
  signStatus?: MedicalRoundSignStatus
  isLoading?: boolean
  onTriggerSubmit: (
    validate: boolean,
    saveStatus: RedCapSaveStatus,
    assignTo?: string, //Practitioner ID
    sign?: boolean
  ) => void
  onTriggerAutosave: () => void
  onClose: () => void
}

const MultipleFormButtons: React.FC<MultipleFormButtonProps> = (
  props: MultipleFormButtonProps
) => {
  const context = useContext(AuthStore)
  const { hasPermission } = context.handlers
  const setTriggerSubmit = props.onTriggerSubmit
  const setTriggerAutosave = props.onTriggerAutosave

  const [showAssignModal, setShowAssignModal] = useState<boolean>(null)
  const [assignTo, setAssignTo] = useState<string>(null)

  const buttonCssClass =
    'd-flex align-items-center justify-content-center event-action-button'

  const submitFormWithoutValidationAndAssign = () => {
    if (assignTo) {
      setTriggerSubmit(false, RedCapSaveStatus.INCOMPLETE, assignTo)
      setShowAssignModal(false)
    }
  }

  const submitFormWithoutValidation = () => {
    setTriggerSubmit(false, RedCapSaveStatus.INCOMPLETE, null)
  }

  const submitFormWithValidationAndStatus = () => {
    setTriggerSubmit(true, RedCapSaveStatus.COMPLETE, null)
  }

  const signForms = () => {
    // set to false only for testing
    setTriggerSubmit(true, RedCapSaveStatus.COMPLETE, null, true)
  }

  const submitToDrafts = () => {
    setTriggerAutosave()
  }

  const saveToDrafts = (): JSX.Element => {
    return (
      <OverlayTrigger
        overlay={
          <Tooltip id="save-drafts">
            Zwischenspeichern in eigene Entwürfe. Der Bearbeitungsstand des
            Formulars ist nicht für andere sichtbar.
          </Tooltip>
        }>
        <Button
          id="save-drafts"
          className={`${buttonCssClass} mb-2`}
          onClick={submitToDrafts}
          variant="light">
          <MaterialIcon icon="edit" className="mr-1" />
          Zwischenspeichern in eigene Entwürfe
        </Button>
      </OverlayTrigger>
    )
  }

  const saveAssign = (): JSX.Element => {
    return (
      <OverlayTrigger
        overlay={
          <Tooltip id="save-validation">
            Alle Änderungen speichern ohne vollständige Validierung und zur
            weiteren Bearbeitung zuweisen.
          </Tooltip>
        }>
        <Button
          className={buttonCssClass}
          id="save-validation"
          onClick={() => setShowAssignModal(true)}
          variant="info">
          <MaterialIcon icon="person_search" />
          An andere Person weiterreichen
        </Button>
      </OverlayTrigger>
    )
  }

  const saveNoValidation = (): JSX.Element => {
    return (
      <OverlayTrigger
        overlay={
          <Tooltip id="save-no-validation">
            Alle Änderungen zwischenspeichern ohne vollständige Validierung
          </Tooltip>
        }>
        <Button
          className={buttonCssClass}
          onClick={submitFormWithoutValidation}
          id="save-no-validation">
          <MaterialIcon icon="hourglass_bottom" />
          Speichern ohne vollständige Validierung
        </Button>
      </OverlayTrigger>
    )
  }

  const saveValidationClose = (): JSX.Element => {
    return (
      <OverlayTrigger
        overlay={
          <Tooltip id="save-final">
            Alle Änderungen speichern mit Validierung und Status abgeschlossen
          </Tooltip>
        }>
        <Button
          className={buttonCssClass}
          onClick={submitFormWithValidationAndStatus}
          id="save-final"
          variant="success">
          <MaterialIcon icon="check" />
          Speichern
        </Button>
      </OverlayTrigger>
    )
  }

  const saveUnsigned = (): JSX.Element => {
    return (
      hasPermission(UserPermission.MEDICAL_ROUND_SIGN) && (
        <OverlayTrigger
          overlay={
            <Tooltip id="sign-unsigned-forms">
              Visite speichern mit Validierung, digitialer Unterschrift und
              Zeitstempel
            </Tooltip>
          }>
          <Button
            className={buttonCssClass}
            id="sign-unsigned-forms"
            onClick={signForms}
            variant="success">
            <MaterialIcon icon="check" />
            Speichern und vidieren
          </Button>
        </OverlayTrigger>
      )
    )
  }

  const saveUnsignedWithCaution = (): JSX.Element => {
    return (
      hasPermission(UserPermission.MEDICAL_ROUND_SIGN) && (
        <ConfirmDialogue
          callback={signForms}
          question={
            'Ihre eigener Position ist noch nicht im Profil hinterlegt.' +
            ' Möchten Sie trotzdem vidieren? Die Prüfung der Hierarchie kann bei ' +
            'erneuter Vidierung nicht erfolgen.'
          }
          confirmText="Ja"
          title="Trotzdem vidieren">
          <OverlayTrigger
            overlay={
              <Tooltip id="sign-unsigned-forms-no-position">
                Visite speichern mit Validierung, digitialer Unterschrift und
                Zeitstempel. Achtung, keine Position hinterlegt.
              </Tooltip>
            }>
            <Button
              className={buttonCssClass}
              id="sign-unsigned-forms-no-position"
              variant="success">
              <MaterialIcon icon="check" />
              Speichern und vidieren
            </Button>
          </OverlayTrigger>
        </ConfirmDialogue>
      )
    )
  }

  const saveSignedAllowed = (): JSX.Element => {
    return (
      hasPermission(UserPermission.MEDICAL_ROUND_SIGN) && (
        <OverlayTrigger
          overlay={
            <Tooltip id="sign-signed-forms">
              Visite in neuer Version mit digitialer Unterschrift und
              Zeitstempel erneut vidieren.
            </Tooltip>
          }>
          <Button
            className={buttonCssClass}
            id="sign-signed-forms"
            onClick={signForms}
            variant="success">
            <MaterialIcon icon="check" />
            Speichern und erneut vidieren
          </Button>
        </OverlayTrigger>
      )
    )
  }

  const saveSignedWithCaution = (): JSX.Element => {
    return (
      hasPermission(UserPermission.MEDICAL_ROUND_SIGN) && (
        <ConfirmDialogue
          callback={signForms}
          question={
            'Die Visite wurde bereits von einer Person ohne hinterlegten Rang vidiert oder Ihr eigener Rang ist nicht hinterlegt.' +
            ' Möchten Sie trotzdem erneut vidieren?'
          }
          confirmText="Ja"
          title="Erneut vidieren">
          <OverlayTrigger
            overlay={
              <Tooltip id="sign-unsigned-forms-no-position">
                Visite in neuer Version mit digitialer Unterschrift und
                Zeitstempel erneut vidieren. Hinweis: Eigener Rang oder
                vorheriger Rang nicht bekannt
              </Tooltip>
            }>
            <Button
              className={buttonCssClass}
              id="sign-forms"
              variant="warning">
              <MaterialIcon icon="check" />
              Speichern und erneut vidieren
            </Button>
          </OverlayTrigger>
        </ConfirmDialogue>
      )
    )
  }
  return (
    <div className="mb-3">
      {/*Zuweisen Modal für alle Events sichtbar*/}
      <Modal show={showAssignModal} onHide={() => setShowAssignModal(false)}>
        <Modal.Header>An andere Person zuweisen</Modal.Header>
        <Modal.Body>
          <PractitionerSelect
            label="Bearbeiter"
            value={null}
            onChange={value => setAssignTo(value.id)}
            icon="person"
            disabled={false}
            onlyRegistered
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant={'secondary'}
            onClick={() => setShowAssignModal(false)}>
            Abbrechen
          </Button>
          <Button
            variant={'primary'}
            onClick={submitFormWithoutValidationAndAssign}>
            Zuweisen und speichern
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Während die Formulare in medicalRoundInstrumentCallback zum speichern im BE gesammelt werden, sind alle Speicher buttons deaktiviert
       um doppelte Aktionen zu verhindern */}
      {props.isLoading && <Loader size={30} />}

      {/* Speichern in eigene Entwürfe ist immer möglich */}
      {!props.isLoading && saveToDrafts()}

      {/* Standard Speichermöglichkeiten für alle Events außer Visite*/}
      {!SIGNABLE_EVENTS.includes(props.event) && !props.isLoading && (
        <>
          {saveAssign()}
          {saveNoValidation()}
          {saveValidationClose()}
        </>
      )}
      {/* Speichermöglichkeiten für Visite Zentrumsarzt. Während der SignStatus ermittelt wird sind alle Buttons deaktiviert*/}
      {SIGNABLE_EVENTS.includes(props.event) &&
        !props.signStatus &&
        !props.isLoading && <Loader size={40} />}

      {/* Speichermöglichkeiten für Visite Zentrumsarzt: Nicht vidiert */}
      {SIGNABLE_EVENTS.includes(props.event) &&
        props.signStatus === MedicalRoundSignStatus.UNSIGNED &&
        !props.isLoading && (
          <>
            {saveAssign()}
            {saveNoValidation()}
            {saveUnsigned()}
          </>
        )}

      {/* Speichermöglichkeiten für Visite Zentrumsarzt: Nicht vidiert mit Warnung */}
      {SIGNABLE_EVENTS.includes(props.event) &&
        props.signStatus === MedicalRoundSignStatus.WITH_CAUTION &&
        !props.isLoading && (
          <>
            {saveAssign()}
            {saveNoValidation()}
            {saveUnsignedWithCaution()}
          </>
        )}

      {/* Speichermöglichkeiten für Visite Zentrumsarzt: Vidiert, erneutes Vidieren erlaubt */}
      {SIGNABLE_EVENTS.includes(props.event) &&
        props.signStatus === MedicalRoundSignStatus.ALLOWED &&
        !props.isLoading &&
        saveSignedAllowed()}

      {/* Speichermöglichkeiten für Visite Zentrumsarzt: Vidiert, erneutes Vidieren mit Warnung */}
      {SIGNABLE_EVENTS.includes(props.event) &&
        props.signStatus === MedicalRoundSignStatus.SIGNED_WITH_CAUTION &&
        !props.isLoading &&
        saveSignedWithCaution()}

      <Button className="mt-2 w-100" onClick={props.onClose}>
        Beenden
      </Button>
    </div>
  )
}

export default MultipleFormButtons
