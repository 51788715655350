import ApiServiceBase, { API_RETURN } from './ApiServiceBase'
import {
  API_PRACTITIONER,
  API_USERS,
  API_USERS_PATIENTS,
  API_USERS_PRACTITIONERS,
  API_USERS_RESET_PASSWORD,
} from '../config/Paths'
import { ApiResponse } from '../types/ApiResponse'
import { User, UserRegistrationResult } from '../types/User'
import { ApiError } from '../types/ApiError'
import { APIResult } from '../constants/APIResult'
import { UserPermission } from '../constants/UserPermission'
import { Patient } from '../types/Patient'
import { PrivilegeService } from './PrivilegeService'
import { getUserRoles } from './Keycloak'
import { RoleRight } from '../types/RoleRight'

class UserService extends ApiServiceBase {
  getRegisteredPractitioners(): Promise<ApiResponse<User[] | ApiError>> {
    return this.get<null, User[]>(
      new URL(API_USERS_PRACTITIONERS + '?onlyRegistered=true')
    )
  }

  getUnregisteredPractitioners(): Promise<ApiResponse<User[] | ApiError>> {
    return this.get<null, User[]>(
      new URL(API_USERS_PRACTITIONERS + '?onlyRegistered=false')
    )
  }

  getCurrentUser(): Promise<ApiResponse<User | ApiError>> {
    return this.get<null, User>(new URL(API_USERS))
  }

  getUserById(userId: string): Promise<ApiResponse<User | ApiError>> {
    return this.get<null, User>(new URL(API_USERS + `/${userId}`))
  }

  postUser(user: User): Promise<ApiResponse<User | ApiError>> {
    return this.post<User, User>(new URL(API_USERS), null, user)
  }

  updateUser(user: User): Promise<ApiResponse<User | ApiError>> {
    return this.put<User>(
      new URL(
        user.type === 'Practitioner' ? API_USERS_PRACTITIONERS : API_USERS
      ),
      user
    )
  }

  deleteUser(user: User): Promise<ApiResponse<string | ApiError>> {
    const url = new URL(
      user.type === 'Practitioner'
        ? API_USERS_PRACTITIONERS
        : API_USERS_PATIENTS
    )
    return this.delete<User>(url, user)
  }

  resetUserPassword(user: User): Promise<ApiResponse<Blob | ApiError>> {
    return this.post<User, Blob>(
      new URL(API_USERS_RESET_PASSWORD),
      null,
      user,
      API_RETURN.blob
    )
  }

  getUserByEmail(email: string): Promise<ApiResponse<User | ApiError>> {
    const url = new URL(`${API_USERS}/userinfo`)
    url.searchParams.append('eMail', email)
    return this.get<null, User>(url)
  }

  getMedicalAdmin(): Promise<ApiResponse<User | ApiError>> {
    const url = new URL(`${API_PRACTITIONER}/medicaladmin`)
    return this.get<null, User>(url)
  }

  createUserAccount(
    user: User
  ): Promise<ApiResponse<UserRegistrationResult | ApiError>> {
    const url = new URL(
      user.type === 'Practitioner'
        ? API_USERS_PRACTITIONERS
        : API_USERS_PATIENTS
    )
    return this.post<User, UserRegistrationResult>(
      url,
      null,
      user,
      API_RETURN.json
    )
  }

  createUserAccountFromPatient(
    patient: Patient
  ): Promise<ApiResponse<Record<string, string> | ApiError>> {
    const url = new URL(API_USERS_PATIENTS)
    return this.post<Patient, Record<string, string>>(url, null, patient)
  }

  getNotifications(): Promise<ApiResponse<Record<string, number> | ApiError>> {
    return this.get<null, Record<string, number>>(
      new URL(API_USERS + '/notifications')
    )
  }

  async getUserPermissions(): Promise<
    ApiResponse<UserPermission[] | ApiError>
  > {
    const userRoles = getUserRoles()
    if (userRoles.length) {
      const userRole = userRoles[0]
      const rightsResponse = await new PrivilegeService().getSingle(userRole)

      if (rightsResponse.Result === APIResult.SUCCESS) {
        const userPermissions = (rightsResponse.Response as RoleRight).rights
        return await {
          Result: APIResult.SUCCESS,
          Response: await userPermissions,
        }
      }
    }
    return await {
      Result: APIResult.FAILURE,
      Response: [],
    }
  }
}

export default UserService
