import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { getAllMedicationActivityDefinition } from '../../../services/MedicationActivityDefinitionService'
import { MedicationTableRow } from '../../../utils/MedicationUtils'
import { Button } from 'react-bootstrap'
import { getMedicationList } from './MedicationListEditable'
import MedicationRequestFromStandardMedicationModal from './MedicationRequestFromStandardMedicationModal'
import {
  createMedicationRequest,
  createMedicationRequestObject,
} from '../../../services/MedicationRequestService'
import moment from 'moment'
import roundTo from 'round-to'
import CarePlanService from '../../../services/CarePlanService'
import { MedicationReason } from '../MedicationSchedule/MedicationReason'
import { AuthStore } from '../../../infrastructure/AuthProvider'
import { UserPermission } from '../../../constants/UserPermission'
import { getMedicationById } from '../../../services/MedicationService'
import { addResourceTypeIfNot } from '../../../utils/Utils'
import { MedicationListTypes } from '../../../constants/MedicationListTypes'
import { MedRequestStandardModalValues } from '../../../types/MedRequestStandardValues'
import { CarePlan } from '../../../types/CarePlan'
import { NotificationManager } from 'react-notifications'
import { MedRequestStandardModalErrors } from '../../../types/MedRequestStandardModalErrors'
import { cloneDeep } from 'lodash'
import determineRowColor from './DetermineRowColor'

interface StandardMedicationRequestProps {
  patientId: string
  carePlan: CarePlan
  medicationRequests: MedicationRequest[]
  handleMedicationRequests: (medicationRequests: MedicationRequest[]) => void
  reason: MedicationReason
}

export const InitialValues = {
  startDate: moment().startOf('day').toDate(),
  endDate: moment().endOf('day').add(70, 'years').toDate(),
  medicationListData: [] /** entire medicationList data */,
  medicationRow: [] /** row selection */,
  selected: [] /** selected row as string */,
  variable: null,
  isLoading: false,
}

export const InitialErrors = {
  startDate: '',
  endDate: '',
  medicationListData:
    [] /** entire medicationList data: corresponding errors */,
  medicationRow: [] /** row selection: corresponding errors */,
  selected: '' /** selected row as string: corresponding errors */,
  variable: '',
  isLoading: '',
}

const StandardMedicationRequest: React.FC<StandardMedicationRequestProps> = ({
  patientId,
  carePlan,
  medicationRequests,
  handleMedicationRequests,
  reason,
}: StandardMedicationRequestProps): JSX.Element => {
  const [showStandardMedicationModal, setShowStandardMedicationModal] =
    useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [loadingMedicationList, setLoadingMedicationList] =
    useState<boolean>(false)
  const context = useContext(AuthStore)
  const { hasPermission } = context.handlers
  const [errors, setErrors] = useState<MedRequestStandardModalErrors>(
    cloneDeep(InitialErrors)
  )
  const [values, setValues] = useState<MedRequestStandardModalValues>(
    cloneDeep(InitialValues)
  )
  const [indices, setIndices] = useState([])

  const validate = (
    values: MedRequestStandardModalValues,
    setIndices: Dispatch<SetStateAction<number[]>>
  ) => {
    let errorExists = false
    const newErrors = cloneDeep(InitialErrors)
    /** handle date error handling */
    const today = moment().startOf('day')
    if (moment(values.startDate).isBefore(today)) {
      newErrors.startDate = 'Startdatum darf nicht vor heute liegen'
      errorExists = true
    }
    if (moment(values.endDate).isBefore(moment(values.startDate))) {
      newErrors.endDate = 'Enddatum darf nicht vor Startdatum liegen'
      errorExists = true
    }
    if (values.selected.length === 0) {
      newErrors.selected =
        'Eine Auswahl an Standardmedikamenten muss getroffen werden. '
      errorExists = true
    }
    /** handle duplicate error handling */
    if (determineRowColor(medicationRequests, values, setIndices)) {
      newErrors.selected +=
        'Ein im Medikationsplan bereits enthaltenes Medikament ' +
        'darf nicht doppelt verschrieben werden. ' +
        'Bitte modifizieren Sie die rot markierte(n) Zeile(n).'
      errorExists = true
    }
    setErrors(newErrors)
    return errorExists
  }

  const standardMedicationCreateClick = () => {
    getMedicationList(
      getAllMedicationActivityDefinition(
        'false',
        MedicationListTypes.standardList
      ),
      loadingMedicationList,
      setErrorMessage,
      setLoadingMedicationList,
      cloneDeep(InitialValues),
      setValues /** hier muss das values Objekt eingebunden werden */
    )
    setShowStandardMedicationModal(true)
  }

  const rowStyle = (row, rowIndex) => {
    const style = { backgroundColor: '' }
    if (indices.includes(rowIndex)) {
      style.backgroundColor = 'red'
    }
    return style
  }

  /**
   * @param {MedRequestStandardModalValues} values
   */
  async function addStandardMedication(
    values: MedRequestStandardModalValues
  ): Promise<void> {
    try {
      /** call validate functionality **/
      const errorExists = validate(values, setIndices)
      if (!errorExists) {
        const requests = cloneDeep(medicationRequests)
        for (const item of values.selected) {
          const medActivityDefinition = values.medicationListData.find(
            element => element.medicationId === item
          )
          const medicationTypeId = addResourceTypeIfNot(item, 'Medication')
          const selectedMedication = await getMedicationById(
            medicationTypeId
          ).then(response => response.data)

          requests.push(
            await createMedicationRequest(
              createMedicationRequestObjectFromActivityDefinition(
                patientId,
                medActivityDefinition,
                values.startDate,
                values.endDate,
                selectedMedication
              )
            ).then(response => response.data)
          )
        }
        if (carePlan.id) {
          await new CarePlanService(reason).updateCarePlansMedicationRequests(
            carePlan.id,
            requests.map(it => it.id.toString())
          )
        }
        handleMedicationRequests(requests)
        setShowStandardMedicationModal(false)
      }
    } catch (e) {
      console.log(e)
      NotificationManager.error(
        'Standardmedikamente konnten nicht verschrieben werden'
      )
    } finally {
      setValues({ ...values, isLoading: false })
    }
  }

  const handleCloseModal = (): void => {
    setShowStandardMedicationModal(false)
    setIndices([])
    setValues(cloneDeep(InitialValues))
    setErrors(cloneDeep(InitialErrors))
  }

  return (
    <>
      <Button
        disabled={!hasPermission(UserPermission.PATIENT_MEDICATION_ADD_EDIT)}
        onClick={standardMedicationCreateClick}
        variant={'outline-dark'}
        size="sm">
        Standardmedikamente verschreiben
      </Button>
      {showStandardMedicationModal && (
        <MedicationRequestFromStandardMedicationModal
          onClose={handleCloseModal}
          errorMessage={errorMessage}
          loading={loadingMedicationList}
          onSubmit={addStandardMedication}
          values={values}
          errors={errors}
          setValues={setValues}
          rowStyle={rowStyle}
          validate={validate}
          setIndices={setIndices}
        />
      )}
    </>
  )
}

/**
 *
 * @param {id} patientId
 * @param {MedicationTableRow} medActivityDefinition
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {Medication} medication
 * @return {MedicationRequest}
 */
function createMedicationRequestObjectFromActivityDefinition(
  patientId: id,
  medActivityDefinition: MedicationTableRow,
  startDate: Date,
  endDate: Date,
  medication: Medication
): MedicationRequest {
  const dosageAsNumber = Number(
    medActivityDefinition.standardDosage.split('/')[0].replace(',', '.')
  )
  let pieces =
    dosageAsNumber / medication?.ingredient[0]?.strength.numerator.value
  if (medication.form.text === 'Tropfen') {
    pieces *= 20
  }
  pieces = roundTo(pieces, 8)

  const dosageInstruction = [
    {
      timing: {
        repeat: {
          boundsPeriod: {
            start: moment(startDate).startOf('minute').toISOString(true),
            end: moment(endDate).startOf('minute').toISOString(true),
          },
          frequency: medActivityDefinition.timingChosen.split('-').length,
          period: 1,
          periodUnit: 'd',
          periodMax: null,
          frequencyMax: null,
          timeOfDay:
            /**
             * @type {Array.<time>}
             */
            medActivityDefinition.timingChosen
              .split('-')
              .map(hour => hour + ':00'),
        },
      },
      text: medActivityDefinition.standardDosage + ';' + pieces,
    },
  ]

  return createMedicationRequestObject(
    patientId,
    medActivityDefinition.businessName,
    dosageInstruction,
    medActivityDefinition.identifiers,
    medication,
    medication.id
  )
}

export { StandardMedicationRequest }
