import React from 'react'
import { ArrayHelpers, useFormikContext } from 'formik'
import Select from 'react-select'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { cloneDeep } from 'lodash'
import './DosageAndTimingSelector.sass'
import moment from 'moment'
import MaterialIcon from '../../UI/MaterialIcon/MaterialIcon'
import DatePicker from 'react-datepicker'
import { TimeRange } from '../../Medication/MedicationRequest/MedicationRequestModal'
import { DosageOnDemandSelector } from './DosageOnDemandSelector'
import { OnInsertedPiecesChanged } from './OnInsertedPiecesChanged'
import { SetTimingAndDosage } from './SetTimingAndDosage'
import { OnInsertedDosageChanged } from './OnInsertedDosageChanged'
import { OnInsertedVolumeChanged } from './OnInsertedVolumeChanged'
import roundTo from 'round-to'
import { OptionChoices } from '../../../utils/MedicationUtils'

const emptyDosageObject = {
  dose: null,
  times: [],
  period: 1,
  pieces: 1,
  volume: 0.05,
}

export const periodUnitOptions = [
  { value: 'h', label: 'Stunde' },
  { value: 'd', label: 'Tag' },
  { value: 'wk', label: 'Woche' },
]

function parseTiming(timeValuesString) {
  return timeValuesString
    .split('-')
    ?.map(time => new Date().setHours(parseInt(time), 0, 0, 0))
}

interface Dosage {
  times: number[]
  dose: {
    value?: string
    label?: string
  }
  pieces?: number
  volume?: number
  frequencyMax?: number
  period?: number
  periodUnit?: {
    value?: string
    label?: string
  }
  distance?: number
  distanceUnit?: {
    value?: string
    label?: string
  }
}

export interface TimingAndDosage {
  timing: number[]
  selectedTiming: { value: string; label: string }
  dosages: Dosage[]
  patientInstructions?: string
}

export interface DosageAndTimingSelectorProps {
  name: string
  arrayHelpers: ArrayHelpers
  standardMedication: Medication
  index: number
  timeInterval: TimeRange
  timingAndDosage: TimingAndDosage
  dosageOptions: OptionChoices[]
  timingOptions: OptionChoices[]
  package: OptionChoices
  isValueError: boolean
}

export const DosageAndTimingSelector: React.FC<DosageAndTimingSelectorProps> = (
  props: DosageAndTimingSelectorProps
): JSX.Element => {
  const { setFieldValue } = useFormikContext()

  if (!props.timingOptions || !props.dosageOptions) {
    return
  }
  const selectedTiming = props.timingAndDosage.timing.filter(time =>
    props.timingAndDosage.dosages.find(dosageObject =>
      dosageObject.times.includes(time)
    )
  )

  function handleTimeSelected(time, index) {
    const newTimingAndDosage = cloneDeep(props.timingAndDosage)
    const isInCurrentTaD =
      props.timingAndDosage.dosages[index].times.includes(time)
    if (isInCurrentTaD) {
      newTimingAndDosage.dosages[index].times = newTimingAndDosage.dosages[
        index
      ].times.filter(doTime => doTime !== time)
    } else {
      newTimingAndDosage.dosages[index].times = [
        ...newTimingAndDosage.dosages[index].times,
        time,
      ]
    }
    SetTimingAndDosage(newTimingAndDosage, props)
  }

  function deleteDosageObject(index) {
    const newTimingAndDosage = cloneDeep(props.timingAndDosage)
    newTimingAndDosage.dosages = newTimingAndDosage.dosages.filter(
      (dosageObject, doIndex) => doIndex !== index
    )
    SetTimingAndDosage(newTimingAndDosage, props)
  }

  function selectUnselected(index) {
    const newTimingAndDosage = cloneDeep(props.timingAndDosage)
    newTimingAndDosage.dosages[index].times = [
      ...newTimingAndDosage.dosages[index].times,
      ...props.timingAndDosage.timing.filter(
        time => !selectedTiming.includes(time)
      ),
    ]
    SetTimingAndDosage(newTimingAndDosage, props)
  }

  function setTimingByIndex(newTime, index) {
    const newTimeValue = newTime.valueOf()
    if (props.timingAndDosage.timing.find(time => time === newTimeValue)) {
      return
    }
    const newTimingAndDosage = cloneDeep(props.timingAndDosage)
    const oldTimeValue = cloneDeep(newTimingAndDosage.timing[index])

    newTimingAndDosage.timing[index] = newTimeValue
    newTimingAndDosage.timing.sort((a, b) => a - b)
    newTimingAndDosage.dosages.forEach(dosage => {
      dosage.times = dosage.times.map(time =>
        time === oldTimeValue ? newTimeValue : time
      )
    })
    SetTimingAndDosage(newTimingAndDosage, props)
  }

  function addTiming() {
    const newTimingAndDosage = cloneDeep(props.timingAndDosage)
    const lastTimeMoment = moment(
      newTimingAndDosage.timing[newTimingAndDosage.timing.length - 1]
    )
    if (
      lastTimeMoment.isSame(moment(lastTimeMoment).add(30, 'minutes'), 'day')
    ) {
      newTimingAndDosage.timing.push(
        lastTimeMoment.add(30, 'minutes').valueOf()
      )
      SetTimingAndDosage(newTimingAndDosage, props)
    }
  }

  function isAddTimePossible() {
    const lastTimeMoment = moment(
      props.timingAndDosage.timing[props.timingAndDosage.timing.length - 1]
    )
    return (
      lastTimeMoment.isSame(moment(lastTimeMoment).add(30, 'minutes'), 'day') &&
      props.timingAndDosage.timing.length < 13
    )
  }

  function removeTime(index) {
    const newTimingAndDosage = cloneDeep(props.timingAndDosage)
    const oldTimeValue = cloneDeep(newTimingAndDosage.timing[index])
    newTimingAndDosage.timing.splice(index, 1)
    newTimingAndDosage.dosages.map(dosage => {
      dosage.times = dosage.times.filter(time => time !== oldTimeValue)
      return dosage
    })
    SetTimingAndDosage(newTimingAndDosage, props)
  }

  function onInsertedPeriodChanged(value, index) {
    const newTimingAndDosage = cloneDeep(props.timingAndDosage)
    newTimingAndDosage.dosages[index].period = value
    SetTimingAndDosage(newTimingAndDosage, props)
  }

  return (
    <>
      <Select
        className={
          props.isValueError ? 'timing-select select-invalid' : 'timing-select'
        }
        classNamePrefix="timing-select"
        options={[
          { value: 'prn', label: 'Bedarfsmedikation' },
          ...props.timingOptions,
        ]}
        value={props.timingAndDosage.selectedTiming}
        onChange={val => {
          const dosePiecesVolume = OnInsertedPiecesChanged(
            cloneDeep(props.timeInterval.timingAndDosage.dosages[0].pieces) ??
              props.standardMedication?.form?.text !== 'Tropfen'
              ? 1
              : 20,
            0,
            props
          )

          if (val.value === 'prn') {
            SetTimingAndDosage(
              {
                timing: [],
                selectedTiming: val,
                dosages: [
                  {
                    times: [],
                    ...dosePiecesVolume,
                    frequencyMax: 1,
                    periodUnit: periodUnitOptions[0],
                    period: 1,
                    distance: 1,
                    distanceUnit: periodUnitOptions[0],
                  },
                ],
              },
              props
            )
          } else {
            SetTimingAndDosage(
              {
                timing: parseTiming(val.value),
                selectedTiming: val,
                dosages: [
                  {
                    ...emptyDosageObject,
                    times: parseTiming(val.value),
                    ...dosePiecesVolume,
                  },
                ],
              },
              props
            )
          }
        }}
      />
      {props.timingAndDosage.timing.length > 0 && (
        <div className={'wrapper-dosage-timing-table'}>
          <Table
            className="dosage-timing-table mt-2"
            variant={props.isValueError ? 'danger' : ''}
            data-testid={'timing-dosage-table-interval-' + props.index}
            bordered
            size="sm">
            <thead>
              <tr>
                {props.standardMedication?.ingredient[0]?.strength.numerator
                  .unit === 'mg/ml' && (
                  <th className="dosage-timing-table-first-col">
                    Dosis [
                    {props.standardMedication?.ingredient?.map((it, index) => {
                      if (index === 0) {
                        return 'mg'
                      } else {
                        return '|mg'
                      }
                    })}
                    ]
                  </th>
                )}
                {props.standardMedication?.ingredient[0]?.strength.numerator
                  .unit !== 'mg/ml' && (
                  <th className="dosage-timing-table-first-col">
                    Dosis [
                    {props.standardMedication?.ingredient?.map((it, index) => {
                      if (index === 0) {
                        return it.strength.numerator.unit
                      } else {
                        return '|' + it.strength.numerator.unit
                      }
                    }) ?? ''}
                    ]
                  </th>
                )}
                {(props.standardMedication?.ingredient[0]?.strength.numerator
                  .unit !== 'mg/ml' ||
                  props.standardMedication?.form?.text === 'Tropfen' ||
                  props.standardMedication?.form?.text === 'Pflaster' ||
                  props.standardMedication?.form?.text === 'Pen') && (
                  <th className="dosage-timing-table-second-col">
                    Stückzahl [{props.standardMedication?.form?.text ?? ''}]
                  </th>
                )}
                {props.standardMedication?.ingredient[0]?.strength.numerator
                  .unit === 'mg/ml' && (
                  <th className="dosage-timing-table-third-col">Menge [ml]</th>
                )}
                <th className="dosage-timing-table-fourth-col">
                  Periode in Tagen
                </th>
                {props.timingAndDosage.timing.map((time, index) => (
                  <th key={'header-time-' + time}>
                    <DatePicker
                      selected={new Date(time)}
                      onChange={date => setTimingByIndex(date, index)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="Einnahmezeit"
                      dateFormat="HH:mm"
                    />
                    {props.timingAndDosage.timing.length > 1 && (
                      <Button
                        className="ml-1"
                        onClick={() => removeTime(index)}
                        size="sm">
                        <MaterialIcon
                          icon="delete"
                          verticalAlignment="middle"
                          size="14px"
                        />
                      </Button>
                    )}
                  </th>
                ))}
                <th className="dosage-timing-table-last-col">
                  <Button
                    data-testid={'button-new-time-interval-' + props.index}
                    onClick={() => addTiming()}
                    size="sm"
                    disabled={!isAddTimePossible()}>
                    <MaterialIcon
                      icon="add"
                      verticalAlignment="middle"
                      size="14px"
                    />
                    <MaterialIcon
                      icon="access_time"
                      verticalAlignment="middle"
                      size="14px"
                    />
                  </Button>
                  {!isAddTimePossible() && (
                    <OverlayTrigger
                      delay={{ show: 250, hide: 400 }}
                      overlay={props => (
                        <Tooltip id="button-tooltip" {...props}>
                          Der letzte Eintrag ist zu spät. Ändern Sie die Zeit
                          des letzten Eintrags, um einen Neuen anzulegen.
                        </Tooltip>
                      )}>
                      <MaterialIcon
                        icon="info"
                        verticalAlignment="middle"
                        size="12px"
                      />
                    </OverlayTrigger>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {props.timingAndDosage.dosages.map((dosageObject, index) => (
                <tr key={'dosage-row-' + index + '-interval-' + props.index}>
                  <td className={'position-relative'}>
                    <Select
                      className={'dosage-select'}
                      classNamePrefix={'dosage-select-' + index}
                      options={props.dosageOptions}
                      value={dosageObject.dose}
                      onChange={val => {
                        OnInsertedDosageChanged(
                          val.value,
                          val.label,
                          index,
                          props,
                          setFieldValue
                        )
                      }}
                    />
                  </td>
                  {(props.standardMedication?.form?.text === 'Tropfen' ||
                    props.standardMedication?.form?.text === 'Pflaster' ||
                    props.standardMedication?.form?.text === 'Pen') && (
                    <td>
                      <input
                        className="form-control"
                        type="number"
                        value={dosageObject.pieces || ''}
                        min={1}
                        step={1}
                        onChange={event => {
                          OnInsertedPiecesChanged(
                            event.target.valueAsNumber,
                            index,
                            props,
                            setFieldValue
                          )
                        }}
                      />
                    </td>
                  )}
                  {props.standardMedication?.form?.text !== 'Tropfen' &&
                    props.standardMedication?.form?.text !== 'Pflaster' &&
                    props.standardMedication?.form?.text !== 'Pen' &&
                    props.standardMedication?.ingredient[0]?.strength.numerator
                      .unit !== 'mg/ml' && (
                      <td>
                        <input
                          className="form-control"
                          type="number"
                          value={dosageObject.pieces || ''}
                          min={0.25}
                          step={0.25}
                          onChange={event => {
                            OnInsertedPiecesChanged(
                              event.target.valueAsNumber,
                              index,
                              props,
                              setFieldValue
                            )
                          }}
                        />
                      </td>
                    )}
                  {props.standardMedication?.ingredient[0]?.strength.numerator
                    .unit === 'mg/ml' && (
                    <td>
                      <input
                        className="form-control"
                        type="number"
                        value={
                          (dosageObject.volume ??
                            roundTo(
                              Number(
                                dosageObject.dose.value
                                  .split('/')[0]
                                  .replace(',', '.')
                              ) /
                                Number(
                                  props.package.value
                                    .split('/')[0]
                                    .replace(',', '.')
                                ),
                              3
                            )) ||
                          ''
                        }
                        min={0.05}
                        step={0.05}
                        onChange={event => {
                          OnInsertedVolumeChanged(
                            event.target.valueAsNumber,
                            index,
                            props,
                            setFieldValue
                          )
                        }}
                      />
                    </td>
                  )}
                  <td>
                    <input
                      className="form-control"
                      type="number"
                      value={dosageObject.period || ''}
                      min={1}
                      step={1}
                      onChange={event => {
                        onInsertedPeriodChanged(
                          event.target.valueAsNumber,
                          index
                        )
                      }}
                    />
                  </td>
                  {props.timingAndDosage.timing.map(time => {
                    const isSelected = dosageObject.times.includes(time)
                    return (
                      <th
                        key={
                          'time-' +
                          time +
                          '-row-' +
                          index +
                          '-interval-' +
                          props.index
                        }>
                        <Button
                          variant={isSelected ? 'primary' : 'outline-primary'}
                          data-testid={
                            'button-time-' +
                            time +
                            '-row-' +
                            index +
                            '-interval-' +
                            props.index
                          }
                          key={
                            'button-time-' +
                            time +
                            '-row-' +
                            index +
                            '-interval-' +
                            props.index
                          }
                          onClick={() => handleTimeSelected(time, index)}
                          disabled={
                            selectedTiming.includes(time) && !isSelected
                          }
                          size="sm">
                          {isSelected ? (
                            <MaterialIcon
                              icon="check_box"
                              verticalAlignment="middle"
                            />
                          ) : (
                            <MaterialIcon
                              icon="check_box_outline_blank"
                              verticalAlignment="middle"
                            />
                          )}
                        </Button>
                      </th>
                    )
                  })}
                  <td>
                    <Button
                      disabled={
                        props.timingAndDosage.timing.length ===
                        selectedTiming.length
                      }
                      data-testid={
                        'button-all-time-row-' +
                        index +
                        '-interval-' +
                        props.index
                      }
                      onClick={() => selectUnselected(index)}
                      size="sm">
                      <>
                        <MaterialIcon
                          icon="done_all"
                          verticalAlignment="middle"
                          size="14px"
                        />
                      </>
                    </Button>
                    {props.timingAndDosage.dosages.length > 1 && (
                      <Button
                        className="ml-2"
                        data-testid={
                          'button-delete-row-' +
                          index +
                          '-interval-' +
                          props.index
                        }
                        onClick={() => deleteDosageObject(index)}
                        size="sm">
                        <MaterialIcon
                          icon="delete"
                          verticalAlignment="middle"
                          size="14px"
                        />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={1}>
                  <Button
                    data-testid={'add-dosage-button-interval-' + props.index}
                    onClick={() => {
                      SetTimingAndDosage(
                        {
                          ...props.timingAndDosage,
                          dosages: [
                            ...props.timingAndDosage.dosages,
                            { ...emptyDosageObject },
                          ],
                        },
                        props
                      )
                    }}
                    size="sm">
                    <MaterialIcon
                      icon="add"
                      verticalAlignment="middle"
                      size="14px"
                    />{' '}
                    Neue Dosierung hinzufügen
                  </Button>
                </td>
              </tr>
            </tfoot>
          </Table>
        </div>
      )}
      {props.timingAndDosage?.selectedTiming?.value === 'prn' && (
        <DosageOnDemandSelector {...props} />
      )}
    </>
  )
}
