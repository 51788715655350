import { Component } from 'react'
import { debounce } from '../../../utils/Utils'
import { MinimalToBeDraft } from '../../../types/Draft'
import { DraftKind } from '../../../types/DraftKind'
import { AutosaveService } from '../../../services/AutosaveService'
import { diff } from 'deep-diff'
import { isEqualIgnore } from '../../../utils/ObjectUtils'
import { cloneDeep, isEqual } from 'lodash'

interface Props<T> {
  value: T
  onSetDraft: (val: T) => void
  onUpdate?: (val: T) => void
  onDelete?: (val: T) => void
  onRefreshAfterFinish?: () => void

  debouncing?: number
  initialWait?: number

  active?: boolean

  flowBeAt?: FlowStep // at start

  parent?: Component
  draftkind: DraftKind

  modifierBeforeSending?: (any?) => any
  modifierBeforeComparison?: (any?) => any

  editedMask?: Record<string, boolean | Record<string, boolean>>
  isSavedNow: boolean
}

export enum FlowStep {
  quiet,
  save,
  edit,
  finish_delete,
  finish_save,
  finished_yet,
  cancel,
  cancel_delete,
}

interface State<T> {
  flowState: FlowStep

  prevValue: T
  unmodified: T
  originalDraft: T

  tempId: string
  yetLookedForDraft: boolean
}

class Autosave<T extends MinimalToBeDraft> extends Component<
  Props<T>,
  State<T>
> {
  finishModes = [
    FlowStep.finish_delete,
    FlowStep.finished_yet,
    FlowStep.finish_save,
    FlowStep.cancel,
    FlowStep.cancel_delete,
  ]

  debug = true

  state = {
    flowState: FlowStep.quiet,
    prevValue: null,
    unmodified: null,
    originalDraft: null,
    tempId: null,
    autoTrigger: false,
    yetLookedForDraft: false,
  }

  _ismounted = true
  initialWaitTimeot: ReturnType<typeof setTimeout> = null
  modifierBeforeComparison?: (any) => any
  modifierBeforeSending?: (any) => any

  fieldWhiteList: string[]
  isEqualIgnoreSomeFields = (
    a: Record<string, any>,
    b: Record<string, any>
  ): boolean => isEqualIgnore(a, b, ['tempId'], this.fieldWhiteList)

  lookOnlyAtSomeFields = (
    mask: Record<string, boolean | Record<string, boolean>>
  ): string[] => {
    if (!mask) return null
    return [
      ...(Object.entries(mask)
        .filter(([_, edited]) => edited)
        .map(([field, _]) => field) ?? []),
    ]
  }

  findDraft = (value: T): Promise<T> => {
    const autosaveService = new AutosaveService<T>()
    return autosaveService.findValueById(value, this.props.draftkind)
  }

  autosaveSave = async (value: T): Promise<T> => {
    const autosaveService = new AutosaveService<T>()
    let res = null
    if (!value.tempId || !this.state.tempId)
      res = await autosaveService.postHavingIdAttribute(
        this.modifierBeforeSending(value),
        this.props.draftkind,
        this.state.tempId
      )
    else
      res = await autosaveService.putHavingIdAttribute(
        this.modifierBeforeSending(value),
        this.props.draftkind,
        this.state.tempId ?? value.tempId
      )
    return res
  }

  autosaveUpdate = async (value: T): Promise<T> => {
    const autosaveService = new AutosaveService<T>()
    return await autosaveService.putHavingIdAttribute(
      this.modifierBeforeSending(value),
      this.props.draftkind,
      this.state.tempId
    )
  }

  autosaveDelete = async (value: T): Promise<void> => {
    const autosaveService = new AutosaveService<T>()
    const response = await autosaveService.deleteValue({
      tempId: this.state.tempId,
      ...value,
    })
    if (this.props.onDelete) this.props.onDelete(value)
    return response
  }

  loading = false

  componentDidMount(): void {
    this.modifierBeforeComparison =
      this.props.modifierBeforeComparison ?? (it => it)
    this.modifierBeforeSending = this.props.modifierBeforeSending ?? (it => it)

    if (this.props.value) {
      this.setState({
        unmodified: cloneDeep(this.props.value),
        prevValue: cloneDeep(this.props.value),
      })
      this.findDraft(this.props.value)
        .then(result => {
          const draft = result

          if (draft && this._ismounted) {
            this.props.onSetDraft(draft)
            this.setState(
              {
                flowState: FlowStep.edit,
                tempId: draft.tempId,
                originalDraft: { ...draft },
              },
              () => {
                if (this.debug) console.log('AUTOSAVE starting at edit')
                this.startUp()
              }
            )
          } else {
            if (this.debug) console.log('AUTOSAVE starting at save')
            this.setState(
              {
                flowState: FlowStep.save,
              },
              () => {
                this.startUp()
              }
            )
          }
        })
        .catch(e => {
          console.log('AUTOSAVE failed to start!', e)
        })
    }

    this.initialWaitTimeot = setTimeout(
      () => this.debounced(),
      this.props.initialWait ?? 5000
    )
  }

  componentDidUpdate(prevProps: Readonly<Props<T>>): void {
    if (!isEqual(this.props.editedMask, prevProps.editedMask))
      this.fieldWhiteList = this.lookOnlyAtSomeFields(this.props.editedMask)
  }

  componentWillUnmount(): void {
    this._ismounted = false
    if (!this.props.isSavedNow) {
      if (this.debug) console.log('Saving, because form was not submitted (?) ')
      this.flowStep(FlowStep.finish_save)
    } else {
      if (this.debug) console.log('Not saving, because form was submitted')
      if (this.state.tempId) {
        console.log('Deleting the draft ' + this.state.tempId)
        new AutosaveService<T>()
          .deleteValue({ tempId: this.state.tempId, ...this.props.value })
          .catch(e => console.error(e))
          .then(() => console.log('Deleting the draft'))
      }
    }
  }

  startUp(): void {
    if (this._ismounted) {
      this.setState({ yetLookedForDraft: true })
      if (this.state.originalDraft) {
        this.props.onSetDraft(this.state.originalDraft)
      }
    }
  }

  finishFlow = (): void => {
    this.flowStep()
  }

  debounced = debounce(this.props.debouncing ?? 2000, () => {
    if (this._ismounted) {
      this.flowStep()
      this.setState({ prevValue: { ...this.props.value } })

      this.debounced()
    }
  })

  flowStep = async (state?: FlowStep): Promise<void> => {
    if (this.props.active === undefined || this.props.active) {
      const step = state ?? this.state.flowState

      if (this.props.isSavedNow) {
        if (this.finishModes.includes(this.state.flowState)) {
          if (this.debug)
            console.log(
              'AUTOSAVE resetting mode to ' + FlowStep[this.props.flowBeAt],
              step,
              this.props.value,
              this.finishModes,
              this.state.flowState
            )
          this.setState({ flowState: this.props.flowBeAt })
        }
      }

      if (!this.state.yetLookedForDraft) {
        this.setState({ unmodified: { ...this.props.value } })
      }

      const equalsPrev = this.isEqualIgnoreSomeFields(
        this.modifierBeforeComparison(this.props.value),
        this.modifierBeforeComparison(this.state.prevValue)
      )
      const equalsOriginal = this.isEqualIgnoreSomeFields(
        this.modifierBeforeComparison(this.props.value),
        this.modifierBeforeComparison(this.state.originalDraft)
      )
      const equalsUnmodified = this.isEqualIgnoreSomeFields(
        this.modifierBeforeComparison(this.props.value),
        this.modifierBeforeComparison(this.state.unmodified)
      )
      this.setState({ prevValue: this.props.value })

      if (
        this.state.yetLookedForDraft &&
        this.state.prevValue &&
        !equalsUnmodified &&
        !equalsPrev &&
        !equalsOriginal
      ) {
        if (this.debug && ![FlowStep.quiet].includes(this.state.flowState))
          console.log('Autosave at state: ', {
            flowState: FlowStep[state ?? this.state.flowState],
            state: this.state,
            props: this.props,
            diffPrev: diff(this.props.value, this.state.prevValue),
            diffOriginal: diff(this.props.value, this.state.unmodified),
            diffOriginalDraft: diff(this.props.value, this.state.originalDraft),
            equalsUnmodified,
            equalsPrev,
            equalsOriginal,
          })

        switch (step) {
          case FlowStep.save: {
            this.setState({ flowState: FlowStep.edit })
            const modifiedValue = cloneDeep(this.props.value)

            const res = await this.autosaveSave(modifiedValue)
            if (res) {
              this.props.onSetDraft(res)

              this.setState({ tempId: res.tempId })
            } else {
              console.error(
                'AUTOSAVE received null, when saving a draft',
                modifiedValue
              )
              this.setState({ flowState: FlowStep.save })
            }
            break
          }

          case FlowStep.edit: {
            const value = this.props.value
            value.tempId = this.state.tempId

            await this.autosaveUpdate(value)
            break
          }

          case FlowStep.finish_delete: {
            await this.autosaveDelete({
              ...this.props.value,
              tempId: this.state.tempId,
            })
            this.resetAfterFinish()
            break
          }

          case FlowStep.finished_yet: {
            this.resetAfterFinish()
            break
          }

          case FlowStep.cancel: {
            await this.autosaveUpdate({
              ...this.state.unmodified,
              id: this.props.value.id,
            })
            this.resetAfterFinish()

            if (this.state.originalDraft) {
              await this.autosaveUpdate(this.state.originalDraft)
            } else {
              await this.autosaveDelete({
                ...this.props.value,
                tempId: this.state.tempId,
              })
            }

            break
          }

          case FlowStep.cancel_delete: {
            await this.autosaveUpdate({
              ...this.state.unmodified,
              id: this.props.value.id,
            })
            await this.autosaveDelete({
              ...this.props.value,
              tempId: this.state.tempId,
            })
            this.resetAfterFinish()
            break
          }

          case FlowStep.finish_save: {
            if (!equalsPrev) await this.autosaveSave(this.props.value)
            this.resetAfterFinish()
            break
          }

          case FlowStep.quiet:
          default: {
            break
          }
        }
      }
    }
  }

  private resetAfterFinish = () => {
    this.setState(
      {
        prevValue: null,
        flowState: FlowStep.quiet,
        tempId: undefined,
      },
      () => {
        if (this.props.onRefreshAfterFinish) this.props.onRefreshAfterFinish()
      }
    )
  }

  render(): null {
    return null
  }
}

export default Autosave
