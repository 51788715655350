export enum MedicalRoundSignStatus {
  /** unsigned */
  UNSIGNED = 'UNSIGNED',
  /** signed, allowed to sign again */
  ALLOWED = 'ALLOWED',
  /** unsigned, allowed to sign WITH CAUTION */
  WITH_CAUTION = 'WITH_CAUTION',
  /** signed, allowed to sign again WITH CAUTION */
  SIGNED_WITH_CAUTION = 'SIGNED_WITH_CAUTION',
  FORBIDDEN = 'FORBIDDEN',
}
