import React from 'react'

export function informationFormatterPlanDefinition(
  actions: Iterable<any>
): JSX.Element {
  let duration = 0
  const includedMeds = []
  if (actions) {
    for (const action of actions) {
      const newDuration =
        action.timingTiming.repeat.offset +
        action.timingTiming.repeat.boundsDuration.value +
        1
      if (newDuration > duration) {
        duration = newDuration
      }
      const medCode = action.code.find(
        code => code.coding[0].code === 'urn:panos-backend-medication:Id'
      )
      if (medCode && !includedMeds.includes(medCode.text)) {
        includedMeds.push(medCode.text)
      }
    }
  }
  return (
    <div>
      {duration} Tage, {includedMeds.length} verschiedene Medikamente
    </div>
  )
}

export function informationFormatterCarePlan(medRequests: any[]): JSX.Element {
  return (
    <div>
      {medRequests?.length} Medikamente <br />{' '}
    </div>
  )
}
