import { AppointmentPerson } from './AppointmentPerson'
import { Patient } from './Patient'
import { Location } from './Location'
import { AppointmentCategories } from '../constants/AppointmentCategories'
import { Priority } from '../constants/Priority'

export class Appointment {
  id: string
  startingDateTime: Date
  minutesDuration?: number
  category: AppointmentCategories

  participants: AppointmentPerson[]
  status: string

  title?: string
  description: string

  links: Map<string, any>
  tempId?: string
  tempLinkIds?: Array<number>

  location?: Location
  room?: string
  patientDtos?: Patient[]
  relatedAppointment?: string
  priority: Priority
  shared: boolean
  eventLinkIdentifier?: string
}

export class AppointmentDto {
  id: string
  startingDateTime: Date
  minutesDuration: number
  category: AppointmentCategories

  participants: AppointmentPerson[]
  status: string

  title: string
  description: string

  links: Map<string, any>

  //LOCATION bleibt getrennt
  location: string
  locationName: string
  room?: string

  patientDtos: Patient[]

  relatedAppointment?: string
  priority: Priority
  shared: boolean
  eventLinkIdentifier?: string
}
