import axios, { AxiosResponse } from 'axios'
import { API_MAD } from '../config/Paths'
import { getToken } from './Keycloak'
import { MedicationTableRow } from '../utils/MedicationUtils'

export function getAllMedicationActivityDefinition(
  triggerCache: string,
  listVariant: string
): Promise<AxiosResponse<MedicationTableRow[]>> {
  const url = new URL(API_MAD)
  /** default resetCacheTrigger set in backend is false */
  url.searchParams.append('resetCache', triggerCache)
  url.searchParams.append('listVariant', listVariant)
  return axios.get(url.toString(), {
    headers: { Authorization: `Bearer ` + getToken() },
  })
}

export function saveMedicationActivityDefinition(
  medicationActivityDefinition: Record<string, unknown>,
  listVariant?: string
): Promise<AxiosResponse<unknown>> {
  if (
    medicationActivityDefinition.activityDefinitionId ||
    medicationActivityDefinition.medicationId
  ) {
    const url = new URL(API_MAD + '/update')
    url.searchParams.append('listVariant', listVariant)
    return axios.post(url.toString(), medicationActivityDefinition, {
      headers: {
        Authorization: `Bearer ` + getToken(),
        'Content-Type': 'application/json',
      },
    })
  } else {
    const url = new URL(API_MAD + '/create')
    url.searchParams.append('listVariant', listVariant)
    return axios.post(url.toString(), medicationActivityDefinition, {
      headers: {
        Authorization: `Bearer ` + getToken(),
        'Content-Type': 'application/json',
      },
    })
  }
}

export function importMedicationPlans(
  file: string | Blob,
  listVariant: string
): Promise<AxiosResponse<boolean>> {
  const url = new URL(API_MAD + '/import')
  url.searchParams.append('listVariant', listVariant)
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(url.toString(), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      accept: '*/*',
      Authorization: `Bearer ` + getToken(),
    },
  })
}

export function getMedicationListProcessingProgress(): Promise<
  AxiosResponse<string | number>
> {
  const url = new URL(API_MAD + '/progress')
  return axios.get(url.toString(), {
    headers: { Authorization: `Bearer ` + getToken() },
  })
}

export function resetAllMedicationAndActivityDefinitions(
  listVariant: string
): Promise<AxiosResponse<MedicationTableRow[]>> {
  const url = new URL(API_MAD + '/reset')
  url.searchParams.append('listVariant', listVariant)

  return axios.delete(url.toString(), {
    headers: { Authorization: `Bearer ` + getToken() },
  })
}

export const searchMedication = async (
  searchParam: string
): Promise<AxiosResponse<any>> => {
  const url = new URL(`${API_MAD}/searchMedications`)
  url.searchParams.append('search', searchParam)

  return axios.get(url.toString(), {
    headers: { Authorization: `Bearer ` + getToken() },
  })
}

export const getMedicationActitivityDefinitionById = async (
  id: string
): Promise<AxiosResponse<any>> => {
  const url = new URL(`${API_MAD}/${id}`)
  return axios.get(url.toString(), {
    headers: { Authorization: `Bearer ` + getToken() },
  })
}
