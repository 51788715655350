import React, { Component } from 'react'
import { AppointmentFilterParams } from '../../../types/AppointmentFilterParams'
import { SelectorList } from '../../SelectorList'
import { Practitioner } from '../../../types/Practitioner'
import { Patient } from '../../../types/Patient'
import { AuthStore } from '../../../infrastructure/AuthProvider'
import { AppointmentStatus } from '../../../constants/AppointmentStatus'
import PatientSelect from '../../UI/Forms/PatientSelect/PatientSelect'
import PractitionerSelect from '../../UI/Forms/PractitionerSelect/PractitionerSelect'
import { Location } from '../../../types/Location'
import LocationSelect from '../../UI/Forms/LocationSelect/LocationSelect'
import OrganizationSelect from '../../UI/Forms/OrganizationSelect/OrganizationSelect'
import { Organization } from '../../../types/Organization'
import { UserPermission } from '../../../constants/UserPermission'
import { PanosInstitution } from '../../../constants/PanosInstitutions'
import QuickFilterButtonGroup from '../../UI/QuickFilterButtonGroup/QuickFilterButtonGroup'

enum FilterOptions {
  ALL = 'Alle',
  OWN = 'Eigene',
  PATIENT = 'Patient',
  PRACTITIONER = 'Personal',
  LOCATION = 'Veranstaltungsort',
  ORGANIZATION = 'Organisation',
  NONE = 'Keine',
}

enum FilterOptionsRestricted {
  OWN = 'Eigene',
  NONE = 'Keine',
}

interface AppointmentFilterProps {
  status?: AppointmentStatus
  onSubmit: (filter: AppointmentFilterParams) => void
  onQuickFilterChange: (quickFilter: PanosInstitution[]) => void
}

interface AppointmentFilterState {
  selectedFilterOption: FilterOptions | FilterOptionsRestricted
  selectedValue: string | Patient | Practitioner
  includeOwn: boolean
  additionalQuickfilters: PanosInstitution[]
}

class AppointmentFilter extends Component<
  AppointmentFilterProps,
  AppointmentFilterState
> {
  state = {
    selectedFilterOption: FilterOptions.OWN,
    selectedValue: null,
    includeOwn: true,
    additionalQuickfilters: [],
  }
  static contextType = AuthStore
  requiresNoValue = [FilterOptions.ALL, FilterOptions.OWN, FilterOptions.NONE]

  componentDidUpdate(
    prevProps: AppointmentFilterProps,
    prevState: AppointmentFilterState
  ): void {
    if (prevProps.status !== this.props.status) {
      this.setState({
        selectedFilterOption: FilterOptions.OWN,
        selectedValue: null,
      })
    }
    if (
      prevState.additionalQuickfilters !== this.state.additionalQuickfilters
    ) {
      this.props.onQuickFilterChange(this.state.additionalQuickfilters)
    }
  }

  onSelectFilterValue = (
    value: string | Patient | Practitioner | Location | Organization
  ): void => {
    this.setState({ selectedValue: value })
  }

  onSelectFilterOption = (filterOption: FilterOptions | string): void => {
    switch (filterOption) {
      case FilterOptions.OWN:
        this.setState({
          selectedFilterOption: filterOption,
          selectedValue: this.context.auth.panosId,
          includeOwn: true,
        })
        break
      case FilterOptions.ALL:
        this.setState({
          selectedFilterOption: filterOption,
          selectedValue: null,
        })
        break
      default:
        this.setState({
          selectedFilterOption: filterOption as FilterOptions,
          selectedValue: null,
        })
        break
    }
  }

  selectRoleSpecificFilterValues = (): [
    string,
    FilterOptions | FilterOptionsRestricted
  ][] => {
    const { hasPermission } = this.context.handlers

    if (hasPermission(UserPermission.APPOINTMENTS_FILTER_ALL)) {
      return Object.entries(FilterOptions)
    } else {
      return Object.entries(FilterOptionsRestricted)
    }
  }

  onSubmit = (): void => {
    const { selectedFilterOption, selectedValue } = this.state
    const filterParams = new AppointmentFilterParams()
    if (this.props.status) filterParams.status = this.props.status

    switch (selectedFilterOption) {
      case FilterOptions.PATIENT:
        filterParams.patientId = selectedValue?.id
        filterParams.includeOwn = this.state.includeOwn
        break
      case FilterOptions.PRACTITIONER:
        filterParams.practitionerId = selectedValue?.id
        filterParams.includeOwn = this.state.includeOwn
        break
      case FilterOptions.OWN:
        filterParams.userId = this.context.auth.panosId
        break
      case FilterOptions.ORGANIZATION:
        filterParams.organization = selectedValue
        filterParams.includeOwn = this.state.includeOwn
        break
      case FilterOptions.LOCATION:
        filterParams.location = selectedValue
        filterParams.includeOwn = this.state.includeOwn
        break
      case FilterOptions.NONE:
        filterParams.patientId = '-1'
        filterParams.includeOwn = false
        break
      default:
        break
    }

    this.props.onSubmit(filterParams)
  }

  renderFilterValueSelectors = (): JSX.Element => {
    const { selectedFilterOption } = this.state
    switch (selectedFilterOption) {
      case FilterOptions.PATIENT:
        return (
          <div className="form-group">
            <PatientSelect
              value={this.state.selectedValue}
              onChange={this.onSelectFilterValue}
              icon="search"
              name={'patient'}
            />
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="checkboxIncludeOwn"
                onChange={e => this.setState({ includeOwn: e.target.checked })}
                checked={this.state.includeOwn}
              />
              <label className="form-check-label" htmlFor="checkboxIncludeOwn">
                Trotzdem alle eigenen Termine anzeigen
              </label>
            </div>
          </div>
        )
      case FilterOptions.PRACTITIONER:
        return (
          <div className="form-group">
            <PractitionerSelect
              value={this.state.selectedValue}
              onChange={this.onSelectFilterValue}
              icon="search"
              onlyRegistered
            />
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="checkboxIncludeOwn"
                onChange={e => this.setState({ includeOwn: e.target.checked })}
                checked={this.state.includeOwn}
              />
              <label className="form-check-label" htmlFor="checkboxIncludeOwn">
                Trotzdem alle eigenen Termine anzeigen
              </label>
            </div>
          </div>
        )
      case FilterOptions.LOCATION:
        return (
          <div className="form-group">
            <LocationSelect
              name={'location'}
              value={this.state.selectedValue}
              onChange={this.onSelectFilterValue}
            />

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="checkboxIncludeOwn"
                onChange={e => this.setState({ includeOwn: e.target.checked })}
                checked={this.state.includeOwn}
              />
              <label className="form-check-label" htmlFor="checkboxIncludeOwn">
                Trotzdem alle eigenen Termine anzeigen
              </label>
            </div>
          </div>
        )

      case FilterOptions.ORGANIZATION:
        return (
          <div className="form-group">
            <OrganizationSelect
              value={this.state.selectedValue}
              onChange={this.onSelectFilterValue}
            />
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="checkboxIncludeOwn"
                onChange={e => this.setState({ includeOwn: e.target.checked })}
                checked={this.state.includeOwn}
              />
              <label className="form-check-label" htmlFor="checkboxIncludeOwn">
                Trotzdem alle eigenen Termine anzeigen
              </label>
            </div>
          </div>
        )
    }
    return null
  }

  render(): JSX.Element {
    return (
      <>
        <div
          className="form-group"
          style={{ position: 'relative', zIndex: 100 }}>
          <label>Filter:</label>
          <div className="form-group">
            <SelectorList
              values={this.selectRoleSpecificFilterValues()}
              get={val => ({ value: val[1], label: val[1] })}
              onChange={this.onSelectFilterOption}
              value={this.state.selectedFilterOption}
            />
          </div>
        </div>

        {this.renderFilterValueSelectors()}

        <div className="form-group">
          <button
            className="btn btn-primary mr-3"
            onClick={this.onSubmit}
            disabled={
              !this.requiresNoValue.includes(this.state.selectedFilterOption) &&
              !this.state.selectedValue
            }>
            Filtern
          </button>
          <QuickFilterButtonGroup
            oneLine={true}
            onChange={newState =>
              this.setState({
                additionalQuickfilters: newState,
              })
            }
          />
        </div>
      </>
    )
  }
}

export default AppointmentFilter
