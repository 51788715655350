import React from 'react'
import { REQUIRED_MESSAGE } from '../../../../utils/ValidationUtils'

interface FormErrorMessageProps {
  error: string
}
const FormErrorMessage: React.FC<FormErrorMessageProps> = ({
  error,
}: FormErrorMessageProps) => {
  if (error && error !== REQUIRED_MESSAGE)
    return <div className="invalid-tooltip">{error}</div>
  else return null
}

export default FormErrorMessage
