import React from 'react'
import './Loader.scss'

export interface LoaderProps {
  msg?: string
  size?: number
  inline?: boolean
}

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const divStyle = {
    width: props.size ? props.size + 'px' : '50px',
    height: props.size ? props.size + 'px' : '50px',
    borderWidth: props.size ? props.size * 0.1 + 'px' : '5px',
  }
  return (
    <div
      className={`d-inline-flex flex-column align-items-center ${
        props.inline ? 'mr-1' : 'w-100'
      }`}>
      <div className="Loader" style={divStyle} />
      {!props.msg || <span>{props.msg}</span>}
    </div>
  )
}

export default Loader
