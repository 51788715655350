export enum MedicationReason {
  ANAMNESE = 'Anamnese',
  ORDER = 'nach Anordnung',
  DRAFT = 'Entwurf',
}

export interface MedicationRequestUpdateWithReason {
  medicationRequests: MedicationRequest[]
  reason: string
}
