import React, { useContext, useRef } from 'react'
import { ICustomExtraComponent } from '../Forms/FormInterfaces'
import { FormDynamicComponentProps } from './FormDynamicComponent'
import { DraftKind } from '../../types/DraftKind'
import FormDynamicComponentList from './FormDynamicComponentList'
import { EventStore } from '../../infrastructure/EventProviderInterfaces'
import { RedCapSaveStatus } from '../../constants/RedCapSaveMethods'
import { PrevValues } from './PrevValues'

interface Props {
  components: FormDynamicComponentProps[]
  initialValues: Record<string, unknown>
  formName: string
  defaultFooter?: boolean
  customFooter?: ICustomExtraComponent
  children?: ICustomExtraComponent
  triggerSubmit?: boolean
  patientId?: string
  draftKind?: DraftKind
  readOnlyMode?: boolean
}

const FormDynamicForm: React.FC<Props> = ({
  components,
  formName,
  customFooter,
  children,
  defaultFooter,
  patientId,
  readOnlyMode,
  draftKind,
}: Props) => {
  const formRef = useRef(null)
  const context = useContext(EventStore)

  const { handleChange, setValues, values, handleSubmit } = context
  const prevValueVersions = Object.keys(context.prevValues ?? {})

  const currentPreviousValueVersion = context.currentPreviousVersion
  const setCurrentPreviousVersion = context.setCurrentPreviousVersion

  const increaseIndex = () => {
    if (
      currentPreviousValueVersion !==
      prevValueVersions[prevValueVersions.length - 1]
    )
      setCurrentPreviousVersion(
        prevValueVersions[
          prevValueVersions.findIndex(
            it => it === currentPreviousValueVersion
          ) + 1
        ]
      )
  }

  const reduceIndex = () => {
    if (
      currentPreviousValueVersion !== prevValueVersions[0] &&
      currentPreviousValueVersion
    )
      setCurrentPreviousVersion(
        prevValueVersions[
          prevValueVersions.findIndex(
            it => it === currentPreviousValueVersion
          ) - 1
        ]
      )
  }

  const getPreviousValues = () => {
    if (!context.prevValues) return null
    return context.prevValues[currentPreviousValueVersion]
  }

  const prevVersionInfo = context.prevVersions[currentPreviousValueVersion]

  const preventSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleSubmitButtonClick = () => {
    handleSubmit(true, RedCapSaveStatus.COMPLETE)
  }

  return (
    <>
      <form
        onSubmit={preventSubmit}
        noValidate={true}
        name={formName}
        ref={formRef}>
        {getPreviousValues() ? (
          <PrevValues
            currentPreviousValueVersion={currentPreviousValueVersion}
            strings={prevValueVersions}
            reduceIndex={reduceIndex}
            increaseIndex={increaseIndex}
            setValues={() => setValues(getPreviousValues())}
            prevVersionInfo={prevVersionInfo}
          />
        ) : null}
        <FormDynamicComponentList
          components={components}
          handleChange={handleChange}
          patientId={patientId}
          readOnlyMode={readOnlyMode}
        />
        {children && children(values)}
        {customFooter && customFooter(values)}
        {defaultFooter === true && (
          <button
            type={'submit'}
            onClick={handleSubmitButtonClick}
            className="btn btn-primary">
            Abschicken
          </button>
        )}
      </form>
      {/* <OnSubmitValidationError formName={formName} /> */}
    </>
  )
}

export default FormDynamicForm
