import ApiServiceBase from './ApiServiceBase'
import { API_DRAFT } from '../config/Paths'
import { Draft, MinimalDraft, MinimalToBeDraft } from '../types/Draft'
import {
  getValueTransferringTempId,
  toDraftDto,
  toDraftHavingID,
} from '../utils/DraftUtils'
import { NotificationManager } from 'react-notifications'
import { APIResult } from '../constants/APIResult'
import { DraftKind } from '../types/DraftKind'
import { DraftIdInfo } from '../types/DraftIdInfo'
import { ApiError } from '../types/ApiError'
import { ApiResponse } from '../types/ApiResponse'

export class AutosaveService<
  T extends MinimalToBeDraft
> extends ApiServiceBase {
  static unpackResult = <R>(response: ApiResponse<ApiError | any>): R => {
    switch (true) {
      case response.isAborted: {
        console.warn('aborted getting draft from request')
        return null
      }
      case response.Result === APIResult.SUCCESS:
        if (!response.Response) {
          return null
        } else {
          return response.Response as R
        }
      case response.Result === APIResult.FAILURE: {
        console.warn('error getting draft from request', response)
        return null
      }
      default: {
        console.warn('unhandled case for unpacking the request', response)
        return null
      }
    }
  }

  private findDraftById = async (
    value: Record<string, any>,
    kind: DraftKind
  ): Promise<Draft<T>> => {
    const response = await this.get<null, MinimalDraft>(
      new URL(API_DRAFT + `/id/${kind}/${value.id}`)
    )
    return new Draft(AutosaveService.unpackResult<MinimalDraft>(response))
  }

  public findValueById = async (
    value: Record<string, any>,
    kind: DraftKind
  ): Promise<T> => {
    return getValueTransferringTempId(await this.findDraftById(value, kind))
  }

  public getAll = async (kind: DraftKind = null): Promise<Draft<T>[]> => {
    const response = await this.get<null, MinimalDraft[]>(
      new URL(API_DRAFT + (kind ? '/kind/' + kind : ''))
    )
    return (
      AutosaveService.unpackResult<MinimalDraft[]>(response) as MinimalDraft[]
    )?.map(it => new Draft(it))
  }

  public getAllIdInfosOfKind = async (
    kind: string = null
  ): Promise<DraftIdInfo[]> => {
    const response = await this.get<null, string[]>(
      new URL(API_DRAFT + (kind ? '/ids/' + kind : ''))
    )
    return AutosaveService.unpackResult<string[]>(response)?.map(
      it => new DraftIdInfo(it)
    )
  }

  public getAllValuesOfKind = async (kind: DraftKind): Promise<T[] | void> => {
    try {
      return (await this.getAll(kind)).map(it => getValueTransferringTempId(it))
    } catch (e) {
      NotificationManager.error('Entwürfe konnten nicht geladen werden')
    }
  }

  public postDraft = async (draft: Draft<T>): Promise<Draft<T>> => {
    const response = await this.post<MinimalDraft, MinimalDraft>(
      new URL(API_DRAFT),
      null,
      toDraftDto(draft)
    )
    return await new Draft(AutosaveService.unpackResult<MinimalDraft>(response))
  }

  public postHavingIdAttribute = async (
    value: T,
    kind: DraftKind,
    tempId: string
  ): Promise<T> => {
    return getValueTransferringTempId(
      await this.postDraft(toDraftHavingID(value, kind, tempId))
    )
  }
  public putHavingIdAttribute = async (
    value: T,
    kind: DraftKind,
    tempId: string
  ): Promise<T> => {
    return getValueTransferringTempId(
      await this.putDraft(toDraftHavingID(value, kind, tempId))
    )
  }

  public putDraft = async (draft: Draft<T>): Promise<Draft<T>> => {
    const response = await this._put<MinimalDraft, string>(
      new URL(API_DRAFT),
      toDraftDto(draft)
    )
    return await new Draft(AutosaveService.unpackResult<MinimalDraft>(response))
  }

  public deleteDraft = async (draft: Draft<T>): Promise<void> => {
    const response = await this.delete<Draft<T>>(
      new URL(API_DRAFT + `/tempId/${draft.tempId ?? draft.value.tempId}`)
    )

    try {
      delete draft.value.tempId
      await AutosaveService.unpackResult<string>(response)
    } catch (e) {
      // it was not yet a draft
    }
    return
  }

  public deleteValue = async (value: Record<string, any>): Promise<void> => {
    const response = await this.delete<Draft<T>>(
      new URL(API_DRAFT + `/tempId/${value.tempId}`)
    )
    await AutosaveService.unpackResult<string>(await response)
    return
  }

  public deleteValueById = async (
    value: Record<string, any>,
    kind: DraftKind
  ): Promise<string> => {
    const response = await this.delete<Draft<T>>(
      new URL(API_DRAFT + `/id/${value.id}/${kind}`)
    )
    return AutosaveService.unpackResult<string>(await response)
  }
}
