import { AUTH_STATE_UNVERIFIED, AUTH_UNVERIFIED } from '../config/Constants'
import UserService from './UserService'
import { APIResult } from '../constants/APIResult'
import IAuth from '../types/Auth'
import { User } from '../types/User'
import { UserRole } from '../constants/UserRole'
import { UserPermission } from '../constants/UserPermission'
import keycloak, { hasRole, IdTokenParsed } from './Keycloak'
import { PrivilegeService } from './PrivilegeService'
import { RoleLabel } from '../types/RoleRight'

export default class AuthService {
  state: IAuth = {
    authenticated: false,
    panosId: null,
    permissions: null,
    roleLabels: null,
    connected: false,
    user: null,
  }

  connect = async (): Promise<IAuth> => {
    await new Promise<void>(resolve => {
      /* When keycloak is not available then the kc.init will not thow any error. Thats we have to do it manually here */
      setTimeout(() => {
        if (!this.state.connected) {
          console.error('AuthServer connection failed')
          resolve()
        }
      }, 15000)

      keycloak
        .init({
          onLoad: 'check-sso',
          flow: 'standard',
          // flow: 'implicit',
        })
        .then(() => {
          const token = keycloak.idTokenParsed as IdTokenParsed
          this.state.authenticated = keycloak.authenticated
          this.state.connected = true
          this.state.user = {
            id: null,
            userName: token.preferred_username,
            firstName: token.given_name,
            lastName: token.family_name,
            lanr: token.lanr || null,
          }
          this.state.keycloak = keycloak
          resolve()
        })
        .catch(() => {
          this.state.connected = true
          resolve()
        })
    })

    if (hasRole(UserRole.TREATING_PHYSICIAN_UNVERIFIED)) {
      sessionStorage.setItem(AUTH_STATE_UNVERIFIED, AUTH_UNVERIFIED)
      this.logout()
      return
    }

    const _userService = new UserService()
    //set Panos ID
    if (!this.state.panosId && this.state.authenticated) {
      await _userService.getCurrentUser().then(response => {
        if (response.Result === APIResult.SUCCESS) {
          this.state.user = {
            ...this.state.user,
            ...response.Response,
          }
          this.state.panosId = this.state.user.id
        }
      })
    }

    if (!this.state.permissions) {
      const labelResponse = await new PrivilegeService().getLabels()
      if (labelResponse.Result === APIResult.SUCCESS) {
        this.state.roleLabels = labelResponse.Response as RoleLabel[]
      }
      await _userService.getUserPermissions().then(response => {
        if (response.Result === APIResult.SUCCESS) {
          this.state.permissions = response.Response as UserPermission[]
        }
      })
    }

    // sessionStorage.removeItem(AUTH_STATE)

    return this.state
  }

  login = (options?: Keycloak.KeycloakLoginOptions): void => {
    // sessionStorage.setItem(AUTH_STATE, AUTH_LOGGING_IN)
    keycloak.login(options)
  }

  logout = (): void => {
    keycloak.logout()
  }

  reLogin = (): void => {
    keycloak.logout({ redirectUri: keycloak.createLoginUrl() })
  }

  register = (): void => {
    keycloak.register()
  }

  updateUser = (callback: (user: User) => void): User => {
    const _userService = new UserService()
    _userService.getCurrentUser().then(response => {
      if (response.Result === APIResult.SUCCESS) {
        this.state.user = response.Response as User
        callback(this.state.user)
      }
    })
    return this.state.user
  }
}
