import * as Yup from 'yup'
import { FieldFunctionValidation } from '../components/FieldFunctionComponents/FieldFunctionInterfaces'
import IAuth from '../types/Auth'
import { FormDynamicComponentProps } from '../components/FormDynamic/FormDynamicComponent'
import { _Array, parseUnknownToDate } from './Utils'
import { FieldTypes } from '../constants/FieldTypes'

export interface FieldValidationProps extends FieldFunctionValidation {
  auth: IAuth
}

export const REQUIRED_MESSAGE = 'REQUIRED'

/**
 * Validates the given string_number as number.
 *
 * Only for YUP validation!
 * There is already a preflightcheck if its a number,
 * thats why its not checked here
 */
const checkNumber = (
  num: number,
  min?: string,
  max?: string,
  step?: string
) => {
  if (num === null) return true
  let result = true
  if (min && !isNaN(+min)) result = result && num >= +min

  if (max && !isNaN(+max)) result = result && num <= +max

  if (step && !isNaN(+step) && step != null)
    result = result && _Array.range(+min, +max, +step).includes(num)

  return result
}

export const KIND_OF_VALIDATION = {
  regex: (component: FormDynamicComponentProps): Yup.StringSchema => {
    const regExp = new RegExp('^' + component.annotation?.regex + '$')
    return Yup.string()
      .nullable()
      .test(
        `regex`,
        `Erlaubtes Eingabemuster ${component.annotation.regex}`,
        str => {
          return str?.match(regExp) !== null
        }
      )
  },
  postalcode_germany: (
    component: FormDynamicComponentProps
  ): Yup.StringSchema => {
    const regExp = new RegExp('^' + component.annotation?.regex + '$')
    let yupObject = Yup.string().nullable()
    if (component.required) {
      yupObject = COLLECTION_VALIDATION.required(
        yupObject,
        component
      ) as Yup.StringSchema
    }
    return yupObject.test(`plz`, `Postleitzahl mit 5 Ziffern`, str => {
      return str?.match(regExp) !== null
    })
  },
  integer: (component: FormDynamicComponentProps): Yup.NumberSchema => {
    let yupObject = Yup.number()
      .typeError('Muss eine Zahl sein')
      .nullable()
      .transform((value, originalValue) =>
        String(originalValue) === '' ? null : value
      )

    if (component.required) {
      yupObject = COLLECTION_VALIDATION.required(
        yupObject,
        component
      ) as Yup.NumberSchema
    }
    return yupObject
      .integer('Muss eine ganze Zahl sein')
      .nullable()
      .test(
        component.name,
        `Ungültige Eingabe im Intervall ${
          component.validation_min ? 'von ' + component.validation_min : ''
        } ${
          component.validation_max ? ' bis ' + component.validation_max : ''
        } ${
          component.annotation.step
            ? 'in Schritten von ' + component.annotation.step
            : ''
        }`,
        num =>
          checkNumber(
            num,
            component.validation_min,
            component.validation_max,
            component.annotation?.step
          )
      )
  },
  number: (component: FormDynamicComponentProps): Yup.NumberSchema => {
    let yupObject = Yup.number()
      .typeError('Muss eine Zahl sein')
      .nullable()
      .transform((value, originalValue) =>
        String(originalValue) === '' ? null : value
      )
    if (component.required) {
      yupObject = COLLECTION_VALIDATION.required(
        yupObject,
        component
      ) as Yup.NumberSchema
    }
    return yupObject.test(
      component.name,
      `Ungültige Eingabe im Intervall ${
        +component.validation_min ? 'von ' + component.validation_min : ''
      } bis ${
        +component.validation_max ? 'bis ' + component.validation_max : ''
      } ${
        +component.annotation?.step
          ? 'in Schritten von ' + component.annotation.step
          : ''
      } `,
      num =>
        checkNumber(
          num,
          component.validation_min,
          component.validation_max,
          component.annotation?.step
        )
    )
  },
  date_dmy: (component: FormDynamicComponentProps): Yup.DateSchema => {
    let yupObject = Yup.date()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable()
    try {
      if (component.required) {
        yupObject = COLLECTION_VALIDATION.required(
          yupObject,
          component
        ) as Yup.DateSchema
      }
      if (component.validation_min) {
        yupObject = yupObject.min(
          parseUnknownToDate(component.validation_min),
          `Bitte Datum nach ${component.validation_min} eingeben`
        )
      }
      if (component.validation_max) {
        yupObject = yupObject.max(
          parseUnknownToDate(component.validation_max),
          `Bitte Datum vor ${component.validation_max} eingeben`
        )
      }
    } catch (e) {
      console.log(e)
    }
    return yupObject
  },
  checkbox: (component: FormDynamicComponentProps): Yup.ObjectSchema<any> => {
    const shape = {}
    component.options.forEach(
      option => (shape[option.value] = Yup.boolean().nullable())
    )
    return Yup.object()
      .shape(shape)
      .test(
        component.name,
        null, // see at last return, it returns an error with the field name to show the error at
        (checkboxGroupValue: Record<string, unknown>) => {
          // if checkboxGroupValue is null, then the cast from values to shape is probably wrong.
          if (Object.values(checkboxGroupValue).every(value => value !== true))
            return new Yup.ValidationError(
              'Mindestens eins muss ausgewählt werden',
              '?',
              // this must be the name to show errors thrown here at the component named like this
              component.name
            )
          return true
        }
      )
  },
  none: (component: FormDynamicComponentProps): Yup.AnySchema => {
    let yupObject = Yup.mixed().nullable()
    if (component.required) {
      yupObject = COLLECTION_VALIDATION.required(
        yupObject,
        component
      ) as Yup.AnySchema
    }

    return yupObject
  },
}

export const COLLECTION_VALIDATION = {
  radioMatrix: (
    component: FormDynamicComponentProps
  ): ((
    yupObject: Yup.AnySchema | Yup.AnyObjectSchema
  ) => Yup.AnySchema | Yup.AnyObjectSchema) => {
    return yupObject => {
      return yupObject.test(
        component.name,
        null,
        (val: Record<string, unknown>) => {
          let value
          const column = {} // counts if a column is already selected
          for (const row of component.subComponents) {
            if (val[row.name]) {
              value = val[row.name] as string
              if (column[value])
                return new Yup.ValidationError(
                  'Eine Spalte ist mehrmals ausgewählt!',
                  null,
                  component.name
                )
              column[value] = true
            }
          }
        }
      )
    }
  },
  required: (
    yupObject:
      | Yup.StringSchema
      | Yup.DateSchema
      | Yup.NumberSchema
      | Yup.AnySchema
      | Yup.AnyObjectSchema,
    component: FormDynamicComponentProps
  ):
    | Yup.StringSchema
    | Yup.DateSchema
    | Yup.NumberSchema
    | Yup.AnySchema
    | Yup.AnyObjectSchema => {
    let message: string
    if (typeof component.label === 'string') {
      message = 'Feld ' + component.label + ' ist erforderlich'
    } else {
      message = REQUIRED_MESSAGE
    }
    return yupObject
      .test('Pflichtfeld', message, val => val !== null && val !== undefined)
      .test(`Pflichtfeld`, message, str => {
        return str !== ''
      })
  },
}

export const createDynamicYupValidation = (
  components: FormDynamicComponentProps[],
  ignoreRequired = true
): Yup.ObjectSchema<any> => {
  /* The validation object consists of an record type with:
    {...[fieldName]: Yup-validation}
    And the whole object, that is itself a Yup-Object is applied to other
    Yup-testing functions, here called "collectionValidation Functions"

    Every part of that is collected in this function and then plugged into another
   */

  const validationFunctions = [] // all tests with local scope
  const collectionValidationFunctions = [] // all tests with global scope

  /*
    add all components which are contained in another component (Matrix has checkboxes or radios)
  */
  const listOfComponents = withSubcomponents(components)

  listOfComponents.forEach(component => {
    if (
      ignoreRequired ||
      component.annotation?.hidden ||
      component.annotation?.hidden_FORM
    ) {
      component = { ...component, required: false }
    }

    try {
      const kind =
        component.validation !== '' &&
        component.validation !== null &&
        component.validation !== undefined
          ? component.validation
          : component.annotation && component.annotation.regex
          ? 'regex'
          : null
      let yupObject = null
      if (component.field_type === FieldTypes.CHECKBOX && component.required) {
        yupObject = KIND_OF_VALIDATION.checkbox(component)
      }

      if (kind) {
        if (KIND_OF_VALIDATION[kind]) {
          yupObject = KIND_OF_VALIDATION[kind](component)
        }
      }
      if (!yupObject) {
        switch (component.field_type) {
          case FieldTypes.RADIO_MATRIX:
            collectionValidationFunctions.push(
              COLLECTION_VALIDATION.radioMatrix(component)
            )
            yupObject = KIND_OF_VALIDATION.none(component)
            break
          default:
            yupObject = KIND_OF_VALIDATION.none(component)
        }
      }
      validationFunctions.push([component.name, yupObject])
    } catch (e) {
      console.error('failed to make yup schema for ', component, e)
    }
  })

  const validationObject = {}
  validationFunctions.forEach(([fieldName, yupObject]) => {
    if (yupObject) {
      validationObject[fieldName] = yupObject
    }
  })

  let collectionObject = Yup.object().shape(validationObject)

  collectionValidationFunctions.forEach(collectionFunction => {
    collectionObject = collectionFunction(collectionObject)
  })

  return collectionObject
}

/**
 * Adds all contained compenents of another component to the error checks
 */
function withSubcomponents(
  components: FormDynamicComponentProps[]
): FormDynamicComponentProps[] {
  if (!components) return null
  const listOfComponents = []
  components.forEach(component => {
    switch (component.field_type) {
      case FieldTypes.CHECKBOX_MATRIX: // same as radioMatrix without ranking
      case FieldTypes.RADIO_MATRIX: {
        if (!component.subComponents) return
        component.subComponents.forEach(row => {
          listOfComponents.push(row)
        })
        if (component.matrixRanking) listOfComponents.push(component)
        break
      }
      default:
        listOfComponents.push(component)
    }
  })
  return listOfComponents
}
