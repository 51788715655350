/**
 * Options for unit of strength of an ingredient
 */
export const UnitOptions = [
  {
    value: 'mg',
    label: 'mg',
  },
  {
    value: 'ml',
    label: 'ml',
  },
  {
    value: 'g',
    label: 'g',
  },
  {
    value: 'mg/ml',
    label: 'mg/ml',
  },
  {
    value: 'mg/h',
    label: 'mg/h',
  },
  {
    value: 'mg/24h',
    label: 'mg/24h',
  },
  {
    value: 'ml/h',
    label: 'ml/h',
  },
  {
    value: 'μg',
    label: 'μg',
  },
  {
    value: 'E',
    label: 'E',
  },
  {
    value: 'E/l',
    label: 'E/l',
  },
  {
    value: 'E/ml',
    label: 'E/ml',
  },
  {
    value: 'IE',
    label: 'IE',
  },
  {
    value: 'IE/g',
    label: 'IE/g',
  },
  {
    value: 'IE/ml',
    label: 'IE/ml',
  },
  {
    value: 'mg/d',
    label: 'mg/d',
  },
  {
    value: 'mg/g',
    label: 'mg/g',
  },
  {
    value: 'mg/qcm',
    label: 'mg/qcm',
  },
  {
    value: 'Mio E/ml',
    label: 'Mio E/ml',
  },
  {
    value: 'Mio IE',
    label: 'Mio IE',
  },
  {
    value: 'Mio IE/ml',
    label: 'Mio IE/ml',
  },
  {
    value: 'ml/l',
    label: 'ml/l',
  },
  {
    value: 'mmol/ml',
    label: 'mmol/ml',
  },
  {
    value: 'Vol-%/ml',
    label: 'Vol-%/ml',
  },
]
