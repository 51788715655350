import React from 'react'
import { RouteChildrenProps, withRouter } from 'react-router-dom'
import SidebarButton from './SidebarButton'
import SidebarDropdown from './SidebarDropdown'
import { NotificationTypes } from '../../../config/Constants'
import { UserPermission } from '../../../constants/UserPermission'

export interface SidebarButtonData {
  children?: SidebarButtonData[]
  description: string
  icon?: string
  colorTheme?: string
  path?: string | Record<string, unknown>
  permissision?: UserPermission
  additionalActivePaths?: [string | Record<string, unknown>]
  notification?: NotificationTypes
  notifications?: Record<NotificationTypes, number>
}

interface SidebarEntryProps extends RouteChildrenProps {
  sidebarItem: SidebarButtonData
  notifications?: Record<NotificationTypes, number>
}

const SidebarEntry: React.FC<SidebarEntryProps> = ({
  location,
  sidebarItem,
  notifications,
}): JSX.Element => {
  const currentPath = location.pathname

  let active = currentPath.includes(sidebarItem.path?.toString())
  if (
    sidebarItem.additionalActivePaths !== undefined &&
    sidebarItem.additionalActivePaths.length > 0
  ) {
    for (let i = 0; i < sidebarItem.additionalActivePaths.length; i++) {
      active = active || currentPath === sidebarItem.additionalActivePaths[i]
    }
  }

  if (sidebarItem.children && sidebarItem.children.length > 0) {
    return (
      <SidebarDropdown
        sidebarItem={sidebarItem}
        active={active}
        notifications={notifications}
      />
    )
  } else {
    return (
      <SidebarButton
        active={active}
        colorTheme={sidebarItem.colorTheme}
        description={sidebarItem.description}
        path={sidebarItem.path}
        icon={sidebarItem.icon}
        notification={sidebarItem.notification}
        notifications={notifications}
      />
    )
  }
}

export default withRouter(SidebarEntry)
