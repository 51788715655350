import roundTo from 'round-to'
import { DosageAndTimingSelectorProps } from './DosageAndTimingSelector'
import { ISetFieldValue } from '../../Forms/FormInterfaces'

export function OnInsertedPiecesChanged(
  value: number,
  index: number,
  props: DosageAndTimingSelectorProps,
  setFieldValue: ISetFieldValue = null
): {
  dose: {
    value?: string
    label?: string
  }
  pieces?: number
  volume?: number
} {
  let volume,
    dose = undefined
  const pieces = value
  setFieldValue?.(
    `timeIntervals[${props.index}].timingAndDosage.dosages[${index}].pieces`,
    pieces
  )
  if (value) {
    let packageDosageArray: Array<string>
    let packageDosageArrayConverted: Array<number>
    let packageDosageNumber: number
    let computedDosageString = ''
    if (
      props.standardMedication?.form?.text === 'Tropfen' &&
      props.standardMedication?.ingredient[0]?.strength.numerator.unit ===
        'mg/ml'
    ) {
      volume = roundTo(value / 20, 8)
      setFieldValue?.(
        `timeIntervals[${props.index}].timingAndDosage.dosages[${index}].volume`,
        volume
      )
      if (props.package.value.includes('/')) {
        packageDosageArray = props.package.value.split('/')
        packageDosageArrayConverted = packageDosageArray.map(it =>
          Number(it.replace(',', '.'))
        )
        packageDosageArrayConverted
          .map(it => roundTo((it * value) / 20, 8))
          .forEach((it, arrayIndex) => {
            if (arrayIndex !== packageDosageArrayConverted.length - 1) {
              computedDosageString += String(it).replace('.', ',') + '/'
            } else {
              computedDosageString += String(it).replace('.', ',')
            }
          })
      } else {
        packageDosageNumber = Number(props.package.value.replace(',', '.'))
        packageDosageNumber *= value / 20
        computedDosageString = String(roundTo(packageDosageNumber, 8)).replace(
          '.',
          ','
        )
      }
      dose = {
        value: computedDosageString,
        label: computedDosageString,
      }
      setFieldValue?.(
        `timeIntervals[${props.index}].timingAndDosage.dosages[${index}].dose`,
        dose
      )
    } else {
      if (props.package?.value.includes('/')) {
        packageDosageArray = props.package.value.split('/')
        packageDosageArrayConverted = packageDosageArray.map(it =>
          Number(it.replace(',', '.'))
        )
        packageDosageArrayConverted
          .map(it => roundTo(it * value, 8))
          .forEach((it, arrayIndex) => {
            if (arrayIndex !== packageDosageArrayConverted.length - 1) {
              computedDosageString += String(it).replace('.', ',') + '/'
            } else {
              computedDosageString += String(it).replace('.', ',')
            }
          })
      } else {
        packageDosageNumber = Number(props.package?.value.replace(',', '.'))
        packageDosageNumber *= value
        computedDosageString = String(roundTo(packageDosageNumber, 8)).replace(
          '.',
          ','
        )
      }
      dose = {
        value: computedDosageString,
        label: computedDosageString,
      }
      setFieldValue?.(
        `timeIntervals[${props.index}].timingAndDosage.dosages[${index}].dose`,
        dose
      )
    }
  }
  return { volume, pieces, dose: dose }
}
