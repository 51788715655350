import React, { Component } from 'react'
import Autocomplete from '../../Autocomplete/Autocomplete'
import { NotificationManager } from 'react-notifications'
import { searchMedication } from '../../../../services/MedicationActivityDefinitionService'
import { nest } from '../../../../utils/NestUtils'

export const getMedicationActivityDefinitionName = (
  medicationActivityDefinition: MedicationActivityDefinition[]
): string => {
  return medicationActivityDefinition?.[0]?.substances
}

interface Props {
  value?: MedicationActivityDefinition[]
  label?: string
  placeholder?: string
  disabled?: boolean
  icon?: string
  clearOnSelect?: boolean
  onChange: (
    medicationActivityDefinition: MedicationActivityDefinition[]
  ) => void
  excludeSelf: boolean
}

interface State {
  medicationActivityDefinitionValues?: MedicationActivityDefinition[][]
  selected: MedicationActivityDefinition[]
  value: string
}

function ingredientGrouping(
  medicationActivityDefinitionValues: MedicationActivityDefinition[]
): MedicationActivityDefinition[][] {
  return Object.values(nest(medicationActivityDefinitionValues, ['substances']))
}

class MedicationActivityDefinitionSelect extends Component<Props, State> {
  static defaultProps = {
    icon: 'search',
    excludeSelf: false,
  }
  state = {
    selected: null,
    medicationActivityDefinitionValues: null,
    value: '',
  }

  componentDidMount(): void {
    const { value } = this.props

    if (value) {
      this.setState({
        selected: value,
        value: getMedicationActivityDefinitionName(value),
      })
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { value } = this.props

    if (prevProps.value !== value) {
      if (value) {
        this.setState({
          selected: value,
          value: getMedicationActivityDefinitionName(value),
        })
      } else {
        this.setState({
          selected: null,
          value: '',
          medicationActivityDefinitionValues: null,
        })
      }
    }
  }

  handleSelect = (
    medicationActivityDefinition: MedicationActivityDefinition[]
  ): void => {
    if (medicationActivityDefinition !== this.state.selected)
      this.props.onChange(medicationActivityDefinition)
    if (this.props.clearOnSelect) {
      this.setState({
        selected: medicationActivityDefinition,
        value: '',
      })
    } else if (medicationActivityDefinition) {
      this.setState({
        selected: medicationActivityDefinition,
        value: getMedicationActivityDefinitionName(
          medicationActivityDefinition
        ),
      })
    } else {
      this.setState({
        selected: null,
        value: '',
      })
    }
  }

  handleSearch = async (param: string): Promise<void> => {
    if (param) {
      const res = await searchMedication(param)
      if (res && res.data) {
        const medicationActivityDefinitions =
          res.data as MedicationActivityDefinition[]
        this.setState({
          medicationActivityDefinitionValues: ingredientGrouping(
            medicationActivityDefinitions
          ),
        })
        console.log({
          medicationActivityDefinitionValues: ingredientGrouping(
            medicationActivityDefinitions
          ),
        })
      } else {
        NotificationManager.error('Fehler', 'Derzeit keine Antwort vom Server')
      }
    } else {
      this.setState({ medicationActivityDefinitionValues: [] })
    }
  }

  render(): JSX.Element {
    return (
      <Autocomplete<MedicationActivityDefinition[]>
        label={this.props.label}
        data={this.state.medicationActivityDefinitionValues}
        onSearch={this.handleSearch}
        onSelect={this.handleSelect}
        selected={this.state.selected}
        disabled={this.props.disabled}
        renderValue={getMedicationActivityDefinitionName}
        icon={this.props.icon}
        value={this.state.value}
        placeholder={
          this.props.placeholder ? this.props.placeholder : 'Medikament'
        }
        notFoundMessage="Kein Medikament gefunden"
        idProp={'medicationId'}
        name={'MedicationActivityDefinition'}
      />
    )
  }
}

export default MedicationActivityDefinitionSelect
