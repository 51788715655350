import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import AuthProvider from './infrastructure/AuthProvider'
import NotificationReceiver from './infrastructure/NotificationReceiver'
import { Router } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
import { createBrowserHistory } from 'history'
import moment from 'moment'
import 'moment/locale/de'
import LayoutProvider from './infrastructure/LayoutProvider'
import DynamicSidebarProvider from './infrastructure/DynamicSidebarProvider'
import SubscriptionProvider from './infrastructure/SubscriptionProvider'
import { FlagsProvider } from 'react-feature-flags'
import { flags } from './config/FeatureFlags'

moment.locale('de')
const history = createBrowserHistory()

const panos = (
  <FlagsProvider value={flags}>
    <AuthProvider>
      <SubscriptionProvider>
        <NotificationReceiver />
        <LayoutProvider>
          <DynamicSidebarProvider>
            <Router history={history}>
              <App />
            </Router>
            <NotificationContainer />
          </DynamicSidebarProvider>
        </LayoutProvider>
      </SubscriptionProvider>
    </AuthProvider>
  </FlagsProvider>
)

ReactDOM.render(panos, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
