import React, { useContext, useState } from 'react'
import { useEvaluateBranching } from '../../hooks/UseEvaluateBranching'
import { FormOption } from '../../types/FormOption'
import { getPrevValueFromProps, noWriteErrorMessage } from '../../utils/FormUtils'
import FieldAufgabe from '../FieldFunctionComponents/FieldAufgabe'
import FieldExternalTool from '../FieldFunctionComponents/FieldExternalTool'
import {
  AtAnnotation,
  FieldExternalToolProps,
  FieldFunctionAnnotationExplanation,
  FieldFunctionAnnotationOntology,
  FieldFunctionAnnotationPiping,
  FieldFunctionAnnotationValidation,
  FieldFunctionAppointment,
  FieldFunctionInstrument,
  FieldFunctionSlider,
  FieldFunctionTask,
  FieldFunctionValidation,
  Links,
} from '../FieldFunctionComponents/FieldFunctionInterfaces'
import FieldInstrument from '../FieldFunctionComponents/FieldInstrument'
import DescriptiveText from '../UI/Forms/DescriptiveText'
import FormDatepicker, { FormDatePickerTypes } from '../UI/Forms/FormDatepicker/FormDatepicker'
import ErrorBoundary from '../UI/Forms/FormError/ErrorBoundary'
import FormError from '../UI/Forms/FormError/FormError'
import FormMatrix from '../UI/Forms/FormMatrix/FormMatrix'
import FormShowPrevValue from './FormShowPrevValue'
import FieldFunctionExplanation from '../FieldFunctionComponents/FieldExplanation'
import FieldFunctionPiping from '../FieldFunctionComponents/FieldFunctionPiping'
import { FieldFunctionRepeat, FieldFunctionRepeatAnnotation } from '../FieldFunctionComponents/FieldFunctionRepeat'
import FieldTerminAufgabe from '../FieldFunctionComponents/FieldTerminAufgabe'
import FieldTermin from '../FieldFunctionComponents/FieldTermin'
import FieldFunctionOntology from '../FieldFunctionComponents/FieldFunctionOntology'
import { SmartVariablesInfo } from '../Forms/FormInterfaces'
import { UserRole } from '../../constants/UserRole'
import FormCalc from '../UI/Forms/FormCalc/FormCalc'
import { EventStore } from '../../infrastructure/EventProviderInterfaces'
import FormInput from '../UI/Forms/FormInput/FormInput'
import FormRadio from '../UI/Forms/FormRadio/FormRadio'
import FormSelect from '../UI/Forms/FormSelect/FormSelect'
import FormCheckboxGroup from '../UI/Forms/FormCheckbox/FormCheckboxGroup'
import FormTextarea from '../UI/Forms/FormTextarea/FormTextarea'
import FormSlider from '../UI/Forms/FormSlider/FormSlider'
import { FieldTypes } from '../../constants/FieldTypes'
import { hasRole } from '../../services/Keycloak'
import { defaultAppointment } from '../../utils/CalendarUtils'

/**
 * contains all the possible key value pairs for the fieldfunctions
 * @param fieldName RedCap variable name of checkbox choices
 * @param fieldValues links checkbox choices to values, e.g. choice 1 == nms_questionnaire
 * @param category label for group of created forms, e.g. 'motorisch', 'nicht-motorisch', 'schlaf', 'sozial'
 */
export interface AnnotationRedcap
  extends FieldExternalToolProps,
    FieldFunctionInstrument,
    FieldFunctionAnnotationPiping,
    FieldFunctionAnnotationExplanation,
    FieldFunctionAnnotationValidation,
    FieldFunctionRepeatAnnotation,
    Links,
    FieldFunctionAnnotationOntology,
    AtAnnotation {
  fieldFunction?: string // name of the fieldfunction to use
  fieldName?: string
  fieldValues?: CheckBoxValues[]
  category?: string
  slider?: FieldFunctionSlider
  recordId?: string
  calculation?: string
}

export interface FormDynamicComponentProps
  extends FieldFunctionValidation,
    FieldFunctionTask,
    FieldFunctionAppointment {
  formName?: string // the origin unique redcap form name
  eventName?: string // the origin unique redcap event name
  moduleName?: string // the origin unique redcap form name of the module
  moduleEvent?: string // the origin unique redcap event name of the module

  field_type: FieldTypes // component to use
  annotation?: AnnotationRedcap // for the metalanguage
  name: string // variable name of Redcap and used in Formik
  value?: unknown // DEPRECATED ?!!! its not the prop you thing it is! needs to be cast to the right type in the frontend
  label?: string | JSX.Element // the label to be shown on top of the component
  required?: boolean // if the content is needed to complete the form
  error?: string // error message
  touched?: boolean // formik touched
  branchingLogic?: string
  header?: JSX.Element //section header
  handleChange?: {
    (
      e: React.ChangeEvent<any>,
      component: FormDynamicComponentProps,
      isDefault: boolean
    ): void
    <T = string | React.ChangeEvent<any>>(
      field: T,
      component: FormDynamicComponentProps,
      isDefault: boolean
    ): T extends React.ChangeEvent<any>
      ? void
      : (
          e: string | React.ChangeEvent<any>,
          component: FormDynamicComponentProps,
          isDefault: boolean
        ) => void
  }

  className?: string // HTML className
  disabled?: boolean // if the content should be editable
  inline?: boolean // for inline styling
  previousValues?: Record<string, unknown> //All prevous values
  showPrevValue?: boolean
  readOnlyMode?: boolean
  /**
   * properties depending on field_type
   */
  placeholder?: string // Datepicker, Textarea
  options?: Array<FormOption> // Radio, Checkbox
  type?: string // HTML input type
  pattern?: string // HTML input pattern

  subComponents?: Array<FormDynamicComponentProps> // Matrix rows, External Instrument Fields
  /**
   * Matrix component
   */
  matrixRanking?: boolean // set to true if column can be selected only once

  /**
   * for evaluation if no formik context
   */
  values?: Record<string, unknown>

  allComponents?: FormDynamicComponentProps[]
  index?: number
  justDisplay?: boolean

  /**
   * patientId necessary for some FieldFunctions
   */
  patientId?: string
  /**
   * smart variables
   */
  smartVariables?: SmartVariablesInfo[]
}

/**
 * contains the value to each checkbox choice
 */
export interface CheckBoxValues {
  choice: number
  value: string
}

const FormDynamicComponent: React.FC<FormDynamicComponentProps> = (
  props: FormDynamicComponentProps
) => {
  const [shouldRender, branchingError] = useEvaluateBranching({
    name: props.name,
  })
  const context = useContext(EventStore)
  const { errors, values, touched } = context
  const value = values[props.name]
  const error = errors[props.name]
  const isTouched = touched[props.name]
  const prevValue = getPrevValueFromProps(
    props,
    context.prevValues[context.currentPreviousVersion]
  )

  const [onClickTriggerMatrix, setOnClickTriggerMatrix] =
    useState<boolean>(null)

  if (branchingError) {
    console.log('error with branching logic', branchingError.message)
    return (
      <FormError>
        <>
          Branchinglogikfehler ({props.name}): {props.branchingLogic}
        </>
      </FormError>
    )
  }
  if (!shouldRender) return null

  const setValueFromPrevValue = (value: Record<string, unknown>) => {
    if (
      props.readOnlyMode ||
      (props.annotation && props.annotation.readonly === true)
    ) {
      return
    }
    context.setFieldValue(props.name, value[props.name])
    context.setFieldTouched(props.name, false, true)
  }

  let onClick = () => setValueFromPrevValue(prevValue)

  const handleChange = (value: unknown) => {
    if (props.readOnlyMode === true) {
      noWriteErrorMessage()
      return
    }

    context.handleFieldChange(value, props.name)
  }

  const handleChangeEvent = (event: any): void => {
    if (props.readOnlyMode === true) {
      noWriteErrorMessage()
      return
    }
    const { checked, value } = event.target
    const obj = {
      ...(values[props.name] as Record<string, unknown>),
      [value]: checked,
    }
    context.handleFieldChange(obj, props.name)
  }
  try {
    let displayItem
    switch (props.field_type) {
      case FieldTypes.TEXT:
        if (!props.annotation?.ontology) {
          displayItem = (
            <FormInput
              {...props}
              value={value as string}
              error={error}
              touched={isTouched ?? false}
              onChange={handleChange}
              instant={false}
            />
          )
        } else {
          displayItem = <FieldFunctionOntology {...props} />
        }

        break
      case FieldTypes.DESCRIPTIVE:
        displayItem = (
          <DescriptiveText className={props.className} value={props.label} />
        )
        break
      case FieldTypes.DATEPICKER:
        displayItem = (
          <FormDatepicker
            {...props}
            type={props.type as FormDatePickerTypes}
            value={value as string}
            error={error}
            touched={isTouched ?? false}
            onChange={handleChange}
          />
        )
        break
      case FieldTypes.DROPDOWN:
        displayItem = (
          <FormSelect
            {...props}
            options={props.options}
            value={value as string}
            error={error}
            touched={isTouched ?? false}
            onChange={handleChange}
          />
        )
        break
      case FieldTypes.YESNO:
      case FieldTypes.RADIO:
        displayItem = (
          <FormRadio
            {...props}
            options={props.options}
            value={value as string}
            error={error}
            touched={isTouched ?? false}
            onChange={handleChange}
          />
        )

        break
      case FieldTypes.CHECKBOX:
        displayItem = (
          <FormCheckboxGroup
            {...props}
            options={props.options}
            value={value as Record<string, unknown>}
            error={error}
            touched={isTouched ?? false}
            onChangeEvent={handleChangeEvent}
          />
        )
        break
      case FieldTypes.CHECKBOX_MATRIX: // same as radioMatrix
      case FieldTypes.RADIO_MATRIX: {
        displayItem = (
          <FormMatrix
            {...props}
            previousValues={prevValue}
            onClickTriggerMatrix={onClickTriggerMatrix}
            context={context}
          />
        )
        onClick = () => {
          setOnClickTriggerMatrix(!onClickTriggerMatrix)
        }
        break
      }
      case FieldTypes.NOTES:
        displayItem = (
          <FormTextarea
            {...props}
            value={value as string}
            error={error}
            touched={isTouched ?? false}
            onChange={handleChange}
            instant={false}
          />
        )
        break
      case FieldTypes.SUBMIT:
        displayItem = <input type={'submit'} />
        break
      case FieldTypes.FIELD_EXTERNAL_TOOL:
        displayItem = <FieldExternalTool {...props} />
        break
      case FieldTypes.FIELD_INSTRUMENT:
        displayItem = (
          <FieldInstrument
            {...props}
            error={error}
            touched={isTouched ?? false}
          />
        )
        break
      case FieldTypes.FIELD_AUFGABE:
        displayItem =
          props.readOnlyMode !== true ? (
            <FieldAufgabe tasks={props.tasks} links={props.annotation.links} />
          ) : (
            <div />
          )
        break
      case FieldTypes.FIELD_TERMIN_AUFGABE:
        displayItem =
          props.readOnlyMode !== true ? (
            <FieldTerminAufgabe
              initialTasks={props.tasks}
              initialAppointments={props.appointments}
              links={props.annotation.links}
            />
          ) : (
            <div />
          )
        break
      case FieldTypes.FIELD_TERMIN: {
        const appointment =
          props.appointments && props.appointments.length > 0
            ? props.appointments[0]
            : defaultAppointment()
        displayItem =
          props.readOnlyMode !== true ? (
            <FieldTermin
              defaultAppointment={appointment}
              links={props.annotation.links}
            />
          ) : (
            <div />
          )
        break
      }
      case FieldTypes.SLIDER: {
        displayItem = (
          <FormSlider
            {...props}
            {...props.annotation.slider}
            options={props.options}
            value={Number(value)}
            error={error}
            onChange={handleChange}
          />
        )
        break
      }
      case FieldTypes.CALC: {
        if (props.annotation?.calculation) {
          displayItem = (
            <FormCalc
              {...props}
              isPatient={hasRole(UserRole.PATIENT)}
              readOnlyMode={props.readOnlyMode}
            />
          )
        }

        break
      }
      default:
        console.log('Bad component', props)
        displayItem = (
          <FormError>Der Typ der Komponente ist nicht vorhanden.</FormError>
        )
    }

    const renderDisplayItem = (displayItem: JSX.Element): JSX.Element => {
      if (!props.header) return displayItem
      return (
        <>
          {props.header}
          {displayItem}
        </>
      )
    }

    const cssClasses: string[] = ['']
    if (
      props.field_type !== FieldTypes.CHECKBOX_MATRIX &&
      props.field_type !== FieldTypes.RADIO_MATRIX
    )
      cssClasses.push('avoid-pagebreak-inside')
    if (props.field_type !== FieldTypes.DESCRIPTIVE)
      cssClasses.push('border-bottom')
    const { annotation } = props
    if (annotation) {
      if (annotation.hidden) {
        cssClasses.push('d-none')
      } else if (annotation.hidden_PDF) {
        cssClasses.push('d-print-none')
      } else if (annotation.hidden_FORM) {
        cssClasses.push('d-none d-print-block')
      }
    }

    if (
      props.field_type === FieldTypes.DESCRIPTIVE &&
      props.className === 'error'
    ) {
      cssClasses.push('d-print-none')
    }

    return (
      <ErrorBoundary
        message={'Es gab einen Fehler beim Anzeigen der Komponente.'}>
        <div className={cssClasses.join(' ')}>
          {!props.justDisplay ? (
            <>
              <FormShowPrevValue
                displayItem={renderDisplayItem(displayItem)}
                prevValue={prevValue}
                {...props}
                value={value}
                onClick={onClick}
              />
              <FieldFunctionPiping
                component={props}
                values={context.values}
                setValues={context.setValues}
              />
              <FieldFunctionRepeat
                component={props}
                allComponents={props.allComponents}
                index={props.index}
                repeatNextFields={props.annotation?.repeatNextFields}
              />
              <FieldFunctionExplanation
                explanation={props.annotation?.explanation}
              />
            </>
          ) : (
            renderDisplayItem(displayItem)
          )}
        </div>
      </ErrorBoundary>
    )
  } catch (e) {
    console.log('Error matching component', e)
    return <FormError />
  }
}

export default FormDynamicComponent
