import ApiServiceBase from './ApiServiceBase'
import { API_CAREPLAN } from '../config/Paths'
import { ApiResponse } from '../types/ApiResponse'
import { ApiError } from '../types/ApiError'
import { CarePlan, CarePlanUpdate } from '../types/CarePlan'
import {
  addResourceTypeIfNot,
  getIdPartFromFhirResourceId,
} from '../utils/Utils'

export class CarePlanService extends ApiServiceBase {
  context: string
  constructor(context: string = undefined) {
    super()
    if (!context) {
      throw Error('careplan without reason')
    }
    this.context = context
  }

  parseCarePlan = (carePlan: CarePlan): CarePlan => {
    if (carePlan) {
      return {
        ...carePlan,
        patient: addResourceTypeIfNot(carePlan.patient, 'Patient'),
        medicationRequests: carePlan.medicationRequests.map(mr =>
          addResourceTypeIfNot(mr, 'MedicationRequest')
        ),
        instantiatesCanonical: addResourceTypeIfNot(
          carePlan.instantiatesCanonical,
          'PlanDefinition'
        ),
        note: this.context,
      } as CarePlan
    } else {
      console.error('CarePlan is null!')
    }
  }

  getCarePlan(carePlanId: string): Promise<ApiResponse<CarePlan | ApiError>> {
    return this.get<null, CarePlan>(
      new URL(API_CAREPLAN + `/${getIdPartFromFhirResourceId(carePlanId)}`)
    )
  }

  getCarePlans(patientId: string): Promise<ApiResponse<CarePlan[] | ApiError>> {
    return this.get<null, CarePlan[]>(
      new URL(
        API_CAREPLAN + `/patient/${getIdPartFromFhirResourceId(patientId)}`
      )
    )
  }

  getActiveCarePlan(
    patientId: string
  ): Promise<ApiResponse<CarePlan | ApiError>> {
    return this.get<null, CarePlan>(
      new URL(
        API_CAREPLAN +
          `/patient/active/${getIdPartFromFhirResourceId(patientId)}`
      )
    )
  }

  activateCarePlan(
    patientId: string,
    carePlanId: string
  ): Promise<ApiResponse<CarePlan | ApiError>> {
    return this.get<null, CarePlan>(
      new URL(
        API_CAREPLAN + `/patient/${patientId}/activate?resourceId=${carePlanId}`
      )
    )
  }

  updateCarePlansMedicationRequests(
    carePlanId: string,
    medicationRequestIds: string[],
    reason = null
  ): Promise<ApiResponse<CarePlan | ApiError>> {
    const body = {
      medicationRequestIds,
      note: reason ?? this.context,
    }
    return this.post<CarePlanUpdate, CarePlan>(
      new URL(
        API_CAREPLAN +
          `/${getIdPartFromFhirResourceId(
            carePlanId
          )}/updateCarePlansMedicationRequests`
      ),
      null,
      body
    )
  }

  postCarePlan(carePlan: CarePlan): Promise<ApiResponse<CarePlan | ApiError>> {
    return this.post<CarePlan, CarePlan>(
      new URL(API_CAREPLAN),
      null,
      this.parseCarePlan(carePlan)
    )
  }

  putCarePlan(carePlan: CarePlan): Promise<ApiResponse<CarePlan | ApiError>> {
    return this.put<CarePlan>(
      new URL(API_CAREPLAN),
      this.parseCarePlan(carePlan)
    )
  }

  deleteCarePlan = (
    carePlan: CarePlan
  ): Promise<ApiResponse<string | ApiError>> => {
    return this.delete<CarePlan>(new URL(API_CAREPLAN + '/' + carePlan.id))
  }

  updateCarePlan(
    carePlan: CarePlan
  ): Promise<ApiResponse<CarePlan | ApiError>> {
    if (carePlan.id) return this.putCarePlan(carePlan)
    else return this.postCarePlan(carePlan)
  }

  /*  saveCarePlanWithVersionBasedOn = async (carePlan: CarePlan) => {
    return this.post<CarePlan, CarePlan>(
      new URL(API_CAREPLAN + '/saveCarePlanWithVersionBasedOn'),
      null,
      this.parseCarePlan(carePlan)
    )
  }*/

  getActiveCarePlanHistory = async (
    patientId: string
  ): Promise<ApiResponse<ApiError | CarePlan[][]>> => {
    return await this.get<null, CarePlan[][]>(
      new URL(API_CAREPLAN + '/history/' + patientId),
      null
    )
  }
}

export default CarePlanService
