import React from 'react'
import { useField, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'

interface DatePickerFieldProps {
  name: string
  className: string
}

export const DatePickerField: React.FC<DatePickerFieldProps> = (props: DatePickerFieldProps) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)
  return (
    <DatePicker
      dateFormat="dd.MM.yyyy"
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, val)
      }}
      showWeekNumbers
    />
  )
}
