import Keycloak from 'keycloak-js'
import { keycloakConfig } from '../config/Keycloak'
import { UserRole } from '../constants/UserRole'

const keycloak = Keycloak(keycloakConfig)

keycloak.onTokenExpired = async (): Promise<void> => {
  keycloak
    .updateToken(30)
    .then(() => {
      console.log('Token refreshed')
    })
    .catch(function () {
      console.log('Token is no longer valid')
    })
}

keycloak.onAuthLogout = (): void => {
  window.location.reload()
}

export default keycloak

export const getToken = (): string => {
  return keycloak.token
}

export const getRealmAccess = (): Keycloak.KeycloakRoles => {
  return keycloak.realmAccess
}

export const hasRole = (role: string): boolean => {
  return keycloak.hasRealmRole(role)
}

export const getUserRoles = (): UserRole[] => {
  const realmAccess = keycloak.realmAccess
  return realmAccess ? (realmAccess.roles as UserRole[]) : []
}

export interface IdTokenParsed extends Keycloak.KeycloakTokenParsed {
  preferred_username: string
  given_name: string
  family_name: string
  lanr?: string
}
