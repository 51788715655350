import { Client, messageCallbackType, StompHeaders } from '@stomp/stompjs'
import IAuth from '../../types/Auth'

interface IWebSocketService {
  connect(
    onConnectCb: () => void,
    onDisconnectCb: () => void,
    onErrorCb: () => void
  ): void
  disconnect(): void
  subscribe(destination: string, cb: messageCallbackType): void
  sendMessage(destination: string, body: string): void
}

export class WebsocketService implements IWebSocketService {
  private client: Client
  private onConnectCb?: () => void
  private onDisconnectCb?: () => void
  private onErrorCb?: () => void
  private _isConnected = false
  private headers: StompHeaders

  private static instance: WebsocketService

  private constructor(auth: IAuth) {
    const webSocketUrl =
      process.env.NODE_ENV === 'development'
        ? 'ws://localhost:8080/ws'
        : `wss://${window.location.hostname}/ws`

    if (auth.authenticated) {
      this.headers = new StompHeaders()
      this.headers['keycloakId'] = auth.keycloak.token
      this.headers['subjectId'] = auth.keycloak.subject

      //console.log(
      //  `${process.env.NODE_ENV === 'development' ? 'DEV' : 'PROD'} mode`
      //)
      this.client = new Client({
        brokerURL: webSocketUrl,
        debug: function (str: string) {
          //console.log('WS debug: ', str)
          //console.trace()
        },
        reconnectDelay: 30000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
        connectHeaders: this.headers,
      })

      this.client.onConnect = () => {
        this._isConnected = true
        this.onConnectCb && this.onConnectCb()
      }

      this.client.onDisconnect = () => {
        this._isConnected = false
        this.onDisconnectCb && this.onDisconnectCb()
      }

      this.client.onStompError = (frame: any) => {
        console.error('WS: Broker reported error: ' + frame.headers['message'])
        console.error('WS: Additional details: ' + frame.body)
        this.onErrorCb && this.onErrorCb()
      }
    }
  }

  static getInstance(auth: IAuth): WebsocketService {
    if (!WebsocketService.instance) {
      return new WebsocketService(auth)
    }
    return WebsocketService.instance
  }

  get isConnected(): boolean {
    return this._isConnected
  }

  connect(
    onConnectCb: () => void,
    onDisconnectCb: () => void,
    onErrorCb: () => void
  ): void {
    this.onConnectCb = onConnectCb
    this.onDisconnectCb = onDisconnectCb
    this.onErrorCb = onErrorCb

    this.client?.activate()
  }

  disconnect(): void {
    this.client?.deactivate()
  }

  subscribe(destination: string, cb: messageCallbackType): void {
    //console.trace()
    this.client?.subscribe(destination, cb, this.headers)
  }

  sendMessage(destination: string, body: string): void {
    this.client?.publish({ destination, body, headers: this.headers })
  }
}
