import React, { useEffect, useRef, useState } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import { getPrevValueText } from '../../utils/FormUtils'
import MaterialIcon from '../UI/MaterialIcon/MaterialIcon'
import {
  AnnotationRedcap,
  FormDynamicComponentProps,
} from './FormDynamicComponent'

import { isEqual } from 'lodash'

interface FormShowPrevValueProps extends FormDynamicComponentProps {
  displayItem: JSX.Element
  prevValue: Record<string, unknown>
  annotation?: AnnotationRedcap
  onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  hideIfEqual?: boolean
}

const FormShowPrevValue: React.FC<FormShowPrevValueProps> = ({
  displayItem,
  prevValue,
  name,
  field_type,
  options,
  subComponents,
  annotation,
  onClick,
  value,
  hideIfEqual,
}: FormShowPrevValueProps) => {
  const isEqualValue = isEqual(value, prevValue?.[name])
  const [minified, setMinified] = useState(isEqualValue)

  useEffect(() => {
    if (isEqualValue) {
      setMinified(true)
    }
    if (!isEqualValue) {
      setMinified(false)
    }
  }, [isEqualValue])

  const target = useRef(null)
  const prevDisplayValues = getPrevValueText({
    name: name,
    field_type: field_type,
    options: options,
    previousValues: prevValue,
    subComponents: subComponents,
  })

  if (!prevDisplayValues || (annotation && annotation.readonly === true)) {
    return displayItem
  }

  if (hideIfEqual && isEqual) return null

  const popover = (
    <Popover
      id="popover-basic"
      show={true}
      aria-label={'Vorwert'}
      style={{ padding: '0px !important' }}
      data-toogle="tooltip"
      title="Vorherige Werte"
      placement={minified ? 'right' : null}>
      <Popover.Content>
        <table>
          <tbody>
            <tr>
              <td>
                <div>
                  {!minified && (
                    <span>
                      {prevDisplayValues
                        ? prevDisplayValues
                        : 'kein Wert gesetzt'}
                    </span>
                  )}
                </div>
              </td>
              <td>
                <div
                  style={{
                    minWidth: !minified ? '5em' : '1em',
                    textAlign: 'right',
                  }}>
                  {!minified && (
                    <span
                      onClick={e => {
                        setMinified(true)
                        onClick(e)
                      }}
                      className="prevValueControl "
                      aria-label={'Übernehmen'}>
                      <MaterialIcon icon="menu_open" mirrored />
                    </span>
                  )}
                  <span
                    onClick={() => setMinified(!minified)}
                    className="prevValueControl"
                    aria-label={minified ? 'Maximieren' : 'Minimieren'}>
                    <MaterialIcon icon={minified ? 'maximize' : 'minimize'} />
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Popover.Content>
    </Popover>
  )

  return (
    <div ref={target}>
      {!minified ? (
        <Overlay
          container={document.body}
          target={target.current}
          show={true}
          placement="left">
          {popover}
        </Overlay>
      ) : (
        <div className="position-relative">
          <div
            className={'position-absolute'}
            style={{
              right: '10px',
            }}>
            {popover}
          </div>
        </div>
      )}
      {displayItem}
    </div>
  )
}

export default FormShowPrevValue
