import React from 'react'
import { Button, Nav, Navbar } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import logo from '../../../assets/logo-transparent.png'

const PrintHeader: React.FC = (): JSX.Element => {
  const history = useHistory()

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Nav className="ml-0">
          <Nav.Item>
            <Nav.Link
              as={Button}
              variant="outline-primary"
              onClick={() => history.goBack()}>
              Zurück
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Nav className="ml-auto mr-0">
          <Nav.Item>
            <Nav.Link
              as={Button}
              variant="outline-primary"
              onClick={() => window.print()}>
              Drucken
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar>
      <img src={logo} style={{ maxHeight: '160px' }} alt="Panos Logo" />
    </>
  )
}

export default PrintHeader
