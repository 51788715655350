import isEqual from 'lodash/isEqual'
import { ExtraVariableInfos } from '../components/Forms/FormInterfaces'
import { isNullUndefinedOrCheckboxWithNullValues } from './FormUtils'
import {
  insertVariablesBranching,
  insertVariablesDefault,
} from './RedcapVariableUtils'
import { math } from './CalcParseUtils'
import { FormDynamicComponentProps } from 'src/components/FormDynamic/FormDynamicComponent'
import { FormOption } from 'src/types/FormOption'
import { FieldTypes } from '../constants/FieldTypes'

/**
 * hides the component and sets the value to null,
 * so that other components can also be updated
 */
export function hideAndUpdateValue(
  show: boolean,
  newShow: boolean,
  name: string,
  extraInfosComponents: Record<string, ExtraVariableInfos>,
  setFieldValue: (key: string, value: unknown) => void
): void {
  if (show !== false && !newShow) {
    const { field_type, options } = extraInfosComponents[name]
    switch (field_type) {
      case FieldTypes.CHECKBOX: {
        const value = {}
        options.forEach(option => {
          value[option.value] = null
        })
        setFieldValue(name, value)
        break
      }
      case FieldTypes.TEXT:
      case FieldTypes.DATEPICKER:
      case FieldTypes.DROPDOWN:
      case FieldTypes.RADIO:
      case FieldTypes.YESNO:
      case FieldTypes.NOTES:
      case FieldTypes.SLIDER:
        setFieldValue(name, null)
        break
      default:
        break
    }
  }
}

export function updateValueWithDefaultValue(
  name: string,
  extraInfosComponents: Record<string, FormDynamicComponentProps>,
  values: Record<string, unknown>,
  setFieldValue: (key: string, value: unknown) => void
): void {
  const { field_type, options } = extraInfosComponents[name]
  if (
    values &&
    isNullUndefinedOrCheckboxWithNullValues(values[name], field_type, options)
  ) {
    const { annotation } = extraInfosComponents[name]
    const defaultValue = annotation ? annotation.default : null

    switch (field_type) {
      case FieldTypes.CHECKBOX: {
        const value = values[name]
        const newValue = {}
        options.forEach(option => {
          newValue[option.value] = false
        })
        let defaultValues
        if (defaultValue) {
          const defaultString = insertVariablesDefault(
            defaultValue,
            values,
            extraInfosComponents
          )
          if (defaultString) {
            defaultValues = defaultString.split(',')
          } else {
            defaultValues = []
          }
        } else {
          defaultValues = []
        }
        let checkboxTouched = false
        Object.keys(value).forEach(key => {
          if (value[key] === true || value[key] === false)
            checkboxTouched = true
        })
        if (!checkboxTouched) {
          options.forEach(option => {
            newValue[option.value] = defaultValues.includes(option.value)
          })
          if (!isEqual(value, newValue)) setFieldValue(name, newValue)
        }
        break
      }
      case FieldTypes.TEXT:
      case FieldTypes.DATEPICKER:
      case FieldTypes.DROPDOWN:
      case FieldTypes.RADIO:
      case FieldTypes.YESNO:
      case FieldTypes.NOTES:
      case FieldTypes.SLIDER:
        setFieldValue(
          name,
          insertVariablesDefault(defaultValue, values, extraInfosComponents)
        )
        break
      default:
        break
    }
  }
}

export default function evaluate(
  str: string,
  values: Record<string, unknown>,
  extraInfosComponents?: Record<string, ExtraVariableInfos>
): boolean {
  const stringToParse = insertVariablesBranching(
    str,
    values,
    extraInfosComponents
  )
  const newValue = math.evaluate(stringToParse)
  if (typeof newValue !== 'boolean') throw new Error('Kein boolean!')
  return newValue
}

export function evaluateBranch(
  name: string,
  values: Record<string, unknown>,
  extraInfosComponents: Record<string, FormDynamicComponentProps>,
  show: boolean | null,
  setShow: (show: boolean) => void,
  error: Error | null,
  setError: React.Dispatch<React.SetStateAction<Error>>,
  setFieldValue: (key: string, value: unknown) => void
): void {
  const { branchingLogic } = extraInfosComponents[name]
  if (values) {
    try {
      if (
        branchingLogic === '' ||
        branchingLogic === null ||
        branchingLogic === undefined
      ) {
        if (show !== true || error !== null) {
          setShow(true)
          setError(null)
          updateValueWithDefaultValue(
            name,
            extraInfosComponents,
            values,
            setFieldValue
          )
        }
      } else {
        const newShow = evaluate(
          branchingLogic,
          values,
          extraInfosComponents
        ) as boolean
        hideAndUpdateValue(
          show,
          newShow,
          name,
          extraInfosComponents,
          setFieldValue
        )
        if (newShow !== show) {
          setShow(newShow)
          if (newShow) {
            updateValueWithDefaultValue(
              name,
              extraInfosComponents,
              values,
              setFieldValue
            )
          }
        }
      }
    } catch (e) {
      if (!isEqual(e, error)) setError(e)
      if (show !== false) {
        setShow(false)
      }

      console.log('Branching error', branchingLogic, values, e)
    }
  }
}

export function generateValueFromDefaultValue(
  stateValues: Record<string, unknown>,
  field_type: string,
  options: FormOption[],
  name: string,
  defaultValue?: string,
  extraInfosComponents?: Record<string, ExtraVariableInfos>
): unknown {
  if (stateValues) {
    switch (field_type) {
      case FieldTypes.CHECKBOX: {
        const newValue = {}
        let defaultValues
        if (defaultValue) {
          const defaultString = insertVariablesDefault(
            defaultValue,
            stateValues as Record<string, unknown>,
            extraInfosComponents
          )
          if (defaultString) {
            defaultValues = defaultString.split(',')
          } else {
            defaultValues = []
          }
        } else {
          defaultValues = []
        }
        options.forEach(option => {
          newValue[option.value] = defaultValues.includes(option.value)
        })
        return newValue
      }
      case FieldTypes.TEXT:
      case FieldTypes.DATEPICKER:
      case FieldTypes.DROPDOWN:
      case FieldTypes.RADIO:
      case FieldTypes.YESNO:
      case FieldTypes.NOTES:
      case FieldTypes.SLIDER:
        return insertVariablesDefault(
          defaultValue,
          stateValues as Record<string, unknown>,
          extraInfosComponents
        )
      default:
        return null
    }
  }
}

export function setShow(
  name: string,
  show: Record<string, boolean>
): (newShow: boolean) => void {
  return (newShow: boolean) => (show[name] = newShow)
}

export function generateSetFieldValue(
  values: Record<string, unknown>
): (key: string, value: unknown) => void {
  return (key: string, value: unknown) => {
    if (isEqual(values[key], value)) return
    values[key] = value
  }
}

/**
 * has 2 tiers because of checkboxes
 * we need to overwrite every reference and create new objects instead
 */
export function deepCopyState(
  values: Record<string, unknown>
): Record<string, unknown> {
  const tempValues = {}
  Object.entries(values).forEach(([key, value]) => {
    if (value !== null && typeof value === 'object') {
      tempValues[key] = { ...value }
    } else {
      tempValues[key] = value
    }
  })
  return tempValues
}

export function updateAll(
  values: Record<string, unknown>,
  extraInfosComponents: Record<string, FormDynamicComponentProps>,
  show: Record<string, boolean>,
  setShow: (
    name: string,
    show: Record<string, boolean>
  ) => (newShow: boolean) => void,
  generateSetFieldValue: (
    values: Record<string, unknown>
  ) => (key: string, value: unknown) => void
): [Record<string, unknown>, Record<string, boolean>] {
  let oldState
  let oldShow
  const newState = deepCopyState(values)
  const newShow: Record<string, boolean> = { ...show }
  do {
    oldState = deepCopyState(newState)
    oldShow = { ...newShow }
    Object.keys(extraInfosComponents).forEach(name => {
      evaluateBranch(
        name,
        newState,
        extraInfosComponents,
        newShow[name],
        setShow(name, newShow),
        null,
        () => {
          return
        },
        generateSetFieldValue(newState)
      )
    })
  } while (!isEqual(oldState, newState) || !isEqual(oldShow, newShow))
  return [newState, newShow]
}

export function isEqualCustomizer(
  arg1: unknown,
  arg2: unknown
): boolean | undefined {
  if ((arg1 === false && arg2 === null) || (arg1 === null && arg2 === false))
    return true
  return undefined
}
