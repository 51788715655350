import { Button } from '@material-ui/core'
import MaterialIcon from '../UI/MaterialIcon/MaterialIcon'
import { RedCapFormStatus } from '../Forms/RedCapFormStatus'
import NameDisplay from '../UI/NameDisplay/NameDisplay'
import moment from 'moment'
import React from 'react'
import { PrevVersionInfo } from '../Forms/FormInterfaces'

export function PrevValues(props: {
  currentPreviousValueVersion: string
  strings: string[]
  reduceIndex: () => void
  increaseIndex: () => void
  setValues: () => void
  prevVersionInfo: PrevVersionInfo
}): JSX.Element {
  return (
    <div className="prevFormValues alert-info alert">
      {' '}
      <div style={{ flexGrow: 2 }} className=" d-flex ">
        <h5>
          Vorwerte {props.currentPreviousValueVersion} / {props.strings.length}
        </h5>

        <div className="d-flex">
          <Button onClick={props.reduceIndex} className="prevValueControl">
            <MaterialIcon icon="keyboard_arrow_left" />
          </Button>
          <Button onClick={props.increaseIndex} className="prevValueControl">
            <MaterialIcon icon="keyboard_arrow_right" />
          </Button>
        </div>
        <div className="d-flex">
          <span
            className="btn-link dark ml-auto mr-0"
            onClick={props.setValues}>
            Alle übernehmen
          </span>
        </div>
      </div>
      <table>
        <tbody>
          <td style={{ width: '10%' }}>Status:</td>
          <td style={{ width: '20%' }}>
            <RedCapFormStatus status={props.prevVersionInfo?.status} />
          </td>
          <td style={{ width: '10%' }}>Autor:</td>
          <td style={{ width: '20%' }}>
            <NameDisplay personId={props.prevVersionInfo?.creator} />
          </td>
          <td style={{ width: '10%' }}>Datum:</td>
          <td style={{ width: '20%' }}>
            {moment(props.prevVersionInfo?.date).format('DD.MM.YYYY')}
          </td>
        </tbody>
      </table>
    </div>
  )
}
