import React from 'react'
import FormDynamicComponent, {
  FormDynamicComponentProps,
} from './FormDynamicComponent'

interface FormDynamicComponentListProps {
  components: FormDynamicComponentProps[]
  handleChange: (
    e: React.ChangeEvent<unknown>,
    component: FormDynamicComponentProps,
    isDefault: boolean
  ) => void
  patientId: string
  readOnlyMode?: boolean
}

const FormDynamicComponentList: React.FC<FormDynamicComponentListProps> = ({
  components,
  handleChange,
  patientId,
  readOnlyMode,
}: FormDynamicComponentListProps) => {
  if (!components) return null
  return (
    <>
      {components.map((component, i) => (
        <FormDynamicComponent
          key={component.name + '-dynamic-form-' + i}
          {...component}
          handleChange={handleChange}
          allComponents={components}
          index={i}
          patientId={patientId}
          readOnlyMode={readOnlyMode}
        />
      ))}
    </>
  )
}

export default FormDynamicComponentList
