export enum AppointmentStatus {
  PROPOSED = 'PROPOSED',
  PENDING = 'PENDING',
  BOOKED = 'BOOKED',
  ARRIVED = 'ARRIVED',
  FULFILLED = 'FULFILLED',
  CANCELLED = 'CANCELLED',
  NOSHOW = 'NOSHOW',
  ENTEREDINERROR = 'ENTERED-IN-ERROR',
  CHECKEDIN = 'CHECKED-IN',
  WAITLIST = 'WAITLIST',
}
