import React from 'react'
import { FormOption } from '../../../../types/FormOption'
import { SelectorList } from '../../../SelectorList'
import FormErrorMessage from '../FormError/FormErrorMessage'

interface FormSelectProps {
  name: string
  onChange?: (value: string) => void
  onChangeEvent?: (event: React.ChangeEvent<any>) => void
  options: FormOption[]
  label?: string | JSX.Element
  required?: boolean
  value?: string | null
  className?: string
  disabled?: boolean
  error?: string
  touched?: boolean
}

/**
 * Use this component with or without formik cotnext.
 *
 * Required props without Formik are:
 * -- name
 * -- value
 * -- onChange
 */
const FormSelect: React.FC<FormSelectProps> = ({
  name,
  label,
  value,
  className,
  disabled,
  options,
  error,
  required,
  onChange,
  onChangeEvent,
}: FormSelectProps) => {
  const inputValue = value !== null && value !== undefined ? value : null

  const onChangeHandler = (value: string): void => {
    if (onChangeEvent) {
      const event = {
        target: {
          name: name,
          value: value,
        },
      }
      onChangeEvent(event as React.ChangeEvent<any>)
    } else if (onChange) {
      onChange(value)
    }
  }
  const cssClasses = className ? [className] : []
  if (error) cssClasses.push('position-relative')
  if (error) cssClasses.push('validate-bad')

  return (
    <div className={`form-group ${cssClasses.join(' ')}`}>
      {label ? (
        <label className="form-label">
          {label}
          {required ? <span style={{ color: 'red' }}> *</span> : null}
        </label>
      ) : null}
      <SelectorList
        id={name}
        values={options}
        get={row => row}
        onChange={onChangeHandler}
        className={error ? 'select-is-invalid' : ''}
        value={inputValue}
        disabled={disabled}
      />
      <FormErrorMessage error={error} />
    </div>
  )
}

export default FormSelect
