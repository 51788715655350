export const ShapeOptions = [
  {
    value: 'Salbe',
    label: 'Salbe',
  },
  {
    value: 'Tablette',
    label: 'Tablette',
  },
  {
    value: 'Ampulle',
    label: 'Ampulle',
  },
  {
    value: 'Kapsel',
    label: 'Kapsel',
  },
  {
    value: 'Tropfen',
    label: 'Tropfen',
  },
  {
    value: 'Lösung',
    label: 'Lösung',
  },
  {
    value: 'Retard-Tablette',
    label: 'Retard-Tablette',
  },
  {
    value: 'Pulver',
    label: 'Pulver',
  },
  {
    value: 'Pflaster',
    label: 'Pflaster',
  },
  {
    value: 'Pen',
    label: 'Pen',
  },
  {
    value: 'BrTabl',
    label: 'BrTabl',
  },
  {
    value: 'Supp',
    label: 'Supp',
  },
  {
    value: 'Creme',
    label: 'Creme',
  },
  {
    value: 'PLH',
    label: 'PLH',
  },
  {
    value: 'AuSalbe',
    label: 'AuSalbe',
  },
  {
    value: 'Gran',
    label: 'Gran',
  },
  {
    value: 'Paste',
    label: 'Paste',
  },
  {
    value: 'Gel',
    label: 'Gel',
  },
  {
    value: 'Beutel',
    label: 'Beutel',
  },
  {
    value: 'LuTabl',
    label: 'LuTabl',
  },
  {
    value: 'Susp',
    label: 'Susp',
  },
  {
    value: 'Sirup',
    label: 'Sirup',
  },
  {
    value: 'Tafel',
    label: 'Tafel',
  },
  {
    value: 'Spray',
    label: 'Spray',
  },
  {
    value: 'RetKaps',
    label: 'RetKaps',
  },
  {
    value: 'Amp',
    label: 'Amp',
  },
  {
    value: 'Konz',
    label: 'Konz',
  },
  {
    value: 'Dragees',
    label: 'Dragees',
  },
  {
    value: 'NasÖl',
    label: 'NasÖl',
  },
  {
    value: 'PLI',
    label: 'PLI',
  },
  {
    value: 'TRS',
    label: 'TRS',
  },
  {
    value: 'Flüss',
    label: 'Flüss',
  },
  {
    value: 'SubTabl',
    label: 'SubTabl',
  },
  {
    value: 'Tampon',
    label: 'Tampon',
  },
  {
    value: 'VagGel',
    label: 'VagGel',
  },
  {
    value: 'Flasche',
    label: 'Flasche',
  },
  {
    value: 'Klist',
    label: 'Klist',
  },
  {
    value: 'Test',
    label: 'Test',
  },
  {
    value: 'Spritze',
    label: 'Spritze',
  },
  {
    value: 'AuGel',
    label: 'AuGel',
  },
  {
    value: 'NA',
    label: 'NA',
  },
  {
    value: 'Gaze',
    label: 'Gaze',
  },
  {
    value: 'VagTabl',
    label: 'VagTabl',
  },
  {
    value: 'Saft',
    label: 'Saft',
  },
  {
    value: 'Impl',
    label: 'Impl',
  },
  {
    value: 'TSS',
    label: 'TSS',
  },
  {
    value: 'LOV',
    label: 'LOV',
  },
  {
    value: 'Bad',
    label: 'Bad',
  },
  {
    value: 'RMS',
    label: 'RMS',
  },
  {
    value: 'KauTabl',
    label: 'KauTabl',
  },
  {
    value: 'Tinktur',
    label: 'Tinktur',
  },
  {
    value: 'Tee',
    label: 'Tee',
  },
  {
    value: 'Kompr',
    label: 'Kompr',
  },
  {
    value: 'NSalbe',
    label: 'NSalbe',
  },
  {
    value: 'TSE',
    label: 'TSE',
  },
  {
    value: 'Kegel',
    label: 'Kegel',
  },
  {
    value: 'RSU',
    label: 'RSU',
  },
  {
    value: 'VagCrem',
    label: 'VagCrem',
  },
  {
    value: 'InhLös',
    label: 'InhLös',
  },
  {
    value: 'InhKaps',
    label: 'InhKaps',
  },
  {
    value: 'InhAmp',
    label: 'InhAmp',
  },
  {
    value: 'NasSpr',
    label: 'NasSpr',
  },
  {
    value: 'InhPulv',
    label: 'InhPulv',
  },
  {
    value: 'Emul',
    label: 'Emul',
  },
  {
    value: 'SMT',
    label: 'SMT',
  },
  {
    value: 'KombiPg',
    label: 'KombiPg',
  },
  {
    value: 'RetGran',
    label: 'RetGran',
  },
  {
    value: 'Schwamm',
    label: 'Schwamm',
  },
  {
    value: 'Inhalat',
    label: 'Inhalat',
  },
  {
    value: 'Pipette',
    label: 'Pipette',
  },
  {
    value: 'Schaum',
    label: 'Schaum',
  },
  {
    value: 'PLV',
    label: 'PLV',
  },
  {
    value: 'Tücher',
    label: 'Tücher',
  },
  {
    value: 'Tamp',
    label: 'Tamp',
  },
  {
    value: 'Pumpe',
    label: 'Pumpe',
  },
  {
    value: 'Sonstiges',
    label: 'Sonstiges',
  },
]
