const LOCAL_STORAGE_ID = 'LOCAL_STORAGE_ID'
export const LOCAL_STORAGE_ID_PREFIX = ''
export default class LocalStorageService<T> {
  store: string
  _idPrefix: string
  values: T[]
  constructor(store: string, idPrefix = LOCAL_STORAGE_ID_PREFIX) {
    this.store = store
    this._idPrefix = idPrefix

    this.localStorageGet = this.localStorageGet.bind(this)
    this.localStorageAdd = this.localStorageAdd.bind(this)
    this.localStorageDeleteAll = this.localStorageDeleteAll.bind(this)
  }

  newAbcId(): string {
    const id_counter = this._localStorageGet(LOCAL_STORAGE_ID)
    const newId = (+id_counter + 1).toString()
    this._localStorageSet(LOCAL_STORAGE_ID, newId)
    return this._idPrefix + newId
  }

  localStorageGet(): T[] {
    return this._localStorageGet(this.store)
  }

  private _localStorageGet(_store): T[] {
    let values: T[] = []
    const storageItems: string = localStorage.getItem(_store)
    if (storageItems) {
      values = [...JSON.parse(storageItems)]
      //values = reduceDistinct<T>(
      //  values.filter((value, index, self) => self.indexOf(value) === index)
      //)
    }
    return values
  }

  private _localStorageSet(_store: string, val: string | any, _default = '0') {
    try {
      if (val instanceof String) {
        localStorage.setItem(_store, val.toString())
      } else {
        localStorage.setItem(_store, JSON.stringify(val))
      }
    } catch {
      localStorage.setItem(this.store, JSON.stringify(_default))
    }
  }

  localStorageAdd(value: T): T {
    const oldValues = this.localStorageGet()
    const values = [...oldValues, value]
    localStorage.setItem(this.store, JSON.stringify(values))
    return value
  }

  localStorageDeleteAll(): void {
    this._localStorageSet(this.store, [])
  }
}
