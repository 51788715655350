import MaterialIcon from '../MaterialIcon/MaterialIcon'
import React, { RefObject, useRef, useState } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import FormDynamicComponent, {
  FormDynamicComponentProps,
} from '../../FormDynamic/FormDynamicComponent'

interface FieldFunctionSymbolButtonProps {
  icon: string
  onClick?: () => void
  component?: FormDynamicComponentProps
  hoverTitle?: string
  containerRef?: RefObject<HTMLElement>
  name: string
  invert?: boolean
}
export const FieldFunctionSymbolButton: React.FC<FieldFunctionSymbolButtonProps> = (
  props: FieldFunctionSymbolButtonProps
) => {
  const [showHover, setShowHover] = useState<boolean>(false)
  const ref = useRef(null)

  return (
    <span ref={ref} key={props.name + '-button'}>
      <span
        style={
          props.invert && {
            color: '#000',
          }
        }
        onClick={props.onClick}
        onMouseEnter={() => setShowHover(true)}
        onMouseLeave={() => setShowHover(false)}>
        <MaterialIcon icon={props.icon} />
      </span>
      {props.component && (
        <Overlay
          show={showHover}
          target={ref}
          placement="bottom-start"
          container={ref.current}
          containerPadding={20}>
          <Popover
            id={'popover-contained-' + props.name}
            style={{ maxWidth: '95.5%' }}>
            <Popover.Title as="h3">{props.hoverTitle}</Popover.Title>
            <Popover.Content>
              <FormDynamicComponent
                {...props.component}
                key={props.name + '-component'}
                justDisplay={true}
              />
            </Popover.Content>
          </Popover>
        </Overlay>
      )}
    </span>
  )
}
