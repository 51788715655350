import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import IAuth from '../../types/Auth'
import { Task } from '../../types/Task'
import { fillTaskFromParsed } from '../../utils/FormUtils'
import { AuthStore, withAuth } from '../../infrastructure/AuthProvider'
import { defaultTask } from '../../utils/TaskUtils'
import OrganizerModal, { OrganizerModalType } from './OrganizerModal'
import { FieldTerminAufgabeAlert } from './FieldTerminAufgabe'
import { Links } from './FieldFunctionInterfaces'
import { FieldTerminAufgabeStore } from './FieldTerminAufgabeProvider'
import { UserPermission } from '../../constants/UserPermission'

export interface FieldAufgabeProps extends Links {
  tasks: Task[]
  auth: IAuth
}

const FieldAufgabe: React.FC<FieldAufgabeProps> = ({
  links,
  tasks,
}: FieldAufgabeProps) => {
  const [task, setTask] = useState<Task>(null)
  const [show, setShow] = useState<boolean>(false)
  const [isCreatedAlert, setIsCreatedAlert] =
    useState<FieldTerminAufgabeAlert>(null)
  const fieldTerminAufgabeContext = useContext(FieldTerminAufgabeStore)
  const context = useContext(AuthStore)
  const { hasPermission } = context.handlers

  const handleShow = (): void => {
    setShow(true)
  }
  const handleAbort = (): void => {
    setShow(false)
  }

  const handleEdit = (): void => {
    fieldTerminAufgabeContext.removeDraft(isCreatedAlert.tempId)
    setShow(true)
  }

  const handleSubmit = (
    taskTitle: string,
    appointmentTitle: string,
    tempId: string
  ): void => {
    setIsCreatedAlert({
      taskTitle: taskTitle,
      appointmentTitle: appointmentTitle,
      tempId: tempId,
    })
  }

  useEffect(() => {
    setTask(fillTaskFromParsed(defaultTask, tasks))
  }, [tasks])

  if (!task) return null
  return (
    <div className="form-group">
      <OrganizerModal
        show={show}
        links={links}
        initialTasks={[task]}
        initialAppointments={null}
        onSubmit={handleSubmit}
        onAbort={handleAbort}
        onEdit={handleEdit}
        type={OrganizerModalType.FIELD_AUFGABE}
      />

      {!isCreatedAlert ? (
        <div>
          <label className="form-label">Aufgabe erstellen</label>
          <div>
            <Alert variant={'info'}>
              <Button
                variant="primary"
                onClick={handleShow}
                size="sm"
                className="mr-1">
                {task.title !== null &&
                task.title !== undefined &&
                task.title !== ''
                  ? task.title
                  : 'Erstellen'}
              </Button>{' '}
              Aufgabenentwurf nicht erstellt
            </Alert>
          </div>
        </div>
      ) : (
        <Alert variant={'success'}>
          Aufgabenentwurf erstellt: {isCreatedAlert.taskTitle}{' '}
          <Button
            variant="primary"
            onClick={handleEdit}
            disabled={!hasPermission(UserPermission.TASK_EDIT)}>
            Bearbeiten
          </Button>
        </Alert>
      )}
    </div>
  )
}

export default withAuth(FieldAufgabe)
