import React, { SyntheticEvent, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'

export interface RadioProps {
  name: string
  id: string
  value: string | null
  inputValue?: string | null
  disabled?: boolean
  onChange?: (key: string, value: unknown) => void
  formikValues: Record<string, unknown>
}

const MatrixRadio: React.FC<RadioProps> = ({
  name,
  value,
  disabled,
  onChange,
  formikValues,
}: RadioProps) => {
  const inputValue = formikValues ? formikValues[name] : null
  const [uniqueKey] = useState<string>(uuidv4())
  const radioId = `${name}_${value}_${uniqueKey}`
  return (
    <label
      htmlFor={uniqueKey}
      className="form-check position-absolute d-flex align-items-center justify-content-center"
      style={{
        cursor: !disabled ? 'pointer' : 'default',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}>
      <input
        className="form-check-input"
        type="radio"
        name={name}
        id={radioId}
        value={value}
        disabled={disabled}
        onChange={e => {
          e.persist()
          /* don't remove, it's preventing the warning of missing on hange handler*/
        }}
        onClick={
          /* needed for deselection */
          (event: SyntheticEvent<HTMLInputElement>): void => {
            let value = (event.target as HTMLInputElement).value

            if (value === inputValue) {
              value = ''
            }
            onChange(name, value)
          }
        }
        checked={inputValue === value}
      />
    </label>
  )
}

export default MatrixRadio
