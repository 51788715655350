import axios, { AxiosResponse } from 'axios'
import {
  API_FORWARDING_CREATE,
  API_FORWARDING_GET_RESOURCES_BY_FILTER,
  API_FORWARDING_UPDATE,
} from '../config/Paths'
import { getToken } from './Keycloak'

export function getActivePlanDefinitionsByIdentifier(): Promise<any> {
  const filterParameter = 'status=active' // &identifier=urn:panos-template:Type|' + templateType

  const url = new URL(API_FORWARDING_GET_RESOURCES_BY_FILTER)
  url.searchParams.append('resourceType', 'PlanDefinition')
  url.searchParams.append('filterParameter', filterParameter)

  return axios.get(url.toString(), {
    headers: {
      Authorization: `Bearer ` + getToken(),
    },
  })
}

export function updatePlanDefinition(
  planDefinition: Record<string, any>
): Promise<AxiosResponse<any>> {
  const url = new URL(API_FORWARDING_UPDATE + '/' + planDefinition.id)

  return axios.put(url.toString(), planDefinition, {
    headers: { Authorization: `Bearer ` + getToken() },
  })
}

export function createPlanDefinition(
  planDefinition: Record<string, any>
): Promise<AxiosResponse<any>> {
  const url = new URL(API_FORWARDING_CREATE)
  return axios.post(url.toString(), planDefinition, {
    headers: { Authorization: `Bearer ` + getToken() },
  })
}
