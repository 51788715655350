import React, { useCallback } from 'react'
import { Draft, RedCapDraft } from '../../../types/Draft'
import DraftRow from './DraftRow'
import TableGroupRow from '../../UI/TableGroup/TableGroupRow'
import { DraftKind } from '../../../types/DraftKind'
import { KIND_TO_NAME } from '../DraftContainer'
import { Appointment } from '../../../types/Appointment'
import { Task } from '../../../types/Task'

interface DraftTableProps<T> {
  groups: Record<DraftKind, Record<string, Record<string, Record<string, T[]>>>>
  selectedRowId?: string

  onClick: (draft: T) => void
  onDeleteRow: (draft: T) => void
}

const DraftTable: React.FC<
  DraftTableProps<Draft<Task | Appointment | RedCapDraft>>
> = <T extends Draft<Task | Appointment | RedCapDraft>>({
  selectedRowId,
  groups,
  onClick,
  onDeleteRow,
}: DraftTableProps<T>) => {
  const renderSimpleRow = useCallback(
    (
      rows: Draft<Task | Appointment | RedCapDraft>[],
      _selected: boolean = null,
      isSubRow = false,
      className = '',
      key: string
    ) => {
      return rows.map(row => {
        if (row) {
          const selected =
            _selected ?? (selectedRowId && selectedRowId === row.documentId)
          if (row instanceof Draft) {
            return (
              <DraftRow
                key={'key-' + row.documentId + key}
                className={className}
                isSubRow={isSubRow}
                draft={row}
                selected={selected}
                onClick={() => onClick(row as T)}
                onDelete={() => onDeleteRow(row as T)}
              />
            )
          }
        }
        return null
      })
    },
    [selectedRowId, onClick, onDeleteRow]
  )

  const renderRows = useCallback(
    (
      draftGroups: Record<
        string,
        Record<
          string,
          Record<string, Draft<Task | Appointment | RedCapDraft>[]>
        >
      >
    ) => {
      return Object.values(draftGroups)
        .map((eventGroups, i) => {
          return Object.values(eventGroups).map(value => {
            return Object.values(value).map((drafts, j) => {
              return renderSimpleRow(drafts, false, false, '', i + '_' + j)
            })
          })
        })
        .flat()
    },
    [renderSimpleRow]
  )

  return (
    <table className="table table-panos table-hover">
      <thead>
        <tr>
          <th></th>
          <th>Titel</th> <th>Patienten</th>
          <th>Datum</th>
          <th>Aktion</th>
        </tr>
      </thead>
      <tbody>
        {groups
          ? Object.entries(groups).map(([kind, group], i) => {
              return (
                <React.Fragment key={'kind-' + i}>
                  <TableGroupRow icon={'edit'} colspan={6}>
                    {KIND_TO_NAME[kind]}
                  </TableGroupRow>
                  {group ? renderRows(group) : null}
                </React.Fragment>
              )
            })
          : null}
      </tbody>
    </table>
  )
}

export default DraftTable
