import React, { ChangeEvent, useEffect, useState } from 'react'
import FormErrorMessage from '../FormError/FormErrorMessage'
import { noWriteErrorMessage } from '../../../../utils/FormUtils'
import { v4 as uuidv4 } from 'uuid'

interface FormInputProps {
  name: string
  onChange?: (value: string) => void
  onChangeEvent?: (event: ChangeEvent<HTMLInputElement>) => void
  label?: string | JSX.Element
  placeholder?: string
  required?: boolean
  value?: string | null
  info?: any
  type?: string
  className?: string
  pattern?: string
  maxLength?: number
  minLength?: number
  disabled?: boolean
  error?: string
  touched?: boolean
  hidden?: boolean
  readOnlyMode?: boolean
  instant?: boolean
}

/**
 * Use this component with or without formik cotnext.
 *
 * Required props without Formik are:
 * -- name
 * -- value
 * -- onChange
 */
const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  placeholder,
  required,
  value,
  info,
  type,
  className,
  pattern,
  disabled,
  error,
  hidden,
  maxLength,
  minLength,
  onChange,
  onChangeEvent,
  readOnlyMode,
  instant = true,
}: FormInputProps) => {
  const inputValue =
    (value !== null && value !== undefined) ||
    (value !== null && value !== undefined && !isNaN(value as any))
      ? value
      : ''

  const [uniqueKey] = useState<string>(uuidv4())

  const [text, setText] = useState<string>(inputValue)

  useEffect(() => {
    setText(inputValue)
  }, [setText, inputValue])

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (readOnlyMode === true) {
      noWriteErrorMessage()
      return
    }
    if (onChangeEvent) {
      onChangeEvent(event)
    } else if (onChange) {
      let value = text
      if (instant) value = event.target.value
      if (
        value !== null &&
        typeof value === 'string' &&
        value.trim().length === 0
      ) {
        value = null
      }
      onChange(value)
    }
  }

  const cssClasses = className ? [className] : []
  if (error) cssClasses.push('position-relative')
  if (hidden) cssClasses.push('d-none')
  if (error) cssClasses.push('validate-bad')

  return (
    <div className={`form-group ${cssClasses.join(' ')}`}>
      {label ? (
        <label className="form-label">
          {label}
          {required ? <span style={{ color: 'red' }}> *</span> : null}
        </label>
      ) : null}
      <input
        id={uniqueKey}
        type={type}
        name={name}
        disabled={disabled || null}
        required={required}
        value={text}
        autoComplete={'off'}
        className={`form-control ${error ? 'is-invalid' : ''}`}
        placeholder={placeholder}
        pattern={pattern || null}
        maxLength={maxLength || null}
        minLength={minLength || null}
        onBlur={onChangeHandler}
        onChange={e => {
          setText(e.target.value)
          instant && onChangeHandler(e)
        }}
      />
      {info}
      <FormErrorMessage error={error} />
    </div>
  )
}

export default FormInput
