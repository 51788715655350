import { REDCapFormNames } from '../components/Forms/REDCapFormNames'
import { ApiError } from '../types/ApiError'
import {
  EventGroup,
  EventSuperGroup,
  FieldFunctionAttachment,
  FormInformations,
  IFormSharedValues,
  LastModule,
  MedicalInstrument,
  SmartVariablesInfo,
} from '../components/Forms/FormInterfaces'
import ApiServiceBase from './ApiServiceBase'
import {
  API_PATIENT_ARCHIVED_EVENTS,
  API_PATIENT_NEW_EVENTS,
  API_PATIENT_REDCAP_LINKED_EVENT,
  API_PATIENT_REDCAP_LAST_EVENT,
  API_PATIENT_REDCAP_MEDICALINSTRUMENTS_SORTED,
  API_PATIENT_REDCAP_LINKED_NEW_EVENT,
  API_REDCAP_EXPORT_METADATA,
  API_REDCAP_EXPORT_RECORDS,
  API_REDCAP_GET_FORM_INFORMATIONS,
  API_REDCAP_GET_SIGN_STATUS,
  API_REDCAP_IMPORT_RECORDS,
  API_REDCAP_LAST_MODULE_INSTANCE,
  API_REDCAP_PREFETCH_PIPING,
  API_REDCAP_SAVE_FORMS,
  API_REDCAP_SYNC_METADATA,
  API_REDCAP_VALIDATE_METADATA,
  PATIENT_REGISTRATION,
} from '../config/Paths'
import { ApiResponse } from '../types/ApiResponse'
import { FormDynamicComponentProps } from '../components/FormDynamic/FormDynamicComponent'
import {
  RedCapSaveCombination,
  RedCapSaveMethods,
} from '../constants/RedCapSaveMethods'
import { RedCapSave, RedCapSaveResponse } from '../types/RedCapSave'
import { MedicalRoundSignStatus } from '../types/MedicalRoundSignStatus'

export interface IRedcapInstrument {
  instrument_name: string
  instrument_label: string
}

interface IREDCapImportRecords {
  data: string
  overwriteBehavior: string
  forms?: Array<string>
  events?: Array<string>
  formLabel?: string
}

export interface RedCapDTO {
  fields?: Array<string>
  forms?: Array<string>
  arms?: Array<string>
  events?: Array<string>
  records?: Array<string>
  redcapDataType?: string
  data?: string
  rawOrLabel?: string
  rawOrLabelHeaders?: string
  exportCheckboxLabel?: boolean
  exportSurveyFields?: boolean
  exportDataAccessGroups?: boolean
  filterLogic?: string
  includeRecordId?: boolean
  overwriteBehavior?: string
  forceAutoNumber?: boolean
  dateFormat?: string
  returnContent?: string
  repeatInstance?: Array<string>
  instrumentLabel?: string
  eventLabel?: string
  smartVariables?: SmartVariablesInfo[]
  saveCombination?: RedCapSaveCombination
  attachment?: FieldFunctionAttachment
  editedModules?: Array<string>
  sourceRepeatInstance?: string
}

class REDCapService extends ApiServiceBase {
  async registerPatient(
    values: Record<string, unknown>
  ): Promise<ApiResponse<unknown>> {
    const url: URL = new URL(PATIENT_REGISTRATION)
    const body: RedCapDTO = {
      data: JSON.stringify([{ ...values, redcap_repeat_instance: '1' }]),
    }
    return await this.post<RedCapDTO, null>(url, null, body)
  }

  getREDCapFormURL(formName: REDCapFormNames, recordId?: string): URL {
    switch (formName) {
      case REDCapFormNames.Erstanmeldung: {
        const url = new URL(PATIENT_REGISTRATION)
        recordId && url.searchParams.append('redCapRecordId', recordId)
        return url
      }
    }
  }

  async getREDCapFormSharedValues(
    formName: REDCapFormNames,
    recordId?: string
  ): Promise<ApiResponse<ApiError | IFormSharedValues>> {
    const url = this.getREDCapFormURL(formName, recordId)
    return await this.get<null, IFormSharedValues>(url)
  }

  async getREDCapFormSavedValues(
    formName: REDCapFormNames | string,
    recordId?: string,
    repeatInstance?: string,
    event?: string
  ): Promise<ApiResponse<ApiError | Record<string, unknown>[]>> {
    const url = new URL(API_REDCAP_EXPORT_RECORDS)
    const body: RedCapDTO = {
      forms: [formName],
      records: [recordId],
    }
    if (repeatInstance) {
      body.repeatInstance = [repeatInstance]
    }
    if (event) {
      body.events = [event]
    }
    return await this.post<RedCapDTO, Record<string, unknown>[]>(
      url,
      null,
      body
    )
  }

  async getREDCapFormMeta(
    formName: REDCapFormNames | string,
    instrument_label?: string,
    recordId?: string,
    repeatInstance?: string,
    event?: string
  ): Promise<ApiResponse<ApiError | FormDynamicComponentProps[]>> {
    // repeatInstance !== undefined && repeatInstance !== null
    const body: RedCapDTO = {
      forms: [formName],
      instrumentLabel: instrument_label,
      records: recordId ? [recordId] : null,
      repeatInstance: [repeatInstance],
      events: [event],
    }
    const url = new URL(API_REDCAP_EXPORT_METADATA)
    return await this.post<RedCapDTO, FormDynamicComponentProps[]>(
      url,
      null,
      body
    )
  }

  async importRecords(
    data: Record<string, unknown>,
    forms?: Array<string>,
    events?: Array<string>,
    formLabel?: string
  ): Promise<ApiResponse<ApiError>> {
    const body: IREDCapImportRecords = {
      data: JSON.stringify([data]),
      overwriteBehavior: 'overwrite',
      forms: forms,
      events: events,
      formLabel: formLabel,
    }
    const url = new URL(API_REDCAP_IMPORT_RECORDS)
    return await this.post<IREDCapImportRecords, null>(url, null, body)
  }

  async saveForms(
    saveForms: RedCapSave
  ): Promise<ApiResponse<ApiError | RedCapSaveResponse>> {
    const url = new URL(API_REDCAP_SAVE_FORMS)
    return await this.post<RedCapSave, RedCapSaveResponse>(url, null, saveForms)
  }

  async getCurrentMedicalInstrumentsByHash(
    patientId: string,
    event: string,
    hash: string
  ): Promise<ApiResponse<ApiError | MedicalInstrument[]>> {
    const url = new URL(
      API_PATIENT_REDCAP_LINKED_EVENT.replace(':id', patientId)
    )
    url.searchParams.append('event', event)
    url.searchParams.append('hash', hash)
    return await this.get<null, MedicalInstrument[]>(url)
  }

  async createNewMedicalInstrumentsByEvent(
    patientId: string,
    event: string
  ): Promise<ApiResponse<ApiError | MedicalInstrument[]>> {
    const url = new URL(
      API_PATIENT_REDCAP_LINKED_NEW_EVENT.replace(':id', patientId)
    )
    url.searchParams.append('event', event)
    return await this.get<null, MedicalInstrument[]>(url)
  }

  async getSortedFormsForPatient(
    patientId: string,
    event: string
  ): Promise<ApiResponse<ApiError | MedicalInstrument[]>> {
    const url = new URL(
      API_PATIENT_REDCAP_MEDICALINSTRUMENTS_SORTED.replace(':id', patientId)
    )
    url.searchParams.append('event', event)
    return await this.get<null, MedicalInstrument[]>(url)
  }

  prefetchPiping = async (): Promise<ApiResponse<any | ApiError>> => {
    const url = new URL(API_REDCAP_PREFETCH_PIPING)
    return await this.get<null, any>(url)
  }

  async getFormInformations(
    forms?: REDCapFormNames | REDCapFormNames[] | string | string[],
    instrument_label?: string,
    recordId?: string,
    repeatInstance?: string,
    event?: string
  ): Promise<ApiResponse<ApiError | FormInformations[]>> {
    let formsToFetch = forms
    if (forms) {
      formsToFetch = Array.isArray(forms) ? forms : [forms]
    }
    const body: RedCapDTO = {
      forms: formsToFetch as string[],
      instrumentLabel: instrument_label,
      records: recordId ? [recordId] : null,
      repeatInstance: repeatInstance ? [repeatInstance] : null,
      events: event ? [event] : null,
    }
    const url = new URL(API_REDCAP_GET_FORM_INFORMATIONS)
    return await this.post<RedCapDTO, FormInformations[]>(url, null, body)
  }

  async syncMetadata(): Promise<ApiResponse<ApiError | Map<string, string>>> {
    const url = new URL(API_REDCAP_SYNC_METADATA)
    return await this.post<null, Map<string, string>>(url, null, null)
  }

  async getSignStatus(
    recordId: string,
    event: string,
    saveMethod: RedCapSaveMethods,
    hash?: string
  ): Promise<ApiResponse<MedicalRoundSignStatus | ApiError>> {
    const url = new URL(API_REDCAP_GET_SIGN_STATUS)
    url.searchParams.append('recordId', recordId)
    url.searchParams.append('saveMethod', saveMethod)
    url.searchParams.append('event', event)

    if (hash) url.searchParams.append('hash', hash)
    return await this.get<null, MedicalRoundSignStatus>(url)
  }

  async validateMetadata(): Promise<ApiResponse<ApiError | Array<string>>> {
    const url = new URL(API_REDCAP_VALIDATE_METADATA)
    return await this.post<null, Array<string>>(url, null, null)
  }

  async getLastInstanceValues(
    lastModule: LastModule,
    recordId: string
  ): Promise<ApiResponse<ApiError | Record<string, unknown>[]>> {
    const url = new URL(API_REDCAP_LAST_MODULE_INSTANCE)
    url.searchParams.append('recordId', recordId)
    return await this.post<LastModule, Record<string, unknown>[]>(
      url,
      null,
      lastModule
    )
  }

  async getArchivedEventSuperGroups(
    patientId: string
  ): Promise<ApiResponse<ApiError | EventSuperGroup[]>> {
    const url = new URL(API_PATIENT_ARCHIVED_EVENTS.replace(':id', patientId))
    return await this.get<null, EventSuperGroup[]>(url)
  }

  async getNewEventGroups(
    patientId: string
  ): Promise<ApiResponse<ApiError | EventGroup[]>> {
    const url = new URL(API_PATIENT_NEW_EVENTS.replace(':id', patientId))
    return await this.get<null, EventGroup[]>(url)
  }

  async getLastEvent(
    patientId: string
  ): Promise<ApiResponse<ApiError | EventGroup>> {
    const url = new URL(API_PATIENT_REDCAP_LAST_EVENT.replace(':id', patientId))
    return await this.get<null, EventGroup>(url)
  }
}

export default REDCapService
