import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormDynamicComponentProps } from '../FormDynamic/FormDynamicComponent'
import FormDynamicComponentList from '../FormDynamic/FormDynamicComponentList'
import FormikValidationBranchingContext from './ValidationBranchingContext'
import {
  AccordionContext,
  Button,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import Accordion from '../UI/VisualIndicatorAccordion'
import { EventStore } from '../../infrastructure/EventProviderInterfaces'
import {
  deepCopyState,
  generateSetFieldValue,
  isEqualCustomizer,
  setShow,
  updateAll,
} from 'src/utils/EvaluateBranchingUtils'
import {
  clearValues,
  getVariableNamesWithSubcomponents,
  noWriteErrorMessage,
  setSubValues,
  translateRedcapToFrontend,
} from 'src/utils/FormUtils'
import { isEqualWith } from 'lodash'
import REDCapService from 'src/services/REDCapService'
import { RedCapSaveMethods } from 'src/constants/RedCapSaveMethods'
import { LastModule } from '../Forms/FormInterfaces'
import { NotificationManager } from 'react-notifications'
import { APIResult } from '../../constants/APIResult'

const FieldInstrument: React.FC<FormDynamicComponentProps> = (
  props: FormDynamicComponentProps
) => {
  const eventContext = useContext(EventStore)
  const { errors, setClickedModule, values, allMeta, event } = eventContext
  const anyInvalidValue = props.subComponents.find(
    component => errors[component.name]
  )
  const [originvarField] = useState(
    props.subComponents.find(it => it.name.endsWith('originvar'))?.name
  )

  useEffect(() => {
    if (!originvarField) {
      console.warn(
        `No originvar field in '${props.annotation.instrument_name}'!`
      )
    }
    if (
      originvarField &&
      eventContext.edited[props.formName][props.annotation.instrument_name] &&
      !eventContext.values[originvarField]
    )
      eventContext.setFieldValue(originvarField, props.name)
  }, [
    originvarField,
    eventContext,
    eventContext.setFieldValue,
    eventContext.edited,
    props.formName,
    props.annotation?.instrument_name,
    props.name,
  ])

  const [showConfirmModule, setShowConfirmModule] = useState<boolean>(false)
  const [valuesUpdate, setValueUpdate] = useState<Record<string, unknown>>({})
  const [loading, setLoading] = useState<boolean>(false)

  const fetchLastInstanceValues = useCallback(async () => {
    const moduleEvent = props?.moduleEvent
      ? props.moduleEvent
      : props.subComponents
      ? props.subComponents[0]?.moduleEvent
      : null
    const moduleName = props?.moduleName
      ? props.moduleName
      : props.subComponents
      ? props.subComponents[0]?.moduleName
      : null
    const lastModuleMeta: LastModule = {
      formName: props.formName,
      eventName: props.eventName,
      moduleEvent: moduleEvent,
      moduleName: moduleName,
    }

    const loadedValuesResponse =
      await new REDCapService().getLastInstanceValues(
        lastModuleMeta,
        event.recordId
      )
    if (
      loadedValuesResponse.Result === APIResult.FAILURE ||
      !loadedValuesResponse.Response
    ) {
      NotificationManager.error('Fehler beim Laden der letzten Modulinstanz.')
      console.log(loadedValuesResponse.Response)
    }

    const sharedValues = loadedValuesResponse.Response[0]

    const moduleValues = translateRedcapToFrontend(
      props.subComponents,
      sharedValues,
      RedCapSaveMethods.EDIT,
      [lastModuleMeta]
    )
    const newValues = deepCopyState(values)
    return { moduleValues, newValues }
  }, [
    event.recordId,
    props.eventName,
    props.formName,
    props.moduleEvent,
    props.moduleName,
    props.subComponents,
    values,
  ])

  const [lastInstanceValues, setLastInstanceValues] = useState<{
    moduleValues: Record<string, unknown>
    newValues: Record<string, unknown>
  }>()

  useEffect(() => {
    setLoading(true)
    ;(async () => {
      try {
        if (!lastInstanceValues && !loading) {
          const it = await fetchLastInstanceValues()

          if (it) setLastInstanceValues(it)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(true)
      }
    })()
  }, [fetchLastInstanceValues, lastInstanceValues, loading])

  const callLoadLastInstanceModal = useCallback(async () => {
    //todo exclude readonly? if we get the feedback from the mds.
    if (props.readOnlyMode === true) {
      noWriteErrorMessage()
      return
    }

    try {
      const { moduleValues, newValues } = await fetchLastInstanceValues()
      setSubValues(moduleValues, newValues)
      const show = {}
      const [updatedValues] = updateAll(
        newValues,
        allMeta,
        show,
        setShow,
        generateSetFieldValue
      )
      const confirmIsNeeded = !isEqualWith(
        values,
        updatedValues,
        isEqualCustomizer
      )
      if (confirmIsNeeded) {
        setShowConfirmModule(true)
        setValueUpdate(updatedValues)
      }
    } catch (e) {
      NotificationManager.error('Fehler beim Laden der letzten Modulinstanz.')
      console.error('error getting last module data', e)
    }
  }, [props.readOnlyMode, fetchLastInstanceValues, allMeta, values])

  const callDefaultValuesModal = useCallback(() => {
    if (props.readOnlyMode === true) {
      noWriteErrorMessage()
      return
    }

    const newValues = deepCopyState(values)
    const usedVariableNames = getVariableNamesWithSubcomponents(
      props.subComponents
    )
    //todo exclude readonly? if we get the feedback from the mds.
    clearValues(usedVariableNames, newValues, allMeta)
    const show = {}
    const [updatedValues] = updateAll(
      newValues,
      allMeta,
      show,
      setShow,
      generateSetFieldValue
    )

    const confirmIsNeeded = !isEqualWith(
      values,
      updatedValues,
      isEqualCustomizer
    )
    if (confirmIsNeeded) {
      setShowConfirmModule(true)
      setValueUpdate(updatedValues)
    }
  }, [values, allMeta, props.readOnlyMode, props.subComponents])

  const continueAction = () => {
    eventContext.setValues(valuesUpdate)
    setShowConfirmModule(false)
  }

  return (
    <FormikValidationBranchingContext.Consumer>
      {context => (
        <span>
          <Modal show={showConfirmModule}>
            <Modal.Body>
              Achtung, es werden Eingabewerte überschrieben oder neu gesetzt.
              Soll trotzdem fortgefahren werden?
            </Modal.Body>
            <Modal.Footer>
              <button
                className={'btn btn-secondary mr-1'}
                onClick={continueAction}>
                Fortfahren
              </button>
              <button
                className={'btn btn-secondary mr-1'}
                onClick={() => {
                  setShowConfirmModule(false)
                }}>
                Abbrechen
              </button>
            </Modal.Footer>
          </Modal>
          <Accordion defaultActiveKey="0">
            <AccordionContext.Consumer>
              {currentEventKey => (
                <Card
                  style={{
                    overflow:
                      currentEventKey === props.annotation.instrument_name
                        ? 'visible'
                        : null,
                  }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    variant="link"
                    eventKey={props.annotation.instrument_name}
                    className={anyInvalidValue ? 'text-danger' : ''}>
                    {props.annotation.instrument_label}
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    eventKey={props.annotation.instrument_name}
                    onEntering={() => setClickedModule(props.name)}>
                    <Card.Body>
                      {/* todo show if any component is not readonly if we get the feedback from the mds.*/}
                      <div className="d-flex justify-content-center">
                        <div className="pr-2 pb-2 flex-fill">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="last-instance">
                                Lädt die letzte Modulinstanz, falls vorhanden.
                              </Tooltip>
                            }>
                            <Button
                              className="d-flex align-items-center justify-content-center event-action-button m-0"
                              onClick={callLoadLastInstanceModal}>
                              Letzte Instanz
                            </Button>
                          </OverlayTrigger>
                        </div>
                        <div className="pl-2 pb-2 flex-fill">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="last-instance">
                                Setzt Standardwerte und überschreibt Änderungen!
                              </Tooltip>
                            }>
                            <Button
                              className="d-flex align-items-center justify-content-center event-action-button m-0"
                              onClick={callDefaultValuesModal}>
                              Standardwerte
                            </Button>
                          </OverlayTrigger>
                        </div>
                      </div>
                      <FormDynamicComponentList
                        components={props.subComponents}
                        handleChange={props.handleChange}
                        patientId={props.patientId}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )}
            </AccordionContext.Consumer>
          </Accordion>
        </span>
      )}
    </FormikValidationBranchingContext.Consumer>
  )
}

export default FieldInstrument
