import React, { useContext, useEffect, useState } from 'react'
import MedicalRoundInstrument from './MedicalRoundInstrument'
import MultipleFormButtons from './MultipleFormButtons'
import { APIResult } from '../../constants/APIResult'
import REDCapService from '../../services/REDCapService'
import { AuthContext, withAuth } from '../../infrastructure/AuthProvider'
import { SIGNABLE_EVENTS } from '../../config/Constants'
import { MedicalRoundSignStatus } from '../../types/MedicalRoundSignStatus'
import { AccordionContext, Button, Card, Modal } from 'react-bootstrap'
import Accordion from '../UI/VisualIndicatorAccordion'
import Loader from '../UI/Spinner/Loader'
import { ConfirmDialogue } from '../UI/Dialogues/ConfirmDialogue'
import { withEventProvider } from '../../infrastructure/EventProvider'
import FormikValidationBranchingContext from '../FieldFunctionComponents/ValidationBranchingContext'
import { RedCapSaveStatus } from '../../constants/RedCapSaveMethods'
import { NotificationManager } from 'react-notifications'
import { EventStore } from 'src/infrastructure/EventProviderInterfaces'

export interface MedicalRoundInstrumentsProps extends AuthContext {
  activeInstrumentIndex?: string //Open Form with this index by default
  isArchived?: boolean
  readOnlyMode?: boolean
  alwaysRenderForms?: boolean
}

const MedicalRoundInstruments: React.FC<MedicalRoundInstrumentsProps> = ({
  isArchived,
  activeInstrumentIndex,
  readOnlyMode,
  alwaysRenderForms,
}: MedicalRoundInstrumentsProps) => {
  const eventContext = useContext(EventStore)
  const {
    handleSubmit,
    triggerAutosave,
    closeSidebar,
    saveMethod,
    forms,
    isSaving,
    event,
    firstRender,
    setClickedForm,
    syncModules,
    showSyncModule,
    closeSyncModule,
    showConfirmModule,
    closeConfirmModule,
    continueAction,
  } = eventContext

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDeterminingSignatureAndRank, setIsDeterminingSignatureAndRank] =
    useState<boolean>(false)

  const [showSavingModal, setShowSavingModal] = useState<boolean>(isSaving)

  useEffect(() => {
    setShowSavingModal(isSaving)
    setIsLoading(isSaving)
  }, [isSaving])

  useEffect(() => {
    setIsLoading(false)
  }, [forms])
  // const [formSavedSuccessfully, setFormSavedSuccessfully] = useState<boolean>(
  //   false
  // )
  const [signStatus, setSignStatus] = useState<MedicalRoundSignStatus>(null)
  const handleTriggerSubmit = (
    validate: boolean,
    saveStatus: RedCapSaveStatus,
    assignTo?: string,
    sign?: boolean
  ) => {
    setIsLoading(true)
    handleSubmit(validate, saveStatus, assignTo, sign)
  }

  useEffect(() => {
    async function getVidierung() {
      const { instruments } = eventContext.event
      setIsDeterminingSignatureAndRank(true)
      const redCapService = new REDCapService()
      const signStatusResponse = await redCapService.getSignStatus(
        instruments[0].recordId,
        instruments[0].event_name,
        saveMethod,
        instruments[0].hash_value
      )
      if (signStatusResponse.Result === APIResult.SUCCESS) {
        const status = signStatusResponse.Response as MedicalRoundSignStatus
        setSignStatus(status)
        setIsDeterminingSignatureAndRank(false)
      } else {
        setSignStatus(MedicalRoundSignStatus.FORBIDDEN)
        setIsDeterminingSignatureAndRank(false)
        NotificationManager.error(
          'Vidierungsstatus konnte nicht ermittelt werden'
        )
      }
    }

    if (eventContext.event) {
      const { instruments } = eventContext.event

      if (
        instruments?.length > 0 &&
        SIGNABLE_EVENTS.includes(instruments[0]?.event_name)
      ) {
        getVidierung()
      }
    }
  }, [eventContext.event, saveMethod])

  if (!event && !signStatus) return null
  //const { instruments, patientId, name, hash_value } = eventContext.event
  if (!event.instruments && !signStatus) return null
  return (
    <span>
      <Modal show={showSavingModal}>
        <Modal.Body>
          {event.instruments.length > 1 ? (
            <div>
              {event.instruments.length} Formulare werden gespeichert, bitte
              warten.
            </div>
          ) : (
            <div>
              {event.instruments.length} Formular wird gespeichert, bitte
              warten.
            </div>
          )}
          <Loader />
          <button
            className={'btn btn-secondary mr-1'}
            onClick={() => {
              setShowSavingModal(false)
              // setIsLoading(false)
            }}>
            Ausblenden
          </button>
        </Modal.Body>
      </Modal>
      <Modal show={isDeterminingSignatureAndRank}>
        <Modal.Body>
          <Loader msg="Status der Vidierung wird ermittelt, bitte warten." />
          <ConfirmDialogue
            callback={() => {
              setIsDeterminingSignatureAndRank(false)
            }}
            question={
              'Hinweisfenster schließen, der Vidierungstatus der Visite wird im Hintergrund ' +
              'weiter ermittelt.'
            }
            confirmText="Ja">
            <Button className={'btn btn-secondary mr-1'}>Schließen</Button>
          </ConfirmDialogue>
        </Modal.Body>
      </Modal>
      <Modal show={showSyncModule}>
        <Modal.Body>
          Möchten Sie die aktuellen anamnestischen Werte für das Procedere
          übernehmen?
          <Modal.Footer>
            <button className={'btn btn-secondary mr-1'} onClick={syncModules}>
              Synchronisieren
            </button>
            <button
              className={'btn btn-secondary mr-1'}
              onClick={closeSyncModule}>
              Nein
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      <Modal show={showConfirmModule}>
        <Modal.Body>
          Achtung, mit dem Klick auf Fortfahren werden in Abhängigkeit stehende
          Felder ausgeblendet und bereits ausgefüllte Daten gelöscht.
        </Modal.Body>
        <Modal.Footer>
          <button className={'btn btn-secondary mr-1'} onClick={continueAction}>
            Fortfahren
          </button>
          <button
            className={'btn btn-secondary mr-1'}
            onClick={closeConfirmModule}>
            Abbrechen
          </button>
        </Modal.Footer>
      </Modal>
      <div className="mb-3">
        <Accordion defaultActiveKey={activeInstrumentIndex ?? '0'}>
          <AccordionContext.Consumer>
            {currentEventKey => (
              <>
                {event.instruments.map((medicalInstrument, index) => {
                  let headerClassName = ''
                  if (forms.length && forms[index]?.valid === true)
                    headerClassName = 'text-success'
                  if (forms.length && forms[index]?.valid === false)
                    headerClassName = 'text-danger'

                  return (
                    <React.Fragment key={index}>
                      <Card
                        key={medicalInstrument.instrument_label}
                        style={{
                          overflow:
                            currentEventKey === index.toString()
                              ? 'visible'
                              : null,
                        }}>
                        <Accordion.Toggle
                          as={Card.Header}
                          variant="link"
                          eventKey={index.toString()}
                          className={headerClassName}
                          onClick={() =>
                            setClickedForm(medicalInstrument.instrument_name)
                          }>
                          <>{medicalInstrument.instrument_label} </>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index.toString()}>
                          <Card.Body>
                            {eventContext.forms && eventContext.forms.length ? (
                              <>
                                <FormikValidationBranchingContext.Provider
                                  value={
                                    eventContext?.forms?.[index]
                                      ?.branchingValidator /*.listen(isSubmitting, isValidating) */
                                  }>
                                  {/**  currentEventKey === index.toString()**/}
                                  {firstRender ||
                                  alwaysRenderForms ||
                                  currentEventKey === index.toString() ? (
                                    <MedicalRoundInstrument
                                      form={eventContext.forms[index]}
                                      key={index}
                                      index={index}
                                      closeSidebar={closeSidebar}
                                      showSingleSubmit={false}
                                      isArchived={isArchived}
                                      instrument={medicalInstrument}
                                      readOnlyMode={readOnlyMode}
                                    />
                                  ) : null}
                                </FormikValidationBranchingContext.Provider>
                              </>
                            ) : (
                              <Loader msg="Formulardaten werden geladen" />
                            )}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </React.Fragment>
                  )
                })}
                {readOnlyMode !== true && (
                  <MultipleFormButtons
                    event={eventContext.event.name}
                    signStatus={signStatus}
                    isLoading={isLoading}
                    onTriggerSubmit={handleTriggerSubmit}
                    onTriggerAutosave={triggerAutosave}
                    onClose={closeSidebar}
                  />
                )}
              </>
            )}
          </AccordionContext.Consumer>
        </Accordion>
      </div>
    </span>
  )
}

export default withAuth(withEventProvider(MedicalRoundInstruments))
