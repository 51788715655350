import ApiServiceBase, { API_RETURN } from '../ApiServiceBase'
import {
  API_TASKS,
  API_TASKS_ADD_USER,
  API_TASKS_COMPLETE,
} from '../../config/Paths'
import { ApiResponse } from '../../types/ApiResponse'
import { Task } from '../../types/Task'
import { ApiError } from '../../types/ApiError'
import { TaskFilterParams } from '../../types/TaskFilterParams'
import { getEnumKeyByEnumValue, parseUnknownToDate } from '../../utils/Utils'
import { TaskSortingParameter } from './TaskSortingParamter'
import { Priority } from '../../constants/Priority'
import { TaskStatus } from '../../constants/TaskStatus'
import moment from 'moment'
import UserService from '../UserService'
import { isSuccess } from '../../utils/ApiUtils'
import { Practitioner } from '../../types/Practitioner'
import { TaskCategories } from '../../constants/TaskCategories'
import { PanosInstitution } from '../../constants/PanosInstitutions'

class TaskService extends ApiServiceBase {
  getTasks(
    filterParams: TaskFilterParams = null,
    sorting: TaskSortingParameter = null,
    quickFilterParams: PanosInstitution[] = null
  ): Promise<ApiResponse<Task[] | ApiError>> {
    const url = new URL(API_TASKS)

    if (filterParams) {
      if (filterParams.userId) {
        url.searchParams.append('user', filterParams.userId)
      }
      if (filterParams.role) {
        url.searchParams.append('role', filterParams.role.toString())
      }
      if (filterParams.category) {
        url.searchParams.append('category', filterParams.category)
      }
      if (filterParams.patientId) {
        url.searchParams.append('patient', filterParams.patientId)
      }

      if (filterParams.status) {
        url.searchParams.append('status', filterParams.status.toString())
      }
      if (filterParams.priority) {
        url.searchParams.append('priority', filterParams.priority.toLowerCase())
      }
      if (sorting) {
        url.searchParams.append(
          'sortedBy',
          getEnumKeyByEnumValue(TaskSortingParameter, sorting)
        )
      }
    }

    if (quickFilterParams) {
      for (const institution of quickFilterParams) {
        console.log(institution)
        url.searchParams.append('includePanosPhysiciansFrom', institution)
      }
    }

    return this.get<null, Task[]>(url)
  }

  completeTask(task: Task): Promise<ApiResponse<ApiError | string>> {
    return this.post<null, string>(
      new URL(API_TASKS_COMPLETE + `/${task.id}`),
      null,
      null,
      API_RETURN.text
    )
  }

  getTask(taskId: string): Promise<ApiResponse<Task | ApiError>> {
    return this.get<null, Task>(new URL(API_TASKS + `/${taskId}`))
  }

  postTask(task: Task): Promise<ApiResponse<Task | ApiError>> {
    return this.post<Task, Task>(
      new URL(API_TASKS),
      null,
      this.convertTaskToBackendTask(task)
    )
  }

  putTask(task: Task): Promise<ApiResponse<Task | ApiError>> {
    return this.put<Task>(
      new URL(API_TASKS + `/${task.id}`),
      this.convertTaskToBackendTask(task)
    )
  }

  deleteTask(
    task: Task,
    userName: string
  ): Promise<ApiResponse<Task | ApiError>> {
    const newHistory = task.history
    const timeStamp = moment(new Date()).format('DD.MM.YYYY HH:mm')
    newHistory.push(`Der Task wurde am ${timeStamp} von ${userName} gelöscht.`)
    const taskToDelete = { ...task }
    taskToDelete.status = TaskStatus.REJECTED
    taskToDelete.history = newHistory
    return this.putTask(taskToDelete)
  }

  async createDefaultTaskForMedicalAdmin(
    message: string,
    title: string
  ): Promise<void> {
    const userService = new UserService()
    const medicalAdminResponse = await userService.getMedicalAdmin()

    if (isSuccess(medicalAdminResponse)) {
      const medicalAdmin = medicalAdminResponse.Response as Practitioner
      const taskService = new TaskService()
      const task = new Task()
      task.ownerDto = { id: medicalAdmin.id }
      task.title = title
      task.category = TaskCategories.MISCELLANEOUS
      task.deadline = moment().add(14, 'days').toDate()
      task.description = message
      task.priority = Priority.URGENT
      task.status = TaskStatus.REQUESTED

      await taskService.postTask(task)
    } else {
      console.warn('No medical admin returned from Backend.')
    }
  }

  addUser(
    taskId: string,
    userId: string
  ): Promise<ApiResponse<Task | ApiError>> {
    const params = {
      task: taskId,
      user: userId,
    }
    return this.post<null, Task>(new URL(API_TASKS_ADD_USER), params, null)
  }

  convertTaskToBackendTask(task: Task): Task {
    const newTask = {
      ...task,
      owner: task.ownerDto?.id ? 'Practitioner/' + task.ownerDto.id : null,
      for: task.forDto?.id ? 'Patient/' + task.forDto.id : null,
    }
    delete newTask.ownerDto
    delete newTask.forDto
    delete newTask.author
    delete newTask.lastUpdated

    return newTask
  }

  compareOnPriority(a: Task, b: Task): number {
    return Priority[b.priority?.toString()] - Priority[a.priority?.toString()]
  }

  compareOnDeadlineAndPriority(taskA: Task, taskB: Task): number {
    if (taskA.deadline === null || taskB.deadline === null) {
      return (
        Priority[taskB.priority?.toString()] -
        Priority[taskA.priority?.toString()]
      )
    }

    const dateTaskA = parseUnknownToDate(taskA.deadline)
    const dateTaskB = parseUnknownToDate(taskB.deadline)

    if (
      dateTaskA.getDate() === dateTaskB.getDate() &&
      dateTaskA.getMonth() === dateTaskB.getMonth() &&
      dateTaskA.getFullYear() === dateTaskB.getFullYear() &&
      dateTaskA.getHours() === dateTaskB.getHours()
    ) {
      return (
        Priority[taskB.priority?.toString()] -
        Priority[taskA.priority?.toString()]
      )
    } else {
      return (
        dateTaskA.getTime() - dateTaskB.getTime() ||
        Priority[taskB.priority?.toString()] -
          Priority[taskA.priority?.toString()]
      )
    }
  }
}

export default TaskService
