import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import {
  getDateFromDuration,
  istOutOfWorkAppointment,
} from '../../../utils/CalendarUtils'
import FormDatepicker from '../../UI/Forms/FormDatepicker/FormDatepicker'
import PatientSelect from '../../UI/Forms/PatientSelect/PatientSelect'
import PractitionerSelect from '../../UI/Forms/PractitionerSelect/PractitionerSelect'
import AppointmentParticipants from '../AppointmentParticipants/AppointmentParticipants'
import DurationsSelector from '../Calendar/DurationsSelector'
import { AppointmentPerson } from '../../../types/AppointmentPerson'
import { Practitioner } from '../../../types/Practitioner'
import AppointmentBasicView, {
  AppointmentMinimalViewProps,
} from './AppointmentBasicView'
import { withAuth } from '../../../infrastructure/AuthProvider'
import { PANOS_WORK_END, PANOS_WORK_START } from '../../../config/Constants'
import { Patient } from '../../../types/Patient'

interface AppointmentViewProps extends AppointmentMinimalViewProps {
  onChangeEndDate: (value: Date) => void
  handleParticipantDelete: (person: AppointmentPerson) => void
  onPractitionerAdded: (person: Practitioner) => void
  onPatientAdded: (person: Patient) => void
}

const AppointmentView: React.FC<AppointmentViewProps> = ({
  appointment,
  isEditable,
  handleInputChange,
  maxDate,
  onChangeEndDate,
  handleParticipantDelete,
  onPractitionerAdded,
  onPatientAdded,
  errors,
}: AppointmentViewProps) => {
  return (
    <AppointmentBasicView
      appointment={appointment}
      isEditable={isEditable}
      handleInputChange={handleInputChange}
      maxDate={maxDate}
      errors={errors}>
      <Tab.Container defaultActiveKey={appointment ? 'ende' : 'dauer'}>
        <Tab.Content>
          <Nav className="nav-material nav-sm mb-1">
            <Nav.Item>
              <Nav.Link eventKey="dauer">Dauer</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="ende">Ende</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Pane eventKey="dauer">
            <div className="form-group">
              <div className="col-w-100">
                <DurationsSelector
                  disabled={!isEditable}
                  onChange={value =>
                    handleInputChange('minutesDuration', value)
                  }
                  value={appointment.minutesDuration}
                  name={'minutesDuration'}
                  error={errors?.minutesDuration}
                />
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="ende">
            <FormDatepicker
              name="endingDateTime"
              type="datetime"
              value={getDateFromDuration(appointment)}
              maxDate={maxDate}
              onChange={onChangeEndDate}
              minDate={appointment.startingDateTime || new Date()}
              softDateLimit={true}
              minTime={PANOS_WORK_START}
              maxTime={PANOS_WORK_END}
              disabled={!isEditable || !appointment.startingDateTime}
              error={errors?.endingDateTime}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      <AppointmentParticipants
        participants={appointment.participants}
        onDelete={handleParticipantDelete}
      />
      <div className="form-group mb-0">
        <label>Teilnehmer hinzufügen:</label>
      </div>

      <Tab.Container defaultActiveKey="betreuer">
        <Tab.Content>
          <Nav className="nav-material nav-sm mb-1">
            {!istOutOfWorkAppointment(appointment) ? (
              <>
                <Nav.Item>
                  <Nav.Link
                    style={errors?.practitioner ? { color: 'red' } : {}}
                    eventKey="betreuer">
                    Betreuer
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    style={errors?.patient ? { color: 'red' } : {}}
                    eventKey="patient">
                    Patient
                  </Nav.Link>
                </Nav.Item>
              </>
            ) : (
              <Nav.Item>
                <Nav.Link eventKey="betreuer">Teilnehmer</Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          <Tab.Pane eventKey="betreuer">
            {(istOutOfWorkAppointment(appointment) &&
              appointment.participants.length === 0) ||
            !istOutOfWorkAppointment(appointment) ? (
              <PractitionerSelect
                onChange={onPractitionerAdded}
                name={'practitioner'}
                icon="person"
                clearOnSelect={true}
                disabled={!isEditable}
                onlyRegistered
                error={errors?.practitioner}
              />
            ) : istOutOfWorkAppointment(appointment) ? (
              <>
                <span className="badge badge-primary">
                  Die Terminskategorie unterstützt maximal einen Teilnehmer.
                </span>
                <br />
                <span className="badge badge-primary">
                  Löschen Sie den aktuellen Teilnehmer, wenn Sie wechseln
                  möchten.
                </span>
              </>
            ) : null}
          </Tab.Pane>
          <Tab.Pane eventKey="patient">
            {(istOutOfWorkAppointment(appointment) &&
              appointment.participants.length === 0) ||
            !istOutOfWorkAppointment(appointment) ? (
              <PatientSelect
                onChange={onPatientAdded}
                icon="person"
                name={'patient'}
                clearOnSelect={true}
                disabled={!isEditable}
                error={errors?.patient}
              />
            ) : null}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </AppointmentBasicView>
  )
}

export default withAuth(AppointmentView)
