import moment from 'moment'
import { hasStatus } from '../services/FhirResourceService'
import {
  checkDosageRatioValidity,
  findOverallStart,
  OptionChoices,
  parseIdentifierToCode,
  parseMedicationToCode,
  parseSupportingInformationToCode,
} from './MedicationUtils'
import { MedicationRequestStatus } from '../constants/MedicationRequestStatus'
import { ExtensionURL } from '../constants/ExtensionURL'
import { getExtensionValueByUrl } from './Utils'
import TaskService from '../services/TaskService/TaskService'
import { NotificationManager } from 'react-notifications'

export const sortMedicationRequests = (
  medicationRequests: MedicationRequest[]
): MedicationRequest[] => {
  return medicationRequests.sort((a, b) =>
    a.medicationReference.display < b.medicationReference.display ? -1 : +1
  )
}

/**
 * Determines whether a dosage or list of dosages fits to a given list of package variants so that the smallest
 * unit of each ingredient within the dosage is a quarter of a pill.
 * @param packageVariants A list of package variants.
 * @param dosages A list of dosages that could be selected.
 */
export const determineInvalidDosages = (
  packageVariants: OptionChoices[],
  dosages: OptionChoices[]
): undefined | OptionChoices => {
  return dosages.find(dosage => {
    const validDosage = checkDosageRatioValidity(packageVariants, dosage.value)
    if (!validDosage) {
      return dosage
    } else {
      return null
    }
  })
}

/**
 * Informs medical admin about an invalid dosage and prints corresponding console errors
 */
export const handleDosageError = async (
  medicationName: string,
  standardMedicationId: id
): Promise<void> => {
  console.error('Found invalid dosage')
  const message = `Die Medikamentenliste enthält eine ungültige Dosierung oder Paketgröße. Betroffen ist das Medikament ${medicationName} mit StandardMedication ID ${standardMedicationId}`
  const title = 'Ungültige Dosierung in der Medikamentenliste'

  const taskService = new TaskService()
  await taskService.createDefaultTaskForMedicalAdmin(message, title)

  NotificationManager.error(
    'Die Medikamentenliste enthält für diese Kombination einen Fehler, da keine sinnvolle Aufteilung möglich ist. Die minimale Einheit ist 0,25.'
  )
}

export const createActionsFromMedicationRequest = (
  medicationReqs: MedicationRequest[]
): Array<Record<string, unknown>> => {
  const actions = []
  if (medicationReqs.length) {
    const overallStart = findOverallStart(medicationReqs)
    for (const medRequest of medicationReqs) {
      if (
        hasStatus(medRequest.status, [
          MedicationRequestStatus.active,
          MedicationRequestStatus.draft,
          MedicationRequestStatus.on_hold,
        ])
      ) {
        const start = moment(
          medRequest.dosageInstruction[0].timing.repeat.boundsPeriod.start
        )
        const end = moment(
          medRequest.dosageInstruction[0].timing.repeat.boundsPeriod.end
        )
        const duration = end.diff(start, 'days')
        const offset = start.diff(overallStart, 'days')
        const codedInformation = [
          parseMedicationToCode(medRequest.medicationReference),
        ]
        const medKind = parseIdentifierToCode(
          medRequest.identifier,
          'urn:panos-backend-medication:Art'
        )
        if (medKind) {
          codedInformation.push(medKind)
        }

        const standardMedicationRef = parseSupportingInformationToCode(
          medRequest.supportingInformation
        )
        if (standardMedicationRef) {
          codedInformation.push(standardMedicationRef)
        }

        const medShape = parseIdentifierToCode(
          medRequest.identifier,
          'urn:panos-backend-medication:Form'
        )
        if (medShape) {
          codedInformation.push(medShape)
        }
        /**
         * codeInformation.text contains all the timing and dosage information in a single string
         * separated by semicolons contained in the dosage instruction of the medication request.
         * The order is:
         * 0. dosage
         * 1. timeOfDay
         * 2. minimalDistance
         * 3. minimalDistanceUnit
         * 4. frequencyMax
         * 5. period
         * 6. periodUnit
         * 7. indication
         */
        for (const dosage of medRequest.dosageInstruction) {
          codedInformation.push({
            coding: [
              {
                code: 'dosage-and-timing',
              },
            ],
            text: `${dosage.text};${
              dosage.timing.repeat.timeOfDay
                ? dosage.timing.repeat.timeOfDay.join()
                : ''
            };${
              getExtensionValueByUrl(
                dosage.timing.repeat.extension,
                ExtensionURL.medicationRequestMinimalDistance
              ) ?? ''
            };${
              getExtensionValueByUrl(
                dosage.timing.repeat.extension,
                ExtensionURL.medicationRequestMinimalDistanceUnit
              ) ?? ''
            };${dosage.timing.repeat.frequencyMax ?? ''};${
              dosage.timing.repeat.period ?? ''
            };${dosage.timing.repeat.periodUnit ?? ''};${
              dosage.patientInstruction ?? ''
            }`,
          })
        }
        actions.push({
          title: medRequest.note[0].text,
          code: codedInformation,
          timingTiming: {
            repeat: {
              boundsDuration: {
                value: duration,
                unit: 'days',
                system: 'http://unitsofmeasure.org',
                code: 'd',
              },
              offset: offset,
            },
          },
          definitionCanonical: 'ActivityDefinition/' + medRequest.id,
        })
      }
    }
  }
  return actions
}
