import { isSignedEvent } from '../../utils/FormUtils'
import MaterialIcon from '../UI/MaterialIcon/MaterialIcon'
import React from 'react'

export function RedCapFormStatus(props: {
  status: string
  children?: any
}): JSX.Element {
  return (
    <>
      {props.children}
      {isSignedEvent(props.status) ? (
        <MaterialIcon icon="verified" />
      ) : (
        <MaterialIcon icon="error" />
      )}
    </>
  )
}
