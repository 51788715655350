import React, { useEffect, useState } from 'react'
import { ICD10 } from '../../../../types/ICD10'
import { APIResult } from '../../../../constants/APIResult'
import ICD10Select from './ICD10Select'
import ICD10Service from '../../../../services/ICD10Service'
import FormErrorMessage from '../FormError/FormErrorMessage'

interface FormICD10SelectProps {
  name: string
  onChange?: (value: string) => void
  onChangeEvent?: (event: React.ChangeEvent<any>) => void
  label?: string | JSX.Element
  required?: boolean
  value?: string | null
  className?: string
  disabled?: boolean
  error?: string
  touched?: boolean
}

/**
 * Use this component with or without formik cotnext.
 *
 * Required props without Formik are:
 * -- name
 * -- value
 * -- onChange
 */

const FormIcd10Select: React.FC<FormICD10SelectProps> = ({
  name,
  value,
  onChangeEvent,
  onChange,
  className,
  error,
  label,
  touched,
  required,
  disabled,
}: FormICD10SelectProps) => {
  const inputValue = value !== null && value !== undefined ? value : null
  const [icd10, setIcd10] = useState<ICD10>(null)

  useEffect(() => {
    const loadIcd10 = async () => {
      const inputIcd10Response = await new ICD10Service().getICD10sById(
        inputValue
      )

      if (
        inputIcd10Response.Result === APIResult.SUCCESS &&
        inputIcd10Response.Response
      )
        setIcd10(inputIcd10Response.Response as ICD10)
    }
    if (inputValue && !icd10) {
      loadIcd10()
    }
  }, [inputValue, icd10])

  const onChangeHandler = (value: ICD10): void => {
    if (onChangeEvent) {
      const event = {
        target: {
          name: name,
          value: value && value.keyNoCross,
        },
        persist: () => {
          return
        },
      }
      onChangeEvent(event as React.ChangeEvent<any>)
    } else if (onChange) {
      onChange(value && value.keyNoCross)
    }
  }
  const cssClasses = className ? [className] : []
  if (error) cssClasses.push('position-relative')
  if (error) cssClasses.push('validate-bad')

  return (
    <div className={`form-group ${cssClasses.join(' ')}`}>
      {label ? (
        <label className="form-label">
          {label}
          {required ? <span style={{ color: 'red' }}> *</span> : null}
        </label>
      ) : null}
      <ICD10Select value={icd10} onChange={onChangeHandler} />
      <FormErrorMessage error={error} />
    </div>
  )
}

export default FormIcd10Select
