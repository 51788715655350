import ApiServiceBase from './ApiServiceBase'
import { API_PRIVILEGES } from '../config/Paths'
import { ApiResponse } from '../types/ApiResponse'
import { ApiError } from '../types/ApiError'
import { DescriptiveRoleRight, RoleLabel, RoleRight } from '../types/RoleRight'
import { UserPermission } from '../constants/UserPermission'
import { UserRole } from '../constants/UserRole'

export class PrivilegeService extends ApiServiceBase {
  getAll = async (): Promise<
    ApiResponse<DescriptiveRoleRight[] | ApiError>
  > => {
    return this.get<any, DescriptiveRoleRight[]>(
      new URL(API_PRIVILEGES + '/all'),
      null
    )
  }

  getLabels = async (): Promise<ApiResponse<RoleLabel[] | ApiError>> => {
    return this.get<any, RoleLabel[]>(new URL(API_PRIVILEGES + '/labels'), null)
  }

  getRights = async (): Promise<ApiResponse<UserPermission[] | ApiError>> => {
    return this.get<any, UserPermission[]>(
      new URL(API_PRIVILEGES + '/rights'),
      null
    )
  }

  getSingle = async (
    role: UserRole
  ): Promise<ApiResponse<RoleRight | ApiError>> => {
    const url = new URL(API_PRIVILEGES)
    url.searchParams.append('role', role)
    return this.get<any, RoleRight>(url, null)
  }

  add = async (roleRight: {
    role: any
    rights: UserPermission[]
  }): Promise<ApiResponse<RoleRight | ApiError>> => {
    return await this.put(new URL(API_PRIVILEGES + '/add'), roleRight)
  }

  remove = async (
    roleRight: RoleRight
  ): Promise<ApiResponse<RoleRight | ApiError>> => {
    return await this.put(new URL(API_PRIVILEGES + '/remove'), roleRight)
  }
}
