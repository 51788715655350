import ApiServiceBase, { API_RETURN } from './ApiServiceBase'
import {
  API_CREATE_PRACTITIONERS,
  API_GET_PRACTITIONERS,
  API_IMPORT_CSV_PRACTITIONERS,
  API_PRACTITIONER,
} from '../config/Paths'
import { ApiResponse } from '../types/ApiResponse'
import { Practitioner } from '../types/Practitioner'
import { ApiError } from '../types/ApiError'
import axios, { AxiosResponse } from 'axios'
import { getToken } from './Keycloak'

export class PractitionerService extends ApiServiceBase {
  getPractitioners(): Promise<ApiResponse<Practitioner[] | ApiError>> {
    const url = new URL(API_PRACTITIONER)
    return this.get<null, Practitioner[]>(url)
  }

  async findPractitioner(
    searchParam: string,
    excludeSelf: boolean,
    onlyRegistered: boolean
  ): Promise<ApiResponse<Practitioner[] | ApiError>> {
    const url = new URL(API_PRACTITIONER)
    url.searchParams.append('search', searchParam)
    url.searchParams.append('excludeSelf', excludeSelf.toString())
    url.searchParams.append('onlyRegistered', onlyRegistered.toString())

    return this.get<null, Practitioner[]>(url).then(
      (res: ApiResponse<Practitioner[]>) => res
    )
  }

  getPractitioner(
    practitionerId: string
  ): Promise<ApiResponse<Practitioner | ApiError>> {
    const id = practitionerId
      ? practitionerId.replace('Practitioner/', '')
      : null
    return this.get<null, Practitioner>(new URL(API_PRACTITIONER + `/${id}`))
  }

  getPractitionerXLSID(
    practitionerId: string
  ): Promise<ApiResponse<Practitioner | ApiError>> {
    return this.get<null, Practitioner>(
      new URL(API_PRACTITIONER + `/xls/${practitionerId}`)
    )
  }

  getPractitionersProgress(): Promise<ApiResponse<number | ApiError>> {
    return this.get<Practitioner[], null>(
      new URL(API_CREATE_PRACTITIONERS + '/progress'),
      null,
      API_RETURN.text
    )
  }

  postPractitioner(
    practitioner: Practitioner
  ): Promise<ApiResponse<Practitioner | ApiError>> {
    return this.post<Practitioner, Practitioner>(
      new URL(API_PRACTITIONER),
      null,
      practitioner
    )
  }

  putPractitioner(
    practitioner: Practitioner
  ): Promise<ApiResponse<Practitioner | ApiError>> {
    return this.put<Practitioner>(
      new URL(API_PRACTITIONER + `/${practitioner.id}`),
      practitioner
    )
  }

  deletePractitioner(
    practitioner: Practitioner
  ): Promise<ApiResponse<string | ApiError>> {
    return this.delete<Practitioner>(
      new URL(API_PRACTITIONER + `/${practitioner.id}`)
    )
  }

  getPractitionersByIds(
    practitionerIdParts: string[]
  ): Promise<ApiResponse<Practitioner[] | ApiError>> {
    const joinIdsToString = practitionerIdParts.join(',')
    const url = new URL(API_GET_PRACTITIONERS + joinIdsToString)
    return this.get<null, Practitioner[]>(url)
  }

  /** KVS practitioner import */
  importPractitionerFromCsv(
    file: File
  ): Promise<AxiosResponse<Record<string, string>>> {
    const formData = new FormData()
    formData.append('file', file)
    return axios.post(API_IMPORT_CSV_PRACTITIONERS, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*/*',
        Authorization: `Bearer ` + getToken(),
      },
    })
  }

  /** Ontology practitioner import */
  importPractitionerFromXLSX(
    file: File
  ): Promise<AxiosResponse<Record<string, string>>> {
    const formData = new FormData()
    formData.append('file', file)
    return axios.post(API_CREATE_PRACTITIONERS, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*/*',
        Authorization: `Bearer ` + getToken(),
      },
    })
  }

  createPractitioner(
    practitioner: Practitioner
  ): Promise<ApiResponse<null | ApiError>> {
    return this.post<Practitioner, null>(
      new URL(API_PRACTITIONER),
      null,
      practitioner
    )
  }
}

export default PractitionerService
