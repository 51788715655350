import { Draft, MinimalDraft, MinimalToBeDraft } from '../types/Draft'
import { AutosaveService } from '../services/AutosaveService'
import { DraftKind } from '../types/DraftKind'
import { Task } from '../types/Task'
import { Appointment } from '../types/Appointment'
import PatientService from '../services/PatientService'
import { APIResult } from '../constants/APIResult'
import { Patient } from '../types/Patient'
import { MedicalInstrument } from '../components/Forms/FormInterfaces'

export const toDraftDto = <T extends MinimalToBeDraft>(
  draft: Draft<T>
): MinimalDraft => {
  const minimalDraft = {
    draftId: `${draft.tempId ?? ''}:${draft.documentId}`,
    value: JSON.stringify(draft.value),
    date: null,
  }
  return minimalDraft
}

export const toDraftHavingID = <T extends MinimalToBeDraft>(
  value: T,
  kind: string,
  tempId: string
): Draft<T> => {
  const id = value.id ? value.id : ''
  const draft = {
    draftId: `${value.tempId ?? ''}:${kind}/${id}`,
    tempId: value.tempId ?? tempId,
    kind: DraftKind[kind],
    documentId: `${kind}/${id}`,
    partId: id,
    value: value,
    date: null,
  }
  return draft
}

export const getValueTransferringTempId = <T extends MinimalToBeDraft>(
  draft: Draft<T>
): T => {
  if (draft.value) {
    const value = draft.value
    value.tempId = draft.tempId
    return value
  }
  return null
}

export const draftIdFromfromMedicalInstrument = (
  medicalInstrument: MedicalInstrument
): string => {
  return (
    [
      medicalInstrument?.patientId ?? '',
      medicalInstrument?.recordId ?? '',
      medicalInstrument?.repeatInstance ?? '',
    ].join('-') ?? ''
  )
}

export const markDraftsAndAddDraftsOnly = async (
  values: any[],
  draftKind: string,
  onUpdate: (drafts: any[]) => void
): Promise<void> => {
  const autosaveService = new AutosaveService()
  await autosaveService.getAllIdInfosOfKind(draftKind).then(responseDrafts => {
    responseDrafts.forEach(draftIdInfo => {
      const valueHavingDraft = values.find(
        value => draftIdInfo.partId === value.id
      )
      if (valueHavingDraft) {
        valueHavingDraft.tempId = draftIdInfo.tempId
      }
    })
    onUpdate(values)
  })
}

export const getPatientsFromAnyKind = async (
  draft: Draft<any>
): Promise<Patient[]> => {
  if (draft.kind === DraftKind.Task) {
    const task: Task = draft.value
    if (task.for !== null && task.for !== undefined) {
      return [task.forDto]
    }
  } else if (draft.kind === DraftKind.Appointment) {
    const appointment: Appointment = draft.value
    return appointment.patientDtos as Patient[]
  } else if (draft.kind === DraftKind.RedCap) {
    const result = await new PatientService().getPatient(draft.value.patientId)
    if (result.Result === APIResult.SUCCESS) {
      const patient = result.Response as Patient
      return [patient]
    } else {
      console.log('Patient of draft not found')
    }
  }
}

export const redCapDraftId = (
  formName: string,
  event_name: string,
  hash_value: string,
  repeatInstance: string,
  patientId: string
): string => {
  return (
    (formName + event_name + hash_value).replace(/[^a-zA-Z0-9]+/g, '-') +
    patientId +
    '-' +
    repeatInstance
  )
}

export const isPureDraft = (appointment: Appointment): boolean => {
  return appointment.id === undefined || appointment.id === null
}

export const isAlsoDraft = (appointment: Appointment): string => {
  return appointment.tempId && appointment.id
}

export const isPureAppointment = (appointment: Appointment): string => {
  return !appointment.tempId && appointment.id
}
