import React, { Component } from 'react'
import { APIResult } from '../../../../constants/APIResult'
import PractitionerService from '../../../../services/PractitionerService'
import { Practitioner } from '../../../../types/Practitioner'
import Autocomplete from '../../Autocomplete/Autocomplete'
import { NotificationManager } from 'react-notifications'
import { getFullNameAndAddressPractitioner } from '../../../../utils/Utils'

interface Props {
  value?: Practitioner
  label?: string
  placeholder?: string
  disabled?: boolean
  icon?: string
  clearOnSelect?: boolean
  onChange: (practitioner: Practitioner) => void
  excludeSelf: boolean
  onlyRegistered: boolean
  name?: string
  error?: string
}

interface State {
  practitioner?: Practitioner[]
  selected: Practitioner
  value: string
}

class PractitionerSelect extends Component<Props, State> {
  static defaultProps = {
    icon: 'search',
    excludeSelf: false,
    onlyRegistered: false,
  }
  state = {
    selected: null,
    practitioner: null,
    value: '',
  }

  componentDidMount(): void {
    const { value } = this.props

    if (value) {
      this.setState({
        selected: value,
        value: getFullNameAndAddressPractitioner(value),
      })
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { value } = this.props

    if (prevProps.value !== value) {
      if (value) {
        this.setState({
          selected: value,
          value: getFullNameAndAddressPractitioner(value),
        })
      } else {
        this.setState({ selected: null, value: '', practitioner: null })
      }
    }
  }

  handleSelect = (person: Practitioner): void => {
    if (person !== this.state.selected) this.props.onChange(person)
    if (this.props.clearOnSelect) {
      this.setState({
        selected: person,
        value: '',
      })
    } else if (person) {
      this.setState({
        selected: person,
        value: getFullNameAndAddressPractitioner(person),
      })
    } else {
      this.setState({
        selected: null,
        value: '',
      })
    }
  }

  handleSearch = async (param: string): Promise<void> => {
    if (param) {
      const service = new PractitionerService()
      const res = await service.findPractitioner(
        param,
        this.props.excludeSelf,
        this.props.onlyRegistered
      )
      if (
        res &&
        res.Result === APIResult.SUCCESS &&
        Array.isArray(res.Response)
      ) {
        const practitioners = res.Response as Practitioner[]
        this.setState({
          practitioner: practitioners,
        })
      } else {
        NotificationManager.error('Fehler', 'Derzeit keine Antwort vom Server')
      }
    } else {
      this.setState({ practitioner: [] })
    }
  }

  render(): JSX.Element {
    return (
      <Autocomplete<Practitioner>
        label={this.props.label}
        data={this.state.practitioner}
        onSearch={this.handleSearch}
        onSelect={this.handleSelect}
        selected={this.state.selected}
        disabled={this.props.disabled}
        renderValue={practitioner =>
          getFullNameAndAddressPractitioner(practitioner)
        }
        icon={this.props.icon}
        value={this.state.value}
        name={this.props.name ?? 'practitioner'}
        placeholder={
          this.props.placeholder ? this.props.placeholder : 'Bearbeiter suchen'
        }
        notFoundMessage="kein Bearbeiter gefunden"
        error={this.props.error}
      />
    )
  }
}

export default PractitionerSelect
