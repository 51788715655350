import React, { Component, ReactElement } from 'react'
import {
  FieldFunctionAttachment,
  FormInformations,
  MedicalInstrument,
} from '../components/Forms/FormInterfaces'
import PatientService from '../services/PatientService'
import { Patient } from '../types/Patient'
import { isSuccess } from '../utils/ApiUtils'
import {
  allCheckboxValuesAreFalse,
  createFieldFunctionNewEvents,
  createModuleVarNames,
  extractAllMetaWithSubComponents,
  extractMetaData,
  getAllFields,
  getSameModules,
  parseHTMLMetaData,
  transformToNameMap,
  translateFrontendToRedcap,
  translateModuleVarNames,
  translateRedcapToFrontend,
} from '../utils/FormUtils'
import REDCapService, { RedCapDTO } from '../services/REDCapService'
import { NotificationManager } from 'react-notifications'
import DocumentService from '../services/DocumentService'
import {
  BEFUNDE_EINLESEN,
  FINDINGS_EVENT_NAME,
  MODULE_PREFIX,
  MONITORING_EVENT_NAMES,
  MONITORING_RELATIVE_EVENT_NAMES,
} from '../config/Constants'
import { Document } from '../types/Document'
import Loader from '../components/UI/Spinner/Loader'
import {
  RedCapSaveCombination,
  RedCapSaveStatus,
} from '../constants/RedCapSaveMethods'
import { BranchingValidationContext } from '../components/FieldFunctionComponents/ValidationBranchingContext'
import { FormDynamicComponentProps } from '../components/FormDynamic/FormDynamicComponent'
import { RedCapSave, RedCapSaveResponse } from '../types/RedCapSave'
import Autosave, { FlowStep } from '../components/Draft/Autosave/Autosave'
import { redCapDraftId } from '../utils/DraftUtils'
import { DraftKind } from '../types/DraftKind'
import { AutosaveService } from '../services/AutosaveService'
import { Button } from 'react-bootstrap'
import { AuthStore } from './AuthProvider'
import {
  MedicationUpdates,
  reloginActivateMedicationRequests,
} from '../components/FieldFunctionComponents/FieldExternalTool'
import { DynamicSidebarStore } from './DynamicSidebarProvider'
import evaluate, {
  generateSetFieldValue,
  generateValueFromDefaultValue,
  setShow,
  updateAll,
} from '../utils/EvaluateBranchingUtils'
import AppointmentService from '../services/AppointmentService'
import {
  DynamicForm,
  EventContextContextAsProps,
  EventContextProps,
  EventContextProviderState,
  EventMeta,
  EventStore,
} from './EventProviderInterfaces'
import { FieldTypes } from '../constants/FieldTypes'
import TaskService from '../services/TaskService/TaskService'
import { isEqual, merge, mergeWith } from 'lodash'

export default class EventProvider extends Component<
  EventContextProps,
  EventContextProviderState
> {
  static defaultProps = {
    showPreviousValues: true,
    isAutosaving: true,
  }

  private debug = true

  state: EventContextProviderState = {
    medicationUpdates: {},
    event: null,
    practitioner: null,
    forms: null,
    values: null,
    prevValues: null,
    prevVersions: null,
    errors: {},
    formFetchErrors: false,
    allMeta: {},
    edited: {},
    touched: {},
    firstRender: true,
    triggerAutoSave: false,
    hasDraft: false,
    noValidation: true,
    singleForm: false,
    lastClickedForm: null,
    lastClickedModule: null,
    showSyncModule: false,
    showConfirmModule: false,
    lastFieldName: null,
    lastFieldValue: null,
    currentPreviousVersion: null,
  }

  validationTimeout: ReturnType<typeof setTimeout> = null
  private autosaveRef: React.RefObject<Autosave<any>>

  setCurrentPreviousVersion = (version: string): void => {
    this.setState({ currentPreviousVersion: version })
  }

  componentDidMount(): void {
    if (this.props.patientId && this.props.instruments) this.createEventMeta()
    this.autosaveRef = React.createRef<Autosave<any>>()
  }

  componentDidUpdate(
    prevProps: EventContextProps,
    prevState: EventContextProviderState
  ): void {
    if (prevState.event !== this.state.event) {
      this.fetchEvent()
    }
    if (
      (prevProps.patientId !== this.props.patientId ||
        prevProps.instruments !== this.props.instruments) &&
      this.props.instruments &&
      this.props.patientId
    ) {
      this.createEventMeta()
    }
    if (prevState.firstRender && prevState.forms !== this.state.forms) {
      this.setState({ firstRender: false })
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.validationTimeout)
    this.setState = (state, callback) => {
      return
    }
  }

  createEventMeta = async (): Promise<void> => {
    const { patientId, instruments } = this.props
    const recordID = instruments[0]?.recordId || null
    if (this.debug) console.log('createEventMeta instruments', instruments)
    const event: EventMeta = {
      name: instruments[0]?.event_name,
      label: instruments[0]?.event_label,
      instruments: [...instruments],
      patientId: patientId,
      recordId: recordID,
      hash_value: instruments[0]?.hash_value,
    }

    if (recordID === null) {
      const patientService = new PatientService()
      const patientResult = await patientService.getPatient(patientId)

      if (isSuccess(patientResult)) {
        const patient = patientResult.Response as Patient
        event.recordId = patient.recordId
      }
    }

    this.setState({
      event,
      singleForm: instruments.length <= 1,
    })
  }

  fetchEvent = async (): Promise<void> => {
    const { instruments } = this.state.event
    const instrument = instruments[0]

    /* In an monitoring event, we have to fetch only instruments which are given */
    let forms = null
    if (
      [...MONITORING_EVENT_NAMES, ...MONITORING_RELATIVE_EVENT_NAMES].includes(
        this.state.event.name
      )
    ) {
      forms = this.state.event.instruments.map(
        instrument => instrument.instrument_name
      )
    }

    const redcapService = new REDCapService()
    const eventInformationsResult = await redcapService.getFormInformations(
      forms,
      null,
      instrument.recordId,
      instrument.repeatInstance,
      instrument.event_name
    )
    if (this.debug) console.log('EVENT', eventInformationsResult)
    if (!isSuccess(eventInformationsResult)) {
      NotificationManager.error('Formulardaten wurden nicht geladen')
      return
    }
    const eventInformations =
      eventInformationsResult.Response as FormInformations[]
    const formNames = instruments.map(value => {
      return value.instrument_name
    })

    let criticalFormErrors: boolean
    const formFetchErrors = eventInformations.reduce(
      (errors, formInformation) => {
        return [...errors, ...(formInformation.errors ?? [])]
      },
      []
    )
    criticalFormErrors = Boolean(formFetchErrors.length)
    if (this.debug && formFetchErrors.length) console.log(formFetchErrors)

    const filtered = eventInformations.filter(value => {
      return formNames.includes(value.form)
    })

    const dynamicFormDatas = await Promise.all(
      filtered.map((formInformation, index) => {
        return this.processFormInformations(formInformation, index)
      })
    ).catch(reason => {
      console.error('FAILED PROCESSING FORMS', reason)
      return null
    })

    const values = dynamicFormDatas.reduce((obj, response) => {
      return response?.data?.values
        ? {
            ...obj,
            ...response?.data?.values,
          }
        : obj
    }, {})

    const prevValues = dynamicFormDatas.reduce((obj, response) => {
      return response?.data?.prevValues
        ? merge(obj, response?.data?.prevValues)
        : obj
    }, {})
    const prevVersions = dynamicFormDatas.reduce((obj, response) => {
      return response?.data?.prevValues
        ? merge(obj, response?.data?.prevVersions)
        : obj
    }, {})

    setTimeout(() => this.setState({ noValidation: false }), 1000)

    if (!dynamicFormDatas.length) {
      NotificationManager.error(
        'Metadaten konnten nicht geladen werden. Bitte Admin kontaktieren'
      )
      console.warn(
        'Metadaten konnten nicht geladen werden? Wurden sie synchronisiert?'
      )
      if (this.props.closeSidebar) this.props.closeSidebar()
      return
    }

    let allMeta: Record<string, FormDynamicComponentProps>
    try {
      allMeta = transformToNameMap(dynamicFormDatas)
    } catch (e) {
      criticalFormErrors = true
    }
    const currentPreviousVersion = Object.keys(prevValues).reverse()[0]

    this.setState({
      forms: dynamicFormDatas,
      values,
      prevValues,
      prevVersions,
      currentPreviousVersion,
      formFetchErrors: criticalFormErrors,
      lastClickedForm: instruments[0].instrument_name,
      allMeta: allMeta,
    })
  }

  processFormInformations = async (
    formInformations: FormInformations,
    index: number
  ): Promise<DynamicForm> => {
    const { instruments } = this.state.event
    if (this.debug)
      console.log(
        'processFormInformations',
        instruments,
        index,
        formInformations
      )

    //TODO: Find out if 0 or index should be used
    const instrument = instruments[0]

    const form: DynamicForm = {
      name: formInformations.form,
      data: null,
      smartvariables: null,
      prevValues: null,
      assignedDocument: null,
      externalDocuments: null,
      branchingValidator: null,
      lastModules: [],
    }

    try {
      const meta: FormDynamicComponentProps[] = parseHTMLMetaData(
        formInformations.meta
      )

      const sharedValues = (
        JSON.parse(formInformations.values) as Record<string, unknown>[]
      )[0]

      const dynamicFormDataValues = translateRedcapToFrontend(
        meta,
        sharedValues,
        this.props.saveMethod,
        formInformations.lastModules
      )

      const prevValues: Record<
        string,
        Record<string, unknown>
      > = Object.entries(formInformations.prevValues ?? {}).reduce(
        (prevValues, [repeatInstance, values]) => {
          if (
            this.state.event.instruments[0].repeatInstance &&
            parseInt(repeatInstance) >=
              parseInt(this.state.event.instruments[0].repeatInstance)
          )
            return prevValues

          const parsedValues = (
            JSON.parse(values) as Record<string, unknown>[]
          )[0]

          const prevDynamicFormDataValues = translateRedcapToFrontend(
            meta,
            parsedValues,
            this.props.saveMethod,
            formInformations.lastModules
          )

          prevValues[repeatInstance] = merge(
            prevValues[repeatInstance] ?? {},
            prevDynamicFormDataValues
          )
          return prevValues
        },
        {}
      )
      const prevVersions = formInformations.prevVersions

      form.smartvariables = formInformations.smartvariables
      form.lastModules = formInformations.lastModules
      form.data = {
        values: dynamicFormDataValues,
        prevValues: prevValues,
        prevVersions: prevVersions,
        redcapForm: meta,
      }

      formInformations?.lastModules?.forEach(lastModule => {
        this.setState(state => {
          const edited = { ...state.edited }
          const formName = lastModule.formName
          const moduleName = lastModule.moduleName
          if (!edited[formName]) edited[formName] = {}
          edited[formName][moduleName] = true
          return { edited: edited }
        })
      })

      form.branchingValidator = new BranchingValidationContext(meta)

      if (instrument.event_name === FINDINGS_EVENT_NAME) {
        return await this.loadExternalDocuments(instrument, form)
      }

      return await form
    } catch (e) {
      NotificationManager.error(
        'Fehler beim Laden der Formulardaten oder Werte'
      )
      console.error('error getting medical round data', e)
    }
    return null
  }

  loadExternalDocuments = async (
    instrument: MedicalInstrument,
    form: DynamicForm
  ): Promise<DynamicForm> => {
    const documentService = new DocumentService()
    const findingsResponse = await documentService.getFindingsForPatient(
      instrument.patientId,
      'lexicographic',
      'external'
    )

    if (isSuccess(findingsResponse)) {
      const findings = findingsResponse.Response as unknown as Document[]

      // Determine the document assigned to this form and instance (if such a document exists)
      const assignedDocument = findings.find(document => {
        if (document.referencedForm) {
          const refInfos = document.referencedForm.split('::')
          if (refInfos.length !== 2) {
            console.warn(
              `Illegal reference form specification in document with id=${document.id}. Expected one '::' delimiter.`
            )
            return false
          }
          const documentRepeatInstanceReference = parseInt(refInfos[0])
          if (
            documentRepeatInstanceReference ===
            parseInt(instrument.repeatInstance)
          ) {
            return true
          }
        }

        return false
      })
      return {
        ...form,
        assignedDocument: assignedDocument,
        externalDocuments: findings,
      }
    }
  }

  handleUpdateForm = (index: number, form: DynamicForm): void => {
    this.debug && console.log('handleUpdateForm', index, form)

    this.setState(state => {
      const forms = state.forms ? [...state.forms] : []
      forms[index] = form
      return { forms: forms }
    })
  }

  handleUpdateValue = (key: string, value: unknown): void => {
    if (isEqual(this.state.values[key], value)) return

    this.setState(
      state => {
        const values = state.values ? { ...state.values } : {}
        values[key] = value

        return { values }
      },
      () => !this.state.noValidation && this.debouncedValidate()
    )
  }

  updateEdited = (key: string, value: boolean): void => {
    this.setState(state => {
      const edited = { ...state.edited }
      const formName = state.allMeta[key].formName
      const moduleName = state.allMeta[key].annotation?.instrument_name

      if (!edited[formName]) edited[formName] = {}
      if (!edited[formName][moduleName]) edited[formName][moduleName] = null
      if (value !== edited[formName][moduleName])
        edited[formName][moduleName] = value
      return { edited: edited }
    })
  }

  handleUpdateValues = (newValues: Record<string, unknown>): void => {
    this.debug && console.log('handleUpdateValues', newValues)

    this.setState(
      state => {
        const values = state.values
          ? { ...state.values, ...newValues }
          : { ...newValues }
        return { values: values }
      },
      () => !this.state.noValidation && this.debouncedValidate()
    )
  }

  handleChange = (event: React.ChangeEvent<any>): void => {
    this.debug &&
      console.log('handleChange', event.target.name, event.target.value)

    event.persist()
    this.setState(
      state => {
        const values = state.values
          ? {
              ...state.values,
            }
          : {}
        values[event.target.name] = event.target.value
        return { values }
      },
      () => !this.state.noValidation && this.debouncedValidate()
    )
  }

  setFieldTouched = (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ): void => {
    this.debug &&
      console.log('setFieldTouched', field, isTouched, shouldValidate)

    this.setState(state => {
      const touched = state.touched ? { ...state.touched } : {}
      touched[field] = isTouched
      return { touched: touched }
    })
  }

  /**
   * Analyzes the change in the field. It takes into account whether the branching logic of subsequent fields in the
   * event are affected. If this is the case, it is considered whether these fields were already visible, had a value
   * and whether this value is not the default value.
   *
   * Assumption 1: In modules preloaded checkbox fields which contain only false values are to be treated like
   * unfilled fields
   *
   * If such critical fields exist when the value is changed, a warning modal is opened and the new possible value
   * is saved and passed to the old handleUpdateValue() and setFieldTouched() methods only when confirmed.
   *
   * In addition, it analyzes whether the following field could be a modal of the FieldInstrument field function
   * if the value is changed. If this is the case, the ModuleSync check is started.
   * @param fieldValue new field value
   * @param fieldName field name
   */
  handleFieldChange = (fieldValue: unknown, fieldName: string): void => {
    let analyzeBranchingFields = true
    const meta = extractAllMetaWithSubComponents(this.state.forms)
    if (meta.length === 0) return
    const clickedFieldIndex = meta.findIndex(value => value.name === fieldName)
    const stateValues = { ...this.state.values }
    const stateMeta = { ...this.state.allMeta }

    const allBranchingRelatedFields = meta.filter(field => {
      return (
        field.branchingLogic?.includes(fieldName) && field.name !== fieldName
      )
    })

    const allBranchingFieldsIndexed: Record<string, number> = {}
    const allBranchingFieldsShowed: Record<string, boolean> = {}
    if (Object.keys(allBranchingRelatedFields)?.length) {
      allBranchingRelatedFields.forEach(field => {
        allBranchingFieldsIndexed[field.name] = meta.findIndex(
          value => value.name === field.name
        )
      })
    }

    /**
     * If only single characters are added or removed within text fields, this should not be analyzed further.
     * Only if the field is completely cleared, this should be handled/analyzed as all other fields
     */
    const currentField = meta[clickedFieldIndex]
    if (
      currentField.field_type === FieldTypes.TEXT ||
      currentField.field_type === FieldTypes.NOTES
    ) {
      if (fieldValue !== undefined && fieldValue !== null) {
        analyzeBranchingFields = false
      }
    }

    let criticalFieldToHide
    if (analyzeBranchingFields) {
      if (Object.keys(allBranchingRelatedFields)?.length) {
        allBranchingRelatedFields.forEach(field => {
          if (
            field.branchingLogic &&
            this.state.values &&
            field.field_type !== FieldTypes.DESCRIPTIVE &&
            field.field_type !== FieldTypes.FIELD_INSTRUMENT
          ) {
            let showed: boolean
            try {
              showed = evaluate(field.branchingLogic, stateValues, stateMeta)
            } catch (e) {
              showed = false
            }

            if (showed) {
              const value = stateValues?.[field.name]
              const checkboxValue: Record<string, unknown> = {}
              let ignoreField = false
              let someCheckboxValuesAreNull: boolean
              let allValuesAreFalse: boolean

              if (field.field_type === FieldTypes.CHECKBOX) {
                /** There are checkboxes where the value object contains null instead of false for each value.
                 * For the comparison with the default values this must be adjusted. */
                const nullCaseExists = !!Object.keys(value).find(
                  key => value[key] === null
                )

                if (nullCaseExists) {
                  Object.keys(value).forEach(key => {
                    const currentValue = value?.[key]
                    if (currentValue === null) {
                      checkboxValue[key] = false
                      someCheckboxValuesAreNull = true
                    } else {
                      checkboxValue[key] = value
                    }
                  })
                  // Assumption 1
                  if (allCheckboxValuesAreFalse(checkboxValue)) {
                    allValuesAreFalse = true
                    someCheckboxValuesAreNull = false
                  }
                } else {
                  // Assumption 1
                  allValuesAreFalse = allCheckboxValuesAreFalse(
                    value as Record<string, unknown>
                  )
                }

                /** For example, if option 2 of a checkbox choice of three is deselected, we need to check whether this
                 * specific branching case is still affected, or is still covered by the other (possibly) selected options.
                 * There can be several key value pairs in the checkbox values object, therefore only the difference
                 * of size 1 is the new value to be considered */
                const oldValues = stateValues[fieldName]
                let newValue: string = null
                if (
                  Object.keys(fieldValue)?.length > 0 &&
                  Object.keys(oldValues)?.length > 0
                ) {
                  newValue = Object.keys(oldValues).find(key => {
                    const oldValue = oldValues?.[key]
                    const newValue = fieldValue?.[key]
                    if (
                      oldValue !== undefined &&
                      oldValue !== null &&
                      newValue !== undefined &&
                      newValue !== null
                    ) {
                      return !isEqual(oldValue, newValue)
                    } else return false
                  })
                }

                if (newValue) {
                  let checkboxValue: number
                  try {
                    checkboxValue = parseInt(newValue)
                  } catch (e) {
                    checkboxValue = null
                  }
                  if (checkboxValue) {
                    const checkboxVar = `${fieldName}(${checkboxValue})`
                    const isAffected = allBranchingRelatedFields.find(value =>
                      value.branchingLogic?.includes(checkboxVar)
                    )
                    if (!isAffected) {
                      ignoreField = true
                    }
                  }
                }
              }

              const hasValue = Object.keys(checkboxValue)?.length
                ? checkboxValue
                : value

              // Comparison with default values must only be made if the field has a value or is not to be ignored.
              if ((hasValue || hasValue === {}) && !ignoreField) {
                let isDefault: boolean
                const defaultString = field?.annotation?.default

                if (defaultString && isEqual(hasValue, defaultString)) {
                  isDefault = true
                } else if (defaultString) {
                  let defaultValue: unknown
                  try {
                    defaultValue = generateValueFromDefaultValue(
                      stateMeta,
                      field.field_type,
                      field.options,
                      field.name,
                      defaultString,
                      stateMeta
                    )
                  } catch (e) {
                    console.log(e)
                    defaultValue = null
                  }
                  if (defaultValue) {
                    isDefault = isEqual(hasValue, defaultValue)
                  } else {
                    isDefault = false
                  }
                } else if (!defaultString && someCheckboxValuesAreNull) {
                  isDefault = false
                } else if (!defaultString && allValuesAreFalse) {
                  isDefault = true
                } else {
                  isDefault = false
                }

                if (!isDefault) {
                  allBranchingFieldsShowed[field.name] = true
                }
              }
            }
          }
        })
      }

      // one field is enough to trigger the confirm modal
      criticalFieldToHide = Object.entries(allBranchingFieldsIndexed).find(
        ([fieldName, fieldIndex]) => {
          return (
            clickedFieldIndex < fieldIndex &&
            allBranchingFieldsShowed[fieldName]
          )
        }
      )
    }

    const nextModuleField = Object.entries(allBranchingFieldsIndexed).find(
      value => {
        const fieldIndex = value[1]
        const fieldName = value[0]
        return (
          fieldIndex === clickedFieldIndex + 1 &&
          stateMeta[fieldName]?.field_type === FieldTypes.FIELD_INSTRUMENT
        )
      }
    )

    if (criticalFieldToHide) {
      this.setState({
        showConfirmModule: true,
        lastFieldName: fieldName,
        lastFieldValue: fieldValue,
      })
    } else if (nextModuleField && fieldValue === '1') {
      const data = stateMeta[nextModuleField[0]]
      this.handleModuleSync(data)
      this.handleUpdateValue(fieldName, fieldValue)
      this.setFieldTouched(fieldName, true)
    } else {
      this.handleUpdateValue(fieldName, fieldValue)
      this.setFieldTouched(fieldName, true)
    }
  }

  continueAction = (): void => {
    this.handleUpdateValue(this.state.lastFieldName, this.state.lastFieldValue)
    this.setFieldTouched(this.state.lastFieldName, true)
    this.setState({ showConfirmModule: false })
  }

  /**
   * Triggers validation some interval after setting the value
   * It will cancel other validations until then, so many values
   * can be set and when there is a free second, we will do the
   * validation
   */
  debouncedValidate(): void {
    clearTimeout(this.validationTimeout)
    this.validationTimeout = setTimeout(() => {
      const { forms } = this.state
      this.triggerValidation(forms, true, false)
    }, 1000)
  }

  handleTriggerAutosave = (): void => {
    this.setState({ triggerAutoSave: !this.state.triggerAutoSave })
  }

  handleSubmit = (
    validate: boolean,
    saveStatus: RedCapSaveStatus,
    assignTo?: string,
    sign?: boolean
  ): void => {
    if (this.debug) console.log('handleSubmit')

    const { forms } = this.state
    const { validatedForms, anyInvalidForm } = this.triggerValidation(
      forms,
      // validate at all
      true,
      // validate required fields -> if we don't validate, we want at least
      // to validate fields fitting into redcap
      !validate
    )
    if (anyInvalidForm) {
      const message = this.state.singleForm
        ? 'Das Formular beinhaltet Fehler'
        : 'Eines oder mehrere Formulare beinhalten Fehler.'
      NotificationManager.error(message)
      return
    }

    const newUpdatedValues = this.computeDefaultValues(this.state.values)

    /** create RedCapDto for each form */
    const redCapDtos: RedCapDTO[] = validatedForms.map((form, index) =>
      this.convertFormToRedcapDto(
        index,
        newUpdatedValues,
        form,
        saveStatus,
        assignTo,
        sign
      )
    )

    /** break when not all forms are created correctly */

    if (
      redCapDtos.filter(form => form !== null).length !==
      this.state.forms.length
    ) {
      NotificationManager.error('Bitte überprüfen und erneut abschicken')
      return
    }

    /** update documents for all forms **/
    this.state.forms
      .filter(form => form.name === BEFUNDE_EINLESEN)
      .forEach(async form => {
        if (form.assignedDocument) {
          const documentService = new DocumentService()
          const updateAssignedDocumentResult =
            await documentService.editPatientFindingsFile(form.assignedDocument)
          if (!isSuccess(updateAssignedDocumentResult)) {
            NotificationManager.error(
              `Fehler beim Speichern des Fremdbefunds zu Form ${form.name}`
            )
          }
        }
      })

    this.translateLocationObjectsInForms(redCapDtos)

    this.saveForms(redCapDtos).then(() => {
      if (sign)
        reloginActivateMedicationRequests(
          this.props.authStore,
          this.props.dynamicSidebarStore,
          this.state.medicationUpdates,
          this.state.event.patientId
        )

      if (this.debug) {
        console.log('handledSubmit', sign)
        return
      }
    })
  }

  computeDefaultValues = (
    prevValues: Record<string, any>
  ): Record<string, any> => {
    const [updatedValues] = updateAll(
      prevValues,
      this.state.allMeta,
      {},
      setShow,
      generateSetFieldValue
    )

    const newUpdatedValues = mergeWith({}, prevValues, updatedValues, (a, b) =>
      b === null ? a : undefined
    )
    return newUpdatedValues
  }

  private translateLocationObjectsInForms(
    redCapDtos: RedCapDTO[]
  ): RedCapDTO[] {
    return redCapDtos.map(form => {
      if (form && form.attachment && form.attachment.tasksAppointments) {
        const translatedTaskAppointments =
          form.attachment.tasksAppointments.map(taskAppointment => {
            if (taskAppointment && taskAppointment.appointments) {
              const appointmentService = new AppointmentService()
              taskAppointment.appointments = taskAppointment.appointments.map(
                appointment =>
                  appointmentService.convertAppointmentToDto(appointment)
              )
              return taskAppointment
            } else {
              return taskAppointment
            }
          })

        form.attachment.tasksAppointments = translatedTaskAppointments
      }
      return form
    })
  }

  private triggerValidation(
    forms: DynamicForm[],
    validate: boolean,
    ignoreRequired: boolean
  ) {
    let validatedForms = [...forms]
    let anyInvalidForm = false
    /** first Validate all forms */
    if (validate) {
      let errors = {}
      validatedForms = validatedForms.map(form => {
        const newForm = { ...form }
        /** Update validation onject to latest version */
        form.branchingValidator.updateValidation(ignoreRequired)
        const formErrors = form.branchingValidator.validate(
          this.state.values,
          false, //deactivate validation
          ignoreRequired
        )
        errors = {
          ...errors,
          ...formErrors,
        }
        newForm.valid = !Object.keys(formErrors).length
        if (!newForm.valid) anyInvalidForm = true
        return newForm
      })
      this.setState({ forms: validatedForms, errors })
    }

    if (this.debug)
      console.log('validate:', validate, validatedForms, anyInvalidForm)
    // If there is any Invalid Form, just update the state
    return { validatedForms, anyInvalidForm }
  }

  convertFormToRedcapDto = (
    index: number,
    values: Record<string, unknown>,
    form: DynamicForm,
    saveStatus: RedCapSaveStatus,
    assignTo: string,
    signMedicalRound: boolean
  ): RedCapDTO => {
    const formMeta = transformToNameMap([form])
    const instrument = this.state.event.instruments[index]
    try {
      const data = {}
      if (this.state.event.name)
        data['redcap_event_name'] = this.state.event.name
      /* iterate though all fields from formik */
      for (const key in form.data.values) {
        if (Object.prototype.hasOwnProperty.call(values, key)) {
          data[key] = values[key]
        }
      }
      data['record_id'] = this.state.event.recordId
      data['redcap_repeat_instance'] = instrument.repeatInstance

      const translatedData = translateFrontendToRedcap(
        form.data.redcapForm,
        data,
        instrument.instrument_name
      )

      const saveCombination: RedCapSaveCombination = {
        method: this.props.saveMethod,
        status: saveStatus,
        sign: signMedicalRound,
        assignTo: assignTo,
      }

      /** Create attachments */
      const attachment: FieldFunctionAttachment = {
        form: form.name,
        tasksAppointments: form.fieldDrafts,
        events: createFieldFunctionNewEvents(values, formMeta),
      }

      if (this.debug) console.log('Attachments', attachment)
      /** End of attachment creation */

      const editedModules = this.state.edited[instrument.instrument_name] ?? {}
      let editedModuleNames = Object.keys(editedModules)
        .map(key => {
          if (editedModules[key] === true) return key
          return null
        })
        .filter(x => x)
      editedModuleNames = editedModuleNames ?? []

      return {
        data: JSON.stringify(translatedData),
        smartVariables: form.smartvariables,
        overwriteBehavior: 'overwrite',
        forms: [instrument.instrument_name],
        events: [instrument.event_name],
        eventLabel: instrument.event_label,
        records: [instrument.recordId],
        saveCombination: saveCombination,
        attachment: attachment,
        editedModules: editedModuleNames,
        sourceRepeatInstance: instrument.repeatInstance,
      }
    } catch (e) {
      console.error('Preparing data failed', e)
    }
    return null
  }

  saveForms = async (redCapDtos: RedCapDTO[]): Promise<void> => {
    this.setState({ isSaving: true })

    const redCapSaveDto: RedCapSave = {
      event: this.state.event.name,
      eventLabel: this.state.event.label,
      forms: redCapDtos,
      recordId: this.state.event.recordId,
      creator: this.props.authStore?.auth?.panosId,
      eventLinkIdentifier: this.state.event.instruments[0]?.eventLinkIdentifier,
    }

    const redCapService = new REDCapService()
    const response = await redCapService.saveForms(redCapSaveDto)

    if (isSuccess(response)) {
      const saveResponse = response.Response as RedCapSaveResponse
      if (saveResponse.success) {
        if (this.state.singleForm) {
          NotificationManager.success('Formular wurde gespeichert')
        } else {
          NotificationManager.success(saveResponse.message)
        }

        if (this.props.closeSidebar) {
          if (this.debug)
            console.log('CloseSidebar is set', this.props.closeSidebar)
          this.props.closeSidebar()
        }
        if (this.props.onSaved) {
          if (this.debug) console.log('onSaved is set')
          this.props.onSaved()
        }
      } else {
        NotificationManager.error(saveResponse.message)
      }
    } else {
      NotificationManager.error(
        'Unbekannter Fehler beim Speichern der Formulare'
      )
    }
    this.setState({ isSaving: false })
  }

  resetFormular = (): void => {
    if (this.state.hasDraft) this.deleteDraft()
    /*
       This seems to be hacky, to tell the autosave to delete reset itself,
       but we don't want to include here states to control the autosave component
    */

    const newValues = this.computeDefaultValues(
      this.autosaveRef.current.state.unmodified.values
    )

    this.handleUpdateValues(newValues)
    this.autosaveRef.current.state.flowState = FlowStep.save
  }

  deleteDraft = async (): Promise<void> => {
    const response = await new AutosaveService<any>().deleteValueById(
      {
        id: redCapDraftId(
          this.state.event.recordId,
          this.state.event.name,
          this.state.event.hash_value,
          this.state.event.instruments[0].repeatInstance,
          this.state.event.patientId
        ),
        values: '',
        title: this.state.event.label,
      },
      DraftKind.RedCap
    )
    if (this.debug) console.log('deleteDraft response', response)
    this.setState({ hasDraft: false })
    return
  }

  setActiveForm = (activeForm: string): void => {
    this.setState({ lastClickedForm: activeForm })
  }

  handleModuleClick = (componentName: string): void => {
    const activeModule =
      this.state.allMeta[componentName]?.annotation?.instrument_name
    this.updateEdited(componentName, true)
    this.setState({ lastClickedModule: activeModule })
  }

  handleModuleSync = (nextField: FormDynamicComponentProps): void => {
    if (this.state.lastClickedForm) {
      const activeModule = nextField.annotation.instrument_name
      const meta = extractMetaData(this.state.forms)
      const sameModules = getSameModules(meta, activeModule)

      if (sameModules.length > 1) {
        const moduleAction = sameModules.find((value, index) => {
          return value.formName === this.state.lastClickedForm && index > 0
        })

        if (moduleAction) {
          this.setState({
            showSyncModule: true,
            lastClickedModule: activeModule,
          })
        }
      }
    }
  }

  syncModules = (): void => {
    if (this.state.lastClickedModule) {
      let meta
      try {
        meta = extractMetaData(this.state.forms)
      } catch (e) {
        return
      }
      const sameModules = getSameModules(meta, this.state.lastClickedModule)
      const firstModuleComponents = sameModules[0]?.subComponents
      const formName1 = sameModules[0]?.formName
      const formName2 = sameModules[1]?.formName
      const allFields = getAllFields(firstModuleComponents).filter(value =>
        value.startsWith(MODULE_PREFIX)
      )
      this.setState(
        state => {
          const values = state.values ? { ...state.values } : {}
          allFields.forEach(key => {
            const value = values[key]
            const variable = translateModuleVarNames(key, formName1)
            const current = createModuleVarNames(variable, formName2)
            values[current] = value
          })

          return {
            values: values,
            showSyncModule: false,
          }
        },
        () => !this.state.noValidation && this.debouncedValidate()
      )
    }
  }

  closeSyncModule = (): void => {
    this.setState({ showSyncModule: false })
  }

  closeConfirmModule = (): void => {
    this.setState({ showConfirmModule: false })
  }

  registerMedicationUpdates = (
    reason: string,
    medicationUpdates: MedicationUpdates
  ): void => {
    this.setState(state => {
      state.medicationUpdates[reason] = medicationUpdates
      return this.state
    })
  }

  render(): ReactElement {
    if (!this.props.instruments || !this.props.patientId) return null
    if (this.debug) console.log('RENDER', this.state, this.props)
    if (!this.state.forms) return <Loader msg="Event wird geladen" />
    if (this.state.formFetchErrors) {
      return (
        <div className="alert alert-danger">
          Beim Laden des Events traten Fehler auf. Der Administrator wurde
          benachrichtigt.
        </div>
      )
    }
    const { event } = this.state
    return (
      <>
        {this.props.isAutosaving ? (
          <>
            <Autosave<any>
              ref={this.autosaveRef}
              draftkind={DraftKind.RedCap}
              value={{
                id: redCapDraftId(
                  event.recordId,
                  event.name,
                  event.hash_value,
                  event.instruments[0].repeatInstance,
                  event.patientId
                ),
                values: this.state.values,
                title: event.label,
                event: event.name,
                patientId: event.patientId,
                instruments: event.instruments,
                saveMethod: this.props.saveMethod,
              }}
              modifierBeforeSending={it => {
                return {
                  ...it,
                  values: Object.fromEntries(
                    Object.keys(this.state.touched)
                      .filter(field => (this.state.touched ?? {})?.[field])
                      .map(field => [field, it?.values?.[field]])
                  ),
                }
              }}
              modifierBeforeComparison={it =>
                Object.fromEntries(
                  Object.keys(this.state.touched)
                    .filter(field => (this.state.touched ?? {})?.[field])
                    .map(field => [field, it?.values?.[field]])
                )
              }
              onSetDraft={draft => {
                if (!this.state.hasDraft) this.setState({ hasDraft: true })
                this.handleUpdateValues(draft.values)
              }}
              onUpdate={_ => {
                if (!this.state.hasDraft) this.setState({ hasDraft: true })
              }}
              editedMask={this.state.touched}
              flowBeAt={FlowStep.save}
              isSavedNow={this.state.isSaving}
            />
            {this.state.hasDraft ? (
              <div className="d-flex">
                <Button className="mb-2 ml-auto" onClick={this.resetFormular}>
                  Entwurf zurücksetzen
                </Button>
              </div>
            ) : null}
          </>
        ) : null}

        <EventStore.Provider
          value={{
            event: this.state.event,
            forms: this.state.forms,
            values: this.state.values,
            prevValues: this.state.prevValues,
            prevVersions: this.state.prevVersions,
            allMeta: this.state.allMeta,
            errors: this.state.errors,
            touched: this.state.touched,
            edited: this.state.edited,
            setCurrentPreviousVersion: this.setCurrentPreviousVersion,
            currentPreviousVersion: this.state.currentPreviousVersion,
            medicationUpdates: this.state.medicationUpdates,
            registerMedicationUpdates: this.registerMedicationUpdates,
            saveMethod: this.props.saveMethod,
            isSaving: this.state.isSaving,
            firstRender: this.state.firstRender,
            closeSidebar: this.props.closeSidebar,
            showSyncModule: this.state.showSyncModule,
            showConfirmModule: this.state.showConfirmModule,
            updateForm: this.handleUpdateForm,
            setFieldValue: this.handleUpdateValue,
            setValues: this.handleUpdateValues,
            handleChange: this.handleChange,
            setFieldTouched: this.setFieldTouched,
            handleFieldChange: this.handleFieldChange,
            handleSubmit: this.handleSubmit,
            triggerAutosave: this.handleTriggerAutosave,
            setClickedForm: this.setActiveForm,
            setClickedModule: this.handleModuleClick,
            syncModules: this.syncModules,
            closeSyncModule: this.closeSyncModule,
            updateEdited: this.updateEdited,
            closeConfirmModule: this.closeConfirmModule,
            continueAction: this.continueAction,
          }}>
          {this.state.event ? (
            this.props.children
          ) : (
            <Loader msg="Event wird geladen" />
          )}
        </EventStore.Provider>
      </>
    )
  }
}

export function handleNullComponentError(
  exception: string,
  fieldName: string,
  formName: string
): void {
  const message = `Es wurde eine fehlerhafte Komponente im Formular ${formName} entdeckt. Letzte fehlerfreie Komponente ist ${fieldName}.
  Bitte im RedCap Codebook nach dieser Komponente suchen und die nächste auf Fehler untersuchen.`

  const title = 'Fehlerhafte Komponente in Redcap Formular'

  const taskService = new TaskService()
  taskService.createDefaultTaskForMedicalAdmin(message, title)

  NotificationManager.error(
    `Fehlerhafte Komponente in Formular ${formName}. Es wurde eine Aufgabe an den Medical Admin gesendet.`
  )
}

export function withEvents<T>(
  Component: React.ComponentType<T>
): React.ComponentType<Omit<T, keyof EventContextContextAsProps>> {
  const componentWithEvents = (props: T) => {
    return (
      <EventStore.Consumer>
        {store => <Component event={store ? store : null} {...props} />}
      </EventStore.Consumer>
    )
  }
  componentWithEvents.displayName = `withEvents(${
    Component.displayName || Component.name || 'Component'
  })`
  return componentWithEvents
}

export function withEventProvider<TIn, TOut extends TIn & EventContextProps>(
  Component: React.ComponentType<TIn>
): React.ComponentType<TOut> {
  const componentWithEvents = (props: TOut) => {
    const {
      instruments,
      patientId,
      showPreviousValues,
      saveMethod,
      isAutosaving,
      closeSidebar,
      onSaved,
      ...otherprops
    } = props
    /** Is required, beacause TS doesn´t picks the correct props type */
    const other = otherprops as unknown as TIn
    return (
      <DynamicSidebarStore.Consumer>
        {dynamicSidebarStore => (
          <AuthStore.Consumer>
            {authStore => (
              <EventProvider
                instruments={instruments}
                patientId={patientId}
                showPreviousValues={showPreviousValues}
                saveMethod={saveMethod}
                isAutosaving={isAutosaving}
                closeSidebar={closeSidebar}
                onSaved={onSaved}
                dynamicSidebarStore={dynamicSidebarStore}
                authStore={authStore}>
                <Component {...other} />
              </EventProvider>
            )}
          </AuthStore.Consumer>
        )}
      </DynamicSidebarStore.Consumer>
    )
  }
  componentWithEvents.displayName = `withEventProvider(${
    Component.displayName || Component.name || 'Component'
  })`
  return componentWithEvents
}
