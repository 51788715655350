import React, { ChangeEvent, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

export interface CheckboxProps {
  name: string
  id: string
  value: string | null
  disabled?: boolean
  onChangeEvent?: (event: ChangeEvent<HTMLInputElement>) => void
  rowValues: Record<string, boolean>
}

const MatrixCheckbox: React.FC<CheckboxProps> = ({
  name,
  value,
  disabled,
  onChangeEvent,
  rowValues,
}: CheckboxProps) => {
  const checked = rowValues && rowValues[value] ? rowValues[value] : false

  const [uniqueKey] = useState<string>(uuidv4())
  const checkboxId = `${name}_${value}_${uniqueKey}`
  return (
    <label
      htmlFor={uniqueKey}
      className="form-check position-absolute d-flex align-items-center justify-content-center"
      style={{
        cursor: !disabled ? 'pointer' : 'default',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}>
      <input
        className="form-check-input"
        type="checkbox"
        name={`${name}.${value}`}
        id={checkboxId}
        value={value}
        disabled={disabled}
        onChange={onChangeEvent}
        checked={checked}
      />
    </label>
  )
}

export default MatrixCheckbox
