import React, { Component } from 'react'
import { Task } from '../../../types/Task'
import { AuthStore } from '../../../infrastructure/AuthProvider'
import { TaskStatus } from '../../../constants/TaskStatus'
import { Patient } from '../../../types/Patient'
import { Practitioner } from '../../../types/Practitioner'
import { APIResult } from '../../../constants/APIResult'
import { ApiResponse } from '../../../types/ApiResponse'
import { ApiError } from '../../../types/ApiError'
import { NotificationManager } from 'react-notifications'
import BigButton from '../../UI/BigButton/BigButton'
import TaskService from '../../../services/TaskService/TaskService'
import TaskFieldsView from './TaskFieldsView'
import { AutosaveService } from '../../../services/AutosaveService'

import { DraftKind } from '../../../types/DraftKind'
import Autosave, { FlowStep } from '../../Draft/Autosave/Autosave'
import { UserPermission } from 'src/constants/UserPermission'

interface State {
  casemanager: Practitioner
  originalTask: Task
  newTask: Task
  isSavedNow: boolean
}

interface Props {
  editMode?: boolean
  patientId?: string
  task?: Task

  onClose?: () => void
  onChanged: (task?: Task) => void
  handleTaskValueChange?: (task?: Task) => void
}

class TaskForm extends Component<Props, State> {
  static contextType = AuthStore

  constructor(props: Props) {
    super(props)

    const origTask = this.props.task
      ? { ...this.props.task }
      : this.defaultTask()

    this.state = {
      casemanager: null,
      originalTask: origTask,
      newTask: origTask,
      isSavedNow: false,
    }
  }

  componentDidMount(): void {
    if (
      !this.context.handlers.hasPermission(
        UserPermission.TASK_CREATE_FOR_OTHER_USERS
      )
    ) {
      this.setState({
        originalTask: {
          ...this.state.originalTask,
          ownerDto: this.context.auth.user,
        },
        newTask: {
          ...this.state.newTask,
          ownerDto: this.context.auth.user,
        },
      })
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { task } = this.props
    if (prevProps.task !== task) {
      this.setState({
        //originalTask: task,
        newTask: task,
      })
    }
  }

  updateThis(response: ApiResponse<Task | ApiError>): void {
    if (response.Result === APIResult.SUCCESS) {
      NotificationManager.success('Aufgabe wurde gespeichert')
      if (!this.props.task) {
        this.setState({
          newTask: this.defaultTask(),
          casemanager: null,
          isSavedNow: true,
        })
      } else {
        this.setState({
          newTask: {
            ...this.state.newTask,
            id: (response.Response as Task).id,
          },
          casemanager: null,
          isSavedNow: true,
        })
      }
      this.props.onChanged(response.Response as Task)
    } else {
      NotificationManager.error(
        'Fehler',
        'Daten konnten nicht gespeichert werden werden'
      )
    }
  }

  private defaultTask() {
    const newTask: Task = {
      status: TaskStatus.DRAFT,
      title: '',
      description: '',
      deadline: null,
      forDto: null,
      ownerDto: null,
    }
    return newTask
  }

  handleInputChange = (
    fieldname: string,
    value: string | Date | Patient | Practitioner
  ): void => {
    const newTask = { ...this.state.newTask }
    if (fieldname === 'patient') {
      const patient = value as Patient
      newTask.forDto = patient
      if (
        value &&
        this.context.handlers.hasPermission(
          UserPermission.TASK_CREATE_FOR_OTHER_USERS
        )
      ) {
        newTask.ownerDto = patient.caseManagerDto || null
      }
    } else if (
      fieldname !== 'ownerDto' ||
      this.context.handlers.hasPermission(
        UserPermission.TASK_CREATE_FOR_OTHER_USERS
      )
    ) {
      newTask[fieldname] = value
    }
    this.setState({
      newTask,
    })
  }

  private handleSubmit = async () => {
    const newTask = {
      ...this.state.newTask,
      status: TaskStatus.READY,
    }
    if (this.props.patientId) {
      newTask.forDto = { id: this.props.patientId }
    }

    if (this.state.newTask.id) {
      await this.onUpdate(newTask)
    } else {
      await this.onSave(newTask)
    }
  }

  onSave = (task: Task = null): Promise<ApiResponse<Task | ApiError>> => {
    const newTask = { ...task }
    newTask.id = null

    return new TaskService().postTask(newTask).then(response => {
      this.updateThis(response)
      return response
    })
  }

  onDelete = (
    task: Task = null
  ): Promise<void | ApiResponse<Task | ApiError>> => {
    const { firstName, lastName } = this.context.auth
    const userName = `${lastName} ${firstName}`

    return new TaskService().deleteTask(task, userName).then(response => {
      this.updateThis(response)
    })
  }

  onUpdate = (task: Task = null): Promise<ApiResponse<Task | ApiError>> => {
    const newTask = { ...task }

    return new TaskService().putTask(newTask).then(response => {
      this.updateThis(response)
      return response
    })
  }

  removeDraftForThis = async (): Promise<void> => {
    this.setState({
      newTask: Object.assign(new Task(), this.state.originalTask),
    })
    if (this.props.handleTaskValueChange)
      this.props.handleTaskValueChange(
        Object.assign(new Task(), this.state.originalTask)
      )
    return await new AutosaveService().deleteValue(this.state.newTask)
  }

  resetFormular = (): void => {
    const { newTask, originalTask } = this.state

    if (newTask !== originalTask) {
      this.setState(
        {
          newTask: { ...originalTask },
        },
        () => {
          /* if you edit an existing task */
          if (this.props.task) {
            this.props.onChanged(originalTask)
          } else {
            this.props.onClose()
          }
        }
      )
    } else {
      if (this.props.task) {
        this.props.onChanged()
      } else {
        this.props.onClose()
      }
    }
  }

  handleDraftUpdate = (task: Task): void => {
    this.setState({
      newTask: {
        ...task,
        owner: this.state.newTask.owner,
      },
    })
    this.props.handleTaskValueChange(Object.assign(new Task(), task))
  }

  handleDraftAutoUpdate = (task: Task): void => {
    this.props.handleTaskValueChange(Object.assign(new Task(), task))
  }

  handleDraftAutoDelete = (task: Task): void => {
    this.props.handleTaskValueChange(Object.assign(new Task(), task))
  }

  render(): JSX.Element {
    return (
      <div className="task-detail">
        {this.props.editMode && this.props.task?.id ? (
          <Autosave<Task>
            value={{ ...this.state.newTask }}
            draftkind={DraftKind.Task}
            onSetDraft={this.handleDraftUpdate}
            onUpdate={this.handleDraftAutoUpdate}
            onDelete={this.handleDraftAutoDelete}
            flowBeAt={FlowStep.save}
            isSavedNow={this.state.isSavedNow}
          />
        ) : null}

        <div className="text-right">
          {this.state.newTask.tempId ? (
            <BigButton
              icon="settings_backup_restore"
              onClick={this.removeDraftForThis}>
              <>
                Entwurf
                <br />
                löschen
              </>
            </BigButton>
          ) : null}
          <BigButton icon="cancel_presentation" onClick={this.resetFormular}>
            Abbrechen
          </BigButton>
          <BigButton icon="save" onClick={this.handleSubmit}>
            Speichern
          </BigButton>
        </div>
        <TaskFieldsView
          newTask={this.state.newTask}
          handleInputChange={this.handleInputChange}
          patientId={this.props.patientId}
          editMode={this.props.editMode}
          staticFields={
            this.context.handlers.hasPermission(
              UserPermission.TASK_CREATE_FOR_OTHER_USERS
            )
              ? []
              : ['ownerDto']
          }
        />
      </div>
    )
  }
}

export default TaskForm
