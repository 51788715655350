import React, { CSSProperties, RefObject } from 'react'
import './DynamicSidebar.scss'
import {
  DynamicSidebarContent,
  DynamicSidebarStore,
} from '../../../infrastructure/DynamicSidebarProvider'
import { defaultMinSidebarWidth } from '../../../constants/DynamicSidebarConstants'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import DynamicSidebarColumn from './DynamicSidebarColumn'

interface DynamicSidebarState {
  width: number
  isDragging: boolean
  numberOfDisplayedChildren: number
}

let dynamicSidebarLastMousePosition = 0

class DynamicSidebarRenderer extends React.Component<
  Record<string, unknown>,
  DynamicSidebarState
> {
  slideRef: RefObject<HTMLDivElement> = React.createRef()
  state = {
    width: defaultMinSidebarWidth,
    isDragging: false,
    numberOfDisplayedChildren: 0,
  }

  componentDidUpdate(
    _: Record<string, unknown>,
    prevState: DynamicSidebarState
  ): void {
    if (prevState.isDragging !== this.state.isDragging) {
      if (this.state.isDragging) {
        document.addEventListener('mousemove', this.handleMouseMove, false)
        document.addEventListener('mouseup', this.handleMouseUp, false)
      } else {
        document.removeEventListener('mousemove', this.handleMouseMove, false)
        document.removeEventListener('mouseup', this.handleMouseUp, false)
      }
    }
    if (this.context.content.length !== this.state.numberOfDisplayedChildren) {
      const newState: DynamicSidebarState = {
        numberOfDisplayedChildren: this.context.content.length,
        isDragging: prevState.isDragging,
        width: prevState.width,
      }
      if (prevState.numberOfDisplayedChildren === 0) {
        newState.isDragging = false
        newState.width = defaultMinSidebarWidth
        setTimeout(() => this.context.handlers.setCollapsed(false), 100)
      } else if (this.context.content.length === 0) {
        document.removeEventListener('mousemove', this.handleMouseMove, false)
        document.removeEventListener('mouseup', this.handleMouseUp, false)
      }
      this.setState(newState)
    }
  }

  onClose = (input: string): void => {
    const identifier = (' ' + input).slice(1)
    if (this.context.content.length === 1) {
      this.context.handlers.setCollapsed(true)
    }
    const contentElement = this.context.content.find(
      element => element.identifier === identifier
    )
    if (contentElement !== undefined) {
      setTimeout(() => {
        if (contentElement.onClose !== undefined) {
          contentElement.onClose()
        }
        this.context.handlers.removeContent(identifier)
      }, 500)
    }
  }

  handleOpenToggle = (): void => {
    this.context.handlers.setCollapsed(!this.context.collapsed)
  }

  handleMouseUp = (): void => {
    this.setState({ isDragging: false })
  }

  handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    dynamicSidebarLastMousePosition = e.pageX
    this.setState({
      isDragging: true,
    })
  }

  /** max & min initialWidth is solved via CSS */
  handleMouseMove = (e: MouseEvent): void => {
    if (this.slideRef.current) {
      const movedX = dynamicSidebarLastMousePosition - e.pageX
      const newWidth: number =
        parseInt(getComputedStyle(this.slideRef.current, '').width) + movedX
      dynamicSidebarLastMousePosition = e.pageX
      this.slideRef.current.style.width = newWidth + 'px'
    }

    let index = 0
    this.context.content.forEach(column => {
      const newContent = { ...column, initialWidth: null }
      this.context.handlers.updateContent(newContent, index)
      index++
    })
  }

  render(): JSX.Element {
    const style: CSSProperties = {}

    if (this.state.width > defaultMinSidebarWidth) {
      style.minWidth = this.state.width + 'px'
    }

    /**
     * For animation. Its currently deactivated.
     * When an content element changes its size after render, it breaks the visual style
     * if (this.context.collapsed && this.slideRef.current) {
     * style.right =
     *   '-' + parseInt(getComputedStyle(this.slideRef.current, '').width) + 'px'
     * }
     **/

    if (this.context.content.length === 0) return null

    return (
      <div className="DynamicSidebar">
        <div
          className={`DynamicSidebarContainer ${
            this.context.collapsed && 'collapsed'
          }`}
          ref={this.slideRef}
          style={style}>
          <div
            className="DynamicSidebarSlide"
            onMouseDown={this.handleMouseDown}></div>
          <button
            className={`btn btn-light hide btn-sm ${
              this.context.collapsed && 'collapsed'
            }`}
            onClick={this.handleOpenToggle}>
            <MaterialIcon
              icon={'keyboard_tab'}
              mirrored={this.context.collapsed}
            />
          </button>
          <button className="btn btn-light hide btn-sm counter">
            {this.state.numberOfDisplayedChildren}
          </button>
          <div className="DynamicSidebarContent d-flex">
            {this.context.content.map((content: DynamicSidebarContent) => {
              return (
                <DynamicSidebarColumn
                  key={content.identifier}
                  content={content}
                  onClose={this.onClose}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

DynamicSidebarRenderer.contextType = DynamicSidebarStore

export default DynamicSidebarRenderer
