import { useCallback, useContext, useEffect, useState } from 'react'
import FormikValidationBranchingContext, {
  BranchingValidationContext,
} from '../components/FieldFunctionComponents/ValidationBranchingContext'
import { evaluateBranch } from '../utils/EvaluateBranchingUtils'
import { EventStore } from '../infrastructure/EventProviderInterfaces'
import { FieldTypes } from '../constants/FieldTypes'

export interface UseEvaluateBranchingProps {
  name: string
}

export function useEvaluateBranching({
  name,
}: UseEvaluateBranchingProps): [boolean, Error] {
  const branchingValidationContext = useContext<BranchingValidationContext>(
    FormikValidationBranchingContext
  )
  const [show, _setShow] = useState<boolean>(null)
  const [error, setError] = useState<Error>(null)

  const context = useContext(EventStore)
  const { field_type } = context.allMeta[name]

  const setShow = useCallback(
    (newShow: boolean) => {
      if (newShow !== show) {
        if (branchingValidationContext)
          branchingValidationContext.registerComponent(name, newShow)
        _setShow(newShow)
        if (field_type === FieldTypes.FIELD_INSTRUMENT)
          context.updateEdited(name, newShow)
      }
    },
    [branchingValidationContext, name, show, context, field_type]
  )

  useEffect(() => {
    evaluateBranch(
      name,
      context.values,
      context.allMeta,
      show,
      setShow,
      error,
      setError,
      context.setFieldValue
    )
  }, [
    context.values,
    context.allMeta,
    context.setFieldValue,
    error,
    setError,
    name,
    setShow,
    show,
  ])

  return [show, error]
}
