export enum ExtensionURL {
  medicationRequestReason = 'https://panos.de/fhir/extension/medicationRequest/reason',
  medicationRequestHistoryReason = 'https://panos.de/fhir/extension/medicationRequest/historyReason',
  medicationRequestArrangementReason = 'https://panos.de/fhir/extension/medicationRequest/arrangementReason',

  medicationRequestMinimalDistance = 'https://panos.de/fhir/extension/medicationRequest/minimalDistance',
  medicationRequestMinimalDistanceUnit = 'https://panos.de/fhir/extension/medicationRequest/minimalDistanceUnit',

  medicationRequestDosageChange = 'https://panos.de/fhir/extension/medicationRequest/dosageChange',

  activityDefinitionPackageDosageVariants = 'https://panos.de/fhir/extension/activityDefinition/packageDosageVariants',
  activityDefinitionMedicationResourcesList = 'https://panos.de/fhir/extension/activityDefinition/medicationResourcesList',

  medicationRequestProposedStatus = 'https://panos.de/fhir/extension/medicationRequest/proposedStatus',
}
