export enum UserRole {
  PATIENT = 'patient',
  CASE_MANAGER = 'case-manager',
  CASE_MANAGER_HEAD = 'case-manager-head',
  PANOS_PHYSICIAN = 'panos-physician',
  MEDICAL_ADMIN = 'medical-admin',
  NETWORK_MANAGER = 'network-manager',
  PATIENT_SCHOOL_HEAD = 'patient-school-head',
  QUALITY_MANAGER = 'quality-manager',
  RESEARCH_ASSISTANT = 'research-assistant',
  TECHNICAL_ADMIN = 'technical-admin',
  TREATING_PHYSICIAN = 'treating-physician',
  TREATING_PHYSICIAN_UNVERIFIED = 'treating-physician-unverified',
  ASSISTANT = 'assistant',
  MEDICAL_TECHNICAL_ASSISTANT = 'medical-technical-assistant',
}

const StringIsNumber = value => isNaN(Number(value)) === false

export function UserRoleToArray(): string[] {
  return Object.keys(UserRole)
    .filter(StringIsNumber)
    .map(key => UserRole[key])
}
