import React, { Component, ReactNode } from 'react'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'
import { WebsocketService } from '../services/WebsocketService/WebsocketService'
import { AuthStore } from './AuthProvider'
import {
  webSocketLoginSendEndpoint,
  webSocketQueueEndpoint,
  webSocketReceiveSendEndpoint,
} from '../config/Paths'
import { PushMessage } from '../components/PushNotifications/PushMessage'
import {
  NotifierProps,
  SubscriptionType,
  withSubscriptionNotifier,
} from './SubscriptionProvider'

interface NotificationReceiverState {
  messages?: string[]
}

class NotificationReceiver extends Component<
  NotifierProps,
  NotificationReceiverState
> {
  state: NotificationReceiverState = {
    messages: [],
  }
  static contextType = AuthStore
  private webSocketService = WebsocketService.getInstance(this.context.auth)

  componentDidMount(): void {
    if (this.context.auth && this.context.auth.authenticated === true) {
      this.webSocketService.connect(
        () => {
          this.webSocketService &&
            this.webSocketService.subscribe(webSocketQueueEndpoint, message => {
              if (message.body) {
                const notification = JSON.parse(message.body)
                this.props.notifiySubscriptions(
                  SubscriptionType.Notifications,
                  notification.kind,
                  notification
                )
                if (
                  !notification.conversationId ||
                  !window.location.href.includes(
                    notification.conversationId?.replace('Communication/', '')
                  )
                ) {
                  this.showNotification(notification)
                }
                this.setState({
                  messages: [...this.state.messages, notification],
                })
                this.sendReceiveMessage(notification.id)
              } else {
                console.warn('Received empty message', message)
              }
            })

          // send initial message to get jobs
          this.sendLoginMessage()
        },
        () => {
          this.state.messages.push('disconnect')
        },
        () => {
          this.state.messages.push('error')
        }
      )
    }
  }

  private sendLoginMessage = () => {
    this.webSocketService &&
      this.webSocketService.sendMessage(
        webSocketLoginSendEndpoint,
        JSON.stringify({ subject: this.context.auth.keycloak.subject })
      )
  }

  private sendReceiveMessage = (id: string) => {
    this.webSocketService &&
      this.webSocketService.sendMessage(
        webSocketReceiveSendEndpoint,
        JSON.stringify({ id: id })
      )
  }

  private showNotification(message) {
    NotificationManager.info(<PushMessage message={message} />, '', 10000, () =>
      this.dropMessage(message)
    )
  }

  private dropMessage(message: any) {
    this.setState({
      messages: this.state.messages.filter(
        message_in => !_.isEqual(message_in, message)
      ),
    })
  }

  render(): ReactNode {
    return this.props.children || null
  }
}

export default withSubscriptionNotifier(NotificationReceiver)
