import { cloneDeep } from 'lodash'
import {
  DosageAndTimingSelectorProps,
  TimingAndDosage,
} from './DosageAndTimingSelector'

export function SetTimingAndDosage(
  newTimingAndDosage: TimingAndDosage,
  props: DosageAndTimingSelectorProps
): void {
  const editTimeInterval = cloneDeep(props.timeInterval)
  editTimeInterval.timingAndDosage = newTimingAndDosage
  props.arrayHelpers.replace(props.index, editTimeInterval)
}
