import { URL } from 'url'
import { ApiResponse } from '../types/ApiResponse'
import { APIResult } from '../constants/APIResult'
import { ApiError } from '../types/ApiError'
import { getToken } from './Keycloak'

function _arrayBufferToBase64(buffer) {
  return btoa(
    new Uint8Array(buffer).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  )
}

export function _base64ToArrayBuffer(base64: string): ArrayBufferLike {
  const string = window.atob(base64)
  const byteArray = new Uint8Array(string.length)
  for (let i = 0; i < string.length; i++) {
    byteArray[i] = string.charCodeAt(i)
  }
  return byteArray.buffer
}

export enum API_RETURN {
  json,
  raw,
  text,
  blob,
}

abstract class ApiServiceBase {
  private _headers: string[][] = []
  controller: AbortController
  signal: any

  constructor() {
    this._headers.push(['Accept', 'application/json'])
    this._headers.push(['Content-Type', 'application/json'])
    this._headers.push([
      'Origin',
      document.location.protocol + '://' + document.location.host,
    ])

    this.delete = this.delete.bind(this)

    const AbortController = window.AbortController
    this.controller = new AbortController()
    this.signal = this.controller.signal
  }

  fetchWithErrorHandling<T>(
    url: string,
    requestBody: RequestInit,
    api_return: API_RETURN = API_RETURN.json
  ): Promise<ApiResponse<T> | ApiResponse<ApiError>> {
    let status
    return fetch(url, requestBody)
      .then(response => {
        status = APIResult.FAILURE
        if (response.status >= 200 && response.status <= 226) {
          status = APIResult.SUCCESS
        }

        //FIXME: We have to check the content type before doing anything tih the response
        switch (api_return) {
          case API_RETURN.json:
            return response.text().then(data => {
              try {
                return JSON.parse(data)
              } catch {
                return data
              }
            })
          case API_RETURN.text:
            return response.text()
          case API_RETURN.blob:
            return response.blob()
          case API_RETURN.raw:
            return response.arrayBuffer().then(res => _arrayBufferToBase64(res))
          default: {
            console.log('other return result', response)
            return response.json()
          }
        }
      })
      .then(data => {
        return {
          Result: status,
          Response: data,
          isAborted: false,
        }
      })
      .catch(err => {
        if (requestBody.signal.aborted) {
          console.warn(
            'Request was aborted in deliberate manner',
            url,
            err,
            requestBody
          )
          return {
            Result: status,
            Response: err,
            isAborted: requestBody.signal.aborted,
          }
        } else {
          console.log(`Failue fetching ` + url, err, requestBody)
          return {
            Result: status,
            Response: err,
            isAborted: requestBody.signal.aborted,
          }
        }
      })
  }

  createErrorResponse(
    errorCode: string,
    errorMessage: string
  ): ApiResponse<ApiError> {
    const error: ApiError = {
      ErrorCode: errorCode,
      ErrorMessage: errorMessage,
    }

    return {
      Result: APIResult.FAILURE,
      Response: error,
      isAborted: false,
    }
  }

  get<T, R>(
    url: URL,
    body: T = null,
    api_return = API_RETURN.json
  ): Promise<ApiResponse<R | ApiError>> {
    this._headers.push(['Access-Control-Request-Method', 'GET'])

    const requestBody: RequestInit = this.request<T>(body, 'get')
    return this.fetchWithErrorHandling<R>(
      url.toString(),
      requestBody,
      api_return
    )
  }

  post<T, R>(
    url: URL,
    urlParams:
      | string
      | string[][]
      | Record<string, string>
      | URLSearchParams = null,
    body: T,
    api_return = API_RETURN.json,
    isFormData = false
  ): Promise<ApiResponse<R | ApiError>> {
    this._headers.push(['Access-Control-Request-Method', 'POST'])

    const requestBody: RequestInit = this.request<T>(body, 'post', isFormData)
    let newUrl = url.toString()
    let queryParams
    if (urlParams) {
      queryParams = new URLSearchParams(urlParams)
      newUrl = newUrl + '?' + queryParams.toString()
    }
    return this.fetchWithErrorHandling<R>(newUrl, requestBody, api_return)
  }

  put<T>(url: URL, body: T): Promise<ApiResponse<T | ApiError>> {
    return this._put<T, T>(url, body)
  }
  _put<T, R>(url: URL, body: T): Promise<ApiResponse<R | ApiError>> {
    this._headers.push(['Access-Control-Request-Method', 'PUT'])

    const requestBody: RequestInit = this.request<T>(body, 'put')
    return this.fetchWithErrorHandling<R>(url.toString(), requestBody)
  }

  delete<T>(url: URL, body: T = null): Promise<ApiResponse<string | ApiError>> {
    this._headers.push(['Access-Control-Request-Method', 'DELETE'])

    const requestBody: RequestInit = this.request<T>(body, 'delete')
    return fetch(url.toString(), requestBody).then(data => {
      if (data.status >= 200 && data.status < 300) {
        const res: ApiResponse<string> = {
          Result: APIResult.SUCCESS,
          Response: data.statusText,
        }
        return res
      } else {
        const error: ApiError = {
          ErrorCode: data.status.toString(),
          ErrorMessage: data.statusText,
        }

        const res: ApiResponse<ApiError> = {
          Result: APIResult.FAILURE,
          Response: error,
        }

        return res
      }
    })
  }

  request<T>(body: T = null, method: string, isFormData = false): RequestInit {
    let headers = [...this._headers]
    if (isFormData)
      headers = [
        ...headers.filter(it => !(it[0] === 'Content-Type')), // The browser will set it itself
      ]

    const token = getToken()
    if (token) headers.push(['Authorization', `Bearer ${token}`])
    return {
      headers: [...headers],
      method: method,
      body: body
        ? !isFormData
          ? JSON.stringify(body)
          : (body as unknown as FormData)
        : null,
      signal: this.signal,
    }
  }
}

export default ApiServiceBase
