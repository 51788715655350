import React, { useContext, useEffect, useState } from 'react'
import { AppointmentCategories } from '../../constants/AppointmentCategories'
import { AuthStore, withAuth } from '../../infrastructure/AuthProvider'
import { Appointment, AppointmentDto } from '../../types/Appointment'
import { Task } from '../../types/Task'
import TaskFieldsView from '../Tasks/TaskForm/TaskFieldsView'
import TabsSimple, { ITabSimple } from '../UI/TabsComponent/TabsSimple'
import { cloneDeep } from 'lodash'
import './OrganizerModal.scss'
import { calcMinutesDuration } from '../../utils/CalendarUtils'
import IAuth from '../../types/Auth'
import { ParticipationStatus } from '../../constants/ParticipationStatus'
import { FieldTerminAufgabeProps } from './FieldTerminAufgabe'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { verifyCastAppointment } from '../../services/AppointmentService'
import {
  FieldTerminAufgabeDraft,
  FieldTerminAufgabeStore,
} from './FieldTerminAufgabeProvider'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { UserRole } from '../../constants/UserRole'
import { TaskCategories } from '../../constants/TaskCategories'
import { TaskStatus } from '../../constants/TaskStatus'
import { AppointmentFields } from '../../constants/AppointmentFields'
import { TaskFields } from '../../constants/TaskFields'
import OrganizerAppointmentForm from './OrganizerAppointmentForm'
import { AppointmentFormErrors } from '../../types/AppointmentFormErrors'
import { TaskFormErrors } from 'src/types/TaskFormErrors'
import { FieldDraftErrors } from '../../types/FieldDraftErrors'

function makeActiveTabKey(title: string, type: string, index: number): string {
  return `${title}_${type}_${index}`
}

export enum OrganizerModalType {
  FIELD_TERMIN,
  FIELD_AUFGABE,
  FIELD_TERMIN_AUFGABE,
  FIELD_TERMIN_AUFGABE_COMBI,
}

interface FieldOrganizerProps extends FieldTerminAufgabeProps {
  show: boolean
  onAbort: () => void
  auth: IAuth
  type: OrganizerModalType
  onSubmit: (
    tempId: string,
    taskTitle?: string,
    appointmentTitle?: string
  ) => void
  onEdit: (id: '') => void
}

const OrganizerModal: React.FC<FieldOrganizerProps> = ({
  show,
  links,
  initialAppointments,
  initialTasks,
  onAbort,
  auth,
  type,
  onSubmit,
}: FieldOrganizerProps) => {
  const [activeTab, setActiveTab] = useState<string>()
  const [tabs, setTabs] = useState<Array<ITabSimple>>([])
  const [appointments, setAppointments] = useState<Array<Appointment>>()
  const [tasks, setTasks] = useState<Array<Task>>(initialTasks)
  const fieldTerminAufgabeContext = useContext(FieldTerminAufgabeStore)
  const userContext = useContext(AuthStore)
  const [errors, setErrors] = useState<FieldDraftErrors>(null)

  // initialize tabs
  useEffect(() => {
    const newAppointments = initialAppointments
      ? cloneDeep(initialAppointments).map(
          (appointment: Appointment): Appointment => {
            const verifiedAppointment = verifyCastAppointment(appointment)
            verifiedAppointment.startingDateTime.setHours(8)
            return verifiedAppointment
          }
        )
      : null
    const appointmentTabs = newAppointments
      ? newAppointments.map(
          (appointment: Appointment, index: number): ITabSimple => {
            const id = makeActiveTabKey(appointment.title, 'appointment', index)
            return { title: appointment.title, key: id, errors: false }
          }
        )
      : []
    let taskTabs = []
    if (type === OrganizerModalType.FIELD_TERMIN_AUFGABE_COMBI) {
      setTasks([])
    } else {
      taskTabs = initialTasks
        ? initialTasks.map((task: Task, index: number): ITabSimple => {
            const id = makeActiveTabKey(task.title, 'task', index)
            return { title: task.title, key: id, errors: false }
          })
        : []
    }
    setAppointments(newAppointments)
    const newTabs = appointmentTabs.concat(taskTabs)
    setTabs(newTabs)
    if (newTabs && newTabs[0] && newTabs[0].key) setActiveTab(newTabs[0].key)
  }, [initialTasks, initialAppointments, type])

  /**
   * move one tab forward
   */
  const onContinue = (): void => {
    const length = tabs.length
    if (!tabs || length === 0) return
    let index = tabs.findIndex(tab => activeTab === tab.key)
    if (index < 0) {
      setActiveTab(tabs[0].key)
      return
    }
    index = index + 1
    if (index < length) {
      return setActiveTab(tabs[index].key)
    }
    return setActiveTab(tabs[length - 1].key)
  }

  /**
   * move one tab backward
   */
  const onBack = (): void => {
    const length = tabs.length
    if (!tabs || length === 0) return
    let index = tabs.findIndex(tab => activeTab === tab.key)
    if (index < 1) {
      setActiveTab(tabs[0].key)
      return
    }
    index = index - 1
    return setActiveTab(tabs[index].key)
  }

  const handleChangeTask = (
    taskIndex: number,
    fieldname: string,
    value: string | Date
  ) => {
    const newTasks = cloneDeep(tasks)
    newTasks[taskIndex][fieldname] = value
    setTasks(newTasks)
  }

  const handleChangeAppointment = (
    appointmentIndex: number,
    fieldname: string,
    value: string | number | Date | AppointmentCategories | Location | boolean
  ): void => {
    if (
      fieldname === 'category' &&
      (value === AppointmentCategories.ABSENCE ||
        value === AppointmentCategories.PRIVATE)
    ) {
      if (value !== appointments[appointmentIndex].category) {
        cleanParticipants(appointmentIndex)
      }
    }
    const newAppointments = cloneDeep(appointments)
    newAppointments[appointmentIndex][fieldname] = value
    setAppointments(newAppointments)
  }

  const onChangeEndDate = (appointmentIndex: number, end: Date): void => {
    const newAppointments = cloneDeep(appointments)
    newAppointments[appointmentIndex]['minutesDuration'] = calcMinutesDuration(
      newAppointments[appointmentIndex].startingDateTime,
      end
    )
    setAppointments(newAppointments)
  }

  const cleanParticipants = (appointmentIndex: number): void => {
    const newAppointments = cloneDeep(appointments)
    const appointment = newAppointments[appointmentIndex]
    appointment['participants'] = [
      {
        id: 'Practitioner/' + auth.panosId,
        firstName: auth.user.firstName,
        lastName: auth.user.lastName,
        participationStatus: ParticipationStatus.ACCEPTED,
      },
    ]
    setAppointments(newAppointments)
  }

  const requiredFieldsAppointment = [
    AppointmentFields.TITLE,
    AppointmentFields.CATEGORY,
    AppointmentFields.PRIORITY,
    AppointmentFields.STARTINGDATETIME,
    AppointmentFields.MINUTESDURATION,
  ]

  const requiredFieldsTask = [
    TaskFields.TITLE,
    TaskFields.CATEGORY,
    TaskFields.PRIORITY,
    TaskFields.DESCRIPTION,
    TaskFields.OWNERDTO,
    TaskFields.FORDTO,
    TaskFields.START_DATE,
    TaskFields.DEADLINE,
  ]

  const mappingAppointmentToTaskCategory = (
    appointmentCategory: AppointmentCategories
  ): TaskCategories => {
    switch (appointmentCategory) {
      case AppointmentCategories.MEDICAL_ROUND_NONMEDICALLY:
        return TaskCategories.MEDICAL_ROUND_NONMEDICALLY
      case AppointmentCategories.MEDICAL_ROUND_MEDICALLY:
        return TaskCategories.MEDICAL_ROUND_MEDICALLY
      case AppointmentCategories.CONTACT_LICENSED:
        return TaskCategories.CONTACT_LICENSED
      case AppointmentCategories.CONTACT_PATIENT:
        return TaskCategories.CONTACT_PATIENT
      case AppointmentCategories.CONTACT_OTHER:
        return TaskCategories.CONTACT_OTHER
      case AppointmentCategories.MEETING:
        return TaskCategories.MEETING
      case AppointmentCategories.PATIENT_SCHOOL:
        return TaskCategories.PATIENT_SCHOOL
      case AppointmentCategories.MOTOR_MEASUREMENT:
        return TaskCategories.MOTOR_MEASUREMENT
      default:
        return TaskCategories.MISCELLANEOUS
    }
  }

  const createAppointmentTaskCombination = () => {
    const clonedAppointments = cloneDeep(appointments)
    const createdTasks: Task[] = clonedAppointments.map(appointment => {
      let owner = appointment.participants.find(
        participant => participant.role === UserRole.CASE_MANAGER
      )
      if (!owner) {
        owner = appointment.participants.find(
          participant => participant.role !== UserRole.PATIENT
        )
      }

      return {
        id: null,
        title: appointment.title,
        priority: appointment.priority,
        category: mappingAppointmentToTaskCategory(appointment.category),
        description: appointment.description,
        status: TaskStatus.READY,
        start_date: new Date(),
        links: appointment.links,
        ownerDto: owner,
        forDto: appointment.participants.find(
          it => it.role === UserRole.PATIENT
        ),
        author: userContext?.auth?.panosId
          ? `Practitioner/${userContext?.auth?.panosId}`
          : null,
        deadline: appointment.startingDateTime,
        tempLinkIds: appointment.tempLinkIds,
      } as Task
    })
    return createdTasks
  }

  const handleSave = (): void => {
    let fieldDraft: FieldTerminAufgabeDraft
    if (type === OrganizerModalType.FIELD_TERMIN_AUFGABE_COMBI) {
      const tasksFromCombi = createAppointmentTaskCombination()
      fieldDraft = {
        form: fieldTerminAufgabeContext?.form,
        tasks: tasksFromCombi,
        appointments: appointments?.length > 0 ? appointments : null,
        links: links?.entries() ? links : null,
        tempId: uuidv4(),
      }
    } else {
      fieldDraft = {
        form: fieldTerminAufgabeContext?.form,
        tasks: tasks?.length > 0 ? tasks : null,
        appointments: appointments?.length > 0 ? appointments : null,
        links: links?.entries() ? links : null,
        tempId: uuidv4(),
      }
    }
    console.log('fieldDraft', fieldDraft)

    const isValid = draftsAreValid(fieldDraft)
    if (isValid) {
      fieldTerminAufgabeContext.addDraft(fieldDraft)
      const taskTitles = fieldDraft?.tasks
        ?.map(value => {
          return value.title
        })
        .join('; ')
      const appointmentTitles = fieldDraft?.appointments
        ?.map(value => {
          return value.title
        })
        .join('; ')
      onSubmit(taskTitles, appointmentTitles, fieldDraft.tempId)
      onAbort()
    }
  }

  const draftsAreValid = (fieldDraft: FieldTerminAufgabeDraft) => {
    const newErrors: FieldDraftErrors = {
      appointmentErrors: null,
      taskErrors: null,
    }

    if (fieldDraft.appointments) {
      newErrors.appointmentErrors = propertiesMissing(
        fieldDraft.appointments,
        requiredFieldsAppointment
      )

      fieldDraft?.appointments.forEach(
        (appointment: Appointment | AppointmentDto, index: number) => {
          const start = moment(appointment.startingDateTime)
          const end = moment(start).add(appointment.minutesDuration, 'minutes')

          let isInConflict = false
          fieldDraft?.appointments.forEach(
            (otherAppointment: Appointment | AppointmentDto) => {
              if (otherAppointment !== appointment) {
                const otherStart = moment(otherAppointment.startingDateTime)
                const otherEnd = moment(otherStart).add(
                  otherAppointment.minutesDuration,
                  'minutes'
                )
                if (otherStart.isBetween(start, end)) {
                  isInConflict = true
                } else isInConflict = otherEnd.isBetween(start, end)
              } else {
                isInConflict = false
              }
            }
          )
          if (isInConflict) {
            newErrors.appointmentErrors[index].startingDateTime =
              'Der Termin steht im zeitlichen Konflikt mit anderen Entwürfen, welche gerade von Ihnen geplant werden'
          }
        }
      )

      fieldDraft.appointments.forEach(
        (appointment: Appointment | AppointmentDto, index: number) => {
          if (moment(appointment.startingDateTime).isBefore(moment.now())) {
            newErrors.appointmentErrors[index].startingDateTime =
              'Der Zeitpunkt eines Termins darf nicht in der Vergangenheit liegen.'
          }
          if (
            appointment.participants.filter(participant => {
              return participant.role === 'patient'
            })?.length === 0
          ) {
            console.log('no patient')
            newErrors.appointmentErrors[index].patient =
              'Mindestens ein*e Patient*in hinzufügen'
          }
          if (
            appointment.participants.filter(participant => {
              return participant.role !== 'patient'
            })?.length === 0
          ) {
            newErrors.appointmentErrors[index].practitioner =
              'Mindestens eine*n Ärzt*in hinzufügen'
          }
        }
      )
    }

    if (fieldDraft.tasks) {
      newErrors.taskErrors = propertiesMissing(
        fieldDraft.tasks,
        requiredFieldsTask
      )

      fieldDraft.tasks.forEach((task: Task, index: number) => {
        if (
          !task.deadline ||
          (moment(task.deadline).isBefore(moment.now()) &&
            !moment(task.deadline).isSame(moment.now(), 'day'))
        ) {
          newErrors.taskErrors[index].deadline =
            'Die Aufgabe muss eine Deadline haben, die nicht in der Vergangenheit liegt.'
        }
        if (moment(task.deadline).isSame(moment.now(), 'day')) {
          if (task.deadline && moment(task.deadline).isBefore(moment.now())) {
            if (typeof task.deadline === 'number') {
              task.deadline = moment(task.deadline).toDate()
            }
            task.deadline.setHours(23, 59, 59, 999)
          }
        }
      })
    }
    updateTabsWithErrors(newErrors)
    setErrors(newErrors)

    let noAppointmentErrors = newErrors?.appointmentErrors?.every(
      appointment => {
        return Object.keys(appointment)?.length === 0
      }
    )
    if (noAppointmentErrors === undefined) noAppointmentErrors = true

    let noTaskErrors = newErrors?.taskErrors?.every(task => {
      return Object.keys(task)?.length === 0
    })
    if (noTaskErrors === undefined) noTaskErrors = true

    console.log('errors', newErrors)

    return noAppointmentErrors && noTaskErrors
  }

  const updateTabsWithErrors = (errors: FieldDraftErrors) => {
    const oldActiveTab = activeTab
    const updatedTabs: ITabSimple[] = []
    errors?.appointmentErrors?.forEach((appointment, index) => {
      if (Object.keys(appointment).length !== 0) {
        const currentTab = tabs[index]
        currentTab.errors = true
        updatedTabs.push(currentTab)
      } else {
        const currentTab = tabs[index]
        currentTab.errors = false
        updatedTabs.push(currentTab)
      }
    })
    setTabs(updatedTabs)
    setActiveTab(oldActiveTab)
  }

  const propertiesMissing = (
    data: Appointment[] | AppointmentDto[] | Task[],
    requiredFields: string[]
  ): AppointmentFormErrors[] | TaskFormErrors[] => {
    const errors: AppointmentFormErrors[] | TaskFormErrors[] = []
    data.forEach(resource => {
      let newErrors: AppointmentFormErrors | TaskFormErrors = {}
      if (
        resource instanceof Appointment ||
        resource instanceof AppointmentDto
      ) {
        newErrors = {} as AppointmentFormErrors
      } else if (resource instanceof Task) {
        newErrors = {} as TaskFormErrors
      } else {
        newErrors = {}
      }

      requiredFields.forEach(field => {
        if (
          resource[field] === null ||
          resource[field] === '' ||
          resource[field] === undefined
        ) {
          newErrors[`${field}`] = 'Ist ein Pflichtfeld, bitte ausfüllen.'
        }
      })
      errors.push(newErrors)
    })
    return errors
  }

  return (
    <Modal show={show} onHide={onAbort}>
      {(type === OrganizerModalType.FIELD_TERMIN_AUFGABE ||
        type === OrganizerModalType.FIELD_TERMIN_AUFGABE_COMBI) && (
        <Modal.Header closeButton>
          <TabsSimple
            tabs={tabs}
            activeTab={activeTab}
            onSelect={setActiveTab}
            disableBottomLine={true}
          />
        </Modal.Header>
      )}
      <Modal.Body>
        {appointments &&
          appointments.map((appointment: Appointment, index: number) => {
            const tabName = makeActiveTabKey(
              initialAppointments[index].title,
              'appointment',
              index
            )

            return (
              <div className="wrapper" key={'wrapper-Organizer-' + index}>
                <div className="form-wrapper" key={'form-wrapper-' + index}>
                  {tabName === activeTab && (
                    <div className="appointment-detail Modal">
                      <OrganizerAppointmentForm
                        key={'OrganizerModalAppointmentForm[' + index + ']'}
                        index={index}
                        appointment={appointment}
                        appointments={appointments}
                        setAppointments={setAppointments}
                        onChangeEndDate={onChangeEndDate} //
                        handleChangeAppointment={handleChangeAppointment} //wichtig! alles prüfen
                        errors={errors?.appointmentErrors?.[index]}
                      />
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        {tasks &&
          type !== OrganizerModalType.FIELD_TERMIN_AUFGABE_COMBI &&
          tasks.map((task: Task, index: number) => {
            const tabName = makeActiveTabKey(
              initialTasks[index].title,
              'task',
              index
            )
            return (
              activeTab === tabName && (
                <div className="task-detail Modal" key={tabName}>
                  <TaskFieldsView
                    patientId={'isDefined'} // used to disable patient select, any string possible
                    newTask={task}
                    handleInputChange={handleChangeTask.bind(null, index)}
                    errors={errors?.taskErrors?.[index]}
                  />
                </div>
              )
            )
          })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onAbort}>
          Abrechen
        </Button>
        {(type === OrganizerModalType.FIELD_TERMIN_AUFGABE ||
          type === OrganizerModalType.FIELD_TERMIN_AUFGABE_COMBI) && (
          <>
            <Button
              variant="primary"
              onClick={onBack}
              disabled={activeTab === tabs[0]?.key}>
              Zurück
            </Button>
            <Button
              variant="primary"
              onClick={onContinue}
              disabled={activeTab === tabs[tabs.length - 1]?.key}>
              Weiter
            </Button>
          </>
        )}

        <OverlayTrigger
          overlay={
            <Tooltip id="save-submitTemplate">
              Erstellt die Aufgabe und/oder den Termin als Entwurf. Die Vorlagen
              werden beim speichern der Formulare erstellt.
            </Tooltip>
          }>
          <Button variant="primary" id={'submitTemplate'} onClick={handleSave}>
            Erstellen
          </Button>
        </OverlayTrigger>
      </Modal.Footer>
    </Modal>
  )
}

export default withAuth(OrganizerModal)
