import { DosageAndTimingSelectorProps } from './DosageAndTimingSelector'
import { ISetFieldValue } from '../../Forms/FormInterfaces'
import roundTo from 'round-to'

export function OnInsertedVolumeChanged(
  value: number,
  index: number,
  props: DosageAndTimingSelectorProps,
  setFieldValue: ISetFieldValue
): void {
  setFieldValue(
    `timeIntervals[${props.index}].timingAndDosage.dosages[${index}].volume`,
    value
  )
  if (value) {
    let packageDosageArray: Array<string>
    let packageDosageArrayConverted: Array<number>
    let packageDosageNumber: number
    let computedDosageString = ''

    if (props.package.value.includes('/')) {
      packageDosageArray = props.package.value.split('/')
      packageDosageArrayConverted = packageDosageArray.map(it =>
        Number(it.replace(',', '.'))
      )
      packageDosageArrayConverted
        .map(it => roundTo(it * value, 8))
        .forEach((it, arrayIndex) => {
          if (arrayIndex !== packageDosageArrayConverted.length - 1) {
            computedDosageString += String(it).replace('.', ',') + '/'
          } else {
            computedDosageString += String(it).replace('.', ',')
          }
        })
    } else {
      packageDosageNumber = Number(props.package.value.replace(',', '.'))
      packageDosageNumber *= value
      computedDosageString = String(roundTo(packageDosageNumber, 8)).replace(
        '.',
        ','
      )
    }
    setFieldValue(
      `timeIntervals[${props.index}].timingAndDosage.dosages[${index}].dose`,
      {
        value: computedDosageString,
        label: computedDosageString,
      }
    )
    if (props.standardMedication.form.text === 'Tropfen') {
      setFieldValue(
        `timeIntervals[${props.index}].timingAndDosage.dosages[${index}].pieces`,
        roundTo(20 * value, 8)
      )
    } else {
      setFieldValue(
        `timeIntervals[${props.index}].timingAndDosage.dosages[${index}].pieces`,
        roundTo(value, 8)
      )
    }
  }
}
