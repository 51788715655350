export enum UserPermission {
  APPOINTMENTS = 'appointments',
  APPOINTMENTS_ADD = 'appointments_add',
  APPOINTMENTS_SHOW = 'appointments_show',
  APPOINTMENTS_FILTER_ALL = 'appointments_filter_all',
  DRAFTS = 'drafts',
  EVENT_SAVE = 'event_save',
  MEDICATIONS = 'medications',
  MEDICATIONS_ADD = 'medications_add',
  MEDICATIONS_EDIT = 'medications_edit',
  MEDICAL_ROUND_SIGN = 'medical_round_sign',
  MESSAGES = 'messages',
  PATIENT_RECORDS = 'patient_records',
  PATIENT_RECORDS_FILTER_ALL = 'patient_records_filter_all',
  PATIENT_RECORDS_DELETED = 'patient_records_deleted',
  PATIENT_ADD = 'patient_add', //Case-manager / threating Physican
  PATIENT_DELETE = 'patient_delete',
  PATIENT_EDIT_PHYSICIANS = 'patient_edit_physicians',
  PATIENT_EXPORT = 'patient_export',
  PATIENT_SHOW = 'patient_show',
  PATIENT_CREATE_ACCOUNT = 'patient_create_account',
  PATIENT_INDICATIONS = 'patient_indications',
  PATIENT_TREATMENT = 'patient_treatment',
  PATIENT_FORMS = 'patient_forms',
  PATIENT_APPOINTMENTS = 'patient_appointments',
  PATIENT_PERSONAL_DATA = 'patient_personal_data',
  PATIENT_MEDICATION_ADD_EDIT = 'patient_medication_add_edit',
  PATIENT_MEDICATION_CONFIRM = 'patient_medication_confirm',
  PATIENT_MEDICATION_CREATE_TASK = 'patient_medication_create_task',
  PATIENT_MEDICATIONPLAN_RECOVER_OLDER_VERSIONS = 'patient_medicationplan_recover_older_versions',
  PATIENT_LETTER_CREATE = 'patient_letter_create',
  PATIENT_PLAN_DEFINITION_TEMPLATE = 'patient_medication_plan_definition_template',
  PATIENT_CAREPLAN_TEMPLATE = 'patient_medication_careplan_template',

  TASK = 'task',
  TASK_EDIT = 'task_edit',
  TASK_ADD = 'task_add',
  TASK_SHOW = 'task_show',
  TASK_FILTER_ALL = 'task_filter_all',
  TASK_CREATE_FOR_OTHER_USERS = 'task_create_for_other_users',
  USER_PROFILE = 'user_profile',
  ADMIN_AREA = 'admin_area',
  ADMIN_PATIENT_EXPORT = 'admin_patient_export',
  ADMIN_PRELOAD_PIPING = 'preload_piping',
  PATIENT_MONITORING = 'patient_monitoring',
  ADMIN_IMPORT_PRACTITIONERS = 'admin_import_practitioners',
  ADMIN_IMPORT_PATIENTS_FROM_SURVEY = 'admin_import_survey_patients',
  ADMIN_SYNC_REDCAP_METADATA = 'admin_sync_redcap_metadata',
  ADMIN_VALIDATE_REDCAP_METADATA = 'admin_validate_redcap_metadata',
  ADMIN_IMPORT_ICD10 = 'admin_import_icd10',
  ADMIN_USER_ADD = 'admin_user_add',
  ADMIN_USER_DELETE = 'admin_user_delete',
  ADMIN_USER_RESET_PASSWORD = 'admin_user_reset_password',
  ADMIN_USER_SETROLE = 'admin_user_setrole',
  ADMIN_PRACTITIONERS_IMPORT = 'admin_practitioners_import',
  ADMIN_PATIENT_VALIDATION = 'admin_patient_validation',
  PRACTITIONER_LETTER_PRINT = 'practitioner_letter_print',
  PROFILE_SET_CENTER_OR_POSITION = 'profile_set_center_or_position',

  PRACTITIONER_LETTER_TEMPLATE_EDIT = 'practitioner_letter_template_edit',
  IMPORT_TELEFORM = 'import_teleform',
}
