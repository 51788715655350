import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface ItemRendererProps {
  item: {
    borderColor: string
    effectiveDateTime: string
    shape: string
    bgColor: string
    title: string
  }
  getItemProps: (item: Record<string, unknown>) => Record<string, unknown>
}

/**
 *
 * @param {MedicationScheduleItem} item
 * @param {function} getItemProps
 * @returns {*}
 */
export const itemRenderer: React.FC<ItemRendererProps> = ({
  item,
  getItemProps,
}: ItemRendererProps): JSX.Element => {
  const popup = `Form: ${item.shape}`

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={`tooltip-"bottom"`}>{popup}</Tooltip>}>
      <div
        style={
          getItemProps({
            style: {
              display: 'inline-block',
              color: 'black',
              backgroundColor: item.bgColor,
              borderColor: item.borderColor,
              textAlign: 'center',
              height: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }).style
        }>
        {item.title}
      </div>
    </OverlayTrigger>
  )
}
