import React, { useContext, useMemo } from 'react'
import { MedicationDiff } from './MedicationDiff'
import {
  formatDate,
  formatTime,
  getIdPartFromFhirResourceId,
  parseUnknownToDate,
} from '../../../utils/Utils'
import { Button } from 'react-bootstrap'
import { MedicationScheduleObject } from '../../../utils/MedicationScheduleUtils'
import { getMedicationRequestById } from '../../../services/MedicationRequestService'
import { CarePlan } from '../../../types/CarePlan'
import LocalStorageService from '../../../services/LocalStorageService'
import { MedicationRequestUpdateWithReason } from '../MedicationSchedule/MedicationReason'
import { MEDICATION_CONFIRM_STORAGE } from '../../../config/Storages'
import { UserPermission } from '../../../constants/UserPermission'
import {
  AuthContext,
  AuthStore,
  withAuth,
} from '../../../infrastructure/AuthProvider'
import { MedicationRequestInitialRationale } from '../../../constants/MedicationRequestInitialRationale'
import { ExtensionURL } from '../../../constants/ExtensionURL'
import { NotificationManager } from 'react-notifications'
import MaterialIcon from '../../UI/MaterialIcon/MaterialIcon'
import { ApiResponseFhir } from '../../../types/ApiResponseFhir'
import { Link } from '@material-ui/core'
import { applyFind } from '../../../utils/NestUtils'
import { makeResourceVersion } from './HistoryTable'
import { DosageDiff } from './DosageDiff'
import { MedicationRequestPanosRationale } from '../../../constants/MedicationRequestPanosRationale'

interface HistoryRowProps extends AuthContext {
  carePlan: CarePlan
  setSchedule: (schedule: MedicationScheduleObject) => void
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  item: MedicationRequestExtended
  group: CarePlan
  keyId: string
  selected: boolean
  groupVersionBefore: CarePlan
  groupVersionsBefore: CarePlan[]
  onClick: () => void
}

const makeItemId = (
  medicationRequest: MedicationRequest,
  carePlan: CarePlan
): string => {
  return `${medicationRequest.medicationReference.reference}--${carePlan.id}-${carePlan.meta.versionId}`
}

function scrollToOlderMedicationRequest(
  medicationRequest: MedicationRequestExtended,
  groupVersionBefore: CarePlan,
  carePlan: CarePlan,
  groupVersionsBefore: CarePlan[]
) {
  if (!medicationRequest.compared) {
    console.log('row ids not found!', {
      _item: medicationRequest,
      groupVersionBefore,
      carePlan,
    })
    return
  }
  const row = applyFind(groupVersionsBefore, carePlan =>
    document.getElementById(makeItemId(medicationRequest.compared, carePlan))
  )

  if (!row) {
    console.log(
      'row not found!',
      makeItemId(medicationRequest.compared, groupVersionBefore),
      { _item: medicationRequest, groupVersionBefore, carePlan }
    )
    return
  }
  row.scrollIntoView({ behavior: 'smooth' })
}

const HistoryRow: React.FC<HistoryRowProps> = ({
  carePlan,
  setShowModal,
  onClick,
  item,
  group,
  groupVersionBefore,
  groupVersionsBefore,
  selected,
  handlers,
  keyId,
}: HistoryRowProps) => {
  const medicationRequest = { ...item }
  if (groupVersionBefore.medicationRequests.includes(item.idVersioned))
    delete medicationRequest.diff

  // For saving while relogin
  const localStorageService = useMemo(
    () =>
      new LocalStorageService<MedicationRequestUpdateWithReason>(
        MEDICATION_CONFIRM_STORAGE
      ),
    []
  )
  const context = useContext(AuthStore)
  const { hasPermission } = context.handlers

  const date = parseUnknownToDate(medicationRequest.meta.lastUpdated)
  const reason = (medicationRequest.extension || []).find(
    extension => extension.url === ExtensionURL.medicationRequestReason
  )?.valueString

  async function selectVersion() {
    try {
      const newMedicationRequestIds = [
        ...carePlan.medicationRequests,
        medicationRequest.idVersioned,
      ]

      const responsesMedReq = (await Promise.all(
        newMedicationRequestIds.map(it => getMedicationRequestById(it))
      )) as unknown
      const newMedRequests = (
        responsesMedReq as ApiResponseFhir<MedicationRequest>[]
      ).map(it => {
        delete (it.data as MedicationRequest).meta
        return it.data
      }) as MedicationRequest[]

      localStorageService.localStorageAdd({
        medicationRequests: newMedRequests,
        reason: reason,
      })
      handlers.doRelogin()
    } catch (e) {
      NotificationManager.error('Laden der Version fehlgeschlagen')
      console.error('CarePlan could not be updated', e)
    }
    setShowModal(false)
  }

  return (
    <tr
      key={keyId + medicationRequest.idVersioned}
      id={makeItemId(medicationRequest, group)}
      onClick={onClick}
      className={selected ? 'cell-selected' : ''}>
      <td className="pt-1 pb-0 theme-history">
        {!carePlan.medicationRequests.includes(
          medicationRequest.idVersioned
        ) && (
          <Button
            disabled={
              !hasPermission(
                UserPermission.PATIENT_MEDICATIONPLAN_RECOVER_OLDER_VERSIONS
              )
            }
            className="mt-2 mb-2"
            variant={'outline-dark'}
            onClick={selectVersion}>
            <MaterialIcon verticalAlignment="middle" icon="history" />
          </Button>
        )}
      </td>
      <td>
        <p>{formatDate(date) + ' ' + formatTime(date)}</p>
        <p>{makeResourceVersion(medicationRequest)}</p>
        {groupVersionBefore && medicationRequest.compared && (
          <p>
            <Link
              onClick={() => {
                scrollToOlderMedicationRequest(
                  medicationRequest,
                  groupVersionBefore,
                  carePlan,
                  groupVersionsBefore
                )
              }}>
              Vorversion
            </Link>
          </p>
        )}
      </td>

      <td>
        <p>
          Handelsname: {medicationRequest.medicationReference?.display ?? '–'}
        </p>
        <p>
          Wirkstoff(e):{' '}
          {medicationRequest.contained
            .map(it =>
              it.ingredient.map(
                it =>
                  it.itemCodeableConcept.text +
                  ' ' +
                  it.strength.numerator.value +
                  it.strength.numerator.unit
              )
            )
            .join(', ')}
        </p>
        <p>
          {medicationRequest.substitution &&
          medicationRequest.substitution.allowedBoolean
            ? 'Aut Idem'
            : null}
        </p>
      </td>
      <td>
        <p>
          {!medicationRequest.compared && <i>Neu verschrieben</i>}
          {medicationRequest.compared &&
            !groupVersionBefore.medicationRequests
              .map(getIdPartFromFhirResourceId)
              .includes(medicationRequest.id.toString()) && (
              <i>Wieder neu angesetzt </i>
            )}
        </p>
        {Object.entries({
          Historie: [
            ExtensionURL.medicationRequestHistoryReason,
            MedicationRequestInitialRationale,
          ],
          Anordnung: [
            ExtensionURL.medicationRequestArrangementReason,
            MedicationRequestPanosRationale,
          ],
        }).map(([text, [url, enumObj]], index) =>
          medicationRequest.extension
            ?.filter(it => it.url === url)
            .map(
              (extension, index) =>
                extension.valueString.trim() && (
                  <div
                    key={
                      'extension' +
                      index +
                      medicationRequest.id +
                      medicationRequest.meta.versionId +
                      url
                    }>
                    Grund unter {text}:{' '}
                    {enumObj[extension.valueString] ??
                      extension.valueString + JSON.stringify(enumObj)}
                    {reason ? ' (' + reason + ')' : ''}
                  </div>
                )
            )
        )}

        {!groupVersionBefore.medicationRequests.includes(
          medicationRequest.idVersioned
        ) ? (
          <>
            {' '}
            <div>
              <MedicationDiff
                medicationRequest={medicationRequest}
                diff={medicationRequest.diff}
                key={
                  'd' + medicationRequest.id + medicationRequest.meta.versionId
                }
              />
            </div>
            <div>
              <DosageDiff medicationRequest={medicationRequest} />
            </div>
          </>
        ) : (
          <Link
            onClick={() => {
              scrollToOlderMedicationRequest(
                medicationRequest,
                groupVersionBefore,
                carePlan,
                groupVersionsBefore
              )
            }}>
            Siehe gleiche Version unten
          </Link>
        )}
      </td>
    </tr>
  )
}

export default withAuth(HistoryRow)
