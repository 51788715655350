import React from 'react'
import { diff } from 'deep-diff'
import _ from 'lodash'
import { path as pathAccess } from '../../../utils/NestUtils'
import { formatDateTime, formatTime } from '../../../utils/Utils'
import './DosageDiff.scss'
import { Diffable } from './Diffable'
import { PluralModifier, SingularPluralNoun } from '../../SingularPluralWord'
import { mapDosageInstruction } from '../../../utils/MedicationScheduleUtils'

interface DosageDiffProps {
  medicationRequest: MedicationRequestExtended
}

export const DosageDiff: React.FC<DosageDiffProps> = ({
  medicationRequest,
}: DosageDiffProps) => {
  const dosage_v1 = mapDosageInstruction(
    medicationRequest.dosageInstruction,
    '?????'
  )
  const dosage_v2 =
    medicationRequest?.compared &&
    mapDosageInstruction(medicationRequest.compared.dosageInstruction, '?????')
  const dosageDiff = diff(dosage_v1, dosage_v2)

  const fulldosageDiff = _.cloneDeep(dosage_v1)
  if (dosageDiff)
    dosageDiff.forEach(difference => {
      pathAccess(
        fulldosageDiff,
        !isNaN(difference.index)
          ? [...difference.path, difference.index]
          : difference.path,
        null,
        obj =>
          (obj['modification'] = !obj['modification']
            ? [difference]
            : [...obj['modification'], difference])
      )

      switch (difference.kind) {
        case 'N': // new
          diff.applyChange(fulldosageDiff, dosage_v2, difference)
          break
        case 'D': // edited
          diff.revertChange(fulldosageDiff, dosage_v2, difference)
          break
        case 'A': {
          // in array
          switch (difference.item.kind) {
            case 'N':
              diff.applyChange(fulldosageDiff, dosage_v2, difference)
              break
            case 'D':
              diff.revertChange(fulldosageDiff, dosage_v2, difference)
              break
          }
        }
      }

      if (difference.kind === 'E') {
        if (!isNaN(difference?.path[difference?.path?.length - 1]))
          difference.index = difference?.path[difference?.path?.length - 1]
      }
      difference.lastKey = difference?.path
        ? difference?.path[difference?.path?.length - 1]
        : undefined
    })

  return (
    <table key={medicationRequest.idVersioned} style={{ width: '100%' }}>
      <tbody>
        {fulldosageDiff.timeIntervals.map((it1, i_wholeObject) => (
          <React.Fragment key={`dosage time interval ${i_wholeObject}`}>
            <Diffable
              key={i_wholeObject + 'time range'}
              wrapperElement={<tr key={'head' + i_wholeObject} />}
              changes={
                (fulldosageDiff.timeIntervals as TimingWithModification)
                  .modification
              }
              index={i_wholeObject}
              isArray
              noComparand>
              <th>Zeitraum</th>
              <th colSpan={10}>
                vom{' '}
                <Diffable
                  wrapperElement={<span />}
                  changes={(it1 as TimingWithModification).modification}
                  lastKey={'startDate'}
                  index={i_wholeObject}
                  formatFunction={it => formatDateTime(new Date(it))}>
                  {it1.startDate}
                </Diffable>{' '}
                bis{' '}
                <Diffable
                  wrapperElement={<span />}
                  changes={(it1 as TimingWithModification).modification}
                  index={i_wholeObject}
                  lastKey={'endDate'}
                  formatFunction={it => formatDateTime(new Date(it))}>
                  {it1.endDate}
                </Diffable>
              </th>
            </Diffable>
            <Diffable
              key={i_wholeObject + 'times'}
              wrapperElement={<tr key={'head' + i_wholeObject} />}
              changes={
                (fulldosageDiff.timeIntervals as TimingWithModification)
                  .modification
              }
              index={i_wholeObject}
              isArray
              noComparand>
              <th>Zeiten der Medikation</th>
              <th colSpan={10}>
                <Diffable
                  wrapperElement={<span />}
                  changes={it1.timingAndDosage.selectedTiming.modification}
                  lastKey={'label'}
                  index={i_wholeObject}>
                  {it1.timingAndDosage.selectedTiming.label}
                </Diffable>
              </th>
            </Diffable>
            {it1.timingAndDosage.patientInstructions && (
              <Diffable
                key={i_wholeObject + 'instruction'}
                wrapperElement={<tr key={'head' + i_wholeObject} />}
                changes={
                  (fulldosageDiff.timeIntervals as TimingWithModification)
                    .modification
                }
                index={i_wholeObject}
                isArray
                noComparand>
                <th>Patienteninstruktionen</th>
                <th colSpan={10}>
                  <Diffable
                    wrapperElement={<span />}
                    changes={
                      (it1.timingAndDosage as DosageWithModification)
                        ?.modification
                    }
                    lastKey={'patientInstructions'}
                    index={i_wholeObject}>
                    {it1.timingAndDosage.patientInstructions}
                  </Diffable>
                </th>
              </Diffable>
            )}

            {it1.timingAndDosage.dosages.map((it2, i) => (
              <Diffable
                key={i_wholeObject + 'row' + i}
                wrapperElement={
                  <tr key={'body' + i_wholeObject} style={{ width: '100%' }} />
                }
                changes={
                  (fulldosageDiff.timeIntervals as DosageWithModification)
                    .modification
                }
                index={i_wholeObject}
                isArray
                noComparand>
                <td>
                  Dosis:{' '}
                  <Diffable
                    wrapperElement={<span />}
                    changes={it2.dose.modification}
                    index={i}
                    lastKey={'label'}
                    formatFunction={it =>
                      it +
                      medicationRequest.contained
                        .map(it =>
                          it.ingredient.map(it => it.strength.numerator.unit)
                        )
                        .join(' ')
                    }>
                    {it2.dose.label}
                  </Diffable>{' '}
                </td>
                <td>
                  Stück:{' '}
                  <Diffable
                    wrapperElement={<span />}
                    changes={it2.modification}
                    lastKey={'pieces'}
                    index={i}>
                    {it2.pieces}
                  </Diffable>{' '}
                </td>
                <td>
                  Einnahmehäufigkeit:{' '}
                  <PluralModifier num={it2.period}>aller</PluralModifier>{' '}
                  <Diffable
                    wrapperElement={<span />}
                    changes={it2.modification}
                    lastKey={'period'}
                    index={i}>
                    {it2.period}
                  </Diffable>{' '}
                  <Diffable
                    wrapperElement={<span />}
                    changes={it2.periodUnit.modification}
                    lastKey={'label'}
                    index={i}>
                    <SingularPluralNoun num={it2.period}>
                      {it2.periodUnit.label}
                    </SingularPluralNoun>
                  </Diffable>
                </td>
                {it2.frequencyMax && (
                  <td>
                    Maximale Einnahmen:{' '}
                    <Diffable
                      wrapperElement={<span />}
                      changes={it2.modification}
                      lastKey={'frequencyMax'}
                      index={i}>
                      {it2.frequencyMax}
                    </Diffable>
                  </td>
                )}
                {it2.distance && (
                  <td>
                    Abstand zwischen den Einnahmen:{' '}
                    <Diffable
                      wrapperElement={<span />}
                      changes={it2.modification}
                      lastKey={'distance'}
                      index={i}>
                      {it2.distance}
                    </Diffable>{' '}
                    <Diffable
                      wrapperElement={<span />}
                      changes={it2.distanceUnit.modification}
                      lastKey={'label'}
                      index={i}>
                      <SingularPluralNoun num={it2.distance}>
                        {it2.distanceUnit.label}
                      </SingularPluralNoun>
                    </Diffable>
                  </td>
                )}

                {it2.times.map((it3, i) => (
                  <td key={`timing and dosage 2 column ${i}`}>
                    um{' '}
                    <Diffable
                      wrapperElement={<span />}
                      changes={it2.times.modification}
                      index={i}
                      formatFunction={it => formatTime(new Date(it))}
                      isArray>
                      {it3}
                    </Diffable>
                  </td>
                ))}
              </Diffable>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  )
}
