import React from 'react'

const vowels = 'aeiouöäüAEIOUÄÜÖ'
export const SingularPluralNoun = (props: {
  // Sigular word
  children: string
  // Returns the plural of the word, if needed for the number
  num: number
}): JSX.Element => {
  const children =
    props.num === 1
      ? props.children
      : vowels.includes(props.children[props.children.length])
      ? props.children + 'n'
      : props.children + 'e'
  return <>{children}</>
}

export const PluralModifier = (props: {
  // Sigular word
  children: string
  // Returns the plural of the word, if needed for the number
  num: number
}): JSX.Element => {
  const children = props.num > 1 ? props.children : null
  return <>{children}</>
}
