import React from 'react'
import { AppointmentCategories } from '../../../constants/AppointmentCategories'
import { PriorityLabels } from '../../../constants/Priority'
import { Appointment } from '../../../types/Appointment'
import { istOutOfWorkAppointment } from '../../../utils/CalendarUtils'
import FormCheckbox from '../../UI/Forms/FormCheckbox/FormCheckbox'
import FormDatepicker from '../../UI/Forms/FormDatepicker/FormDatepicker'
import FormInput from '../../UI/Forms/FormInput/FormInput'
import FormSelect from '../../UI/Forms/FormSelect/FormSelect'
import FormTextarea from '../../UI/Forms/FormTextarea/FormTextarea'
import LocationSelect from '../../UI/Forms/LocationSelect/LocationSelect'
import { Location } from '../../../types/Location'
import { PANOS_WORK_END, PANOS_WORK_START } from '../../../config/Constants'
import { AppointmentFormErrors } from '../../../types/AppointmentFormErrors'

export interface AppointmentMinimalViewProps {
  appointment: Appointment
  isEditable: boolean
  handleInputChange: (
    fieldname: string,
    value: string | number | Date | AppointmentCategories | Location | boolean
  ) => void
  maxDate: Date
  errors?: AppointmentFormErrors
}

const AppointmentBasicView: React.FC<
  React.PropsWithChildren<AppointmentMinimalViewProps>
> = ({
  appointment,
  isEditable,
  handleInputChange,
  maxDate,
  children,
  errors,
}: React.PropsWithChildren<AppointmentMinimalViewProps>) => {
  return (
    <div>
      <FormInput
        name="title"
        label="Titel"
        onChange={value => handleInputChange('title', value)}
        disabled={!isEditable}
        value={appointment.title}
        error={errors?.title}
      />
      <FormSelect
        label="Kategorie"
        name="category"
        options={Object.entries(AppointmentCategories).map(val => ({
          label: val[1],
          value: val[1],
        }))}
        value={appointment?.category}
        onChange={value => handleInputChange('category', value)}
        error={errors?.category}
      />
      {appointment.category === AppointmentCategories.PRIVATE && (
        <FormCheckbox
          name="shared"
          value={appointment.shared}
          valueText="Persönlichen Termin freigeben"
          onChange={value => handleInputChange('shared', value)}
        />
      )}

      <FormSelect
        label="Priorität"
        name="priority"
        options={Object.entries(PriorityLabels).map(val => ({
          label: val[1],
          value: val[0],
        }))}
        value={appointment.priority}
        onChange={value => handleInputChange('priority', value)}
        error={errors?.priority}
      />
      <FormDatepicker
        name="startingDateTime"
        type="datetime"
        label="Beginn"
        value={appointment.startingDateTime || ''}
        maxDate={maxDate}
        softDateLimit={true}
        minTime={PANOS_WORK_START}
        maxTime={PANOS_WORK_END}
        onChange={value => handleInputChange('startingDateTime', value)}
        minDate={new Date()}
        disabled={!isEditable}
        error={errors?.startingDateTime}
      />
      {children}
      {!istOutOfWorkAppointment(appointment) ? (
        <>
          <LocationSelect
            onChange={value => handleInputChange('location', value)}
            label={'Ort'}
            value={appointment.location}
            name={'location'}
          />
          <FormInput
            name="room"
            label="Raum"
            onChange={value => handleInputChange('room', value)}
            disabled={!isEditable}
            value={appointment.room}
          />
        </>
      ) : null}

      <FormTextarea
        name="description"
        label="Notiz"
        value={appointment.description}
        onChange={value => handleInputChange('description', value)}
      />
    </div>
  )
}

export default AppointmentBasicView
