import React, { Component, ReactNode } from 'react'
import { PARAMETRIC_LINKED_LINKS } from '../../../config/Paths'
import { Link } from 'react-router-dom'
import { LinkModalDisplayParams, LinkObject } from './LinkList'

interface Props {
  documentType: string
  linkParams: any[]
  index: number
  openModalOrSidebar: (modalDisplayParams: LinkModalDisplayParams) => void
  linkObject: LinkObject
}

export class LinkListRow extends Component<Props> {
  render(): ReactNode {
    if (!this.props.linkParams) return null

    return this.props.linkParams.map((linkParam, index2) => {
      const link = PARAMETRIC_LINKED_LINKS[this.props.documentType]
      const { title, forms, ...params } = linkParam

      if (forms) {
        return forms.map((form, index) => {
          const displayParams: LinkModalDisplayParams = {
            title: form.linkLabel
              ? form.linkLabel
              : form.form
              ? form.form.instrument_label
                ? form.form.instrument_label
                : title
              : title,

            form: form,
          }

          return this.renderButtonEntry(displayParams, index, index2)
        })
      }

      if (this.props.documentType === 'Letter') {
        const displayParams: LinkModalDisplayParams = {
          title: title,
          linkObject: { task: this.props.linkObject.task } as LinkObject,
          ...params,
        }

        return this.renderButtonEntry(displayParams, this.props.index, index2)
      }

      return this.renderLinkEntry(params, link, title, this.props.index, index2)
    })
  }

  renderButtonEntry(
    displayParams: LinkModalDisplayParams,
    index: number,
    index2: number
  ): JSX.Element {
    const title = displayParams.title
    return displayParams ? (
      <tr key={`${title}_${index}${index2}`}>
        <td onClick={() => this.props.openModalOrSidebar(displayParams)}>
          {title}
        </td>
      </tr>
    ) : null
  }

  renderLinkEntry(
    params: LinkModalDisplayParams,
    link: string,
    title: string,
    index: number,
    index2: number
  ): JSX.Element {
    const linkObject = this.props.linkObject.task
      ? this.props.linkObject.task
      : this.props.linkObject.appointment
    return (
      <tr className="table-group" key={index + index2}>
        <td className="pt-1 pb-0">
          <Link
            to={{
              pathname: link,
              state: {
                ...params,
                ...linkObject,
              },
            }}>
            {title}
          </Link>
        </td>
      </tr>
    )
  }
}
