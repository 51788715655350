import React, { Component } from 'react'
import { AuthContext, withAuth } from '../../../infrastructure/AuthProvider'
import { Task } from '../../../types/Task'
import TaskService from '../../../services/TaskService/TaskService'
import { APIResult } from '../../../constants/APIResult'
import { TaskStatus } from '../../../constants/TaskStatus'
import { ConfirmDialogue } from '../../UI/Dialogues/ConfirmDialogue'
import { Practitioner } from '../../../types/Practitioner'
import { formatDate, formatDateTime, getFullName } from '../../../utils/Utils'
import BigButton from '../../UI/BigButton/BigButton'
import { NotificationManager } from 'react-notifications'
import Loader from '../../UI/Spinner/Loader'
import TaskForm from '../TaskForm/TaskForm'
import { LinkList } from '../../UI/LinkList/LinkList'
import PractitionerSelect from '../../UI/Forms/PractitionerSelect/PractitionerSelect'
import { AutosaveService } from '../../../services/AutosaveService'
import { DraftKind } from '../../../types/DraftKind'
import { DynamicSidebarStore } from '../../../infrastructure/DynamicSidebarProvider'
import { MedicalInstrument } from '../../Forms/FormInterfaces'
import { UserPermission } from '../../../constants/UserPermission'
import {
  SubscriptionIdentifier,
  SubscriptionProps,
  SubscriptionType,
  withSubscriptions,
} from '../../../infrastructure/SubscriptionProvider'

interface Props extends AuthContext, SubscriptionProps {
  taskId: string
  taskTempId?: string
  closeTab: () => void
  onChanged: () => void
  handleTaskValueChange?: (task: Task) => void
}

interface State {
  task: Task
  owner?: Practitioner
  isEditing: boolean
  isModeAssign: boolean
  medicationInstrument: string
}

class TaskDetail extends Component<Props, State> {
  private _taskService = new TaskService()
  static contextType = DynamicSidebarStore

  state = {
    isEditing: false,
    isModeAssign: false,
    task: null,
    owner: null,
    medicationInstrument: null,
  }

  onSidebarChange(title) {
    if (
      Object.entries(this.state.task?.links || {}).find(
        ([key, linkParams]: [string, any[]]) =>
          key === 'RedCap' &&
          linkParams.find(
            linkParam =>
              linkParam.forms &&
              (linkParam.forms as MedicalInstrument[]).find(
                form => 'event-' + form.event_name === title
              )
          )
      )
    )
      this.fetchTask()
  }
  private boundOnSidebarChange = this.onSidebarChange.bind(this)

  componentDidMount() {
    if (this.props.taskId) this.fetchTask()

    this.props.subscribe(
      SubscriptionType.DynamicSidebar,
      SubscriptionIdentifier.Task,
      this.boundOnSidebarChange
    )
  }

  componentWillUnmount() {
    this.props.unsubscribe(
      SubscriptionType.DynamicSidebar,
      SubscriptionIdentifier.Task,
      this.boundOnSidebarChange
    )
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.taskId !== this.props.taskId) {
      this.fetchTask()
    }
  }

  fetchTask = async () => {
    const res = await this._taskService.getTask(this.props.taskId)
    if (res.Response && res.Result === APIResult.SUCCESS) {
      const task: Task = res.Response as Task
      await this.updateTask(task)
    } else {
      NotificationManager.error('Fehler', 'Aufgabe konnte nicht geladen werden')
    }
  }

  updateTask = async (task: Task) => {
    const owner = task.ownerDto

    this.setState({
      task: { ...task },
      isEditing: false,
      isModeAssign: false,
      owner,
    })
  }

  handleDeleteTask = async () => {
    const service = new TaskService()
    const { firstName, lastName } = this.props.auth.user
    console.log('NAMES: ', firstName, lastName)
    console.log(this.context)
    const userName = `${lastName} ${firstName}`
    const response = await service.deleteTask(this.state.task, userName)

    if (response && response.Result === APIResult.SUCCESS) {
      NotificationManager.success(
        'Erfolg',
        `Die Aufgabe "${
          this.state.task.title || 'Ohne Titel'
        }" wurde erfolgreich gelöscht`
      )

      await new AutosaveService().deleteValueById(
        this.state.task,
        DraftKind.Task
      )

      this.props.onChanged()
      this.props.closeTab()
    } else {
      NotificationManager.error(
        'Fehler',
        'Beim Löschen ist ein Fehler aufgetreten'
      )
    }
  }

  isTaskCompletable = () => {
    return (
      this.state.task?.status !== TaskStatus.COMPLETED &&
      this.state.task?.status !== TaskStatus.CANCELLED &&
      this.state.task?.status !== TaskStatus.FAILED
    )
  }

  onEditTask = () => {
    this.setState({
      isEditing: true,
    })
  }

  onCompleteTask() {
    const newTask = { ...this.state.task, status: TaskStatus.COMPLETED }
    this._taskService.completeTask(newTask).then(response => {
      if (response.Result === APIResult.SUCCESS) {
        NotificationManager.success('Aufgabe erledigt!')
        //TODO: Update State of linkObject with Object your got from Backend on Approve
        this.setState(
          {
            task: {
              ...newTask,
            },
          },
          () => this.props.onChanged()
        )
      } else {
        NotificationManager.error(
          'Status der Aufgabe konnte nicht geändert werden' +
            (response.Response as string)
        )
      }
    })
  }

  onReopenTask() {
    const newTask = { ...this.state.task }
    newTask.status = TaskStatus.READY
    this._taskService.putTask(newTask).then(response => {
      if (response.Result === APIResult.SUCCESS) {
        this.setState(
          {
            task: {
              ...this.state.task,
              status: TaskStatus.READY,
            },
          },
          () => this.props.onChanged()
        )
      } else {
        NotificationManager.error(
          'Status der Aufgabe konnte nicht geändert werden '
        )
      }
    })
  }

  toggleAssignMode = () => {
    this.setState(prevState => ({
      isModeAssign: !prevState.isModeAssign,
    }))
  }

  onSaveAssignment = async () => {
    const { task, owner } = this.state
    if (task?.id && owner?.id) {
      const service = new TaskService()
      const response = await service.addUser(task.id, owner.id)
      if (response && response.Result === APIResult.SUCCESS) {
        NotificationManager.success('Zuweisung Erfolgreich')
        this.setState(
          {
            isModeAssign: false,
          },
          () => this.props.onChanged()
        )
      } else {
        NotificationManager.error('Fehler', 'Zuweisung fehlgeschlagen')
      }
    } else {
      NotificationManager.error('Fehler', '1Zuweisung fehlgeschlagen')
    }
  }

  handleTaskFormChange = (task: Task) => {
    if (task) {
      this.setState(
        {
          task: { ...task },
          isEditing: false,
          owner: task.ownerDto,
        },
        () => this.props.onChanged()
      )
    } else {
      this.setState({
        isEditing: false,
      })
    }
  }

  handleTaskValueChange = (task: Task) => {
    if (task) {
      this.setState(
        {
          task: task,
        },
        () =>
          this.props.handleTaskValueChange
            ? this.props.handleTaskValueChange(task)
            : null
      )
    }
  }

  render = () => {
    if (this.state.task === null) return <Loader />
    const { task } = this.state
    const { links } = task
    return (
      <>
        <div className="mb-1 d-flex align-items-center mb-2">
          <h6>{task.title || 'Ohne Titel'}</h6>
        </div>
        {this.state.isEditing ? (
          <TaskForm
            task={this.state.task}
            onChanged={this.handleTaskFormChange}
            handleTaskValueChange={this.handleTaskValueChange}
            editMode={true}
          />
        ) : (
          <>
            {this.state.task.status !== TaskStatus.REJECTED && (
              <div className="d-flex justify-content-end">
                {this.isTaskCompletable() && !this.state.isModeAssign ? (
                  <>
                    <BigButton
                      disabled={
                        this.state.isEditing ||
                        !this.props.handlers.hasPermission(
                          UserPermission.TASK_EDIT
                        )
                      }
                      icon="edit"
                      onClick={() => this.onEditTask()}>
                      Bearbeiten
                    </BigButton>
                    <BigButton
                      icon="person_add"
                      onClick={() => this.toggleAssignMode()}>
                      Zuweisen
                    </BigButton>
                    {this.isTaskCompletable() &&
                    this.state.task?.status !== TaskStatus.DRAFT ? (
                      <BigButton
                        icon="check_circle"
                        onClick={() => this.onCompleteTask()}>
                        Erledigt
                      </BigButton>
                    ) : null}
                  </>
                ) : null}
                {!this.isTaskCompletable() ? (
                  <BigButton
                    icon="settings_backup_restore"
                    onClick={() => this.onReopenTask()}>
                    Nicht erledigt
                  </BigButton>
                ) : null}
                {!this.state.isModeAssign ? (
                  <ConfirmDialogue
                    callback={() => this.handleDeleteTask()}
                    question="Wollen Sie die Aufgabe wirklich löschen?"
                    confirmText="Löschen"
                    title="Löschen">
                    <BigButton icon="delete">Löschen</BigButton>
                  </ConfirmDialogue>
                ) : (
                  <>
                    <BigButton
                      icon="cancel_presentation"
                      onClick={this.toggleAssignMode}>
                      Abbrechen
                    </BigButton>
                    <BigButton
                      icon="save"
                      onClick={() => this.onSaveAssignment()}>
                      Speichern
                    </BigButton>
                  </>
                )}
              </div>
            )}
            {this.state.task.history && this.state.task.history.length > 0 && (
              <div className="alert alert-info mt-2">
                {this.state.task.history[this.state.task.history.length - 1]}
              </div>
            )}
            <div className="form-group">
              <label>Priorität</label>
              <input
                className="form-control"
                disabled
                value={this.state.task?.priority || ''}
              />
            </div>
            <div className="form-group">
              <label>Kategorie</label>
              <input
                className="form-control"
                disabled
                value={this.state.task?.category || ''}
              />
            </div>
            <div className="form-group">
              <label>Eingang</label>
              <input
                className="form-control"
                disabled
                value={formatDateTime(this.state.task?.start_date)}
              />
            </div>
            <div className="form-group">
              <label>Patient</label>
              <input
                className="form-control"
                disabled
                value={getFullName(this.state.task.forDto)}
              />
            </div>
            <div className="form-group">
              <label>Bearbeiter</label>
              {this.state.isModeAssign ? (
                <PractitionerSelect
                  value={this.state.owner || null}
                  onChange={value =>
                    this.setState({
                      owner: value,
                    })
                  }
                  icon="person"
                  onlyRegistered
                />
              ) : (
                <input
                  className="form-control"
                  disabled
                  value={getFullName(this.state.owner)}
                />
              )}
            </div>
            <div className="form-group">
              <label>Notiz</label>
              <textarea
                className="form-control"
                disabled
                value={this.state.task?.description || ''}
              />
            </div>
            <div className="form-group">
              <label>Deadline</label>
              <input
                className="form-control"
                disabled
                value={formatDate(this.state.task?.deadline)}
              />
            </div>
            <div className="links">
              <LinkList links={links} linkObject={{ task: task }} />
            </div>
          </>
        )}
      </>
    )
  }
}

export default withAuth(withSubscriptions(TaskDetail))
