export enum RedCapSaveMethods {
  /** saves a new event with new hash and instance */
  NEW = 'NEW',

  /** saves an existing event with the same hash and new instance, used for editing */
  EDIT = 'EDIT',

  /** saves on the same hash and instance, only for filling out forms by the patient or relatives */
  CURRENT = 'CURRENT',

  /** read only, triggers an error message if it gets to BE */
  READ = 'READ',
}

export enum RedCapSaveStatus {
  COMPLETE = 'COMPLETE',
  UNVERIFIED = 'UNVERIFIED',
  INCOMPLETE = 'INCOMPLETE',
}

export interface RedCapSaveCombination {
  method: RedCapSaveMethods
  status: RedCapSaveStatus
  /** to sign a medical round*/
  sign?: boolean
  /** creates a new task assigned to idPart, e.g. 22 */
  assignTo?: string
}
