import React from 'react'
import { AutosaveService } from '../../services/AutosaveService'
import DraftTable from './DraftTable/DraftTable'
import { Draft, RedCapDraft } from '../../types/Draft'
import Loader from '../UI/Spinner/Loader'
import { NotificationManager } from 'react-notifications'
import AppointmentDetail from '../Appointments/AppointmentDetail/AppointmentDetail'
import TaskDetail from '../Tasks/TaskDetail/TaskDetail'
import MedicalRoundInstruments from '../Forms/MedicalRoundInstruments'
import { DraftKind } from '../../types/DraftKind'
import { nest } from '../../utils/NestUtils'
import { Task } from '../../types/Task'
import { Appointment } from '../../types/Appointment'
import {
  DynamicSidebarContent,
  DynamicSidebarStore,
} from '../../infrastructure/DynamicSidebarProvider'
import { defaultFormTabWidth } from '../../constants/DynamicSidebarConstants'
import {
  SubscriptionProps,
  SubscriptionType,
  withSubscriptions,
} from '../../infrastructure/SubscriptionProvider'

export const KIND_TO_NAME = {
  Task: 'Aufgaben',
  Appointment: 'Termine',
  RedCap: 'Formulare',
  RedCapRegistration: 'Erstanmeldung',
}

export const KIND_TO_ICON = {
  Task: 'notifications',
  Appointment: 'today',
  RedCap: 'sticky_note_2',
}

interface DraftContainerState<T> {
  groups: Record<
    DraftKind,
    Record<string, Record<string, Record<string, Draft<T>[]>>>
  >
  draft: T
}

class DraftContainer<
  T extends Draft<Task | Appointment | RedCapDraft>
> extends React.Component<SubscriptionProps, DraftContainerState<T>> {
  static contextType = DynamicSidebarStore

  onNotificationReceived = (): void => {
    this.loadDrafts()
  }
  private boundOnNotificationReceived = this.onNotificationReceived.bind(this)

  state = {
    groups: null,
    draft: null,
  }

  componentDidMount(): void {
    this.loadDrafts()
    if (this.props.subscribe) {
      this.props.subscribe(
        SubscriptionType.DynamicSidebar,
        null,
        this.boundOnNotificationReceived
      )
    }
  }

  componentWillUnmount(): void {
    if (this.props.unsubscribe) {
      this.props.unsubscribe(
        SubscriptionType.DynamicSidebar,
        null,
        this.boundOnNotificationReceived
      )
    }
    this.setState = (values: DraftContainerState<T>) => {
      console.warn('[DraftContainer] State update after unmount', values)
    }
  }

  loadDrafts = async (): Promise<void> => {
    const drafts = await new AutosaveService<T>().getAll()

    if (drafts) {
      const groups = this.groupValues(drafts)
      this.setState({ groups: groups })
    } else
      NotificationManager.error('Die Entwürfe konnten nicht geladen werden')
  }

  groupValues = (
    drafts: Draft<T>[]
  ): Record<
    DraftKind,
    Record<string, Record<string, Record<string, Draft<T>[]>>>
  > => {
    const groupedDrafts = nest(drafts, [
      'kind',
      'value.patientId',
      'value.context.instruments.0.event_name',
      'value.context.instruments.0.hash_value',
    ]) as Record<
      DraftKind,
      Record<string, Record<string, Record<string, Draft<T>[]>>>
    >
    return groupedDrafts
  }

  onDelete = async (draft: Draft<T>): Promise<void> => {
    await new AutosaveService<T>().deleteDraft(draft)
    await this.loadDrafts()
  }

  handleDraftClick = (draft: T): void => {
    this.setState({ draft }, this.handleShowWholeEventClick)
  }

  onCloseSidebar = (): void => {
    // TODO put this into the sidebars "onClose()", but it's not working nowhere
    if (this.state.draft?.tempId)
      this.context.handlers.removeContent(this.state.draft.tempId)
    this.setState({ draft: null })
    this.loadDrafts()
  }

  handleShowWholeEventClick = (): void => {
    const { addContent } = this.context.handlers

    if (this.state.draft) {
      const identifier = this.state.draft.tempId

      let component: JSX.Element
      switch (this.state.draft.kind) {
        case DraftKind.Appointment:
          component = (
            <AppointmentDetail
              appointmentId={this.state.draft.value.id}
              onClose={this.onCloseSidebar}
              onChanged={() => null}
            />
          )
          break
        case DraftKind.Task:
          component = (
            <TaskDetail
              taskId={this.state.draft.value.id}
              closeTab={this.onCloseSidebar}
              onChanged={this.onCloseSidebar}
            />
          )
          break
        case DraftKind.RedCap:
          component = (
            <MedicalRoundInstruments
              instruments={this.state.draft.value.instruments}
              patientId={this.state.draft.value.patientId}
              saveMethod={this.state.draft.value.saveMethod}
              closeSidebar={this.onCloseSidebar}
              onSaved={this.onCloseSidebar}
              showPreviousValues
            />
          )
          break
        default: {
          component = null
          NotificationManager.error('Unbekannter Typ von Entwurf')
        }
      }
      if (component) {
        const forms: DynamicSidebarContent = {
          identifier: identifier,
          headline: this.state.draft.title,
          component: component,
          onClose: () => null,
          minWidth: defaultFormTabWidth,
          initialWidth: defaultFormTabWidth,
        }
        addContent(forms)
      }
    }
  }

  render(): JSX.Element {
    return (
      <>
        <div>
          <div className="d-flex align-items-center">
            <h3>Entwürfe</h3>
          </div>
          {!this.state.groups ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : this.state.groups && Object.keys(this.state.groups).length > 0 ? (
            <DraftTable
              groups={this.state.groups}
              selectedRowId={null}
              onDeleteRow={this.onDelete}
              onClick={this.handleDraftClick}
            />
          ) : (
            <div className="alert alert-info">Keine Entwürfe vorhanden</div>
          )}
        </div>
      </>
    )
  }
}

export default withSubscriptions(DraftContainer)
