import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import React, { useContext } from 'react'
import { InfoButton, OverlayMenuButton } from './ActionButtons'
import MaterialIcon from '../../UI/MaterialIcon/MaterialIcon'
import MedicalIcon from '../../UI/MedicalIcon/MedicalIcon'
import { MedicationRequestStatus } from '../../../constants/MedicationRequestStatus'
import { AuthStore } from '../../../infrastructure/AuthProvider'
import { UserPermission } from '../../../constants/UserPermission'
import { ConfirmDialogue } from '../../UI/Dialogues/ConfirmDialogue'
import { MedicationStatusDelegation } from '../../../utils/MedicationUtils'

function getIcon(shape) {
  switch (shape) {
    case 'Tablette':
      return 'tablets'
    case 'Pulver':
      return 'mountain'
    case 'Pflaster':
      return 'band-aid'
    case 'Pumpe':
      return 'pager'
    case 'Retard-Tablette':
      return 'tablets'
    case 'Kapsel':
      return 'capsules'
    case 'Pen':
      return 'pen'
    default:
      return 'prescription-bottle'
  }
}

function getClassByMedicationType(type) {
  switch (type) {
    case 'motorisch':
      return 'medication-motoric-type'
    case 'nicht-motorisch':
      return 'medication-non-motoric-type'
    case 'allgemein/anderes':
      return 'medication-other-type'
    default:
      return 'medication-unknown-type'
  }
}

/**
 *
 * @param {object} props
 * @param {Array<MedicationScheduleGroup>} props.groups
 * @param {MedicationScheduleGroup} props.group
 * @param {string} props.title
 * @returns {*}
 * @constructor
 */

interface LeftSidebarItemProps {
  groups: MedicationScheduleGroup[]
  group: MedicationScheduleGroup
}

const LeftSidebarItem: React.FC<LeftSidebarItemProps> = (
  props: LeftSidebarItemProps
) => {
  const group = props.group
  const ingredients = group.medicationRequest
    ? group.medicationRequest.contained
        ?.flatMap(med =>
          med.ingredient?.map(
            ingredient =>
              ingredient.itemCodeableConcept.text +
              ' ' +
              ingredient.strength.numerator.unit
          )
        )
        .join(', ')
    : group.title
  const isOnDemand =
    group.medicationRequest.dosageInstruction[0]?.timing?.repeat?.timeOfDay ===
      undefined && !group.isGroupHeader

  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip id={`tooltip-timeline-${group.id}`}>
          {group.isGroupHeader ||
          group.medicationRequest.dosageInstruction[0].timing.repeat
            .timeOfDay ? (
            ''
          ) : (
            <>
              <i>(PRN)</i>
              <br />
            </>
          )}
          {group.title}
          <br />
          <strong>Wirkstoff(e):</strong> {ingredients}
          <br />
          <strong>Typ:</strong> {group.type}
          <br />
          <strong>Form:</strong> {group.shape}
          <br />
        </Tooltip>
      }>
      <div
        className={`d-flex px-2 ${getClassByMedicationType(group.type)}${
          group.isGroupHeader ? ' medication-group' : ''
        } ${group.root ? ' ' : 'medication-group-inner-group'}`}>
        <div
          className={`medication-icon-group d-flex justify-content-stretch mr-2`}>
          <div className="medication-icon-wrapper">
            {group.root && group.isGroupHeader && (
              <MaterialIcon
                icon="filter_none"
                size={'18px'}
                verticalAlignment={'middle'}
              />
            )}
          </div>
          <div className="medication-icon-wrapper">
            <MedicalIcon
              className="medication-icon"
              name={getIcon(group.shape)}
            />
          </div>
          <div>{isOnDemand && <b>B</b>}</div>
        </div>
        <div className="flex-grow-1">
          <div className="medication-overflow-text">{ingredients}</div>
          <div className="medication-overflow-text">
            {group.isGroupHeader ? <i>Gruppierung</i> : group.title}
          </div>
        </div>
      </div>
    </OverlayTrigger>
  )
}

interface RightSideBarItemProps {
  disabled: boolean
  group: MedicationScheduleGroup
  onEdit: () => void
  onRemove: () => void
  onResume: () => void
  onConfirm: () => void
}
function RightSidebarItem(props: RightSideBarItemProps): JSX.Element {
  const context = useContext(AuthStore)
  const { hasPermission } = context.handlers
  const status = MedicationStatusDelegation.getCaseManagerOverRealStatus(
    props.group.medicationRequest
  )

  return (
    <div className="medication-schedule-sidebar-elements">
      <InfoButton group={props.group} variant={'outline-primary'} />
      <OverlayMenuButton
        disabled={!hasPermission(UserPermission.PATIENT_MEDICATION_ADD_EDIT)}
        variant={'primary'}
        icon="edit"
        onClick={props.onEdit}>
        Bearbeiten
      </OverlayMenuButton>
      {![
        MedicationRequestStatus.stopped,
        MedicationRequestStatus.cancelled,
        MedicationRequestStatus.completed,
      ].includes(status) ? (
        <ConfirmDialogue
          callback={props.onRemove}
          question="Wollen Sie die Verschreibung wirklich löschen?"
          confirmText="Löschen"
          title="Löschen">
          <OverlayMenuButton
            disabled={
              !hasPermission(UserPermission.PATIENT_MEDICATION_ADD_EDIT)
            }
            variant={'danger'}
            icon="delete">
            Löschen
          </OverlayMenuButton>
        </ConfirmDialogue>
      ) : (
        <OverlayMenuButton
          disabled={!hasPermission(UserPermission.PATIENT_MEDICATION_ADD_EDIT)}
          variant={'primary'}
          icon="refresh"
          onClick={props.onResume}>
          Wiederaufnehmen
        </OverlayMenuButton>
      )}

      {hasPermission(UserPermission.PATIENT_MEDICATION_CONFIRM) &&
        (MedicationStatusDelegation.statusDiffer([
          props.group.medicationRequest,
        ]) ||
          [
            MedicationRequestStatus.active,
            MedicationRequestStatus.draft,
            MedicationRequestStatus.on_hold,
          ].includes(
            MedicationStatusDelegation.getCaseManagerOverRealStatus(
              props.group.medicationRequest
            )
          )) && (
          <OverlayMenuButton
            disabled={
              !hasPermission(UserPermission.PATIENT_MEDICATION_CONFIRM) ||
              props.disabled
            }
            variant={'primary'}
            icon={
              MedicationStatusDelegation.getCaseManagerOverRealStatus(
                props.group.medicationRequest
              ) === MedicationRequestStatus.active
                ? 'pause'
                : 'done_outline'
            }
            onClick={props.onConfirm}>
            {MedicationStatusDelegation.getCaseManagerOverRealStatus(
              props.group.medicationRequest
            ) === MedicationRequestStatus.active
              ? 'Pausieren'
              : 'Bestätigen'}
          </OverlayMenuButton>
        )}
      {hasPermission(UserPermission.PATIENT_MEDICATION_CREATE_TASK) &&
        (MedicationStatusDelegation.statusDiffer([
          props.group.medicationRequest,
        ]) ||
          MedicationStatusDelegation.getCaseManagerOverRealStatus(
            props.group.medicationRequest
          ) === MedicationRequestStatus.draft) && (
          <OverlayMenuButton
            disabled={
              !hasPermission(UserPermission.PATIENT_MEDICATION_CREATE_TASK) ||
              props.disabled
            }
            variant={'primary'}
            icon={'add_task'}
            onClick={props.onConfirm}>
            {'Aufgabe zur Indikationsprüfung erstellen'}
          </OverlayMenuButton>
        )}
    </div>
  )
}

const LeftSidebarItemComponent = React.memo(LeftSidebarItem)
const RightSidebarItemComponent = React.memo(RightSidebarItem)

export { LeftSidebarItemComponent, RightSidebarItemComponent }
