import { Priority } from '../constants/Priority'
import { TaskStatus } from '../constants/TaskStatus'
import { TaskCategories } from '../constants/TaskCategories'
import { Patient } from './Patient'
import { Practitioner } from './Practitioner'

export class Task {
  id?: string
  title?: string
  priority?: Priority
  category?: TaskCategories
  description?: string
  status?: TaskStatus
  for?: string
  deadline?: Date
  start_date?: Date
  owner?: string
  author?: string
  lastUpdated?: Date
  note?: string
  appointments?: Array<string>
  history?: Array<string>
  links?: Map<string, unknown>
  ownerDto?: Practitioner
  forDto?: Patient
  formData?: string
  redcapForm?: string

  tempId?: string
  tempLinkIds?: Array<number>

  eventLinkIdentifier?: string
}
