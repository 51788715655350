import { ArrayHelpers, Field, FormikErrors, FormikTouched, getIn } from 'formik'
import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { DosageAndTimingSelector } from './DosageAndTimingSelector/DosageAndTimingSelector'
import MaterialIcon from '../UI/MaterialIcon/MaterialIcon'
import {
  MedicationRequestInputFormValues,
  TimeRange,
} from '../Medication/MedicationRequest/MedicationRequestModal'
import PropTypes from 'prop-types'
import { DateTimePickerField } from './DateTimePickerField'
import { ExtensionURL } from '../../constants/ExtensionURL'
import {
  determineInvalidDosages,
  handleDosageError,
} from '../../utils/MedicationRequestUtils'
import { getChoicesSingle, OptionChoices } from '../../utils/MedicationUtils'

/**
 *
 * @param {ActivityDefinition} activityDefinition
 * @returns {OptionChoices[]}
 */
const getTimingOptions = (
  activityDefinition: ActivityDefinition
): OptionChoices[] => {
  return activityDefinition.title.split(';').map(timing => {
    return { value: timing, label: timing }
  })
}

/**
 *
 * @param {ActivityDefinition} activityDefinition
 * @returns {OptionChoices[]}
 */
export const getPackageOptions = (
  activityDefinition: ActivityDefinition
): OptionChoices[] => {
  const packageVariant = activityDefinition.extension.find(
    extension =>
      extension.url === ExtensionURL.activityDefinitionPackageDosageVariants
  )

  return getChoicesSingle(packageVariant.valueString)
}

export const getPackageIds = (
  activityDefinition: ActivityDefinition
): string[] => {
  const packageVariantIds = activityDefinition.extension.find(
    extension =>
      extension.url === ExtensionURL.activityDefinitionMedicationResourcesList
  )
  return packageVariantIds.valueString.split(';')
}

interface TimeIntervalProps {
  arrayHelpers: ArrayHelpers
  timeRange: TimeRange
  values: MedicationRequestInputFormValues
  touched: FormikTouched<MedicationRequestInputFormValues>
  errors: FormikErrors<MedicationRequestInputFormValues>
  index: number
}

export const TimeIntervalSelector: React.FC<TimeIntervalProps> = ({
  arrayHelpers,
  timeRange,
  values,
  touched,
  errors,
  index,
}: TimeIntervalProps): JSX.Element => {
  const [errorDetected, setErrorDetected] = useState<boolean>(false)

  /**
   *
   * @returns {OptionChoices[]}
   * @param {MedicationRequestInputFormValues} values
   */
  const getDosageOptions = (
    values: MedicationRequestInputFormValues
  ): OptionChoices[] => {
    const packageVariants = getPackageOptions(values.activityDefinition)
    const dosages = values.activityDefinition.dosage.map(dosage => {
      return { value: dosage.text, label: dosage.text }
    })

    const invalidDosage = determineInvalidDosages(packageVariants, dosages)

    if (invalidDosage && !errorDetected) {
      handleDosageError(values.label, values.standardMedicationId).then()
      setErrorDetected(true)
    }

    return values.activityDefinition.dosage.map(dosage => {
      return { value: dosage.text, label: dosage.text }
    })
  }

  const ErrorMessage = ({ name }) => (
    <Field key={'error-message-' + name} name={name}>
      {({ form }) => {
        const error = getIn(form.errors, name)
        const touch = getIn(form.touched, name)
        return touch && error ? error : null
      }}
    </Field>
  )
  ErrorMessage.propTypes = {
    name: PropTypes.string.isRequired,
  }

  const dosageOptions = getDosageOptions(values)

  return (
    <div className={'intervals-medicationRequestModal'}>
      <Row key={'title-' + index}>
        <Col>
          <span>
            <b>Zeitintervall {index + 1}:</b>
          </span>
        </Col>
      </Row>
      <Row key={'setDates-row-' + index}>
        <Col>
          <label key={'startDate-label-' + index} htmlFor="startDate">
            Start der Medikation
          </label>
          <DateTimePickerField
            key={`timeIntervals[${index}].startDate`}
            name={`timeIntervals[${index}].startDate`}
            selectsStart
            selected={
              (timeRange.startDate && new Date(timeRange.startDate)) || null
            }
            className={`form-control ${
              getIn(touched, `timeIntervals[${index}].startDate`) &&
              getIn(errors, `timeIntervals[${index}].startDate`)
                ? 'is-invalid'
                : ''
            }`}
          />
          <div className="error-messages">
            <ErrorMessage name={`timeIntervals[${index}].startDate`} />
          </div>
        </Col>
        <Col>
          <label key={'endDate-label-' + index} htmlFor="endDate">
            Ende der Medikation
          </label>
          <DateTimePickerField
            key={`timeIntervals[${index}].endDate`}
            name={`timeIntervals[${index}].endDate`}
            selectsEnd
            selected={
              (timeRange.endDate && new Date(timeRange.endDate)) || null
            }
            className={`form-control ${
              getIn(touched, `timeIntervals[${index}].endDate`) &&
              getIn(errors, `timeIntervals[${index}].endDate`)
                ? 'is-invalid'
                : ''
            }`}
          />
          <div className="error-messages">
            <ErrorMessage name={`timeIntervals[${index}].endDate`} />
          </div>
        </Col>
      </Row>
      <Row key={'timingAndDosage-row-' + index}>
        <Col>
          <label
            key={'timingAndDosage-label-' + index}
            htmlFor="timingAndDosage">
            Timing und Dosierung
          </label>
          <DosageAndTimingSelector
            key={'timingAndDosage-' + index}
            name={timeRange.name}
            arrayHelpers={arrayHelpers}
            standardMedication={values.standardMedication}
            index={index}
            timeInterval={timeRange}
            package={values.package}
            timingOptions={getTimingOptions(values.activityDefinition)}
            dosageOptions={dosageOptions}
            timingAndDosage={timeRange.timingAndDosage}
            isValueError={
              getIn(touched, `timeIntervals[${index}].timingAndDosage`) &&
              getIn(errors, `timeIntervals[${index}].timingAndDosage`) !==
                null &&
              getIn(errors, `timeIntervals[${index}].timingAndDosage`) !==
                undefined
            }
          />
          <div className="error-messages">
            <ErrorMessage name={`timeIntervals[${index}].timingAndDosage`} />
          </div>
        </Col>
      </Row>
      {values.timeIntervals.length > 1 && (
        <>
          <Button onClick={() => arrayHelpers.remove(index)} size="sm">
            <MaterialIcon
              icon="delete"
              verticalAlignment="middle"
              size="16px"
            />{' '}
            Zeitraum löschen
          </Button>
        </>
      )}
      <hr />
    </div>
  )
}
