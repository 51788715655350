import { LetterConfig, LetterFlag } from '../types/LetterFlag'

export const ROUTE_FRONTEND = 'https://test.panos.infai.org' // 'http://localhost:3000'
export const BACKEND_API =
  (window as unknown as any)._env_?.BACKEND_API || 'http://localhost:8080'
export const AUTH_API =
  (window as unknown as any)._env_?.AUTH_API || 'http://localhost:8000'

export const REGISTRATION = '/registerNewPatient'

export const ROUTE_HOME = '/login'
export const ROUTE_REGISTRATION = '/registration'

export const ROUTE_DASHBOARD = '/dashboard'

export const ROUTE_CHAT = '/chat'
export const ROUTE_CHAT_NEW = '/chat/new'
export const ROUTE_CHAT_AT = '/chat/:id'

export const ROUTE_TASKS = '/tasks'
export const ROUTE_TASKS_NEW = '/tasks/new'
export const ROUTE_TASKS_AT = '/tasks/:id'

export const ROUTE_CALENDAR = '/calendar'
export const ROUTE_CALENDAR_NEW = '/calendar/new'
export const ROUTE_CALENDAR_AT = '/calendar/:id'

export const ROUTE_PATIENT_RECORDS = '/patient-records'
export const ROUTE_PATIENT_MONITORING = '/monitoring/patient'
export const ROUTE_PATIENT_RELATIVE_MONITORING = '/monitoring/relative'

export const ROUTE_PATIENT_RECORD = '/patient/:id'
export const ROUTE_PATIENT_DATA_TREATMENT = ROUTE_PATIENT_RECORD + '/treatment'
export const ROUTE_PATIENT_DATA_INDICATIONS =
  ROUTE_PATIENT_RECORD + '/indications'
export const ROUTE_PATIENT_DATA_FORMS_ARCHIVE =
  ROUTE_PATIENT_RECORD + '/forms-archive'
export const ROUTE_PATIENT_DATA_FORMS = ROUTE_PATIENT_RECORD + '/forms'
export const ROUTE_PATIENT_DATA_PERSON = ROUTE_PATIENT_RECORD + '/person'
export const ROUTE_PATIENT_DATA_APPOINTMENTS =
  ROUTE_PATIENT_RECORD + '/appointments'

export const ROUTE_DRAFT = '/draft'
export const ROUTE_MEDICATION_DATA_PAGE = '/medications'
export const ROUTE_MEDICATION_STANDARD_LIST = '/medications/standard'
export const ROUTE_MEDICATION_HOUSE_LIST = '/medications/house'
export const ROUTE_LETTER = '/letter'
export const ROUTE_LETTER_PREVIEW = '/letter/preview'
export const ROUTE_PATIENT_REGISTRATION = '/registration-patient'
export const ROUTE_MEDICAL_ROUND = '/medical-round'
export const ROUTE_PROFIL = '/profile'

export const ROUTE_REDCAP_EVENTS = '/event'

export const PARAMETRIC_LINKED_LINKS = {
  RedCap: ROUTE_MEDICAL_ROUND,
  Letter: ROUTE_LETTER,
}

export const ROUTE_ADMINISTRATION = '/administration'
export const ROUTE_ADMINISTRATION_PRACTITONER = '/administration/practitioner'
export const ROUTE_ADMINISTRATION_PRACTITONER_DETAIL =
  '/administration/practitioner/:id'
export const ROUTE_TELEFORM = '/teleform'
export const ROUTE_ADMINISTRATION_RIGHTS = '/administration/rights'

export const API_PRIVILEGES = BACKEND_API + '/api/privileges'
export const API_TASKS = BACKEND_API + '/api/task'
export const API_TASKS_ADD_USER = BACKEND_API + '/api/task/addUser'
export const API_TASKS_COMPLETE = BACKEND_API + '/api/task/completeTask'
export const API_USERS = BACKEND_API + '/api/user'
export const API_PATIENT = BACKEND_API + '/api/patient'
export const API_DRAFT = BACKEND_API + '/api/autosave'
export const API_ICD10 = BACKEND_API + '/api/icd10'
export const API_TRACKING = BACKEND_API + '/api/tracking'
export const API_ZEGV = BACKEND_API + '/api/zegv'

export const API_ICD10_BYCODE = API_ICD10 + '/getByCode'
export const API_ICD10_IMPORT = API_ICD10 + '/importICD10'

export const API_TRACKING_LOCATION = API_TRACKING + '/locations'
export const API_EXPORT_TRACKING_LOCATION = API_TRACKING + '/export'

export const API_USERS_PRACTITIONERS = API_USERS + '/practitioners'
export const API_USERS_PATIENTS = API_USERS + '/patients'
export const API_USERS_RESET_PASSWORD = API_USERS + '/resetPassword'

export const API_GET_PATIENTS_BY_IDS = API_PATIENT + '/ids'
export const API_EXPORT_ZEGV_MEDICAL_ROUND = API_ZEGV + '/export'
export const API_CAREPLAN = BACKEND_API + '/api/careplan'
export const API_GET_TRIAGE_TASKS_OF_PATIENT = API_PATIENT + '/:id/triages'
export const API_PRACTITIONER = BACKEND_API + '/api/practitioner'
export const API_GET_PRACTITIONERS = API_PRACTITIONER + '/list?ids='
export const API_IMPORT_CSV_PRACTITIONERS = API_PRACTITIONER + '/import/kvs'
export const API_CREATE_PRACTITIONERS = API_PRACTITIONER + '/import'
export const API_ORGANIZATION = BACKEND_API + '/api/organization'
export const API_ORGANIZATIONS = API_ORGANIZATION + '/all'
export const API_APPOINTMENTS = BACKEND_API + '/api/appointment'
export const API_LETTER = BACKEND_API + '/api/letter'
export const API_TELEFORM = BACKEND_API + '/api/teleform'

export const API_LETTER_TEMPLATE_DB = API_LETTER + '/letter_template'

export const API_MAD = BACKEND_API + '/api/mad'
export const API_DOCUMENT = BACKEND_API + '/api/document'
export const API_PATIENT_LETTER = BACKEND_API + '/api/patientLetter'
export const API_LOCATION = BACKEND_API + '/api/location'
export const API_MEDICATION_REQUESTS = BACKEND_API + '/api/medicationRequest'
export const API_CHAT_MESSAGING = BACKEND_API + '/api/messaging'
export const API_GET_CHAT_CONVERSATIONS_BY_SENDER =
  API_CHAT_MESSAGING + '/conversations'

export const API_LINKING_TASK_APPOINTMENT = BACKEND_API + '/api/linking'
export const API_LINKING_CREATE =
  API_LINKING_TASK_APPOINTMENT + '/createLinking'

export const API_REDCAP = BACKEND_API + '/api/redcap'
export const API_REDCAP_EXPORT_METADATA = API_REDCAP + '/export_metadata'
export const API_REDCAP_EXPORT_RECORDS = API_REDCAP + '/export_records'

// not used at the moment (redcapService.importRecords)
export const API_REDCAP_IMPORT_RECORDS = API_REDCAP + '/import_records'
export const API_REDCAP_SAVE_FORMS = API_REDCAP + '/save_forms'
export const API_REDCAP_PREFETCH_PIPING = API_REDCAP + '/prefetch_piping'
export const API_REDCAP_GET_FORM_INFORMATIONS =
  API_REDCAP + '/form_informations'
export const API_REDCAP_SYNC_METADATA = API_REDCAP + '/sync_metadata'
export const API_REDCAP_GET_SIGN_STATUS = API_REDCAP + '/sign_status'
export const API_REDCAP_VALIDATE_METADATA = API_REDCAP + '/validate_variables'
export const API_REDCAP_LAST_MODULE_INSTANCE =
  API_REDCAP + '/last_module_instance'

export const API_PATIENT_EXPORT_PA_P2 = API_PATIENT + '/selfRegistrationForm'
export const API_PATIENT_SELF_REGISTRATE = API_PATIENT + '/selfRegistration'
export const API_PATIENT_PATIENT_RECORDS = API_PATIENT + '/records'
export const API_PATIENT_ARCHIVED_EVENTS = API_PATIENT + '/:id/events'
export const API_PATIENT_NEW_EVENTS = API_PATIENT + '/:id/events/new'
export const API_PATIENT_REDCAP_LINKED_EVENT = API_PATIENT + '/:id/linked_event'
export const API_PATIENT_REDCAP_LINKED_NEW_EVENT =
  API_PATIENT + '/:id/linked_event/new'
export const API_PATIENT_REDCAP_LAST_EVENT = API_PATIENT + '/:id/event/last'
export const API_PATIENT_REDCAP_MEDICALINSTRUMENTS_SORTED =
  API_PATIENT + '/:id/monitoring_forms'
export const API_PATIENT_EXPORT_CSV = API_PATIENT + '/export'
export const API_PATIENT_IMPORT_SURVEY = API_PATIENT + '/registerSurveyPatient'
export const API_PATIENT_IMPORT_SURVEY_INFO =
  API_PATIENT_IMPORT_SURVEY + '/info'
export const API_PATIENT_VALIDATION = API_PATIENT + '/validation'

export const API_FORWARDING = BACKEND_API + '/api/forwarding'
export const API_FORWARDING_CREATE = API_FORWARDING + '/createResource'
export const API_FORWARDING_UPDATE = API_FORWARDING + '/updateResource'
export const API_FORWARDING_GET = API_FORWARDING + '/getResource'
export const API_FORWARDING_GET_RESOURCES =
  API_FORWARDING + '/getAllResourcesByType'
export const API_FORWARDING_GET_RESOURCES_BY_FILTER =
  API_FORWARDING + '/getResourcesByFilter'

export const PATIENT_REGISTRATION = API_PATIENT + REGISTRATION

export const webSocketQueueEndpoint = '/user/queue/notifications'
export const webSocketLoginSendEndpoint = '/app/login'
export const webSocketReceiveSendEndpoint = '/app/receive'

export const API_MEDICATION_PLAN_PRINT = BACKEND_API + '/api/medicationPlan'

export const LETTER_PATHS: Record<string, LetterConfig> = {
  LETTER_REJECTION: {
    template: '/create_rejection_letter_template',
    create: '/create_rejection_letter',
    required: { practitioner: true },
  } as LetterConfig,
  LETTER_REQUEST_INFORMATION: {
    template: '/create_request_information_letter_template',
    create: '/request_information_letter',
    required: { patient: true, deadline: true },
  } as LetterConfig,
  LETTER_INITIAL_MONITORING: {
    template: '/create_initial_monitoring_letter_template',
    create: '/initial_monitoring_letter',
    required: { patient: true, deadline: true } as LetterFlag,
  } as LetterConfig,
  LETTER_ADDITIONAL_CLAIM_MONITORING: {
    template: '/create_additional_claim_first_monitoring_template',
    create: '/create_additional_claim_first_monitoring',
    required: { patient: true, deadline: true } as LetterFlag,
  } as LetterConfig,
  LETTER_ADDITIONAL_CLAIM_INFORMATION: {
    template: '/create_additional_claim_first_information_template',
    create: '/create_additional_claim_first_information',
    required: {
      practitioner: true,
      patient: true,
      deadline: true,
    } as LetterFlag,
  } as LetterConfig,
  LETTER_APPOINTMENT: {
    template: '/appointment_template',
    create: '/appointments',
    required: {
      practitioner: false,
      patient: true,
      deadline: true,
      end: true,
      start: true,
    } as LetterFlag,
  } as LetterConfig,
  LETTER_EXTERNAL_PHYSICIAN: {
    template: '/practitioner_letter_template',
    create: '/practitioner_letter',
    required: { patient: true } as LetterFlag,
  } as LetterConfig,
  DOCUMENTATION: {
    template: '/practitioner_letter_template',
    create: '/documentation',
    required: {} as LetterFlag,
  } as LetterConfig,
}
