import roundTo from 'round-to'
import { DosageAndTimingSelectorProps } from './DosageAndTimingSelector'
import { ISetFieldValue } from '../../Forms/FormInterfaces'

export function OnInsertedDosageChanged(
  label: string,
  value: string,
  index: number,
  props: DosageAndTimingSelectorProps,
  setFieldValue: ISetFieldValue
): void {
  setFieldValue(
    `timeIntervals[${props.index}].timingAndDosage.dosages[${index}].dose`,
    {
      value: value,
      label: label,
    }
  )

  if (value) {
    let dosageNumber: number
    let packageDosageNumber: number

    if (value.includes('/')) {
      dosageNumber = Number(value.split('/')[0].replace(',', '.'))
      packageDosageNumber = Number(
        props.package.value.split('/')[0].replace(',', '.')
      )
    } else {
      dosageNumber = Number(value.replace(',', '.'))
      packageDosageNumber = Number(props.package.value.replace(',', '.'))
    }
    if (
      props.standardMedication.form.text === 'Tropfen' &&
      props.standardMedication.ingredient[0].strength.numerator.unit === 'mg/ml'
    ) {
      setFieldValue(
        `timeIntervals[${props.index}].timingAndDosage.dosages[${index}].pieces`,
        roundTo((20 * dosageNumber) / packageDosageNumber, 8)
      )
      setFieldValue(
        `timeIntervals[${props.index}].timingAndDosage.dosages[${index}].volume`,
        roundTo(dosageNumber / packageDosageNumber, 8)
      )
    } else if (
      props.standardMedication.form.text !== 'Tropfen' &&
      props.standardMedication.ingredient[0].strength.numerator.unit === 'mg/ml'
    ) {
      setFieldValue(
        `timeIntervals[${props.index}].timingAndDosage.dosages[${index}].volume`,
        roundTo(dosageNumber / packageDosageNumber, 8)
      )
      setFieldValue(
        `timeIntervals[${props.index}].timingAndDosage.dosages[${index}].pieces`,
        roundTo(dosageNumber / packageDosageNumber, 8)
      )
    } else {
      setFieldValue(
        `timeIntervals[${props.index}].timingAndDosage.dosages[${index}].pieces`,
        roundTo(dosageNumber / packageDosageNumber, 8)
      )
    }
  }
}
