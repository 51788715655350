import React from 'react'
import { Task } from '../../../types/Task'
import { PriorityLabels } from '../../../constants/Priority'
import DeadlineSelector from './DeadlineSelector'
import PractitionerSelect from '../../UI/Forms/PractitionerSelect/PractitionerSelect'
import PatientSelect from '../../UI/Forms/PatientSelect/PatientSelect'
import { Patient } from '../../../types/Patient'
import { Practitioner } from '../../../types/Practitioner'
import { TaskCategories } from '../../../constants/TaskCategories'
import { withAuth } from '../../../infrastructure/AuthProvider'
import FormInput from '../../UI/Forms/FormInput/FormInput'
import FormTextarea from '../../UI/Forms/FormTextarea/FormTextarea'
import FormSelect from '../../UI/Forms/FormSelect/FormSelect'
import { TaskFormErrors } from '../../../types/TaskFormErrors'

interface TaskFieldsViewProps {
  newTask: Task
  handleInputChange: (
    fieldname: string,
    value: string | Date | Patient | Practitioner
  ) => void
  patientId: string
  editMode?: boolean
  staticFields?: string[]
  errors?: TaskFormErrors
}

const TaskFieldsView: React.FC<TaskFieldsViewProps> = (
  props: TaskFieldsViewProps
) => {
  const {
    newTask,
    handleInputChange,
    patientId,
    editMode,
    errors,
    staticFields,
  } = props

  return (
    <>
      <FormInput
        className={'title'}
        name="title"
        label="Titel"
        onChange={value => handleInputChange('title', value)}
        value={newTask?.title || ''}
        error={errors?.title}
      />
      <FormSelect
        label="Kategorie"
        name="category"
        options={Object.entries(TaskCategories).map(val => ({
          label: val[1],
          value: val[1],
        }))}
        value={newTask?.category}
        className={'category'}
        onChange={value => handleInputChange('category', value)}
        error={errors?.category}
      />

      <FormSelect
        label="Priorität"
        name="priority"
        options={Object.entries(PriorityLabels).map(val => ({
          label: val[1],
          value: val[0],
        }))}
        value={newTask?.priority || ''}
        className={'task-severity'}
        onChange={value => handleInputChange('priority', value)}
        error={errors?.priority}
      />

      {!patientId && (
        <div className="form-group patient">
          <label>Patient</label>
          <PatientSelect
            value={newTask.forDto}
            onChange={value => handleInputChange('patient', value)}
            icon="person"
            disabled={patientId ? true : false}
            name={'patient'}
          />
        </div>
      )}
      <div className="form-group owner">
        <label>Bearbeiter</label>
        <PractitionerSelect
          value={newTask.ownerDto || null}
          onChange={value => handleInputChange('ownerDto', value)}
          icon="person"
          disabled={editMode || staticFields?.includes('ownerDto')}
          onlyRegistered
          error={errors?.ownerDto}
        />
      </div>
      <FormTextarea
        name={'description'}
        label={'Notiz'}
        className="description task_desciption"
        value={newTask.description || ''}
        onChange={value => handleInputChange('description', value)}
      />

      <div className="form-group deadline">
        <label>Deadline in</label>
        <DeadlineSelector
          value={newTask?.deadline}
          onChange={value => handleInputChange('deadline', value)}
          error={errors?.deadline}
        />
      </div>
    </>
  )
}

export default withAuth(TaskFieldsView)
