import React, { useContext, useState } from 'react'
import { Button, Col, Form as Form2, Modal, Row } from 'react-bootstrap'
import {
  ErrorMessage,
  FastField,
  FieldArray,
  Form,
  Formik,
  FormikErrors,
  FormikTouched,
  useFormikContext,
} from 'formik'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import { getActivityDefinitionById } from '../../../services/ActivityDefinitionService'
import { TimingAndDosage } from '../../Formik/DosageAndTimingSelector/DosageAndTimingSelector'
import Loader from '../../UI/Spinner/Loader'
import MaterialIcon from '../../UI/MaterialIcon/MaterialIcon'
import _, { cloneDeep } from 'lodash'
import moment from 'moment'
import {
  getPackageIds,
  getPackageOptions,
  TimeIntervalSelector,
} from '../../Formik/TimeIntervalSelector'
import { getMedicationById } from '../../../services/MedicationService'
import { NotificationManager } from 'react-notifications'
import {
  addResourceTypeIfNot,
  getExtensionValueByUrl,
} from '../../../utils/Utils'
import MedicationForm from '../MedicationForm/MedicationForm'
import { AuthStore } from '../../../infrastructure/AuthProvider'
import { UserPermission } from '../../../constants/UserPermission'
import { MedicationRequestInitialRationale } from '../../../constants/MedicationRequestInitialRationale'
import { MedicationRequestPanosRationale } from '../../../constants/MedicationRequestPanosRationale'
import roundTo from 'round-to'
import { getNextHalfHour, OptionChoices } from '../../../utils/MedicationUtils'
import { MedicationScheduleObject } from '../../../utils/MedicationScheduleUtils'
import MedicationActivityDefinitionSelect from '../../UI/Forms/MedicationSelect/MedicationActivityDefinitionSelect'

interface validateErrors {
  timeIntervals?: [
    {
      startDate?: string
      endDate?: string
      timingAndDosage?: string
    }
  ]
  note?: string
  warning?: string
  package?: string
  historyReason?: string
  arrangementReason?: string
  label?: string
  activityDefinition?: string
  isEditing?: string
}

/**
 *
 * @param {object} values
 * @returns {object}
 */
const validate = values => {
  const errors: validateErrors = {
    activityDefinition: null,
    timeIntervals: [{}],
    note: null,
    warning: null,
    package: null,
    historyReason: null,
    arrangementReason: null,
    label: null,
    isEditing: null,
  }
  let errorExists = false
  values.timeIntervals.forEach((interval, index) => {
    if (index > 0) {
      errors.timeIntervals.push({})
    }
    if (!interval.startDate) {
      errors.timeIntervals[index].startDate = 'Erforderlich'
      errorExists = true
    } else if (interval.startDate > interval.endDate) {
      errors.timeIntervals[index].startDate = 'Muss vor dem Enddatum sein'
      errorExists = true
    }
    if (!interval.endDate) {
      errors.timeIntervals[index].endDate = 'Erforderlich'
      errorExists = true
    } else if (interval.endDate < interval.startDate) {
      errors.timeIntervals[index].endDate = 'Muss nach dem Startdatum sein'
      errorExists = true
    }
    if (interval.startDate < new Date()) {
      errors.timeIntervals[index].startDate =
        'Startdatum darf nicht in der Vergangenheit sein'
      errorExists = true
    }
    if (index < values.timeIntervals.length - 1) {
      if (interval.endDate > values.timeIntervals[index + 1].startDate) {
        errors.timeIntervals[index].endDate =
          'Zeitintervalle dürfen nicht überlappen'
        errorExists = true
      }
    }
    if (interval.timingAndDosage.selectedTiming?.value === 'prn') {
      if (
        interval.timingAndDosage?.dosages[0]?.frequencyMax === null ||
        interval.timingAndDosage?.dosages[0]?.frequencyMax % 1 !== 0
      ) {
        errors.timeIntervals[index].timingAndDosage =
          'Erforderlich - Maximale Einnahmen muss ganze Zahl sein'
        errorExists = true
      } else if (interval.timingAndDosage?.dosages[0]?.frequencyMax < 1) {
        errors.timeIntervals[index].timingAndDosage =
          'Erforderlich - Maximale Einnahmen muss positiv sein'
        errorExists = true
      }
      if (
        values.standardMedication?.form?.text !== 'Tropfen' &&
        values.standardMedication?.form?.text !== 'Pflaster' &&
        values.standardMedication?.form?.text !== 'Pen' &&
        values.standardMedication?.ingredient[0]?.strength.numerator.unit !==
          'mg/ml'
      ) {
        if (
          interval.timingAndDosage?.dosages.find(
            dosageObject =>
              dosageObject.pieces === null || dosageObject.pieces % 0.25 !== 0
          )
        ) {
          errors.timeIntervals[index].timingAndDosage =
            'Erforderlich - Maximale Stückzahl darf nur halbierbar oder viertelbar sein'
          errorExists = true
        } else if (
          interval.timingAndDosage?.dosages.find(
            dosageObject => dosageObject.pieces < 0.25
          )
        ) {
          errors.timeIntervals[index].timingAndDosage =
            'Erforderlich - Maximale Stückzahl darf nicht kleiner als ein Viertel sein.'
          errorExists = true
        }
      } else if (
        values.standardMedication?.form?.text === 'Tropfen' ||
        values.standardMedication?.form?.text === 'Pflaster' ||
        values.standardMedication?.form?.text === 'Pen'
      ) {
        if (
          interval.timingAndDosage?.dosages.find(
            dosageObject =>
              dosageObject.pieces === null || dosageObject.pieces % 1 !== 0
          )
        ) {
          errors.timeIntervals[index].timingAndDosage =
            'Erforderlich - Maximale Stückzahl muss ganze Zahl sein.'
          errorExists = true
        } else if (
          interval.timingAndDosage?.dosages.find(
            dosageObject => dosageObject.pieces < 1
          )
        ) {
          errors.timeIntervals[index].timingAndDosage =
            'Erforderlich - Maximale Stückzahl darf nicht kleiner als 1 sein.'
          errorExists = true
        }
      }
      if (
        interval.timingAndDosage?.dosages[0]?.period === null ||
        interval.timingAndDosage?.dosages[0]?.period % 1 !== 0
      ) {
        errors.timeIntervals[index].timingAndDosage =
          'Erforderlich - Mindestabstand Einzeldosierung muss ganze Zahl sein'
        errorExists = true
      } else if (interval.timingAndDosage?.dosages[0]?.period < 1) {
        errors.timeIntervals[index].timingAndDosage =
          'Erforderlich - Mindestabstand Einzeldosierung muss positiv sein'
        errorExists = true
      }
      if (
        interval.timingAndDosage?.dosages[0]?.period === null ||
        interval.timingAndDosage?.dosages[0]?.period % 1 !== 0
      ) {
        errors.timeIntervals[index].timingAndDosage =
          'Erforderlich - Periode Maximaleinnahmen muss ganze Zahl sein'
        errorExists = true
      } else if (interval.timingAndDosage?.dosages[0]?.period < 1) {
        errors.timeIntervals[index].timingAndDosage =
          'Erforderlich - Periode Maximaleinnahmen muss positiv sein'
        errorExists = true
      }
      if (!interval.timingAndDosage?.dosages[0]?.periodUnit) {
        errors.timeIntervals[index].timingAndDosage =
          'Erforderlich - Zeiteinheit ist erforderlich'
        errorExists = true
      }
      if (
        interval.timingAndDosage?.dosages.find(
          dosageObject =>
            dosageObject.dose === null || dosageObject.dose.value === ''
        )
      ) {
        errors.timeIntervals[index].timingAndDosage =
          'Erforderlich - Dosierung Fehlt'
        errorExists = true
      }
    } else {
      if (interval.timingAndDosage?.timing.length === 0) {
        errors.timeIntervals[index].timingAndDosage = 'Erforderlich'
        errorExists = true
      } else if (
        interval.timingAndDosage?.dosages.find(
          dosageObject =>
            dosageObject.dose === null || dosageObject.dose.value === ''
        )
      ) {
        errors.timeIntervals[index].timingAndDosage =
          'Erforderlich - Dosierung Fehlt'
        errorExists = true
      } else {
        const notSelectedTiming = interval.timingAndDosage.timing.filter(
          time =>
            !values.timeIntervals[index].timingAndDosage.dosages.find(
              dosageObject => dosageObject.times.includes(time)
            )
        )
        if (notSelectedTiming.length > 0) {
          errors.timeIntervals[index].timingAndDosage =
            'Erforderlich - Nicht alle Zeiten ausgewählt'
          errorExists = true
        } else if (
          interval.timingAndDosage?.dosages.find(
            dosageObject => dosageObject.times.length === 0
          )
        ) {
          errors.timeIntervals[index].timingAndDosage =
            'Erforderlich - Dosierung ohne Zeiten'
          errorExists = true
        }
      }
      if (
        values.standardMedication?.form?.text !== 'Tropfen' &&
        values.standardMedication?.form?.text !== 'Pflaster' &&
        values.standardMedication?.form?.text !== 'Pen' &&
        values.standardMedication?.ingredient[0]?.strength.numerator.unit !==
          'mg/ml'
      ) {
        if (
          interval.timingAndDosage?.dosages.find(
            dosageObject =>
              dosageObject.pieces === null || dosageObject.pieces % 0.25 !== 0
          )
        ) {
          errors.timeIntervals[index].timingAndDosage =
            'Erforderlich - Stückangabe darf nur halbierbar oder viertelbar sein'
          errorExists = true
        } else if (
          interval.timingAndDosage?.dosages.find(
            dosageObject => dosageObject.pieces < 0.25
          )
        ) {
          errors.timeIntervals[index].timingAndDosage =
            'Erforderlich - Stückzahl darf nicht kleiner als ein Viertel sein.'
          errorExists = true
        }
      }
      if (
        values.standardMedication?.form?.text === 'Tropfen' ||
        values.standardMedication?.form?.text === 'Pflaster' ||
        values.standardMedication?.form?.text === 'Pen'
      ) {
        if (
          interval.timingAndDosage?.dosages.find(
            dosageObject =>
              dosageObject.pieces === null || dosageObject.pieces % 1 !== 0
          )
        ) {
          errors.timeIntervals[index].timingAndDosage =
            'Erforderlich - Stückangabe muss ganze Zahl sein.'
          errorExists = true
        } else if (
          interval.timingAndDosage?.dosages.find(
            dosageObject => dosageObject.pieces < 1
          )
        ) {
          errors.timeIntervals[index].timingAndDosage =
            'Erforderlich - Stückzahl darf nicht kleiner als 1 sein.'
          errorExists = true
        }
      }
      if (
        interval.timingAndDosage?.dosages.find(
          dosageObject =>
            dosageObject.period === null || dosageObject.period % 1 !== 0
        )
      ) {
        errors.timeIntervals[index].timingAndDosage =
          'Erforderlich - Periode muss ganze Zahl sein'
        errorExists = true
      } else if (
        interval.timingAndDosage?.dosages.find(
          dosageObject => dosageObject.period < 1
        )
      ) {
        errors.timeIntervals[index].timingAndDosage =
          'Erforderlich - Periode muss Zahl muss positiv sein'
        errorExists = true
      }
    }

    if (
      values.standardMedication?.ingredient[0]?.strength.numerator.unit ===
        'mg/ml' &&
      values.standardMedication?.form.text !== 'Tropfen'
    ) {
      if (
        interval.timingAndDosage?.dosages.find(
          dosageObject =>
            dosageObject.volume === undefined ||
            dosageObject.volume === null ||
            dosageObject.volume.isNaN ||
            dosageObject.volume === 0
        )
      ) {
        errors.timeIntervals[index].timingAndDosage =
          'Erforderlich - Menge muss angegeben und ungleich 0 sein.'
        errorExists = true
      }
    }
  })

  if (
    values.historyReason &&
    values.historyReason.value === 'FURTHER' &&
    (values.note === '' || !values.note)
  ) {
    errors.note =
      'Erforderlich - Ein Grund muss angegeben sein, wenn "Weiteres" im Feld "Historie" ausgewählt ist.'
    errorExists = true
  } else if (
    values.arrangementReason &&
    values.arrangementReason.value === 'FURTHER' &&
    (values.note === '' || !values.note)
  ) {
    errors.note =
      'Erforderlich - Ein Grund muss angegeben sein, wenn "Aus folgender Indikation:" im Feld "Neu / hier angeordnet" ausgewählt ist.'
    errorExists = true
  }

  if (values.medicationId === null) {
    errors.package =
      'Das entsprechende Präparat ist bereits im Medikamentenplan enthalten.'
    errorExists = true
  }

  if (!values.label || values.label === '') {
    errors.label = 'Erforderlich'
    errorExists = true
  } else if (values.label.length > 200) {
    errors.label = 'Maximal 200 Zeichen'
    errorExists = true
  }
  if (!errorExists) {
    return {}
  } else {
    return errors
  }
}

function getShapeOptions(
  medicationActivityDefinitions: MedicationActivityDefinition[],
  activityDefinitionIds: string | null
): OptionChoices[] {
  if (activityDefinitionIds && medicationActivityDefinitions) {
    const filteredMAD = medicationActivityDefinitions.filter(it =>
      activityDefinitionIds.split('|').includes(it?.activityDefinitionId)
    )
    const uniqShape = _.uniq(filteredMAD.map(it => it.shape))

    return uniqShape.map(it => {
      return { value: it, label: it }
    })
  } else {
    return null
  }
}

function getBusinessNameOptions(
  medicationActivityDefinitions: MedicationActivityDefinition[],
  activityDefinitionIds: string | null,
  shape: string | null
): OptionChoices[] {
  if (shape && activityDefinitionIds) {
    const filteredMAD = medicationActivityDefinitions.filter(
      it =>
        activityDefinitionIds.split('|').includes(it.activityDefinitionId) &&
        shape === it.shape
    )
    return filteredMAD.map(it => {
      return {
        value: it.medicationId + '|' + it.activityDefinitionId,
        label: it.businessName,
      }
    })
  } else {
    return null
  }
}

export interface TimeRange {
  name: string
  startDate: string
  endDate: string
  timingAndDosage: TimingAndDosage
}

export interface MedicationRequestInputFormValues {
  timeIntervals: TimeRange[]
  medicationId: string
  package?: OptionChoices
  arrangementReason: OptionChoices
  historyReason: OptionChoices
  note: string
  warning: string
  label: string
  activityDefinition?: ActivityDefinition
  isEditing: boolean
  standardMedicationId: id
  standardMedication: Medication
  medicationRequestId: string
}

interface MedicationRequestInputFormProps {
  schedule: MedicationScheduleObject
  values: MedicationRequestInputFormValues
  touched: FormikTouched<MedicationRequestInputFormValues>
  errors: FormikErrors<MedicationRequestInputFormValues>
}

const MedicationRequestInputForm: React.FC<MedicationRequestInputFormProps> = ({
  schedule,
  values,
  touched,
  errors,
}: MedicationRequestInputFormProps): JSX.Element => {
  const { setFieldValue } = useFormikContext()
  const [inputNote, setInputNote] = useState(values.note)
  const [inputWarning, setInputWarning] = useState(values.warning)

  function onInsertedNote(value) {
    setFieldValue(`note`, value)
  }

  function onInsertedWarning(value) {
    setFieldValue(`warning`, value)
  }

  function onHistoryReasonChanged(value) {
    setFieldValue(`historyReason`, value)
    setFieldValue(`arrangementReason`, undefined)
  }

  function onArrangementReasonChanged(value) {
    setFieldValue(`arrangementReason`, value)
    setFieldValue(`historyReason`, undefined)
  }

  function addTimeInterval(timeInterval, arrayHelpers, index) {
    const newTimeInterval = cloneDeep(timeInterval)

    /**
     * shrink current timeInterval to preset startDate of new
     * timeInterval (startDate + 3 months)
     */
    const offset = 3
    newTimeInterval.startDate = moment(newTimeInterval.startDate)
      .startOf('minute')
      .add(offset, 'months')
      .toDate()
    newTimeInterval.endDate = new Date('2099-12-31T00:00:00')

    setFieldValue(
      `timeIntervals[${index}].endDate`,
      cloneDeep(newTimeInterval.startDate)
    )
    arrayHelpers.push(newTimeInterval)
  }

  function onInsertedPackageChanged(schedule, selection, standardMedication) {
    const valuesCopy = cloneDeep(values)
    valuesCopy.package = selection
    const packageIds = getPackageIds(values.activityDefinition)
    const packages = getPackageOptions(values.activityDefinition).map(
      it => it.value
    )
    const selectedIndex = packages.indexOf(selection.value)
    const idString = packageIds[selectedIndex] as string
    valuesCopy.medicationId = idString.split('/')[1]
    valuesCopy.timeIntervals.forEach(interval => {
      if (interval.timingAndDosage) {
        if (interval.timingAndDosage.dosages) {
          let dosageNumber: number
          let packageDosageNumber: number
          let i = 0
          for (const dosages of interval.timingAndDosage.dosages) {
            if (dosages.dose) {
              if (dosages.dose.value.includes('/')) {
                dosageNumber = Number(
                  dosages.dose.value.split('/')[0].replace(',', '.')
                )
                packageDosageNumber = Number(
                  selection.value.split('/')[0].replace(',', '.')
                )
              } else {
                dosageNumber = Number(dosages.dose.value.replace(',', '.'))
                packageDosageNumber = Number(selection.value.replace(',', '.'))
              }
              if (
                standardMedication.form.text === 'Tropfen' &&
                standardMedication.ingredient[0].strength.numerator.unit ===
                  'mg/ml'
              ) {
                interval.timingAndDosage.dosages[i].pieces = roundTo(
                  (20 * dosageNumber) / packageDosageNumber,
                  8
                )
                interval.timingAndDosage.dosages[i].volume = roundTo(
                  dosageNumber / packageDosageNumber,
                  8
                )
              } else if (
                standardMedication.form.text !== 'Tropfen' &&
                standardMedication.ingredient[0].strength.numerator.unit ===
                  'mg/ml'
              ) {
                interval.timingAndDosage.dosages[i].volume = roundTo(
                  dosageNumber / packageDosageNumber,
                  8
                )
              } else {
                interval.timingAndDosage.dosages[i].pieces = roundTo(
                  dosageNumber / packageDosageNumber,
                  8
                )
              }
            }
            i++
          }
        }
      }
    })
    setFieldValue(`timeIntervals`, valuesCopy.timeIntervals)
    setFieldValue(`package`, valuesCopy.package)
    if (
      !schedule.groups
        .map(it => it.groupIdKey)
        .includes(valuesCopy.medicationId)
    ) {
      setFieldValue(`medicationId`, valuesCopy.medicationId)
    }
  }

  return (
    <>
      <hr />
      <Row key={'description-input-row'}>
        <Col>
          <label key={'description-label'} htmlFor="label">
            Bezeichnung
          </label>
          <FastField
            key={'description-input'}
            type="input"
            name="label"
            className={`form-control ${
              touched.label && errors.label ? 'is-invalid' : ''
            }`}
          />
          <ErrorMessage
            className="error-messages"
            name="label"
            component="div"
          />
        </Col>
      </Row>
      <hr />
      <Row key={'package-select'}>
        <Col>
          {values.standardMedication?.ingredient[0].strength.numerator.unit !==
            'mg/ml' && (
            <label key={'package-label'} htmlFor="package">
              Dosierung pro Stück [
              {values.standardMedication?.ingredient.map((it, index) => {
                if (index === 0) {
                  return it.strength.numerator.unit
                } else {
                  return '|' + it.strength.numerator.unit
                }
              })}
              ]
            </label>
          )}
          {values.standardMedication?.ingredient[0].strength.numerator.unit ===
            'mg/ml' && (
            <label key={'package-label'} htmlFor="package">
              Dosierung pro ml [
              {values.standardMedication?.ingredient.map((it, index) => {
                if (index === 0) {
                  return it.strength.numerator.unit
                } else {
                  return '|' + it.strength.numerator.unit
                }
              })}
              ]
            </label>
          )}
          <Select
            id={'package-select'}
            name={`package`}
            className={'package-select'}
            classNamePrefix={'package-select'}
            options={getPackageOptions(values.activityDefinition)}
            value={values.package}
            onChange={val => {
              onInsertedPackageChanged(schedule, val, values.standardMedication)
            }}
          />
          <ErrorMessage
            className="error-messages"
            name="package"
            component="div"
          />
        </Col>
      </Row>
      <hr />
      <FieldArray
        name="timeIntervals"
        render={arrayHelpers => {
          return (
            <>
              {values.timeIntervals.map((item, index) => {
                return (
                  <TimeIntervalSelector
                    key={'timeIntervalSelector' + index}
                    arrayHelpers={arrayHelpers}
                    timeRange={item}
                    values={values}
                    errors={errors}
                    touched={touched}
                    index={index}
                  />
                )
              })}
              <Row key={'add-timeInterval-button-row'}>
                <Col key={'add-timeInterval-button-col'}>
                  <Button
                    data-testid="add-timeInterval-button"
                    onClick={() => {
                      addTimeInterval(
                        values.timeIntervals[values.timeIntervals.length - 1],
                        arrayHelpers,
                        values.timeIntervals.length - 1
                      )
                    }}
                    size="sm">
                    <MaterialIcon
                      icon="add"
                      verticalAlignment="middle"
                      size="16px"
                    />{' '}
                    Weiterer Zeitraum
                  </Button>
                </Col>
              </Row>
            </>
          )
        }}
      />
      <hr />
      <Row key={'reason-row-header'}>
        <Col>
          <h4>Begründung</h4>
        </Col>
      </Row>
      <Row key={'reason-row'}>
        <Col>
          <label key={'historyId-label-'} htmlFor="historyReason">
            Historie
          </label>
          {Object.entries(MedicationRequestInitialRationale).map(
            (val, innerIndex) => (
              <Form2.Check
                type={'radio'}
                label={val[1]}
                value={val[0]}
                checked={
                  values.historyReason?.label === val[1] &&
                  values.historyReason?.value === val[0]
                }
                name={`historyReason`}
                id={'history-select-' + innerIndex}
                key={'history-select-' + innerIndex}
                onChange={() => {
                  onHistoryReasonChanged({ value: val[0], label: val[1] })
                }}
              />
            )
          )}
          <div className="error-messages">
            <ErrorMessage name={`historyReason`} />
          </div>
        </Col>
        <Col>
          <label key={'arrangement-label'} htmlFor="arrangementReason">
            Neu / hier angeordnet
          </label>
          {Object.entries(MedicationRequestPanosRationale).map(
            (val, innerIndex) => (
              <Form2.Check
                type={'radio'}
                label={val[1]}
                value={val[0]}
                checked={
                  values.arrangementReason?.label === val[1] &&
                  values.arrangementReason?.value === val[0]
                }
                name={`arrangementReason`}
                id={'arrangement-select-' + innerIndex}
                key={'arrangement-select-' + innerIndex}
                onChange={() => {
                  onArrangementReasonChanged({ value: val[0], label: val[1] })
                }}
              />
            )
          )}
          <div className="error-messages">
            <ErrorMessage name={`arrangementReason`} />
          </div>
        </Col>
      </Row>
      <br />
      <Row key={'note'}>
        <Col>
          <label key={'note-label'} htmlFor="note">
            {values.isEditing
              ? 'Grund der (veränderten) Anordnung'
              : 'Bemerkungen'}
          </label>
          <textarea
            name={`note`}
            className="form-control"
            value={inputNote}
            key={'note'}
            id={'note'}
            onChange={event => setInputNote(event.target.value)}
            onBlur={event => {
              setInputNote(event.target.value)
              onInsertedNote(event.target.value)
            }}
          />
          <div className="error-messages">
            <ErrorMessage name={`note`} />
          </div>
        </Col>
      </Row>
      <br />
      <Row key={'warning'}>
        <Col>
          <label key={'warning-label'} htmlFor="warning">
            Warnhinweise
          </label>
          <textarea
            name={`warning`}
            className="form-control"
            value={inputWarning}
            key={'warning'}
            id={'warning'}
            onChange={event => setInputWarning(event.target.value)}
            onBlur={event => {
              setInputWarning(event.target.value)
              onInsertedWarning(event.target.value)
            }}
          />
          <div className="error-messages">
            <ErrorMessage name={`warning`} />
          </div>
        </Col>
      </Row>
    </>
  )
}

/**
 * @param {MedicationScheduleObject} schedule
 * @param {stateSetter} setIsLoading
 * @param {OptionChoices} option
 * @param {stateSetter} setFieldValue
 * @param {object} initialValues
 * @param {boolean} isLoading
 * @param {MedicationActivityDefinition[]} ingredientGroup
 * @param {React.Dispatch<React.SetStateAction<MedicationActivityDefinition[]>} setSelectedIngredientGroup
 * @param {id} standardMedicationId
 */

function handleChangeFirstHierarchy(
  schedule,
  setIsLoading,
  option,
  setFieldValue,
  initialValues,
  isLoading,
  ingredientGroup,
  setSelectedIngredientGroup,
  standardMedicationId
) {
  setFieldValue('shape', null)
  setFieldValue('businessName', null)
  setFieldValue('timeIntervals', initialValues.timeIntervals)
  setFieldValue('activityDefinitionIds', option.value)
  setFieldValue('shape', null)
  setFieldValue('activityDefinition', null)
  setFieldValue('label', '')
  setFieldValue('standardMedicationId', null)
  setFieldValue('standardMedication', null)
  setFieldValue('medicationId', null)
  setFieldValue('package', null)
  setSelectedIngredientGroup(ingredientGroup)

  const shapeOptions = getShapeOptions(
    ingredientGroup,
    option.value ?? initialValues.activityDefinitionIds
  )
  const preSelectedShape = shapeOptions?.filter(
    option =>
      shapeOptions.length === 1 || option.value === ingredientGroup?.[0].shape
  )?.[0]
  const businessNameOptions = getBusinessNameOptions(
    ingredientGroup,
    option.value,
    preSelectedShape?.value
  )
  const preSelectedBusinessName = businessNameOptions?.filter(
    option =>
      businessNameOptions.length === 1 ||
      option.value.split('|')[0] === standardMedicationId
  )?.[0]

  if (preSelectedShape) {
    setFieldValue('shape', preSelectedShape)
    if (preSelectedBusinessName) {
      handleChangeThirdHierarchy(
        schedule,
        setIsLoading,
        preSelectedBusinessName,
        setFieldValue,
        isLoading
      ).then()
    }
  }
}

/**
 * @param {MedicationScheduleObject} schedule
 * @param {stateSetter} setIsLoading
 * @param {OptionChoices} option
 * @param {stateSetter} setFieldValue
 * @param {object} initialValues
 * @param {string} activityDefinitionIds
 * @param {MedicationActivityDefinition[]} medicationActivityDefinitions
 * @param {boolean} isLoading
 */
function handleChangeSecondHierarchy(
  schedule,
  setIsLoading,
  option,
  setFieldValue,
  initialValues,
  activityDefinitionIds,
  medicationActivityDefinitions,
  isLoading
) {
  const timeIntervals = cloneDeep(initialValues.timeIntervals)
  setFieldValue('businessName', null)
  setFieldValue('timeIntervals', timeIntervals)
  setFieldValue('shape', option)
  setFieldValue('activityDefinition', null)
  setFieldValue('label', '')
  setFieldValue('standardMedicationId', null)
  setFieldValue('standardMedication', null)
  setFieldValue('medicationId', null)
  setFieldValue('package', null)
  const businessNameOption =
    getBusinessNameOptions(
      medicationActivityDefinitions,
      activityDefinitionIds,
      option.value
    )?.length === 1
      ? getBusinessNameOptions(
          medicationActivityDefinitions,
          activityDefinitionIds,
          option.value
        )[0]
      : null

  if (businessNameOption) {
    handleChangeThirdHierarchy(
      schedule,
      setIsLoading,
      businessNameOption,
      setFieldValue,
      isLoading
    ).then()
  }
}

/**
 * @param {MedicationScheduleObject} schedule
 * @param {stateSetter} setIsLoading
 * @param {OptionChoices} option
 * @param {stateSetter} setFieldValue
 * @param {boolean} isLoading
 */
async function handleChangeThirdHierarchy(
  schedule,
  setIsLoading,
  option,
  setFieldValue,
  isLoading
) {
  if (!isLoading)
    try {
      setIsLoading(true)
      const activityDefinitionTypeId = addResourceTypeIfNot(
        option.value.split('|')[1],
        'ActivityDefinition'
      )
      const activityDefinition = await getActivityDefinitionById(
        activityDefinitionTypeId
      )
      if (activityDefinition.data) {
        const medicationTypeId = addResourceTypeIfNot(
          option.value.split('|')[0],
          'Medication'
        )
        const standardMedication = await getMedicationById(medicationTypeId)
        if (standardMedication.data) {
          const standardMedIndex = getPackageIds(
            activityDefinition.data
          ).findIndex(
            it => it === activityDefinition.data.productReference.reference
          )
          const packages = getPackageOptions(activityDefinition.data)
          const timeIntervals = [
            {
              name: 'timeIntervals',
              startDate: getNextHalfHour(moment()),
              endDate: new Date('2099-12-31T00:00:00'),
              timingAndDosage: {
                timing: [],
                selectedTiming: null,
                dosages: [{ dose: packages[standardMedIndex] }],
                patientInstructions: '',
              },
            },
          ]
          setFieldValue('businessName', option)
          setFieldValue('package', packages[standardMedIndex])
          setFieldValue('timeIntervals', timeIntervals)
          setFieldValue('activityDefinition', activityDefinition.data)
          setFieldValue(
            'label',
            activityDefinition.data.productReference.display
          )
          setFieldValue('standardMedicationId', option.value.split('|')[0])
          setFieldValue('standardMedication', standardMedication.data)
          if (
            !schedule.groups
              .map(it => it.groupIdKey)
              .includes(option.value.split('|')[0])
          ) {
            setFieldValue('medicationId', option.value.split('|')[0])
          }
        } else {
          console.error('Es trat ein Fehler beim Abfragen der Medikation auf.')
          NotificationManager.error(
            'Es trat ein Fehler beim Abfragen der Medikation auf.'
          )
        }
      } else {
        console.error('Es trat ein Fehler beim Abfragen der Medikation auf.')
        NotificationManager.error(
          'Es trat ein Fehler beim Abfragen der Medikation auf.'
        )
      }
    } catch (e) {
      console.error(e)
      NotificationManager.error(
        'Es trat ein Fehler beim Abrufen der Medikamentendaten auf.'
      )
    } finally {
      setIsLoading(false)
    }
}

const combineMedicationANDactivityDefinition = (
  activityDefinition: ActivityDefinition,
  medication: Medication
) => {
  if (!activityDefinition || !medication) return null
  const substances = medication.ingredient.map(
    it => it.itemCodeableConcept.text
  )
  const strengths = medication.ingredient.map(it => it.strength.numerator.value)
  const units = medication.ingredient.map(it => it.strength.numerator?.unit)

  const dosageChoices =
    activityDefinition.dosage != null
      ? activityDefinition.dosage.map(it => it.text).join(';')
      : ''

  return {
    activityDefinitionId: activityDefinition.id,
    businessName: medication.code.text,
    substances: substances.join('/'),
    standardDosage: strengths.join('/'),
    unit: units?.[0],
    type: getExtensionValueByUrl(medication.extension, 'Art'),
    administration: getExtensionValueByUrl(medication.extension, 'Applikation'),
    shape: medication.form.text.toString(),
    dosageChoices: dosageChoices,
    timing: activityDefinition.title,
  } as MedicationActivityDefinition
}

interface MedicationRequestModalProps {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  schedule: MedicationScheduleObject
  onSubmit: (values: unknown, identifierList: unknown) => void
  initialValues: Record<string, unknown>
}

export const MedicationRequestModal: React.FC<MedicationRequestModalProps> = ({
  showModal,
  setShowModal,
  schedule,
  onSubmit,
  initialValues,
}: MedicationRequestModalProps): JSX.Element => {
  const [showMedicamentCreationForm, setShowMedicamentCreationForm] =
    useState<boolean>(false)

  const context = useContext(AuthStore)

  const [selectedIngredientGroup, setSelectedIngredientGroup] = useState<
    MedicationActivityDefinition[]
  >([
    combineMedicationANDactivityDefinition(
      initialValues.activityDefinition as ActivityDefinition,
      initialValues.standardMedication as Medication
    ) as MedicationActivityDefinition,
  ])

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setShowModal(false)
  }

  const handleCreateNewMedicament = () => {
    setShowMedicamentCreationForm(true)
  }

  const handleBackToMedicationRequest = () => {
    setShowMedicamentCreationForm(false)
  }

  const handleBackToMedicationRequestAfterSaving = () => {
    handleBackToMedicationRequest()

    setIsLoading(true)

    setIsLoading(false)
  }

  return (
    <Modal animation={true} show={showModal} size={'xl'} onHide={handleClose}>
      {showMedicamentCreationForm ? (
        <MedicationForm
          onClose={handleBackToMedicationRequestAfterSaving}
          handleBack={handleBackToMedicationRequest}
        />
      ) : (
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={values => {
            onSubmit(values, values.activityDefinition.identifier)
          }}>
          {({ values, touched, errors, isSubmitting, setFieldValue }) => {
            const shapeOptions = getShapeOptions(
              selectedIngredientGroup,
              values.activityDefinitionIds ??
                initialValues.activityDefinitionIds
            )
            const businessNameOptions = getBusinessNameOptions(
              selectedIngredientGroup,
              values.activityDefinitionIds,
              values.shape?.value
            )

            return (
              <Form autoComplete="off">
                <Modal.Body>
                  <>
                    <div className="d-flex">
                      <div className="flex-fill mr-2">
                        <label
                          key={'substance'}
                          htmlFor="activityDefinitionIds">
                          Wirkstoff
                        </label>
                        <MedicationActivityDefinitionSelect
                          onChange={ingredientGroup => {
                            handleChangeFirstHierarchy(
                              schedule,
                              setIsLoading,
                              {
                                value: ingredientGroup
                                  ?.filter(it => it)
                                  .map(it => it.activityDefinitionId)
                                  .join('|'),
                              },
                              setFieldValue,
                              initialValues,
                              isLoading,
                              ingredientGroup,
                              setSelectedIngredientGroup,
                              values
                            )
                          }}
                          value={selectedIngredientGroup}
                          disabled={!!initialValues.standardMedication}
                        />
                      </div>
                      <div className="flex-fill mr-2">
                        <label key={'shape-label'} htmlFor="shape">
                          Darreichungsform
                        </label>
                        <Select
                          autofocus
                          className="basic-single"
                          classNamePrefix="select"
                          name="shape"
                          options={shapeOptions}
                          value={values.shape}
                          isLoading={isLoading}
                          onChange={option => {
                            handleChangeSecondHierarchy(
                              schedule,
                              setIsLoading,
                              option,
                              setFieldValue,
                              initialValues,
                              values.activityDefinitionIds,
                              selectedIngredientGroup,
                              isLoading
                            )
                          }}
                          isDisabled={
                            values.activityDefinitionIds === null ||
                            initialValues.activityDefinition !== null
                          }
                        />
                      </div>
                      <div className="flex-fill">
                        <div className="d-flex justify-content-between">
                          <label
                            key={'business-name'}
                            htmlFor="standardMedicationId">
                            Handelsname
                          </label>
                          <label className="checkbox-inline">
                            <input
                              className="form-check-inline"
                              name="autIdem"
                              type="checkbox"
                              value={values.autIdem}
                              onChange={() =>
                                setFieldValue('autIdem', !values.autIdem)
                              }
                              checked={values.autIdem}
                            />
                            Aut Idem
                          </label>
                        </div>
                        <Select
                          autofocus
                          className="basic-single"
                          classNamePrefix="select"
                          name="standardMedicationId"
                          options={businessNameOptions}
                          value={values.businessName}
                          isLoading={isLoading}
                          onChange={option => {
                            handleChangeThirdHierarchy(
                              schedule,
                              setIsLoading,
                              option,
                              setFieldValue,
                              isLoading
                            ).then()
                          }}
                          isDisabled={
                            !values.shape ||
                            initialValues.activityDefinition !== null
                          }
                        />
                      </div>
                    </div>
                    {values.activityDefinition && (
                      <MedicationRequestInputForm
                        schedule={schedule}
                        values={values}
                        touched={touched}
                        errors={errors}
                      />
                    )}
                  </>
                </Modal.Body>
                <Modal.Footer>
                  {context.handlers.hasPermission(
                    UserPermission.MEDICATIONS_ADD
                  ) ||
                  context.handlers.hasPermission(
                    UserPermission.MEDICATIONS_EDIT
                  ) ? (
                    <Button
                      variant={'secondary'}
                      className="ml-0 mr-auto"
                      onClick={handleCreateNewMedicament}>
                      Medikament Erstellen
                    </Button>
                  ) : null}
                  <Button variant="btn btn-secondary" onClick={handleClose}>
                    Schließen
                  </Button>
                  <Button
                    type="submit"
                    disabled={!values.activityDefinition || isSubmitting}>
                    Speichern {isSubmitting && <Loader size={24} />}
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      )}
    </Modal>
  )
}

export default React.memo(MedicationRequestModal)
