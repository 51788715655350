import ApiServiceBase, { API_RETURN } from './ApiServiceBase'
import {
  API_GET_PATIENTS_BY_IDS,
  API_GET_TRIAGE_TASKS_OF_PATIENT,
  API_MEDICATION_PLAN_PRINT,
  API_PATIENT,
  API_PATIENT_EXPORT_CSV,
  API_PATIENT_IMPORT_SURVEY,
  API_PATIENT_IMPORT_SURVEY_INFO,
  API_PATIENT_LETTER,
  API_PATIENT_PATIENT_RECORDS,
  API_PATIENT_VALIDATION,
} from '../config/Paths'
import { ApiResponse } from '../types/ApiResponse'
import { Patient } from '../types/Patient'
import { ApiError } from '../types/ApiError'
import { APIResult } from '../constants/APIResult'
import { parseUnknownToDate } from '../utils/Utils'
import { Task } from '../types/Task'
import { PatientRecord, PatientRecordResponse } from '../types/PatientRecord'
import { PatientRecordsFilterParams } from '../types/PatientRecordsFilterParams'
import axios, { AxiosResponse } from 'axios'
import { Pseudonym } from '../types/Pseudonym'
import { getToken } from './Keycloak'

class PatientService extends ApiServiceBase {
  parseDatesCorrectly(
    response: Promise<ApiResponse<Patient | ApiError>>
  ): Promise<ApiResponse<Patient>> {
    return response.then((res: ApiResponse<Patient>) => {
      if (res && res.Result && res.Result === APIResult.SUCCESS) {
        const patient = res.Response as Patient
        const birthDate = patient.birthDate
          ? parseUnknownToDate(patient.birthDate)
          : null
        const lastUpdate = patient.birthDate
          ? parseUnknownToDate(patient.birthDate)
          : null
        return {
          Result: APIResult.SUCCESS,
          Response: {
            ...patient,
            birthDate,
            lastUpdate,
          } as Patient,
        }
      }
      return {
        Result: APIResult.FAILURE,
        Response: null,
      }
    })
  }

  async findPatient(
    searchParam: string,
    active = true
  ): Promise<ApiResponse<Patient[] | ApiError>> {
    const url = new URL(API_PATIENT)
    url.searchParams.append('search', searchParam)
    url.searchParams.append('active', `${active}`)

    return this.get<null, Patient[]>(url).then(
      (res: ApiResponse<Patient[]>) => res
    )
  }

  /**
   * @deprecated not used anymore, deactivated BE methods
   */
  async loadPatients(patientsIdParts: string[]): Promise<Patient[]> {
    // in case id's are transmitted instead of idparts or duplicates. the backend can handle blanks, special characters etc.
    patientsIdParts = [
      ...new Set(
        patientsIdParts?.map(patientId => patientId?.replace('Patient/', ''))
      ),
    ]

    const result = await this.getPatientsByIds(patientsIdParts)
    if (result.Result === APIResult.SUCCESS) {
      return result.Response as Patient[]
    } else {
      //todo: implement error handling!
    }
  }

  /**
   * @deprecated not used anymore, deactivated BE methods
   */
  getPatientsByIds(
    patientsIdParts: string[]
  ): Promise<ApiResponse<Patient[] | ApiError>> {
    const url = new URL(API_GET_PATIENTS_BY_IDS)
    const joinIdsToString = patientsIdParts.join(',')
    url.searchParams.append('patientsIdParts', joinIdsToString)
    return this.get<null, Patient[]>(url)
  }

  async getPatients(): Promise<ApiResponse<Patient[] | ApiError>> {
    const url = new URL(API_PATIENT)
    return this.get<null, Patient[]>(url).then(
      (res: ApiResponse<Patient[]>) => {
        if (res && res.Result && res.Result === APIResult.SUCCESS) {
          const patients = res.Response as Patient[]
          return {
            Result: res.Result,
            Response: patients
              ? patients?.map(patient => {
                  return {
                    ...patient,
                    birthDate: parseUnknownToDate(patient.birthDate),
                    lastUpdate: parseUnknownToDate(patient.lastUpdate),
                  } as Patient
                })
              : null,
          }
        }
        return {
          Result: APIResult.FAILURE,
          Response: null,
        }
      }
    )
  }

  async getPatientRecords(
    startIndex = 0,
    filterParams: PatientRecordsFilterParams = null
  ): Promise<ApiResponse<PatientRecordResponse>> {
    const url = new URL(API_PATIENT_PATIENT_RECORDS + '/' + startIndex)

    if (filterParams) {
      if (filterParams.caseManagerId) {
        url.searchParams.append('caseManager', filterParams.caseManagerId)
      }
      if (filterParams.generalPractitionerId) {
        url.searchParams.append(
          'generalPractitioner',
          filterParams.generalPractitionerId
        )
      }
      if (filterParams.severity) {
        url.searchParams.append('severity', filterParams.severity.toLowerCase())
      }

      if (filterParams.active !== null) {
        url.searchParams.append('active', String(filterParams.active))
      }

      if (
        filterParams.withoutPractitioner !== null &&
        filterParams.withoutPractitioner !== undefined
      ) {
        url.searchParams.append(
          'withoutPractitioner',
          String(filterParams.withoutPractitioner)
        )
      }
    }

    return this.get<null, PatientRecordResponse>(url).then(
      (res: ApiResponse<PatientRecordResponse>) => {
        if (res && res.Result && res.Result === APIResult.SUCCESS) {
          const response = res.Response as PatientRecordResponse

          return {
            Result: res.Result,
            Response: response
              ? ({
                  ...response,
                  patients: response?.patients.map(patient => {
                    return {
                      ...patient,
                      lastUpdate: parseUnknownToDate(patient.lastUpdate),
                    } as PatientRecord
                  }),
                } as PatientRecordResponse)
              : null,
          }
        } else {
          return {
            Result: APIResult.FAILURE,
            Response: null,
          }
        }
      }
    )
  }

  getPatient(patientId: string): Promise<ApiResponse<Patient | ApiError>> {
    return this.parseDatesCorrectly(
      this.get<null, Patient>(new URL(API_PATIENT + `/${patientId}`))
    )
  }

  getTriageTasksOfPatient(
    patientId: string
  ): Promise<ApiResponse<Task[] | ApiError>> {
    const url = API_GET_TRIAGE_TASKS_OF_PATIENT.replace(':id', patientId)
    return this.get<null, Task[]>(new URL(url))
  }

  postPatient(patient: Patient): Promise<ApiResponse<Patient | ApiError>> {
    return this.post<Patient, Patient>(new URL(API_PATIENT), null, patient)
  }

  putPatient(patient: Patient): Promise<ApiResponse<Patient | ApiError>> {
    return this.put<Patient>(new URL(API_PATIENT + `/${patient.id}`), patient)
  }

  archivePatient(patientId: string): Promise<ApiResponse<Patient | ApiError>> {
    this.put = this.put.bind(this)
    return this.put<Patient>(
      new URL(API_PATIENT + `/archive/${patientId}`),
      null
    )
  }

  restorePatient(patientId: string): Promise<ApiResponse<Patient | ApiError>> {
    this.put = this.put.bind(this)
    return this.put<Patient>(
      new URL(API_PATIENT + `/restore/${patientId}`),
      null
    )
  }

  pseudonymize(patientId: string): Promise<ApiResponse<Pseudonym | ApiError>> {
    return this.post<null, Pseudonym>(
      new URL(API_PATIENT + `/pseudonymize`),
      {
        id: patientId,
      },
      null
    )
  }

  createPatientLetter(
    patientId: string,
    letterType: string
  ): Promise<ApiResponse<string | ApiError>> {
    const url = new URL(API_PATIENT_LETTER + `/${patientId}`)
    url.searchParams.append('letterType', letterType)
    return this.get<null, string>(url)
  }

  createMedicationPlanPrint(
    patientId: string,
    planType: string
  ): Promise<ApiResponse<string | ApiError>> {
    const url = new URL(API_MEDICATION_PLAN_PRINT + `/${patientId}`)
    url.searchParams.append('planType', planType)
    return this.get<null, string>(url)
  }

  async exportPatientCSV(): Promise<AxiosResponse> {
    return await axios.get(API_PATIENT_EXPORT_CSV, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
  }

  importFromSurvey = async (): Promise<ApiResponse<string | ApiError>> => {
    return await this.get<null, string>(
      new URL(API_PATIENT_IMPORT_SURVEY),
      null
    )
  }

  importFromSurveyInfo = async (): Promise<ApiResponse<string | ApiError>> => {
    return await this.get<null, string>(
      new URL(API_PATIENT_IMPORT_SURVEY_INFO),
      null,
      API_RETURN.text
    )
  }

  async validatePatients(): Promise<AxiosResponse> {
    return await axios.get(API_PATIENT_VALIDATION, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
  }
}

export default PatientService
