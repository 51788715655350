import React, { Component, ReactNode, useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { getActivityDefinitionByMedication } from '../../../services/ActivityDefinitionService'
import { Overlay, Popover } from 'react-bootstrap'

/**
 * @type {string}
 */
const identifierPrefix = 'urn:panos-backend-medication:'

interface MedicationDetailsProps {
  group: any
  show: any
  target: never
  setShow: any
}
/**
 * @property {MedicationScheduleGroup} props.group
 */
class MedicationDetails extends Component<MedicationDetailsProps> {
  _isMounted = false
  state = {
    medication: null,
    activityDefinition: null,
  }

  set isMounted(isMounted: boolean) {
    this._isMounted = isMounted
  }

  get isMounted(): boolean {
    return this._isMounted
  }

  componentDidMount(): void {
    const medicationId = this.props.group.groupIdKey
    this.isMounted = true
    if (medicationId !== undefined) {
      this.setState({
        medication: this.props.group.medicationRequest.contained.find(
          it => it.resourceType === 'Medication'
        ),
      })

      getActivityDefinitionByMedication(medicationId)
        .then(response => {
          if (response.data && this.isMounted && response.data.entry) {
            this.setState({
              activityDefinition: response.data.entry[0].resource,
            })
          }
        })
        .catch
        // TODO error message
        ()
    }
  }

  componentWillUnmount(): void {
    this.isMounted = false
  }

  showWarnings(): string {
    const foundWarnings = this.state.medication.identifier?.find(
      identifier =>
        identifier.system.replace(identifierPrefix, '') === 'Warnhinweise'
    )
    return foundWarnings === undefined || foundWarnings.value === undefined
      ? 'keine'
      : foundWarnings.value
  }

  render(): ReactNode {
    return (
      <>
        <Overlay
          target={this.props.target}
          show={this.props.show}
          placement={'left'}
          rootClose={true}
          rootCloseEvent={'click'}
          onHide={() => this.props.setShow(false)}>
          <Popover
            id={`medication-request-info-${this.props.group.medicationRequest.id}`}
            className={'medication-details-popover'}>
            <Popover.Title>
              <h2>
                {this.props.group.medicationRequest.note
                  ? this.props.group.medicationRequest.note[0].text
                  : 'no Title'}
              </h2>
            </Popover.Title>
            <Popover.Content>
              {this.state.medication && (
                <>
                  <IngredientTable
                    ingredient={this.state.medication.ingredient}
                  />
                  <h3>Warnhinweise</h3>
                  {this.showWarnings()}
                  <h3>Sonstiges</h3>
                  {this.state.medication.identifier?.map(identifier => {
                    if (
                      identifier.value &&
                      identifier.system.replace(identifierPrefix, '') !==
                        'Warnhinweise'
                    )
                      return (
                        <div key={identifier.system}>
                          {identifier.system.replace(identifierPrefix, '') +
                            ' : ' +
                            identifier.value}
                        </div>
                      )
                    return null
                  })}
                  <div>{'Form : ' + this.state.medication.form.text}</div>
                </>
              )}
              {this.state.activityDefinition && (
                <>
                  <TimingsTable
                    timing={this.state.activityDefinition.title.split(';')}
                  />
                  <DosagesTable
                    dosages={this.state.activityDefinition.dosage}
                  />
                </>
              )}
            </Popover.Content>
          </Popover>
        </Overlay>
      </>
    )
  }
}

interface IngredientTableProps {
  ingredient: Array<MedicationIngredient>
}

function IngredientTable(props: IngredientTableProps): JSX.Element {
  const [columns, setColumns] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    const columns = [
      {
        dataField: 'Substances',
        text: 'Wirkstoffe',
      },
    ]
    const Quantities = {
      Substances: `Menge in ${props.ingredient[0].strength.numerator.unit}`,
    }
    for (
      let substanceIndex = 0;
      substanceIndex < props.ingredient.length;
      substanceIndex++
    ) {
      columns.push({
        dataField: `Substance ${substanceIndex}`,
        text: props.ingredient[substanceIndex].itemCodeableConcept.text,
      })
      Quantities[`Substance ${substanceIndex}`] =
        props.ingredient[substanceIndex].strength.numerator.value
    }
    setColumns(columns)
    setData([Quantities])
  }, [props])

  return (
    <div className="bg-light">
      <h3>Standarddosis</h3>
      {columns && data && (
        <BootstrapTable
          keyField={'Substances'}
          data={data}
          columns={columns}
          scrollTop={'Bottom'}
        />
      )}
    </div>
  )
}

interface TimingTableProps {
  timing: Array<string>
}

function TimingsTable(props: TimingTableProps): JSX.Element {
  return (
    <div className="bg-light">
      <h3>Möglichkeiten der Medikamenteneinahme mit Zeitpunkt</h3>
      <table>
        <tbody>
          <tr className={'timings-table'}>
            {props.timing.map(time => {
              return <td key={time}>{time}</td>
            })}
          </tr>
        </tbody>
      </table>
    </div>
  )
}

interface DosageTableProps {
  dosages: Array<Dosage>
}

function DosagesTable(props: DosageTableProps): JSX.Element {
  return (
    <div className="bg-light">
      <h3>Möglichkeiten der Dosierung</h3>
      <table>
        <tbody>
          <tr className={'dosages-table'}>
            {props.dosages.map(dosage => {
              return <td key={dosage.text}>{dosage.text}</td>
            })}
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export { MedicationDetails }
