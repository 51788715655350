enum PanosInstitution {
  dresden = 'Zentrum Dresden',
  meissen = 'Zentrum Meißen',
  hetzdorf = 'Zentrum Hetzdorf',
}

const PanosInstitutionNames: Record<
  PanosInstitution,
  { fullName: string; shortName: string }
> = {
  [PanosInstitution.meissen]: {
    fullName: 'Elblandklinikum Meißen',
    shortName: 'Meißen',
  },
  [PanosInstitution.hetzdorf]: {
    fullName: 'Klinik am Tharandter Wald, Hetzdorf',
    shortName: 'Hetzdorf',
  },
  [PanosInstitution.dresden]: {
    fullName: 'Universitätsklinikum Carl Gustav Carus, Dresden',
    shortName: 'Dresden',
  },
}

export { PanosInstitution, PanosInstitutionNames }
