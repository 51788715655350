import ApiServiceBase from './ApiServiceBase'
import { ApiResponse } from '../types/ApiResponse'
import { ApiError } from '../types/ApiError'
import { ICD10 } from '../types/ICD10'
import { API_ICD10, API_ICD10_BYCODE, API_ICD10_IMPORT } from '../config/Paths'

export class ICD10Service extends ApiServiceBase {
  async findICD10(
    searchParam: string,
    excludeSelf: boolean
  ): Promise<ApiResponse<ICD10[] | ApiError>> {
    const url = new URL(API_ICD10)
    url.searchParams.append('search', searchParam)
    url.searchParams.append('excludeSelf', excludeSelf.toString())

    return this.get<null, ICD10[]>(url).then((res: ApiResponse<ICD10[]>) => res)
  }

  getICD10sById(code: string): Promise<ApiResponse<ICD10 | ApiError>> {
    const url = new URL(API_ICD10_BYCODE + '/' + code)
    return this.get<null, ICD10>(url)
  }

  importICD10Codes(): Promise<ApiResponse<null | ApiError>> {
    const url = new URL(API_ICD10_IMPORT)
    return this.get<null, null>(url)
  }
}

export default ICD10Service
