import React, { useContext } from 'react'
import Header from '../Header/Header'
import '../../../panos.scss'
import IAuth from '../../../types/Auth'
import Sidebar from '../Sidebar/Sidebar'
import DynamicSidebarRenderer from '../DynamicSidebar/DynamicSidebarRenderer'
import {
  LayoutStore,
  PanosLayout,
} from '../../../infrastructure/LayoutProvider'
import PrintHeader from '../Header/PrintHeader'

interface Props {
  auth: IAuth
  children: JSX.Element
}

const Layout: React.FC<Props> = (props: Props) => {
  const context = useContext(LayoutStore)
  if (context.layout === PanosLayout.default) {
    return (
      <div className={'d-flex'}>
        {props.auth.authenticated && (
          <div className={'flex-shrink-1'}>
            <Sidebar />
          </div>
        )}
        <div
          className={'container-fluid d-flex flex-column'}
          style={
            context.fixMainColumn /** this fixes the broken medication plan. please do not remove**/
              ? {
                  overflowX: 'scroll',
                }
              : null
          }>
          {props.auth.authenticated ? null : (
            <div className={'row'}>
              <div className={'col-lg-12'}>
                <Header auth={props.auth} />
              </div>
            </div>
          )}
          <div className={'mainColumn pt-4'}>{props.children}</div>
        </div>
        <div id="sidebar_right_container" className={'flex-shrink-1'}>
          <DynamicSidebarRenderer />
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <PrintHeader />
        <div id="printLayout">{props.children}</div>
      </div>
    )
  }
}

export default Layout
