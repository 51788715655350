import { Col, Modal, Row } from 'react-bootstrap'
import React, { Dispatch, FormEvent, SetStateAction } from 'react'
import Button from 'react-bootstrap/Button'
import MedicationListEditable from './MedicationListEditable'
import { Type } from 'react-bootstrap-table2-editor'
import {
  getChoicesSingle,
  MedicationTableRow,
} from '../../../utils/MedicationUtils'
import Loader from '../../UI/Spinner/Loader'
import {
  determineInvalidDosages,
  handleDosageError,
} from '../../../utils/MedicationRequestUtils'
import { FieldTypes } from '../../../constants/FieldTypes'
import { MedRequestStandardModalErrors } from '../../../types/MedRequestStandardModalErrors'
import { MedRequestStandardModalValues } from '../../../types/MedRequestStandardValues'
import FormDatepicker from '../../UI/Forms/FormDatepicker/FormDatepicker'
import FormError from '../../UI/Forms/FormError/FormError'

interface Props {
  onSubmit: (values: MedRequestStandardModalValues) => void
  onClose: () => void
  errorMessage: string
  loading: boolean
  values: MedRequestStandardModalValues
  errors: MedRequestStandardModalErrors
  setValues: Dispatch<SetStateAction<MedRequestStandardModalValues>>
  rowStyle: (row: any, rowIndex: any) => Record<string, unknown>
  validate: (
    values: MedRequestStandardModalValues,
    setIndices: Dispatch<SetStateAction<number[]>>
  ) => boolean
  setIndices: Dispatch<SetStateAction<number[]>>
}

interface State {
  errorDetected: boolean
}

/**
 * comment
 */
class MedicationRequestFromStandardMedicationModal extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props)

    this.state = {
      errorDetected: false,
    }
  }

  handleOnSelect = (row: MedicationTableRow, isSelect: boolean): void => {
    let newValues: MedRequestStandardModalValues
    if (isSelect) {
      const medication = this.props.values.medicationListData.find(
        medicationItem => {
          return medicationItem.id === row.id
        }
      )

      if (medication) {
        const packageVariants = getChoicesSingle(medication.packageVariants)
        const dosageChoices = getChoicesSingle(medication.dosageChoices)

        const invalidDosage = determineInvalidDosages(
          packageVariants,
          dosageChoices
        )

        if (invalidDosage && !this.state.errorDetected) {
          handleDosageError(row.businessName, row.standardDosage).then(() =>
            this.setState({
              errorDetected: true,
            })
          )
        }
      }
      newValues = {
        ...this.props.values,
        selected: [...this.props.values.selected, row.medicationId],
      }
    } else {
      newValues = {
        ...this.props.values,
        selected: this.props.values.selected.filter(
          x => x !== row.medicationId
        ),
      }
    }
    this.props.setValues(newValues)
    this.props.validate(newValues, this.props.setIndices)
  }

  handleOnSelectAll = (isSelect: boolean, rows: MedicationTableRow[]): void => {
    const ids = rows.map(r => r.medicationId)
    let newValues: MedRequestStandardModalValues
    if (isSelect) {
      newValues = { ...this.props.values, selected: ids }
    } else {
      newValues = { ...this.props.values, selected: [] }
    }
    this.props.setValues(newValues)
    this.props.validate(newValues, this.props.setIndices)
  }

  setAndValidate = (
    values: MedRequestStandardModalValues,
    setIndices: Dispatch<SetStateAction<number[]>>
  ): void => {
    this.props.setValues(values)
    this.props.validate(values, setIndices)
  }
  handleChangeStartDate = (value: Date): void => {
    const newValues = { ...this.props.values, startDate: value }
    this.setAndValidate(newValues, this.props.setIndices)
  }

  handleChangeEndDate = (value: Date): void => {
    const newValues = { ...this.props.values, endDate: value }
    this.setAndValidate(newValues, this.props.setIndices)
  }

  onSave = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    this.props.setValues({ ...this.props.values, isLoading: true })
    this.props.onSubmit(this.props.values)
  }

  render(): JSX.Element {
    const selectRow = {
      mode: FieldTypes.CHECKBOX,
      clickToSelect: true,
      clickToEdit: true,
      selected: this.props.values.selected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    }

    const configuredColumns = [
      {
        dataField: 'substances',
        text: 'Wirkstoff(e)',
        editable: false,
      },
      {
        dataField: 'businessName',
        text: 'Handelsname(n)',
        editable: false,
      },
      {
        dataField: 'standardDosage',
        text: 'Dosierung',
        editor: {
          type: Type.SELECT,
          getOptions: (
            setOptions: unknown,
            { row }: { row: MedicationTableRow }
          ) => {
            return getChoicesSingle(row.dosageChoices)
          },
        },
      },
      {
        dataField: 'unit',
        text: 'Dosiseinheit',
        editable: false,
      },
      {
        dataField: 'timingChosen',
        text: 'Zeitspalten',
        editor: {
          type: Type.SELECT,
          getOptions: (
            setOptions: unknown,
            { row }: { row: MedicationTableRow }
          ) => {
            return getChoicesSingle(row.timing)
          },
        },
      },
    ]

    return (
      <Modal show={true} onHide={this.props.onClose} size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>Standardmedikamente hinzufügen</Modal.Title>
        </Modal.Header>
        <form onSubmit={this.onSave}>
          <Modal.Body>
            <Row>
              <Col>
                <label htmlFor="startDate">Start der Medikation</label>
                <FormDatepicker
                  name="startDate"
                  value={this.props.values.startDate}
                  error={this.props.errors.startDate}
                  onChange={this.handleChangeStartDate}
                />
              </Col>
              <Col>
                <label htmlFor="endDate">Ende der Medikation</label>
                <FormDatepicker
                  name="endDate"
                  value={this.props.values.endDate}
                  error={this.props.errors.endDate}
                  onChange={this.handleChangeEndDate}
                />
              </Col>
            </Row>
            <>
              {
                <MedicationListEditable
                  columns={configuredColumns}
                  medicationList={this.props.values.medicationListData}
                  errorMessage={this.props.errorMessage}
                  loading={this.props.loading}
                  selectRow={selectRow}
                  rowStyle={this.props.rowStyle}
                />
              }
            </>
            <FormError className="error-messages">
              {this.props.errors.selected}
            </FormError>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose}>
              Schließen
            </Button>
            <Button
              type="submit"
              disabled={this.props.values.isLoading}
              variant="primary">
              Ausgewählte hinzufügen{' '}
              {this.props.values.isLoading && <Loader msg={'Lade props...'} />}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default MedicationRequestFromStandardMedicationModal
