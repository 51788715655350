import React, { useContext, useState } from 'react'
import { Badge, Collapse } from 'react-bootstrap'
import BigIcon from '../BigIcon/BigIcon'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import SidebarEntry, { SidebarButtonData } from './SidebarEntry'
import { NotificationTypes } from '../../../config/Constants'
import { AuthStore } from '../../../infrastructure/AuthProvider'

interface SidebarDropdownProps {
  active?: boolean
  sidebarItem: SidebarButtonData
  notifications?: Record<NotificationTypes, number>
}

const SidebarDropdown: React.FC<SidebarDropdownProps> = ({
  active,
  sidebarItem,
  notifications,
}: SidebarDropdownProps): JSX.Element => {
  const [childrenOpen, setChildrenOpen] = useState(false)
  const context = useContext(AuthStore)
  let badge = null
  if (
    sidebarItem.notification &&
    notifications &&
    notifications[sidebarItem.notification]
  ) {
    badge = notifications[sidebarItem.notification]
  }
  return (
    <li
      className={
        (active ? 'active' : '') +
        (sidebarItem.colorTheme !== undefined
          ? ' hoverable theme-' + sidebarItem.colorTheme
          : '')
      }>
      <button
        className={'btn w-100 d-flex'}
        onClick={() => setChildrenOpen(!childrenOpen)}>
        <div className={'list-chevron'}>
          <MaterialIcon
            icon={'chevron_right'}
            className={childrenOpen ? 'open' : ''}
          />
        </div>
        <div className={'flex-grow-1'}>
          <BigIcon icon={sidebarItem.icon}>
            <span className={'description'}>{sidebarItem.description}</span>
          </BigIcon>
        </div>
        {badge ? <Badge className={'align-self-center'}>{badge}</Badge> : null}
      </button>
      <Collapse in={childrenOpen}>
        <ul className={'list-unstyled'}>
          {sidebarItem.children.map(entry => {
            if (!context.handlers.hasPermission(entry.permissision)) return null
            return (
              <SidebarEntry
                sidebarItem={entry}
                notifications={notifications}
                key={'menu-button-' + entry.description}
              />
            )
          })}
        </ul>
      </Collapse>
    </li>
  )
}

export default React.memo(SidebarDropdown)
