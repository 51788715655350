import React, { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import BootstrapTable from 'react-bootstrap-table-next'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import MaterialIcon from '../../UI/MaterialIcon/MaterialIcon'
import {
  formatDate,
  formatTime,
  parseUnknownToDate,
} from '../../../utils/Utils'
import { CarePlanStatus } from '../../../constants/CarePlanStatus'
import { DatePickerField } from '../../Formik/DatePickerField'
import Loader from '../../UI/Spinner/Loader'
import ComposeCarePlanModal from './ComposeCarePlanModal'
import { DeletePlanModal } from './DeletePlanModal'
import { APIResult } from '../../../constants/APIResult'
import { NotificationManager } from 'react-notifications'
import CarePlanService from '../../../services/CarePlanService'
import { AuthStore } from '../../../infrastructure/AuthProvider'
import { UserPermission } from '../../../constants/UserPermission'

const statusIcons: Record<string, string> = {
  [CarePlanStatus.active]: 'check_box',
  [CarePlanStatus.on_hold]: null,
}

interface TemplateLoadModalProps {
  patientId: string
  showModal: boolean
  handleClose: () => void
  medPlanTemplates: any[]
  selectPlanAction: (
    selectedPlan: unknown,
    date: Date,
    deleteOtherMedRequests: boolean
  ) => Promise<unknown>
  deletePlanAction: (selectedPlan: unknown) => void
  informationFormatter: (medRequest: any) => void
  informationField?: string

  displayStatusColumn: boolean
  displaySavedVersionColumn: boolean

  enableEditPlans: boolean
  enableActivatePlans?: boolean
  setShowSaveModal: (value: boolean) => void
  reason: string
}

const TemplateLoadModal: React.FC<TemplateLoadModalProps> = (
  props: TemplateLoadModalProps
) => {
  const [showActivateModal, setShowActivateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(undefined)
  const [planToEdit, setShowCarePlanComposeModal] = useState(null)

  const context = useContext(AuthStore)
  const { hasPermission } = context.handlers

  const editCarePlanFromFromValues = async composedFormValues => {
    return new CarePlanService(props.reason)
      .updateCarePlansMedicationRequests(
        composedFormValues.plan.id,
        composedFormValues.plan.medicationRequests
      )
      .then(response => {
        if (response.Result === APIResult.SUCCESS) {
          props.handleClose()
        } else
          NotificationManager.error(
            'Fehler beim Erstellen eines neuen Medikationsplans'
          )
      })
  }

  function statusFormatter(cell, row) {
    return statusIcons[cell] ? <MaterialIcon icon={statusIcons[cell]} /> : null
  }

  function savedVersionFormatter(cell, row) {
    const time = parseUnknownToDate(row.savedTime)
    if (row.savedVersion)
      return (
        <p>
          gespeichert am {formatDate(time)} {formatTime(time)}
        </p>
      )
    else return null
  }

  function actionFormatter(cell, row) {
    if (
      row.status === CarePlanStatus.active &&
      row.resourceType !== 'PlanDefinition'
    ) {
      console.log(row)
      return null
    }

    return (
      <div>
        {props.enableActivatePlans ||
        hasPermission(UserPermission.PATIENT_MEDICATION_CONFIRM) ? (
          <Button
            className="ml-2"
            onClick={() => {
              setSelectedPlan(row)
              setShowActivateModal(true)
            }}
            size={'sm'}
            title="Verwenden">
            <MaterialIcon icon="done" verticalAlignment="middle" />
          </Button>
        ) : null}
        <Button
          className="ml-2"
          onClick={() => {
            setSelectedPlan(row)
            setShowDeleteModal(true)
          }}
          size={'sm'}
          title="Löschen">
          <MaterialIcon icon="delete" verticalAlignment="middle" />
        </Button>
        {props.enableEditPlans ? (
          <Button
            className="ml-2"
            onClick={() => {
              setSelectedPlan(row)
              setShowCarePlanComposeModal(row)
            }}
            size={'sm'}
            title="Bearbeiten">
            <MaterialIcon icon="edit" verticalAlignment="middle" />
          </Button>
        ) : null}
      </div>
    )
  }

  const statusColumn = props.displayStatusColumn
    ? [
        {
          dataField: 'status',
          text: 'Status',
          formatter: statusFormatter,
        },
      ]
    : []

  const savedVersionColumn = props.displaySavedVersionColumn
    ? [
        {
          dataField: 'savedTime',
          text: 'Zuletzt gespeichert',
          formatter: savedVersionFormatter,
        },
      ]
    : []

  const columns = [
    ...statusColumn,
    {
      dataField: 'title',
      text: 'Vorlagenbezeichnung',
    },
    {
      dataField: props.informationField ?? 'action',
      text: 'Informationen',
      formatter: props.informationFormatter,
    },
    ...savedVersionColumn,
    {
      dataField: 'id',
      text: 'Aktionen',
      formatter: actionFormatter,
    },
  ]

  return (
    <div>
      <Modal show={props.showModal} onHide={props.handleClose} size={'xl'}>
        <Modal.Header>
          <Modal.Title>Vorlagenübersicht</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BootstrapTable
            keyField="ID"
            data={props.medPlanTemplates?.map((it, i) => {
              return { ...it, ID: i }
            })}
            columns={columns}
            noDataIndication="Es gibt noch keine Vorlagen zur Medikation."
            striped
            hover
            condensed
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.handleClose()}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>

      <DeletePlanModal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false)
        }}
        onClickNotDelete={() => setShowDeleteModal(false)}
        onClickDelete={() => {
          props.deletePlanAction(selectedPlan)
          setShowDeleteModal(false)
        }}
      />

      <Modal
        show={showActivateModal}
        onHide={() => {
          setShowActivateModal(false)
        }}>
        <Modal.Header>
          <Modal.Title> Start wählen </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            startDate: new Date(),
            deleteOtherMedRequests: true,
          }}
          validate={values => {
            const errors: Record<string, any> = {}
            if (!values.startDate) {
              errors.startDate = 'Erforderlich'
            }
            return errors
          }}
          onSubmit={values => {
            props
              .selectPlanAction(
                selectedPlan,
                values.startDate,
                values.deleteOtherMedRequests
              )
              .then(() => {
                setShowActivateModal(false)
                props.handleClose()
              })
          }}>
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <Modal.Body>
                <DatePickerField
                  name="startDate"
                  className={`form-control ${
                    touched.startDate && errors.startDate ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name="startDate" component="div" />
                <hr />
                <div className="form-group form-check">
                  <Field
                    type="checkbox"
                    name="deleteOtherMedRequests"
                    className={
                      'form-check-input ' +
                      (errors.deleteOtherMedRequests &&
                      touched.deleteOtherMedRequests
                        ? ' is-invalid'
                        : '')
                    }
                  />
                  <label
                    htmlFor="deleteOtherMedRequests"
                    className="form-check-label">
                    Alle zuvor verschriebenen Medikamente löschen?
                  </label>
                  <ErrorMessage name="deleteOtherMedRequests" component="div" />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowActivateModal(false)
                    props.handleClose()
                    setShowCarePlanComposeModal(false)
                  }}>
                  Abbrechen
                </Button>
                <Button type="submit" disabled={isSubmitting}>
                  Speichern {isSubmitting && <Loader msg={'Lade...'} />}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      {planToEdit ? (
        <ComposeCarePlanModal
          patientId={props.patientId}
          carePlan={planToEdit}
          onHide={() => {
            setShowActivateModal(false)
            setShowCarePlanComposeModal(false)
            props.handleClose()
          }}
          checkCarePlanTitle={x => true}
          onSubmit={editCarePlanFromFromValues}
        />
      ) : null}
    </div>
  )
}

export default TemplateLoadModal
