import axios, { AxiosResponse } from 'axios'
import {
  API_FORWARDING_CREATE,
  API_FORWARDING_GET,
  API_FORWARDING_GET_RESOURCES_BY_FILTER,
  API_FORWARDING_UPDATE,
  API_MEDICATION_REQUESTS,
} from '../config/Paths'
import ApiServiceBase from './ApiServiceBase'
import { ApiResponse } from '../types/ApiResponse'
import { ApiError } from '../types/ApiError'
import { MedicationRequestStatus } from '../constants/MedicationRequestStatus'
import { ExtensionURL } from '../constants/ExtensionURL'
import { APIResult } from '../constants/APIResult'
import { getToken } from './Keycloak'

export function getMedicationRequestByPatient(
  patientId: string
): Promise<AxiosResponse<any>> {
  const filterParameter =
    'status=active,on-hold,stopped&subject=Patient/' + patientId

  const url = new URL(API_FORWARDING_GET_RESOURCES_BY_FILTER)
  url.searchParams.append('resourceType', 'MedicationRequest')
  url.searchParams.append('filterParameter', filterParameter)
  return axios.get(url.toString(), {
    headers: {
      Authorization: `Bearer ` + getToken(),
    },
  })
}

export function getMedicationRequestsByCarePlan(
  carePlanId: string
): Promise<AxiosResponse<any>> {
  const filterParameter =
    'status=active,on-hold,stopped,completed,draft&_has:CarePlan:activity-reference:_id=' +
    carePlanId

  const url = new URL(API_FORWARDING_GET_RESOURCES_BY_FILTER)
  url.searchParams.append('resourceType', 'MedicationRequest')
  url.searchParams.append('filterParameter', filterParameter)
  return axios.get(url.toString(), {
    headers: {
      Authorization: `Bearer ` + getToken(),
    },
  })
}

export function updateMedicationRequestById(
  medicationRequest: MedicationRequest,
  id: id
): Promise<AxiosResponse<MedicationRequest>> {
  const url = new URL(API_FORWARDING_UPDATE + '/' + id)

  return axios.put(url.toString(), medicationRequest, {
    headers: { Authorization: `Bearer ` + getToken() },
  })
}

export function getMedicationRequestById(id: id): Promise<AxiosResponse<any>> {
  const url = new URL(API_FORWARDING_GET + '?resourceId=' + id)

  return axios.get(url.toString(), {
    headers: { Authorization: `Bearer ` + getToken() },
  })
}

export function createMedicationRequest(
  medicationRequest: Record<string, any>
): Promise<AxiosResponse<any>> {
  const url = new URL(API_FORWARDING_CREATE)
  return axios.post(url.toString(), medicationRequest, {
    headers: { Authorization: `Bearer ` + getToken() },
  })
}

/**
 *
 * @param {id} patientId
 * @param {id} standardMedicationId
 * @param {string} requestLabel
 * @param {Array.<Dosage>} dosageInstruction
 * @param {Array.<Identifier>} identifiers
 * @param {Medication} medication
 * @param {string} requestNotes
 * @param {string} warningNotice
 * @param {boolean} autIdem
 * @param {string} historyReason
 * @param {string} arrangementReason
 * @return {MedicationRequest}
 */
export function createMedicationRequestObject(
  patientId: id,
  requestLabel: string,
  dosageInstruction: Array<Dosage>,
  identifiers: Array<Identifier>,
  medication: Medication,
  standardMedicationId: id,
  requestNotes = '',
  warningNotice = '',
  autIdem = true,
  historyReason = '',
  arrangementReason = ''
): MedicationRequest {
  return {
    resourceType: 'MedicationRequest',
    status: MedicationRequestStatus.draft,
    intent: 'order',
    identifier: [...identifiers],
    note: [{ text: requestLabel }, { text: warningNotice }],
    extension: [
      {
        url: ExtensionURL.medicationRequestReason,
        valueString: requestNotes,
      },
      {
        url: ExtensionURL.medicationRequestHistoryReason,
        valueString: historyReason,
      },
      {
        url: ExtensionURL.medicationRequestArrangementReason,
        valueString: arrangementReason,
      },
    ],
    medicationReference: {
      reference: '#' + medication.id,
      display: medication.code.text,
    },
    subject: {
      reference: 'Patient/' + patientId,
    },
    dosageInstruction: [...dosageInstruction],
    contained: [medication],
    supportingInformation: [
      {
        reference: 'Medication/' + standardMedicationId,
      },
    ],
    substitution: {
      allowedBoolean: autIdem,
    },
  }
}

class MedicationRequestService extends ApiServiceBase {
  getMedicationRequest(
    medicationRequestId: string
  ): Promise<ApiResponse<MedicationRequest | ApiError>> {
    return this.get<null, MedicationRequest>(
      new URL(API_MEDICATION_REQUESTS + `/${medicationRequestId}`)
    )
  }

  postMedicationRequest(
    medicationRequest: MedicationRequest
  ): Promise<ApiResponse<MedicationRequest | ApiError>> {
    return this.post<MedicationRequest, MedicationRequest>(
      new URL(API_MEDICATION_REQUESTS),
      null,
      medicationRequest
    )
  }

  putMedicationRequest(
    medicationRequest: MedicationRequest
  ): Promise<ApiResponse<MedicationRequest | ApiError>> {
    return this.put<MedicationRequest>(
      new URL(API_MEDICATION_REQUESTS + `/${medicationRequest.id}`),
      medicationRequest
    )
  }

  deleteMedicationRequest(
    medicationRequest: MedicationRequest
  ): Promise<ApiResponse<string | ApiError>> {
    return this.delete<MedicationRequest>(
      new URL(API_MEDICATION_REQUESTS + `/${medicationRequest.id}`)
    )
  }

  updateStatus = (
    medicationRequestsIds: string[],
    status: MedicationRequestStatus[]
  ): Promise<ApiResponse<MedicationRequest | ApiError>> => {
    const body = [medicationRequestsIds, status]
    return this.post<(string | number)[][], MedicationRequest>(
      new URL(API_MEDICATION_REQUESTS + '/updateMedicationRequestsStatus'),
      null,
      body
    )
  }

  getMedicationRequestHistories = async (
    medicationRequestIds: string[]
  ): Promise<ApiResponse<MedicationRequest[][] | ApiError>> => {
    const result = await this.post<string[], string[][]>(
      new URL(API_MEDICATION_REQUESTS + '/history'),
      null,
      medicationRequestIds
    )
    if (result.Result === APIResult.SUCCESS) {
      return {
        Response: (result.Response as string[][]).map(
          medicationRequestVersions =>
            medicationRequestVersions.map(
              it => JSON.parse(it) as MedicationRequest
            )
        ),
        Result: result.Result,
      } as ApiResponse<MedicationRequest[][] | ApiError>
    }
    return {
      Response: null,
      Result: result.Result,
    }
  }
}

export default MedicationRequestService
