import { MedicationRequestStatus } from '../constants/MedicationRequestStatus'
import _, { cloneDeep } from 'lodash'
import moment, { Moment } from 'moment'
import {
  findValueFromIdentifiers,
  getIdFromReference,
  hasStatus,
} from '../services/FhirResourceService'
import { MED_URN } from '../constants/MedicationURN'
import { createMedicationRequestObject } from '../services/MedicationRequestService'
import { ExtensionURL } from '../constants/ExtensionURL'
import roundTo from 'round-to'
import { getMedicationById } from '../services/MedicationService'
import { addResourceTypeIfNot, getExtensionValueByUrl } from './Utils'
import {
  getPackageIds,
  getPackageOptions,
} from '../components/Formik/TimeIntervalSelector'
import { OptionChoices } from './MedicationUtils'

export interface MedicationScheduleObject {
  groups: MedicationScheduleGroup[]
  items: Record<string, unknown>[]
  requests: MedicationRequest[]
}

/**
 *
 * @param {MedicationScheduleObject} data
 * @returns {MedicationScheduleObject}
 */
export function sortAndMergeGroups(
  data: MedicationScheduleObject
): MedicationScheduleObject {
  let groups = []
  const groupedGroups = _.groupBy(data.groups, 'groupIdKey')
  const items = [...data.items]
  const groupedItems = _.groupBy(data.items, 'group')
  for (const [medId, currentGroups] of Object.entries(groupedGroups)) {
    if (currentGroups.length === 1) {
      groups.push({ ...currentGroups[0], root: true })
    } else {
      const exampleGroup = currentGroups[0]
      let startTime = null
      let endTime = null
      for (const currentGroup of currentGroups) {
        if (
          startTime === null ||
          moment(startTime).isAfter(currentGroup.startTime)
        ) {
          startTime = currentGroup.startTime
        }
        if (
          endTime === null ||
          moment(endTime).isBefore(currentGroup.endTime)
        ) {
          endTime = currentGroup.endTime
        }
      }
      groups.push({
        id: medId + ' - header',
        title: exampleGroup.medName,
        type: exampleGroup.type,
        typeOrder: exampleGroup.typeOrder,
        shape: exampleGroup.shape,
        medicationRequest: exampleGroup.medicationRequest,
        groupIdKey: medId,
        standardMedicationId: exampleGroup.standardMedicationId,
        medName: exampleGroup.medName,
        startTime: startTime,
        endTime: endTime,
        root: true,
        isGroupHeader: true,
      })

      for (const currentGroup of currentGroups) {
        currentGroup.root = false
        groups.push(currentGroup)
        if (groupedItems[currentGroup.id]) {
          const currentItems = [...groupedItems[currentGroup.id]]
          for (const currentItem of currentItems) {
            items.push({
              ...currentItem,
              id: medId + '-' + currentItem.id,
              group: medId,
            })
          }
        }
      }
    }
  }
  groups = _.orderBy(
    groups,
    [
      'typeOrder',
      'medName',
      'groupIdKey',
      'root',
      'title',
      'medicationRequest.priorPrescription.reference',
      'endTime',
    ],
    ['asc', 'asc', 'asc', 'desc', 'asc', 'asc', 'desc']
  )

  return {
    groups,
    items,
    requests: data.requests,
  }
}

export const completedItemExists = (
  step: number,
  start: Moment,
  end: Moment,
  times: Array<string>
): boolean => {
  const initialStart = cloneDeep(start)
  const initialEnd = cloneDeep(end)
  const intervalDays = initialEnd
    .startOf('day')
    .diff(initialStart.startOf('day'), 'days')
  let itemExists = true
  if (times) {
    itemExists = false
    if (start.isValid() && end.isValid() && start.isBefore(end)) {
      let days = 0
      while (start.isBefore(initialEnd.endOf('day'))) {
        if (itemExists) break
        for (const time of times) {
          const hourAndMinute = time.split(':')
          const medTime = moment(start)
          medTime.set('hour', parseInt(hourAndMinute[0]))
          medTime.set('minute', parseInt(hourAndMinute[1]))
          if (
            (days > 0 || medTime.isSameOrAfter(start)) &&
            (days < intervalDays || medTime.isBefore(end))
          ) {
            itemExists = true
            break
          }
        }
        start.add(step, 'day')
        days = days + step
      }
    }
  }
  return itemExists
}

/**
 *
 * @param {MedicationScheduleFormikValues} values
 * @param {Identifier[]} identifierList
 * @param {string} patientId
 * @param {?MedicationRequest} inputMedRequest
 * @returns {MedicationRequest}
 */
export const mapMedRequest = async (
  values: MedicationScheduleFormikValues,
  identifierList: Identifier[],
  patientId: string,
  inputMedRequest = null
): Promise<MedicationRequest> => {
  const medicationTypeId = addResourceTypeIfNot(
    values.medicationId.toString(),
    'Medication'
  )
  const medRequest = await getMedicationById(medicationTypeId).then(
    response => {
      if (response.data?.id) {
        const medication = response.data
        const historyReason = values.historyReason
          ? values.historyReason.value
          : ''
        const arrangementReason = values.arrangementReason
          ? values.arrangementReason.value
          : ''
        return createMedicationRequestObject(
          patientId,
          values.label,
          [],
          identifierList,
          medication,
          values.standardMedicationId.split('|')[0],
          values.note,
          values.warning,
          values.autIdem,
          historyReason,
          arrangementReason
        )
      } else {
        console.error('No existing medicationrequest received while saving')
        return null
      }
    }
  )
  if (values.isEditing) {
    const oldMedRequest = cloneDeep(inputMedRequest)
    const splitMoment = moment(values.timeIntervals[0].startDate).startOf(
      'minute'
    )
    const indices = []
    let isIncluded = false
    oldMedRequest.dosageInstruction.forEach((it, arrayIndex) => {
      const start = moment(it.timing.repeat.boundsPeriod.start)
      const end = moment(it.timing.repeat.boundsPeriod.end)
      /**
       * determine if split moment is in dosage Instruction object time interval
       */
      if (
        splitMoment.isBetween(start, end, undefined, '()') &&
        start.diff(end, 'minute') !== 0
      ) {
        isIncluded = true
        /**
         * determine if dosage Instruction Object contains completed item
         */
        if (
          completedItemExists(
            it.timing.repeat.period,
            start,
            splitMoment,
            it.timing.repeat.timeOfDay
          )
        ) {
          indices.push(arrayIndex)
        }
      } else {
        indices.length = 0
      }
    })
    /**
     * Cut dosage Instruction array to proper length
     * abandoning all entries which are situated beyond startDate of
     * first values.timeInterval object
     */
    if (indices.length > 0) {
      oldMedRequest.dosageInstruction.length = indices[indices.length - 1] + 1
      indices.forEach(includingIndex => {
        oldMedRequest.dosageInstruction[
          includingIndex
        ].timing.repeat.boundsPeriod.end = moment(
          values.timeIntervals[0].startDate
        )
          .startOf('minute')
          .toISOString(true)
      })
    } else if (indices.length === 0 && isIncluded) {
      oldMedRequest.dosageInstruction.length = 0
    }

    delete oldMedRequest.meta
    medRequest.dosageInstruction = oldMedRequest.dosageInstruction
    medRequest.id = oldMedRequest.id
  }

  const newDosageInstruction = values.timeIntervals.map(interval => {
    if (interval.timingAndDosage.selectedTiming?.value === 'prn') {
      return interval.timingAndDosage.dosages.map(dosageObject => ({
        timing: {
          repeat: {
            boundsPeriod: {
              start: moment(interval.startDate)
                .startOf('minute')
                .toISOString(true),
              end: moment(interval.endDate).startOf('minute').toISOString(true),
            },
            extension: [
              {
                url: ExtensionURL.medicationRequestMinimalDistance,
                valueInteger: dosageObject.distance,
              },
              {
                url: ExtensionURL.medicationRequestMinimalDistanceUnit,
                valueCode: dosageObject.distanceUnit.value,
              },
            ],
            frequencyMax: dosageObject.frequencyMax,
            period: dosageObject.period,
            periodUnit: dosageObject.periodUnit.value,
          },
        },
        text:
          dosageObject.dose.value.replaceAll(',', '.') +
          ';' +
          dosageObject.pieces,
        patientInstruction: interval.timingAndDosage.patientInstructions,
      }))
    } else {
      return interval.timingAndDosage.dosages.map(dosageObject => ({
        timing: {
          repeat: {
            boundsPeriod: {
              start: moment(interval.startDate)
                .startOf('minute')
                .toISOString(true),
              end: moment(interval.endDate).startOf('minute').toISOString(true),
            },
            frequency: dosageObject.times.length,
            period: dosageObject.period,
            periodUnit: 'd',
            timeOfDay:
              /**
               * @type {Array.<time>}
               */
              dosageObject.times
                .sort((a, b) => a - b)
                .map(time => moment(time).format('H:mm')),
          },
        },
        text:
          dosageObject.dose.value.replaceAll(',', '.') +
          ';' +
          dosageObject.pieces,
      }))
    }
  })
  const newDosageInstructionFlat = newDosageInstruction.flat()

  medRequest.dosageInstruction = newDosageInstructionFlat

  /**
   * assign previous active version as prior prescription
   */
  if (
    inputMedRequest !== null &&
    inputMedRequest.status === MedicationRequestStatus.active
  ) {
    medRequest.priorPrescription = {
      reference: 'MedicationRequest/' + inputMedRequest.id,
    }
  } else if (inputMedRequest?.priorPrescription?.reference) {
    medRequest.priorPrescription = {
      reference: inputMedRequest?.priorPrescription?.reference,
    }
  }
  return medRequest
}

/**
 *
 * @param medRequests {Array.<MedicationRequest>}
 * @returns {MedicationScheduleObject}
 */
export const mapRequestsToSchedule = (
  medRequests: MedicationRequest[]
): MedicationScheduleObject => {
  const groups = []
  const items = []
  const requests = []
  for (const entry of medRequests) {
    addGroupAndItems(entry, groups, items, requests)
  }
  return sortAndMergeGroups({ groups, items, requests })
}

/**
 * @param {string} change
 * @param {Array} items
 * @param {Repeat} repeat
 * @param {string} doseQuantity
 * @param {id} requestId
 * @param {string} shape
 */
export function mapItems(
  change: string,
  items: MedicationScheduleItem[],
  repeat: Repeat,
  doseQuantity: string,
  requestId: id,
  shape: string
): MedicationScheduleItem {
  if (!repeat.boundsPeriod) {
    return
  }
  const start = moment(repeat.boundsPeriod.start)
  const end = moment(repeat.boundsPeriod.end)
  const initialStart = cloneDeep(start)
  const initialEnd = cloneDeep(end)
  const intervalDays = initialEnd
    .startOf('day')
    .diff(initialStart.startOf('day'), 'days')

  if (repeat.period && repeat.period % 1 === 0 && repeat.period >= 1) {
    if (repeat.timeOfDay) {
      if (start.isValid() && end.isValid() && start.isBefore(end)) {
        let days = 0
        let dosageItemIndex = 1
        let bgColor
        let borderColor
        const addedItems = 0
        while (start.isBefore(initialEnd.endOf('day'))) {
          for (const time of repeat.timeOfDay) {
            /**
             * color only first 24 hours of new dosage subscribed
             * if change exists
             */
            if (dosageItemIndex <= repeat.timeOfDay.length) {
              switch (change) {
                case '>':
                  bgColor = 'rgb(169, 51, 22, 0.5)'
                  borderColor = 'rgb(169, 51, 22)'
                  break
                case '<':
                  bgColor = 'rgb(100, 140, 100, 0.5)'
                  borderColor = 'rgb(100, 140, 100)'
                  break
                default:
                  bgColor = 'lightgray'
                  borderColor = 'black'
                  break
              }
            } else {
              bgColor = 'lightgray'
              borderColor = 'black'
            }
            const hourAndMinute = time.split(':')
            const medTime = moment(start)
            medTime.set('hour', parseInt(hourAndMinute[0]))
            medTime.set('minute', parseInt(hourAndMinute[1]))
            if (
              (days > 0 || medTime.isSameOrAfter(start)) &&
              (days < intervalDays || medTime.isBefore(end))
            ) {
              if (addedItems < 1000)
                items.push({
                  id: requestId + '-' + medTime.valueOf(),
                  group: requestId,
                  title: doseQuantity,
                  shape: shape,
                  bgColor: bgColor,
                  borderColor: borderColor,
                  start_time: medTime.valueOf(),
                  end_time: medTime.add(1, 'hour').valueOf(),
                })
              dosageItemIndex++
            }
          }
          start.add(repeat.period, 'day')
          days = days + repeat.period
        }
      }
    }
  } else {
    throw Error('Processed period is not positive integer!')
  }
  return items
}

/**
 * Maps a MedicationRequest to a group and its items.
 * If a group, item or request in the Schedule have the same id (or in case of items 'group') as the MedicationRequest
 * then those objects will be removed.
 *
 * @param {MedicationScheduleObject} schedule
 * @param {MedicationRequest[]} medicationRequests
 * @returns {MedicationScheduleObject} highlighted Schedule
 */
export function addMedicationRequestToSchedule(
  schedule: MedicationScheduleObject,
  medicationRequests: MedicationRequest[]
): MedicationScheduleObject {
  let items = cloneDeep(schedule.items)
  let groups = cloneDeep(schedule.groups)
  let requests = cloneDeep(schedule.requests)
  for (const medicationRequest of medicationRequests) {
    const groupId = medicationRequest.id
    items = [
      ...items.filter(
        item =>
          item.group !== groupId &&
          !schedule.groups
            .map(it => it.groupIdKey)
            .includes(item.group.toString())
      ),
    ]
    groups = [
      ...groups.filter(group => group.id !== groupId && !group.isGroupHeader),
    ]
    requests = [
      ...requests.filter(request => request.id !== medicationRequest.id),
    ]
    addGroupAndItems(medicationRequest, groups, items, requests)
  }
  return sortAndMergeGroups({ groups, items, requests })
}

/**
 * Returns order position of MedicationRequest by it's type
 *
 * @param type medication type
 * @returns {number} order position
 */
export function getTypeOrder(type: string): number {
  switch (type) {
    case 'motorisch':
      return 10
    case 'nicht-motorisch':
      return 20
    case 'allgemein/anderes':
      return 50
    default:
      return 100
  }
}

/**
 *
 * @param medicationRequest
 * @returns MedicationScheduleGroup
 */
export function getMedicationScheduleGroup(
  medicationRequest: MedicationRequest
): MedicationScheduleGroup {
  const medicationType = findValueFromIdentifiers(
    medicationRequest.identifier,
    MED_URN + 'Art'
  )
  const typeOrder = getTypeOrder(medicationType)
  const strengthArray = medicationRequest.contained[0].ingredient.map(
    ingredient =>
      ingredient.strength.numerator.value + ingredient.strength.numerator.unit
  )
  let strengthString = ' '
  strengthArray.forEach((it, index) => {
    if (index < strengthArray.length - 1) {
      strengthString += it + '/'
    } else {
      strengthString += it
    }
  })

  return {
    id: medicationRequest.id,
    title: medicationRequest.medicationReference.display + strengthString,
    type: medicationType ? medicationType : '',
    typeOrder: typeOrder,
    shape: findValueFromIdentifiers(
      medicationRequest.identifier,
      MED_URN + 'Form'
    ),
    medicationRequest: medicationRequest,
    groupIdKey: getIdFromReference(medicationRequest.medicationReference),
    medName: medicationRequest.medicationReference.display,
    standardMedicationId: medicationRequest.supportingInformation
      ? medicationRequest.supportingInformation[0].reference.split('/')[1]
      : '',
    startTime: calculateStartTime(medicationRequest).valueOf(),
    endTime: calculateEndTime(medicationRequest).valueOf(),
    isGroupHeader: null,
  }
}

/**
 *
 * @param {MedicationRequest} medicationRequest
 * @param groups {array} schedule.groups
 * @param items {array} schedule.items
 * @param requests {array} schedule.requests
 */
export function addGroupAndItems(
  medicationRequest: MedicationRequest,
  groups: Array<MedicationScheduleGroup>,
  items: Array<MedicationScheduleItem>,
  requests: Array<MedicationRequest>
): void {
  if (
    hasStatus(medicationRequest.status, [
      MedicationRequestStatus.active,
      MedicationRequestStatus.on_hold,
      //      MedicationRequestStatus.stopped,
      MedicationRequestStatus.completed,
      MedicationRequestStatus.draft,
    ])
  ) {
    if (medicationRequest.id) {
      requests.push(medicationRequest)
      groups.push(getMedicationScheduleGroup(medicationRequest))
    }

    const change = []
    let previousSumDailyDosage = null
    let sumDailyDosage = 0
    let changeIndex = 0

    /**
     * Assigns change indicators for n - 1 of n dosage periods.
     * Each dosage period k contains i(k) dosage Instruction objects.
     * These have to be looped over by the criterion that their boundsPeriods coincide.
     */
    medicationRequest.dosageInstruction.forEach((it, index) => {
      if (index > 0) {
        if (
          it.timing.repeat.boundsPeriod.start !==
          medicationRequest.dosageInstruction[index - 1].timing.repeat
            .boundsPeriod.start
        ) {
          if (previousSumDailyDosage !== null) {
            for (let i = 0; i < index - changeIndex; i++) {
              if (sumDailyDosage > previousSumDailyDosage) {
                change.push('>')
              } else if (sumDailyDosage < previousSumDailyDosage) {
                change.push('<')
              } else {
                change.push('')
              }
            }
          } else {
            for (let i = 0; i < index - 1; i++) {
              change.push('')
            }
          }
          changeIndex = index
          previousSumDailyDosage = sumDailyDosage
          sumDailyDosage = 0
        }
      } else {
        change.push('')
      }
      /**
       * add only to sum if not medication on demand
       */
      if (it.timing.repeat.timeOfDay) {
        it.timing.repeat.timeOfDay.forEach(() => {
          sumDailyDosage += Number(it.text.split(';')[0].split('/')[0])
        })
      }
    })
    /**
     * Cares for change elements of last dosage period n.
     * Considering all i(n) dosage Instructions objects.
     */
    if (previousSumDailyDosage !== null) {
      for (
        let i = 0;
        i < medicationRequest.dosageInstruction.length - changeIndex;
        i++
      ) {
        if (sumDailyDosage > previousSumDailyDosage) {
          change.push('>')
        } else if (sumDailyDosage < previousSumDailyDosage) {
          change.push('<')
        } else {
          change.push('')
        }
      }
    }

    medicationRequest.dosageInstruction.forEach((dosage, index) => {
      if (medicationRequest.status === MedicationRequestStatus.on_hold) {
        mapItems(
          change[index],
          items,
          dosage.timing.repeat,
          'P',
          medicationRequest.id,
          findValueFromIdentifiers(
            medicationRequest.identifier,
            MED_URN + 'Form'
          )
        )
      } else if (medicationRequest.status === MedicationRequestStatus.stopped) {
        mapItems(
          change[index],
          items,
          dosage.timing.repeat,
          'A',
          medicationRequest.id,
          findValueFromIdentifiers(
            medicationRequest.identifier,
            MED_URN + 'Form'
          )
        )
      } else {
        mapItems(
          change[index],
          items,
          dosage.timing.repeat,
          dosage.text.split(';')[0],
          medicationRequest.id,
          findValueFromIdentifiers(
            medicationRequest.identifier,
            MED_URN + 'Form'
          )
        )
      }
    })
  }
}

/**
 *
 * @param {MedicationRequest} medicationRequest
 * @returns {Date} time of first intake of a medicine requested in a MedicationRequest
 */
export function calculateStartTime(
  medicationRequest: MedicationRequest
): moment.Moment {
  return moment(
    medicationRequest.dosageInstruction[0].timing.repeat.boundsPeriod.start
  ).startOf('day')
}

/**
 *
 * @param {MedicationRequest} medicationRequest
 * @returns {Date} time of last intake of a medicine requested in a MedicationRequest
 */
export function calculateEndTime(
  medicationRequest: MedicationRequest
): moment.Moment {
  const length = medicationRequest.dosageInstruction.length
  return moment(
    medicationRequest.dosageInstruction[length - 1].timing.repeat.boundsPeriod
      .end
  ).endOf('day')
}

/**
 * Convert milliseconds to time string (hh:mm:ss:mss).
 *
 *
 * @return String
 * @param {number} ms
 */
export const toHourAndSecond = (ms: number): string => {
  const hours: string = new Date(ms).getHours().toString()
  const minutes: string = new Date(ms).getMinutes().toString()

  if (minutes !== '0') {
    return hours + ':' + minutes
  }
  return hours
}

/**
 *
 * @param timeValues array of milliseconds to represent the hour of the day ()
 * @returns A string with the concatenated hours separeted by "-". E.g.: 7-12-19
 */
function getTimingLabel(timeValues) {
  const sortedTimeValues = timeValues.sort()

  return sortedTimeValues?.map(time => toHourAndSecond(time)).join('-')
}

/**
 *
 * @param dosageInstructions
 * @param packageVariant
 * @returns {{timing; selectedTiming; dosages; patientInstructions}}
 */
export function remapDosageInstruction(
  dosageInstructions: Array<Dosage>,
  packageVariant: string
): { timing; selectedTiming; dosages; patientInstructions } {
  const timing = []
  const dosages = []
  let selectedTiming = null
  let frequencyMax = null
  let period = null
  let distance = null
  let periodUnit = null
  let periodUnitLabel = null
  let distanceUnit = null
  let distanceUnitLabel = null
  let volume = null

  const getUnitLabel = unit => {
    switch (unit) {
      case 'wk':
        return 'Woche'
      case 'd':
        return 'Tag'
      case 'h':
        return 'Stunde'
      default:
        return ''
    }
  }

  for (const dosageInstruction of dosageInstructions) {
    const times = []
    const dosage = dosageInstruction.text.split(';')[0]
    const pieces = dosageInstruction.text.split(';')[1]

    if (dosageInstruction.timing.repeat.timeOfDay) {
      for (const timeOfDay of dosageInstruction.timing.repeat.timeOfDay) {
        times.push(moment(timeOfDay, 'H:mm').valueOf())
      }
      timing.push(...times)

      const timingLabelValue = getTimingLabel(timing)
      selectedTiming = {
        label: timingLabelValue,
        value: timingLabelValue,
      }
    } else {
      selectedTiming = { value: 'prn', label: 'Bedarfsmedikation' }
    }
    frequencyMax = dosageInstruction.timing.repeat.frequencyMax
      ? dosageInstruction.timing.repeat.frequencyMax
      : null
    periodUnitLabel = getUnitLabel(dosageInstruction.timing.repeat.periodUnit)
    period = dosageInstruction.timing.repeat.period
    distance = getExtensionValueByUrl(
      dosageInstruction.timing.repeat.extension,
      ExtensionURL.medicationRequestMinimalDistance
    )
    periodUnit = {
      value: dosageInstruction.timing.repeat.periodUnit
        ? dosageInstruction.timing.repeat.periodUnit
        : '',
      label: periodUnitLabel,
    }
    distanceUnitLabel = getUnitLabel(
      getExtensionValueByUrl(
        dosageInstruction.timing.repeat.extension,
        ExtensionURL.medicationRequestMinimalDistanceUnit
      )
    )
    distanceUnit = {
      value:
        getExtensionValueByUrl(
          dosageInstruction.timing.repeat.extension,
          ExtensionURL.medicationRequestMinimalDistanceUnit
        ) ?? '',
      label: distanceUnitLabel,
    }
    volume = roundTo(
      Number(dosage.split('/')[0].replace(',', '.')) /
        Number(packageVariant.split('/')[0].replace(',', '.')),
      3
    )
    dosages.push({
      dose: {
        label: dosage.replaceAll('.', ','),
        value: dosage.replaceAll('.', ','),
      },
      pieces: pieces,
      times: times,
      frequencyMax: frequencyMax,
      period: period,
      distance: distance,
      periodUnit: periodUnit,
      distanceUnit: distanceUnit,
      volume: volume,
    })
  }
  const patientInstructions = dosageInstructions[0].patientInstruction

  timing.sort((a, b) => a - b)

  return {
    timing,
    selectedTiming: selectedTiming,
    dosages,
    patientInstructions,
  }
}

export const getPackage = (
  activityDefinition: ActivityDefinition,
  medicationId: id
): OptionChoices => {
  const packageIds = getPackageIds(activityDefinition).map(
    it => it.split('/')[1]
  )
  const index = packageIds.findIndex(it => it === medicationId)
  return getPackageOptions(activityDefinition)[index]
}

export const mapDosageInstruction = (
  dosageInstruction: Dosage[],
  packageVariant: string
): null | Record<string, any> => {
  let dosageObjectsOfSameInterval = []

  let timeIntervals = dosageInstruction
    ?.map((dosageObject, index) => {
      const endCurrentDosageObject = moment(
        dosageObject.timing.repeat.boundsPeriod.end
      ).valueOf()
      let endNextDosageObject = null
      if (index + 1 < dosageInstruction.length) {
        endNextDosageObject = moment(
          dosageInstruction[index + 1].timing.repeat.boundsPeriod.end
        ).valueOf()
      }

      dosageObjectsOfSameInterval.push(dosageObject)

      if (
        endCurrentDosageObject !== endNextDosageObject &&
        new Date(dosageObject.timing.repeat.boundsPeriod.start) !==
          new Date(dosageObject.timing.repeat.boundsPeriod.end)
      ) {
        const timingAndDosage = remapDosageInstruction(
          dosageObjectsOfSameInterval,
          packageVariant
        )
        dosageObjectsOfSameInterval = []
        return {
          name: 'timeIntervals',
          startDate: new Date(dosageObject.timing.repeat.boundsPeriod.start),
          endDate: new Date(dosageObject.timing.repeat.boundsPeriod.end),
          timingAndDosage: timingAndDosage,
        }
      } else {
        return null
      }
    })
    .filter(it => it !== null)

  if (timeIntervals === undefined) timeIntervals = []

  return { timeIntervals }
}
