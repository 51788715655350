import React, { CSSProperties, useRef } from 'react'
import './MedicalIcon.scss'
import '../../Medication/MedicationSchedule/MedicationSchedule.scss'
import { useAsync } from 'react-use'

/**
 * Grateful credits to the discussion participants in stackoverflow:
 * https://stackoverflow.com/questions/61339259/how-to-dynamically-import-svg-and-render-it-inline/61472427#61472427
 * and github:
 * https://github.com/facebook/create-react-app/issues/5276#issuecomment-665628393
 */

interface MedicalIconProps extends React.SVGProps<SVGSVGElement> {
  name: string
  className?: string
  style?: CSSProperties
  size?: string
  color?: string
  verticalAlignment?: string
}

const MedicalIcon: React.FC<MedicalIconProps> = ({
  name,
  className,
  style,
  size,
  color,
  verticalAlignment,
}: MedicalIconProps ): JSX.Element | null => {
  const ImportedIconRef = useRef(null)

  const ImportIcon = () => {
    useAsync(
      async (): Promise<void> => {
        // eslint-disable-next-line no-useless-catch
        try {
          ImportedIconRef.current = (
            await import(
              `!!@svgr/webpack?-svgo,+titleProp,+ref!../../../assets/icons/${name}.svg`
            )
          ).default
        } catch (err) {
          throw err
        }
      }
    )
  }

  ImportIcon()

  if (ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef
    return (
      <ImportedIcon
        className={className ? className : 'medical-icons'}
        style={
          style
            ? style
            : {
                color: color,
                width: size,
                height: size,
                verticalAlign: verticalAlignment,
              }
        }
      />
    )
  }

  return null
}

export default MedicalIcon
