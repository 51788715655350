import { CarePlanStatus } from '../constants/CarePlanStatus'
import { CarePlan } from '../types/CarePlan'
import { fhirURIregexp } from './Utils'

export const defaultCarePlanFromMedicationRequests = (
  patientId: string,
  medicationRequests: MedicationRequest[],
  title?: string,
  status: CarePlanStatus = CarePlanStatus.on_hold
): CarePlan => {
  return defaultCarePlan(
    patientId,
    medicationRequests.map(it => it.id.toString()),
    title,
    status
  )
}

export const defaultCarePlan = (
  patientId: string,
  medicationRequests: string[],
  title?: string,
  status: CarePlanStatus = CarePlanStatus.on_hold
): CarePlan => {
  return {
    title: title ?? 'Automatisch erstellt',
    medicationRequests: medicationRequests,
    status: status,
    patient: patientId,
  } as CarePlan
}

export const findMedicationRequestsMatchingCarePlan = (
  medicationRequestId: string,
  medicationRequests: MedicationRequest[]
): MedicationRequest[] => {
  return medicationRequests.filter(it => {
    return it.idVersioned === medicationRequestId
  })
}

export function carePlanWasSaved(careplan: Record<string, any>): boolean | any {
  if (careplan.created && careplan?.basedOn?.length > 0) {
    const versionedId = careplan?.basedOn[0]?.reference
    const versionId = versionedId?.match(fhirURIregexp).groups?.versionId
    const wasSaved =
      parseInt(versionId) === parseInt(careplan?.meta?.versionId) - 1
    return wasSaved
  } else {
    return careplan.savedTime
  }
}

export function intersect<T>(a: T[], b: T[]): T[] {
  const setB = new Set(b)
  return [...new Set(a)].filter(x => setB.has(x))
}
