import React, { ReactNode } from 'react'
import { Button, Modal } from 'react-bootstrap'

interface Props {
  callback1: () => void
  callback2: () => void
  question: string
  text1?: string
  text2?: string
  disabled1?: boolean
  disabled2?: boolean
  title?: string
  disabled?: boolean
}

interface State {
  show: boolean
}

export class DecideDialogue extends React.Component<Props, State> {
  state: State = {
    show: false,
  }
  handleClose = (): void => {
    this.setState({ show: false })
  }
  handleShow = (event: React.MouseEvent<HTMLInputElement>): void => {
    if (event) event.stopPropagation()
    this.setState({ show: true })
  }
  handleConfirm1 = (event: React.MouseEvent<HTMLInputElement>): void => {
    if (event) event.stopPropagation()

    this.handleClose()
    this.props.callback1()
  }
  handleConfirm2 = (event: React.MouseEvent<HTMLInputElement>): void => {
    if (event) event.stopPropagation()

    this.handleClose()
    this.props.callback2()
  }

  render(): ReactNode {
    return (
      <>
        <span onClick={this.handleShow}>{this.props.children}</span>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            {this.props.title ? (
              <Modal.Title>{this.props.title}</Modal.Title>
            ) : null}
          </Modal.Header>
          <Modal.Body>{this.props.question}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Abbrechen
            </Button>
            <Button
              variant="primary"
              onClick={this.handleConfirm1}
              disabled={this.props.disabled1}>
              {this.props.text1 || 'Variante 1'}
            </Button>
            <Button
              variant="primary"
              onClick={this.handleConfirm2}
              disabled={this.props.disabled2}>
              {this.props.text2 || 'Variante 2'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}
