export enum FieldTypes {
  CALC = 'calc',
  CHECKBOX = 'checkbox',
  CHECKBOX_MATRIX = 'checkboxMatrix',
  DATEPICKER = 'DatePicker',
  DESCRIPTIVE = 'descriptive',
  DROPDOWN = 'dropdown',
  FIELD_AUFGABE = 'FieldAufgabe',
  FIELD_EXTERNAL_TOOL = 'FieldExternalTool',
  FIELD_INSTRUMENT = 'FieldInstrument',
  FIELD_TERMIN_AUFGABE = 'FieldTerminAufgabe',
  FIELD_TERMIN = 'FieldTermin',
  NOTES = 'notes',
  RADIO = 'radio',
  RADIO_MATRIX = 'radioMatrix',
  SLIDER = 'slider',
  SUBMIT = 'submit',
  TEXT = 'text',
  YESNO = 'yesno',
  FAKE = 'fake', // used only in FieldFunctionPiping
}
