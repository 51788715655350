import React from 'react'
import { NotificationManager } from 'react-notifications'
import Button from 'react-bootstrap/Button'
import TemplateLoadModal from './TemplateLoadModal'
import { informationFormatterCarePlan } from './TemplatePlanFormatters'
import _ from 'lodash'
import { APIResult } from '../../../constants/APIResult'
import { CarePlan } from '../../../types/CarePlan'
import { defaultCarePlan } from '../../../utils/CarePlanUtils'
import CarePlanService from '../../../services/CarePlanService'
import ComposeCarePlanModal from './ComposeCarePlanModal'
import LocalStorageService from '../../../services/LocalStorageService'
import { CAREPLAN_CONFIRM_STORAGE } from '../../../config/Storages'
import {
  AuthContext,
  AuthStore,
  withAuth,
} from '../../../infrastructure/AuthProvider'
import { CarePlanStatus } from '../../../constants/CarePlanStatus'
import { MedicationScheduleObject } from '../../../utils/MedicationScheduleUtils'
import { MedicationReason } from '../MedicationSchedule/MedicationReason'
import { UserPermission } from '../../../constants/UserPermission'

interface IProps extends AuthContext {
  patientId
  medicationRequests
  setLoad
  carePlan: CarePlan
  setCarePlan: (CarePlan) => void
  schedule: MedicationScheduleObject
  setUpdateIsRunning: (it: boolean) => void
  showCarePlanModals?: boolean
  disableCarePlanSaving: boolean
  isSubComponent?: boolean
  reason: MedicationReason
}

interface IState {
  carePlanTemplates
  showLoadModal
  showCreateNewCarePlanModal
  intermediateLoading
  yetUpdatedMedicationRequests
  defaultCarePlan: CarePlan
}

interface SwitchCarePlanData {
  selectedPlan: CarePlan
  startDate: Date
  deleteOtherMedications: boolean
}

class CarePlanTemplate extends React.Component<IProps, IState> {
  state = {
    carePlanTemplates: null,
    showLoadModal: false,
    intermediateLoading: false,
    yetUpdatedMedicationRequests: [],
    showCreateNewCarePlanModal: false,
    carePlanToSwitch: null,
    defaultCarePlan: defaultCarePlan(
      this.props.patientId,
      [],
      '',
      CarePlanStatus.on_hold
    ),
  }
  carePlanService: CarePlanService
  localStorageService = new LocalStorageService<SwitchCarePlanData>(
    CAREPLAN_CONFIRM_STORAGE
  )

  constructor(props) {
    super(props)
    this.carePlanService = new CarePlanService(props.reason)
  }
  static contextType = AuthStore

  componentDidMount() {
    this.fetchCarePlans().then()

    const carePlanStorage = this.localStorageService.localStorageGet()
    if (carePlanStorage.length > 0) {
      this.props.setUpdateIsRunning(true)
      const { selectedPlan } = carePlanStorage[0]
      this.setMedicationRequestsFromCarePlan(selectedPlan).then()
    }
    this.localStorageService.localStorageDeleteAll()
  }

  componentWillUnmount() {
    this.carePlanService.controller.abort()
  }

  reloginForSwitchingCarePlan = (
    selectedPlan,
    startDate,
    deleteOtherMedications
  ) => {
    this.localStorageService.localStorageAdd({
      selectedPlan,
      startDate,
      deleteOtherMedications,
    })
    this.props.handlers.doRelogin()
  }

  writeActiveMedicationRequestsToCarePlan = async () => {
    let carePlan = this.props.carePlan
    if (!carePlan?.id) {
      carePlan = (await this.carePlanService
        .postCarePlan(this.props.carePlan)
        .then(response => {
          if (response.Result === APIResult.SUCCESS) {
            return response.Response
          } else {
            console.error('CarePlan was not saved', response)
          }
        })) as CarePlan
    }

    if (carePlan?.id) {
      const medicationRequestVersionIds = this.props.medicationRequests.map(
        it => `${it.resourceType}/${it.id}/_history/${it.meta.versionId}`
      )

      const medicationRequestIds = _.uniq([
        ...this.props.medicationRequests.map(it => it.id.toString()),
      ])
      this.setState({
        yetUpdatedMedicationRequests: medicationRequestVersionIds,
      })

      await this.carePlanService
        .updateCarePlansMedicationRequests(carePlan.id, medicationRequestIds)
        .then(response => {
          if (response.Result === APIResult.SUCCESS) {
            this.props.setCarePlan(response.Response as CarePlan)
            this.fetchCarePlans()
          } else {
            NotificationManager.error(
              'Medikamentenplan nicht unter Medikamentenpläne gespeichert'
            )
          }
        })
    }
  }

  setShowLoadModal = (show: boolean) => {
    this.setState({ showLoadModal: show })
  }
  setShowCreateNewCarePlanModal = (show: boolean) => {
    this.setState({ showCreateNewCarePlanModal: show })
  }

  fetchCarePlans = () => {
    return this.carePlanService
      .getCarePlans(this.props.patientId)
      .then(response => {
        if (response.Result === APIResult.SUCCESS) {
          const careplans = (response.Response as CarePlan[]).map(it => {
            it.savedTime = it.savedTime ?? null
            it.savedVersion = it.savedVersion ?? null
            return it
          })
          this.setState({
            carePlanTemplates: careplans,
          })
        } else {
          if (!response.isAborted) {
            NotificationManager.error('Fehler beim Laden der Medikamentenpläne')
            console.log(
              'Fehler beim Laden der Medikamentenpläne',
              response,
              this.props
            )
          }
        }
      })
  }

  createCarePlanFromFromValues = async composedFormValues => {
    const medicationRequestIds = composedFormValues.plan.medicationRequests

    const newCarePlan = {
      id: null,
      title: composedFormValues.planName,
      patient: this.props.patientId,
      medicationRequests: medicationRequestIds,
      status: CarePlanStatus.on_hold,
    } as CarePlan

    // using a new service, that this service can be aborted, while the new service finishes
    return this.carePlanService.postCarePlan(newCarePlan).then(response => {
      if (response.Result === APIResult.SUCCESS) {
        this.fetchCarePlans()
      } else
        NotificationManager.error(
          'Fehler beim Erstellen eines neuen Medikationsplans'
        )
    })
  }

  setMedicationRequestsFromCarePlan = async selectedCarePlan => {
    //if (needToDeleteOtherMedRequests)
    // TODO transfer other medications to new CarePlan
    // and to move the new CarePlan to a certain date
    //  await stopOtherMedicationRequests(this.props.medicationRequests)

    this.carePlanService
      .activateCarePlan(
        this.props.patientId,
        selectedCarePlan.savedVersion && selectedCarePlan.savedVersion.length
          ? selectedCarePlan.savedVersion
          : selectedCarePlan.id
      )
      .then(async response => {
        if (response.Result === APIResult.SUCCESS) {
          const newCarePlan = response.Response as unknown as CarePlan
          this.props.setCarePlan(newCarePlan)
          this.props.setUpdateIsRunning(false)
          this.props.setLoad()
          NotificationManager.success(
            `Aktivieren des Medikamentenplans "${newCarePlan.title}" erfolgreich`
          )
        } else {
          console.error('could not switch careplan')
        }
      })
  }

  render() {
    if (
      !this.props.handlers.hasPermission(
        UserPermission.PATIENT_CAREPLAN_TEMPLATE
      )
    )
      return null
    if (!this.props.showCarePlanModals) return null
    return (
      <div>
        {this.props.isSubComponent ? null : (
          <>
            <Button
              className="ml-2 mr-2"
              variant={'outline-dark'}
              onClick={() => {
                this.fetchCarePlans().then()
                this.setShowLoadModal(true)
              }}
              size="sm">
              Medikationsplan laden
            </Button>

            <Button
              className="ml-2 mr-2"
              variant={'outline-dark'}
              onClick={() => {
                this.setShowCreateNewCarePlanModal(true)
              }}
              size="sm">
              Medikationsplan anlegen
            </Button>
          </>
        )}
        {this.state.showCreateNewCarePlanModal ? (
          <>
            <ComposeCarePlanModal
              patientId={this.props.patientId}
              carePlan={this.state.defaultCarePlan}
              onHide={() => {
                this.setShowCreateNewCarePlanModal(false)
              }}
              checkCarePlanTitle={planName => {
                for (const plan of this.state.carePlanTemplates) {
                  if (plan.title === planName) {
                    return false
                  }
                }
                return true
              }}
              enableEditCarePlanTitle={true}
              onSubmit={value => {
                this.createCarePlanFromFromValues(value).then()
                this.setShowCreateNewCarePlanModal(false)
              }}
            />
          </>
        ) : null}
        <TemplateLoadModal
          patientId={this.props.patientId}
          showModal={this.state.showLoadModal}
          displayStatusColumn={true}
          displaySavedVersionColumn={true}
          handleClose={() => {
            this.setShowLoadModal(false)
          }}
          medPlanTemplates={this.state.carePlanTemplates}
          selectPlanAction={async (plan, startDate, deleteOtherMedRequests) => {
            return this.reloginForSwitchingCarePlan(
              plan,
              startDate,
              deleteOtherMedRequests
            )
          }}
          setShowSaveModal={this.setShowCreateNewCarePlanModal}
          enableEditPlans={true}
          deletePlanAction={this.carePlanService.deleteCarePlan}
          informationFormatter={informationFormatterCarePlan}
          informationField={'medicationRequests'}
          reason={this.props.reason}
        />
      </div>
    )
  }
}

export default withAuth(CarePlanTemplate)
