import React, { Component } from 'react'
import { AuthContext, withAuth } from '../../../infrastructure/AuthProvider'
import { Appointment } from '../../../types/Appointment'
import { ConfirmDialogue } from '../../UI/Dialogues/ConfirmDialogue'
import { ParticipationStatus } from '../../../constants/ParticipationStatus'
import { Patient } from '../../../types/Patient'
import { AppointmentPerson } from '../../../types/AppointmentPerson'
import { ApiResponse } from '../../../types/ApiResponse'
import { Location } from '../../../types/Location'
import { ApiError } from '../../../types/ApiError'
import { NotificationManager } from 'react-notifications'
import { AppointmentCategories } from '../../../constants/AppointmentCategories'
import AppointmentService from '../../../services/AppointmentService'
import { AppointmentStatus } from '../../../constants/AppointmentStatus'
import { APIResult } from '../../../constants/APIResult'
import BigButton from '../../UI/BigButton/BigButton'
import {
  calcMinutesDuration,
  defaultAppointment,
  getParticipant,
  makeMaxDate,
  personToParticipant,
} from '../../../utils/CalendarUtils'
import { Practitioner } from '../../../types/Practitioner'
import { AutosaveService } from '../../../services/AutosaveService'
import { DraftKind } from '../../../types/DraftKind'
import AppointmentView from './AppointmentView'
import { Modal } from 'react-bootstrap'
import Autosave, { FlowStep } from '../../Draft/Autosave/Autosave'
import { AppointmentFormErrors } from '../../../types/AppointmentFormErrors'

interface AppointmentFormState {
  appointment: Appointment
  origAppointment: Appointment
  isSavedNow: boolean
  isEditable?: boolean
  errors?: AppointmentFormErrors
}

interface AppointmentFormProps extends AuthContext {
  appointment?: Appointment
  isEditable?: boolean
  onChanged: (value?: Appointment) => void
  handleValueChange?: (value?: Appointment) => void
  onClose?: () => void
  insideModal?: boolean
}

class AppointmentForm extends Component<
  AppointmentFormProps,
  AppointmentFormState
> {
  static defaultProps = {
    isEditable: true,
  }

  constructor(props: AppointmentFormProps) {
    super(props)
    const origAppointment = this.props.appointment
      ? {
          ...this.props.appointment,
        }
      : defaultAppointment(this.props.auth.user.settings?.sharedCalendar)
    const { auth } = this.props
    if (!this.props.appointment && auth.panosId) {
      origAppointment.participants = [
        {
          id: 'Practitioner/' + auth.panosId,
          firstName: auth.user.firstName,
          lastName: auth.user.lastName,
          participationStatus: ParticipationStatus.ACCEPTED,
        },
      ]
    }
    this.state = {
      appointment: origAppointment,
      origAppointment: origAppointment,
      isEditable: this.props.isEditable,
      isSavedNow: false,
      errors: null,
    }
  }

  onCancelEditAppointment = () => {
    this.setState(
      {
        appointment: this.state.origAppointment,
      },
      () => {
        this.props.onChanged(this.state.origAppointment)
        this.props.onClose()
      }
    )
  }

  handleSubmit = async () => {
    const { appointment } = this.state

    if (!appointment.id) {
      await this.saveAppointment(appointment)
    } else {
      await this.updateAppointment(appointment)
    }
  }

  saveAppointment = async (appointment: Appointment = null) => {
    const service = new AppointmentService()
    const response = await service.postAppointment({
      ...appointment,
      status: AppointmentStatus.ARRIVED,
    })
    await this.handleResponse(response)
  }

  updateAppointment = async (
    appointment: Appointment = null,
    isDraft = false
  ) => {
    const service = new AppointmentService()
    const response = await service.putAppointment({
      ...appointment,
      status: AppointmentStatus.ARRIVED,
    })
    await this.handleResponse(response)
  }

  handleResponse = async (response: ApiResponse<Appointment | ApiError>) => {
    if (response.Result === APIResult.SUCCESS) {
      NotificationManager.success('Erfolg', 'Termin gespeichert')
      const appointment = response.Response as Appointment

      this.setState(
        {
          appointment: appointment,
          isSavedNow: true,
        },
        () => this.props.onChanged(appointment)
      )
    } else {
      if (typeof response.Response === 'string') {
        NotificationManager.error(response.Response, `Termin nicht gespeichert`)
      } else {
        NotificationManager.error(`Termin nicht gespeichert`, 'Fehler')
      }
    }
  }

  onPractitionerAdded = (person: Practitioner) => {
    this.addParticipant(
      { ...person, id: 'Practitioner/' + person.id },
      this.props.auth.panosId === person.id
    )
  }

  onPatientAdded = (person: Patient) => {
    this.addParticipant(
      { ...person, id: 'Patient/' + person.id, role: 'patient' },
      this.props.auth.panosId === person.id
    )
  }

  addParticipant = (person: Patient | Practitioner, autoAccept: boolean) => {
    const { appointment } = this.state
    if (person && !getParticipant(person.id, appointment)) {
      const { participants } = appointment
      const newParticipants = participants || []
      const newParticipant = personToParticipant(person)
      /* directly accept if you are self assinging */
      if (autoAccept) {
        newParticipant.participationStatus = ParticipationStatus.ACCEPTED
      }
      this.setState({
        appointment: {
          ...this.state.appointment,
          participants: [...newParticipants, newParticipant],
        },
      })
    }
  }

  handleInputChange = (
    fieldname: string,
    value: string | number | Date | AppointmentCategories | Location | boolean
  ): void => {
    if (
      fieldname === 'category' &&
      (value === AppointmentCategories.ABSENCE ||
        value === AppointmentCategories.PRIVATE)
    ) {
      if (value !== this.state.appointment.category) {
        this.cleanParticipants()
      }
    }

    this.setState({
      appointment: {
        ...this.state.appointment,
        [fieldname]: value,
      },
    })
  }

  cleanParticipants() {
    const { auth } = this.props

    this.setState({
      appointment: {
        ...this.state.appointment,
        participants: [
          {
            id: 'Practitioner/' + auth.panosId,
            firstName: auth.user.firstName,
            lastName: auth.user.lastName,
            participationStatus: ParticipationStatus.ACCEPTED,
          },
        ],
      },
    })
  }

  onTemplateSelected = (selectedTemplate: Appointment) => {
    this.setState({
      appointment: {
        ...this.state.appointment,
        category: selectedTemplate?.category,
        description: selectedTemplate?.description,
        minutesDuration: selectedTemplate?.minutesDuration,
        priority: selectedTemplate?.priority,
      },
    })
  }

  handleParticipantDelete = (person: AppointmentPerson) => {
    const { participants } = this.state.appointment
    this.setState({
      appointment: {
        ...this.state.appointment,
        participants: participants.filter(
          participant => participant.id !== person.id
        ),
      },
    })
  }

  removeDraftForThis = async () => {
    await new AutosaveService().deleteValue(this.state.appointment)

    this.setState({
      appointment: this.state.origAppointment,
    })
    this.props.onChanged(this.state.origAppointment)
  }

  onChangeEndDate = (end: Date): void => {
    if (this.state.appointment.startingDateTime) {
      this.setState({
        appointment: {
          ...this.state.appointment,
          minutesDuration: calcMinutesDuration(
            this.state.appointment.startingDateTime,
            end
          ),
        },
      })
    }
  }

  handleDraftUpdate = (appointment: Appointment): void => {
    appointment.startingDateTime = new Date(appointment.startingDateTime)
    this.setState({ appointment: appointment })
    if (this.props.handleValueChange) this.props.handleValueChange(appointment)
  }

  render() {
    const { appointment } = this.state

    const maxDate = makeMaxDate(appointment)

    return (
      <div className="">
        {this.props.appointment && (
          <Autosave<Appointment>
            value={this.state.appointment}
            draftkind={DraftKind.Appointment}
            onSetDraft={this.handleDraftUpdate}
            flowBeAt={FlowStep.save}
            isSavedNow={this.state.isSavedNow}
          />
        )}
        {this.props.insideModal === undefined ||
        this.props.insideModal === false ? (
          <>
            <div className="text-right">
              {this.state.appointment.tempId ? (
                <BigButton
                  icon="settings_backup_restore"
                  onClick={this.removeDraftForThis}>
                  <>
                    Entwurf
                    <br />
                    löschen
                  </>
                </BigButton>
              ) : null}
              <ConfirmDialogue
                callback={this.onCancelEditAppointment}
                title="Abbrechen"
                question="Sollen die Änderungen wirklich verworfen werden?"
                confirmText="Änderungen verwerfen"
                disabled={this.state.origAppointment === appointment}>
                <BigButton icon="cancel_presentation">Abbrechen</BigButton>
              </ConfirmDialogue>
              <BigButton
                icon="save"
                onClick={this.handleSubmit}
                disabled={!this.state.isEditable}>
                Speichern
              </BigButton>
            </div>
            <div className="d-flex justify-content-end"></div>
            <AppointmentView
              appointment={appointment}
              isEditable={this.state.isEditable}
              handleInputChange={this.handleInputChange}
              maxDate={maxDate}
              onChangeEndDate={this.onChangeEndDate}
              handleParticipantDelete={this.handleParticipantDelete}
              onPatientAdded={this.onPatientAdded}
              onPractitionerAdded={this.onPractitionerAdded}
              errors={this.state.errors}
            />
          </>
        ) : (
          <>
            <Modal.Body>
              <AppointmentView
                appointment={appointment}
                isEditable={this.state.isEditable}
                handleInputChange={this.handleInputChange}
                maxDate={maxDate}
                onChangeEndDate={this.onChangeEndDate}
                handleParticipantDelete={this.handleParticipantDelete}
                onPatientAdded={this.onPatientAdded}
                onPractitionerAdded={this.onPractitionerAdded}
                errors={this.state.errors}
              />
            </Modal.Body>
            <Modal.Footer>
              {this.state.appointment.tempId ? (
                <BigButton
                  icon="settings_backup_restore"
                  onClick={this.removeDraftForThis}>
                  <>
                    Entwurf
                    <br />
                    löschen
                  </>
                </BigButton>
              ) : null}
              <ConfirmDialogue
                callback={this.onCancelEditAppointment}
                title="Abbrechen"
                question="Sollen die Änderungen wirklich verworfen werden?"
                confirmText="Änderungen verwerfen"
                disabled={this.state.origAppointment === appointment}>
                <BigButton icon="cancel_presentation">Abbrechen</BigButton>
              </ConfirmDialogue>
              <BigButton
                icon="save"
                disabled={!this.state.isEditable}
                onClick={this.handleSubmit}>
                Speichern
              </BigButton>
            </Modal.Footer>
          </>
        )}
      </div>
    )
  }
}

export default withAuth(AppointmentForm)
