import moment from 'moment'
import { ParticipationStatus } from '../constants/ParticipationStatus'
import { Appointment } from '../types/Appointment'
import { AppointmentPerson } from '../types/AppointmentPerson'
import { Patient } from '../types/Patient'
import { Practitioner } from '../types/Practitioner'
import { AppointmentIconAction } from '../components/Appointments/Calendar/CalendarContainer'
import { Priority, priorityDaysRange } from '../constants/Priority'
import { AppointmentCategories } from '../constants/AppointmentCategories'
import { addDays, addMinutes, parseUnknownToDate } from './Utils'
import { isAlsoDraft, isPureDraft } from './DraftUtils'
import { AppointmentStatus } from '../constants/AppointmentStatus'
import { EventInput } from '@fullcalendar/common'

export const getAppointmentPartiticipantStatus = (
  participant: Patient | Practitioner | string,
  appointment: Appointment
): ParticipationStatus | null => {
  if (!participant || !appointment) return null
  if (typeof participant === 'string') {
    const tmp = getParticipant(participant, appointment)
    if (tmp) return tmp.participationStatus
  } else {
    const appointmentPerson: AppointmentPerson = getParticipant(
      participant.id,
      appointment
    )
    if (appointmentPerson) {
      return appointmentPerson.participationStatus
    }
  }
  return null
}

export function appointmentHasPractitioners(appointment: Appointment): boolean {
  if (!appointment || !appointment.participants) return false
  return appointment.participants.some(participant => participant.role)
}

export function appointmentWriteDate(
  appointment: Appointment,
  date: Date
): Date {
  const timeStr = appointment.startingDateTime
    ? moment(appointment.startingDateTime).format('HH:mm')
    : moment(new Date()).format('HH:mm')
  return moment(moment(date).format('YYYY-MM-DD') + ' ' + timeStr).toDate()
}

export function personToParticipant(
  person: Patient | Practitioner
): AppointmentPerson {
  const participant: AppointmentPerson = {
    id: person.id,
    participationStatus: ParticipationStatus.NEEDSACTION,
    firstName: person.firstName,
    lastName: person.lastName,
    role: person.role,
  }

  return participant
}

export function getParticipant(
  participant: string,
  appointment: Appointment
): AppointmentPerson | null {
  if (!appointment.participants) return null
  return appointment.participants.find(
    person =>
      person.id === participant ||
      person.id === 'Practitioner/' + participant ||
      person.id === 'Patient/' + participant
  )
}

export function appointmentWriteTime(
  appointment: Appointment,
  date: Date
): Date {
  return moment(
    moment(appointment.startingDateTime ?? new Date()).format('YYYY-MM-DD') +
      ' ' +
      moment(date).format('HH:mm')
  ).toDate()
}

export function mapAppointmentIcons(
  actions: AppointmentIconAction[]
): string[] {
  return actions.map(appointmentIconAction => {
    switch (appointmentIconAction) {
      case AppointmentIconAction.hasDraft: {
        return 'bookmark'
      }
      case AppointmentIconAction.isDraft: {
        return 'edit'
      }
      case AppointmentIconAction.needsPractitioner:
        return 'alarm_add'
      default:
        return null
    }
  })
}

export function mapAppointmentToEvent(
  appointment: Appointment,
  panosId: string
): EventInput {
  if (!(appointment.tempId ?? appointment.id)) {
    console.error(
      Error('No id set on CalendarEvent ' + JSON.stringify(appointment))
    )
    return {
      ...defaultAppointment(),
      start: new Date(),
      title: 'ERROR',
      end: new Date(),
      id: '000',
      stick: false,
    }
  }
  if (!appointment.startingDateTime) {
    appointment.startingDateTime = new Date()
  }

  const eventAppointment = {
    id: appointment.tempId ?? appointment.id,
    title: appointment.title ?? 'Ohne Titel',
    start: appointment.startingDateTime.toISOString(),
    end: moment(appointment.startingDateTime)
      .add(appointment.minutesDuration, 'minutes')
      .toDate()
      .toISOString(),
    actionIcons: getAppointmentIconActions(appointment),
    //backgroundColor: this.getEventColor(appointment),
    className: [
      'calItem',
      getBackgroundColorClass(appointment),
      getEventColorClass(appointment, panosId),
    ].join(' '),
    //textColor: 'black',
    borderColor: 'grey',
    stick: false,
    participants: toStringParticipantsList(appointment),
  }
  return eventAppointment
}

function toStringParticipantsList(appointment: Appointment): string {
  if (
    appointment === null ||
    appointment.participants === null ||
    appointment.participants.length === 0
  ) {
    return null
  }

  return appointment.participants
    ?.map(person => person?.firstName + ' ' + person?.lastName)
    ?.join(', ')
}

export function getAppointmentIconActions(
  appointment: Appointment
): AppointmentIconAction[] {
  if (!appointment) {
    return []
  }

  let actions = []
  if (!appointmentHasPractitioners(appointment)) {
    actions = [...actions, AppointmentIconAction.needsPractitioner]
  }
  if (isAlsoDraft(appointment) || isPureDraft(appointment)) {
    actions = [...actions, AppointmentIconAction.isDraft]
  }
  return actions
}

export function getEventColorClass(
  appointment: Appointment,
  panosId: string
): string {
  if (appointment.tempId) {
    return 'border-secondary'
  }
  const participant = getParticipant(panosId, appointment)

  let participantStatus: ParticipationStatus
  if (!participant) {
    return 'border-warning'
  } else {
    participantStatus = getAppointmentPartiticipantStatus(
      participant,
      appointment
    )
  }

  switch (participantStatus) {
    case ParticipationStatus.NEEDSACTION:
      return 'bg border-info'
    case ParticipationStatus.ACCEPTED:
      return 'border-success'
    case ParticipationStatus.DECLINED:
      return 'border-danger'
    default:
      return 'border-light'
  }
}

export function getBackgroundColorClass(appointment: Appointment): string {
  switch (appointment.priority) {
    case Priority.ROUTINE:
      return 'priority routine'
    case Priority.URGENT:
      return 'priority urgent'
    case Priority.ASAP:
      return 'priority asap'
    default:
      return 'white'
  }
}

export function istOutOfWorkAppointment(appointment: Appointment): boolean {
  return (
    appointment.category === AppointmentCategories.PRIVATE ||
    appointment.category === AppointmentCategories.ABSENCE
  )
}

export function getDateFromDuration(appointment: Appointment): Date | string {
  const { minutesDuration, startingDateTime } = appointment
  if (!startingDateTime) return ''

  return addMinutes(minutesDuration || 0, parseUnknownToDate(startingDateTime))
}

export function makeMaxDate(appointment: Appointment): Date | null {
  let maxDate: Date = null
  if (
    appointment &&
    appointment.priority &&
    priorityDaysRange[appointment.priority]
  ) {
    maxDate = addDays(priorityDaysRange[appointment.priority], new Date())
    maxDate.setHours(23, 59, 59, 999)
  }
  return maxDate
}

export function calcMinutesDuration(start: Date, end: Date): number | null {
  if (end && start) return (end.getTime() - start.getTime()) / 1000 / 60
  else return null
}

export function defaultAppointment(sharedCalendar?: string): Appointment {
  const newAppointment = new Appointment()
  newAppointment.minutesDuration = 30
  newAppointment.category = AppointmentCategories.MEETING
  newAppointment.status = AppointmentStatus.ARRIVED
  newAppointment.description = ''

  newAppointment.shared = sharedCalendar === 'true' || false
  return { ...newAppointment }
}
