import { FormDynamicComponentProps } from '../components/FormDynamic/FormDynamicComponent'
import { FormOption } from '../types/FormOption'
import { Task } from '../types/Task'
import {
  REPEAT_PREFIX,
  REPEAT_REGEX,
} from '../components/FieldFunctionComponents/FieldFunctionRepeat'
import _, { isArray } from 'lodash'
import { nest } from './NestUtils'
import React from 'react'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { FieldFunctionNewEvent } from '../types/FieldFunctionNewEvent'
import { LastModule, NewEventData } from '../components/Forms/FormInterfaces'
import VariableInserter from '../components/UI/VariableInserter/VariableInserter'
import { hasVariables } from './RedcapVariableUtils'
import { formatDate, getFullName, isValidDate } from './Utils'
import { Person } from '../types/Person'
import { MODULE_PREFIX } from '../config/Constants'
import { DynamicForm } from '../infrastructure/EventProviderInterfaces'
import { handleNullComponentError } from '../infrastructure/EventProvider'
import evaluate from './EvaluateBranchingUtils'
import { RedCapSaveMethods } from '../constants/RedCapSaveMethods'
import { FieldTypes } from '../constants/FieldTypes'
import { NotificationManager } from 'react-notifications'

const componentsWithVariable = [
  FieldTypes.TEXT,
  FieldTypes.DATEPICKER,
  FieldTypes.DROPDOWN,
  FieldTypes.YESNO,
  FieldTypes.RADIO,
  FieldTypes.CHECKBOX,
  FieldTypes.NOTES,
  FieldTypes.SLIDER,
  FieldTypes.CALC,
]
/* const componentsWithoutVariable = diffArray(components, componentsWithVariable)
 */
/**
 * Calculates the set difference of arr1 difference arr2
 * @param arr1
 * @param arr2
 * @returns
 */
/* export function diffArray<T>(arr1: T[], arr2: T[]): T[] {
  return arr1.filter(item => !arr2.includes(item))
} */

// easy workaround, security issue !!!
export function stripHTML(htmlString: string): string {
  if (htmlString === undefined || htmlString === null) return ''
  return htmlString.replace(/<[^>]+>/g, '')
}

interface HTMLTagJSRepresentation {
  name: string
  attributes: Record<string, string>
  children: Array<string | HTMLTagJSRepresentation>
}

function recursiveParseHTMLToJS(
  htmlTagName: string,
  htmlString: string,
  htmlTagAttributes: Record<string, string> = {}
): [HTMLTagJSRepresentation, number] {
  let match
  const tag = { name: htmlTagName, attributes: htmlTagAttributes, children: [] }
  let stringToParse = htmlString
  let charactersParsed = 0
  while ((match = stringToParse.match(/<\s*\/?([^>\s]+)([^>]*)>/)) !== null) {
    if (match.index !== 0)
      tag.children.push(stringToParse.substring(0, match.index))

    stringToParse = stringToParse.substring(match.index + match[0].length)
    charactersParsed += match.index + match[0].length

    const matchAttributes = {}
    Array.from(
      match[2].matchAll(/([^\s=]+)(=("([^"]+)"|'([^']+)'))?/g),
      attribute => {
        matchAttributes[attribute[1]] = attribute[5] || attribute[4] || ''
        return null
      }
    )

    if (match[0].match(/^<\s*\//) !== null) {
      if (match[1] !== htmlTagName)
        console.warn('Error in HTML Syntax to parse: found wrong closing tag')
      return [tag, charactersParsed]
    }

    if (match[0].match(/\/\s*>$/) !== null) {
      tag.children.push({
        name: match[1],
        attributes: matchAttributes,
        children: [],
      })
    } else {
      const [child, innerCharactersParsed] = recursiveParseHTMLToJS(
        match[1],
        stringToParse,
        matchAttributes
      )
      tag.children.push(child)
      stringToParse = stringToParse.substring(innerCharactersParsed)
      charactersParsed += innerCharactersParsed
    }
  }
  if (stringToParse.length > 0) {
    tag.children.push(stringToParse)
    charactersParsed += stringToParse.length
  }
  return [tag, charactersParsed]
}

const convertStylesStringToObject = stringStyles =>
  typeof stringStyles === 'string'
    ? (stringStyles.split(';').reduce((acc, style) => {
        const colonPosition = style.indexOf(':')

        if (colonPosition === -1) {
          return acc
        }

        const camelCaseProperty = style
          .substr(0, colonPosition)
          .trim()
          .replace(/^-ms-/, 'ms-')
          .replace(/-./g, c => c.substr(1).toUpperCase())
        const value = style.substr(colonPosition + 1).trim()

        // filters out malicious css functions
        if (value.match(/(url\(|expression\()/) !== null) return acc

        return value ? { ...acc, [camelCaseProperty]: value } : acc
      }, {}) as CSSProperties)
    : {}

// only hand selected tags allowed, security issue !!!
function recursiveParseJSToReact(
  htmlObject: string | HTMLTagJSRepresentation,
  keyPrefix: string
) {
  if (typeof htmlObject === 'string') {
    if (hasVariables(htmlObject)) return <VariableInserter text={htmlObject} />
    return <>{htmlObject}</>
  } else {
    const children = htmlObject.children.map((child, i) =>
      recursiveParseJSToReact(child, keyPrefix + '-' + i)
    )
    const attributes: Record<string, unknown> = {
      key: htmlObject.attributes.key || keyPrefix,
    }
    let styles: CSSProperties = {}
    if (htmlObject.attributes.style !== undefined)
      styles = convertStylesStringToObject(htmlObject.attributes.style)
    let returnElement = <>{children}</>
    switch (htmlObject.name) {
      case 'div':
        returnElement = (
          <div {...attributes} style={styles}>
            {children}
          </div>
        )
        break
      case 'p':
        returnElement = (
          <p {...attributes} style={styles}>
            {children}
          </p>
        )
        break
      case 'span':
        returnElement = (
          <span {...attributes} style={styles}>
            {children}
          </span>
        )
        break
      case 'b':
        returnElement = (
          <b {...attributes} style={styles}>
            {children}
          </b>
        )
        break
      case 'i':
        returnElement = (
          <i {...attributes} style={styles}>
            {children}
          </i>
        )
        break
      case 'u':
        returnElement = (
          <u {...attributes} style={styles}>
            {children}
          </u>
        )
        break
      case 'h1':
        returnElement = (
          <h1 {...attributes} style={styles}>
            {children}
          </h1>
        )
        break
      case 'h2':
        returnElement = (
          <h2 {...attributes} style={styles}>
            {children}
          </h2>
        )
        break
      case 'h3':
        returnElement = (
          <h3 {...attributes} style={styles}>
            {children}
          </h3>
        )
        break
      case 'h4':
        returnElement = (
          <h4 {...attributes} style={styles}>
            {children}
          </h4>
        )
        break
      case 'h5':
        returnElement = (
          <h5 {...attributes} style={styles}>
            {children}
          </h5>
        )
        break
      case 'h6':
        returnElement = (
          <h6 {...attributes} style={styles}>
            {children}
          </h6>
        )
        break
      case 'ol':
        returnElement = (
          <ol {...attributes} style={styles}>
            {children}
          </ol>
        )
        break
      case 'ul':
        returnElement = (
          <ul {...attributes} style={styles}>
            {children}
          </ul>
        )
        break
      case 'li':
        returnElement = (
          <li {...attributes} style={styles}>
            {children}
          </li>
        )
        break
      case 'table':
        returnElement = (
          <table {...attributes} style={styles}>
            {children}
          </table>
        )
        break
      case 'tr':
        returnElement = (
          <tr {...attributes} style={styles}>
            {children}
          </tr>
        )
        break
      case 'th':
        returnElement = (
          <th {...attributes} style={styles}>
            {children}
          </th>
        )
        break
      case 'td':
        returnElement = (
          <td {...attributes} style={styles}>
            {children}
          </td>
        )
        break
      case 'br':
        return <br />
      case 'img':
        switch (htmlObject.attributes.position) {
          case 'left':
            styles.float = 'left'
            break
          case 'right':
            styles.float = 'right'
            break
          default:
        }
        returnElement = (
          <img
            {...attributes}
            alt={(attributes.alt as string) || 'bild'}
            style={styles}
            src={htmlObject.attributes.src}
          />
        )
        break
    }
    return returnElement
  }
}

export function parseHTML(htmlString: string): JSX.Element {
  if (htmlString === undefined || htmlString === null) return null
  const [htmlObject] = recursiveParseHTMLToJS('', htmlString)
  return recursiveParseJSToReact(htmlObject, 'recursiveParsedElement')
}

export function fillTaskFromParsed(defaultTask: Task, tasks: Task[]): Task {
  const task = tasks.length > 0 ? tasks[0] : {}
  let newTask = Object.assign({}, defaultTask)
  newTask = Object.assign(newTask, task)
  return newTask
}

export const makeCheckBoxOptionNameRedcap = (
  name: string,
  option: FormOption
): string => `${name}___${option.value}`

/**
 *
 */
const fieldsToIgnoreForVariables = [
  FieldTypes.DESCRIPTIVE,
  FieldTypes.FIELD_EXTERNAL_TOOL,
  FieldTypes.FIELD_INSTRUMENT,
  FieldTypes.FIELD_AUFGABE,
  FieldTypes.FIELD_TERMIN,
  FieldTypes.FIELD_TERMIN_AUFGABE,
]

/**
 * Remove HTML tags from MetaData labels
 * @param metaData
 */

export function stripHTMLMetaData(
  metaData: FormDynamicComponentProps[]
): FormDynamicComponentProps[] {
  return metaData.map(componentData => {
    if (typeof componentData.label === 'string') {
      componentData.label = stripHTML(componentData.label)
    }
    return componentData
  })
}

/**
 * Remove HTML tags from MetaData labels
 * @param metaData
 */

export function parseHTMLMetaData(
  metaData: FormDynamicComponentProps[]
): FormDynamicComponentProps[] {
  if (metaData === null || metaData === undefined) return []
  return metaData.map(componentData => {
    if (componentData.subComponents)
      componentData.subComponents = parseHTMLMetaData(
        componentData.subComponents
      )
    if (typeof componentData.label === 'string') {
      componentData.label = parseHTML(componentData.label)
    }
    if (typeof componentData.header === 'string') {
      componentData.header = parseHTML(componentData.header)
    }
    return componentData
  })
}

/**
 * Preparation for translateRedcapToFrontend
 * @param metaData
 */
export function cleanMetaData(
  metaData: FormDynamicComponentProps[]
): FormDynamicComponentProps[] {
  if (metaData === null || metaData === undefined) return []
  return metaData.filter(curent => {
    return (
      [
        FieldTypes.DESCRIPTIVE,
        FieldTypes.FIELD_AUFGABE,
        'FieldTerminAufgabe',
        FieldTypes.FIELD_AUFGABE,
      ].indexOf(curent.field_type) === -1
    )
  })
}

/**
 * Adds subcomponents
 * especially checkboxes and radios
 * @param metaData
 */

export function addSubComponents(
  metaData: FormDynamicComponentProps[]
): FormDynamicComponentProps[] {
  if (metaData === null || metaData === undefined) return []
  let metaWithSubcomponents = []
  metaData.forEach(element => {
    switch (element.field_type) {
      case FieldTypes.FIELD_INSTRUMENT:
        metaWithSubcomponents = metaWithSubcomponents.concat(
          addSubComponents(element.subComponents)
        )
        break
      case FieldTypes.CHECKBOX_MATRIX:
      case FieldTypes.RADIO_MATRIX: {
        element.subComponents.forEach(row => {
          metaWithSubcomponents.push(row)
        })
        break
      }
      default:
        metaWithSubcomponents.push(element)
        break
    }
  })
  return metaWithSubcomponents
}

/**
 * Translates the Checkbox variables to a better usable form
 * {checkboxgroup___1: null, checkboxgroup___2: '1', checkboxgroup___3: '1'} -> {checkboxgroup:{1: null, 2: true, 3: true}}
 */
export function translateRedcapToFrontend(
  metaData: FormDynamicComponentProps[],
  sharedValues: Record<string, unknown>,
  saveMethod: RedCapSaveMethods,
  lastModules: LastModule[]
): Record<string, unknown> {
  const meta = cleanMetaData(metaData)
  const values = {}
  let metaWithSubcomponents: FormDynamicComponentProps[]
  metaWithSubcomponents = addSubComponents(meta)
  if (sharedValues === undefined) sharedValues = {}
  metaWithSubcomponents = [
    ...metaWithSubcomponents,
    ...generateRepeatedComponents(metaWithSubcomponents, sharedValues).flat(),
  ]

  const checkboxComponents = {}

  metaWithSubcomponents.forEach((element: FormDynamicComponentProps) => {
    let checkboxDefault
    let otherDefault
    switch (saveMethod) {
      case RedCapSaveMethods.NEW:
        if (isInLastModules(element, lastModules)) {
          checkboxDefault = false
          otherDefault = ''
        } else {
          checkboxDefault = null
          otherDefault = null
        }
        break
      default:
        checkboxDefault = false
        otherDefault = ''
    }

    switch (element.field_type) {
      case FieldTypes.CHECKBOX:
        checkboxComponents[element.name] = true
        values[element.name] = {}
        element.options.forEach(option => {
          values[element.name][option.value] = checkboxDefault
        })
        break
      case FieldTypes.FIELD_AUFGABE:
      case FieldTypes.FIELD_INSTRUMENT:
      case FieldTypes.DESCRIPTIVE:
        delete values[element.name]
        break
      default:
        values[element.name] = otherDefault
    }
  })
  for (const prop in sharedValues) {
    if (Object.prototype.hasOwnProperty.call(values, prop)) {
      if (checkboxComponents[prop]) values[prop][sharedValues[prop]] = true
      else values[prop] = sharedValues[prop]
    } else {
      const [checkboxName, checkboxValue] = prop.split('___')
      if (
        checkboxName &&
        checkboxValue &&
        Object.prototype.hasOwnProperty.call(values, checkboxName)
      ) {
        values[checkboxName][checkboxValue] = switchRedcapValueToCheckboxValue(
          sharedValues[prop] as string
        )
      }
    }
  }

  return values
}

export function switchRedcapValueToCheckboxValue(value: string): boolean {
  switch (value) {
    case '1':
      return true
    case '0':
      return false
    default:
      return null
  }
}

function switchCheckboxValueToRedcapValue(value: boolean): string {
  switch (value) {
    case true:
      return '1'
    case false:
      return '0'
    case null:
      return '0'
    default:
      return null
  }
}

/**
 * Translates the Checkbox variables to a better usable form
 * {checkboxgroup:{1:null, 2:'1', 3:'1}} -> {checkboxgroup___1: null, checkboxgroup___2: '1', checkboxgroup___3: '1'}
 */
export function translateFrontendToRedcap(
  metaData: FormDynamicComponentProps[],
  values: Record<string, unknown>,
  formName: string
): Record<string, unknown> {
  const translatedValues = { ...values }

  let metaWithSubcomponents: FormDynamicComponentProps[]
  metaWithSubcomponents = addSubComponents(metaData)
  metaWithSubcomponents = [
    ...metaWithSubcomponents,
    ...generateRepeatedComponents(metaWithSubcomponents, values).flat(),
  ]

  metaWithSubcomponents.forEach(component => {
    const name = component.name
    switch (component.field_type) {
      case FieldTypes.CHECKBOX: {
        delete translatedValues[name]
        component?.options?.forEach(option => {
          const checkboxName = makeCheckBoxOptionNameRedcap(name, option)

          translatedValues[checkboxName] = switchCheckboxValueToRedcapValue(
            values[name] ? (values[name][option.value] as boolean) : false
          )
        })
        break
      }
      case FieldTypes.FIELD_TERMIN:
      case FieldTypes.FIELD_AUFGABE:
      case FieldTypes.FIELD_TERMIN_AUFGABE:
      case FieldTypes.FIELD_INSTRUMENT:
      case FieldTypes.DESCRIPTIVE:
        delete translatedValues[name]
        break
      default:
        break
    }
    if (translatedValues[name] === null) delete translatedValues[name]
  })

  // write values in correct form
  const allForms = {}
  if (Array.isArray(metaData))
    metaData?.forEach(component => {
      if (component.field_type === FieldTypes.FIELD_INSTRUMENT) {
        const variableNames = getVariableNamesFromMeta(component.subComponents)
        const formValues = {}
        if (Array.isArray(variableNames)) {
          variableNames.forEach(name => {
            const updated = translateModuleVarNames(name, formName)
            formValues[updated] = translatedValues[name]
            delete translatedValues[name]
          })
        }
        formValues['record_id'] = component.annotation.recordId
        formValues['redcap_repeat_instance'] =
          component.annotation.repeatInstance
        formValues['redcap_event_name'] = component.annotation.event
        allForms[component.annotation.instrument_name] = formValues
      }
    })
  allForms['rootForm'] = translatedValues
  return allForms
}

export function getVariableNamesFromMeta(
  metaData: FormDynamicComponentProps[]
): string[] {
  const names = []
  metaData?.forEach(component => {
    switch (component.field_type) {
      case FieldTypes.CHECKBOX: {
        component?.options?.forEach(option => {
          names.push(makeCheckBoxOptionNameRedcap(component.name, option))
        })
        break
      }
      case FieldTypes.CHECKBOX_MATRIX:
      case FieldTypes.RADIO_MATRIX:
        component.subComponents.forEach(subComponent =>
          names.push(subComponent.name)
        )
        break
      case FieldTypes.FIELD_TERMIN:
      case FieldTypes.FIELD_AUFGABE:
      case FieldTypes.FIELD_TERMIN_AUFGABE:
      case FieldTypes.FIELD_INSTRUMENT:
      case FieldTypes.DESCRIPTIVE:
        break
      default:
        names.push(component.name)
    }
  })
  return names
}

export function getPrevValueFromProps(
  props: FormDynamicComponentProps,
  previousValues: Record<string, unknown>
): Record<string, unknown> {
  const { name, field_type } = props

  if (!previousValues) return null
  if (fieldsToIgnoreForVariables.indexOf(field_type) !== -1) return null

  let value: Record<string, unknown>
  switch (field_type) {
    case FieldTypes.RADIO_MATRIX:
    case FieldTypes.CHECKBOX_MATRIX:
      value = getPrevValueMatrix(props.subComponents, previousValues)
      break
    default: {
      value = previousValues[name] ? { [name]: previousValues[name] } : null
    }
  }

  return value
}

export function getPrevValueText(props: FormDynamicComponentProps): string {
  const { name, field_type, options, previousValues } = props
  if (!previousValues) return null
  if (fieldsToIgnoreForVariables.indexOf(field_type) !== -1) return null
  switch (field_type) {
    case FieldTypes.CHECKBOX:
      return getPrevValueCheckboxText(name, options, previousValues)
    case FieldTypes.RADIO_MATRIX:
    case FieldTypes.CHECKBOX_MATRIX:
      return getPrevValueMatrixText(props)
    case FieldTypes.RADIO:
    case FieldTypes.DROPDOWN:
    case FieldTypes.YESNO:
      return getPrevValueRadioLikeText(name, options, previousValues)
    case FieldTypes.TEXT:
    case FieldTypes.NOTES:
    case FieldTypes.DATEPICKER:
    case FieldTypes.SLIDER:
    default:
      return previousValues[name] ? (previousValues[name] as string) : null
  }
}

export function getPrevValueRadioLikeText(
  name: string,
  options: Array<FormOption>,
  previousValues: Record<string, unknown>
): string {
  const value = previousValues[name]
  if (!value) return null
  const option = options.find(obj => obj.value === value)
  if (option) return option.label
  return null
}

export function getPrevValueCheckboxText(
  name: string,
  options: Array<FormOption>,
  previousValues: Record<string, unknown>
): string {
  const value = {}
  options.forEach(option => {
    if (previousValues[name]?.[option.value]) value[option.value] = option.label
    else if (previousValues[option.value]) value[option.value] = option.label
  })
  const result = Object.values(value).join(', ')
  return result ? result : null
}

export function getPrevValueMatrix(
  subComponents: FormDynamicComponentProps[],
  previousValues: Record<string, unknown>
): Record<string, unknown> {
  const values = subComponents.map(({ name }) => {
    return { [name]: previousValues[name] }
  })
  return Object.assign.apply({}, values)
}

export function getPrevValueMatrixText({
  subComponents,
  previousValues,
}: FormDynamicComponentProps): string {
  const values = {}
  subComponents.forEach(({ field_type, name, options }, index) => {
    if (field_type === FieldTypes.RADIO)
      values[index] = getPrevValueRadioLikeText(name, options, previousValues)
    if (field_type === FieldTypes.CHECKBOX)
      values[index] = getPrevValueCheckboxText(name, options, previousValues)
  })
  const result = Object.values(values)
    .filter(it => it)
    .join(', ')
  return result ? 'Alle übernehmen: ' + result : null
}

export const generateRepeatedComponents = (
  metaData: FormDynamicComponentProps[],
  sharedValues: Record<string, unknown>
): FormDynamicComponentProps[][] => {
  // Find the repeating components, that are marked with the REPEAT_PREFIX-prefix
  // and make an Object from a regex grouping

  // Determine special values and parse there name to parts
  // and sort
  const repeatedValuesRegexGroups = Object.keys(sharedValues)
    .map(key => {
      if (key.startsWith(REPEAT_PREFIX)) {
        const match = REPEAT_REGEX.exec(key)
        if (match && match.groups) {
          return match.groups
        }
      }
      return null
    })
    .filter(x => x)
    .sort(
      (group1, group2) =>
        // sort by the repetition number
        parseInt(group1.i) - parseInt(group2.i)
    )

  // Find original components, that should be filled with these values
  const repeatedComponents = metaData.filter(component =>
    repeatedValuesRegexGroups.some(group => group.name === component.name)
  )

  // Make new components from these components, that have a name fitting to
  // the values, that were found to be repeating
  // This is for checkboxes, to rule out the multiple values
  const repeatingComponentsGroupMetaData = _(repeatedValuesRegexGroups)
    .groupBy(regexGroup => regexGroup.db_name)
    .map((regexGroupings, db_name) => ({
      ...repeatedComponents.find(
        component => regexGroupings[0].name === component.name
      ),
      i: regexGroupings[0].i,
      name: db_name,
    }))
    .value()

  // Group then to the blocks, that were repeated
  return Object.values(
    nest(repeatingComponentsGroupMetaData, ['i'])
  ) as FormDynamicComponentProps[][]
}

export function createFieldFunctionNewEvents(
  state: Record<string, unknown>,
  allMeta: Record<string, FormDynamicComponentProps>
): FieldFunctionNewEvent[] {
  /***
   * given create event annotations, evaluate checkbox choices/values for submit listener
   * "createEvent" annotation can be run within form (condition 1), or within modules (condition 2)
   */
  const createEventAnnotations: FormDynamicComponentProps[] = []
  if (!Object.keys(allMeta)?.length) return null
  Object.entries(allMeta).forEach(entry => {
    const field = entry[1]

    const withinFormCondition =
      field?.annotation?.fieldFunction === 'createEvent'

    const modulesCondition =
      field?.annotation?.fieldFunction === FieldTypes.FIELD_INSTRUMENT &&
      field?.subComponents

    if (withinFormCondition || modulesCondition) {
      if (withinFormCondition) {
        let includeField = false
        const branchingLogic = field.branchingLogic
        if (!branchingLogic) includeField = true
        if (branchingLogic && state) {
          const showed = evaluate(branchingLogic, state, allMeta)
          if (showed) includeField = true
        }
        if (includeField) {
          const included = createEventAnnotations.find(
            value => value.name === field.name
          )
          if (!included) createEventAnnotations.push(field)
        }
      }

      if (modulesCondition) {
        field.subComponents.forEach(subField => {
          const withinSubField =
            subField?.annotation?.fieldFunction === 'createEvent'

          let includeField = false
          if (withinSubField) {
            const branchingLogic = field.branchingLogic
            if (!branchingLogic) includeField = true
            if (branchingLogic && state) {
              let showed: boolean
              try {
                showed = evaluate(branchingLogic, state, allMeta)
              } catch (e) {
                showed = false
              }
              if (showed) includeField = true
            }
          }

          if (withinSubField && includeField) {
            const included = createEventAnnotations.find(
              value => value.name === subField.name
            )
            if (!included) createEventAnnotations.push(subField)
          }
        })
      }
    }
  })

  let newEventData: NewEventData[] = []
  if (createEventAnnotations.length > 0) {
    newEventData = createEventAnnotations.map(eventAnnotation => {
      const { event, fieldName, category, fieldValues } =
        eventAnnotation?.annotation

      const checkboxChoices = new Map<string, string>()

      fieldValues.forEach(value => {
        checkboxChoices[value.choice] = value.value
      })

      return {
        event: event,
        field: fieldName,
        formOrigin: eventAnnotation?.formName,
        choices: checkboxChoices,
        category: category,
        fromModule: !!eventAnnotation?.moduleName,
      }
    })
  } else {
    return null
  }

  /***
   * evaluates and returns the forms to be created from the checkbox answers
   */
  const newForms: FieldFunctionNewEvent[] = []
  if (newEventData && newEventData.length > 0) {
    newEventData.forEach(newEvent => {
      const field = newEvent?.field
      const choices = newEvent?.choices
      const event = newEvent?.event
      const form = newEvent?.formOrigin
      let fieldName: string
      if (newEvent.fromModule) {
        fieldName = createModuleVarNames(field, form)
      } else {
        fieldName = field
      }
      const fieldValue = state[fieldName]

      const forms: string[] = []
      if (fieldValue !== null && fieldValue !== undefined) {
        Object.entries(fieldValue).forEach(key => {
          if (key[1] === true) {
            const form = choices[key[0]]
            if (form) {
              forms.push(form)
            }
          }
        })
        const newForm = {
          event: event,
          forms: forms,
          category: newEvent?.category,
          fieldOrigin: field,
        } as FieldFunctionNewEvent

        newForms.push(newForm)
      }
    })
  }

  return newForms
}

export function isNullUndefinedOrCheckboxWithNullValues(
  variable: unknown,
  field_type: string,
  options: FormOption[]
): boolean {
  return (
    variable === null ||
    variable === undefined ||
    isCheckboxWithNullValues(variable, field_type, options)
  )
}

export function isCheckboxWithNullValues(
  variable: unknown,
  field_type: string,
  options: FormOption[]
): boolean {
  if (field_type !== FieldTypes.CHECKBOX) return false
  return options
    .map(option => {
      return (
        variable[option.value] === null || variable[option.value] === undefined
      )
    })
    .reduce((previousValue, currentValue) => {
      return previousValue || currentValue
    })
}

export function calcExtraInfos(
  components: FormDynamicComponentProps[]
): Record<string, FormDynamicComponentProps> {
  const newComponents = cleanMetaData(components)
  const newExtraInfos = {}
  newComponents.forEach(component => {
    newExtraInfos[component.name] = {
      field_type: component.field_type,
      options: component.options,
    }
    if (component.subComponents) {
      Object.assign(newExtraInfos, calcExtraInfos(component.subComponents))
    }
  })
  return newExtraInfos
}

export const createEventHeadline = (
  eventTitle: string,
  eventDate: Date,
  person: Person
): JSX.Element => {
  const title = `${eventTitle || ''} ${
    isValidDate(eventDate) ? ` vom ${formatDate(eventDate)} ` : ''
  }`
  return (
    <>
      {getFullName(person)}: <b>{title}</b>
    </>
  )
}

export const translateModuleVarNames = (
  varName: string,
  formName: string
): string => {
  const prefix = `${MODULE_PREFIX}_${formName}_`
  if (varName.startsWith(prefix)) {
    return varName.replace(prefix, '')
  } else {
    return varName
  }
}

export function createModuleVarNames(
  varName: string,
  formName: string
): string {
  const prefix = `${MODULE_PREFIX}_${formName}_`
  return prefix + varName
}

export function getAllFields(metaData: FormDynamicComponentProps[]): string[] {
  if (metaData === null || metaData === undefined) return []
  let components = []
  metaData.forEach(element => {
    switch (element.field_type) {
      case FieldTypes.FIELD_INSTRUMENT:
        components = components.concat(getAllFields(element.subComponents))
        break
      case FieldTypes.CHECKBOX_MATRIX:
      case FieldTypes.RADIO_MATRIX: {
        element.subComponents.forEach(row => {
          components.push(row)
        })
        break
      }
      default:
        components.push(element)
        break
    }
  })
  return components.map(value => value.name)
}

export function extractMetaData(
  formData: DynamicForm[]
): FormDynamicComponentProps[] {
  let meta: FormDynamicComponentProps[] = []
  if (formData === null || formData === undefined) return meta
  try {
    meta = formData
      .map(value => {
        if (value && value.data && value.data.redcapForm) {
          return value.data.redcapForm
        } else {
          throw new Error('Illegal component in forms: No value or data object')
        }
      })
      .flat(1)
  } catch (e) {
    const previousComponent = meta[meta?.length - 1]
    const fieldName = previousComponent?.name
      ? previousComponent.name
      : 'unbekannt. Fehler liegt vermutlich in der ersten Komponente'
    const formName = previousComponent?.formName
      ? previousComponent.formName
      : formData[0].name

    // send previous component field name and form name to medical admin
    handleNullComponentError(e, fieldName, formName)
    throw e
  }
  return meta
}

export function addSubComponentsWithOriginals(
  metaData: FormDynamicComponentProps[]
): FormDynamicComponentProps[] {
  if (metaData === null || metaData === undefined) return []
  let metaWithSubcomponents = []
  metaData.forEach(element => {
    switch (element.field_type) {
      case FieldTypes.FIELD_INSTRUMENT:
        metaWithSubcomponents.push(element)
        metaWithSubcomponents = metaWithSubcomponents.concat(
          addSubComponents(element.subComponents)
        )
        break
      case FieldTypes.CHECKBOX_MATRIX:
      case FieldTypes.RADIO_MATRIX: {
        element.subComponents.forEach(row => {
          metaWithSubcomponents.push(row)
        })
        break
      }
      default:
        metaWithSubcomponents.push(element)
        break
    }
  })
  return metaWithSubcomponents
}

export function extractAllMetaWithSubComponents(
  formData: DynamicForm[]
): FormDynamicComponentProps[] {
  try {
    const meta = extractMetaData(formData)
    return addSubComponentsWithOriginals(meta)
  } catch (e) {
    return []
  }
}

export function extractMetaToNameMap(
  allComponents: FormDynamicComponentProps[]
): Record<string, FormDynamicComponentProps> {
  let nameToFormDynamicComponentProps = {}
  let currentIndex: number
  if (!isArray(allComponents) || allComponents.length === 0)
    return nameToFormDynamicComponentProps
  try {
    allComponents.forEach((component, index) => {
      currentIndex = index
      nameToFormDynamicComponentProps[component.name] = component
      nameToFormDynamicComponentProps = {
        ...nameToFormDynamicComponentProps,
        ...extractMetaToNameMap(component.subComponents),
      }
    })
  } catch (e) {
    const previousComponent = allComponents[currentIndex - 1]
    const fieldName = previousComponent?.name
      ? previousComponent.name
      : 'unbekannt. Fehler liegt vermutlich in der ersten Komponente'
    const formName = previousComponent?.formName
      ? previousComponent.formName
      : allComponents[0]?.formName
      ? allComponents[0]?.formName
      : allComponents[0]?.eventName
      ? allComponents[0]?.eventName
      : 'unbekannt'

    // send previous component field name and form name to medical admin
    handleNullComponentError(e, fieldName, formName)
    throw e
  }
  return nameToFormDynamicComponentProps
}

export function transformToNameMap(
  formData: DynamicForm[]
): Record<string, FormDynamicComponentProps> {
  return extractMetaToNameMap(extractMetaData(formData))
}

export function getSameModules(
  meta: FormDynamicComponentProps[],
  moduleName: string
): FormDynamicComponentProps[] {
  let sameModules: FormDynamicComponentProps[] = []
  if (meta === null || meta === undefined) return sameModules
  sameModules = meta.filter(value => {
    return (
      value?.field_type === FieldTypes.FIELD_INSTRUMENT &&
      value?.annotation?.instrument_name === moduleName
    )
  })
  return sameModules
}

export function allCheckboxValuesAreFalse(
  checkboxValue: Record<string, unknown>
): boolean {
  let allCheckboxValuesAreFalls = false
  const falseFields = Object.keys(checkboxValue).filter(key => {
    return checkboxValue[key] === false
  })
  if (falseFields?.length === Object.keys(checkboxValue)?.length) {
    allCheckboxValuesAreFalls = true
  }
  return allCheckboxValuesAreFalls
}

function isInLastModules(
  component: FormDynamicComponentProps,
  lastModules: LastModule[]
): boolean {
  const lastModule: LastModule = {
    formName: component.formName,
    eventName: component.eventName,
    moduleName: component.moduleName,
    moduleEvent: null,
  }
  const result = false
  if (lastModule.moduleName === null) return false
  const foundModule = lastModules.filter(
    module =>
      module.formName === lastModule.formName &&
      module.eventName === lastModule.eventName &&
      module.moduleName === lastModule.moduleName
  )
  if (foundModule.length > 0) return true
  return result
}

/**
 * Gets all variable names of the components in meta
 * @param meta
 * @returns
 */
// ToDo add generateRepeatedComponents
export function getVariableNamesWithSubcomponents(
  meta: FormDynamicComponentProps[]
): string[] {
  const newMeta = addSubComponents(meta)
  const variableNames = newMeta
    .filter(component => componentsWithVariable.includes(component.field_type))
    .map(component => component.name)
  return variableNames
}

/**
 * Sets values in variableNames to null depending on the component type
 * @param variableNames
 * @param values
 * @param allMeta
 */
export function clearValues(
  variableNames: string[],
  values: Record<string, unknown>,
  allMeta: Record<string, FormDynamicComponentProps>
): void {
  variableNames.forEach(name => {
    if (values[name] !== undefined) {
      const component = allMeta[name]
      const fieldType = component?.field_type
      switch (fieldType) {
        case null:
          throw Error('clearValues failed because of null type!')
        case FieldTypes.CHECKBOX: {
          const newCheckboxValue = {}
          component?.options.forEach(option => {
            newCheckboxValue[option.value] = null
          })
          values[name] = newCheckboxValue
          break
        }
        default:
          values[name] = null
      }
    }
  })
}

export function setSubValues(
  subValues: Record<string, unknown>,
  values: Record<string, unknown>
): void {
  Object.assign(values, subValues)
}

export function noWriteErrorMessage(): void {
  NotificationManager.error(
    'Sie haben keine Berechtigungen zum Ändern des Formulars.'
  )
}

export function isSignedEvent(status: string): boolean {
  return status === '2' || status === 'COMPLETE'
}
