import React, { useContext, useEffect, useState } from 'react'
import { parseHTML } from '../../../utils/FormUtils'
import {
  fillVariablesWithOldValues,
  getVariablesFromText,
  insertVariablesInText,
} from '../../../utils/RedcapVariableUtils'
import { NoVariableOfThisNameError } from '../../../errors/VariableError'
import { EventStore } from '../../../infrastructure/EventProviderInterfaces'

interface VariableInserterProps {
  text: string
}

const VariableInserter: React.FC<VariableInserterProps> = ({
  text,
}: VariableInserterProps) => {
  const [oldText, setOldText] = useState<string>()
  const [result, setResult] = useState<JSX.Element>(<>{text}</>)
  const [variables, setVariables] = useState<Record<string, unknown>>()
  const [error, setError] = useState<boolean>(false)
  const { values, allMeta } = useContext(EventStore)

  /**
   * parses Variables in the text and saves them
   * for comparison with changed values for efficiency
   */
  useEffect(() => {
    if (oldText === text || error) return
    try {
      const variablesInText = getVariablesFromText(
        text,
        values as Record<string, unknown>
      )
      setOldText(text)
      setVariables(variablesInText)
    } catch (e) {
      if (e instanceof NoVariableOfThisNameError) {
        setError(true)
        setResult(parseHTML(`<span style="color:red">${e.message}</span>`))
      } else {
        throw e
      }
    }
  }, [oldText, text, result, values, error])

  /**
   * replace the Variables with the corresponding value in the text
   */
  useEffect(() => {
    if (error) return
    try {
      const [newResult, changed] = insertVariablesInText(
        text,
        variables,
        values as Record<string, unknown>,
        allMeta
      )
      if (changed) {
        setResult(parseHTML(newResult))
        setVariables(
          fillVariablesWithOldValues(
            variables,
            values as Record<string, unknown>
          )
        )
      }
    } catch (e) {
      if (e instanceof NoVariableOfThisNameError) {
        setError(true)
        setResult(parseHTML(`<span style="color:red">${e.message}</span>`))
      } else {
        throw e
      }
    }
  }, [values, text, variables, result, error, allMeta])

  return result
}

export default VariableInserter
