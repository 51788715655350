import {
  ExtraVariableInfos,
  matchRedcapVariable,
  SmartVariablesInfo,
} from '../components/Forms/FormInterfaces'
import { NoVariableOfThisNameError } from '../errors/VariableError'
import { FormOption } from '../types/FormOption'
import { getSmartVariableData, transformExpression } from './CalcParseUtils'
import { FieldTypes } from '../constants/FieldTypes'
import { isEqual } from 'lodash'

export const variablenRegexRedcap =
  /(?:\[(?<event>[A-Za-z0-9_]+)\])?(?:\[(?<fieldName>[A-Za-z][A-Za-z0-9_]*)(?:\((?<checkboxNumber>[0-9]+)\))?(?::(?<check>(?:checked)|(?:unchecked)))?(?::(?<value>value))?\])(?:\[(?<instance>(?:current-instance)|(?:previous-instance)|(?:first-instance)|(?:last-instance)|(?:\d+))\])?/
export const variablenRegexRedcapGlobal = new RegExp(
  variablenRegexRedcap.source,
  'g'
)

/**
 * This function shall insert the value of the variable in the right format.
 * It should also return, if there are changes to the last time it was run
 * and give an errormessage if something went wrong
 * @param text
 * @param variables
 * @param contextValues
 * @param defaultExtraInfos
 * @returns
 */
export function insertVariablesInText(
  text: string,
  variables: Record<string, unknown>,
  contextValues: Record<string, unknown>,
  defaultExtraInfos: Record<string, ExtraVariableInfos>
): [string, boolean] {
  if (!valuesChanged(variables, contextValues)) return [text, false]
  return [
    insertVariablesInString(text, [], contextValues, defaultExtraInfos),
    true,
  ]
}

/**
 * Saves values from valuesNew in a new Record where only the keys of valuesOld are used
 * @param valuesOld
 * @param valuesNew
 * @returns
 */
export function fillVariablesWithOldValues(
  valuesOld: Record<string, unknown>,
  valuesNew: Record<string, unknown>
): Record<string, unknown> {
  const result = {}
  for (const key in valuesOld) {
    if (Object.prototype.hasOwnProperty.call(valuesOld, key))
      result[key] = valuesNew[key]
  }
  return result
}

/**
 * Checks if the values in valuesOld are the same as in valuesNew.
 * All values not in valuesOld are ignored for the comparison.
 * @param valuesOld
 * @param valuesNew
 * @returns
 */
export function valuesChanged(
  valuesOld: Record<string, unknown>,
  valuesNew: Record<string, unknown>
): boolean {
  for (const key in valuesOld) {
    if (
      Object.prototype.hasOwnProperty.call(valuesOld, key) &&
      !isEqual(valuesOld[key], valuesNew[key])
    )
      return true
  }
  return false
}

/**
 * Parses variables from the given text and initializes them to null.
 * @param text
 * @param values
 * @returns {Record<string, unknown>} a dictionary of variables and their values
 */
export function getVariablesFromText(
  text: string,
  values: Record<string, unknown>
): Record<string, unknown> {
  const variables = {}
  let matches: RegExpExecArray
  while ((matches = variablenRegexRedcapGlobal.exec(text)) !== null) {
    const fieldName = matches[2]
    if (
      fieldName &&
      values &&
      Object.prototype.hasOwnProperty.call(values, fieldName)
    ) {
      variables[fieldName] = undefined
    } else {
      throw new NoVariableOfThisNameError(
        `Die Variable ${fieldName} ist nicht vorhanden.`,
        { text: text, values: values }
      )
    }
  }

  return variables
}

/**
 * Replaces variables in strings with the specified value.
 * Mostly in Label and Header components.
 * @param str
 * @param smartVariables
 * @param values
 * @param extraInfosComponents
 * @returns
 */
export function insertVariablesInString(
  str: string,
  smartVariables: SmartVariablesInfo[],
  values?: Record<string, unknown>,
  extraInfosComponents?: Record<string, ExtraVariableInfos>
): string {
  let correctedString = str
  let match
  while ((match = correctedString.match(variablenRegexRedcap))) {
    let value
    if (
      match.groups.event === undefined &&
      match.groups.instance === undefined
    ) {
      value = getFormValueForString(match.groups, values, extraInfosComponents)
    } else {
      value = getSmartVariableData(match.groups, smartVariables)
    }
    if (!value) value = '_____'
    correctedString =
      correctedString.substring(0, match.index) +
      value +
      correctedString.substring(match.index + match[0].length)
  }
  return correctedString
}

/**
 * Checks if a given string contains redcap variables.
 * @param text
 * @returns
 */
export function hasVariables(text: string): boolean {
  const match = text.match(variablenRegexRedcap)
  if (match?.groups?.fieldName) return true
  return false
}

/**
 * Returns all checked options of a checkbox
 * @param checkbox
 * @param options
 * @returns
 */
export function getChecked(
  checkbox: Record<string, boolean>,
  options: FormOption[]
): FormOption[] {
  return options.filter(option => {
    return checkbox[option.value]
  })
}

/**
 * Returns all unchecked options of a checkbox
 * @param checkbox
 * @param options
 * @returns
 */
export function getUnchecked(
  checkbox: Record<string, boolean>,
  options: FormOption[]
): FormOption[] {
  return options.filter(option => {
    return !checkbox[option.value]
  })
}

/**
 * Concatenates all labels of a given set of options with a comma
 * @param options
 * @returns
 */
export function getLabels(options: FormOption[]): string {
  return options
    .map(option => {
      return option.label
    })
    .join(',')
}

/**
 * Concatenates all values of a given set of options with a comma
 * @param options
 * @returns
 */
export function getValues(options: FormOption[]): string {
  return options
    .map(option => {
      return option.value
    })
    .join(',')
}

/**
 * for calculations
 * The special functions only utilize the value of a field, never its label.
 * @param matchedGroups
 * @param values
 * @param extraInfosComponents
 * @returns
 */
export function getFormValue(
  matchedGroups: matchRedcapVariable,
  values: Record<string, unknown>,
  extraInfosComponents?: Record<string, ExtraVariableInfos>
): string {
  if (
    matchedGroups.event === undefined &&
    matchedGroups.instance === undefined &&
    matchedGroups.fieldName !== undefined
  ) {
    const fieldName = matchedGroups.fieldName
    const infos = extraInfosComponents[fieldName]

    if (!infos) {
      return values[fieldName] as string
      // throw new Error(
      //   `No extra infos components provided for field name = ${fieldName}. Using default value.`
      // )
    }

    switch (infos.field_type) {
      case FieldTypes.CHECKBOX: {
        if (matchedGroups.check) {
          switch (matchedGroups.check) {
            case 'checked':
              if (matchedGroups.value)
                return getValues(
                  getChecked(
                    values[fieldName] as Record<string, boolean>,
                    infos.options
                  )
                )
              return getLabels(
                getChecked(
                  values[fieldName] as Record<string, boolean>,
                  infos.options
                )
              )
            case 'unchecked':
              if (matchedGroups.value)
                return getValues(
                  getUnchecked(
                    values[fieldName] as Record<string, boolean>,
                    infos.options
                  )
                )
              return getLabels(
                getUnchecked(
                  values[fieldName] as Record<string, boolean>,
                  infos.options
                )
              )
            default:
              throw new Error('Checked or unchecked expression is wrong!')
          }
        }
        if (matchedGroups.checkboxNumber) {
          return values[fieldName][matchedGroups.checkboxNumber]
        }
        // this case could be unnecessary, we will make get the checked values the default.
        return getValues(
          getChecked(
            values[fieldName] as Record<string, boolean>,
            infos.options
          )
        )
      }
      case FieldTypes.DROPDOWN:
      case FieldTypes.RADIO: // here we always return the value
      case FieldTypes.TEXT:
      case FieldTypes.DATEPICKER:
      case FieldTypes.YESNO:
      case FieldTypes.NOTES:
      case FieldTypes.SLIDER:
      default:
        return values[fieldName] as string
    }
  }
}

/**
 * for default
 * @param matchedGroups
 * @param values
 * @param extraInfosComponents
 * @returns
 */
export function getFormValueForString(
  matchedGroups: matchRedcapVariable,
  values: Record<string, unknown>,
  extraInfosComponents?: Record<string, ExtraVariableInfos>
): string {
  if (
    matchedGroups.event === undefined &&
    matchedGroups.instance === undefined &&
    matchedGroups.fieldName !== undefined
  ) {
    const fieldName = matchedGroups.fieldName
    const infos = extraInfosComponents[fieldName]

    if (!infos) {
      return values[fieldName] as string
      // throw new Error(
      //   `No extra infos components provided for field name = ${fieldName}. Using default value.`
      // )
    }

    switch (infos.field_type) {
      case FieldTypes.CHECKBOX: {
        if (matchedGroups.check) {
          switch (matchedGroups.check) {
            case 'checked':
              if (matchedGroups.value)
                return getValues(
                  getChecked(
                    values[fieldName] as Record<string, boolean>,
                    infos.options
                  )
                )
              return getLabels(
                getChecked(
                  values[fieldName] as Record<string, boolean>,
                  infos.options
                )
              )
            case 'unchecked':
              if (matchedGroups.value)
                return getValues(
                  getUnchecked(
                    values[fieldName] as Record<string, boolean>,
                    infos.options
                  )
                )
              return getLabels(
                getUnchecked(
                  values[fieldName] as Record<string, boolean>,
                  infos.options
                )
              )
            default:
              throw new Error('Checked or unchecked expression is wrong!')
          }
        }
        if (matchedGroups.checkboxNumber) {
          return values[fieldName][matchedGroups.checkboxNumber]
        }
        // this case could be unnecessary, we will make get the checked values the default.
        return getLabels(
          getChecked(
            values[fieldName] as Record<string, boolean>,
            infos.options
          )
        )
      }
      case FieldTypes.DROPDOWN:
      case FieldTypes.YESNO:
      case FieldTypes.RADIO:
        if (matchedGroups.value) {
          if (matchedGroups.check) {
            console.error(
              matchedGroups,
              'A radio has no list of checked values. Remove :checked!  '
            )
            return ('A radio has no list of checked values. Remove :checked! ' +
              values[fieldName]) as string
          }

          return values[fieldName] as string
        } else {
          const option = infos.options.find(option => {
            return option.value === values[fieldName]
          })
          return option ? option.label : ''
        }
      case FieldTypes.TEXT:
      case FieldTypes.DATEPICKER:
      case FieldTypes.NOTES:
      case FieldTypes.SLIDER:
      default:
        return values[fieldName] as string
    }
  }
}

export function insertVariablesCalc(
  str: string,
  smartVariables: SmartVariablesInfo[],
  values?: Record<string, unknown>,
  extraInfosComponents?: Record<string, ExtraVariableInfos>
): string {
  let correctedString = transformExpression(str)
  let match
  while ((match = correctedString.match(variablenRegexRedcap))) {
    let value
    if (
      match.groups.event === undefined &&
      match.groups.instance === undefined
    ) {
      try {
        value = getFormValue(match.groups, values, extraInfosComponents)
      } catch (e) {
        value = handleUndefinedInfosError(e)
      }
    } else {
      value = getSmartVariableData(match.groups, smartVariables)
    }
    const valueToInsert =
      value !== null && value !== undefined
        ? typeof value === 'string'
          ? "'" + value + "'"
          : value
        : 'NULL()'
    correctedString =
      correctedString.substring(0, match.index) +
      valueToInsert +
      correctedString.substring(match.index + match[0].length)
  }
  return correctedString
}

export function insertVariablesBranching(
  str: string,
  values?: Record<string, unknown>,
  extraInfosComponents?: Record<string, ExtraVariableInfos>
): string {
  let correctedString = transformExpression(str)
  let match
  while ((match = correctedString.match(variablenRegexRedcap))) {
    let value
    if (
      match.groups.event === undefined &&
      match.groups.instance === undefined
    ) {
      try {
        value = getFormValue(match.groups, values, extraInfosComponents)
      } catch (e) {
        value = handleUndefinedInfosError(e)
      }
    }
    let valueToInsert =
      value !== null && value !== undefined
        ? typeof value === 'string'
          ? ` '${value}' `
          : ` ${value} `
        : " '' "
    // extra spaces so that or[text] can be parsed in mathjs
    valueToInsert = ` ${valueToInsert} `
    correctedString =
      correctedString.substring(0, match.index) +
      valueToInsert +
      correctedString.substring(match.index + match[0].length)
  }
  return correctedString
}

export function insertVariablesDefault(
  str: unknown,
  values?: Record<string, unknown>,
  extraInfosComponents?: Record<string, ExtraVariableInfos>
): string {
  if (!str) return null
  const correctedString = str.toString()
  const match = correctedString.match(variablenRegexRedcap)
  if (!match) return correctedString
  let value
  if (match.groups.event === undefined && match.groups.instance === undefined) {
    try {
      value = getFormValue(match.groups, values, extraInfosComponents)
    } catch (e) {
      value = handleUndefinedInfosError(e)
    }
  }

  return value
}

function handleUndefinedInfosError(e: Error) {
  console.error(`${e.name}: ${e.message}`)
  return ''
}
