import { DraftKind } from './DraftKind'

const DraftIdRegex =
  /(?<tempId>[\w\d-]+)?:(?<documentId>(?<kind>\w+)\/(?<partId>[\d\w-]+)?)?/

export class DraftIdInfo {
  draftId: string // whole id, that combines all the others
  tempId: string // id that will be generated, if it has no id (before coming to redcap or fhir)
  documentId: string // "ResourceType/123" or "RedCap/12-234-23"
  partId: string // "123"
  kind: DraftKind

  constructor(draftId: string) {
    if (draftId) {
      const match = draftId.match(DraftIdRegex).groups

      this.documentId = match.documentId
      this.partId = match.partId
      this.kind = DraftKind[match.kind]
      this.tempId = match.tempId
      this.draftId = draftId

      if (!this.documentId && !this.tempId) {
        console.error(
          'Draft needs to have at least either tempId or a documentId',
          draftId
        )
      }
    }
  }
}
