import ApiServiceBase from './ApiServiceBase'
import { API_APPOINTMENTS } from '../config/Paths'
import { ApiResponse } from '../types/ApiResponse'
import { ApiError } from '../types/ApiError'
import { Appointment, AppointmentDto } from '../types/Appointment'
import { AppointmentFilterParams } from '../types/AppointmentFilterParams'
import { APIResult } from '../constants/APIResult'
import { getIdPartFromFhirResourceId, parseUnknownToDate } from '../utils/Utils'
import { PanosInstitution } from '../constants/PanosInstitutions'

export function verifyCastAppointment(appointment: Appointment): Appointment {
  if (appointment) {
    return {
      ...appointment,
      startingDateTime: appointment.startingDateTime
        ? parseUnknownToDate(appointment.startingDateTime)
        : appointment.startingDateTime,
    }
  }
}

class AppointmentService extends ApiServiceBase {
  convertDtoToAppointment = (appDto: AppointmentDto): Appointment => {
    const { location, ...strippedAppointment } = appDto
    const appointment = {
      ...strippedAppointment,
    } as Appointment

    if (location) {
      appointment.location = {
        id: getIdPartFromFhirResourceId(appDto.location),
        name: appDto.locationName,
      }
    }
    if (appDto.startingDateTime)
      appointment.startingDateTime = new Date(appDto.startingDateTime)
    return appointment
  }

  convertAppointmentToDto = (appointment: Appointment): AppointmentDto => {
    const { tempId, location, ...strippedAppointment } = appointment
    const newAppointment = {
      ...strippedAppointment,
    } as AppointmentDto
    if (location) {
      newAppointment.location = 'Location/' + location.id
      newAppointment.locationName = location.name
    }

    return newAppointment
  }

  handleResponse = <T>(
    res: ApiResponse<ApiError | AppointmentDto | AppointmentDto[]>
  ): ApiResponse<ApiError | T> => {
    if (res.Result === APIResult.SUCCESS && res.Response) {
      let data
      if (Array.isArray(res.Response)) {
        data = res.Response.map((appointment: AppointmentDto) =>
          this.convertDtoToAppointment(appointment)
        )
      } else {
        data = this.convertDtoToAppointment(res.Response as AppointmentDto)
      }
      return {
        Result: res.Result,
        Response: data,
      }
    }
    return {
      Result: res.Result,
      Response: res.Response as ApiError,
    }
  }

  getAppointments(
    filterParams: AppointmentFilterParams = null,
    quickFilterParams: PanosInstitution[] = null
  ): Promise<ApiResponse<Appointment[] | ApiError>> {
    const url = new URL(API_APPOINTMENTS)

    if (filterParams) {
      if (filterParams.role) {
        url.searchParams.append('role', filterParams.role.toString())
      }
      if (filterParams.status) {
        url.searchParams.append('status', filterParams.status.toString())
      }
      if (filterParams.userId) {
        url.searchParams.append('participants', filterParams.userId)
      }
      if (filterParams.patientId) {
        url.searchParams.append('participants', filterParams.patientId)
      }
      if (filterParams.practitionerId) {
        url.searchParams.append('participants', filterParams.practitionerId)
      }
      if (filterParams.location) {
        url.searchParams.append(
          'appointmentLocation',
          filterParams.location.name
        )
      }
      if (filterParams.organization) {
        url.searchParams.append(
          'practitionerOrganization',
          filterParams.organization.id
        )
      }
      if (filterParams.includeOwn !== undefined) {
        url.searchParams.append(
          'includeOwn',
          filterParams.includeOwn ? 'true' : 'false'
        )
      }
    }

    if (quickFilterParams) {
      for (const institution of quickFilterParams) {
        console.log(institution)
        url.searchParams.append('includePanosPhysiciansFrom', institution)
      }
    }

    return this.get<null, AppointmentDto[]>(url).then(res =>
      this.handleResponse<Appointment[]>(res)
    )
  }

  getAppointment(
    appointmentId: string
  ): Promise<ApiResponse<Appointment | ApiError>> {
    return this.get<null, AppointmentDto>(
      new URL(API_APPOINTMENTS + `/${appointmentId}`)
    ).then(res => this.handleResponse<Appointment>(res))
  }

  postAppointment(
    appointment: Appointment
  ): Promise<ApiResponse<Appointment | ApiError>> {
    const _appointment = this.convertAppointmentToDto(appointment)

    return this.post<AppointmentDto, AppointmentDto>(
      new URL(API_APPOINTMENTS + '/create'),
      null,
      _appointment
    ).then(res => this.handleResponse<Appointment>(res))
  }

  putAppointment(
    appointment: Appointment
  ): Promise<ApiResponse<Appointment | ApiError>> {
    const _appointment = this.convertAppointmentToDto(appointment)
    return this.put<AppointmentDto>(
      new URL(API_APPOINTMENTS + `/${appointment.id}`),
      _appointment
    ).then(res => this.handleResponse<Appointment>(res))
  }

  deleteAppointment(
    appointment: Appointment
  ): Promise<ApiResponse<string | ApiError>> {
    return this.delete<Appointment>(
      new URL(API_APPOINTMENTS + `/${appointment.id}`)
    )
  }

  putAppointmentAcceptance(
    participantId: string,
    appointmentId: string,
    status: string
  ): Promise<ApiResponse<Appointment | ApiError>> {
    return this.put<null>(
      new URL(
        API_APPOINTMENTS +
          '/respondToAppointment?participantId=' +
          participantId +
          '&appointmentId=' +
          appointmentId +
          '&status=' +
          status
      ),
      null
    )
  }
}

export default AppointmentService
