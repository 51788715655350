import React, { Component } from 'react'
import TabsComponent, { ITab } from '../UI/TabsComponent/TabsComponent'
import { AuthStore } from '../../infrastructure/AuthProvider'
import ProfileDetails from './ProfileDetails/ProfileDetails'

interface ProfileContainerState {
  activeTab: string
}

type ProfilePageProps = Record<string, unknown>

class ProfilContainer extends Component<
  ProfilePageProps,
  ProfileContainerState
> {
  tabs: ITab[] = [{ title: 'Profileinstellungen', key: 'settings' }]
  static contextType = AuthStore

  state: ProfileContainerState = {
    activeTab: 'settings',
  }

  onTabChanged = (key: string): void => {
    this.setState({ activeTab: key })
  }

  render(): JSX.Element {
    return (
      <>
        <TabsComponent
          tabs={this.tabs}
          activeTab={this.state.activeTab}
          onSelect={(key: string) => this.onTabChanged(key)}
        />

        {this.state.activeTab === 'settings' && <ProfileDetails />}
      </>
    )
  }
}

export default ProfilContainer
