import React, { useContext, useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { Appointment } from '../../types/Appointment'
import { Task } from '../../types/Task'
import OrganizerModal, { OrganizerModalType } from './OrganizerModal'
import { Links } from './FieldFunctionInterfaces'
import { FieldTerminAufgabeStore } from './FieldTerminAufgabeProvider'

export interface FieldTerminAufgabeProps extends Links {
  initialAppointments: Array<Appointment>
  initialTasks: Array<Task>
}

export interface FieldTerminAufgabeAlert {
  tempId: string
  taskTitle: string
  appointmentTitle: string
}

const FieldTerminAufgabe: React.FC<FieldTerminAufgabeProps> = ({
  links,
  initialAppointments,
  initialTasks,
}: FieldTerminAufgabeProps) => {
  const [show, setShow] = useState<boolean>(false)
  const [isCreatedAlert, setIsCreatedAlert] =
    useState<FieldTerminAufgabeAlert>(null)
  const fieldTerminAufgabeContext = useContext(FieldTerminAufgabeStore)
  const handleShow = (): void => {
    setShow(true)
  }

  const handleAbort = (): void => {
    setShow(false)
  }

  const handleEdit = (): void => {
    fieldTerminAufgabeContext.removeDraft(isCreatedAlert.tempId)
    setShow(true)
  }

  const handleSubmit = (
    taskTitle: string,
    appointmentTitle,
    tempId: string
  ): void => {
    setIsCreatedAlert({
      taskTitle: taskTitle,
      appointmentTitle: appointmentTitle,
      tempId: tempId,
    })
  }

  return (
    <div className="form-group">
      <OrganizerModal
        show={show}
        links={links}
        initialTasks={initialTasks}
        initialAppointments={initialAppointments}
        onSubmit={handleSubmit}
        onAbort={handleAbort}
        onEdit={handleEdit}
        type={OrganizerModalType.FIELD_TERMIN_AUFGABE_COMBI}
      />
      {!isCreatedAlert ? (
        <div>
          <label className="form-label">
            Zugehörige Termine und Aufgaben erstellen
          </label>
          <Alert variant={'info'}>
            <Button variant="primary" onClick={handleShow}>
              Erstellen
            </Button>{' '}
            Termin- und Aufgabenentwurf nicht erstellt
          </Alert>
        </div>
      ) : (
        <Alert variant={'success'}>
          Aufgaben: {isCreatedAlert.taskTitle}, Termin:
          {isCreatedAlert.appointmentTitle}{' '}
          <Button variant="primary" onClick={handleEdit}>
            Bearbeiten
          </Button>
        </Alert>
      )}
    </div>
  )
}

export default FieldTerminAufgabe
