import {
  Badge,
  Button,
  OverlayTrigger,
  Popover,
  Tooltip,
} from 'react-bootstrap'
import React, { useState } from 'react'
import { MedicationDetails } from './MedicationDetails'
import { hasStatus } from '../../../services/FhirResourceService'
import MaterialIcon from '../../UI/MaterialIcon/MaterialIcon'
import { MedicationRequestStatus } from '../../../constants/MedicationRequestStatus'

interface MenuButtonProps {
  onClick?: (event) => void
  disabled?: boolean
  variant?: string
  icon?: string
  color?: string
  verticalAlignment?: string
  size?: string
  className?: string
}

function MenuButtonJSX(props: MenuButtonProps): JSX.Element {
  const { verticalAlignment, ...otherProps } = props
  return (
    <Button
      {...otherProps}
      size={'sm'}
      className={'action-button-timeline ' + props.className}
      variant={props.variant ? props.variant : 'secondary'}
      onClick={props.onClick}>
      <MaterialIcon
        size={props.size || '16px'}
        icon={props.icon}
        color={props.color ? props.color : undefined}
        verticalAlignment={verticalAlignment ? verticalAlignment : 'middle'}
      />
    </Button>
  )
}

interface OverlayMenuButtonProps {
  onClick?: (event) => void
  disabled?: boolean
  variant?: string
  children?: JSX.Element | string
  icon?: string
  color?: string
  verticalAlignment?: string
  size?: string
  className?: string
}

function OverlayMenuButtonJSX(props: OverlayMenuButtonProps): JSX.Element {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={
        <Tooltip id={`tooltip-timeline-actionbutton`}>{props.children}</Tooltip>
      }>
      <MenuButton {...props} />
    </OverlayTrigger>
  )
}

interface InfoButtonProps {
  group: MedicationScheduleGroup
  variant: string
}

function InfoButtonJSX(props: InfoButtonProps): JSX.Element {
  const [medicationDetailsShow, setMedicationDetailsShow] = useState(false)
  const [target, setTarget] = useState<never>(null)

  const handleClick = event => {
    setMedicationDetailsShow(!medicationDetailsShow)
    setTarget(event.target)
  }

  return (
    <>
      <MedicationDetails
        group={props.group}
        show={medicationDetailsShow}
        setShow={setMedicationDetailsShow}
        target={target}
      />
      <OverlayTrigger
        placement={'top'}
        trigger={['hover', 'focus']}
        target={target}
        overlay={
          <Popover
            // show api is not implemented yet
            style={medicationDetailsShow ? { visibility: 'hidden' } : {}}
            id={`medication-request-info-${props.group.medicationRequest.id}`}>
            <Popover.Title as="h3">
              {props.group.medicationRequest.note
                ? props.group.medicationRequest.note[0].text
                : 'no Title'}
            </Popover.Title>
            <Popover.Content>
              <b>
                Verschreibungsstatus: {props.group.medicationRequest.status}
              </b>
              <br />
              {hasStatus(props.group.medicationRequest.status, [
                MedicationRequestStatus.on_hold,
                MedicationRequestStatus.stopped,
                MedicationRequestStatus.completed,
                MedicationRequestStatus.draft,
              ]) && (
                <div>
                  <Badge variant="warning">Warning</Badge>
                  Die Medikationsanfrage wurde geändert. Bestätigen sie die
                  Änderungen, um sie zu aktivieren.
                </div>
              )}
              Zusätzliche Informationen werden beim Anklicken angezeigt.
            </Popover.Content>
          </Popover>
        }>
        <MenuButton
          {...props}
          onClick={handleClick}
          icon="info"
          color={
            hasStatus(props.group.medicationRequest.status, [
              MedicationRequestStatus.on_hold,
              MedicationRequestStatus.stopped,
              MedicationRequestStatus.completed,
              MedicationRequestStatus.draft,
            ])
              ? 'orange'
              : 'black'
          }
        />
      </OverlayTrigger>
    </>
  )
}

function GetWarningButton(): JSX.Element {
  return (
    <OverlayMenuButtonJSX
      variant={'outline-light'}
      icon="info"
      color={'orange'}>
      Medikamentenplan noch nicht bestätigt
    </OverlayMenuButtonJSX>
  )
}

const MenuButton = React.memo(MenuButtonJSX)
const OverlayMenuButton = React.memo(OverlayMenuButtonJSX)
const InfoButton = React.memo(InfoButtonJSX)

export { GetWarningButton, MenuButton, OverlayMenuButton, InfoButton }
