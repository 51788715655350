import React, { Component } from 'react'
import REDCapService from '../../services/REDCapService'
import { MedicalInstrument } from '../Forms/FormInterfaces'
import MedicalRoundInstruments from '../Forms/MedicalRoundInstruments'
import Loader from '../UI/Spinner/Loader'
import { RedCapSaveMethods } from '../../constants/RedCapSaveMethods'
import { NotificationManager } from 'react-notifications'
import { isSuccess } from '../../utils/ApiUtils'
import { ApiResponse } from '../../types/ApiResponse'
import { ApiError } from '../../types/ApiError'
import { CREATE_NEW_EVENT_FROM_LINK } from '../../config/Constants'

interface IProps {
  event: string
  recordId: string
  patientId: string
  hash: string
  onClose?: () => void
  eventLinkIdentifier: string
}

interface IState {
  instruments: MedicalInstrument[]
}

class LinkedRedCapEvent extends Component<IProps, IState> {
  state = {
    instruments: null,
  }

  componentDidMount(): void {
    if (this.props.event && this.props.hash && this.props.patientId) {
      this.fetchForms()
    }
  }

  fetchForms = async (): Promise<void> => {
    const service = new REDCapService()
    let medicalInstrumentsResponse: ApiResponse<MedicalInstrument[] | ApiError>

    if (this.props.hash === CREATE_NEW_EVENT_FROM_LINK) {
      medicalInstrumentsResponse =
        await service.createNewMedicalInstrumentsByEvent(
          this.props.patientId,
          this.props.event
        )
    } else {
      medicalInstrumentsResponse =
        await service.getCurrentMedicalInstrumentsByHash(
          this.props.patientId,
          this.props.event,
          this.props.hash
        )
    }

    if (isSuccess(medicalInstrumentsResponse)) {
      let medicalInstruments =
        medicalInstrumentsResponse.Response as MedicalInstrument[]
      medicalInstruments = medicalInstruments.map(value => {
        value.eventLinkIdentifier = this.props.eventLinkIdentifier
        return value
      })

      this.setState({
        instruments: medicalInstruments,
      })
    } else {
      NotificationManager.error(
        'Die verlinkten Formulare konnten nicht geladen werden.'
      )
      this.closeSidebar()
    }
  }

  closeSidebar = (): void => {
    this.setState(
      {
        instruments: null,
      },
      () => {
        if (this.props.onClose) {
          this.props.onClose()
        }
      }
    )
  }

  render(): JSX.Element {
    if (!this.state.instruments) return <Loader />
    const { instruments } = this.state
    return (
      <div>
        {instruments && (
          <MedicalRoundInstruments
            closeSidebar={this.closeSidebar}
            instruments={instruments}
            patientId={this.props.patientId}
            saveMethod={
              this.props.hash === CREATE_NEW_EVENT_FROM_LINK
                ? RedCapSaveMethods.NEW
                : RedCapSaveMethods.EDIT
            }
          />
        )}
      </div>
    )
  }
}

export default LinkedRedCapEvent
