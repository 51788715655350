import PractitionerService from '../../../services/PractitionerService'
import { isSuccess } from '../../../utils/ApiUtils'
import React, { useEffect, useState } from 'react'
import { Person } from '../../../types/Person'
import { NotificationManager } from 'react-notifications'
import { getFullName } from '../../../utils/Utils'

interface Props {
  personId: string
  children?: any
}
const NameDisplay: React.FC<Props> = (props: Props) => {
  const [practitioner, setPractitioner] = useState<Person>(null)
  useEffect(() => {
    const f = async () => {
      if (props.personId && !practitioner) {
        const service = new PractitionerService()
        const response = await service.getPractitioner(props.personId)
        if (isSuccess(response)) {
          setPractitioner(response.Response as Person)
        } else {
          NotificationManager.error(
            'Fehler beim Laden der registrierten Zugänge'
          )
        }
      }
    }
    f()
  }, [practitioner, props.personId])
  if (!practitioner) return null
  return (
    <>
      {props.children}
      {getFullName(practitioner)}
    </>
  )
}

export default NameDisplay
