import React, { Component } from 'react'
import { APIResult } from '../../../../constants/APIResult'
import PatientService from '../../../../services/PatientService'
import { Patient } from '../../../../types/Patient'
import Autocomplete from '../../Autocomplete/Autocomplete'
import { NotificationManager } from 'react-notifications'
import { formatDate, getFullName } from '../../../../utils/Utils'

interface Props {
  value?: Patient
  label?: string
  disabled?: boolean

  /**
   * If true only active (default) patient will be searched,
   * otherwise inactive.
   *
   * @type {boolean}
   * @memberof Props
   */
  active?: boolean
  icon?: string
  clearOnSelect?: boolean
  onChange: (patient: Patient) => void
  name: string
  error?: string
}

interface State {
  patients?: Patient[]
  selected: Patient
  value: string
}

class PatientSelect extends Component<Props, State> {
  static defaultProps = {
    icon: 'search',
  }
  state = {
    selected: null,
    patients: null,
    value: '',
  }

  componentDidMount(): void {
    const { value } = this.props

    if (value) {
      this.setState({
        selected: value,
        value: getFullName(value),
      })
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { value } = this.props

    if (prevProps.value !== value) {
      if (value) {
        this.setState({ selected: value, value: getFullName(value) })
      } else {
        this.setState({ selected: null, value: '', patients: null })
      }
    }
  }

  handleSelect = (patient: Patient): void => {
    if (patient !== this.state.selected) this.props.onChange(patient)

    if (this.props.clearOnSelect) {
      this.setState({
        selected: patient,
        value: '',
      })
    } else if (patient) {
      this.setState({
        selected: patient,
        value: getFullName(patient),
      })
    } else {
      this.setState({
        selected: null,
        value: '',
      })
    }
  }

  handleSearch = async (param: string): Promise<void> => {
    if (param) {
      const service = new PatientService()
      const res = await service.findPatient(param, this.props.active)
      if (
        res &&
        res.Result === APIResult.SUCCESS &&
        Array.isArray(res.Response)
      ) {
        this.setState({ patients: res.Response as Patient[] })
      } else {
        NotificationManager.error('Fehler', 'Derzeit keine Antwort vom Server')
      }
    } else {
      this.setState({ patients: [] })
    }
  }

  renderValue = (patient: Patient): JSX.Element => {
    let birthDate
    if (patient.birthDate) {
      birthDate = (
        <i className="text-muted">(geb. {formatDate(patient.birthDate)})</i>
      )
    }
    return (
      <>
        {getFullName(patient)} {birthDate}
      </>
    )
  }

  render(): JSX.Element {
    return (
      <Autocomplete
        label={this.props.label}
        data={this.state.patients}
        onSearch={this.handleSearch}
        onSelect={this.handleSelect}
        selected={this.state.selected}
        disabled={this.props.disabled}
        renderValue={this.renderValue}
        icon={this.props.icon}
        value={this.state.value}
        name={this.props.name}
        placeholder="Patienten suchen"
        notFoundMessage="kein Patient gefunden"
        error={this.props.error}
      />
    )
  }
}

export default PatientSelect
