import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FieldFunctionSymbolButton } from '../UI/FieldFunctionSymbolButton/FieldFunctionSymbolButton'
import FormDynamicComponent, {
  FormDynamicComponentProps,
} from '../FormDynamic/FormDynamicComponent'
import { generateRepeatedComponents } from '../../utils/FormUtils'
import { fillObjectPropertiesWithValue } from '../../utils/ObjectUtils'
import { EventStore } from '../../infrastructure/EventProviderInterfaces'
import { zip } from '../../utils/NestUtils'

export interface FieldFunctionRepeatAnnotation {
  repeatNextFields?: string
}
export interface FieldRepeatProps extends FieldFunctionRepeatAnnotation {
  component: FormDynamicComponentProps
  allComponents?: FormDynamicComponentProps[]
  computeDisplayItem?: (component: FormDynamicComponentProps) => void
  index?: number
}

export const renameRefillComponent = (
  component: FormDynamicComponentProps,
  namePrefix: string
): FormDynamicComponentProps => {
  return {
    ...component,
    name: namePrefix + component.name,
  }
}

const makeRepeatPrefix = i => REPEAT_PREFIX + i + '_'

export const REPEAT_PREFIX = 'db__'
export const REPEAT_REGEX =
  /^(?<db_name>(?<prefix>db__(?<i>\d+)_)(?<name>.+?))(?<option>___\w+)?$/
/**
 * The match object for a checkbox field "db__0_feldname___2" looks like this:
 * {
 *  db_name: "db__0_feldname",
 *  prefix: "db__0_",
 *  option: "___2",
 *  i: "0"
 * }
 *
 */

export const FieldFunctionRepeat: React.FC<FieldRepeatProps> = (
  props: FieldRepeatProps
) => {
  const [repeatedComponents, setRepeatedComponents] = useState<
    FormDynamicComponentProps[][]
  >([])
  const [repetitionNo, setRepetitionNo] = useState<number>(0)
  const [repetitionId, setRepetitionId] = useState<string>(null)
  const [newDefaultValues, setNewDefaultValues] =
    useState<Record<string, any>>(null)
  const [initialized, setInitialized] = useState<boolean>(false)
  const context = useContext(EventStore)

  const [animationClass, setAnimationClass] = useState<string>(
    'old-component-block'
  )

  const getRepeatedComponents = useCallback(() => {
    return props.allComponents.slice(
      props.index + 1,
      props.index + 1 + +props.repeatNextFields
    )
  }, [props.allComponents, props.index, props.repeatNextFields])

  useEffect(() => {
    if (!initialized && context.values && props.repeatNextFields) {
      // Finding components and components to be repeated at loading

      // Determine the next n components, that have to be repeated
      const repeatingComponentsNames = getRepeatedComponents().map(
        component => component.name
      )

      setRepetitionId(repeatingComponentsNames.join('-'))

      // Find the values for them
      // Depending on the name of the components to be repeated
      const filteredValuesForTheseComponents = Object.fromEntries(
        Object.entries(context.values).filter(([initialKey, initialValue]) => {
          const match = REPEAT_REGEX.exec(initialKey)
          if (match && match.groups && match.groups.prefix) {
            return repeatingComponentsNames.some(
              name => match.groups.name === name
            )
          }
          return null
        })
      )

      const repeatedComponents = generateRepeatedComponents(
        props.allComponents,
        filteredValuesForTheseComponents
      )
      setNewDefaultValues(
        Object.fromEntries(
          repeatingComponentsNames.map(name => [name, context.values[name]])
        )
      )

      setRepeatedComponents(repeatedComponents)
      setInitialized(true)
      setRepetitionNo(repeatedComponents.length)
    }
  }, [
    context.values,
    getRepeatedComponents,
    repetitionNo,
    initialized,
    props.allComponents,
    props.repeatNextFields,
    context,
    newDefaultValues,
  ])

  const addComponentBlock = useCallback(() => {
    const prefix = makeRepeatPrefix(repetitionNo)
    const repeatingComponents = getRepeatedComponents()
    const animationClass = 'new-component-block'
    setAnimationClass(animationClass)
    const newComponents = [
      repeatingComponents.map(repeatedComponent =>
        renameRefillComponent(repeatedComponent, prefix)
      ),
      ...repeatedComponents,
    ]

    setRepeatedComponents(newComponents)

    zip(Object.entries(newDefaultValues), newComponents.flat()).forEach(
      ([[name, value], component]) =>
        context.setFieldValue(
          prefix + name,
          value
            ? typeof value === 'object'
              ? fillObjectPropertiesWithValue(value, null)
              : ''
            : ''
        )
    )

    setRepetitionNo(repetitionNo + 1)

    setAnimationClass('old-component-block')
  }, [
    getRepeatedComponents,
    repetitionNo,
    repeatedComponents,
    context,
    newDefaultValues,
  ])

  const removeComponentBlock = useCallback(
    i => {
      const blockToBeDeleted = repeatedComponents[i]
      setRepeatedComponents([
        ...repeatedComponents.slice(0, i),
        ...repeatedComponents.slice(i + 1, repeatedComponents.length),
      ])
      const prefix = REPEAT_REGEX.exec(blockToBeDeleted[0].name).groups.prefix
      const filteredValues = Object.keys(context.values).filter(name =>
        name.includes(prefix)
      )
      const values = context.values
      filteredValues.forEach(name => delete values[name])
      context.setValues(values)

      const minimalNewrepetitionNo = Math.max(
        ...repeatedComponents.map(block =>
          parseInt(REPEAT_REGEX.exec(block[0].name).groups.i)
        )
      )

      setRepetitionNo(minimalNewrepetitionNo + 1)
    },
    [repeatedComponents, context]
  )

  const onOff = useCallback(
    () => setTimeout(() => setAnimationClass('new-component-block'), 30),
    []
  )

  if (!props.repeatNextFields) return null
  if (animationClass === 'old-component-block') {
    onOff()
  }
  return (
    <>
      <FieldFunctionSymbolButton
        icon="add"
        onClick={addComponentBlock}
        name={'add-repeating-component-' + props.component.name}
      />
      {repeatedComponents.map((block, _i) => (
        <div
          key={'repeating-block' + _i + '-' + repetitionId}
          className={`${
            _i === 0 && animationClass !== 'old-component-block'
              ? animationClass
              : 'old-component-block'
          }`}>
          <FieldFunctionSymbolButton
            icon="delete"
            onClick={() => removeComponentBlock(_i)}
            name={'add-repeating-component-' + props.component.name}
          />
          {block.map((repeatedComponent, _j) => (
            <FormDynamicComponent
              key={'repeating-component-' + _i + '-' + _j}
              {...repeatedComponent}
              handleChange={context.handleChange}
            />
          ))}{' '}
        </div>
      ))}
    </>
  )
}
