import { TaskCategories } from '../constants/TaskCategories'
import { AuthContext } from '../infrastructure/AuthProvider'
import { MedicationStatusDelegation } from './MedicationUtils'
import { MedicationRequestStatus } from '../constants/MedicationRequestStatus'
import { UserPermission } from '../constants/UserPermission'
import { MedicationScheduleProps } from '../components/Medication/MedicationSchedule/MedicationSchedule'
import { Practitioner } from '../types/Practitioner'
import { Task } from '../types/Task'

export function getCaseManagerTask(
  medicationRequests: MedicationRequest[],
  treatingPractitioner: Practitioner
): Task {
  medicationRequests = medicationRequests.filter(
    it =>
      MedicationStatusDelegation.statusDiffer([it]) ||
      MedicationStatusDelegation.isDraft(it)
  )
  return {
    title: `Medikament löschen`,
    category: TaskCategories.INDICATION_CHECK,
    ownerDto: treatingPractitioner,
    description:
      `Betrifft folgende Verschreibung(en): \n\n` +
      medicationRequests
        .map(
          medicationRequest => `
    Handelsname: ${medicationRequest.medicationReference?.display ?? '–'}
    Wirkstoff(e): ${medicationRequest.contained
      .map(it =>
        it.ingredient.map(
          it =>
            it.itemCodeableConcept.text +
            ' ' +
            it.strength.numerator.value +
            it.strength.numerator.unit
        )
      )
      .join(', ')}`
        )
        .join('\n––––––––––––––––––\n'),
  }
}

export function getRemoveRestoreControlConfig(
  authContext: AuthContext,
  props: MedicationScheduleProps,
  medicationRequests: MedicationRequest[]
): { actionTitle: string; actionIcon: string } {
  const medicationRequestStatusList = medicationRequests.map(it =>
    MedicationStatusDelegation.getCaseManagerOverRealStatus(it)
  )
  const medicationRequestStatusListAllActiveOrStopped =
    medicationRequestStatusList.every(it =>
      [
        MedicationRequestStatus.active,
        MedicationRequestStatus.completed,
        MedicationRequestStatus.stopped,
      ].includes(it)
    )
  const medicationRequestStatusListAnyActive = medicationRequestStatusList.some(
    it => [MedicationRequestStatus.active].includes(it)
  )

  const AllActionConfig = {
    [[true, true].toString()]: ['Alle pausieren', 'pause'],
    [[true, false].toString()]: ['Alle bestätigen', 'done_outline'],
    [[false, false].toString()]: [
      'Aufgabe zur gesamten Indikationsbestätigung erstellen',
      'add_task',
    ],
    [[false, true].toString()]: [
      'Aufgabe zur gesamten Aufhebung der Indikation erstellen',
      'add_task',
    ],
  }
  const [actionTitle, actionIcon] =
    AllActionConfig[
      [
        props.handlers.hasPermission(UserPermission.PATIENT_MEDICATION_CONFIRM),
        medicationRequestStatusListAllActiveOrStopped &&
          medicationRequestStatusListAnyActive,
      ].toString()
    ]

  return { actionTitle, actionIcon }
}
