import MaterialIcon from '../MaterialIcon/MaterialIcon'
import React from 'react'
import { Task } from '../../../types/Task'
import { Appointment } from '../../../types/Appointment'
import { LinkListRow } from './LinkListRow'
import LinkedRedCapEvent from '../../RedCapEvent/LinkedRedCapEvent'
import {
  DynamicSidebarContent,
  DynamicSidebarPositionPriority,
  DynamicSidebarStore,
} from '../../../infrastructure/DynamicSidebarProvider'
import LetterEditor from '../../Letter/LetterEditor'
import { MedicalInstrument } from '../../Forms/FormInterfaces'

import { createEventHeadline } from '../../../utils/FormUtils'
import { defaultFormTabWidth } from '../../../constants/DynamicSidebarConstants'

export interface LinkModalDisplayParams {
  title: string
  form?: MedicalInstrument
  createUrl?: string
  templateUrl?: string
  linkObject?: LinkObject
}

interface Props {
  links: Map<string, any[]>
  linkObject: LinkObject
}

export interface LinkObject {
  task?: Task
  appointment?: Appointment
}

interface State {
  showModal: boolean
  showDynamicSidebar: boolean
  modalDisplayParams: LinkModalDisplayParams
}

export class LinkList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showModal: false,
      showDynamicSidebar: false,
      modalDisplayParams: null,
    }
  }

  static contextType = DynamicSidebarStore

  openSidebar = (modalDisplayParams: LinkModalDisplayParams): void => {
    if (modalDisplayParams.templateUrl) {
      this.showLetterEditor(modalDisplayParams)
    } else if (modalDisplayParams.form) {
      this.showRedcapForm(modalDisplayParams)
    }
  }

  showLetterEditor = (modalDisplayParams: LinkModalDisplayParams): void => {
    const identifier = `letter-${modalDisplayParams?.linkObject?.task?.id}`

    const forms: DynamicSidebarContent = {
      identifier: identifier,
      component: (
        <LetterEditor
          task={modalDisplayParams.linkObject.task}
          templateUrl={modalDisplayParams.templateUrl}
          createUrl={modalDisplayParams.createUrl}
          editor={true}
        />
      ),
      initialWidth: defaultFormTabWidth,
    }
    this.context.handlers.addContent(forms, DynamicSidebarPositionPriority.LEFT)
  }

  showRedcapForm = (modalDisplayParams: LinkModalDisplayParams): void => {
    const identifier = `event-${modalDisplayParams?.form?.event_name}`

    const patient = this.props.linkObject.appointment
      ? this.props.linkObject.appointment?.participants?.find(value => {
          return value.role === 'patient'
        })
      : this.props.linkObject.task.forDto
      ? this.props.linkObject.task.forDto
      : null

    const eventDate = modalDisplayParams?.form.date
      ? new Date(modalDisplayParams.form.date)
      : null
    const headline = patient
      ? createEventHeadline(modalDisplayParams?.title, eventDate, patient)
      : ''

    const eventLinkIdentifier = this.props.linkObject.appointment
      ? this.props.linkObject.appointment.eventLinkIdentifier
      : this.props.linkObject.task
      ? this.props.linkObject.task.eventLinkIdentifier
      : null

    const forms: DynamicSidebarContent = {
      identifier: identifier,
      headline,
      component: (
        <LinkedRedCapEvent
          event={modalDisplayParams.form.event_name}
          recordId={modalDisplayParams.form.recordId}
          patientId={patient ? patient.id.replace('Patient/', '') : null}
          hash={modalDisplayParams.form.hash_value}
          eventLinkIdentifier={eventLinkIdentifier}
          onClose={() => {
            this.closeSidebar(`event-${modalDisplayParams?.form?.event_name}`)
          }}
        />
      ),
      initialWidth: defaultFormTabWidth,
    }
    this.context.handlers.addContent(forms, DynamicSidebarPositionPriority.LEFT)
  }

  closeSidebar = (content: string): void => {
    this.setState(
      {
        showDynamicSidebar: false,
      },
      () => {
        this.context.handlers.removeContent(content)
      }
    )
  }

  render(): JSX.Element {
    if (!this.props.links) return null

    return (
      <>
        <div className={'d-flex mt-4'}>
          <span className={'mr-2'}>
            <MaterialIcon icon={'link'} size={'16px'} />
          </span>
          <h6>Links</h6>
        </div>
        {this.props.links ? (
          <table className={'table table-panos table-hover'}>
            <tbody>
              {this.props.links
                ? Object.entries(this.props.links)
                    .map(([key, values], index) => {
                      return (
                        <LinkListRow
                          key={index}
                          documentType={key}
                          linkParams={values}
                          index={index}
                          openModalOrSidebar={this.openSidebar}
                          linkObject={this.props.linkObject}
                        />
                      )
                    })
                    .flat()
                : null}
            </tbody>
          </table>
        ) : null}
      </>
    )
  }
}
