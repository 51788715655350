export function wordWrap(
  str: string,
  width: number,
  delimiter: string
): string {
  // use this on single lines of text only

  if (str.length > width) {
    const p = width
    //for (; p > 0 && str[p] !== ' '; p--) {}
    if (p > 0) {
      const left = str.substring(0, p)
      const right = str.substring(p + 1)
      return left + delimiter + wordWrap(right, width, delimiter)
    }
  }
  return str
}

export function multiParagraphWordWrap(
  str: string,
  width: number,
  delimiter: string
): string {
  if (!str) return ''

  // use this on multi-paragraph lines of text

  const arr = str.split(delimiter)

  for (let i = 0; i < arr.length; i++) {
    if (arr[i].length > width) arr[i] = wordWrap(arr[i], width, delimiter)
  }

  return arr.join(delimiter)
}

export const pad = (num: string, size: number, char = '0'): string => {
  let s = num + ''
  while (s.length < size) s = '0' + s
  return s
}

export function upperCaseFirstLetter(string: string): string {
  if (!string) return null
  return string.charAt(0).toUpperCase() + string.slice(1)
}
