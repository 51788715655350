import React, { useContext, useEffect, useMemo, useState } from 'react'
import _, { cloneDeep } from 'lodash'
import HistoryRow from './HistoryRow'
import {
  formatDate,
  formatTime,
  getIdPartFromFhirResourceId,
  parseUnknownToDate,
} from '../../../utils/Utils'
import Loader from '../../UI/Spinner/Loader'
import MaterialIcon from '../../UI/MaterialIcon/MaterialIcon'
import { Practitioner } from '../../../types/Practitioner'
import PractitionerService from '../../../services/PractitionerService'
import { carePlanWasSaved } from '../../../utils/CarePlanUtils'
import { CarePlan } from '../../../types/CarePlan'
import {
  mapRequestsToSchedule,
  MedicationScheduleObject,
} from '../../../utils/MedicationScheduleUtils'
import { Button } from 'react-bootstrap'
import { UserPermission } from '../../../constants/UserPermission'

import {
  AuthContext,
  AuthStore,
  withAuth,
} from '../../../infrastructure/AuthProvider'
import { getMedicationRequestById } from '../../../services/MedicationRequestService'
import { CarePlanStatus } from '../../../constants/CarePlanStatus'
import CarePlanService from '../../../services/CarePlanService'
import { APIResult } from '../../../constants/APIResult'
import { NotificationManager } from 'react-notifications'
import LocalStorageService from '../../../services/LocalStorageService'
import { MedicationRequestUpdateWithReason } from '../MedicationSchedule/MedicationReason'
import { MEDICATION_CONFIRM_STORAGE } from '../../../config/Storages'
import { ApiResponseFhir } from '../../../types/ApiResponseFhir'

export interface HistoryGroup {
  group: CarePlan
  data: MedicationRequest[]
  groupVersionBefore: CarePlan
  groupVersionsBefore: CarePlan[]
}

interface HistoryTableProps extends AuthContext {
  carePlan: CarePlan
  setSchedule: (schedule: MedicationScheduleObject) => void
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  groups: HistoryGroup[]
  singleItems: MedicationRequest[]
  selectedRowId?: string
  onClick: (item: MedicationRequest) => void
}

export const UNTITLED_RESSOURCE = 'Ohne Titel'
export const makeRessourceTitle = (resource: CarePlan): string => {
  return (
    ' Medikationsplan ' +
    (resource.title ? '"' + resource.title + '"' : UNTITLED_RESSOURCE)
  )
}

export const makeResourceVersion = (
  resource: MedicationRequestExtended | CarePlan
): string => {
  if (Object.prototype.hasOwnProperty.call(resource, 'title')) {
    return ' Medikationsplan: Version ' + resource.meta.versionId
  } else {
    return ' Verschreibung: Version ' + resource.meta.versionId
  }
}

export const makeRessourceLastUpdated = (resource: CarePlan): string => {
  return ' am ' + formatDate(resource.meta.lastUpdated)
}

export const makeRessourceWasSaved = (resource: CarePlan): string => {
  const time = parseUnknownToDate(resource.created)
  return carePlanWasSaved(resource)
    ? ` — gespeicherte Medikationsplan-Version vom ${formatDate(
        time
      )} ${formatTime(time)}`
    : ''
}

export const makeRessourceReason = (resource: CarePlan): JSX.Element => {
  if (!resource.note) {
    console.warn('NO REASON FOR CAREPLAN')
    return <i>-- ohne Grund --</i>
  }
  return (
    <>
      {' '}
      Kontext: <b>{resource.note ?? ''}</b>
    </>
  )
}

const HistoryTable: React.FC<HistoryTableProps> = (
  props: HistoryTableProps
) => {
  const context = useContext(AuthStore)
  const { hasPermission } = context.handlers
  const reason = 'keine Angabe'
  // For saving while relogin
  const localStorageService = useMemo(
    () =>
      new LocalStorageService<MedicationRequestUpdateWithReason>(
        MEDICATION_CONFIRM_STORAGE
      ),
    []
  )

  const renderRows = (
    careplan: CarePlan,
    rows: MedicationRequest[],
    groupVersionBefore: CarePlan,
    groupVersionsBefore: CarePlan[],
    group: CarePlan
  ) => {
    return rows.map(row => {
      if (row) {
        const selected = props.selectedRowId && props.selectedRowId === row.id
        const keyId =
          'history-medicationRequest-' +
          row.id +
          row.idVersioned +
          '-' +
          careplan.id +
          '/' +
          careplan.meta.versionId
        return (
          <HistoryRow
            carePlan={props.carePlan}
            setSchedule={props.setSchedule}
            setShowModal={props.setShowModal}
            key={keyId}
            keyId={keyId}
            item={row}
            groupVersionBefore={groupVersionBefore}
            groupVersionsBefore={groupVersionsBefore}
            selected={selected}
            group={group}
            onClick={() => props.onClick(row)}
          />
        )
      }
      return null
    })
  }

  const necessaryPractitionerIds = _.uniq(
    props.groups.map(it => it.group.author)
  )
  const [practitioners, setPractioners] = useState<Practitioner[]>([])
  useEffect(() => {
    if (props.groups.length && !practitioners.length) {
      const fun = async () => {
        const practitionerResponse =
          await new PractitionerService().getPractitionersByIds(
            necessaryPractitionerIds
          )
        setPractioners(practitionerResponse.Response as Practitioner[])
      }
      fun().then()
    }
  })

  const makeRessourceAutor = ressource => {
    if (practitioners) {
      const practitioner = practitioners?.find(
        it => it.id === getIdPartFromFhirResourceId(ressource.author)
      )

      return practitioner ? (
        <>
          {' '}
          Autor{' '}
          <b>
            {practitioner.firstName} {practitioner.lastName}{' '}
          </b>
        </>
      ) : (
        <Loader />
      )
    } else return <Loader />
  }

  async function selectCarePlan(index) {
    if (props.groups[index].group?.id) {
      let medicationRequests = []
      const selectedCarePlan = cloneDeep(props.groups[index].group)
      const currentCarePlan = cloneDeep(props.carePlan)
      if (selectedCarePlan.medicationRequests) {
        const responses = (await Promise.all(
          selectedCarePlan.medicationRequests?.map(it =>
            getMedicationRequestById(it)
          )
        )) as unknown
        medicationRequests = (
          responses as ApiResponseFhir<MedicationRequest>[]
        ).map(it => it.data) as MedicationRequest[]
        medicationRequests.map(mR => {
          delete mR.meta
          return mR
        })
      }

      delete selectedCarePlan.meta
      delete selectedCarePlan.id

      currentCarePlan.status = CarePlanStatus.on_hold
      await new CarePlanService(reason).putCarePlan(currentCarePlan)
      const newCareResponse = await new CarePlanService(reason).postCarePlan(
        selectedCarePlan
      )
      if (newCareResponse.Result === APIResult.SUCCESS) {
        const newCarePlan = newCareResponse.Response as CarePlan
        const responsesMedReq = (await Promise.all(
          newCarePlan.medicationRequests.map(it => getMedicationRequestById(it))
        )) as unknown
        const newMedRequests = (
          responsesMedReq as ApiResponseFhir<MedicationRequest>[]
        ).map(it => it.data) as MedicationRequest[]
        const newSchedule = mapRequestsToSchedule(newMedRequests)
        props.setSchedule(newSchedule)

        localStorageService.localStorageAdd({
          medicationRequests: newMedRequests,
          reason: reason,
        })
        props.handlers.doRelogin()
      } else {
        NotificationManager.error('Laden der Version fehlgeschlagen')
        console.error('CarePlan could not be created')
      }
    }
    props.setShowModal(false)
  }

  return (
    <table className="table table-panos table-hover">
      <thead>
        <tr>
          <th></th>
          <th>Version</th>
          <th>Titel</th>
          <th>Änderungen</th>
        </tr>
      </thead>
      <tbody>
        {props.groups
          ? props.groups.map((group, index) => {
              return [
                <tr
                  className="table-group"
                  key={group.group.id + '-' + group.group.meta.versionId}>
                  <td colSpan={8}>
                    <Button
                      disabled={
                        !hasPermission(
                          UserPermission.PATIENT_MEDICATIONPLAN_RECOVER_OLDER_VERSIONS
                        )
                      }
                      className="mt-2 mb-2"
                      variant={'outline-dark'}
                      onClick={() => selectCarePlan(index)}>
                      <MaterialIcon verticalAlignment="middle" icon="history" />
                    </Button>
                    <span className="mt-2 mb-2 ml-2 mr-2">
                      <MaterialIcon
                        verticalAlignment="middle"
                        icon="calendar_today"
                      />
                    </span>
                    {makeRessourceTitle(group.group)} —
                    {makeResourceVersion(group.group)} —
                    {makeRessourceAutor(group.group)} —
                    {makeRessourceLastUpdated(group.group)}
                    {makeRessourceWasSaved(group.group)} —
                    {makeRessourceReason(group.group)}
                  </td>
                </tr>,
                ...(group.data
                  ? renderRows(
                      group.group,
                      group.data,
                      group.groupVersionBefore,
                      group.groupVersionsBefore,
                      group.group
                    )
                  : null),
              ]
            })
          : null}
      </tbody>
    </table>
  )
}

export default withAuth(HistoryTable)
