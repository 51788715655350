import React, { ErrorInfo, ReactNode } from 'react'
import FormError from './FormError'

interface Props {
  message?: string
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  state = { hasError: false }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <FormError>{this.props.message}</FormError>
    }
    return this.props.children
  }
}

export function withErrorBoundary<T>(
  Component: React.ComponentType,
  message?: string
): React.ComponentType<T> {
  const componentWithError = (props: T): JSX.Element => {
    return (
      <ErrorBoundary message={message}>
        <Component {...props} />
      </ErrorBoundary>
    )
  }
  componentWithError.displayName = `withErrorBoundary(${
    Component.displayName || Component.name || 'Component'
  })`
  return componentWithError
}

export default ErrorBoundary
