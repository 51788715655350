import {
  ExtraVariableInfos,
  SmartVariablesInfo,
} from '../components/Forms/FormInterfaces'
import { all, create, MathArray } from 'mathjs'
import { insertVariablesCalc } from './RedcapVariableUtils'

export const math = create(all)
// until mathjs types are fixed
const mathTypedAsAny = math.typed as any
const mathCeil = math.ceil as (number: number, decimalPlaces: number) => number

/**
 * To mimic Redcap behaviour:
 * empty value is inserted as '' and
 * shall be handled as 0 in a number comparison
 * which is counterintuitive
 */
const equal = math.typed('equal', {
  'string, string': function (a: string, b: string): boolean {
    return a === b
  },
  'number, string': function (a: number, b: string): boolean {
    if (b === '') return a === 0
    return a.toString() === b
  },
  'string, number': function (a: string, b: number): boolean {
    if (a === '') return 0 === b
    return a === b.toString()
  },
})

/**
 * To mimic Redcap behaviour:
 * empty value is inserted as '' and
 * shall be handled as 0 in a number comparison
 * which is counterintuitive
 */
const unequal = math.typed('unequal', {
  'string, string': function (a: string, b: string): boolean {
    return a !== b
  },
  'number, string': function (a: number, b: string): boolean {
    if (b === '') return a !== 0
    return a.toString() !== b
  },
  'string, number': function (a: string, b: number): boolean {
    if (a === '') return 0 !== b
    return a !== b.toString()
  },
})

mathTypedAsAny.addType({
  name: 'stringOrNumber',
  test: function (x) {
    return typeof x === 'number' || typeof x === 'string'
  },
})

const ifFunctionUnknownArgs = function (
  condition: boolean,
  trueValue: string | number,
  falseValue: string | number
): string | number {
  return condition ? trueValue : falseValue
}

const ifFunction = math.typed('if', {
  'boolean, stringOrNumber, stringOrNumber': ifFunctionUnknownArgs,
})

function ceil(number: number, decimalPlaces?: number) {
  return decimalPlaces ? mathCeil(number, decimalPlaces) : math.ceil(number)
}

const roundup = math.typed('roundup', {
  'number, number': ceil,
  number: ceil,
})

function floor(number: number, decimalPlaces?: number) {
  return decimalPlaces ? math.floor(number, decimalPlaces) : math.floor(number)
}

const rounddown = math.typed('rounddown', {
  'number, number': floor,
  number: floor,
})

const stdev = math.typed('stdev', {
  '...': function (numbers: MathArray): number {
    return math.std(numbers)
  },
})

const isNumber = function (value: unknown): boolean {
  return typeof value === 'number'
}

const isInteger = function (value: unknown): boolean {
  if (typeof value === 'number' && Number.isInteger(value)) return true
  return false
}

const containsString = math.typed('contains', {
  'string, string': function (teststring: string, substring: string): boolean {
    return teststring
      .toLocaleLowerCase()
      .includes(substring.toLocaleLowerCase())
  },
})

const notContain = math.typed('not_contain', {
  'string, string': function (teststring: string, substring: string): boolean {
    return !teststring
      .toLocaleLowerCase()
      .includes(substring.toLocaleLowerCase())
  },
})

const startsWith = math.typed('starts_with', {
  'string, string': function (teststring: string, start: string): boolean {
    return teststring.toLocaleLowerCase().startsWith(start.toLocaleLowerCase())
  },
})

const endsWith = math.typed('ends_with', {
  'string, string': function (teststring: string, end: string): boolean {
    return teststring.toLocaleLowerCase().endsWith(end.toLocaleLowerCase())
  },
})

const trim = math.typed('trim', {
  string: function (stringToTrim: string): string {
    return stringToTrim.trim()
  },
})

const upper = math.typed('upper', {
  string: function (stringToUpperCase: string): string {
    return stringToUpperCase.toLocaleUpperCase()
  },
})

const lower = math.typed('lower', {
  string: function (stringToLowerCase: string): string {
    return stringToLowerCase.toLocaleLowerCase()
  },
})

const mid = math.typed('mid', {
  'string, number, number': function (
    wholeString: string,
    start: number,
    length: number
  ): string {
    return wholeString.substring(start - 1, start - 1 + length)
  },
})

const left = math.typed('left', {
  'string, number': function (wholeString: string, length: number): string {
    return wholeString.substring(0, length)
  },
})

const right = math.typed('right', {
  'string, number': function (wholeString: string, length: number): string {
    return wholeString.substring(
      wholeString.length - length,
      wholeString.length
    )
  },
})

const length = math.typed('length', {
  string: function (wholeString: string): number {
    return wholeString.length
  },
})

const find = math.typed('find', {
  'string, string': function (subString: string, wholeString: string): number {
    return wholeString.indexOf(subString) + 1
  },
})

// TODO Datediff, isblankormissingcode
export const customCalcFunctions = {
  NULL: (): string => '',
  equal: equal,
  unequal: unequal,
  if: ifFunction,
  roundup: roundup,
  rounddown: rounddown,
  stdev: stdev,
  isnumber: isNumber,
  isinteger: isInteger,
  contains: containsString,
  not_contain: notContain,
  starts_with: startsWith,
  ends_with: endsWith,
  trim: trim,
  upper: upper,
  lower: lower,
  mid: mid,
  left: left,
  right: right,
  length: length,
  find: find,
}
math.import(customCalcFunctions, {})

export function getSmartVariableData(
  matchedGroups: Record<string, string>,
  smartVariables: SmartVariablesInfo[]
): unknown {
  for (const i in smartVariables) {
    if (
      matchedGroups.event !== undefined &&
      matchedGroups.event !== smartVariables[i].fromEvent
    )
      continue
    if (
      matchedGroups.fieldName !== undefined &&
      matchedGroups.fieldName +
        (matchedGroups.checkboxNumber
          ? '___' + matchedGroups.checkboxNumber
          : '') !==
        smartVariables[i].withFieldName
    )
      continue
    if (
      matchedGroups.check !== undefined &&
      matchedGroups.check !== smartVariables[i].withChecked
    )
      continue
    if (
      matchedGroups.value !== undefined &&
      matchedGroups.value !== smartVariables[i].withValue
    )
      continue
    if (
      matchedGroups.instance !== undefined &&
      matchedGroups.instance !== smartVariables[i].withRepeatInstance
    )
      continue
    return smartVariables[i]
  }
  return null
}

const onEqualSigns = match => {
  /**
   * Don't replace '<' and '>' with ==
   * Not doing this with regex lookahead or behind, because it is not working
   * in Safari
   * **/
  if (match[0] === '<' || match[0] === '>') {
    return match
  }
  return '=='
}

export function transformExpression(expression: string): string {
  // TODO fix this in redcap or here?
  return expression
    .replace(/[><=]?(=)/g, match => onEqualSigns(match)) // replace single equals sign
    .replace(/<>/g, '!=') // replace unequal sign

    .replace(/\n/g, '')
}

export default function evaluate(
  str: string,
  smartVariables: SmartVariablesInfo[],
  values: Record<string, unknown>,
  extraInfosComponents?: Record<string, ExtraVariableInfos>
): unknown {
  const stringToParse = insertVariablesCalc(
    str,
    smartVariables,
    values,
    extraInfosComponents
  )
  let newValue
  try {
    newValue = math.evaluate(stringToParse)
    newValue =
      (isNaN(newValue) && newValue.toString() === 'NaN') ||
      (isNumber(newValue) && !isFinite(newValue))
        ? ''
        : newValue
  } catch (e) {
    newValue = ''
  }

  if (newValue.replace) {
    newValue = newValue.replace(/==/g, '=') // ToDo better solution?
    newValue = newValue.replace(/&quot;/g, '"')
  }
  return newValue
}
