/**
 * Options for type field
 */
export const TypeOptions = [
  {
    value: 'nicht-motorisch',
    label: 'nicht-motorisch',
  },
  {
    value: 'motorisch',
    label: 'motorisch',
  },
  {
    value: 'allgemein/anderes',
    label: 'allgemein/anderes',
  },
]
