import React, { Component, ReactElement } from 'react'
import { Appointment, AppointmentDto } from '../../types/Appointment'
import { Task } from '../../types/Task'

export const FieldTerminAufgabeStore =
  React.createContext<FieldTerminAufgabeContext>({
    form: null,
    fieldDraft: null,
  })

export interface FieldTerminAufgabeContext {
  form?: string
  fieldDraft?: FieldTerminAufgabeDraft[]
  status?: string
  addDraft?: (fieldTemplate: FieldTerminAufgabeDraft) => void
  removeDraft?: (id: string) => void
}

export interface FieldTerminAufgabeProps {
  form?: string
  handleFieldDrafts?: (attachments: FieldTerminAufgabeDraft[]) => void
  overwriteFieldDrafts?: (attachments: FieldTerminAufgabeDraft[]) => void
}

export interface FieldTerminAufgabeDraft {
  form?: string
  tasks?: Task[]
  appointments?: Appointment[] | AppointmentDto[]
  links?: Map<string, unknown>
  tempId?: string
}

export default class FieldTerminAufgabeProvider extends Component<
  FieldTerminAufgabeProps,
  FieldTerminAufgabeContext
> {
  state: FieldTerminAufgabeContext = {
    fieldDraft: [],
    form: null,
    status: null,
  }

  componentDidUpdate(
    prevProps: Readonly<FieldTerminAufgabeProps>,
    prevState: Readonly<FieldTerminAufgabeContext>
  ): void {
    if (prevState.fieldDraft !== this.state.fieldDraft) {
      if (this.state.status === 'removed') {
        this.props.overwriteFieldDrafts(this.state.fieldDraft)
        this.setState({
          status: null,
        })
      } else {
        this.props.handleFieldDrafts(this.state.fieldDraft)
      }
    }
  }

  componentDidMount(): void {
    if (this.props.form) {
      this.setState(
        {
          form: this.props.form,
        },
        () => this.props.handleFieldDrafts(this.state.fieldDraft)
      )
    }
  }

  setFieldDrafts = (fieldDraft: FieldTerminAufgabeDraft): void => {
    this.setState({
      fieldDraft: [...this.state.fieldDraft, fieldDraft],
    })
  }

  removeFieldDrafts = (id: string): void => {
    const updated = [...this.state.fieldDraft].filter(value => {
      return value.tempId !== id
    })
    this.setState({
      fieldDraft: [...updated],
      status: 'removed',
    })
  }

  render(): ReactElement {
    return (
      <FieldTerminAufgabeStore.Provider
        value={{
          ...this.state,
          addDraft: this.setFieldDrafts,
          removeDraft: this.removeFieldDrafts,
        }}>
        {this.props.children}
      </FieldTerminAufgabeStore.Provider>
    )
  }
}
