import React, { useContext, useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { withAuth } from '../../infrastructure/AuthProvider'
import { Appointment } from '../../types/Appointment'
import IAuth from '../../types/Auth'
import OrganizerModal, { OrganizerModalType } from './OrganizerModal'
import { FieldTerminAufgabeAlert } from './FieldTerminAufgabe'
import { FieldTerminAufgabeStore } from './FieldTerminAufgabeProvider'
import { Links } from './FieldFunctionInterfaces'

export interface FieldTerminProps extends Links {
  defaultAppointment: Appointment
  auth: IAuth
}

const FieldTermin: React.FC<FieldTerminProps> = ({
  defaultAppointment,
  links,
}: FieldTerminProps) => {
  const [show, setShow] = useState<boolean>(false)
  const [isCreatedAlert, setIsCreatedAlert] =
    useState<FieldTerminAufgabeAlert>(null)
  const fieldTerminAufgabeContext = useContext(FieldTerminAufgabeStore)

  const handleShow = (): void => {
    setShow(true)
  }

  const handleAbort = (): void => {
    setShow(false)
  }

  const handleEdit = (): void => {
    fieldTerminAufgabeContext.removeDraft(isCreatedAlert.tempId)
    setShow(true)
  }

  const handleSubmit = (
    taskTitle: string,
    appointmentTitle,
    tempId: string
  ): void => {
    setIsCreatedAlert({
      taskTitle: taskTitle,
      appointmentTitle: appointmentTitle,
      tempId: tempId,
    })
  }
  if (defaultAppointment == null) return null
  return (
    <div className="form-group">
      <OrganizerModal
        show={show}
        links={links}
        initialTasks={null}
        initialAppointments={[defaultAppointment]}
        onAbort={handleAbort}
        onSubmit={handleSubmit}
        onEdit={handleEdit}
        type={OrganizerModalType.FIELD_TERMIN}
      />

      <div>
        {!isCreatedAlert ? (
          <div>
            <label className="form-label">Neuen Termin erstellen</label>
            <Alert variant={'info'}>
              <Button variant="primary" onClick={handleShow}>
                {defaultAppointment.title
                  ? defaultAppointment.title
                  : 'Neuer Termin'}
              </Button>{' '}
              Terminvorlage nicht erstellt
            </Alert>
          </div>
        ) : (
          <Alert variant={'success'}>
            Terminvorlage erstellt: {isCreatedAlert.appointmentTitle}{' '}
            <Button variant="primary" onClick={handleEdit}>
              Bearbeiten
            </Button>
          </Alert>
        )}
      </div>
    </div>
  )
}

export default withAuth(FieldTermin)
