/* eslint no-eval: 0 */

import { formatDateTime } from '../../utils/Utils'
import { multiParagraphWordWrap } from '../../utils/StringUtils'
import { FieldTypes } from '../../constants/FieldTypes'

const OPERATOR_GERMAN_TRANSLATION = {
  '||': 'oder',
  '&&': 'und',
  '<': 'kleiner',
  '>': 'größer',
  '<=': 'kleiner gleich',
  '>=': 'größer gleich',
  '+': 'plus',
  '*': 'mal',
  '-': 'minus',
  '/': 'geteilt durch',
}

const BOOLEAN_GERMAN_TRANSLATION = {
  true: 'WAHR',
  false: 'FALSCH',
}

export const astToDiGraph = (
  obj: Record<string, any>,
  functionString: string
): { nodes: any; links: any } => {
  const nodes = []
  const links = []
  let objectRoots = 0

  const astObjToId = object => {
    const { type, start, end } = object
    return JSON.stringify({ type, start, end })
  }

  const redCapFieldInfoExpression = astMemberObject => {
    /* the particular references to redCapField are parsed to some html

   astMemberObject: the node in the AST is a member expression,
       because we wrote "{formName: "..."}.value". value is a member of that
       object.
   return: html
 */
    const redCapInfo = {
      fieldName: astMemberObject.properties.find(
        property => property.key.value === 'fieldName'
      ).value.value,
      repeatInstance: astMemberObject.properties.find(
        property => property.key.value === 'repeatInstance'
      ).value.value,
      value: astMemberObject.properties.find(
        property => property.key.value === 'value'
      ).value.value,
      date: astMemberObject.properties.find(
        property => property.key.value === 'date'
      ).value.value,
      owner: astMemberObject.properties.find(
        property => property.key.value === 'owner'
      ).value.value,
      record: astMemberObject.properties.find(
        property => property.key.value === 'record'
      ).value.value,
      formName: astMemberObject.properties.find(
        property => property.key.value === 'formName'
      ).value.value,
      arm: astMemberObject.properties.find(
        property => property.key.value === 'arm'
      ).value.value,
      event: astMemberObject.properties.find(
        property => property.key.value === 'event'
      ).value.value,
    }

    const dateString = formatDateTime(new Date(redCapInfo.date))

    let value = redCapInfo.value ? redCapInfo.value.toString() : ''
    if (value.length > 20) {
      if (value) value = multiParagraphWordWrap(value, 30, '<br />')
      if (
        typeof value === 'string' &&
        (value.indexOf('Error') !== -1 ||
          value.indexOf('Piping Processing Engine') !== -1)
      ) {
        value = '<p style="color:red">' + value + '</p>'
      }
    }

    return `
       <table>
        <tr>
          <td>Wert</td>
          <td> <b>${value}</b></td>
        </tr>
        <tr>
          <td>Feld</td>
          <td> ${redCapInfo.fieldName}</td>
        </tr>
        ${
          redCapInfo.formName
            ? '<tr><td>Formular</td><td>' + redCapInfo.formName + '</td> </tr>'
            : ''
        }
        ${
          redCapInfo.repeatInstance
            ? '<tr><td>RepeatInstance</td><td>' +
              redCapInfo.repeatInstance +
              '</td> </tr>'
            : ''
        }
        ${
          redCapInfo.event
            ? '<tr><td>Event</td><td>' + redCapInfo.event + '</td> </tr>'
            : ''
        }
        ${
          redCapInfo.arm
            ? '<tr><td>Arm</td><td>' + redCapInfo.arm + '</td> </tr>'
            : ''
        }
        ${
          redCapInfo.owner
            ? '<tr><td>Autor</td><td>' + redCapInfo.owner + '</td> </tr>'
            : ''
        }
        ${
          redCapInfo.date
            ? '<tr><td>Datum</td><td>' + dateString + '</td> </tr>'
            : ''
        }
       </table>
     `.replace('\\n', '')
  }

  const recAstToDiGraph = object => {
    /* walk recursively the AST (abstract syntax tree) to make a displayable
       graph respresentation of it

       object: output of acorn-parser s
       return: list of nodes and list fitting to dagre graph package
          representing a tree with html leave
     */
    try {
      if (!object) return
      if (object.length) {
        if (object.length === 1) {
          return recAstToDiGraph(object[0])
        }
        return object.map(recAstToDiGraph)
      }
      if (object.type === 'BlockStatement') {
        return recAstToDiGraph(object.body)
      }
      if (object.type === 'ObjectExpression') {
        if (
          object.properties.find(property => property.key.value === 'fieldName')
        ) {
          const html = redCapFieldInfoExpression(object)
          const rootId = functionString.slice(object.start, object.end)

          nodes.push({
            id: rootId,
            label: html,
            labelType: 'html',
          })
          return rootId
        } else {
          const rootId = (++objectRoots).toString()
          nodes.push({
            id: rootId,
            label: FieldTypes.CHECKBOX,
            labelType: 'html',
          })

          object.properties.forEach(property => {
            const effectiveBranchId = recAstToDiGraph(property.value)
            const branchId = rootId + '_' + property.key.value

            nodes.push({
              id: branchId,
              label: 'Checkbox-Option: ' + property.key.value,
              labelType: 'html',
            })
            links.push(
              {
                source: rootId,
                target: branchId,
                label: '',
                labelType: 'html',
                config: {
                  style: 'stroke: green; fill:none;',
                  arrowheadStyle: 'fill: green',
                },
              },
              {
                source: branchId,
                target: effectiveBranchId,
                label: '',
                labelType: 'html',
                config: {
                  style: 'stroke: green; fill:none;',
                  arrowheadStyle: 'fill: green',
                },
              }
            )
          })
          return rootId
        }
      }
      if (object.type === 'ArrayExpression') {
        const values = object.elements.map(it => {
          return eval('(' + functionString.slice(it.start, it.end) + ')')
        })
        const nodeIds = object.elements.map(it => {
          return recAstToDiGraph(it)
        })
        let foundFirstGood = false
        object.elements.forEach((it, i, array) => {
          if (i > 0) {
            links.push({
              source: nodeIds[i - 1],
              target: nodeIds[i],
              label: 'wenn nicht, dann',
              labelType: 'html',
              config: !foundFirstGood
                ? {
                    style: 'stroke: green; fill:none;',
                    arrowheadStyle: 'fill: green',
                  }
                : {
                    style: 'stroke: black; stroke-dasharray: 5, 5; fill:none;',
                    arrowheadStyle: 'fill: black',
                  },
            })
          }
          if (values[i] !== null && values[i] !== '' && !values[i - 1])
            foundFirstGood = true
        })
        return nodeIds[0]
      }
      if (object.type === 'ReturnStatement') {
        return recAstToDiGraph(object.argument)
      }
      if (
        object.type === 'BinaryExpression' ||
        object.type === 'LogicalExpression'
      ) {
        const leftId = recAstToDiGraph(object.left)
        const rightId = recAstToDiGraph(object.right)
        const operatorId = leftId + object.operator + rightId
        nodes.push({
          id: operatorId,
          label:
            OPERATOR_GERMAN_TRANSLATION[object.operator] +
            '(' +
            object.operator +
            ')',
          labelType: 'html',
        })
        links.push(
          {
            source: operatorId,
            target: leftId,
            label: '... ' + OPERATOR_GERMAN_TRANSLATION[object.operator],
            labelType: 'html',
            config: {
              style: 'stroke: green; fill:none;',
              arrowheadStyle: 'fill: green',
            },
          },
          {
            source: operatorId,
            target: rightId,
            label: OPERATOR_GERMAN_TRANSLATION[object.operator] + ' ...',
            labelType: 'html',
            config: {
              style: 'stroke: green; fill:none;',
              arrowheadStyle: 'fill: green',
            },
          }
        )
        return operatorId
      }
      if (object.type === 'UnaryExpression') {
        return recAstToDiGraph(object.argument)
      }
      if (object.type === 'MemberExpression') {
        const id = astObjToId(object)
        nodes.push({
          id: id,
          label: redCapFieldInfoExpression(object.object),
          labelType: 'html',
        })
        return id
      }
      if (object.type === 'IfStatement') {
        const ifId = astObjToId(object)

        const testId = recAstToDiGraph(object.test)
        const consequentId = recAstToDiGraph(object.consequent)
        const alternateId = recAstToDiGraph(object.alternate)

        if (!testId || !consequentId) {
          console.error(
            'No consequntId or testId generated!',
            testId,
            object.test,
            consequentId,
            object.consequent
          )
        }

        let testResult = ''
        try {
          testResult = eval(
            '(' + functionString.slice(object.test.start, object.test.end) + ')'
          )
        } catch (e) {
          console.log(
            '(' +
              functionString.slice(object.test.start, object.test.end) +
              ')',
            ' for condition of if-statement could not be evaluated',
            e
          )
        }

        nodes.push({
          id: ifId,
          label: `Ergebnis der Bedingung: ${BOOLEAN_GERMAN_TRANSLATION[testResult]}`,
          labelType: 'html',
          config: {
            style: 'black: green; fill:none;',
            arrowheadStyle: 'fill: black',
          },
        })
        links.push(
          {
            source: ifId,
            target: testId,
            label: 'WENN',
            labelType: 'html',
            config: {
              style: 'stroke: black; stroke-dasharray: 5, 5; fill:none;',
              arrowheadStyle: 'fill: black',
            },
          },
          {
            source: ifId,
            target: consequentId,
            label: 'DANN',
            labelType: 'html',
            config: testResult
              ? {
                  style: 'stroke: green; fill:none;',
                  arrowheadStyle: 'fill: green',
                }
              : {
                  style: 'stroke: red; stroke-dasharray: 5, 5; fill:none;',
                  arrowheadStyle: 'fill: red',
                },
          },
          {
            source: ifId,
            target: alternateId,
            label: 'SONST',
            labelType: 'html',
            config: testResult
              ? {
                  style: 'stroke: red; stroke-dasharray: 5, 5; fill:none;',
                  arrowheadStyle: 'fill: red',
                }
              : {
                  style: 'stroke: green; fill:none; ',
                  arrowheadStyle: 'fill: green',
                },
          }
        )
        return ifId
      }
      if (object.type === 'Literal') {
        let result = null
        const id = astObjToId(object)

        try {
          result = eval(
            '(' + functionString.slice(object.start, object.end) + ')'
          )
        } catch (e) {
          console.error(
            'Bad literal in piping expression containing a literal',
            object.start,
            object.end,
            functionString.slice(object.start, object.end),
            result
          )
          result = 'Fehlerhaftes Literal'
        }
        nodes.push({
          id: id,
          label: result !== null ? result.toString() : 'Kein Wert',
          labelType: 'html',
        })
        return astObjToId(object)
      }

      console.error(
        'object not matched',
        object,
        functionString.slice(object.start, object.end)
      )
      return astObjToId(object)
    } catch (e) {
      console.error(
        'Computing graph for abstract syntax tree representation failed',
        {
          object,
          e,
          functionString,
          source: functionString.slice(object.start, object.end),
        }
      )
    }
  }
  recAstToDiGraph(obj)
  return { nodes, links }
}
