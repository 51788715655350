import ApiServiceBase, { API_RETURN } from './ApiServiceBase'
import axios, { AxiosResponse } from 'axios'
import { API_DOCUMENT } from '../config/Paths'
import { ApiResponse } from '../types/ApiResponse'
import { Document } from '../types/Document'
import { ApiError } from '../types/ApiError'
import { getToken } from './Keycloak'

class DocumentService extends ApiServiceBase {
  getFindingsForPatient(
    patientIdRaw: string,
    sortOption: string,
    ownership?: string
  ): Promise<ApiResponse<Document[] | ApiError>> {
    const url = new URL(`${API_DOCUMENT}/findings`)
    const patientId = `Patient/${patientIdRaw}`
    url.searchParams.append('patientId', patientId)
    url.searchParams.append('sortOption', sortOption)
    if (ownership) {
      url.searchParams.append('ownership', ownership)
    }
    return this.get<null, Document[]>(url)
  }

  getDocument(documentId: string): Promise<ApiResponse<Blob | ApiError>> {
    return this.get<null, Blob>(
      new URL(`${API_DOCUMENT}/${documentId}`),
      null,
      API_RETURN.blob
    )
  }

  uploadFile(
    file: string | Blob,
    fileCategory: string
  ): Promise<AxiosResponse<Document[]>> {
    const formData = new FormData()
    formData.append('files', file)
    formData.append('fileCategory', fileCategory)
    const url = API_DOCUMENT + '/upload-practitioner'
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*/*',
        Authorization: `Bearer ` + getToken(),
      },
    })
  }

  uploadPatientFindingsFile(
    file: string | Blob,
    patientId: string,
    ownership: string,
    fileName?: string
  ): Promise<AxiosResponse<Document[]>> {
    const formData = new FormData()
    formData.append('patientId', patientId)

    if (fileName) {
      formData.append('files', file, fileName)
    } else {
      formData.append('files', file)
    }

    formData.append('fileCategory', 'Patient-findings')
    formData.append('ownership', ownership)
    const url = API_DOCUMENT + '/upload'
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*/*',
        Authorization: `Bearer ` + getToken(),
      },
    })
  }

  editPatientFindingsFile(
    documentDto: Document
  ): Promise<ApiResponse<Document | ApiError>> {
    documentDto.documentStatus = documentDto.documentStatus.toLowerCase()
    return this.put<Document>(
      new URL(`${API_DOCUMENT}/${documentDto.id}`),
      documentDto
    )
  }
}

export default DocumentService
