import React, { Component } from 'react'
import { AuthStore } from '../../../infrastructure/AuthProvider'
import ProfilForm from './ProfileForm'
import { Practitioner } from '../../../types/Practitioner'
import { NotificationManager } from 'react-notifications'
import PractitionerService from '../../../services/PractitionerService'
import { APIResult } from '../../../constants/APIResult'
import BigButton from '../../UI/BigButton/BigButton'
import { Button, Col, Row } from 'react-bootstrap'
import MaterialIcon from '../../UI/MaterialIcon/MaterialIcon'

interface ProfileDetailsState {
  profile: Practitioner | null
}

type ProfileDetailsProps = Record<string, unknown>

class ProfileDetails extends Component<
  ProfileDetailsProps,
  ProfileDetailsState
> {
  static contextType = AuthStore

  state: ProfileDetailsState = {
    profile: null,
  }

  async componentDidMount(): Promise<void> {
    await this.fetchProfile()
  }

  fetchProfile = async (): Promise<void> => {
    const profileService = new PractitionerService()
    const response = await profileService.getPractitioner(
      this.context.auth.panosId
    )
    if (response?.Response && response?.Result === APIResult.SUCCESS) {
      const profile = response.Response as Practitioner
      this.setState({
        profile,
      })
    } else {
      NotificationManager.error('Profile error!')
    }
  }

  onProfileChanged = async (profile: Practitioner): Promise<void> => {
    const service = new PractitionerService()
    const res = await service.putPractitioner(profile)
    if (res && res.Result === APIResult.SUCCESS) {
      this.setState({
        profile: {
          ...profile,
        },
      })
      NotificationManager.success('Speichern erfolgreich')

      this.context.handlers.updateUserSettings()
    } else {
      NotificationManager.error(
        res
          ? (res.Response as string).replace('Fehler editing practitioner:', '')
          : '',
        'Fehler beim Speichern'
      )
    }
  }

  render(): JSX.Element {
    return (
      <div className="row">
        <div className="col-10 pt-2">
          <ProfilForm
            profile={this.state.profile}
            onSubmit={this.onProfileChanged}
          />
          <hr />
          <h5>
            <MaterialIcon icon={'data_usage'} size={'16px'} /> Zugang
          </h5>

          <Row>
            <Col lg="4">
              <div className="form-group">
                <label>Benutzername</label>
                <input
                  name={'userName'}
                  className={'form-control'}
                  placeholder={'Benutzername'}
                  disabled={true}
                  defaultValue={this.state.profile?.userName}
                />
              </div>
              <div className="form-group">
                <Button
                  variant={'primary'}
                  block
                  onClick={this.context.handlers.doRelogin}>
                  Passwort/ Benutzername erneuern auf Startseite
                </Button>
              </div>
            </Col>
          </Row>

          <h5 className="mt-2">
            <MaterialIcon icon={'data_usage'} size={'16px'} /> Datenschutz
          </h5>
          <Row>
            <Col>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Duis autem vel
                eum iriure dolor in hendrerit in vulputate velit esse molestie
                consequat, vel illum dolore eu feugiat nulla facilisis at vero
                eros et accumsan et iusto odio dignissim qui blandit praesent
                luptatum zzril delenit augue duis dolore te feugait nulla
                facilisi. Lorem ipsum dolor sit amet,
              </p>
            </Col>
          </Row>
        </div>
        <div className="col-2 border-left pt-2">
          <BigButton
            icon="delete"
            onClick={() => console.log('Implement self deletion')}>
            Profil Löchen
          </BigButton>
        </div>
      </div>
    )
  }
}

export default ProfileDetails
