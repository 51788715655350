import { MedicationReason, MedicationRequestUpdateWithReason } from './MedicationSchedule/MedicationReason'
import { CarePlan } from '../../types/CarePlan'
import { DynamicSidebarContext } from '../../infrastructure/DynamicSidebarProvider'
import { AuthContext } from '../../infrastructure/AuthProvider'
import { Task } from '../../types/Task'
import _ from 'lodash'
import { hasStatus } from '../../services/FhirResourceService'
import { MedicationStatusDelegation } from '../../utils/MedicationUtils'
import { MedicationRequestStatus } from '../../constants/MedicationRequestStatus'
import { UserPermission } from '../../constants/UserPermission'
import CarePlanService from '../../services/CarePlanService'
import { ROUTE_PATIENT_DATA_TREATMENT } from '../../config/Paths'
import TaskForm from '../Tasks/TaskForm/TaskForm'
import React from 'react'
import { updateMedicationRequestById } from '../../services/MedicationRequestService'
import { APIResult } from '../../constants/APIResult'
import { getIdPartFromFhirResourceId } from '../../utils/Utils'
import { NotificationManager } from 'react-notifications'
import LocalStorageService from '../../services/LocalStorageService'

/**
 * Schedules relogin with updates stored in local storage
 * or a task in case of case management opened in the sidebar
 *
 * @param groupId - which medicationRequest
 * @param medicationRequests - medicationRequest objects to write
 * @param reason - context reason
 * @param carePlan - carePlan to edit
 * @param sidebarContext
 * @param authContext
 * @param patientId - treated patient
 * @param localStorageService - resources localStorageService
 * @param casemanagerTask - the task to be scheduled
 * @param includeActiveMedicationRequest - whether to add all other active
 *    MedicationRequests
 **/
export const activateMedicationRequestsBeforeReLogin = async (
  groupId: string,
  medicationRequests: MedicationRequest[],
  reason: MedicationReason,
  carePlan: CarePlan,
  sidebarContext: DynamicSidebarContext,
  authContext: AuthContext,
  patientId: string,
  localStorageService: LocalStorageService<any>,
  casemanagerTask: Task,
  includeActiveMedicationRequest = false,
  readyToConfirm = false
): Promise<void> => {
  let requests = _.cloneDeep(medicationRequests)
  if (groupId) requests = requests.filter(it => it.id === groupId)

  let medicationRequestsToUpdateByConfirm: MedicationRequest[]
  if (readyToConfirm) medicationRequestsToUpdateByConfirm = requests
  else {
    const pendingExists = requests.some(it =>
      hasStatus(MedicationStatusDelegation.getCaseManagerOverRealStatus(it), [
        MedicationRequestStatus.on_hold,
        MedicationRequestStatus.draft,
      ])
    )

    medicationRequestsToUpdateByConfirm = requests.map(request => {
      if (MedicationStatusDelegation.statusDiffer([request])) {
        MedicationStatusDelegation.takeProposedStatus(request)
        return request
      } else {
        const relevantStatus =
          MedicationStatusDelegation.getCaseManagerOverRealStatus(request)
        if (pendingExists) {
          // CONFIRM EDITED
          switch (relevantStatus) {
            case MedicationRequestStatus.on_hold:
            case MedicationRequestStatus.draft: {
              MedicationStatusDelegation.changeStatus(
                request,
                MedicationRequestStatus.active,
                authContext
              )
              /**
               * activated MedRequest is self-referenced
               * in field priorPrescription
               */
              request.priorPrescription = {
                reference: 'MedicationRequest/' + request.id,
              }
              return request
            }
            default:
          }
        } else {
          console.log('pause...')
          // PAUSE ALL/ONE
          switch (relevantStatus) {
            case MedicationRequestStatus.active: {
              MedicationStatusDelegation.changeStatus(
                request,
                MedicationRequestStatus.on_hold,
                authContext
              )
              return request
            }
            default:
          }
        }
        return null
      }
    })
    medicationRequestsToUpdateByConfirm =
      medicationRequestsToUpdateByConfirm.filter(it => it)
  }
  console.log({
    requests,
    status: requests.map(request => {
      return MedicationStatusDelegation.getCaseManagerOverRealStatus(request)
    }),

    medicationRequestsToUpdateByConfirm,
    groupId,
  })

  try {
    if (medicationRequestsToUpdateByConfirm.length > 0) {
      if (
        authContext.handlers.hasPermission(
          UserPermission.PATIENT_MEDICATION_CONFIRM
        )
      ) {
        await new CarePlanService(reason).updateCarePlansMedicationRequests(
          carePlan.id,
          medicationRequests.map(it => it.id.toString())
        )
        medicationRequestsToUpdateByConfirm =
          medicationRequestsToUpdateByConfirm.map(mR => {
            delete mR.meta
            return mR
          })

        localStorageService.localStorageAdd({
          medicationRequests: medicationRequestsToUpdateByConfirm,
          carePlan: carePlan,
          reason: reason,
        })

        authContext.handlers.doRelogin(
          ROUTE_PATIENT_DATA_TREATMENT.replace(':id', patientId)
        )
      } else if (
        authContext.handlers.hasPermission(
          UserPermission.PATIENT_MEDICATION_CREATE_TASK
        ) &&
        sidebarContext
      ) {
        sidebarContext.handlers.addContent({
          /*headline?: JSX.Element | string,*/
          identifier: 'indication-confirmation-task-request',
          component: (
            <TaskForm
              patientId={patientId}
              task={casemanagerTask}
              onClose={() =>
                sidebarContext.handlers.removeContent(
                  'indication-confirmation-task-request'
                )
              }
              onChanged={task => {
                sidebarContext.handlers.removeContent(
                  'indication-confirmation-task-request'
                )
              }}
            />
          ),
          maxWidth: 300,
        })
      }
    }
  } catch (e) {
    console.log(
      'Fehler beim Aktualisieren der Verschreibungen',
      medicationRequests,
      medicationRequestsToUpdateByConfirm
    )
    NotificationManager.error('Fehler beim Aktualisieren der Verschreibungen')
  }
}

export async function activateMedicationRequestsAfterReLogin(
  medicationRequestUpdateWithReason: MedicationRequestUpdateWithReason,
  patientId: string,
  includeExistingMedicationRequests = true
): Promise<{ newMedicationRequests: any; updatedMedicationRequests: any }> {
  const medicationRequestsToUpdate =
    medicationRequestUpdateWithReason.medicationRequests
  const reasonBeforeLogin = medicationRequestUpdateWithReason.reason

  medicationRequestsToUpdate.map(it =>
    MedicationStatusDelegation.setProposedStatus(
      it,
      MedicationStatusDelegation.getRealStatus(it)
    )
  )

  const axiosResponses = await Promise.all(
    medicationRequestsToUpdate.map(
      async medicationRequest =>
        await updateMedicationRequestById(
          medicationRequest,
          medicationRequest.id
        )
    )
  )
  const newMedicationRequests = axiosResponses.map(it => it.data)

  const carePlanService = new CarePlanService(reasonBeforeLogin)
  const response = await carePlanService.getActiveCarePlan(patientId)
  if (response.Result !== APIResult.SUCCESS) {
    console.error('Aktiver Medikamentenplan nicht geladen')
    NotificationManager('Aktiver Medikamentenplan nicht geladen')
  }
  const carePlanUpToDate = response.Response as unknown as CarePlan
  const medicationRequestIds = _.uniq([
    ...(includeExistingMedicationRequests
      ? carePlanUpToDate.medicationRequests.map(it =>
          getIdPartFromFhirResourceId(it)
        )
      : []),
    ...newMedicationRequests.map(it =>
      getIdPartFromFhirResourceId(it.id.toString())
    ),
  ])
  const planToUpdate = {
    ...(response.Response as unknown as CarePlan),
    medicationRequests: medicationRequestIds,
  }
  const updatedMedicationRequests =
    await carePlanService.updateCarePlansMedicationRequests(
      planToUpdate.id,
      medicationRequestIds
    )
  if (updatedMedicationRequests.Result !== APIResult.SUCCESS) {
    console.error('Medikamentenplan nicht akutalisiert')
    NotificationManager.error('Aktiver Medikamentenplan nicht akutalisiert')
  } else
    NotificationManager.success('Änderungen am Medikamentenplan erfolgreich')

  localStorage.clear()
  return { newMedicationRequests, updatedMedicationRequests }
}
