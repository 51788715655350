export enum TaskFields {
  TITLE = 'title',
  CATEGORY = 'category',
  PRIORITY = 'priority',
  DESCRIPTION = 'description',
  OWNER = 'owner',
  OWNERDTO = 'ownerDto',
  FORDTO = 'forDto',
  START_DATE = 'start_date',
  DEADLINE = 'deadline',
}
