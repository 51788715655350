import { Button, Modal } from 'react-bootstrap'
import React from 'react'
import MedicationHistory from './MedicationHistory'
import { MedicationScheduleObject } from '../../../utils/MedicationScheduleUtils'
import { CarePlan } from '../../../types/CarePlan'

interface HistoryModalProps {
  carePlan: CarePlan
  setSchedule: (schedule: MedicationScheduleObject) => void
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  patientId: string
}

export const HistoryModal: React.FC<HistoryModalProps> = ({
  carePlan,
  setSchedule,
  showModal,
  setShowModal,
  patientId,
}: HistoryModalProps): JSX.Element => {
  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <Modal animation={true} show={showModal} size={'xl'} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title> Versionsübersicht </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MedicationHistory
          carePlan={carePlan}
          patientId={patientId}
          setSchedule={setSchedule}
          setShowModal={setShowModal}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className={'btn btn-secondary mr-1'} onClick={handleClose}>
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
