import React, { Component } from 'react'
import { getFullName } from '../../../utils/Utils'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import './Autocomplete.scss'
import FormErrorMessage from '../Forms/FormError/FormErrorMessage'

interface AutocompleteProps<T> {
  data?: T[]
  value: string // value of Textbox
  placeholder: string //placeholder
  notFoundMessage: string //message to display when nobody was found
  selected?: T // selected entity
  label?: string //label to display above the input
  disabled?: boolean
  icon?: string
  minCharacters?: number //Minimum amount of characters to trigger the search
  renderValue?: (value: T) => string | JSX.Element
  onSearch: (value: string) => Promise<void>
  onSelect: (any: T) => void
  idProp: string // access an id attribute, defaults to "id"
  name: string // name for value/error form validation scheme usage
  error?: string
}

interface AutocompleteState {
  value: string
  activeSuggestion: number
}

class Autocomplete<T> extends Component<
  AutocompleteProps<T>,
  AutocompleteState
> {
  static defaultProps = {
    icon: 'search',
    minCharacters: 2,
    idProp: 'id',
  }
  state = {
    value: this.props.value,
    activeSuggestion: -1,
  }
  private timer = null
  private inputRef = React.createRef<HTMLInputElement>()

  componentDidMount(): void {
    const { value } = this.props
    if (value) {
      this.setState({ value })
    }
  }

  componentDidUpdate(prevProps: AutocompleteProps<T>): void {
    const { selected, value, data } = this.props

    if (prevProps.selected !== selected) {
      if (selected) {
        this.setState({ value })
      } else {
        this.setState({ value: '' })
      }
    }
    if (data !== prevProps.data) {
      this.setState({ activeSuggestion: -1 })
    }
  }

  componentWillUnmount(): void {
    if (this.timer) clearTimeout(this.timer)
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value
    this.setState({ value, activeSuggestion: -1 })
    if (value.length >= this.props.minCharacters) {
      if (this.timer) clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.props.onSearch(value)
      }, 500)
    } else {
      this.props.onSearch(null)
    }
  }

  onSelect = (value: T): void => {
    if (value) {
      const activeIndex = this.props.data.findIndex(
        el => el[this.props.idProp] === value[this.props.idProp]
      )
      this.setState({ activeSuggestion: activeIndex })
    }
    this.props.onSelect(value)
  }

  handleKeydown = (e: React.KeyboardEvent): void => {
    // Do nothing when there is nothing to select
    if (!this.props.data?.length) return

    switch (e.key) {
      case 'Down':
      case 'ArrowDown':
        this.setState((prevState, props) => {
          const nextIndex = prevState.activeSuggestion + 1
          if (nextIndex > props.data.length - 1) return null
          return {
            activeSuggestion: nextIndex,
          }
        })
        break
      case 'Up':
      case 'ArrowUp':
        this.setState(prevState => {
          const nextIndex = prevState.activeSuggestion - 1
          if (nextIndex < 0) return null
          return { activeSuggestion: nextIndex }
        })
        break
      case 'Enter':
        if (
          this.state.activeSuggestion >= 0 &&
          this.state.activeSuggestion <= this.props.data.length
        ) {
          const patient = this.props.data[this.state.activeSuggestion]
          this.inputRef.current.blur()
          this.onSelect(patient)
        } else if (this.state.value.length === 0) {
          this.inputRef.current.blur()
        }

        break
    }
  }

  /** Reset input when nothing is selected */
  handleInputBlur = (): void => {
    if (
      this.state.activeSuggestion === -1 &&
      this.props.value !== this.state.value
    ) {
      this.onSelect(null)
    }
  }

  renderValue = (value: T): string | JSX.Element => {
    if (this.props.renderValue) {
      return this.props.renderValue(value)
    } else {
      return getFullName(value)
    }
  }

  render(): JSX.Element {
    return (
      <div className="form-group patientSelect" onKeyDown={this.handleKeydown}>
        {this.props.label ? (
          <label className="form-label">{this.props.label}</label>
        ) : null}
        <input
          ref={this.inputRef}
          type="text"
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          className="form-control"
          value={this.state.value || ''}
          onChange={this.handleInputChange}
          onBlur={this.handleInputBlur}
          readOnly={this.props.minCharacters === 0}
          name={this.props.name}
          autoComplete={'off'}
        />
        <FormErrorMessage error={this.props.error} />
        <MaterialIcon icon={this.props.icon} className="patientSelectIcon" />
        <div className={`select-list${this.props.data ? ' border' : ''}`}>
          {this.props.data?.length === 0 && (
            <div className="select-option">{this.props.notFoundMessage}</div>
          )}
          {this.props.data &&
            this.props.data.map((value, index) => {
              return (
                <div
                  className={`select-option${
                    this.state.activeSuggestion === index ? ' active' : ''
                  }`}
                  key={index}
                  onMouseDown={() => this.onSelect(value)}>
                  {this.renderValue(value)}
                </div>
              )
            })}
        </div>
      </div>
    )
  }
}

export default Autocomplete
