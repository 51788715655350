import * as React from 'react'
import { Component } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'

interface Props {
  onChange: (value: number) => void
  value?: number
  disabled?: boolean
  name?: string
  error?: string
}

interface State {
  duration: number
  checked?: boolean
}

class DurationsSelector extends Component<Props, State> {
  possibleDurations = [30, 45, 60, 90, 120]
  state = {
    duration: this.props.value,
    checked: false,
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.value !== this.props.value) {
      this.setState({ duration: this.props.value })
    }
  }

  onSetDuration = (duration: number): void => {
    this.setState({ duration: duration })
    this.props.onChange(duration)
  }

  onChangeDuration = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const duration = +event.target.value
    this.setState({ duration: duration })
    this.props.onChange(duration)
  }

  render(): JSX.Element {
    return (
      <div className="d-flex">
        <ButtonGroup className="mr-2" aria-label="First group">
          {this.possibleDurations.map(duration => (
            <Button
              key={duration}
              variant="light"
              active={this.state.duration === duration}
              onClick={() => this.onSetDuration(duration)}>
              {duration}
            </Button>
          ))}
        </ButtonGroup>
        <input
          type="number"
          value={this.state.duration}
          name={this.props.name}
          className="form-control w-25"
          onChange={this.onChangeDuration}
          disabled={this.props.disabled}
        />
        <div className="error-messages">{this.props.error}</div>
      </div>
    )
  }
}

export default DurationsSelector
