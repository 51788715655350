import React, { Component, ReactElement } from 'react'

export const SubscriptionStore = React.createContext<
  SubscriptionProps & NotifierProps
>(null)

export enum SubscriptionType {
  Notifications,
  DynamicSidebar,
  Calendar,
}

export enum SubscriptionIdentifier {
  Task = 'task',
  Appointment = 'appointment',
  Form = 'form',
  MessageSystem = 'message_system',
}

type SubscriptionCallback = (
  message: any,
  subscriptionType: SubscriptionType,
  identifier: SubscriptionIdentifier
) => void

export interface SubscriptionProps {
  subscribe: (
    subscriptionType: SubscriptionType,
    identifier: SubscriptionIdentifier,
    callback: SubscriptionCallback
  ) => Promise<void>
  unsubscribe: (
    subscriptionType: SubscriptionType,
    identifier: SubscriptionIdentifier,
    callback: SubscriptionCallback
  ) => Promise<void>
}

export interface NotifierProps {
  notifiySubscriptions: (
    subscriptionType: SubscriptionType,
    identifier: SubscriptionIdentifier,
    message: unknown
  ) => void
}

interface SubscriptionProviderState {
  subscriptions: Record<number, Record<string, SubscriptionCallback[]>>
}

export default class SubscriptionProvider extends Component<
  Record<string, unknown>,
  SubscriptionProviderState
> {
  state: SubscriptionProviderState = {
    subscriptions: Object.values(SubscriptionType).reduce(
      (a, b) =>
        isNaN(parseInt(b as string)) || b === null ? a : { ...a, [b]: {} },
      { null: {} }
    ),
  }

  subscribe = (
    subscriptionType: SubscriptionType,
    identifier: SubscriptionIdentifier,
    callback: SubscriptionCallback
  ): Promise<void> => {
    return new Promise(resolve => {
      if (callback) {
        this.setState(
          {
            subscriptions: {
              ...this.state.subscriptions,
              [subscriptionType]: {
                ...this.state.subscriptions[subscriptionType],
                [identifier]: [
                  ...(
                    this.state.subscriptions[subscriptionType][identifier] || []
                  ).filter(e => e !== callback),
                  callback,
                ],
              },
            },
          },
          resolve
        )
      }
    })
  }

  unsubscribe = (
    subscriptionType: SubscriptionType,
    identifier: SubscriptionIdentifier,
    callback: SubscriptionCallback
  ): Promise<void> => {
    return new Promise(resolve => {
      if (callback) {
        this.setState(
          {
            subscriptions: {
              ...this.state.subscriptions,
              [subscriptionType]: {
                ...this.state.subscriptions[subscriptionType],
                [identifier]: [
                  ...(
                    this.state.subscriptions[subscriptionType][identifier] || []
                  ).filter(e => e !== callback),
                ],
              },
            },
          },
          resolve
        )
      }
    })
  }

  emitNotifications = (
    subscriptionType: SubscriptionType,
    identifier: SubscriptionIdentifier,
    message: unknown
  ): void => {
    this.notifiySubscriptions(subscriptionType, identifier, message)
    if (identifier !== null)
      this.notifiySubscriptions(subscriptionType, null, message)
    if (subscriptionType !== null)
      this.notifiySubscriptions(null, identifier, message)
    if (subscriptionType !== null && identifier !== null)
      this.notifiySubscriptions(null, null, message)
  }

  notifiySubscriptions = (
    subscriptionType: SubscriptionType,
    identifier: SubscriptionIdentifier,
    message: unknown
  ): void => {
    const { subscriptions } = this.state
    if (
      subscriptions &&
      subscriptions[subscriptionType] &&
      subscriptions[subscriptionType][identifier] &&
      subscriptions[subscriptionType][identifier].length
    ) {
      subscriptions[subscriptionType][identifier].forEach(it => {
        it(message, subscriptionType, identifier)
      })
    }
  }

  render(): ReactElement {
    return (
      <SubscriptionStore.Provider
        value={{
          subscribe: this.subscribe,
          unsubscribe: this.unsubscribe,
          notifiySubscriptions: this.emitNotifications,
        }}>
        {this.props.children}
      </SubscriptionStore.Provider>
    )
  }
}

export function withSubscriptions<T>(
  Component: React.ComponentType<T>
): React.ComponentType<Omit<T, keyof SubscriptionProps>> {
  const componentWithNotifications = (props: T) => {
    return (
      <SubscriptionStore.Consumer>
        {store => (
          <Component
            subscribe={store.subscribe}
            unsubscribe={store.unsubscribe}
            {...props}
          />
        )}
      </SubscriptionStore.Consumer>
    )
  }
  componentWithNotifications.displayName = `withSubscriptions(${
    Component.displayName || Component.name || 'Component'
  })`
  return componentWithNotifications
}

export function withSubscriptionNotifier<T>(
  Component: React.ComponentType<T>
): React.ComponentType<Omit<T, keyof NotifierProps>> {
  const componentWithNotifications = (props: T) => {
    return (
      <SubscriptionStore.Consumer>
        {store => (
          <Component
            notifiySubscriptions={store.notifiySubscriptions}
            {...props}
          />
        )}
      </SubscriptionStore.Consumer>
    )
  }
  componentWithNotifications.displayName = `withSubscriptionNotifier(${
    Component.displayName || Component.name || 'Component'
  })`
  return componentWithNotifications
}
